import {
  EstimationDetailInput,
  useEstimationEditMutation,
} from 'gql/__generated__/useEstimationEditMutation.graphql';
import {
  useEstimationEditQuery$data,
  useEstimationEditQuery as useEstimationEditQueryType,
} from 'gql/__generated__/useEstimationEditQuery.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { toast } from 'lib/toast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchQuery, graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { ZodEstimationFormData } from '../zod';

const useEstimationEditQuery = graphql`
  query useEstimationEditQuery ($id: ID!) {
    downloadEstimationPdf(id: $id)
  }
`;

export const useEstimationEdit = ({
  estimationId,
  state,
}: {
  estimationId: string;
  state: ZodEstimationFormData;
}) => {
  const versionWatcher = useContext(VersionWatcherContext);
  useEffect(versionWatcher.disableSoftDeleteEffect, []);

  const navigate = useNavigate();
  const [showPreview, setPreview] = useState(false);
  const [data, setData] = useState<ZodEstimationFormData>(state);
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useEstimationEditMutation>(
    graphql`
      mutation useEstimationEditMutation(
        $id: ID!
        $input: EstimationUpdateInput!
      ) {
        updateEstimation(id: $id, input: $input) {
          id
        }
      }
    `,
  );

  const onClickToConfirm = (data: ZodEstimationFormData) => {
    setData({ ...data });
    setPreview(true);
  };

  const onClickToNewForm = () => setPreview(false);

  const onSubmit = () => {
    const details = data.details.map((detail, index) => ({ ...detail, orderNumber: index + 1 }));

    commitMutation({
      variables: {
        id: estimationId,
        input: {
          title: data.title,
          detailMessage: data.detailMessage,
          expirationPeriod: data.expirationPeriod,
          showAmountSummary: data.showAmountSummary,
          supplierID: data.supplier.id,
          companyID: data.company.id,
          items: details.map(
            (detail) =>
              ({
                name: detail.name,
                unitPrice: detail.unitPrice,
                unitSellingPrice: detail.unitSellingPrice,
                quantity: detail.quantity,
                taxCategoryID: detail.tax.id,
                orderNumber: detail.orderNumber,
                documentDisplayStatus: detail.documentDisplayStatus,
              }) as EstimationDetailInput,
          ),
          internalAssignees: [
            ...(data.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
        },
      },
      onCompleted: (response) => {
        const fetch = async () => {
          let data: useEstimationEditQuery$data | undefined;
          try {
            data = await fetchQuery<useEstimationEditQueryType>(
              RelayEnvironment,
              useEstimationEditQuery,
              {
                id: response.updateEstimation.id,
              },
            ).toPromise();
          } catch (_error) {
            // nop: useErrorHandlableRelayEnvironmentでエラー処理している
            return;
          }

          window.open(data?.downloadEstimationPdf, '_blank', 'noopener, noreferrer');
        };

        fetch();
        toast({
          title: '見積書を更新しました',
          status: 'success',
        });
        navigate(paths.estimation.url());
      },
    });
  };

  return {
    data,
    showPreview,
    onClickToConfirm,
    onClickToNewForm,
    onSubmit,
    isMutationInFlight,
  };
};
