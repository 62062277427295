import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { InvoiceDetailBillingInfo } from 'features/invoice/components/InvoiceDetailBillingInfo';
import { InvoiceDetailHeader } from 'features/invoice/components/InvoiceDetailHeader';
import { InvoiceDetailInfo } from 'features/invoice/components/InvoiceDetailInfo';
import { useQueryParams } from 'features/invoice/hooks/useInvoiceQueryPrams';
import { detail_InvoiceDetailPageQuery } from 'gql/__generated__/detail_InvoiceDetailPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const InvoiceDetailPageQuery = graphql`
  query detail_InvoiceDetailPageQuery  ($id: ID!) {
    invoices(where: {id: $id}) {
      edges {
        node {
          ...InvoiceDetailInfoFragment
          ...InvoiceDetailBillingInfoFragment
        }
      }
    }
    ...InvoiceDetailHeaderFragment
  }
`;

export const InvoiceDetailPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { queryParams } = useQueryParams();

  const query = useLazyLoadQuery<detail_InvoiceDetailPageQuery>(
    InvoiceDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const invoice = (query.invoices?.edges || [])[0]?.node;

  if (!invoice) return null;

  return (
    <Box w="992px">
      <Box mb={6}>
        <Flex justify="space-between">
          <RouterLink
            to={paths.invoice.url({
              creator: queryParams.creator,
              demand: queryParams.demand,
            })}
          >
            <PageBack />
          </RouterLink>
          <InvoiceDetailHeader queryRef={query} invoiceId={id} />
        </Flex>
      </Box>
      <VStack spacing={6} align="start">
        <SubHeading label="請求書の詳細" />
        <HStack spacing={4} align="start">
          <Box w="208px">
            <InvoiceDetailInfo queryRef={invoice} />
          </Box>
          <Box w="768px">
            <InvoiceDetailBillingInfo queryRef={invoice} />
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};
