// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file service/item/v1/item.proto (package service.item.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ConvertToSortedSpecRequest, ConvertToSortedSpecResponse, SummarizeItemsRequest, SummarizeItemsResponse } from "./item_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service service.item.v1.ItemService
 */
export const ItemService = {
  typeName: "service.item.v1.ItemService",
  methods: {
    /**
     * アイテムのサマリーを取得する
     *
     * @generated from rpc service.item.v1.ItemService.SummarizeItems
     */
    summarizeItems: {
      name: "SummarizeItems",
      I: SummarizeItemsRequest,
      O: SummarizeItemsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * JSONの仕様を整列した仕様に変換する
     *
     * @generated from rpc service.item.v1.ItemService.ConvertToSortedSpec
     */
    convertToSortedSpec: {
      name: "ConvertToSortedSpec",
      I: ConvertToSortedSpecRequest,
      O: ConvertToSortedSpecResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

