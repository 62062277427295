import { useDemandRecurringCostMutation } from 'gql/__generated__/useDemandRecurringCostMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { graphql } from 'relay-runtime';

export const useDemandRecurringCost = () => {
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useDemandRecurringCostMutation>(
    graphql`
      mutation useDemandRecurringCostMutation(
        $id: ID!
      ) {
        deleteDemandRecurringCost(id: $id) 
      }
    `,
  );

  const deleteDemandRecurringCost = (id: string, callback?: () => void) => {
    commitMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        toast({
          title: '継続費用を削除しました',
          status: 'success',
        });
        callback && callback();
      },
    });
  };

  return { deleteDemandRecurringCost, isMutationInFlight };
};
