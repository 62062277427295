import { captureException } from '@sentry/react';
import { getOrRefreshFirebaseToken } from 'hooks/useRegularlyRefreshFirebaseToken';
import { toast } from 'lib/toast';
import { request } from 'utils/api';
import { setRedirectUrlPath } from 'utils/browserStorage';

export const useLinkageFreee = () => {
  const onLinkageFreeeApi = async () => {
    setRedirectUrlPath(location.pathname);
    try {
      const idToken = await getOrRefreshFirebaseToken();
      if (!idToken) {
        throw new Error('Failed to get token');
      }
      const res = await request.get('/oauth/freee/authorize', idToken.token);
      const { url } = await new Response(res.body).json();
      window.location.href = url;
    } catch (error) {
      toast({
        title: 'エラーが発生しました',
        status: 'error',
      });
      captureException(error as Error);
    }
  };

  return {
    onLinkageFreeeApi,
  };
};
