// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file service/monthly_checking_report/v1/export.proto (package service.monthly_checking_report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ExportRequest, ExportResponse } from "./export_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service service.monthly_checking_report.v1.MonthlyCheckingReportService
 */
export const MonthlyCheckingReportService = {
  typeName: "service.monthly_checking_report.v1.MonthlyCheckingReportService",
  methods: {
    /**
     * 請求突合リストをエクスポートする
     *
     * @generated from rpc service.monthly_checking_report.v1.MonthlyCheckingReportService.Export
     */
    export: {
      name: "Export",
      I: ExportRequest,
      O: ExportResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

