/**
 * @generated SignedSource<<2f723d57823f2798487029c7fbe327ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EstimationRequestDetailType = "item" | "%future added value";
export type EstimationResponseDetailType = "item" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type new_EstimationNewPageFragment$data = {
  readonly assignees?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly estimationRequest: {
          readonly company: {
            readonly id: string;
            readonly name: string;
          };
          readonly details: ReadonlyArray<{
            readonly item: {
              readonly id: string;
              readonly name: string;
              readonly quantity: any;
              readonly specJSON: any;
            } | null;
            readonly type: EstimationRequestDetailType;
          }> | null;
          readonly id: string;
          readonly internalAssignees: ReadonlyArray<{
            readonly user: {
              readonly id: string;
              readonly profile: ReadonlyArray<{
                readonly firstName: string;
                readonly lastName: string;
              }> | null;
            };
          }> | null;
        };
        readonly id: string;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
      } | null;
    } | null> | null;
  };
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber: string | null;
    readonly userID: string;
  };
  readonly estimationResponses?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assignee: {
          readonly estimationRequest: {
            readonly company: {
              readonly id: string;
              readonly name: string;
            };
            readonly id: string;
            readonly internalAssignees: ReadonlyArray<{
              readonly user: {
                readonly id: string;
                readonly profile: ReadonlyArray<{
                  readonly firstName: string;
                  readonly lastName: string;
                }> | null;
              };
            }> | null;
          };
          readonly supplier: {
            readonly id: string;
            readonly name: string;
          };
        };
        readonly details: ReadonlyArray<{
          readonly item: {
            readonly id: string;
            readonly name: string;
            readonly quantity: any;
            readonly specJSON: any;
            readonly unitPrice: number;
          } | null;
          readonly type: EstimationResponseDetailType;
        }> | null;
        readonly id: string;
        readonly importantNotes: string;
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "new_EstimationNewPageFragment";
};
export type new_EstimationNewPageFragment$key = {
  readonly " $data"?: new_EstimationNewPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"new_EstimationNewPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specJSON",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "EstimationRequestInternalAssignee",
  "kind": "LinkedField",
  "name": "internalAssignees",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": true,
          "selections": [
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "assigneeId"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isSkipAssignee"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isSkipResponse"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "responseId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "new_EstimationNewPageFragment",
  "selections": [
    {
      "condition": "isSkipResponse",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "responseId"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "EstimationResponseConnection",
          "kind": "LinkedField",
          "name": "estimationResponses",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationResponseEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationResponse",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "importantNotes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationResponseDetail",
                      "kind": "LinkedField",
                      "name": "details",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EstimationResponseItem",
                          "kind": "LinkedField",
                          "name": "item",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "unitPrice",
                              "storageKey": null
                            },
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationRequestAssignee",
                      "kind": "LinkedField",
                      "name": "assignee",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EstimationRequest",
                          "kind": "LinkedField",
                          "name": "estimationRequest",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v7/*: any*/),
                            (v10/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryConnection",
      "kind": "LinkedField",
      "name": "taxCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isSkipAssignee",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "assignees",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "assigneeId"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "EstimationRequestAssigneeConnection",
          "kind": "LinkedField",
          "name": "estimationRequestAssignees",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationRequestAssigneeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequestAssignee",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationRequest",
                      "kind": "LinkedField",
                      "name": "estimationRequest",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v7/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EstimationRequestDetail",
                          "kind": "LinkedField",
                          "name": "details",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "EstimationRequestItem",
                              "kind": "LinkedField",
                              "name": "item",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v2/*: any*/),
                                (v3/*: any*/),
                                (v4/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userID",
          "storageKey": null
        },
        (v9/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b16282b01fd31fd52be3b73bffdae5f8";

export default node;
