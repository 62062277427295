import { Box, Button, HStack, Table, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { DemandRecurringCostFormType } from 'features/demandRecurringCost/form';
import { useFormContext } from 'react-hook-form';
import { DemandRecurringCostTable } from '../DemandRecurringCostTable';

type Props = {
  onSubmit: (data: DemandRecurringCostFormType) => void;
  isSubmitting: boolean;
};

export const DemandRecurringCostConfirm = ({ onSubmit }: Props) => {
  const form = useFormContext<DemandRecurringCostFormType>();
  const {
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const { name, supplier, memo, prices, note } = getValues();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w="1024px" pb={8}>
        <VStack spacing={4} alignItems="flex-start" justifyContent="space-between">
          <Table w="100%">
            <Thead>
              <Tr>
                <Th bg="gray.50" w="50%">
                  費用名
                </Th>
                <Th bg="gray.50" w="50%">
                  補足
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr fontSize="sm">
                <Td>{name}</Td>
                <Td>{note}</Td>
              </Tr>
            </Tbody>
          </Table>
          <VStack spacing={4} alignItems="flex-start" justifyContent="space-between" w="100%">
            <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%">
              <VStack spacing={3} alignItems="flex-start" fontSize="sm">
                <HStack spacing={2}>
                  <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right">
                    サプライヤー
                  </Box>
                  <Box>{supplier.name}</Box>
                </HStack>
                <HStack spacing={2}>
                  <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right">
                    カテゴリー
                  </Box>
                  <Box>費用</Box>
                </HStack>
                <HStack spacing={2} alignItems="flex-start">
                  <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right">
                    社内メモ
                  </Box>
                  <Box whiteSpace="pre-wrap">{memo}</Box>
                </HStack>
              </VStack>
              <DemandRecurringCostTable
                values={prices.map((price, index) => ({
                  id: index.toString(),
                  quantity: price.quantity || 0,
                  unitPrice: price.unitPrice || 0,
                  unitSellingPrice: price.unitSellingPrice || 0,
                }))}
              />
            </HStack>
            <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
              登録
            </Button>
          </VStack>
        </VStack>
      </Box>
    </form>
  );
};
