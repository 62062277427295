// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file service/item/v1/item.proto (package service.item.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { CategoryItemsSummary, RawItem } from "../../../model/item/v1/summarize_item_pb.js";
import { SortedSpec, SortedSpecType, SpecOnlyItem } from "../../../model/item/v1/spec_pb.js";

/**
 * @generated from message service.item.v1.SummarizeItemsRequest
 */
export class SummarizeItemsRequest extends Message<SummarizeItemsRequest> {
  /**
   * @generated from field: repeated model.item.v1.RawItem items = 1;
   */
  items: RawItem[] = [];

  constructor(data?: PartialMessage<SummarizeItemsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.item.v1.SummarizeItemsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: RawItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummarizeItemsRequest {
    return new SummarizeItemsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummarizeItemsRequest {
    return new SummarizeItemsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummarizeItemsRequest {
    return new SummarizeItemsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SummarizeItemsRequest | PlainMessage<SummarizeItemsRequest> | undefined, b: SummarizeItemsRequest | PlainMessage<SummarizeItemsRequest> | undefined): boolean {
    return proto3.util.equals(SummarizeItemsRequest, a, b);
  }
}

/**
 * @generated from message service.item.v1.SummarizeItemsResponse
 */
export class SummarizeItemsResponse extends Message<SummarizeItemsResponse> {
  /**
   * @generated from field: repeated model.item.v1.CategoryItemsSummary items = 1;
   */
  items: CategoryItemsSummary[] = [];

  constructor(data?: PartialMessage<SummarizeItemsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.item.v1.SummarizeItemsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: CategoryItemsSummary, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummarizeItemsResponse {
    return new SummarizeItemsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummarizeItemsResponse {
    return new SummarizeItemsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummarizeItemsResponse {
    return new SummarizeItemsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SummarizeItemsResponse | PlainMessage<SummarizeItemsResponse> | undefined, b: SummarizeItemsResponse | PlainMessage<SummarizeItemsResponse> | undefined): boolean {
    return proto3.util.equals(SummarizeItemsResponse, a, b);
  }
}

/**
 * @generated from message service.item.v1.ConvertToSortedSpecRequest
 */
export class ConvertToSortedSpecRequest extends Message<ConvertToSortedSpecRequest> {
  /**
   * @generated from field: model.item.v1.SortedSpecType type = 1;
   */
  type = SortedSpecType.UNSPECIFIED;

  /**
   * @generated from field: repeated model.item.v1.SpecOnlyItem items = 2;
   */
  items: SpecOnlyItem[] = [];

  constructor(data?: PartialMessage<ConvertToSortedSpecRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.item.v1.ConvertToSortedSpecRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(SortedSpecType) },
    { no: 2, name: "items", kind: "message", T: SpecOnlyItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertToSortedSpecRequest {
    return new ConvertToSortedSpecRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertToSortedSpecRequest {
    return new ConvertToSortedSpecRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertToSortedSpecRequest {
    return new ConvertToSortedSpecRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertToSortedSpecRequest | PlainMessage<ConvertToSortedSpecRequest> | undefined, b: ConvertToSortedSpecRequest | PlainMessage<ConvertToSortedSpecRequest> | undefined): boolean {
    return proto3.util.equals(ConvertToSortedSpecRequest, a, b);
  }
}

/**
 * @generated from message service.item.v1.ConvertToSortedSpecResponse
 */
export class ConvertToSortedSpecResponse extends Message<ConvertToSortedSpecResponse> {
  /**
   * @generated from field: repeated model.item.v1.SortedSpec sorted_specs = 1;
   */
  sortedSpecs: SortedSpec[] = [];

  constructor(data?: PartialMessage<ConvertToSortedSpecResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.item.v1.ConvertToSortedSpecResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sorted_specs", kind: "message", T: SortedSpec, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertToSortedSpecResponse {
    return new ConvertToSortedSpecResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertToSortedSpecResponse {
    return new ConvertToSortedSpecResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertToSortedSpecResponse {
    return new ConvertToSortedSpecResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertToSortedSpecResponse | PlainMessage<ConvertToSortedSpecResponse> | undefined, b: ConvertToSortedSpecResponse | PlainMessage<ConvertToSortedSpecResponse> | undefined): boolean {
    return proto3.util.equals(ConvertToSortedSpecResponse, a, b);
  }
}

