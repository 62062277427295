export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return '0Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};

export const twoBytesToSingleByteNumber = (value: string) => {
  if (!value.match(/[^0-9]/g)) {
    return Number(value);
  }

  const stringNumber = value
    .toString()
    .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
  return Number(stringNumber);
};
