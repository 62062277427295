import { UseMutationConfig, useMutation } from 'react-relay';
import {
  Disposable,
  GraphQLTaggedNode,
  IEnvironment,
  MutationConfig,
  MutationParameters,
} from 'relay-runtime';

export const useMutationWrapper = <T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  commitMutationFn?: (environment: IEnvironment, config: MutationConfig<T>) => Disposable,
) => {
  const [_commitMutation, isMutationInFlight] = useMutation<T>(mutation, commitMutationFn);

  const commitMutation = (config: UseMutationConfig<T>): Disposable =>
    _commitMutation({
      ...config,
      onError: (error) => {
        config.onError && config.onError(error);
      },
    });

  return { commitMutation, isMutationInFlight };
};
