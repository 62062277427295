import { DemandItemDetailBlockHeader } from 'features/demandItem/components/DemandItemDetailBlock';
import {
  DEMAND_ITEM_CATEGORY,
  DemandItemCardboard,
  DemandItemFlexiblePackage,
  DemandItemGiftBox,
  DemandItemOther,
  DemandItemPaperBag,
} from 'features/demandItem/models';
import { DemandItemDetailBlocksHeaderFragment$key } from 'gql/__generated__/DemandItemDetailBlocksHeaderFragment.graphql';

import { graphql, useFragment } from 'react-relay';

const DemandItemDetailBlocksHeaderFragment = graphql`
  fragment DemandItemDetailBlocksHeaderFragment on DemandItem {
    name
    category
    cardboard {
      __typename
      size
      type
      material
      thickness
      printingColor
      processing
      other
    }
    flexiblePackage {
      __typename
      size
      type
      material
      printingColor
      processing
      other
    }
    giftBox {
      __typename
      size
      type
      paperType
      printingColor
      processing
      other
    }
    paperBag {
      __typename
      size
      paperType
      printingColor
      processing
      handle
      other
    }
    other {
      __typename
      specText
    }
  }
`;

type Props = {
  queryRef: DemandItemDetailBlocksHeaderFragment$key;
};

export const DemandItemDetailBlocksHeader = ({ queryRef }: Props) => {
  const item = useFragment(DemandItemDetailBlocksHeaderFragment, queryRef);

  return (
    <>
      {item.category === DEMAND_ITEM_CATEGORY.Cardboard && (
        <DemandItemDetailBlockHeader<DemandItemCardboard>
          category={DEMAND_ITEM_CATEGORY.Cardboard}
          itemName={item.name}
          values={item.cardboard as DemandItemCardboard}
        />
      )}
      {item.category === DEMAND_ITEM_CATEGORY.FlexiblePackage && (
        <DemandItemDetailBlockHeader<DemandItemFlexiblePackage>
          category={DEMAND_ITEM_CATEGORY.FlexiblePackage}
          itemName={item.name}
          values={item.flexiblePackage as DemandItemFlexiblePackage}
        />
      )}
      {item.category === DEMAND_ITEM_CATEGORY.GiftBox && (
        <DemandItemDetailBlockHeader<DemandItemGiftBox>
          category={DEMAND_ITEM_CATEGORY.GiftBox}
          itemName={item.name}
          values={item.giftBox as DemandItemGiftBox}
        />
      )}
      {item.category === DEMAND_ITEM_CATEGORY.PaperBag && (
        <DemandItemDetailBlockHeader<DemandItemPaperBag>
          category={DEMAND_ITEM_CATEGORY.PaperBag}
          itemName={item.name}
          values={item.paperBag as DemandItemPaperBag}
        />
      )}
      {item.category === DEMAND_ITEM_CATEGORY.Other && (
        <DemandItemDetailBlockHeader<DemandItemOther>
          category={DEMAND_ITEM_CATEGORY.Other}
          itemName={item.name}
          values={item.other as DemandItemOther}
        />
      )}
    </>
  );
};
