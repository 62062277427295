/**
 * @generated SignedSource<<65bbdac749f9f8fc7c63d5bfd70c5956>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type layout_RecurringCostEditLayoutQuery$variables = {
  code: string;
  costNumber: any;
};
export type layout_RecurringCostEditLayoutQuery$data = {
  readonly companies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly demandRecurringCosts: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly internalMemos: ReadonlyArray<{
                readonly body: string;
              }> | null;
              readonly name: string;
              readonly note: string;
              readonly prices: ReadonlyArray<{
                readonly id: string;
                readonly quantity: any;
                readonly unitPrice: number;
                readonly unitSellingPrice: number;
              }> | null;
              readonly supplier: {
                readonly id: string;
                readonly name: string;
              } | null;
              readonly taxCategoryID: string;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
};
export type layout_RecurringCostEditLayoutQuery = {
  response: layout_RecurringCostEditLayoutQuery$data;
  variables: layout_RecurringCostEditLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "costNumber"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "costNumber",
        "variableName": "costNumber"
      },
      {
        "kind": "Literal",
        "name": "isCurrent",
        "value": true
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxCategoryID",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "DemandRecurringCostPrice",
  "kind": "LinkedField",
  "name": "prices",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitSellingPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "layout_RecurringCostEditLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "DemandRecurringCostConnection",
                    "kind": "LinkedField",
                    "name": "demandRecurringCosts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DemandRecurringCostEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DemandRecurringCost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v3/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DemandRecurringCostInternalMemo",
                                "kind": "LinkedField",
                                "name": "internalMemos",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "layout_RecurringCostEditLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "DemandRecurringCostConnection",
                    "kind": "LinkedField",
                    "name": "demandRecurringCosts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DemandRecurringCostEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DemandRecurringCost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v3/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DemandRecurringCostInternalMemo",
                                "kind": "LinkedField",
                                "name": "internalMemos",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37057157f12798a16074760d2c56ec43",
    "id": null,
    "metadata": {},
    "name": "layout_RecurringCostEditLayoutQuery",
    "operationKind": "query",
    "text": "query layout_RecurringCostEditLayoutQuery(\n  $code: String!\n  $costNumber: Uint32!\n) {\n  companies(where: {code: $code}) {\n    edges {\n      node {\n        id\n        name\n        demandRecurringCosts(where: {costNumber: $costNumber, isCurrent: true}) {\n          edges {\n            node {\n              id\n              supplier {\n                id\n                name\n              }\n              name\n              note\n              internalMemos {\n                body\n                id\n              }\n              taxCategoryID\n              prices {\n                id\n                unitPrice\n                unitSellingPrice\n                quantity\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ada7dbf5d56ac1f2fcc9d6dd2650a52";

export default node;
