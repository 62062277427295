export const concatFullName = ({
  lastName,
  firstName,
}: {
  lastName: string;
  firstName: string;
}) => {
  if (firstName && lastName) {
    return `${lastName} ${firstName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else {
    return '-';
  }
};
