import { useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Variables } from 'relay-runtime';
import { useQueryParams } from './useInvoiceQueryPrams';

export const getInvoiceWhere = (userId?: string, demandId?: string) => {
  const conditions: Array<Record<string, Record<string, string> | string>> = [];

  if (demandId) {
    conditions.push({ companyID: demandId });
  }

  if (userId) {
    conditions.push({ hasUpdatedByWith: { id: userId } });
  }

  if (conditions.length === 0) return {};

  return {
    where: {
      and: conditions,
    },
  };
};

export const useInvoiceFilter = (refetch: (vars: Partial<Variables>) => void) => {
  const { updateQueryParams } = useQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPending, startTransition] = useTransition();

  const onRefetch = ({ userId, demandId }: { userId: string; demandId: string }) => {
    startTransition(() => {
      refetch(getInvoiceWhere(userId, demandId));
    });
  };

  const onClearInput = () => {
    const creatorId = searchParams.get('creator');
    searchParams.delete('demand');
    onRefetch({ demandId: '', userId: creatorId || '' });
    setSearchParams(searchParams);
    updateQueryParams({ demand: '' });
  };

  const onChangeUser = (value: string) => {
    const params = {} as { [key: string]: string };
    const demandId = searchParams.get('demand');
    if (demandId) params.demand = demandId;
    if (value) params.creator = value;

    onRefetch({ demandId: params.demand, userId: value });
    setSearchParams(params);
    updateQueryParams({ creator: value });
  };

  const onChangeCompany = (value: { id: string; name: string } | null | undefined) => {
    const params = {} as { [key: string]: string };
    const creatorId = searchParams.get('creator');
    const demandId = searchParams.get('demand');
    if (creatorId) params.creator = creatorId;
    if (value) params.demand = value.id;

    if (value?.id !== demandId) {
      onRefetch({ demandId: params.demand, userId: params.creator });
      setSearchParams(params);
      updateQueryParams({ demand: value?.id || '' });
    }
  };

  return {
    onClearInput,
    onChangeUser,
    onChangeCompany,
    isPending,
  };
};
