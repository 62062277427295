import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Table: ComponentStyleConfig = {
  sizes: {
    sm: {
      th: {
        px: '4',
        py: '1',
        lineHeight: '4',
        fontSize: 'xs',
      },
      td: {
        px: '4',
        py: '2',
        fontSize: 'sm',
        lineHeight: '4',
      },
      caption: {
        px: '4',
        py: '2',
        fontSize: 'xs',
      },
    },
    md: {
      th: {
        px: '4',
        py: '3',
        lineHeight: '4',
        fontSize: 'xs',
      },
      td: {
        px: '4',
        py: '3',
        lineHeight: '5',
      },
      caption: {
        px: '6',
        py: '2',
        fontSize: 'sm',
      },
    },
    lg: {
      th: {
        px: '8',
        py: '4',
        lineHeight: '5',
        fontSize: 'sm',
      },
      td: {
        px: '8',
        py: '5',
        lineHeight: '6',
      },
      caption: {
        px: '6',
        py: '2',
        fontSize: 'md',
      },
    },
  },
};
