import { formatBytes } from 'utils/number';
import { errorMessages, quantitySchema } from 'utils/validation';
import { z } from 'zod';

// ファイルの最大合計サイズ: 10MB
const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const estimationRequestItemSchema = z.object({
  name: z.string().min(1).max(255),
  specJson: z.map(z.string(), z.any()).optional(),
  quantity: quantitySchema(),
});

export type ZodEstimationRequestItem = z.infer<typeof estimationRequestItemSchema>;

export const estimationRequestSupplierSchema = z.object({
  id: z.string().min(1, { message: '見積先サプライヤーを入力しましょう' }),
  name: z.string(),
  contacts: z
    .array(
      z.object({
        id: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        email: z.string(),
        memo: z.string().optional(),
      }),
    )
    .min(1, { message: '見積依頼を送る担当者を選択しましょう' }),
  headerMessage: z
    .string()
    .min(1, { message: 'メッセージを入力しましょう' })
    .max(2_000, { message: errorMessages.MAX_LENGTH(2_000) }),
  footerMessage: z.string().max(2_000, { message: errorMessages.MAX_LENGTH(2_000) }),
  defaultDetailMessage: z
    .string()
    .max(2_000, { message: errorMessages.MAX_LENGTH(2_000) })
    .optional(),
});

export const estimationRequestSuppliersSchema = z.array(estimationRequestSupplierSchema);

export const createEstimationRequestSchema = z.object({
  company: z.object({
    id: z.string().min(1, { message: 'デマンドを入力しましょう' }),
    name: z.string(),
  }),
  specText: z.string().max(500, { message: errorMessages.MAX_LENGTH(500) }),
  requestTitle: z.string().max(70, { message: errorMessages.MAX_LENGTH(70) }),
  mailSubject: z.string().max(70, { message: errorMessages.MAX_LENGTH(70) }),
  items: z.array(estimationRequestItemSchema).min(1, { message: '見積条件を入力しましょう' }),
  suppliers: estimationRequestSuppliersSchema,
  attachments: z.instanceof(FileList).refine(
    (files) => {
      const totalFileBytes = Array.from(files).reduce((total, file) => {
        return total + file.size;
      }, 0);
      return totalFileBytes <= MAX_FILE_SIZE;
    },
    `ファイルの合計サイズは ${formatBytes(MAX_FILE_SIZE)} 以下にしましょう`,
  ),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
});

export const addAssigneesToEstimationRequestFormSchema = z.object({
  id: z.string(),
  specText: z.string().max(500, { message: errorMessages.MAX_LENGTH(500) }),
  mailSubject: z.string().max(70, { message: errorMessages.MAX_LENGTH(70) }),
  suppliers: estimationRequestSuppliersSchema,
});

export type ZodEstimationRequestNewFormData = z.infer<typeof createEstimationRequestSchema>;

export type ZodEstimationRequestAddAssigneesFormData = z.infer<
  typeof addAssigneesToEstimationRequestFormSchema
>;

export type ZodEstimationRequestSupplierFormElementData = z.infer<
  typeof estimationRequestSupplierSchema
>;

export type ZodEstimationRequestSupplierFormData = z.infer<typeof estimationRequestSuppliersSchema>;
