import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { InformationShizai } from 'components/InformationShizai';
import { convertItemFormat } from 'features/estimation/util';
import { EstimationPreviewFragment$key } from 'gql/__generated__/EstimationPreviewFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';
import { deduplicateAdjacentNames } from '../helper';

const EstimationPreviewFragment = graphql`
  fragment EstimationPreviewFragment on Estimation
  {
    id
    company {
      name
    }
    detailMessage
    expirationPeriod
    showAmountSummary
    createdAt
    previewDetails: details(orderBy: {direction: ASC, field: ORDER_NUMBER}, where: {documentDisplayStatus: shown}) {
      edges {
        node {
          type
          orderNumber
          documentDisplayStatus
          item {
            id
            name
            unitPrice
            quantity
            unitSellingPrice
            taxCategory {
              id
              rate
            }
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: EstimationPreviewFragment$key;
};

export const EstimationPreview = ({ queryRef }: Props) => {
  const { company, createdAt, expirationPeriod, detailMessage, showAmountSummary, previewDetails } =
    useFragment(EstimationPreviewFragment, queryRef);

  const orders = [...(previewDetails.edges || [])]
    .map((edge) => {
      if (edge?.node?.item !== null && edge?.node?.item !== undefined) {
        return convertItemFormat(edge?.node?.item);
      }
    })
    .filter((v): v is NonNullable<typeof v> => v != null);

  const items = deduplicateAdjacentNames(orders);

  return (
    <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full">
      <VStack align="stretch" spacing={0}>
        <HStack align="end" pb="1rem" justify="space-between">
          <Heading as="h2" fontSize={24}>
            見積書
          </Heading>
          <HStack spacing={4}>
            <Text fontSize="sm">{dateFormat(createdAt)}</Text>
          </HStack>
        </HStack>
        <Divider />
      </VStack>
      <HStack pb="1rem" justify="space-between" align="flex-start">
        <VStack spacing={2} w="320px" align="start">
          <Text fontSize="xl" fontWeight="bold">
            {company.name} 御中
          </Text>
          <Text fontSize="sm">有効期限：{expirationPeriod}</Text>
          <Text color="gray.500" fontSize="sm">
            下記のとおりお見積申し上げます
          </Text>
          <VStack spacing={1} w="full" h={41}>
            {showAmountSummary && (
              <>
                <HStack align="end" justify="space-between" w="full">
                  <Text>お見積金額</Text>
                  <HStack spacing={1} align="end">
                    <Text fontSize="2xl" fontWeight="bold">
                      {calculateTotalAmount(orders).toLocaleString()}
                    </Text>
                    <Text>円</Text>
                  </HStack>
                </HStack>
                <Divider borderColor="gray.700" />
              </>
            )}
          </VStack>
        </VStack>
        <Box w="320px">
          <InformationShizai />
        </Box>
      </HStack>
      <VStack align="start" justify="space-between">
        <Table>
          <Thead>
            <Tr>
              <Th color="gray.700" w="100%" pl="0">
                品名
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                単価
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) => (
              <Tr key={`item-${item.id}`} verticalAlign="top">
                <Td pl="0" whiteSpace="pre-wrap">
                  {item.name}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.quantity.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.unitSellingPrice.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700" pr="0">
                  {calculateDetailAmount({
                    quantity: item.quantity,
                    price: item.unitSellingPrice,
                  }).toLocaleString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
      <HStack pb="1rem" justify="space-between" align="flex-start">
        <VStack spacing={2} w="400px" align="start">
          <Text>備考</Text>
          <Text whiteSpace="pre-wrap">{detailMessage}</Text>
        </VStack>
        {showAmountSummary && (
          <VStack align="left" spacing={2} w="240px">
            <Flex mt={2} justify="space-between">
              <Box w="140px" fontSize="sm">
                小計
              </Box>
              <Box fontSize="sm">{calculateSubtotalAmount(orders).toLocaleString()}</Box>
            </Flex>
            <Flex mt={4} justify="space-between">
              <Box whiteSpace="nowrap" w="140px" fontSize="sm">
                消費税
              </Box>
              <Box fontSize="sm">{calculateTotalTaxAmount(orders).toLocaleString()}</Box>
            </Flex>
            <Flex mt={4} justify="space-between">
              <Box w="140px" fontWeight="bold">
                合計金額
              </Box>
              <Box fontWeight="bold">{calculateTotalAmount(orders).toLocaleString()}</Box>
            </Flex>
          </VStack>
        )}
      </HStack>
    </VStack>
  );
};
