import { errorMessages } from 'utils/validation';
import { z } from 'zod';

export const demandFormSchema = z.object({
  name: z.string().max(255, { message: errorMessages.MAX_LENGTH(255) }),
  memo: z
    .string()
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) })
    .optional(),
});

export type DemandFormDataType = z.infer<typeof demandFormSchema>;
