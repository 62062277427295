import { EstimationResponseError } from 'components/EstimationResponseError';
import { EstimationResponseNotFound } from 'components/EstimationResponseNotFound';
import { EstimationResponseThanks } from 'components/EstimationResponseThanks';
import { Loading } from 'components/Loading';
import { EstimationResponseLayout } from 'components/layout/EstimationResponseLayout';
import { EstimationResponseForm } from 'features/estimationResponse/components/EstimationResponseForm';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { useUserTracking } from 'hooks/useUserTracking';
import { toast } from 'lib/toast';
import { EstimationResponseService } from 'proto/service/estimation_response/v1/estimation_response_connect';
import { GetInitialDataByTokenResponse } from 'proto/service/estimation_response/v1/estimation_response_pb';
import { EstimationResponseExternalService } from 'proto/service/estimation_response_external/v1/estimation_response_external_connect';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { captureException } from 'utils/error';

export const EstimationResponsePage = () => {
  useUserTracking();

  const [params, _] = useSearchParams();
  const isInternal = params.get('isInternal');
  const { grpcClient } = useGrpcClient(
    isInternal ? EstimationResponseService : EstimationResponseExternalService,
  );

  const token = params.get('token') || undefined;
  const [isAnswered, setAnswered] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<GetInitialDataByTokenResponse | null>(null);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    if (!token) return;

    (async () => {
      try {
        const d = await grpcClient.getInitialDataByToken({ token });
        setData(d);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [grpcClient, token]);

  const handleSubmit = () => setAnswered(true);

  const navigate = useNavigate();

  const handleClickToForm = () => {
    navigate(0);
  };

  if (error) {
    toast({
      title: 'データの取得に失敗しました。',
      status: 'error',
    });
    captureException(error);
  }

  if (isLoading) return <Loading />;

  return (
    <>
      {!token || !data || error ? (
        <EstimationResponseLayout>
          {error ? <EstimationResponseError /> : <EstimationResponseNotFound />}
        </EstimationResponseLayout>
      ) : isAnswered ? (
        <EstimationResponseLayout>
          <EstimationResponseThanks onClickToForm={handleClickToForm} />
        </EstimationResponseLayout>
      ) : (
        <EstimationResponseForm
          token={token}
          onSubmit={handleSubmit}
          detailMessage={data.detailMessage}
          title={data.title}
          specText={data.specText}
          latestRespondedExpirationPeriod={data.latestRespondedExpirationPeriod}
          latestRespondedImportantNotes={data.latestRespondedImportantNotes}
          items={data.items}
        />
      )}
    </>
  );
};
