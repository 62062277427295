import { profilePageFragment$key } from 'gql/__generated__/profilePageFragment.graphql';
import { useEditProfileMutation } from 'gql/__generated__/useEditProfileMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { Options, RefetchFnDynamic } from 'react-relay/relay-hooks/useRefetchableFragmentNode';
import { useNavigate } from 'react-router-dom';
import { OperationType, graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { ProfileFormDataType } from '../form';

export const useEditProfile = (
  id: string,
  refetch: RefetchFnDynamic<OperationType, profilePageFragment$key, Options>,
) => {
  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useEditProfileMutation>(
    graphql`
      mutation useEditProfileMutation(
        $id: ID!
        $input: UpdateUserProfileInput!
      ) {
        updateUserProfile(id: $id,input: $input) {
          id
        }
      }
    `,
  );

  const onSubmit = (data: ProfileFormDataType) => {
    commitMutation({
      variables: {
        id: id,
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
        },
      },
      onCompleted: () => {
        toast({
          title: 'プロフィールを更新しました',
          status: 'success',
        });
        navigate(paths.root());
        refetch({}, { fetchPolicy: 'network-only' });
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
