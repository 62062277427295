import { createContext, useEffect, useState } from 'react';

type VersionWatcherContext = {
  disableSoftDeleteEffect: () => () => void;
};

const defaultContext: VersionWatcherContext = {
  disableSoftDeleteEffect: () => {
    return () => {
      /* nop */
    };
  },
};

type version = {
  initialized: boolean;
  softReloadVersion: string;
  hardReloadVersion: string;
};

export const VersionWatcherContext = createContext<VersionWatcherContext>(defaultContext);

const REFRESH_INTERVAL = 60_000; // 1分

export const useVersionWatcher = (): VersionWatcherContext => {
  const [disableSoftReload, setDisableSoftReload] = useState<boolean>(false);

  const [currentVersion, setCurrentVersion] = useState<version>({
    initialized: false,
    softReloadVersion: '',
    hardReloadVersion: '',
  });

  useEffect(() => {
    const handle = setInterval(async () => {
      const res = await fetch(`/version.json?ts=${Date.now()}`, { cache: 'no-store' });
      const version = (await res.json()) as version;
      if (!currentVersion.initialized) {
        setCurrentVersion({
          initialized: true,
          softReloadVersion: version.softReloadVersion,
          hardReloadVersion: version.hardReloadVersion,
        });
        return;
      }

      if (currentVersion.softReloadVersion !== version.softReloadVersion) {
        if (!disableSoftReload) {
          window.location.reload();
          return;
        }
      }

      if (currentVersion.hardReloadVersion !== version.hardReloadVersion) {
        window.location.reload();
        return;
      }
    }, REFRESH_INTERVAL);

    // コンポーネントがアンマウントされたらクリアする
    return () => {
      clearInterval(handle);
    };
  }, [currentVersion, disableSoftReload]);

  const disableSoftDeleteEffect = () => {
    setDisableSoftReload(true);
    return () => {
      setDisableSoftReload(false);
    };
  };

  return { disableSoftDeleteEffect };
};
