import { Select } from '@chakra-ui/react';
import { UserSelectBoxQuery as UserSelectBoxQueryType } from 'gql/__generated__/UserSelectBoxQuery.graphql';
import { ChangeEvent } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { concatFullName } from 'utils/label';

type Props = {
  value?: string;
  enableValue?: boolean;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const UserSelectBoxQuery = graphql`
  query UserSelectBoxQuery {
    users {
      edges {
        node {
          id
          profile {
            lastName
            firstName
          }
        }
      }
    }
  }
`;

export const UserSelectBox = ({ onChange, value, enableValue = false }: Props) => {
  const { users } = useLazyLoadQuery<UserSelectBoxQueryType>(
    UserSelectBoxQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  if (!users || !users.edges || users.edges.length === 0) return null;

  return (
    <Select onChange={onChange} {...(enableValue ? { value } : { defaultValue: value })}>
      <option value="">すべて</option>
      {(users.edges || []).map((user, i) => (
        <option key={`user-${user?.node?.id}-${i}`} value={user?.node?.id}>
          {concatFullName({
            lastName: user?.node?.profile?.at(0)?.lastName || '',
            firstName: user?.node?.profile?.at(0)?.firstName || '',
          })}
        </option>
      ))}
    </Select>
  );
};
