import { Box, Flex } from '@chakra-ui/react';
import { SalesOrderFormValuesType } from 'features/salesOrders/form';
import { Control, useWatch } from 'react-hook-form';
import {
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalGrossProfitAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

export const SalesOrderFormDetailPrices = ({
  control,
}: { control: Control<SalesOrderFormValuesType> }) => {
  const details = useWatch({ name: 'details', control });

  const detailsForCalculation = details.map((detail) => ({
    quantity: detail.quantity || 0,
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    tax: detail.tax,
  }));

  return (
    <Box w="240px">
      <Flex mt={2} justify="space-between">
        <Box w="140px" fontSize="sm">
          小計
        </Box>
        <Box fontSize="sm">{calculateSubtotalAmount(detailsForCalculation).toLocaleString()}</Box>
      </Flex>
      <Flex mt={2} justify="space-between">
        <Box
          w="140px"
          color="gray.500"
          fontSize="xs"
          borderColor="gray.200"
          borderStyle="solid"
          borderLeftWidth="2px"
          pl={2}
        >
          粗利
        </Box>
        <Box color="gray.500" fontSize="xs">
          {calculateTotalGrossProfitAmount(detailsForCalculation).toLocaleString()}
        </Box>
      </Flex>
      <Flex mt={4} justify="space-between">
        <Box whiteSpace="nowrap" w="140px" fontSize="sm">
          消費税
        </Box>
        <Box fontSize="sm">{calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}</Box>
      </Flex>
      <Flex mt={4} justify="space-between">
        <Box w="140px" fontWeight="bold">
          合計金額
        </Box>
        <Box fontWeight="bold">{calculateTotalAmount(detailsForCalculation).toLocaleString()}</Box>
      </Flex>
    </Box>
  );
};
