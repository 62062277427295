import {
  DEFAULT_CANCEL_MESSAGE,
  DETAIL_MESSAGE_TEMPLATE,
  FARE_MESSAGE,
  MESSAGE_FOR_ERROR_OF_TAX_CALCULATION,
  getSalesOrderInitialValues,
} from 'features/salesOrders/form';
import { new_SalesOrderNewPageQuery$data } from 'gql/__generated__/new_SalesOrderNewPageQuery.graphql';
import { dateFormatHyphenDate } from 'utils/date';
import { concatFullName } from 'utils/label';

const buildDetailMessage = (message: string) => {
  if (message) {
    let newMessage = `${message}\n${MESSAGE_FOR_ERROR_OF_TAX_CALCULATION}`;

    if (!message.includes(FARE_MESSAGE)) {
      newMessage = `${newMessage}\n${FARE_MESSAGE}`;
    }

    if (!message.includes(DEFAULT_CANCEL_MESSAGE)) {
      newMessage = `${newMessage}\n${DEFAULT_CANCEL_MESSAGE}`;
    }

    return newMessage;
  }

  return DETAIL_MESSAGE_TEMPLATE;
};

export const buildDefaultValue = ({
  estimations,
  tax,
  currentUser,
}: {
  estimations: new_SalesOrderNewPageQuery$data['estimations'];
  tax: new_SalesOrderNewPageQuery$data['taxCategories'];
  currentUser: new_SalesOrderNewPageQuery$data['currentUser'];
}) => {
  const estimation = estimations?.edges?.at(0)?.node;
  if (!estimation) return getSalesOrderInitialValues({ tax: buildTaxValue(tax), currentUser });

  const internalAssignees =
    estimation.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    company: {
      id: estimation.company.id,
      name: estimation.company.name,
    },
    supplier: {
      id: estimation.supplier.id,
      name: estimation.supplier.name,
    },
    title: estimation.title,
    detailMessage: buildDetailMessage(estimation.detailMessage || ''),
    estimationID: estimation.id,
    receivedDate: dateFormatHyphenDate(),
    details: (estimation.details?.edges || [])
      .map((detail) => {
        const item = detail?.node?.item;
        if (detail && item) {
          return {
            name: item.name || '',
            quantity: item.quantity as number | undefined,
            unitPrice: item.unitPrice,
            unitSellingPrice: item.unitSellingPrice,
            tax: {
              id: item.tax.id,
              rate: item.tax.rate,
            },
            fixedDeliveryDate: '',
          };
        }
      })
      .filter((v): v is NonNullable<typeof v> => v != null),
    internalAssignees,
    internalMemo: '',
  };
};

const buildTaxValue = (taxCategories: new_SalesOrderNewPageQuery$data['taxCategories']) => {
  const tax = taxCategories?.edges?.at(0)?.node;

  return {
    id: tax?.id || '',
    rate: tax?.rate || '',
  };
};
