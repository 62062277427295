import { Box, HStack } from '@chakra-ui/react';
import { EfferentFour } from '@icon-park/react';
import { Link } from 'components/Link';
import { Loading } from 'components/Loading';
import { useDemandRecurringCost } from 'features/demandItem/api/useDemandRecurringCost';
import {
  DemandRecurringCostBlock,
  DemandRecurringCostBlockBody,
  DemandRecurringCostBlockFooter,
  DemandRecurringCostBlockHeader,
  DemandRecurringCostBlockInfo,
} from 'features/demandRecurringCost/components/DemandRecurringCostBlock';
import { DemandRecurringCostTable } from 'features/demandRecurringCost/components/DemandRecurringCostTable';
import { DemandRecurringCostBlocksQuery } from 'gql/__generated__/DemandRecurringCostBlocksQuery.graphql';
import { DemandRecurringCostBlocksRefetchFragment$key } from 'gql/__generated__/DemandRecurringCostBlocksRefetchFragment.graphql';
import { useEffect, useRef, useState, useTransition } from 'react';

import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { paths } from 'utils/paths';

// 本来、detail_DemandDetailPageQueryにFragmentを渡して1回のリクエストでデータを取得したいが、
// そのやり方で、useRefetchableFragmentを使用するとエラーになる。
// そのため、Fragmentを親コンポーネントに渡さず、DemandItemDetailBlocks内でQueryを発行しデータを取得するようにしている。
const demandRecurringCostBlocksQuery = graphql`
  query DemandRecurringCostBlocksQuery  ($demandId: ID!) {
    ...DemandRecurringCostBlocksRefetchFragment @arguments(demandId: $demandId)
  }
`;

const demandRecurringCostBlocksRefetchFragment = graphql`
  fragment DemandRecurringCostBlocksRefetchFragment on Query
  @refetchable(queryName: "DemandRecurringCostBlocksRefetchQuery")
  @argumentDefinitions(demandId: {type: "ID"})
  {
    demandRecurringCosts(where: {isCurrent: true, demandID: $demandId}, orderBy: {field: UPDATED_AT, direction: DESC}) {
      edges {
        node {
          id
          name
          note
          costNumber
          supplier {
            name
          }
          internalMemos {
            body
          }
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
          demand {
            code
          }
          orderRecurringCosts(orderBy: { direction: DESC, field: CREATED_AT }, first: 1) {
            edges {
              node {
                orderDetail {
                  order {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  demandId: string;
};

export const DemandRecurringCostBlocks = ({ demandId }: Props) => {
  const query = useLazyLoadQuery<DemandRecurringCostBlocksQuery>(
    demandRecurringCostBlocksQuery,
    { demandId },
    { fetchPolicy: 'network-only' },
  );

  const [data, refetch] = useRefetchableFragment(
    demandRecurringCostBlocksRefetchFragment,
    query as DemandRecurringCostBlocksRefetchFragment$key,
  );
  const { demandRecurringCosts } = data;

  const { deleteDemandRecurringCost } = useDemandRecurringCost();

  const [isPending, startTransition] = useTransition();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (wrapperRef.current) {
      setHeight(wrapperRef.current.offsetHeight);
    }
  }, []);

  if (
    !demandRecurringCosts ||
    !demandRecurringCosts.edges ||
    demandRecurringCosts.edges.length === 0 ||
    !demandRecurringCosts.edges[0]?.node ||
    demandRecurringCosts.edges[0]?.node === null
  )
    return null;

  const costs = demandRecurringCosts.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  const handleDeleteCallback = () => {
    startTransition(() => {
      refetch({ demandId }, { fetchPolicy: 'network-only' });
    });
  };

  return (
    <Box ref={wrapperRef}>
      {isPending ? (
        <Box width="100%" textAlign="center" mt={40} h={height}>
          <Loading />
        </Box>
      ) : (
        costs.map((cost) => {
          const order = cost.orderRecurringCosts.edges?.[0]?.node?.orderDetail?.order;
          return (
            <DemandRecurringCostBlock key={cost.id}>
              <DemandRecurringCostBlockHeader itemName={cost.name} value={cost.note || '-'} />
              <DemandRecurringCostBlockBody>
                <DemandRecurringCostBlockInfo
                  values={[
                    {
                      label: 'サプライヤー',
                      node: cost.supplier?.name || '-',
                    },
                    {
                      label: 'カテゴリー',
                      node: '費用',
                    },
                    {
                      label: '最終発注',
                      node: order ? (
                        <Link to={paths.order._id(order.id)} target="_blank">
                          <HStack gap={1}>
                            <Box>{order.title}</Box>
                            <EfferentFour theme="outline" />
                          </HStack>
                        </Link>
                      ) : (
                        '-'
                      ),
                    },
                    {
                      label: '社内メモ',
                      node: cost.internalMemos?.[0]?.body || '-',
                    },
                  ]}
                />

                <DemandRecurringCostTable
                  values={(cost.prices || []).map((price) => ({
                    id: price.id,
                    quantity: price.quantity,
                    unitPrice: price.unitPrice,
                    unitSellingPrice: price.unitSellingPrice,
                  }))}
                />
              </DemandRecurringCostBlockBody>
              <DemandRecurringCostBlockFooter
                editPath={paths.demands
                  ._demandCode(cost.demand.code)
                  .recurringCosts._costNumber(cost.costNumber)
                  .edit.url()}
                onDelete={(callback) => {
                  deleteDemandRecurringCost(cost.id, () => {
                    callback && callback();
                    handleDeleteCallback();
                  });
                }}
                hasOrder={!!order}
              />
            </DemandRecurringCostBlock>
          );
        })
      )}
    </Box>
  );
};
