/**
 * @generated SignedSource<<a8479a726fa32e7b432e63c3780990e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrderAssigneeRecipientType = "cc" | "to" | "%future added value";
export type OrderInput = {
  companyID: string;
  deliveryInfo?: OrderDeliveryInfoInput | null;
  detailMessage?: string | null;
  internalAssignees?: ReadonlyArray<CreateOrderInternalAssigneeInput> | null;
  items: ReadonlyArray<OrderDetailInput>;
  mailBodyMessage?: string | null;
  salesOrderID?: string | null;
  supplierContacts?: ReadonlyArray<OrderSupplieerContactInput> | null;
  supplierID: string;
  title: string;
};
export type OrderDeliveryInfoInput = {
  desiredDeliveryDate?: string | null;
  recipientsText?: string | null;
};
export type CreateOrderInternalAssigneeInput = {
  userID: string;
};
export type OrderDetailInput = {
  name: string;
  orderNumber: any;
  quantity: number;
  salesOrderDetailID?: string | null;
  taxCategoryID: string;
  unitPrice: number;
};
export type OrderSupplieerContactInput = {
  ID: string;
  recipientType: OrderAssigneeRecipientType;
};
export type useOrderFormMutation$variables = {
  input: OrderInput;
};
export type useOrderFormMutation$data = {
  readonly createOrder: {
    readonly id: string;
  };
};
export type useOrderFormMutation = {
  response: useOrderFormMutation$data;
  variables: useOrderFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Order",
    "kind": "LinkedField",
    "name": "createOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrderFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useOrderFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3d078fad9f0d0b5d4437fdfa87457124",
    "id": null,
    "metadata": {},
    "name": "useOrderFormMutation",
    "operationKind": "mutation",
    "text": "mutation useOrderFormMutation(\n  $input: OrderInput!\n) {\n  createOrder(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e648bfac75f3d641bddc275c782c3215";

export default node;
