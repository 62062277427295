/**
 * @generated SignedSource<<d38d5e91f1239cb807d99c4bf1180aff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationRequestItemMessageFragment$data = {
  readonly specText: string;
  readonly " $fragmentType": "EstimationRequestItemMessageFragment";
};
export type EstimationRequestItemMessageFragment$key = {
  readonly " $data"?: EstimationRequestItemMessageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationRequestItemMessageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationRequestItemMessageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specText",
      "storageKey": null
    }
  ],
  "type": "EstimationRequest",
  "abstractKey": null
};

(node as any).hash = "4a5a576c0c83216b04f3edc82651e95a";

export default node;
