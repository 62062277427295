import { EstimationPreviewFragment$data } from 'gql/__generated__/EstimationPreviewFragment.graphql';
import { new_EstimationNewPageFragment$data } from 'gql/__generated__/new_EstimationNewPageFragment.graphql';
import { extractItemFromDetail } from 'utils/detail';
import { concatFullName } from 'utils/label';
import { calculateGrossProfitUnitPrice } from 'utils/priceCalculation';
import {
  DEFAULT_CANCEL_MESSAGE,
  DEFAULT_DETAIL_MESSAGE,
  DEFAULT_EXPIRATION_PERIOD,
} from '../constant';
import {
  DOCUMENT_DISPLAY_STATUS,
  EstimationNodeType,
  EstimationRequestAssigneeNodeType,
  EstimationResponseNodeType,
} from '../type';
import { ZodEstimationDetail, ZodEstimationFormData } from '../zod';

type EstimationDetailType = NonNullable<EstimationPreviewFragment$data['previewDetails']>;
type EstimationDetailEdgesType = NonNullable<EstimationDetailType['edges']>;
type EstimationDetailEdgeType = NonNullable<EstimationDetailEdgesType[number]>;
type EstimationDetailNodeType = NonNullable<EstimationDetailEdgeType['node']>;

export const convertItemFormat = (item: NonNullable<EstimationDetailNodeType['item']>) => {
  return {
    id: item.id,
    name: item.name,
    unitPrice: item.unitPrice,
    quantity: item.quantity,
    unitSellingPrice: item.unitSellingPrice,
    tax: {
      id: item.taxCategory.id,
      rate: item.taxCategory.rate,
    },
  };
};

// 見積依頼から引用する際に見積書フォーム用にデータを変換

const DEFAULT_GROSS_PROFIT_RATE = 1.3;

const buildDetailMessage = (importantNotes: string) => `${DEFAULT_DETAIL_MESSAGE}

${importantNotes}
`;

const convertEstimationResponseToFormStateFormat = ({
  value,
  taxCategories,
}: {
  value: EstimationResponseNodeType;
  taxCategories: NonNullable<NonNullable<new_EstimationNewPageFragment$data['taxCategories']>>;
}) => {
  const company = value.assignee.supplier;
  const internalAssignees =
    value?.assignee.estimationRequest.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    company: {
      id: value.assignee.estimationRequest.company.id,
      name: value.assignee.estimationRequest.company.name,
    },
    supplier: {
      id: company?.id,
      name: company?.name || '',
    },
    title: '',
    detailMessage: buildDetailMessage(value.importantNotes),
    expirationPeriod: DEFAULT_EXPIRATION_PERIOD,
    estimationResponseID: value.id,
    showAmountSummary: false,
    details: buildEstimationResponseItemToDetails(value.details, {
      id: (taxCategories?.edges || [])[0]?.node?.id || '',
      rate: (taxCategories?.edges || [])[0]?.node?.rate || '',
    }),
    internalAssignees,
  };
};

const buildEstimationResponseItemToDetails = (
  details: EstimationResponseNodeType['details'],
  tax: { id: string; rate: string | number },
): ZodEstimationDetail[] => {
  return [
    ...(details || [])
      .map((d) => extractItemFromDetail(d))
      .filter((item): item is NonNullable<typeof item> => item != null)
      .filter((item) => item.quantity !== 0 && item.unitPrice !== 0)
      .map((item) => {
        return {
          responseItemId: item.id,
          name: item.name,
          unitPrice: item.unitPrice,
          unitSellingPrice: calculateGrossProfitUnitPrice({
            unitPrice: item.unitPrice,
            grossProfitMargin: DEFAULT_GROSS_PROFIT_RATE,
          }),
          quantity: item.quantity,
          documentDisplayStatus: DOCUMENT_DISPLAY_STATUS.shown,
          tax,
        };
      }),
  ];
};

const convertEstimationRequestAssigneeToFormStateFormat = ({
  value,
  taxCategories,
}: {
  value: EstimationRequestAssigneeNodeType;
  taxCategories: NonNullable<NonNullable<new_EstimationNewPageFragment$data['taxCategories']>>;
}) => {
  const company = value.supplier;
  const internalAssignees =
    value.estimationRequest.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    company: {
      id: value.estimationRequest.company.id,
      name: value.estimationRequest.company.name,
    },
    supplier: {
      id: company?.id,
      name: company?.name || '',
    },
    title: '',
    detailMessage: buildDetailMessage(''),
    expirationPeriod: DEFAULT_EXPIRATION_PERIOD,
    showAmountSummary: false,
    estimationResponseID: value.id,
    details: buildEstimationRequestItemToDetails(value.estimationRequest.details, {
      id: (taxCategories?.edges || [])[0]?.node?.id || '',
      rate: (taxCategories?.edges || [])[0]?.node?.rate || '',
    }),
    internalAssignees,
  };
};

const buildEstimationRequestItemToDetails = (
  details: EstimationRequestAssigneeNodeType['estimationRequest']['details'],
  tax: { id: string; rate: string | number },
): ZodEstimationDetail[] => {
  return [
    ...(details || [])
      .map((d) => extractItemFromDetail(d))
      .filter((i): i is NonNullable<typeof i> => i !== null)
      .map((item) => {
        return {
          responseItemId: item.id,
          name: item.name,
          unitPrice: undefined,
          unitSellingPrice: undefined,
          quantity: item.quantity,
          documentDisplayStatus: DOCUMENT_DISPLAY_STATUS.shown,
          tax,
        };
      }),
  ];
};

export const isDocumentDisplayStatusShown = (status: string) =>
  status === DOCUMENT_DISPLAY_STATUS.shown;

export const isDocumentDisplayStatusHidden = (status: string) =>
  status === DOCUMENT_DISPLAY_STATUS.hidden;

export const filterByDocumentDisplayStatusShown = (details: ZodEstimationDetail[]) => {
  return details.filter((detail) => isDocumentDisplayStatusShown(detail.documentDisplayStatus));
};

export const buildCreateEstimationFormState = ({
  responseData,
  assigneeData,
  taxCategories,
  currentUser,
}: {
  responseData: EstimationResponseNodeType | null | undefined;
  assigneeData: EstimationRequestAssigneeNodeType | null | undefined;
  taxCategories: NonNullable<NonNullable<new_EstimationNewPageFragment$data['taxCategories']>>;
  currentUser: new_EstimationNewPageFragment$data['currentUser'];
}): ZodEstimationFormData => {
  // 見積依頼から引用する際に回答されていればresponseのデータからフォーム用のフォーマットに変換する
  if (responseData) {
    return convertEstimationResponseToFormStateFormat({
      value: responseData,
      taxCategories,
    });
  }

  // 見積依頼から引用する際に回答されていなければassigneeのrequestデータからフォーム用のフォーマットに変換する
  if (assigneeData) {
    return convertEstimationRequestAssigneeToFormStateFormat({
      value: assigneeData,
      taxCategories,
    });
  }

  // 見積依頼から引用しない場合はデフォルトのフォーム用のフォーマットを返す
  return {
    company: {
      id: '',
      name: '',
    },
    supplier: {
      id: '',
      name: '',
    },
    detailMessage: DEFAULT_DETAIL_MESSAGE,
    expirationPeriod: DEFAULT_EXPIRATION_PERIOD,
    title: '',
    showAmountSummary: false,
    details: [
      {
        responseItemId: '',
        name: '',
        tax: {
          id: (taxCategories?.edges || [])[0]?.node?.id || '',
          rate: (taxCategories?.edges || [])[0]?.node?.rate || 0,
        },
        documentDisplayStatus: DOCUMENT_DISPLAY_STATUS.shown,
      },
    ],
    internalAssignees: [
      {
        value: currentUser.userID,
        label: concatFullName({
          lastName: currentUser.lastName,
          firstName: currentUser.firstName,
        }),
      },
    ],
  };
};

export const buildEditEstimationFormState = ({
  value,
  taxCategories,
  currentUser,
}: {
  value?: EstimationNodeType | null;
  taxCategories: {
    id: string;
    rate: string | number;
  };
  currentUser: {
    userID: string;
    lastName: string;
    firstName: string;
  };
}) => {
  if (!value)
    return {
      company: {
        id: '',
        name: '',
      },
      supplier: {
        id: '',
        name: '',
      },
      detailMessage: DEFAULT_DETAIL_MESSAGE,
      expirationPeriod: DEFAULT_EXPIRATION_PERIOD,
      title: '',
      showAmountSummary: false,
      details: [
        {
          responseItemId: '',
          name: '',
          tax: {
            id: taxCategories.id,
            rate: taxCategories.rate,
          },
          documentDisplayStatus: DOCUMENT_DISPLAY_STATUS.shown,
        },
      ],
      internalAssignees: [
        {
          value: currentUser.userID,
          label: concatFullName({
            lastName: currentUser.lastName,
            firstName: currentUser.firstName,
          }),
        },
      ],
    };

  const internalAssignees =
    value?.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    company: {
      id: value.company.id,
      name: value.company.name,
    },
    supplier: {
      id: value.supplier.id,
      name: value.supplier.name,
    },
    title: value.title,
    detailMessage: value.detailMessage || '',
    estimationResponseID: value.estimationResponse?.assignee.estimationRequest.id,
    expirationPeriod: value.expirationPeriod,
    showAmountSummary: value.showAmountSummary,
    details: [...(value.details.edges || [])]
      .map((edge) => {
        const item = edge?.node?.item;
        const documentDisplayStatus = edge?.node?.documentDisplayStatus || '';
        if (item && documentDisplayStatus) {
          return {
            name: item.name || '',
            unitPrice: item.unitPrice || 0,
            unitSellingPrice: item.unitSellingPrice || 0,
            quantity: item.quantity || 0,
            documentDisplayStatus: isDocumentDisplayStatusHidden(documentDisplayStatus || '')
              ? DOCUMENT_DISPLAY_STATUS.hidden
              : DOCUMENT_DISPLAY_STATUS.shown,
            tax: {
              id: item.taxCategory.id,
              rate: item.taxCategory.rate,
            },
          };
        }
      })
      .filter((v): v is NonNullable<typeof v> => v != null),
    internalAssignees,
  };
};

export const buildDuplicateEstimationFormState = ({
  value,
  taxCategories,
  currentUser,
}: {
  value?: EstimationNodeType | null;
  taxCategories: {
    id: string;
    rate: number;
  };
  currentUser: {
    userID: string;
    lastName: string;
    firstName: string;
  };
}) => {
  if (!value)
    return {
      company: {
        id: '',
        name: '',
      },
      supplier: {
        id: '',
        name: '',
      },
      detailMessage: DEFAULT_DETAIL_MESSAGE,
      expirationPeriod: DEFAULT_EXPIRATION_PERIOD,
      title: '',
      showAmountSummary: false,
      details: [
        {
          responseItemId: '',
          name: '',
          tax: {
            id: taxCategories.id,
            rate: taxCategories.rate,
          },
          documentDisplayStatus: DOCUMENT_DISPLAY_STATUS.shown,
        },
      ],
      internalAssignees: [
        {
          value: currentUser.userID,
          label: concatFullName({
            lastName: currentUser.lastName,
            firstName: currentUser.firstName,
          }),
        },
      ],
    };

  const internalAssignees =
    value?.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  // 既にキャンセルメッセージが入っている場合はキャンセルメッセージをそのまま引き継ぐ
  // キャンセルメッセージが入っていない場合は文末にキャンセルメッセージを追加する
  const detailMessage = value?.detailMessage?.includes(DEFAULT_CANCEL_MESSAGE)
    ? value?.detailMessage
    : `${value?.detailMessage}\n${DEFAULT_CANCEL_MESSAGE}`;

  return {
    company: {
      id: value.company.id,
      name: value.company.name,
    },
    supplier: {
      id: value.supplier.id,
      name: value.supplier.name,
    },
    title: value.title,
    detailMessage: detailMessage || '',
    estimationResponseID: value.estimationResponse?.assignee.estimationRequest.id,
    expirationPeriod: value.expirationPeriod,
    showAmountSummary: value.showAmountSummary,
    details: [...(value.details.edges || [])]
      .map((edge) => {
        const item = edge?.node?.item;
        const documentDisplayStatus = edge?.node?.documentDisplayStatus || '';
        if (item && documentDisplayStatus) {
          return {
            name: item.name || '',
            unitPrice: item.unitPrice || 0,
            unitSellingPrice: item.unitSellingPrice || 0,
            quantity: item.quantity || 0,
            documentDisplayStatus: isDocumentDisplayStatusHidden(documentDisplayStatus || '')
              ? DOCUMENT_DISPLAY_STATUS.hidden
              : DOCUMENT_DISPLAY_STATUS.shown,
            tax: {
              id: item.taxCategory.id,
              rate: item.taxCategory.rate,
            },
          };
        }
      })
      .filter((v): v is NonNullable<typeof v> => v != null),
    internalAssignees,
  };
};
