import { Button, HStack } from '@chakra-ui/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { useDownloadEstimationPdf } from 'features/estimation/hooks/useDownloadEstimationPdf';
import { useEstimationDelete } from 'features/estimation/hooks/useEstimationDelete';
import { EstimationPreviewHeaderFragment$key } from 'gql/__generated__/EstimationPreviewHeaderFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const EstimationPreviewHeaderFragment = graphql`
  fragment EstimationPreviewHeaderFragment on Estimation
  {
    id
    orders {
      id
    }
  }
`;

type Props = {
  queryRef: EstimationPreviewHeaderFragment$key;
};

export const EstimationPreviewHeader = ({ queryRef }: Props) => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { downloadEstimationPdf } = useDownloadEstimationPdf();
  const { onSubmit } = useEstimationDelete();
  const { orders } = useFragment(EstimationPreviewHeaderFragment, queryRef);

  const order = orders ? orders[0] : null;

  return (
    <HStack spacing={4}>
      <ConfirmModalButton
        header="削除しますか？"
        button={({ onOpen }) => <Button onClick={onOpen}>削除</Button>}
        footer={({ onClose }) => (
          <HStack spacing={3}>
            <Button onClick={onClose}>キャンセル</Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                onClose();
                onSubmit(id);
              }}
            >
              削除
            </Button>
          </HStack>
        )}
      />
      <RouterLink to={paths.estimation.duplication._id(id)}>
        <Button>複製</Button>
      </RouterLink>
      {!order && (
        <RouterLink to={paths.estimation.edit._id(id)}>
          <Button colorScheme="blue">編集</Button>
        </RouterLink>
      )}
      <Button colorScheme="blue" onClick={() => downloadEstimationPdf(id)}>
        PDFダウンロード
      </Button>
    </HStack>
  );
};
