import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from '@chakra-ui/react';
import { More, Order, Seal, SendEmail } from '@icon-park/react';
import { Link } from 'components/Link';
import { EstimationTableRowFragment$key } from 'gql/__generated__/EstimationTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const EstimationTableRowFragment = graphql`
  fragment EstimationTableRowFragment on Estimation {
    id
    title
    company {
      id
      name
    }
    supplier {
      id
      name
    }
    createdAt
    estimationResponse {
      assignee {
        estimationRequest {
          id
        }
      }
    }
    orders {
      id
    }
    salesOrders {
      id
    }
    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

export const EstimationTableRow = ({ queryRef }: { queryRef: EstimationTableRowFragment$key }) => {
  const {
    id,
    createdAt,
    company,
    estimationResponse,

    supplier,
    title,
    orders,
    salesOrders,
    internalAssignees: internalAssigneesData,
  } = useFragment(EstimationTableRowFragment, queryRef);
  const order = orders ? orders[0] : null;
  const salesOrder = salesOrders ? salesOrders[0] : null;

  return (
    <Tr>
      <Td>{title || '-'}</Td>
      <Td>
        <Link to={paths.company._id(company.id).url}>{company.name}</Link>
      </Td>
      <Td>
        <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
      </Td>
      <Td whiteSpace="nowrap">{dateFormat(createdAt)}</Td>
      <Td>
        {internalAssigneesData?.map((assignee) =>
          assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
            <Box key={assignee.user.id}>
              {concatFullName({
                lastName: assignee.user.profile[0].lastName,
                firstName: assignee.user.profile[0].firstName,
              })}
            </Box>
          ) : (
            <Box key={assignee.user.id}>-</Box>
          ),
        ) || '-'}
      </Td>
      <Td align="center" textAlign="center">
        {estimationResponse ? (
          <Button
            as={RouterLink}
            w={10}
            h={10}
            borderRadius={50}
            to={paths.estimationRequest._id(estimationResponse.assignee.estimationRequest.id)}
            mx="auto"
          >
            <SendEmail />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>

      <Td align="center" textAlign="center">
        {salesOrder ? (
          <Button
            as={RouterLink}
            w={10}
            h={10}
            borderRadius={50}
            to={paths.salesOrder.id(salesOrder.id).url()}
            mx="auto"
          >
            <Seal />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>

      <Td align="center" textAlign="center">
        {order ? (
          <Button
            as={RouterLink}
            w={10}
            h={10}
            borderRadius={50}
            to={paths.order._id(order.id)}
            mx="auto"
          >
            <Order />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td>
        <Menu>
          <MenuButton>
            <More />
          </MenuButton>
          <MenuList>
            <MenuItem as={RouterLink} to={paths.estimation.edit._id(id)}>
              編集
            </MenuItem>
            <MenuItem as={RouterLink} to={paths.estimation.duplication._id(id)}>
              複製
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="sm" to={paths.estimation._id(id).url}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
