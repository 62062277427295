import { useOrderSendingMutation } from 'gql/__generated__/useOrderSendingMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { RECIPIENT_TYPE } from '../type';
import { OrderSendingFormData } from '../zod';

export const useOrderSending = ({
  orderId,
}: {
  orderId: string;
}) => {
  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useOrderSendingMutation>(
    graphql`
      mutation useOrderSendingMutation(
        $id: ID!
        $input: OrderSendEmailInput!
      ) {
        sendOrderEmail(id: $id, input: $input)
      }
    `,
  );

  const onSubmit = (data?: OrderSendingFormData) => {
    const to = (data?.supplierContactsTo || []).map((contactId) => {
      return {
        ID: contactId,
        recipientType: RECIPIENT_TYPE.to,
      };
    });
    const cc = (data?.supplierContactsCc || []).map((contactId) => {
      return {
        ID: contactId,
        recipientType: RECIPIENT_TYPE.cc,
      };
    });

    commitMutation({
      variables: {
        id: orderId,
        input: {
          mailBodyMessage: data?.mailBodyMessage || '',
          supplierContacts: [...to, ...cc],
        },
      },
      onCompleted: (_) => {
        toast({
          title: '発注書を送信しました',
          status: 'success',
        });
        navigate(paths.order.url());
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
