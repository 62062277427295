/**
 * @generated SignedSource<<4ed18ffc44cc6a20e9ac6430f5fd5f14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceDetailInfoFragment$data = {
  readonly bookedAt: any;
  readonly company: {
    readonly name: string;
  };
  readonly createdAt: any;
  readonly createdBy: {
    readonly profile: ReadonlyArray<{
      readonly firstName: string;
      readonly lastName: string;
    }> | null;
  };
  readonly freeeInvoice: {
    readonly freeeInvoiceID: any;
  } | null;
  readonly id: string;
  readonly paymentDueAt: any;
  readonly salesOrders: ReadonlyArray<{
    readonly estimations: ReadonlyArray<{
      readonly estimationResponse: {
        readonly assignee: {
          readonly estimationRequestID: string;
        };
      } | null;
      readonly id: string;
      readonly title: string;
    }> | null;
    readonly id: string;
    readonly title: string;
  }> | null;
  readonly title: string;
  readonly " $fragmentType": "InvoiceDetailInfoFragment";
};
export type InvoiceDetailInfoFragment$key = {
  readonly " $data"?: InvoiceDetailInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceDetailInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceDetailInfoFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentDueAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FreeeInvoice",
      "kind": "LinkedField",
      "name": "freeeInvoice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeeInvoiceID",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrder",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Estimation",
          "kind": "LinkedField",
          "name": "estimations",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationResponse",
              "kind": "LinkedField",
              "name": "estimationResponse",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequestAssignee",
                  "kind": "LinkedField",
                  "name": "assignee",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimationRequestID",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};
})();

(node as any).hash = "58e14a11863171167e8bb34939d7772a";

export default node;
