import { captureException } from '@sentry/react';
import { getOrRefreshFirebaseToken } from 'hooks/useRegularlyRefreshFirebaseToken';
import { useSession } from 'hooks/useSession';
import { toast } from 'lib/toast';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { request } from 'utils/api';
import { getRedirectUrlPath } from 'utils/browserStorage';

export const OauthFreeeCallbackPage = () => {
  const navigate = useNavigate();
  const [params, _] = useSearchParams();
  const code = params.get('code');
  const { currentUser } = useSession();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    const fetch = async () => {
      try {
        if (!currentUser) {
          // まだsessionの初期化が終わってないorログインしていない場合は何もしない
          return;
        }

        const idToken = await getOrRefreshFirebaseToken();
        if (!idToken) {
          return;
        }

        await request.post(
          '/oauth/freee/token',
          {
            code,
          },
          idToken.token,
        );
        const redirectUrl = getRedirectUrlPath();
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          // ブラウザによってはfreeeの認証URLを別タブで開いてしまうため、sessionStorageから取得できない場合はトップに戻す。
          // 上記挙動を確認できているのは2023/04/20現在Arcブラウザーのみ。
          navigate('/');
        }
        toast({
          title: 'freee連携が完了しました',
          status: 'success',
        });
      } catch (error) {
        toast({
          title: 'エラーが発生しましたので再度送信してください',
          status: 'error',
        });
        captureException(error as Error);
      }
    };
    fetch();
  }, [currentUser]);

  return null;
};
