import { Center, Checkbox, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { InvoiceSalesOrdersSelectFormValueType } from 'features/invoice/form';
import { detailNumber } from 'features/order/util';
import { InvoiceSalesOrdersSelectFormFragment$data } from 'gql/__generated__/InvoiceSalesOrdersSelectFormFragment.graphql';
import { Fragment } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { dateFormat } from 'utils/date';
import { calculateDetailAmount } from 'utils/priceCalculation';

type Props = {
  control: Control<InvoiceSalesOrdersSelectFormValueType>;
  salesOrders: NonNullable<
    NonNullable<InvoiceSalesOrdersSelectFormFragment$data['salesOrders']>['edges']
  >;
  onChangeCheckbox: (isChecked: boolean, detailId: string) => void;
};

export const SalesOrderDetailsTable = ({ control, salesOrders, onChangeCheckbox }: Props) => {
  const salesOrderDetailIds = useWatch({ control, name: 'salesOrderDetailIds' });

  return (
    <Table size="md">
      <Thead>
        <Tr whiteSpace="nowrap">
          <Th w="3rem" />
          <Th w="9.5rem">ID</Th>
          <Th w="18rem" maxW="18rem">
            品名
          </Th>
          <Th w="8rem">確定納品日</Th>
          <Th w="6.5rem" isNumeric>
            数量
          </Th>
          <Th w="6.5rem" isNumeric>
            単価
          </Th>
          <Th w="6.5rem" isNumeric>
            金額 (税抜)
          </Th>
          <Th w="12rem" maxW="12rem">
            社内メモ
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {salesOrders.map((salesOrder) => {
          if (!salesOrder?.node?.details?.edges || salesOrder?.node?.details?.edges.length === 0)
            return null;

          const memo = salesOrder.node.internalMemos?.at(0)?.body;
          return (
            <Fragment key={salesOrder?.cursor}>
              <Tr bgColor="gray.100" fontSize="xs">
                <Td colSpan={8}>{salesOrder?.node?.title}</Td>
              </Tr>
              {salesOrder?.node?.details?.edges?.map((detail) => {
                const fixedDeliveryDate =
                  detail?.node?.deliveryEvents?.edges?.at(0)?.node?.fixedDeliveryDate;
                return (
                  <Tr
                    key={detail?.cursor}
                    fontSize="sm"
                    _hover={{
                      bgColor: 'gray.50',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangeCheckbox(
                        !salesOrderDetailIds.includes(detail?.node?.id || ''),
                        detail?.node?.id || '',
                      );
                    }}
                  >
                    <Td>
                      <Center>
                        <Checkbox
                          value={detail?.node?.id || ''}
                          isChecked={salesOrderDetailIds.includes(detail?.node?.id || '')}
                          onChange={(e) => {
                            e.preventDefault();
                            onChangeCheckbox(e.target.checked, e.target.value);
                          }}
                        />
                      </Center>
                    </Td>
                    <Td whiteSpace="nowrap">
                      {detailNumber(salesOrder.node?.transactionID, detail?.node?.branchNumber)}
                    </Td>
                    <Td>{detail?.node?.item?.name}</Td>
                    <Td whiteSpace="nowrap">
                      {fixedDeliveryDate ? dateFormat(fixedDeliveryDate) : '-'}
                    </Td>
                    <Td isNumeric>{detail?.node?.item?.quantity.toLocaleString()}</Td>
                    <Td isNumeric>{detail?.node?.item?.unitSellingPrice.toLocaleString()}</Td>
                    <Td isNumeric>
                      {calculateDetailAmount({
                        quantity: detail?.node?.item?.quantity || 0,
                        price: detail?.node?.item?.unitSellingPrice || 0,
                      }).toLocaleString()}
                    </Td>
                    <Td>
                      <Text whiteSpace="pre-wrap">{memo || '-'}</Text>
                    </Td>
                  </Tr>
                );
              })}
            </Fragment>
          );
        })}
      </Tbody>
    </Table>
  );
};
