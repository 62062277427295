import { Box, Flex, HStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { SalesOrderDetail } from 'features/salesOrders/components/SalesOrderDetail';
import { SalesOrderDetailSideBar } from 'features/salesOrders/components/SalesOrderDetailSideBar';
import { SalesOrderPreviewHeader } from 'features/salesOrders/components/SalesOrderPreviewHeader';
import { useQueryParams } from 'features/salesOrders/hooks/useSalesOrderQueryPrams';
import { detail_SalesOrderDetailPageQuery } from 'gql/__generated__/detail_SalesOrderDetailPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const SalesOrderDetailPageQuery = graphql`
  query detail_SalesOrderDetailPageQuery  ($id: ID) {
      salesOrders(where: {id: $id}) {
        edges {
          node {
            id
            ...SalesOrderPreviewHeaderFragment
            ...SalesOrderDetailSideBarFragment
            ...SalesOrderDetailFragment
          }
        }
      }
    } 
`;

export const SalesOrderDetailPage = () => {
  const { queryParams } = useQueryParams();
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { salesOrders } = useLazyLoadQuery<detail_SalesOrderDetailPageQuery>(
    SalesOrderDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const salesOrder = (salesOrders?.edges || [])[0]?.node;

  if (!salesOrder) return null;

  return (
    <Box w="1200px">
      <Box mb={6}>
        <Flex justify="space-between">
          <Link
            to={paths.salesOrder.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              transactionId: queryParams.transactionId,
              supplier: queryParams.supplier,
            })}
          >
            <PageBack />
          </Link>
          <SalesOrderPreviewHeader queryRef={salesOrder} />
        </Flex>
      </Box>
      <Box mb={10}>
        <Box mb={6}>
          <SubHeading label="発注請書の詳細" />
        </Box>
        <HStack spacing={4} align="start">
          <Box w="208px">
            <SalesOrderDetailSideBar queryRef={salesOrder} />
          </Box>
          <Box w="976px">
            <SalesOrderDetail queryRef={salesOrder} />
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
