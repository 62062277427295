/**
 * @generated SignedSource<<8a34841a37d4dc4a2c7e845b3431a470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EstimationRequestSupplierFormElementQuery$variables = {
  skip: boolean;
  supplierId: string;
};
export type EstimationRequestSupplierFormElementQuery$data = {
  readonly supplierContacts?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly email: string;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
        readonly memos: ReadonlyArray<{
          readonly body: string;
        }> | null;
      } | null;
    } | null> | null;
  };
};
export type EstimationRequestSupplierFormElementQuery = {
  response: EstimationRequestSupplierFormElementQuery$data;
  variables: EstimationRequestSupplierFormElementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "supplierId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "supplierID",
        "variableName": "supplierId"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EstimationRequestSupplierFormElementQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "SupplierContactConnection",
            "kind": "LinkedField",
            "name": "supplierContacts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SupplierContactEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplierContact",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SupplierContactMemo",
                        "kind": "LinkedField",
                        "name": "memos",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EstimationRequestSupplierFormElementQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "SupplierContactConnection",
            "kind": "LinkedField",
            "name": "supplierContacts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SupplierContactEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplierContact",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SupplierContactMemo",
                        "kind": "LinkedField",
                        "name": "memos",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "781d6247a1a0767477c766e989d90110",
    "id": null,
    "metadata": {},
    "name": "EstimationRequestSupplierFormElementQuery",
    "operationKind": "query",
    "text": "query EstimationRequestSupplierFormElementQuery(\n  $supplierId: ID!\n  $skip: Boolean!\n) {\n  supplierContacts(where: {supplierID: $supplierId}) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        email\n        memos {\n          body\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "59dbe22c5cd7fc443c7179e44998b86c";

export default node;
