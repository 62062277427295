/**
 * @generated SignedSource<<c140fa5d4684692942d6d3f6452b8d75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profilePageFragmentRefetchQuery$variables = {};
export type profilePageFragmentRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"profilePageFragment">;
};
export type profilePageFragmentRefetchQuery = {
  response: profilePageFragmentRefetchQuery$data;
  variables: profilePageFragmentRefetchQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "profilePageFragmentRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "profilePageFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "profilePageFragmentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6fc69dcb46310c8db565f1cd4d621fd0",
    "id": null,
    "metadata": {},
    "name": "profilePageFragmentRefetchQuery",
    "operationKind": "query",
    "text": "query profilePageFragmentRefetchQuery {\n  ...profilePageFragment\n}\n\nfragment profilePageFragment on Query {\n  currentUser {\n    userID\n    firstName\n    lastName\n    phoneNumber\n  }\n}\n"
  }
};

(node as any).hash = "e8973de8965ec12446182e3eb7d50328";

export default node;
