import { useOwnCompanyInfo_Query } from 'gql/__generated__/useOwnCompanyInfo_Query.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

const useOwnCompanyInfoQuery = graphql`
  query useOwnCompanyInfo_Query {
    ownCompanyInfo {
      id
      name
      address
      addressBuilding
      corporateNumber
    }
  }
`;

export const useOwnCompanyInfo = () => {
  const query = useLazyLoadQuery<useOwnCompanyInfo_Query>(useOwnCompanyInfoQuery, {});
  return {
    ...query.ownCompanyInfo,
    fullAddress: [query.ownCompanyInfo.address, query.ownCompanyInfo.addressBuilding]
      .filter((s): s is NonNullable<typeof s> => s != null)
      .join(' '),
  };
};
