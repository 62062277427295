import { edit_EstimationEditPageFragment$data } from 'gql/__generated__/edit_EstimationEditPageFragment.graphql';
import { new_EstimationNewPageFragment$data } from 'gql/__generated__/new_EstimationNewPageFragment.graphql';

export const EstimationDetailType = {
  Item: 'item',
} as const;

type EstimationType = NonNullable<edit_EstimationEditPageFragment$data['estimations']>;
type EstimationEdgesType = NonNullable<EstimationType['edges']>;
type EstimationEdgeType = NonNullable<EstimationEdgesType[number]>;
export type EstimationNodeType = NonNullable<EstimationEdgeType['node']>;

export type FormType = 'new' | 'edit';

type EstimationResponseType = NonNullable<
  new_EstimationNewPageFragment$data['estimationResponses']
>;
type EstimationResponseTypeEdgesType = NonNullable<EstimationResponseType['edges']>;
type EstimationResponseEdgeType = NonNullable<EstimationResponseTypeEdgesType[number]>;
export type EstimationResponseNodeType = NonNullable<EstimationResponseEdgeType['node']>;

type EstimationRequestAssigneeType = NonNullable<new_EstimationNewPageFragment$data['assignees']>;
type EstimationRequestAssigneeEdgesType = NonNullable<EstimationRequestAssigneeType['edges']>;
type EstimationRequestAssigneeEdgeType = NonNullable<EstimationRequestAssigneeEdgesType[number]>;
export type EstimationRequestAssigneeNodeType = NonNullable<
  EstimationRequestAssigneeEdgeType['node']
>;

export const DOCUMENT_DISPLAY_STATUS = {
  shown: 'shown',
  hidden: 'hidden',
} as const;

export type DocumentDisplayStatusType = keyof typeof DOCUMENT_DISPLAY_STATUS;
