import { quantitySchema, unitPriceSchema } from 'utils/validation';
import { z } from 'zod';
import { errorMessages } from './validation';

export const estimationResponseSchema = z.object({
  uuid: z.string(),
  extraItems: z
    .array(
      z.object({
        name: z.string().max(70, { message: errorMessages.MAX_LENGTH(70) }),
        quantity: quantitySchema(errorMessages),
        unitPrice: unitPriceSchema(errorMessages),
      }),
    )
    .optional(),
  expirationPeriod: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(100, { message: errorMessages.MAX_LENGTH(100) }),
  importantNotes: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(500, { message: errorMessages.MAX_LENGTH(500) }),
  detailMessage: z
    .string()
    .max(2000, { message: errorMessages.MAX_LENGTH(2000) })
    .optional(),
});

export type EstimationResponse = z.infer<typeof estimationResponseSchema>;
