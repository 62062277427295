/**
 * @generated SignedSource<<fd3e2f284f091f938ef6117ee2fb973d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationConfirmQuery$variables = {
  companyId: string;
};
export type EstimationConfirmQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EstimationConfirmFragment">;
};
export type EstimationConfirmQuery = {
  response: EstimationConfirmQuery$data;
  variables: EstimationConfirmQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EstimationConfirmQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EstimationConfirmFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EstimationConfirmQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "companyId"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7daa5f894b2d0524976a8c54c153c7da",
    "id": null,
    "metadata": {},
    "name": "EstimationConfirmQuery",
    "operationKind": "query",
    "text": "query EstimationConfirmQuery(\n  $companyId: ID!\n) {\n  ...EstimationConfirmFragment\n}\n\nfragment EstimationConfirmFragment on Query {\n  companies(where: {id: $companyId}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb1036b4c95c35521672c490338ad42b";

export default node;
