import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  DemandItemFormCardboard,
  DemandItemFormFlexiblePackage,
  DemandItemFormGiftBox,
  DemandItemFormOther,
  DemandItemFormPaperBag,
  DemandItemFormSpec,
} from 'features/demandItem/form';
import {
  DEMAND_ITEM_CATEGORY,
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
} from 'features/demandItem/models';

type Props = {
  category: DemandItemCategory;
  itemName: string;
  values: DemandItemFormSpec;
};
export const DemandItemConfirmSpec = ({ category, itemName, values }: Props) => {
  const labels = DEMAND_ITEM_CATEGORY_LABEL[category].tableLabels;

  return (
    <Table w="100%">
      <Thead>
        <Tr>
          <Th bg="gray.50">商品名</Th>
          {labels.map((label) => (
            <Th bg="gray.50" key={label}>
              {label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr fontSize="sm">
          <Td minW="10rem">{itemName}</Td>
          <DemandItemTableData values={values} category={category} />
        </Tr>
      </Tbody>
    </Table>
  );
};

const DemandItemTableData = ({
  values,
  category,
}: { values: DemandItemFormSpec; category: DemandItemCategory }) => {
  return (
    <>
      {category === DEMAND_ITEM_CATEGORY.Cardboard && (
        <CardboardTableData values={values as DemandItemFormCardboard} />
      )}
      {category === DEMAND_ITEM_CATEGORY.FlexiblePackage && (
        <FlexiblePackageTableData values={values as DemandItemFormFlexiblePackage} />
      )}
      {category === DEMAND_ITEM_CATEGORY.GiftBox && (
        <GiftBoxTableData values={values as DemandItemFormGiftBox} />
      )}
      {category === DEMAND_ITEM_CATEGORY.PaperBag && (
        <PaperBagTableData values={values as DemandItemFormPaperBag} />
      )}
      {category === DEMAND_ITEM_CATEGORY.Other && (
        <OtherTableData values={values as DemandItemFormOther} />
      )}
    </>
  );
};

const CardboardTableData = ({ values }: { values: DemandItemFormCardboard }) => {
  return (
    <>
      <Td minW="5.6rem">{values?.size || '-'}</Td>
      <Td minW="5.6rem">{values?.type || '-'}</Td>
      <Td minW="5.6rem">{values?.material || '-'}</Td>
      <Td minW="5.6rem">{values?.thickness || '-'}</Td>
      <Td minW="5.6rem">{values?.printingColor || '-'}</Td>
      <Td minW="5.6rem">{values?.processing || '-'}</Td>
      <Td minW="5.6rem">{values?.other || '-'}</Td>
    </>
  );
};

const FlexiblePackageTableData = ({ values }: { values: DemandItemFormFlexiblePackage }) => {
  return (
    <>
      <Td minW="5.6rem">{values?.size || '-'}</Td>
      <Td minW="5.6rem">{values?.type || '-'}</Td>
      <Td minW="5.6rem">{values?.material || '-'}</Td>
      <Td minW="5.6rem">{values?.printingColor || '-'}</Td>
      <Td minW="5.6rem">{values?.processing || '-'}</Td>
      <Td minW="5.6rem">{values?.other || '-'}</Td>
    </>
  );
};

const GiftBoxTableData = ({ values }: { values: DemandItemFormGiftBox }) => {
  return (
    <>
      <Td minW="5.6rem">{values?.size || '-'}</Td>
      <Td minW="5.6rem">{values?.type || '-'}</Td>
      <Td minW="5.6rem">{values?.paperType || '-'}</Td>
      <Td minW="5.6rem">{values?.printingColor || '-'}</Td>
      <Td minW="5.6rem">{values?.processing || '-'}</Td>
      <Td minW="5.6rem">{values?.other || '-'}</Td>
    </>
  );
};

const PaperBagTableData = ({ values }: { values: DemandItemFormPaperBag }) => {
  return (
    <>
      <Td minW="5.6rem">{values?.size || '-'}</Td>
      <Td minW="5.6rem">{values?.paperType || '-'}</Td>
      <Td minW="5.6rem">{values?.printingColor || '-'}</Td>
      <Td minW="5.6rem">{values?.processing || '-'}</Td>
      <Td minW="5.6rem">{values?.handle || '-'}</Td>
      <Td minW="5.6rem">{values?.other || '-'}</Td>
    </>
  );
};

const OtherTableData = ({ values }: { values: DemandItemFormOther }) => {
  return (
    <>
      <Td>{values?.specText || '-'}</Td>
    </>
  );
};
