type NameObject<T extends object> = Readonly<{
  name: string;
}> &
  Readonly<T>;

export const deduplicateAdjacentNames = <T extends object>(objects: NameObject<T>[]) => {
  let previousName = '';

  const newDetails = objects.map((object) => {
    if (object.name === previousName) {
      return { ...object, name: '同上' };
    }
    previousName = object.name;
    return object;
  });

  return newDetails;
};
