/**
 * @generated SignedSource<<8ce93398fdac7bb0b6c1a66c00c1c231>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type EstimationInput = {
  companyID: string;
  detailMessage: string;
  estimationResponseID?: string | null;
  expirationPeriod: string;
  internalAssignees?: ReadonlyArray<EstimationInternalAssigneeInput> | null;
  items: ReadonlyArray<EstimationDetailInput>;
  showAmountSummary: boolean;
  supplierID: string;
  title: string;
};
export type EstimationInternalAssigneeInput = {
  userID: string;
};
export type EstimationDetailInput = {
  documentDisplayStatus?: EstimationDetailDocumentDisplayStatus | null;
  name: string;
  orderNumber: number;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type useEstimationDuplicationMutation$variables = {
  input: EstimationInput;
};
export type useEstimationDuplicationMutation$data = {
  readonly createEstimation: {
    readonly id: string;
  };
};
export type useEstimationDuplicationMutation = {
  response: useEstimationDuplicationMutation$data;
  variables: useEstimationDuplicationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Estimation",
    "kind": "LinkedField",
    "name": "createEstimation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEstimationDuplicationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEstimationDuplicationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ac081cca796cde4279ba9ad00c3940e",
    "id": null,
    "metadata": {},
    "name": "useEstimationDuplicationMutation",
    "operationKind": "mutation",
    "text": "mutation useEstimationDuplicationMutation(\n  $input: EstimationInput!\n) {\n  createEstimation(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "19b65981f99c6c11a02439e9d4455e64";

export default node;
