/**
 * @generated SignedSource<<ad4ff3a987f1d406dd5213deee89af76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type EstimationDetailType = "cost" | "item" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EstimationPreviewFragment$data = {
  readonly company: {
    readonly name: string;
  };
  readonly createdAt: any;
  readonly detailMessage: string | null;
  readonly expirationPeriod: string;
  readonly id: string;
  readonly previewDetails: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly documentDisplayStatus: EstimationDetailDocumentDisplayStatus;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly quantity: any;
          readonly taxCategory: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        } | null;
        readonly orderNumber: any;
        readonly type: EstimationDetailType;
      } | null;
    } | null> | null;
  };
  readonly showAmountSummary: boolean;
  readonly " $fragmentType": "EstimationPreviewFragment";
};
export type EstimationPreviewFragment$key = {
  readonly " $data"?: EstimationPreviewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationPreviewFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationPreviewFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detailMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAmountSummary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": "previewDetails",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "ORDER_NUMBER"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "documentDisplayStatus": "shown"
          }
        }
      ],
      "concreteType": "EstimationDetailConnection",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationDetailEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationDetail",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "orderNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "documentDisplayStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationItem",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitSellingPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaxCategory",
                      "kind": "LinkedField",
                      "name": "taxCategory",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"},where:{\"documentDisplayStatus\":\"shown\"})"
    }
  ],
  "type": "Estimation",
  "abstractKey": null
};
})();

(node as any).hash = "e5fc4252b1840304f39542711a8be5ca";

export default node;
