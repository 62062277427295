type DetailType<T> = {
  type: 'item' | '%future added value';
  item: T | null;
};

export class UnsupportedDetailType extends Error {
  constructor(type: string) {
    super(`Unsupported detail type: ${type}`);
  }
}

export class MissingItem extends Error {
  constructor() {
    super('Item is missing');
  }
}

export function extractItemFromDetail<T>(detail: DetailType<T>): T {
  if (detail.type !== 'item') {
    throw new UnsupportedDetailType(detail.type);
  }

  if (!detail.item) {
    throw new MissingItem();
  }

  return detail.item;
}
