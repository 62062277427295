import { dateFormatHyphenDate } from 'utils/date';
import { concatFullName } from 'utils/label';
import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';
import { z } from 'zod';

export const FARE_MESSAGE =
  '・配送先が２階以上、もしくは２トントラック以下しか入れない等の場合は、別途運賃が発生する可能性がございます。';
export const MESSAGE_FOR_ERROR_OF_TAX_CALCULATION =
  '・消費税の端数処理により、請求時に誤差が発生する可能性があります。';
export const DEFAULT_CANCEL_MESSAGE =
  '・ご発注後の製造が進行している場合、キャンセルはお受け致しかねます。';
export const DETAIL_MESSAGE_TEMPLATE = `・振込手数料は貴社にてご負担ください。
${DEFAULT_CANCEL_MESSAGE}
${MESSAGE_FOR_ERROR_OF_TAX_CALCULATION}
${FARE_MESSAGE}
`;

export const salesOrderDetailSchema = z.object({
  /** NOTE: 行更新時はIDを指定する */
  id: z.string().optional(),
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) }),
  unitPrice: unitPriceSchema(),
  unitSellingPrice: unitSellingPriceSchema,
  quantity: quantitySchema(),
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
  }),
  fixedDeliveryDate: z.string().optional(),
});

export const salesOrderFormSchema = z.object({
  company: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  supplier: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  title: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(70, { message: errorMessages.MAX_LENGTH(70) }),
  detailMessage: z.string().max(2000, { message: errorMessages.MAX_LENGTH(2000) }),
  estimationID: z.string().optional(),
  receivedDate: z.string(),
  details: z.array(salesOrderDetailSchema),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
});

export const SALES_ORDER_FORM_TYPE = {
  new: 'new',
  edit: 'edit',
} as const;

export const SALES_ORDER_SUBMIT_TYPE = {
  confirm: 'confirm',
} as const;

export type SalesOrderDetailFormValuesType = z.infer<typeof salesOrderDetailSchema>;
export type SalesOrderFormValuesType = z.infer<typeof salesOrderFormSchema>;
export type SalesOrderFormType = (typeof SALES_ORDER_FORM_TYPE)[keyof typeof SALES_ORDER_FORM_TYPE];
export type SalesOrderSubmitType =
  (typeof SALES_ORDER_SUBMIT_TYPE)[keyof typeof SALES_ORDER_SUBMIT_TYPE];

export const getSalesOrderDetailInitialValues = (tax: {
  id: string;
  rate: string | number;
}): SalesOrderDetailFormValuesType => {
  return {
    name: '',
    fixedDeliveryDate: '',
    tax,
  };
};

export const getSalesOrderInitialValues = ({
  tax,
  currentUser,
}: {
  tax: {
    id: string;
    rate: string | number;
  };
  currentUser: {
    userID: string;
    firstName: string;
    lastName: string;
  };
}): SalesOrderFormValuesType => {
  return {
    company: {
      id: '',
      name: '',
    },
    supplier: {
      id: '',
      name: '',
    },
    title: '',
    detailMessage: DETAIL_MESSAGE_TEMPLATE,
    estimationID: '',
    receivedDate: dateFormatHyphenDate(),
    details: [getSalesOrderDetailInitialValues(tax)],
    internalAssignees: [
      {
        value: currentUser.userID,
        label: concatFullName({
          lastName: currentUser.lastName,
          firstName: currentUser.firstName,
        }),
      },
    ],
    memo: '',
  };
};
