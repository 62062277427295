/**
 * @generated SignedSource<<0b6a7084fa597635bd6e208bf1ee309b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type detail_SalesOrderDetailPageQuery$variables = {
  id?: string | null;
};
export type detail_SalesOrderDetailPageQuery$data = {
  readonly salesOrders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"SalesOrderDetailFragment" | "SalesOrderDetailSideBarFragment" | "SalesOrderPreviewHeaderFragment">;
      } | null;
    } | null> | null;
  };
};
export type detail_SalesOrderDetailPageQuery = {
  response: detail_SalesOrderDetailPageQuery$data;
  variables: detail_SalesOrderDetailPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "detail_SalesOrderDetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SalesOrderConnection",
        "kind": "LinkedField",
        "name": "salesOrders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SalesOrderPreviewHeaderFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SalesOrderDetailSideBarFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SalesOrderDetailFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detail_SalesOrderDetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SalesOrderConnection",
        "kind": "LinkedField",
        "name": "salesOrders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Order",
                    "kind": "LinkedField",
                    "name": "orders",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Invoice",
                    "kind": "LinkedField",
                    "name": "invoices",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Supplier",
                    "kind": "LinkedField",
                    "name": "supplier",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transactionID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "receivedDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Estimation",
                    "kind": "LinkedField",
                    "name": "estimations",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationResponse",
                        "kind": "LinkedField",
                        "name": "estimationResponse",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationRequestAssignee",
                            "kind": "LinkedField",
                            "name": "assignee",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationRequest",
                                "kind": "LinkedField",
                                "name": "estimationRequest",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "detailMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "direction": "ASC",
                          "field": "ORDER_NUMBER"
                        }
                      }
                    ],
                    "concreteType": "SalesOrderDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "branchNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "where",
                                    "value": {
                                      "isValid": true
                                    }
                                  }
                                ],
                                "concreteType": "SalesOrderDetailDeliveryEventConnection",
                                "kind": "LinkedField",
                                "name": "deliveryEvents",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrderDetailDeliveryEventEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SalesOrderDetailDeliveryEvent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "fixedDeliveryDate",
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderItem",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unitPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unitSellingPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "quantity",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TaxCategory",
                                    "kind": "LinkedField",
                                    "name": "taxCategory",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rate",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetailNotionPage",
                                "kind": "LinkedField",
                                "name": "notionPage",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "notionPageURL",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e186f7db0962766bd0f3566fbbcbc4b8",
    "id": null,
    "metadata": {},
    "name": "detail_SalesOrderDetailPageQuery",
    "operationKind": "query",
    "text": "query detail_SalesOrderDetailPageQuery(\n  $id: ID\n) {\n  salesOrders(where: {id: $id}) {\n    edges {\n      node {\n        id\n        ...SalesOrderPreviewHeaderFragment\n        ...SalesOrderDetailSideBarFragment\n        ...SalesOrderDetailFragment\n      }\n    }\n  }\n}\n\nfragment SalesOrderDetailFragment on SalesOrder {\n  internalMemos {\n    body\n    id\n  }\n  title\n  transactionID\n  detailMessage\n  details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {\n    edges {\n      node {\n        id\n        branchNumber\n        deliveryEvents(where: {isValid: true}) {\n          edges {\n            node {\n              fixedDeliveryDate\n              id\n            }\n          }\n        }\n        item {\n          id\n          name\n          unitPrice\n          unitSellingPrice\n          quantity\n          taxCategory {\n            id\n            rate\n          }\n        }\n        notionPage {\n          notionPageURL\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment SalesOrderDetailSideBarFragment on SalesOrder {\n  id\n  title\n  createdAt\n  demand {\n    id\n    name\n  }\n  supplier {\n    id\n    name\n  }\n  createdBy {\n    profile {\n      lastName\n      firstName\n      id\n    }\n    id\n  }\n  transactionID\n  receivedDate\n  estimations {\n    id\n    estimationResponse {\n      assignee {\n        estimationRequest {\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n  orders {\n    id\n  }\n  invoices {\n    id\n  }\n  internalAssignees {\n    user {\n      id\n      profile {\n        lastName\n        firstName\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment SalesOrderPreviewHeaderFragment on SalesOrder {\n  id\n  orders {\n    id\n  }\n  invoices {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb7e306d81f3a884b75a422bc0d43956";

export default node;
