import { isAfterOrSameDate } from 'utils/date';
import { errorMessages, quantitySchema, unitSellingPriceSchema } from 'utils/validation';
import { z } from 'zod';

const invoiceDetailSchema = z.object({
  invoiceDetailId: z.string().optional(),
  salesOrderDetailId: z.string().optional(),
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(200, { message: errorMessages.MAX_LENGTH(200) }),
  salesDate: z.string().optional(),
  quantity: quantitySchema(),
  unitSellingPrice: unitSellingPriceSchema,
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
  }),
});

export const invoiceSalesOrdersSelectFormSchema = z.object({
  demand: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  salesOrderDetailIds: z.array(z.string()).min(1, { message: '1件以上選択しましょう' }),
});

export const invoiceInputFormSchema = z.object({
  title: z.string().max(70, { message: errorMessages.MAX_LENGTH(70) }),
  dates: z
    .object({
      bookedAt: z.string(),
      paymentDueAt: z.string(),
    })
    .refine(
      ({ bookedAt, paymentDueAt }) => {
        return isAfterOrSameDate(paymentDueAt, bookedAt);
      },
      {
        message: '売上計上日以降にしましょう',
        path: ['paymentDueAt'],
      },
    ),
  detailMessage: z
    .string()
    .max(2000, { message: errorMessages.MAX_LENGTH(2000) })
    .optional(),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
  details: z.array(invoiceDetailSchema),
});

export type InvoiceSalesOrdersSelectFormValueType = z.infer<
  typeof invoiceSalesOrdersSelectFormSchema
>;

export type InvoiceInputFormValueType = z.infer<typeof invoiceInputFormSchema>;

export type InvoiceDetailValueType = z.infer<typeof invoiceDetailSchema>;

export const INVOICE_FORM_TYPE = {
  new: 'new',
  edit: 'edit',
} as const;

export const INVOICE_SUBMIT_TYPE = {
  select: 'select',
  input: 'input',
  confirm: 'confirm',
} as const;

export type InvoiceFormType = (typeof INVOICE_FORM_TYPE)[keyof typeof INVOICE_FORM_TYPE];
export type InvoiceSubmitType = (typeof INVOICE_SUBMIT_TYPE)[keyof typeof INVOICE_SUBMIT_TYPE];

export const getInvoiceDetailInitialValues = (tax: {
  id: string;
  rate: string | number;
}): InvoiceDetailValueType => {
  return {
    name: '',
    salesDate: '',
    salesOrderDetailId: '',
    tax,
  };
};

export const getFormPageLabel = (type: InvoiceFormType) => {
  switch (type) {
    case INVOICE_FORM_TYPE.new:
      return '請求書の作成';
    case INVOICE_FORM_TYPE.edit:
      return '請求書の編集';
  }
};
