/**
 * @generated SignedSource<<31dd0467c39c077b3e69faf3666ee0f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type edit_EstimationEditPageQuery$variables = {
  id: string;
};
export type edit_EstimationEditPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EstimationFormFragment" | "edit_EstimationEditPageFragment">;
};
export type edit_EstimationEditPageQuery = {
  response: edit_EstimationEditPageQuery$data;
  variables: edit_EstimationEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "edit_EstimationEditPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "edit_EstimationEditPageFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EstimationFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "edit_EstimationEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "EstimationConnection",
        "kind": "LinkedField",
        "name": "estimations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EstimationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Estimation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Supplier",
                    "kind": "LinkedField",
                    "name": "supplier",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "detailMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expirationPeriod",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showAmountSummary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "direction": "ASC",
                          "field": "ORDER_NUMBER"
                        }
                      }
                    ],
                    "concreteType": "EstimationDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "orderNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "documentDisplayStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationItem",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unitPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unitSellingPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "quantity",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TaxCategory",
                                    "kind": "LinkedField",
                                    "name": "taxCategory",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationResponse",
                    "kind": "LinkedField",
                    "name": "estimationResponse",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationRequestAssignee",
                        "kind": "LinkedField",
                        "name": "assignee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationRequest",
                            "kind": "LinkedField",
                            "name": "estimationRequest",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxCategoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxCategory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userID",
            "storageKey": null
          },
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41e59ad46715edac001d8827becd1a25",
    "id": null,
    "metadata": {},
    "name": "edit_EstimationEditPageQuery",
    "operationKind": "query",
    "text": "query edit_EstimationEditPageQuery(\n  $id: ID!\n) {\n  ...edit_EstimationEditPageFragment\n  ...EstimationFormFragment\n}\n\nfragment EstimationFormFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n  ...TaxSelectBoxFragment\n}\n\nfragment TaxSelectBoxFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n\nfragment edit_EstimationEditPageFragment on Query {\n  estimations(where: {id: $id}) {\n    edges {\n      node {\n        id\n        company {\n          id\n          name\n        }\n        supplier {\n          id\n          name\n        }\n        title\n        detailMessage\n        expirationPeriod\n        showAmountSummary\n        createdAt\n        details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {\n          edges {\n            node {\n              type\n              orderNumber\n              documentDisplayStatus\n              item {\n                id\n                name\n                unitPrice\n                unitSellingPrice\n                quantity\n                taxCategory {\n                  id\n                  rate\n                }\n              }\n              id\n            }\n          }\n        }\n        estimationResponse {\n          assignee {\n            estimationRequest {\n              id\n            }\n            id\n          }\n          id\n        }\n        internalAssignees {\n          user {\n            id\n            profile {\n              lastName\n              firstName\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n  currentUser {\n    userID\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "4bb4d37348e322f04ebe46361aa63805";

export default node;
