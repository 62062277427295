import { Box, Button, FormControl, FormLabel, HStack, Input, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from 'components/ErrorMessage';
import { ProfileFormDataType, profileFormSchema } from 'features/user/form';
import { useForm } from 'react-hook-form';

type Props = {
  onSubmit: (data: ProfileFormDataType) => void;
  isMutationInFlight: boolean;
  value: ProfileFormDataType;
};

export const ProfileForm = ({ onSubmit, value, isMutationInFlight }: Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ProfileFormDataType>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: value,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={6} alignItems="flex-start">
        <HStack justifyContent="space-between" spacing={2} w="full" align="start">
          <FormControl isInvalid={!!errors.lastName} isRequired>
            <FormLabel>姓</FormLabel>
            <Input type="string" {...register('lastName')} />
            <ErrorMessage name="lastName" errors={errors} />
          </FormControl>
          <FormControl isInvalid={!!errors.firstName} isRequired>
            <FormLabel>名</FormLabel>
            <Input type="string" {...register('firstName')} />
            <ErrorMessage name="firstName" errors={errors} />
          </FormControl>
        </HStack>
        <FormControl isInvalid={!!errors.phoneNumber}>
          <FormLabel>電話番号</FormLabel>
          <Input type="tel" {...register('phoneNumber')} placeholder="090-1234-5678" />
          <ErrorMessage name="phoneNumber" errors={errors} />
        </FormControl>
        <Box w="full">
          <Button colorScheme="blue" w="full" type="submit" isDisabled={isMutationInFlight}>
            更新
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
