import { PartialMessage } from '@bufbuild/protobuf';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Img,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddOne } from '@icon-park/react';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { PageHeading } from 'components/PageHeading';
import { EstimationResponse, estimationResponseSchema } from 'features/estimationResponse/zod';
import { ItemTable } from 'features/item/components/ItemTable';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { toast } from 'lib/toast';
import {
  CreateEstimationResponseDetail,
  EstimationResponseDetailTypeCost,
} from 'proto/model/estimation_response/v1/create_estimation_response_pb';
import { CategoryItemsSummary, ItemCategory } from 'proto/model/item/v1/summarize_item_pb';
import { EstimationResponseService } from 'proto/service/estimation_response/v1/estimation_response_connect';
import { EstimationResponseExternalService } from 'proto/service/estimation_response_external/v1/estimation_response_external_connect';
import ShizaiLogoBlack from 'public/images/shizai_logo_black.svg';
import { KeyboardEvent, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { captureException } from 'utils/error';
import {
  convertExtraItemFormat,
  createSubmitDetails,
  getRootItemByCategory,
  removeRootItemByCategory,
} from './helper';

type Props = {
  token: string;
  title: string;
  specText: string;
  detailMessage?: string;
  latestRespondedImportantNotes: string;
  latestRespondedExpirationPeriod: string;
  items: PartialMessage<CategoryItemsSummary>[];
  onSubmit?: () => void;
};

export const EstimationResponseForm = ({
  token,
  title,
  specText,
  detailMessage,
  latestRespondedImportantNotes,
  latestRespondedExpirationPeriod,
  onSubmit: onSubmitProps,
  items: origItems,
}: Props) => {
  const versionWatcher = useContext(VersionWatcherContext);
  useEffect(versionWatcher.disableSoftDeleteEffect, []);

  const [searchParams] = useSearchParams();
  const isInternal = searchParams.get('isInternal');
  const { grpcClient } = useGrpcClient(
    isInternal ? EstimationResponseService : EstimationResponseExternalService,
  );

  const [itemData, setItemData] = useState(
    removeRootItemByCategory(origItems, ItemCategory.UNKNOWN),
  );
  const [extraItems, setExtraItems] = useState(
    getRootItemByCategory(origItems, ItemCategory.UNKNOWN),
  );
  const [items, updateItems] = useState(itemData);

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<EstimationResponse>({
    resolver: zodResolver(estimationResponseSchema),
    defaultValues: {
      uuid: token,
      extraItems: extraItems ? convertExtraItemFormat(extraItems) : [],
      detailMessage: detailMessage || '',
      importantNotes: latestRespondedImportantNotes,
      expirationPeriod: latestRespondedExpirationPeriod,
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setItemData(removeRootItemByCategory(origItems, ItemCategory.UNKNOWN));
    setExtraItems(getRootItemByCategory(origItems, ItemCategory.UNKNOWN));
    updateItems(itemData);
  }, [origItems]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setValue('extraItems', extraItems ? convertExtraItemFormat(extraItems) : []);
    setValue('detailMessage', detailMessage || '');
  }, [extraItems]);

  const {
    fields: extraItemFields,
    append: appendExtraItem,
    remove: removeExtraItem,
  } = useFieldArray({
    control,
    name: 'extraItems',
  });

  const addExtraItem = () =>
    appendExtraItem({ name: '', quantity: '', unitPrice: '' }, { shouldFocus: true });

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !(e.target instanceof HTMLTextAreaElement)) e.preventDefault();
  };

  const onSubmit = async (data: EstimationResponse) => {
    const { uuid, extraItems, detailMessage, expirationPeriod, importantNotes } = data;
    createSubmitDetails(items);
    try {
      await grpcClient.register({
        estimationResponse: {
          uuid,
          detailMessage,
          expirationPeriod,
          importantNotes,
          details: [
            ...createSubmitDetails(items),
            ...(extraItems || []).map(
              (ei) =>
                new CreateEstimationResponseDetail({
                  value: {
                    case: 'cost',
                    value: new EstimationResponseDetailTypeCost({
                      name: ei.name,
                      quantity: ei.quantity !== undefined ? BigInt(ei.quantity) : undefined,
                      unitPrice: ei.unitPrice !== undefined ? Number(ei.unitPrice) : undefined,
                    }),
                  },
                }),
            ),
          ],
        },
      });
      onSubmitProps && onSubmitProps();
    } catch (error) {
      console.log(error);
      toast({
        title: 'エラーが発生しましたので再度送信してください',
        status: 'error',
      });
      captureException(error as Error);
    }
  };

  return (
    <>
      <Box as="header" px={6} py={5} mb={10}>
        <Img src={ShizaiLogoBlack} alt="shizai logo" />
      </Box>
      <Box px={10} pb={20}>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
          <VStack spacing={6} align="start">
            <PageHeading label={title || '件名なし'} />

            <Text whiteSpace="pre-wrap">{specText}</Text>

            <Text size="sm" color="gray.500">
              ロット・見積単価のご入力をお願いいたします
            </Text>

            <VStack spacing={6} align="start">
              <VStack spacing={10} align="start">
                {items.map((item, index) => {
                  if (item.category === ItemCategory.UNSPECIFIED) return null;
                  return (
                    <Box key={`${item.category}-${index}`}>
                      <ItemTable items={[item]} index={0} updateState={updateItems} />
                    </Box>
                  );
                })}
              </VStack>

              <VStack spacing={10} align="start" width="768px">
                <Box w="full">
                  <Box mb={4}>
                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                      見積条件追加
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      上記以外に発生する費用や仕様の違いなどがありましたらご記載ください
                    </Text>
                  </Box>

                  <VStack spacing={4} align="stretch">
                    {extraItemFields.map((item, index) => (
                      <HStack key={`extra-item-${item.id}-${index}`} align="flex-start" spacing={2}>
                        <FormControl
                          flexBasis="60%"
                          isInvalid={!!(errors?.extraItems || [])[index]?.name}
                        >
                          {index === 0 && <FormLabel>品名</FormLabel>}
                          <Input
                            {...register(`extraItems.${index}.name`)}
                            placeholder="印版代など"
                          />
                          <ErrorMessage name={`extraItems.${index}.name`} errors={errors} />
                        </FormControl>
                        <FormControl
                          flexBasis="21%"
                          isInvalid={!!(errors?.extraItems || [])[index]?.quantity}
                        >
                          {index === 0 && <FormLabel>ロット</FormLabel>}
                          <ConfigurableNumberInput
                            {...register(`extraItems.${index}.quantity`)}
                            placeholder="1"
                          />
                          <ErrorMessage name={`extraItems.${index}.quantity`} errors={errors} />
                        </FormControl>
                        <FormControl
                          flexBasis="21%"
                          isInvalid={!!(errors?.extraItems || [])[index]?.unitPrice}
                        >
                          {index === 0 && <FormLabel>見積単価 (税抜)</FormLabel>}
                          <ConfigurableNumberInput
                            {...register(`extraItems.${index}.unitPrice`)}
                            step="0.01"
                            placeholder="50000"
                          />
                          <ErrorMessage name={`extraItems.${index}.unitPrice`} errors={errors} />
                        </FormControl>
                        <Box flexBasis="4%">
                          <Button
                            variant="ghost"
                            borderRadius={50}
                            w={10}
                            h={10}
                            mt={index === 0 ? 8 : 0}
                            onClick={() => removeExtraItem(index)}
                          >
                            <CloseIcon color="gray.700" w="10px" h="10px" />
                          </Button>
                        </Box>
                      </HStack>
                    ))}
                    <Button
                      w="full"
                      colorScheme="gray"
                      leftIcon={<AddOne />}
                      onClick={addExtraItem}
                    >
                      追加
                    </Button>
                  </VStack>
                </Box>

                <VStack gap={2} w={320}>
                  <FormControl isInvalid={!!errors.expirationPeriod} isRequired>
                    <FormLabel>見積有効期限</FormLabel>
                    <Input
                      {...register('expirationPeriod')}
                      placeholder="発行から1ヶ月間"
                      required={false}
                    />
                    <ErrorMessage name="expirationPeriod" errors={errors} />
                  </FormControl>
                </VStack>

                <Box w="full">
                  <FormControl isInvalid={!!errors.importantNotes} isRequired>
                    <FormLabel>諸条件</FormLabel>
                    <Textarea {...register('importantNotes')} required={false} />
                    <ErrorMessage name="importantNotes" errors={errors} />
                  </FormControl>
                </Box>

                <Box w="full">
                  <FormControl isInvalid={!!errors.detailMessage}>
                    <FormLabel>備考</FormLabel>
                    <Textarea {...register('detailMessage')} required={false} />
                    <ErrorMessage name="detailMessage" errors={errors} />
                  </FormControl>
                </Box>

                <Button
                  colorScheme="blue"
                  w="full"
                  type="submit"
                  isLoading={isSubmitting}
                  color="white"
                >
                  送信
                </Button>
              </VStack>
            </VStack>
          </VStack>
        </form>
      </Box>
    </>
  );
};
