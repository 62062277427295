import { Button, HStack, Text } from '@chakra-ui/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { useDownloadOrderPdf } from 'features/order/hooks/useDownloadOrderPdf';
import { useOrderDelete } from 'features/order/hooks/useOrderDelete';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from 'utils/paths';

type Props = {
  id: string;
};

export const OrderPreviewHeader = ({ id }: Props) => {
  const { onSubmit } = useOrderDelete();
  const { downloadOrderPdf } = useDownloadOrderPdf();

  return (
    <HStack spacing={4}>
      <ConfirmModalButton
        header="サプライヤーに連絡を行いましたか？"
        body={
          <Text>
            削除すると発注書を確認できなくなります。
            <br />
            発注を中止する場合は、削除前にサプライヤーへ連絡しましょう。
          </Text>
        }
        button={({ onOpen }) => <Button onClick={onOpen}>削除</Button>}
        footer={({ onClose }) => (
          <HStack spacing={3}>
            <Button onClick={onClose}>キャンセル</Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                onClose();
                onSubmit(id);
              }}
            >
              削除
            </Button>
          </HStack>
        )}
      />
      <RouterLink to={paths.order.edit._id(id)}>
        <Button colorScheme="blue">編集</Button>
      </RouterLink>
      <Button colorScheme="blue" onClick={() => downloadOrderPdf(id)}>
        PDFダウンロード
      </Button>
      <RouterLink to={paths.order.sending._id(id)}>
        <Button colorScheme="blue">メールで送信</Button>
      </RouterLink>
    </HStack>
  );
};
