import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { EstimationDetailSideBar } from 'features/estimation/components/EstimationDetailSideBar';
import { EstimationItemPriceList } from 'features/estimation/components/EstimationItemPriceList';
import { EstimationPreview } from 'features/estimation/components/EstimationPreview';
import { EstimationPreviewHeader } from 'features/estimation/components/EstimationPreviewHeader';
import { useQueryParams } from 'features/estimation/hooks/useEstimatoinQueryPrams';
import { detail_EstimationDetailPageQuery as detail_EstimationDetailPageQueryType } from 'gql/__generated__/detail_EstimationDetailPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const EstimationDetailPageQuery = graphql`
  query detail_EstimationDetailPageQuery  ($id: ID!) {
    estimations(where: {id: $id}) {
      edges {
        node {
          ...EstimationDetailSideBarFragment
          ...EstimationPreviewFragment
          ...EstimationPreviewHeaderFragment
          ...EstimationItemPriceListFragment
        }
      }
    }
  }
`;

export const EstimationDetailPage = () => {
  const { queryParams } = useQueryParams();
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { estimations } = useLazyLoadQuery<detail_EstimationDetailPageQueryType>(
    EstimationDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const estimation = (estimations.edges || [])[0]?.node;

  if (!estimation) return null;

  return (
    <Box w="992px">
      <Box mb={6}>
        <Flex justify="space-between">
          <RouterLink
            to={paths.estimation.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              supplier: queryParams.supplier,
            })}
          >
            <PageBack />
          </RouterLink>
          <EstimationPreviewHeader queryRef={estimation} />
        </Flex>
      </Box>
      <Box mb={10}>
        <Box mb={6}>
          <SubHeading label="見積書の詳細" />
        </Box>
        <HStack spacing={4} align="start">
          <Box w="208px">
            <EstimationDetailSideBar queryRef={estimation} />
          </Box>
          <VStack w="768px" spacing={8}>
            <EstimationPreview queryRef={estimation} />
            <EstimationItemPriceList queryRef={estimation} />
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
};
