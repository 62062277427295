import { UploadThree } from '@icon-park/react';
import { FileUploadButton } from 'components/FileUploadButton';
import {
  ZodEstimationRequestItem,
  estimationRequestItemSchema,
} from 'features/estimationRequest/zod';
import { ChangeEvent } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { readEstimationRequestExcelData } from 'utils/excel';
import { z } from 'zod';

type Props = {
  // biome-ignore lint/suspicious/noExplicitAny:
  register: UseFormRegister<any>;
  name: string;
  onUploaded: (items: ZodEstimationRequestItem[]) => void;
  onError: (message: string) => void;
};

export const ExcelFileUploadButton = ({ register, name, onUploaded, onError }: Props) => {
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files || [])[0];
    if (!file) return;

    const { data, error } = await readEstimationRequestExcelData(file);
    if (error.message) {
      onError(error.message);
      return;
    }

    const result = z.array(estimationRequestItemSchema).safeParse(data);
    if (!result.success) {
      onError('データを読み込めませんでした。プロダクトチームに連絡をお願いします。');
      return;
    }

    onUploaded(data);
  };

  return (
    <FileUploadButton
      onChange={handleChange}
      name={name}
      register={register}
      label="Excelアップロード"
      errors={undefined}
      leftIcon={<UploadThree />}
      accept=".xlsx"
      buttonProps={{
        colorScheme: 'blue',
        width: 'full',
      }}
    />
  );
};
