import { DemandFormDataType } from 'features/company/form';
import { useCreateDemandMutation } from 'gql/__generated__/useCreateDemandMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

export const useCreateDemand = () => {
  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useCreateDemandMutation>(
    graphql`
      mutation useCreateDemandMutation(
        $input: CreateDemandInput!
      ) {
        createDemand(input: $input) {
          id
        }
      }
    `,
  );

  const onSubmit = (data: DemandFormDataType) => {
    commitMutation({
      variables: {
        input: {
          name: data.name,
          memo: data.memo || undefined,
        },
      },
      onCompleted: () => {
        toast({
          title: 'デマンドを作成しました',
          status: 'success',
        });
        navigate(paths.company.url());
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
