import {
  OrderDetailUpdateInput,
  useOrderEditFormMutation,
} from 'gql/__generated__/useOrderEditFormMutation.graphql';
import {
  useOrderEditFormQuery$data,
  useOrderEditFormQuery as useOrderEditFormQueryType,
} from 'gql/__generated__/useOrderEditFormQuery.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { toast } from 'lib/toast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchQuery, graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { ORDER_SUBMIT_TYPE, OrderSubmitType, RECIPIENT_TYPE } from '../type';
import { OrderSendingFormData, ZodOrderFormData } from '../zod';

const useOrderEditFormQuery = graphql`
  query useOrderEditFormQuery ($id: ID!) {
    downloadOrderPdf(id: $id)
  }
`;

export const useOrderEditForm = ({
  orderId,
  state,
  sendingValues,
}: {
  orderId: string;
  state: ZodOrderFormData;
  sendingValues: OrderSendingFormData;
}) => {
  const [_, setSearchParams] = useSearchParams();
  const versionWatcher = useContext(VersionWatcherContext);
  useEffect(versionWatcher.disableSoftDeleteEffect, []);

  const navigate = useNavigate();
  const [showPreview, setPreview] = useState(false);
  const [submitType, setSubmitType] = useState<OrderSubmitType | null>(null);
  const [data, setData] = useState(state);
  const [sendingData, setSendingData] = useState<OrderSendingFormData>(sendingValues);
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useOrderEditFormMutation>(
    graphql`
      mutation useOrderEditFormMutation(
        $id: ID!
        $input: OrderUpdateInput!
      ) {
        updateOrder(id: $id, input: $input) {
          id
        }
      }
    `,
  );

  const onClickToConfirm = (data: ZodOrderFormData) => {
    setData({ ...data });
    setPreview(true);
    if (submitType === ORDER_SUBMIT_TYPE.sending) {
      setSearchParams({ type: ORDER_SUBMIT_TYPE.sending });
    }
    if (submitType === ORDER_SUBMIT_TYPE.download) {
      setSearchParams({ type: ORDER_SUBMIT_TYPE.download });
    }
  };

  const onClickToNewForm = (sendingFormData?: OrderSendingFormData) => {
    if (sendingFormData) setSendingData(sendingFormData);
    setPreview(false);
    setSubmitType(null);
  };

  const updateSubmitType = (type: OrderSubmitType) => {
    setSubmitType(type);
  };

  const onSubmit = (sendingFormData?: OrderSendingFormData) => {
    const to = (sendingFormData?.supplierContactsTo || []).map((contactId) => {
      return {
        ID: contactId,
        recipientType: RECIPIENT_TYPE.to,
      };
    });
    const cc = (sendingFormData?.supplierContactsCc || []).map((contactId) => {
      return {
        ID: contactId,
        recipientType: RECIPIENT_TYPE.cc,
      };
    });

    commitMutation({
      variables: {
        id: orderId,
        input: {
          title: data.title,
          detailMessage: data.detailMessage,
          deliveryInfo: data.deliveryInfo,
          mailBodyMessage: sendingFormData?.mailBodyMessage || '',
          supplierContacts: [...to, ...cc],
          items: data.details.map(
            (detail, i) =>
              ({
                name: detail.name,
                unitPrice: detail.unitPrice,
                quantity: detail.quantity,
                taxCategoryID: detail.tax.id,
                orderNumber: i + 1,
                salesOrderDetailID: detail.salesOrderDetailId,
              }) as OrderDetailUpdateInput,
          ),
          internalAssignees: [
            ...(data.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
        },
      },
      onCompleted: (res) => {
        if (submitType === ORDER_SUBMIT_TYPE.download) {
          const fetch = async () => {
            let data: useOrderEditFormQuery$data | undefined;
            try {
              data = await fetchQuery<useOrderEditFormQueryType>(
                RelayEnvironment,
                useOrderEditFormQuery,
                {
                  id: res?.updateOrder?.id,
                },
              ).toPromise();
            } catch (_error) {
              return;
            }

            window.open(data?.downloadOrderPdf, '_blank', 'noopener, noreferrer');
          };
          fetch();
        }

        toast({
          title: '発注書を更新しました',
          status: 'success',
        });
        navigate(paths.order.url());
      },
    });
  };

  return {
    data,
    showPreview,
    onClickToConfirm,
    onClickToNewForm,
    onSubmit,
    isMutationInFlight,
    updateSubmitType,
    sendingData,
  };
};
