import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'components/Link';
import { EstimationRequestInfoFragment$key } from 'gql/__generated__/EstimationRequestInfoFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const EstimationRequestInfoFragment = graphql`
  fragment EstimationRequestInfoFragment on EstimationRequest
  {
    id
    requestTitle
    createdAt
    company {
      id
      name
    }
    internalAssignees {
      user {
        profile {
          lastName
          firstName
        }
      }
    }
    requestAssignees {
      createdBy {
        profile {
          lastName
          firstName
        }
      }
      supplier {
        id
        name
      }
    }
  }
`;

type Props = {
  queryRef: EstimationRequestInfoFragment$key;
};

export const EstimationRequestInfo = ({ queryRef }: Props) => {
  const data = useFragment(EstimationRequestInfoFragment, queryRef);

  const companies = new Set(data.requestAssignees?.map((assignee) => assignee.supplier));

  const creators = new Set(
    data.requestAssignees?.map((assignee) =>
      assignee.createdBy.profile?.[0]?.lastName && assignee.createdBy.profile?.[0]?.firstName
        ? concatFullName({
            lastName: assignee.createdBy.profile[0].lastName,
            firstName: assignee.createdBy.profile[0].firstName,
          })
        : '-',
    ),
  );

  const internalAssignees =
    data.internalAssignees?.map((assignee) =>
      assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName
        ? concatFullName({
            lastName: assignee.user.profile[0].lastName,
            firstName: assignee.user.profile[0].firstName,
          })
        : '-',
    ) || [];

  return (
    <>
      <Flex mt={2}>
        <Box w="120px" color="gray.500">
          ID
        </Box>
        <Box>{data.id}</Box>
      </Flex>
      <Flex mt={2}>
        <Box w="120px" color="gray.500">
          案件名
        </Box>
        <Box>{data.requestTitle}</Box>
      </Flex>
      <Flex mt={2}>
        <Box w="120px" color="gray.500">
          作成日
        </Box>
        <Box>{dateFormat(data.createdAt)}</Box>
      </Flex>
      <Flex mt={2}>
        <Box w="120px" color="gray.500">
          デマンド
        </Box>
        <Box>
          <Link to={paths.company._id(data.company.id).url}>{data.company.name}</Link>
        </Box>
      </Flex>
      <Flex mt={2}>
        <Box w="120px" color="gray.500">
          サプライヤー
        </Box>
        <Box>
          {Array.from(companies)
            .map<React.ReactNode>((c) => (
              <Link
                to={paths.supplier._id(c.id).url()}
                key={`estimation-reqest-info-supplier-${c.id}`}
              >
                {c.name}
              </Link>
            ))
            .reduce((prev, curr) => [prev, ' / ', curr])}
        </Box>
      </Flex>
      <Flex mt={2}>
        <Box w="120px" color="gray.500">
          担当者
        </Box>
        <Box>{Array.from(internalAssignees).join(' / ')}</Box>
      </Flex>
      <Flex mt={2}>
        <Box w="120px" color="gray.500">
          作成者
        </Box>
        <Box>{Array.from(creators).join(' / ')}</Box>
      </Flex>
    </>
  );
};
