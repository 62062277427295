import { Button, Container, Flex, Spacer, Text, VStack } from '@chakra-ui/react';
import { AppLogo } from 'components/AppLogo';
import { useSession } from 'hooks/useSession';
import { Navigate } from 'react-router-dom';
import { paths } from 'utils/paths';

export function LoginPage() {
  const { currentUser, signIn } = useSession();

  if (currentUser) {
    return <Navigate to={paths.root()} />;
  }

  return (
    <Container bg="gray.800" minH="100vh" minW="100%">
      <Flex minH="100vh" justify="center" align="center">
        <VStack spacing={3}>
          <AppLogo htmlWidth="150px" />
          <Text color="whiteAlpha.800">shizaiのGoogleアカウントでログインしてください</Text>
          <Spacer />
          <Button colorScheme="blue" width="240px" onClick={signIn}>
            ログイン
          </Button>
        </VStack>
      </Flex>
    </Container>
  );
}
