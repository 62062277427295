// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file service/search/v1/search.proto (package service.search.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { SearchRequest, SearchResponse } from "./search_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service service.search.v1.SearchService
 */
export const SearchService = {
  typeName: "service.search.v1.SearchService",
  methods: {
    /**
     * @generated from rpc service.search.v1.SearchService.Search
     */
    search: {
      name: "Search",
      I: SearchRequest,
      O: SearchResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

