import { Box, FormControl, FormLabel, HStack, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { Loading } from '@icon-park/react';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { OrderTableRow } from 'features/order/components/OrderTableRow';
import { getOrderWhere, useOrderFilter } from 'features/order/hooks/useOrderFilter';
import { useQueryParams } from 'features/order/hooks/useOrderQueryPrams';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { UserSelectBox } from 'features/user/components/UserSelectBox';
import { orderFragment$key } from 'gql/__generated__/orderFragment.graphql';
import { orderPageQuery } from 'gql/__generated__/orderPageQuery.graphql';
import { useEffect } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';

const OrderPageQuery = graphql`
 query orderPageQuery ($where: OrderWhereInput, $demandID: ID!, $isDemandSkip: Boolean!, $supplierID: ID!, $isSupplierSkip: Boolean!) {
   ...orderFragment @arguments(where: $where)
   companies(where: {id: $demandID}) @skip(if: $isDemandSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
    suppliers(where: {id: $supplierID}) @skip(if: $isSupplierSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
 }
`;

const OrderFragment = graphql`
 fragment orderFragment on Query
   @refetchable(queryName: "orderFragmentRefetchQuery")
   @argumentDefinitions (
     count: { type: "Int", defaultValue: 100 }
     after: { type: "Cursor" }
     direction: { type: "OrderDirection", defaultValue: DESC }
     field: { type: "OrderOrderField", defaultValue: CREATED_AT }
     where: {type: "OrderWhereInput", defaultValue: null}
   )
 {
   orders(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "orderFragment_orders") {
     edges {
       cursor
       node {
         ...OrderTableRowFragment
       }
     }
     totalCount
   }
 }
`;

export function OrderPage() {
  const { updateQueryParams } = useQueryParams();
  const [searchParams] = useSearchParams();
  const demandId = searchParams.get('demand');
  const supplierId = searchParams.get('supplier');
  const assigneeId = searchParams.get('assignee');

  const query = useLazyLoadQuery<orderPageQuery>(
    OrderPageQuery,
    {
      ...getOrderWhere({
        userId: assigneeId || '',
        demandId: demandId || '',
        supplierId: supplierId || '',
      }),
      demandID: demandId || '',
      isDemandSkip: !demandId,
      supplierID: supplierId || '',
      isSupplierSkip: !supplierId,
    },
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    OrderFragment,
    query as orderFragment$key,
  );
  const {
    onClearDemandInput,
    onClearSupplierInput,
    onChangeUser,
    onChangeDemand,
    onChangeSupplier,
    isPending,
  } = useOrderFilter(refetch);

  const handleClickMore = () => loadNext(100);

  const demand = (query.companies?.edges || [])[0]?.node;
  const supplier = (query.suppliers?.edges || [])[0]?.node;

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    updateQueryParams({ demand: demandId || '', assignee: assigneeId || '' });
  }, [demandId, assigneeId]);

  return (
    <>
      <HStack mt="1.5rem">
        <FormControl w="220px">
          <FormLabel mb={1}>デマンド</FormLabel>
          <CompanyComboBox
            onChangeSelected={onChangeDemand}
            defaultSelectedItem={demand || { id: '', name: '' }}
            onClearInput={onClearDemandInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>サプライヤー</FormLabel>
          <SupplierComboBox
            onChangeSelected={onChangeSupplier}
            defaultSelectedItem={supplier || { id: '', name: '' }}
            onClearInput={onClearSupplierInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>担当者</FormLabel>
          <UserSelectBox
            value={assigneeId || ''}
            enableValue={true}
            onChange={(e) => onChangeUser(e.target.value)}
          />
        </FormControl>
      </HStack>
      {isPending ? (
        <Loading />
      ) : (
        <Box mt="1.5rem">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th w="22%">件名</Th>
                <Th w="16%">デマンド</Th>
                <Th w="16%">サプライヤー</Th>
                <Th w="11%">作成日</Th>
                <Th w="9%">担当者</Th>
                <Th w="7%" textAlign="center">
                  見積依頼
                </Th>
                <Th w="7%" textAlign="center">
                  見積書
                </Th>
                <Th w="7%" textAlign="center">
                  発注請書
                </Th>
                <Th />
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {data.orders.edges?.map(
                (edge, i) =>
                  edge?.node && (
                    <OrderTableRow key={`order-${edge?.cursor}-${i}`} queryRef={edge?.node} />
                  ),
              )}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.orders.totalCount}
              currentLength={data.orders.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
