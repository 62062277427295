import { initializeQuery as InitializeQuery } from 'gql/__generated__/initializeQuery.graphql';
import { useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { Navigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const initializeQuery = graphql`
  query initializeQuery {
    currentUser {
      userID
      firstName
      lastName
      phoneNumber
    }
  }
`;

export const InitializeSetup = () => {
  const { currentUser } = useLazyLoadQuery<InitializeQuery>(
    initializeQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const isRedirect = useMemo(() => !currentUser.firstName && !currentUser.lastName, [currentUser]);

  if (isRedirect) {
    return <Navigate to={paths.settings.profile.url()} />;
  }

  return null;
};
