import { Box, Flex, VStack } from '@chakra-ui/react';
import { InvoiceInputFormValueType } from 'features/invoice/form';
import { Control, useWatch } from 'react-hook-form';
import {
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

export const InvoiceInputFormDetailPrices = ({
  control,
}: { control: Control<InvoiceInputFormValueType> }) => {
  const details = useWatch({ name: 'details', control });

  const detailsForCalculation = details.map((detail) => ({
    quantity: detail.quantity || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    tax: detail.tax,
  }));

  return (
    <VStack w="full" spacing={2} alignItems="stretch">
      <Flex justify="space-between">
        <Box w="140px" fontSize="sm">
          小計
        </Box>
        <Box fontSize="sm">{calculateSubtotalAmount(detailsForCalculation).toLocaleString()}</Box>
      </Flex>
      <Flex justify="space-between">
        <Box whiteSpace="nowrap" w="140px" fontSize="sm">
          消費税
        </Box>
        <Box fontSize="sm">{calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}</Box>
      </Flex>
      <Flex justify="space-between">
        <Box w="140px" fontWeight="bold">
          合計金額
        </Box>
        <Box fontWeight="bold">{calculateTotalAmount(detailsForCalculation).toLocaleString()}</Box>
      </Flex>
    </VStack>
  );
};
