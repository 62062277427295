import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  useToken,
} from '@chakra-ui/react';
import { DeleteKey } from '@icon-park/react';
import { useCombobox } from 'downshift';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'rooks';

export type ComboBoxItem = { id: string; name: string };
type Props = {
  /** デフォルトで選択されているアイテム */
  defaultSelectedItem?: ComboBoxItem;

  /** ドロップダウンリストに表示するアイテム */
  items: ComboBoxItem[];

  /** 選択状態がクリアされた場合に呼び出すコールバック関数 */
  onReset: () => void;

  /** 選択されたアイテムが変更された場合に呼び出すコールバック関数 */
  onChangeSelected: (value: ComboBoxItem) => void;
  onFilterItems?: (
    items: ComboBoxItem[],
    input: string,
    opts?: { signal?: AbortSignal },
  ) => Promise<ComboBoxItem[]>;
} & Omit<InputProps, 'value'>;

const filterItems = async (items: ComboBoxItem[], input: string) =>
  items.filter(
    (value): value is NonNullable<typeof value> =>
      value != null && value.name.trim().toLowerCase().includes(input.trim().toLowerCase()),
  );

export const ComboBox = ({
  defaultSelectedItem,
  items,
  type = 'text',
  placeholder = '検索',
  isDisabled = false,
  onReset,
  onChangeSelected,
  onFilterItems = filterItems,
}: Props) => {
  const gray400 = useToken('colors', 'gray.400');
  const [data, setData] = useState(items.slice(0, 100));
  const [inputValue, setInputValue] = useState(defaultSelectedItem?.name || '');
  const setValueDebounced = useDebounce(setInputValue, 800);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    // UseComboboxStateのプロパティ

    /** 選択中のアイテムのindex */
    highlightedIndex,

    /** 現在選択中のアイテム */
    selectedItem: currentSelectedItem,

    /** ドロップダウンの開閉状態 */
    isOpen,

    /**
     * 現在の入力値
     * @note アイテム名ではなく、実際の入力値が入る
     */
    inputValue: actualInputValue,

    // UseComboboxActionsのプロパティ

    /** ドロップダウンにわたすprops */
    getMenuProps,

    /** ドロップダウンアイテムにわたすprops */
    getItemProps,

    /** inputにわたすprops */
    getInputProps,

    // UseComboboxPropGettersのプロパティ

    /** 選択リセット時に呼び出す関数 */
    reset,

    /** アイテムの選択時に呼び出す関数 */
    selectItem,

    /** 入力値を変更する関数 */
    setInputValue: setActualInputValue,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setValueDebounced(inputValue || '');
    },
    items: data || [],
    itemToString(item) {
      return item ? item.name : '';
    },
    onSelectedItemChange({ selectedItem }) {
      if (
        selectedItem &&
        !(
          selectedItem.id === currentSelectedItem?.id &&
          selectedItem.name === currentSelectedItem?.name
        )
      ) {
        onChangeSelected(selectedItem);
        inputRef.current?.blur();
      }
    },
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          if (!changes.selectedItem) {
            return {
              ...changes,
              inputValue: '',
            };
          }
          return {
            ...changes,
            inputValue: changes.selectedItem.name,
          };
        default:
          return changes;
      }
    },
    initialSelectedItem: defaultSelectedItem,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleClearInput = useCallback(() => {
    reset();
    setData(items.slice(0, 100));
    onReset();
  }, [reset, setData, items, onReset]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: handleClearInputとonFilterItemsを追加するとabortが発生するため
  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      // 入力状態が変更された場合

      if (actualInputValue === '' && currentSelectedItem) {
        // 入力値が空になったら選択状態を解除する
        handleClearInput();
      }

      // 入力値で選択肢をフィルタリングする
      const filteredItems = await onFilterItems(items, inputValue || '', {
        signal: controller.signal,
      });

      setData(filteredItems.slice(0, 100));
    })();

    return () => {
      controller.abort();
    };
  }, [inputValue, actualInputValue, currentSelectedItem /* handleClearInput, onFilterItems */]);

  useEffect(() => {
    // デフォルトで選択されているアイテムが指定されている場合は選択状態にする
    if (defaultSelectedItem !== undefined) {
      if (defaultSelectedItem.id && defaultSelectedItem?.name) {
        selectItem(defaultSelectedItem);
        setActualInputValue(defaultSelectedItem?.name);
      } else {
        reset();
      }
    }
  }, [defaultSelectedItem, reset, selectItem, setActualInputValue]);

  return (
    <Box position="relative">
      <InputGroup size="md">
        <Input
          type={type}
          placeholder={placeholder}
          {...getInputProps({ ref: inputRef })}
          isDisabled={isDisabled}
          autoComplete="off"
        />
        {!!currentSelectedItem && (
          <InputRightElement>
            <IconButton
              aria-label="clear-order-item"
              icon={<DeleteKey size="1rem" fill={gray400} />}
              size="sm"
              variant="text"
              onClick={handleClearInput}
              isDisabled={isDisabled}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <List
        position="absolute"
        right="0"
        top="42px"
        left="0"
        zIndex={3}
        bg="white"
        w="full"
        borderWidth={isOpen ? '1px' : 0}
        borderColor="gray.200"
        borderRadius="6px"
        boxShadow={
          isOpen
            ? '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            : 'none'
        }
        height={isOpen ? 'auto' : 0}
        maxHeight="15rem"
        overflowY="scroll"
        {...getMenuProps()}
      >
        {isOpen && data.length > 0 && (
          <>
            <ListItem w="100%" textAlign="left" color="gray.500" px={2} pt={2} fontSize="sm">
              リストから選択しましょう
            </ListItem>
            {data.map((result, i) => (
              <ListItem
                key={`combobox-data-${result?.id}-${i}`}
                w="100%"
                textAlign="left"
                bg={highlightedIndex === i ? 'gray.100' : 'inherit'}
                _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                p={2}
                {...getItemProps({
                  index: i,
                  item: result,
                })}
              >
                {result?.name}
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Box>
  );
};
