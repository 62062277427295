import { cdate } from 'cdate';

const DEFAULT_FORMAT = 'YYYY/M/D(ddd)';

export const cdateJST = cdate().tz('Asia/Tokyo').cdateFn();

export const getToday = () => cdateJST().locale('ja').format(DEFAULT_FORMAT);

export const dateFormat = (date: string | number | Date | undefined) =>
  cdateJST(date).locale('ja').format(DEFAULT_FORMAT);

export const dateFormatWithTime = (date: string | number | Date | undefined) =>
  cdateJST(date).locale('ja').format(`${DEFAULT_FORMAT} HH:mm`);

export const dateFormatWithoutDayOfWeek = (date: string | number | Date | undefined) => {
  if (!date) return '-';
  cdateJST(date).locale('ja').format('YYYY/M/D');
};

export const dateFormatWithTimeWithoutDayOfWeek = (date: string | number | Date | undefined) => {
  if (!date) return '-';
  return cdateJST(date).locale('ja').format('YYYY/M/D HH:mm');
};

export const dateFormatHyphenDate = (date?: string | number | Date | undefined) => {
  if (date) return cdateJST(date).locale('ja').format('YYYY-MM-DD');
  return cdateJST().locale('ja').format('YYYY-MM-DD');
};

export const isAfterOrSameDate = (
  date: string | number | Date | undefined,
  compareDate: string | number | Date | undefined,
) => {
  return cdateJST(date) >= cdateJST(compareDate);
};
