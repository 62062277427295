/**
 * @generated SignedSource<<cc87da3cb0dbd662834835d57d01c744>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationDetailSideBarFragment$data = {
  readonly company: {
    readonly id: string;
    readonly name: string;
  };
  readonly createdAt: any;
  readonly createdBy: {
    readonly profile: ReadonlyArray<{
      readonly firstName: string;
      readonly lastName: string;
    }> | null;
  };
  readonly estimationResponse: {
    readonly assignee: {
      readonly estimationRequest: {
        readonly id: string;
      };
    };
  } | null;
  readonly expirationPeriod: string;
  readonly id: string;
  readonly internalAssignees: ReadonlyArray<{
    readonly user: {
      readonly id: string;
      readonly profile: ReadonlyArray<{
        readonly firstName: string;
        readonly lastName: string;
      }> | null;
    };
  }> | null;
  readonly salesOrders: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly supplier: {
    readonly id: string;
    readonly name: string;
  };
  readonly title: string;
  readonly " $fragmentType": "EstimationDetailSideBarFragment";
};
export type EstimationDetailSideBarFragment$key = {
  readonly " $data"?: EstimationDetailSideBarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationDetailSideBarFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationDetailSideBarFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Supplier",
      "kind": "LinkedField",
      "name": "supplier",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationResponse",
      "kind": "LinkedField",
      "name": "estimationResponse",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationRequestAssignee",
          "kind": "LinkedField",
          "name": "assignee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationRequest",
              "kind": "LinkedField",
              "name": "estimationRequest",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrder",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationInternalAssignee",
      "kind": "LinkedField",
      "name": "internalAssignees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Estimation",
  "abstractKey": null
};
})();

(node as any).hash = "5ecdd450ff2e613772fd0f06eccee4cd";

export default node;
