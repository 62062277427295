import { errorMessages } from 'utils/validation';
import { z } from 'zod';

export const profileFormSchema = z.object({
  lastName: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(20, { message: errorMessages.MAX_LENGTH(20) }),
  firstName: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(20, { message: errorMessages.MAX_LENGTH(20) }),
  phoneNumber: z.string().max(50, { message: errorMessages.MAX_LENGTH(50) }),
});

export type ProfileFormDataType = z.infer<typeof profileFormSchema>;
