/**
 * @generated SignedSource<<fbe835fb217850c6d97a7b44dcf467a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDeleteEstimationRequestMutation$variables = {
  id: string;
};
export type useDeleteEstimationRequestMutation$data = {
  readonly deleteEstimationRequest: boolean;
};
export type useDeleteEstimationRequestMutation = {
  response: useDeleteEstimationRequestMutation$data;
  variables: useDeleteEstimationRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteEstimationRequest",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteEstimationRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteEstimationRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "62871ed1c6420f53adbb9005e10de3de",
    "id": null,
    "metadata": {},
    "name": "useDeleteEstimationRequestMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteEstimationRequestMutation(\n  $id: ID!\n) {\n  deleteEstimationRequest(id: $id)\n}\n"
  }
};
})();

(node as any).hash = "e7a915fd6da5e0ae18858b7f3c3de27c";

export default node;
