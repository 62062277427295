/**
 * @generated SignedSource<<089dbd9835e64b85bd7c2c3bfa42ce33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrderAssigneeRecipientType = "cc" | "to" | "%future added value";
export type OrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type sending_OrderSendingPageQuery$variables = {
  id: string;
};
export type sending_OrderSendingPageQuery$data = {
  readonly orders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assignees: ReadonlyArray<{
          readonly contact: {
            readonly id: string;
          };
          readonly recipientType: OrderAssigneeRecipientType;
        }> | null;
        readonly company: {
          readonly id: string;
          readonly name: string;
        };
        readonly deliveryInfo: {
          readonly desiredDeliveryDate: string;
          readonly recipientsText: string | null;
        } | null;
        readonly detailMessage: string | null;
        readonly details: ReadonlyArray<{
          readonly id: string;
          readonly item: {
            readonly id: string;
            readonly name: string;
            readonly quantity: any;
            readonly tax: {
              readonly id: string;
              readonly rate: number;
            };
            readonly unitPrice: number;
          } | null;
          readonly type: OrderDetailType;
        }> | null;
        readonly estimationID: string | null;
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
            }> | null;
          };
        }> | null;
        readonly mailBodyMessage: string | null;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
        readonly title: string;
      } | null;
    } | null> | null;
  };
};
export type sending_OrderSendingPageQuery = {
  response: sending_OrderSendingPageQuery$data;
  variables: sending_OrderSendingPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderDetail",
  "kind": "LinkedField",
  "name": "details",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderItem",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        },
        {
          "alias": "tax",
          "args": null,
          "concreteType": "TaxCategory",
          "kind": "LinkedField",
          "name": "taxCategory",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detailMessage",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailBodyMessage",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "desiredDeliveryDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipientsText",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipientType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SupplierContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sending_OrderSendingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderDeliveryInfo",
                    "kind": "LinkedField",
                    "name": "deliveryInfo",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderAssignee",
                    "kind": "LinkedField",
                    "name": "assignees",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sending_OrderSendingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderDeliveryInfo",
                    "kind": "LinkedField",
                    "name": "deliveryInfo",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderAssignee",
                    "kind": "LinkedField",
                    "name": "assignees",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7865b91edee58c7adfa0a11c0a78fda8",
    "id": null,
    "metadata": {},
    "name": "sending_OrderSendingPageQuery",
    "operationKind": "query",
    "text": "query sending_OrderSendingPageQuery(\n  $id: ID!\n) {\n  orders(where: {id: $id}) {\n    edges {\n      node {\n        id\n        company {\n          id\n          name\n        }\n        supplier {\n          id\n          name\n        }\n        details {\n          id\n          type\n          item {\n            id\n            name\n            unitPrice\n            quantity\n            tax: taxCategory {\n              id\n              rate\n            }\n          }\n        }\n        title\n        detailMessage\n        mailBodyMessage\n        deliveryInfo {\n          desiredDeliveryDate\n          recipientsText\n          id\n        }\n        internalAssignees {\n          user {\n            id\n            profile {\n              lastName\n              firstName\n              id\n            }\n          }\n          id\n        }\n        assignees {\n          recipientType\n          contact {\n            id\n          }\n          id\n        }\n        estimationID\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "35a146baeb9cadc7aea884ca147da379";

export default node;
