import { Box, HStack, VStack } from '@chakra-ui/react';
import { SupplierDetailedInfoFragment$key } from 'gql/__generated__/SupplierDetailedInfoFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormatWithTime } from 'utils/date';
import { concatFullName } from 'utils/label';

const SupplierDetailedInfoFragment = graphql`
  fragment SupplierDetailedInfoFragment on Supplier {
    id
    name
    createdAt
    createdBy {
      profile {
        lastName
        firstName
      }
    }
    contacts {
      id
      lastName
      firstName
      email
      memos {
        body
      }
    }
  }
`;

type Props = {
  queryRef: SupplierDetailedInfoFragment$key;
};

export const SupplierDetailedInfo = ({ queryRef }: Props) => {
  const { name, createdAt, createdBy, contacts } = useFragment(
    SupplierDetailedInfoFragment,
    queryRef,
  );

  return (
    <VStack spacing={6} alignItems="flex-start">
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">企業名</Box>
        <Box>{name}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start" data-testid="supplier-detailed-info-contacts">
        <Box color="gray.500">取引先担当者名 / メールアドレス</Box>
        {!contacts || contacts.length === 0 ? (
          <Box>-</Box>
        ) : (
          <VStack spacing={3} alignItems="flex-start">
            {contacts.map((contact) => (
              <Box key={contact.id}>
                {concatFullName({ lastName: contact.lastName, firstName: contact.firstName })} /{' '}
                {contact.email || '-'}
                <HStack fontSize={14} color="gray.600" alignItems="flex-start" flexShrink="0">
                  <Box minWidth="fit-content">メモ:</Box>
                  <Box whiteSpace="pre-wrap">
                    {contact.memos && contact.memos.length > 0 ? contact.memos[0].body : '-'}
                  </Box>
                </HStack>
              </Box>
            ))}
          </VStack>
        )}
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">作成日時</Box>
        <Box>{dateFormatWithTime(createdAt)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start" data-testid="supplier-detailed-info-creator">
        <Box color="gray.500">登録者</Box>
        <Box>
          {createdBy.profile?.[0]?.lastName && createdBy.profile?.[0]?.firstName
            ? concatFullName({
                lastName: createdBy.profile[0].lastName,
                firstName: createdBy.profile[0].firstName,
              })
            : '-'}
        </Box>
      </VStack>
    </VStack>
  );
};
