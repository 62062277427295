/**
 * @generated SignedSource<<5944f9d578fed5e8e2b7b39e63588401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderAssigneeRecipientType = "cc" | "to" | "%future added value";
export type OrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type edit_OrderEditPageFragment$data = {
  readonly orders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assignees: ReadonlyArray<{
          readonly contact: {
            readonly id: string;
          };
          readonly recipientType: OrderAssigneeRecipientType;
        }> | null;
        readonly company: {
          readonly id: string;
          readonly name: string;
        };
        readonly deliveryInfo: {
          readonly desiredDeliveryDate: string;
          readonly recipientsText: string | null;
        } | null;
        readonly detailMessage: string | null;
        readonly details: ReadonlyArray<{
          readonly id: string;
          readonly item: {
            readonly id: string;
            readonly name: string;
            readonly quantity: any;
            readonly tax: {
              readonly id: string;
              readonly rate: number;
            };
            readonly unitPrice: number;
          } | null;
          readonly salesOrderDetailID: string | null;
          readonly type: OrderDetailType;
        }> | null;
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
            }> | null;
          };
        }> | null;
        readonly mailBodyMessage: string | null;
        readonly salesOrders: ReadonlyArray<{
          readonly id: string;
        }> | null;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
        readonly title: string;
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "edit_OrderEditPageFragment";
};
export type edit_OrderEditPageFragment$key = {
  readonly " $data"?: edit_OrderEditPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"edit_OrderEditPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "edit_OrderEditPageFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "OrderConnection",
      "kind": "LinkedField",
      "name": "orders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Supplier",
                  "kind": "LinkedField",
                  "name": "supplier",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderDetail",
                  "kind": "LinkedField",
                  "name": "details",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "salesOrderDetailID",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderItem",
                      "kind": "LinkedField",
                      "name": "item",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "unitPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "quantity",
                          "storageKey": null
                        },
                        {
                          "alias": "tax",
                          "args": null,
                          "concreteType": "TaxCategory",
                          "kind": "LinkedField",
                          "name": "taxCategory",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "detailMessage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailBodyMessage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderDeliveryInfo",
                  "kind": "LinkedField",
                  "name": "deliveryInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "desiredDeliveryDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "recipientsText",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderAssignee",
                  "kind": "LinkedField",
                  "name": "assignees",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "recipientType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SupplierContact",
                      "kind": "LinkedField",
                      "name": "contact",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderInternalAssignee",
                  "kind": "LinkedField",
                  "name": "internalAssignees",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserProfile",
                          "kind": "LinkedField",
                          "name": "profile",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lastName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrder",
                  "kind": "LinkedField",
                  "name": "salesOrders",
                  "plural": true,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryConnection",
      "kind": "LinkedField",
      "name": "taxCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f00168d823802e9645ec44e3da40b22c";

export default node;
