import { Loading } from '@icon-park/react';
import { SalesOrderConfirm } from 'features/salesOrders/components/SalesOrderConfirm';
import { SalesOrderForm } from 'features/salesOrders/components/SalesOrderForm';
import { SALES_ORDER_FORM_TYPE, SALES_ORDER_SUBMIT_TYPE } from 'features/salesOrders/form';
import { useSalesOrderCreateForm } from 'features/salesOrders/hooks/useSalesOrderCreateForm';
import { new_SalesOrderNewPageQuery } from 'gql/__generated__/new_SalesOrderNewPageQuery.graphql';
import { Suspense, useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { buildDefaultValue } from './helper';

const salesOrderNewPageQuery = graphql`
  query new_SalesOrderNewPageQuery ($id: ID!, $isSkip: Boolean!){
    estimations(where: {id: $id}) @skip(if: $isSkip) {
      edges {
        node {
          id
          company {
            id
            name
          }
          supplier {
            id
            name
          }
          title
          detailMessage
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}, where: {documentDisplayStatus: shown}) {
            edges {
              node {
                documentDisplayStatus
                item {
                  id
                  name
                  quantity
                  unitPrice
                  unitSellingPrice
                  tax: taxCategory {
                    id
                    rate
                  }
                }
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }
    taxCategories {
      edges {
        node {
          id
          rate
        }
      }
    }
    currentUser {
      userID
      firstName
      lastName
    }
  }
`;

export const SalesOrderNewPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');
  const estimationId = searchParams.get('estimationId');

  const { estimations, taxCategories, currentUser } = useLazyLoadQuery<new_SalesOrderNewPageQuery>(
    salesOrderNewPageQuery,
    {
      id: estimationId || '',
      isSkip: !estimationId,
    },
    { fetchPolicy: 'network-only' },
  );

  const defaultValue = buildDefaultValue({ estimations, tax: taxCategories, currentUser });

  const {
    showPreview,
    values,
    isMutationInFlight,
    onSubmit,
    toConfirm,
    toForm,
    setShowBlocker,
    isDirtyMemo,
  } = useSalesOrderCreateForm({ defaultValue, estimationId: estimationId || '' });

  useEffect(() => {
    if (!showPreview && type) {
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, showPreview, type]);

  return (
    <Suspense fallback={<Loading />}>
      {showPreview && type === SALES_ORDER_SUBMIT_TYPE.confirm ? (
        <SalesOrderConfirm
          values={values}
          onClickSubmit={onSubmit}
          isMutationInFlight={isMutationInFlight}
          onClickToNewForm={toForm}
        />
      ) : (
        <SalesOrderForm
          defaultValues={values}
          onClickToConfirm={toConfirm}
          type={SALES_ORDER_FORM_TYPE.new}
          previousUrl={estimationId ? paths.estimation._id(estimationId).url : undefined}
          setShowBlocker={setShowBlocker}
          isDirtyMemo={isDirtyMemo}
        />
      )}
    </Suspense>
  );
};
