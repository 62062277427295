export const errorMessages = {
  REQUIRED: 'ご入力をお願いいたします',
  MAX_LENGTH: (limit: number) => `${limit}文字以内でのご入力をお願いいたします`,
  REQUIRED_POSITIVE_NUMBER: '1以上の整数でのご入力をお願いいたします',
  QUANTITY_NUMBER_FORMAT: '0以上、小数点を含まない数字でのご入力をお願いいたします',
  QUANTITY_MAX_DIGIT: '9桁以下でのご入力をお願いいたします',
  REQUIRED_DECIMAL_PLACE: '0以上、小数点第2位までの数字でのご入力をお願いいたします',
  UNIT_PRICE_MAX_DIGIT: '小数点第2位までを含め、11桁以下でのご入力をお願いいたします',
  UNIT_PRICE_DIGIT: '-999,999,999.99~999,999,999.99の範囲でご入力をお願いいたします',
  REQUIRED_UNIT_PRICE: '小数点第2位までの数字でご入力をお願いいたします',
};
