import { Environments } from 'constants/environment';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import * as firebase from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import type { Auth } from 'firebase/auth';
import { getEnvironment } from 'utils/environment';

const firebaseAppName = 'lube-frontend-authentication';

const firebaseConfig: firebase.FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

function initializeAuth(): Auth {
  const app = firebase.getApps()[0] ?? firebase.initializeApp(firebaseConfig, firebaseAppName);
  const auth: Auth = getAuth(app);

  // developmentはemulatorを使う
  if (getEnvironment() === Environments.Development) {
    connectAuthEmulator(auth, 'http://0.0.0.0:9999');
  }

  return auth;
}

export const auth = initializeAuth();

const getFirebaseApp = (): firebase.FirebaseApp => {
  return firebase.getApp(firebaseAppName);
};

export const getFirebaseAuth = (): Auth => {
  return getAuth(getFirebaseApp());
};

export const getFirebaseAnalytics = (): Analytics => {
  return getAnalytics(getFirebaseApp());
};

interface CustomClickEventParams {
  trackingName: string;
  path: string;
  userName: string;
}

export const logCustomClickEvent = (analytics: Analytics, eventParams: CustomClickEventParams) => {
  logEvent(analytics, 'custom_click', {
    tracking_name: eventParams.trackingName,
    path: eventParams.path,
    user_name: eventParams.userName,
  });
};
