import { Text, VStack } from '@chakra-ui/react';
import { useOwnCompanyInfo } from 'hooks/useOwnCompanyInfo';
import { memo } from 'react';

export const InformationShizai = memo(() => {
  const info = useOwnCompanyInfo();

  return (
    <VStack align="left" spacing={2}>
      <Text fontWeight="bold">{info.name}</Text>
      <Text fontSize="sm">{info.fullAddress}</Text>
      <Text fontSize="sm">登録番号: {info.corporateNumber}</Text>
    </VStack>
  );
});

InformationShizai.displayName = 'InformationShizai';
