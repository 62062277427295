/**
 * @generated SignedSource<<077780492a1acc74124433de1f0f2222>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EstimationRequestDetailType = "item" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EstimationRequestPreviewItemsFragment$data = {
  readonly details: ReadonlyArray<{
    readonly item: {
      readonly name: string;
      readonly quantity: any;
      readonly specJSON: any;
    } | null;
    readonly type: EstimationRequestDetailType;
  }> | null;
  readonly " $fragmentType": "EstimationRequestPreviewItemsFragment";
};
export type EstimationRequestPreviewItemsFragment$key = {
  readonly " $data"?: EstimationRequestPreviewItemsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationRequestPreviewItemsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationRequestPreviewItemsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationRequestDetail",
      "kind": "LinkedField",
      "name": "details",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationRequestItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "specJSON",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EstimationRequest",
  "abstractKey": null
};

(node as any).hash = "d86bcf929c61195564ded962fba23e60";

export default node;
