import { useEditSupplierMutation } from 'gql/__generated__/useEditSupplierMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { SupplierFormDataType } from '../zod';

export const useEditSupplier = (id: string) => {
  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useEditSupplierMutation>(
    graphql`
      mutation useEditSupplierMutation(
        $id: ID!
        $input: SupplierUpdateInput!
      ) {
        updateSupplier(id: $id,input: $input) {
          id
        }
      }
    `,
  );

  const onSubmit = (data: SupplierFormDataType) => {
    commitMutation({
      variables: {
        id: id,
        input: {
          name: data.name,
          contacts: data.contacts.map((c) => ({ ...c, firstName: c.firstName || '' })),
        },
      },
      onCompleted: () => {
        toast({
          title: 'サプライヤーを更新しました',
          status: 'success',
        });
        navigate(paths.supplier.url());
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
