import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { Loading } from 'components/Loading';
import { useLiveQuery } from 'dexie-react-hooks';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { EstimationRequestSearchForm } from 'features/estimationRequest/components/EstimationRequestSearchForm';
import { EstimationRequestTableRow } from 'features/estimationRequest/components/EstimationRequestTableRow';
import {
  getEstimationRequestWhere,
  useEstimationRequestFilter,
} from 'features/estimationRequest/hooks/useEstimationRequestFilter';
import { useQueryParams } from 'features/estimationRequest/hooks/useEstimationRequestQueryParams';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { UserSelectBox } from 'features/user/components/UserSelectBox';
import { estimationRequestPageFragment$key } from 'gql/__generated__/estimationRequestPageFragment.graphql';
import { estimationRequestPageQuery } from 'gql/__generated__/estimationRequestPageQuery.graphql';
import { useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useState } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Link, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { getEstimationRequestDraftCount } from 'repositories/estimationRequestDraftRepository';
import { paths } from 'utils/paths';

const EstimationRequestPageQuery = graphql`
  query estimationRequestPageQuery ($where: EstimationRequestWhereInput, $demandID: ID!, $isDemandSkip: Boolean!, $supplierID: ID!, $isSupplierSkip: Boolean!, $isSkipEstimationRequests: Boolean!) {
    ...estimationRequestPageFragment @arguments(isSkip: $isSkipEstimationRequests, where: $where)
    companies(where: {id: $demandID}) @skip(if: $isDemandSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
    suppliers(where: {id: $supplierID}) @skip(if: $isSupplierSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const EstimationRequestFragment = graphql`
  fragment estimationRequestPageFragment on Query
    @refetchable(queryName: "estimationRequestFragmentRefetchQuery")
    @argumentDefinitions (
      count: { type: "Int", defaultValue: 100 }
      after: { type: "Cursor" }
      direction: { type: "OrderDirection", defaultValue: DESC }
      field: { type: "EstimationRequestOrderField", defaultValue: CREATED_AT }
      where: { type: "EstimationRequestWhereInput", defaultValue: null }
      isSkip: { type: "Boolean", defaultValue: false }
    )
  {
    estimationRequests(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "estimationRequestFragment_estimationRequests") @skip(if: $isSkip) {
      edges {
        cursor
        node {
          ...EstimationRequestTableRowFragment
        }
      }
      totalCount
    }
  }
`;

export function EstimationRequestPage() {
  const { updateQueryParams } = useQueryParams();
  const [searchParams] = useSearchParams();

  const { trackCustomClickEvent } = useAnalytics();

  const demandId = searchParams.get('demand');
  const supplierId = searchParams.get('supplier');
  const assigneeId = searchParams.get('assignee');
  const searchWord = searchParams.get('searchWord');

  const [estimationRequestIDs, setEstimationRequestIDs] = useState<string[]>([]);
  const [isSkipEstimationRequests, setIsSkipEstimationRequests] = useState<boolean>(!!searchWord);

  const query = useLazyLoadQuery<estimationRequestPageQuery>(
    EstimationRequestPageQuery,
    {
      ...getEstimationRequestWhere({
        userId: assigneeId || '',
        demandId: demandId || '',
        supplierId: supplierId || '',
        ids: estimationRequestIDs,
      }),
      demandID: demandId || '',
      isDemandSkip: !demandId,
      supplierID: supplierId || '',
      isSupplierSkip: !supplierId,
      isSkipEstimationRequests,
    },
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    EstimationRequestFragment,
    query as estimationRequestPageFragment$key,
  );

  const company = (query.companies?.edges || [])[0]?.node;
  const supplier = (query.suppliers?.edges || [])[0]?.node;

  const {
    onClearDemandInput,
    onClearSupplierInput,
    onChangeUser,
    onChangeDemand,
    onChangeSupplier,
    onChangeSearchWord,
    isPending,
  } = useEstimationRequestFilter(refetch, estimationRequestIDs);

  const handleChangeSearchWord = (word: string, ids: string[], isSkip: boolean) => {
    onChangeSearchWord(word);
    setIsSkipEstimationRequests(isSkip);
    setEstimationRequestIDs(ids);
  };
  const handleClickMore = () => loadNext(100);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    updateQueryParams({
      demand: demandId || '',
      assignee: assigneeId || '',
      supplier: supplierId || '',
      searchWord: searchWord || '',
    });
  }, [demandId, assigneeId, supplierId, searchWord]);

  return (
    <>
      <HStack mt="1.5rem">
        <Link to={paths.estimationRequest.new.url()}>
          <Button
            colorScheme="blue"
            width="208px"
            onClick={() => trackCustomClickEvent('見積依頼作成')}
          >
            見積依頼作成
          </Button>
        </Link>
        <DraftFormButton />
      </HStack>
      <HStack mt="1.5rem">
        <FormControl w="220px">
          <FormLabel mb={1}>デマンド</FormLabel>
          <CompanyComboBox
            onChangeSelected={onChangeDemand}
            onClearInput={onClearDemandInput}
            defaultSelectedItem={company || { id: '', name: '' }}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>サプライヤー</FormLabel>
          <SupplierComboBox
            onChangeSelected={onChangeSupplier}
            defaultSelectedItem={supplier || { id: '', name: '' }}
            onClearInput={onClearSupplierInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>担当者</FormLabel>
          <UserSelectBox
            value={assigneeId || ''}
            enableValue={true}
            onChange={(e) => onChangeUser(e.target.value)}
          />
        </FormControl>
      </HStack>
      <HStack mt={3}>
        <FormControl w="676px">
          <FormLabel mb={1}>フリーワード検索</FormLabel>
          <EstimationRequestSearchForm
            defaultSearchWord={searchWord || ''}
            onChange={handleChangeSearchWord}
          />
          <FormHelperText>
            複数のワードで検索したい場合、単語を半角スペースで区切って入力しましょう。
          </FormHelperText>
        </FormControl>
      </HStack>
      {isPending ? (
        <Loading />
      ) : (
        <Box mt="1.5rem">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th w="22%">案件名</Th>
                <Th w="13%">デマンド</Th>
                <Th w="30%">サプライヤー</Th>
                <Th w="11%">作成日</Th>
                <Th w="13%">担当者</Th>
                <Th w="6%" />
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {data.estimationRequests?.edges?.map(
                (edge, i) =>
                  edge?.node && (
                    <EstimationRequestTableRow
                      key={`estimation-request-${edge?.cursor}-${i}`}
                      queryRef={edge?.node}
                    />
                  ),
              )}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.estimationRequests?.totalCount || 0}
              currentLength={data.estimationRequests?.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

// useLiveQueryによる再レンダリングを限定的にするためにコンポーネントを分割
const DraftFormButton = () => {
  const count = useLiveQuery(() => getEstimationRequestDraftCount());

  return (
    <>
      {!!count && (
        <Link to={paths.estimationRequest.new.url('draft')}>
          <Button colorScheme="blue" width="208px">
            下書きを復元
          </Button>
        </Link>
      )}
    </>
  );
};
