import { Box, Checkbox, CheckboxGroup, HStack, VStack } from '@chakra-ui/react';
import { SupplierContactCheckboxGroupQuery as SupplierContactCheckboxGroupQueryType } from 'gql/__generated__/SupplierContactCheckboxGroupQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { concatFullName } from 'utils/label';

type AddressTarget = 'to' | 'cc';

type Props = {
  supplierId: string;
  value: string[];
  addressTarget: AddressTarget;
  onChange: (selectedContact: { id: string; firstName: string; lastName: string }[]) => void;
};

const supplierContactCheckboxGroupQuery = graphql`
  query SupplierContactCheckboxGroupQuery ($supplierId: ID!, $skip: Boolean!) {
    supplierContacts(where: { supplierID: $supplierId }) @skip(if: $skip) {
      edges {
        node {
          id
          firstName
          lastName
          email
          memos {
            body
          }
        }
      }
    }
  }
`;

export const SupplierContactCheckboxGroup = ({
  supplierId,
  value,
  addressTarget,
  onChange,
}: Props) => {
  const { supplierContacts } = useLazyLoadQuery<SupplierContactCheckboxGroupQueryType>(
    supplierContactCheckboxGroupQuery,
    {
      supplierId: supplierId || '',
      skip: !supplierId,
    },
  );

  const contacts =
    supplierContacts?.edges
      ?.map((edge) => edge?.node)
      ?.filter((contact): contact is NonNullable<typeof contact> => contact != null) || [];

  if (contacts.length === 0) return null;

  return (
    <CheckboxGroup
      value={value}
      onChange={(selectedIds) => {
        const selectedContacts = selectedIds.map((id) => {
          return contacts.find((contact) => contact.id === id);
        });
        onChange(
          (
            selectedContacts.filter(
              (contact): contact is NonNullable<typeof contact> => contact != null,
            ) || []
          ).map((contact) => {
            return {
              id: contact.id,
              firstName: contact.firstName,
              lastName: contact.lastName,
              email: contact.email,
            };
          }),
        );
      }}
    >
      {contacts.map((contact) => (
        <Checkbox
          // 明示的に指定しないと、全て同じ id に紐づいてしまう
          id={`supplier-contact-${addressTarget}-${contact.id}`}
          key={contact.id}
          value={contact.id}
          alignItems="flex-start"
        >
          <VStack alignItems="flex-start" spacing={0}>
            <HStack lineHeight={1}>
              <Box fontWeight="bold" whiteSpace="nowrap" fontSize="m">
                {concatFullName({ lastName: contact.lastName, firstName: contact.firstName })}
              </Box>
              <Box color="gray.600" fontSize="m">
                {contact.email}
              </Box>
            </HStack>
            <HStack alignItems="flex-start" fontSize="sm" color="gray.500">
              <Box minWidth="fit-content">メモ:</Box>
              <Box whiteSpace="pre-wrap">
                {contact.memos && contact.memos.length > 0 ? contact.memos[0].body : '-'}
              </Box>
            </HStack>
          </VStack>
        </Checkbox>
      ))}
    </CheckboxGroup>
  );
};
