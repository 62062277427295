import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AddOne, Copy } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ChangeOrderButton } from 'components/ChangeOrderButton';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { ItemAmountPreview } from 'components/ItemAmountPreview';
import {
  SalesOrderFormValuesType,
  getSalesOrderDetailInitialValues,
} from 'features/salesOrders/form';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { SalesOrderFormDetailsFragment$key } from 'gql/__generated__/SalesOrderFormDetailsFragment.graphql';
import { SalesOrderFormDetailsQuery } from 'gql/__generated__/SalesOrderFormDetailsQuery.graphql';
import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { calculateDetailAmount, calculateGrossProfitMargin } from 'utils/priceCalculation';

const salesOrderFormDetailsQuery = graphql`
  query SalesOrderFormDetailsQuery($detailIds : [ID!]!, $isSkip: Boolean!) {
    salesOrderDetails(where: { idIn: $detailIds, hasInvoiceDetailsWith: { deletedAtIsNil: true }}) @skip(if: $isSkip) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

const salesOrderFormDetailsFragment = graphql`
  fragment SalesOrderFormDetailsFragment on Query
  {
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
    ...TaxSelectBoxFragment
  }
`;

export const SalesOrderFormDetails = ({
  control,
  getValues,
  setValue,
  register,
  errors,
  queryRef,
}: {
  control: Control<SalesOrderFormValuesType>;
  getValues: UseFormGetValues<SalesOrderFormValuesType>;
  setValue: UseFormSetValue<SalesOrderFormValuesType>;
  register: UseFormRegister<SalesOrderFormValuesType>;
  errors: Partial<FieldErrorsImpl<SalesOrderFormValuesType>>;
  queryRef: SalesOrderFormDetailsFragment$key;
}) => {
  const query = useFragment(salesOrderFormDetailsFragment, queryRef);
  const tax = query.taxCategories?.edges?.at(0)?.node;

  const details = useWatch({ name: 'details', control });

  const detailIds = details
    .map((detail) => detail.id)
    .filter((id): id is NonNullable<typeof id> => !!id);

  const { salesOrderDetails: invoicedDetails } = useLazyLoadQuery<SalesOrderFormDetailsQuery>(
    salesOrderFormDetailsQuery,
    {
      detailIds,
      isSkip: detailIds.length === 0,
    },
    { fetchPolicy: 'network-only' },
  );

  const invoicedDetailIds = invoicedDetails?.edges?.map((edge) => edge?.node?.id) || [];

  const [isDisabledDelete, setIsDisabledDelete] = useState(details.length === 1);

  details.forEach((detail, index) => {
    // nameにタブ文字を含む場合は全角スペースに置換する
    if (/\t/.test(detail.name)) {
      // console.log('detail.name', detail.name);
      setValue(`details.${index}.name`, detail.name.replace(/\t/g, '　'));
    }
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'details',
  });

  const appendDetail = () => {
    append({
      ...getSalesOrderDetailInitialValues({ id: tax?.id || '', rate: tax?.rate || '' }),
      unitPrice: '',
      quantity: '',
      unitSellingPrice: '',
    });
  };

  const handleClickUp = (index: number) => {
    const details = getValues('details');
    details.splice(index - 1, 2, details[index], details[index - 1]);
    setValue('details', details);
  };

  const handleClickDown = (index: number) => {
    const details = getValues('details');
    details.splice(index, 2, details[index + 1], details[index]);
    setValue('details', details);
  };

  const handleDuplicationItem = (index: number) => {
    const value = getValues(`details.${index}`);
    insert(
      index + 1,
      { ...value, id: undefined },
      { shouldFocus: true, focusName: `details.${index + 1}.quantity` },
    );
  };

  const isDisabledUpButton = (index: number) => index === 0;
  const isDisabledDownButton = (index: number) => index + 1 === fields.length;

  useEffect(() => {
    if (fields.length === 1) {
      setIsDisabledDelete(true);
    } else {
      setIsDisabledDelete(false);
    }
  }, [fields]);

  return (
    <VStack align="stretch" spacing={4}>
      {fields.map((field, index) => {
        const isInvoiced = invoicedDetailIds.includes(field.id);
        return (
          <HStack key={field.id} align="flex-start" spacing={2}>
            {/* 並び順変更する矢印 */}
            <VStack>
              <ChangeOrderButton
                type="up"
                onClick={() => handleClickUp(index)}
                isDisabled={isDisabledUpButton(index)}
              />
              <ChangeOrderButton
                type="down"
                onClick={() => handleClickDown(index)}
                isDisabled={isDisabledDownButton(index)}
              />
            </VStack>

            {/* 品名 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.name}
              isRequired
              w="22.5rem"
              isDisabled={isInvoiced}
            >
              <AutoResizeTextarea {...register(`details.${index}.name`)} />
              {isInvoiced && (
                <Text fontSize="sm" color="gray.500" mt={2}>
                  請求済みのため変更できません
                </Text>
              )}
              <ErrorMessage name={`details.${index}.name`} errors={errors} />
            </FormControl>

            {/* 確定納品日 */}
            <FormControl isInvalid={!!(errors.details || [])[index]?.fixedDeliveryDate} w="10rem">
              <Input type="date" {...register(`details.${index}.fixedDeliveryDate`)} />
              <ErrorMessage name={`details.${index}.fixedDeliveryDate`} errors={errors} />
            </FormControl>

            {/* 数量 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.quantity}
              isRequired
              flex={1}
              w="7.5rem"
              isDisabled={isInvoiced}
            >
              <ConfigurableNumberInput {...register(`details.${index}.quantity`)} />
              <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
            </FormControl>

            {/* 仕入単価 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.unitPrice}
              isRequired
              flex={1}
              w="7.5rem"
              isDisabled={isInvoiced}
            >
              <ConfigurableNumberInput {...register(`details.${index}.unitPrice`)} step="0.01" />
              <ErrorMessage name={`details.${index}.unitPrice`} errors={errors} />
            </FormControl>

            {/* 顧客単価 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.unitSellingPrice}
              isRequired
              flex={1}
              w="7.5rem"
              isDisabled={isInvoiced}
            >
              <ConfigurableNumberInput
                {...register(`details.${index}.unitSellingPrice`)}
                step="0.01"
              />
              <Text fontSize="sm" color="gray.500" textAlign="right" mt={2}>
                粗利率:{' '}
                {calculateGrossProfitMargin(
                  Number(getValues(`details.${index}.unitPrice`)) || 0,
                  Number(getValues(`details.${index}.unitSellingPrice`)) || 0,
                )}
                %
              </Text>
              <ErrorMessage name={`details.${index}.unitSellingPrice`} errors={errors} />
            </FormControl>

            {/* 税区分 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.tax?.id}
              isRequired
              flex={1}
              w="7rem"
              isDisabled={isInvoiced}
            >
              <Controller
                name={`details.${index}.tax`}
                control={control}
                render={() => (
                  <TaxSelectBox
                    defaultValue={getValues(`details.${index}.tax.id`)}
                    queryRef={query}
                    onChange={(e) => {
                      setValue(`details.${index}.tax`, {
                        ...getValues(`details.${index}.tax`),
                        ...{
                          id: e.target.value,
                          rate: e.target[e.target.selectedIndex].getAttribute(
                            'data-rate',
                          ) as string,
                        },
                      });
                    }}
                  />
                )}
              />
              <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
            </FormControl>

            {/* 金額 */}
            <Box w="7.5rem">
              <ItemAmountPreview
                amount={calculateDetailAmount({
                  quantity: getValues(`details.${index}.quantity`) || 0,
                  price: getValues(`details.${index}.unitSellingPrice`) || 0,
                })}
              />
            </Box>

            {/* 複製ボタン */}
            <Box>
              <IconButton
                onClick={() => handleDuplicationItem(index)}
                variant="ghost"
                isRound
                aria-label="copy"
                icon={<Copy />}
              />
            </Box>

            {/* 削除ボタン */}
            <Box>
              <IconButton
                onClick={() => remove(index)}
                variant="ghost"
                isDisabled={isInvoiced || isDisabledDelete}
                isRound
                aria-label="close"
                icon={<CloseIcon />}
              />
            </Box>
          </HStack>
        );
      })}
      <Button w="full" colorScheme="gray" leftIcon={<AddOne />} onClick={appendDetail}>
        追加
      </Button>
    </VStack>
  );
};
