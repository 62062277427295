import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { InformationShizai } from 'components/InformationShizai';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { OrderFormType } from 'features/order/type';
import { ZodOrderFormData } from 'features/order/zod';
import { Fragment } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { getToday } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

export const OrderDownload = ({
  type = 'new',
  values,
  onClickSubmit,
  isMutationInFlight,
  onClickToNewForm,
}: {
  type?: OrderFormType;
  values: ZodOrderFormData;
  onClickSubmit: () => void;
  isMutationInFlight: boolean;
  onClickToNewForm: () => void;
}) => {
  const detailsForCalculation = values.details.map((detail) => ({
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitPrice || 0,
    quantity: detail.quantity || 0,
    tax: detail.tax || 0,
  }));

  const handleSubmit = () => onClickSubmit();

  return (
    <>
      <ScrollRestoration />
      <Box mb={6}>
        <PageBack onClick={onClickToNewForm} />
      </Box>
      <Box mb={6}>
        <SubHeading label="内容の確認" />
      </Box>

      <VStack w="768px" spacing={6} align="flex-start" mb={6}>
        <Box>
          <Box color="gray.500" mb={1}>
            件名
          </Box>
          <Text>{values.title}</Text>
        </Box>
      </VStack>

      <Box w="768px">
        <VStack
          align="stretch"
          p={10}
          spacing={6}
          borderWidth="1px"
          borderRadius="6px"
          w="full"
          mb={6}
        >
          <VStack align="stretch" spacing={0}>
            <HStack align="end" pb="1rem" justify="space-between">
              <Heading as="h2" fontSize={24}>
                発注書
              </Heading>
              <HStack spacing={4}>
                <Text fontSize="sm">{getToday()}</Text>
              </HStack>
            </HStack>
            <Divider />
          </VStack>
          <HStack pb="1rem" justify="space-between" align="flex-start">
            <VStack spacing={2} w="320px" align="start">
              <Text fontSize="xl" fontWeight="bold">
                {values.supplier.name} 御中
              </Text>
              <Text color="gray.500" fontSize="sm">
                下記のとおり発注いたします
              </Text>
              <VStack spacing={1} w="full">
                <HStack align="end" justify="space-between" w="full">
                  <Text>合計金額</Text>
                  <HStack spacing={1} align="end">
                    <Text fontSize="2xl" fontWeight="bold">
                      {calculateTotalAmount(detailsForCalculation).toLocaleString()}
                    </Text>
                    <Text>円</Text>
                  </HStack>
                </HStack>
                <Divider borderColor="gray.700" />
              </VStack>
            </VStack>
            <Box w="320px">
              <InformationShizai />
            </Box>
          </HStack>
          <VStack align="start" justify="space-between">
            <Table>
              <Thead>
                <Tr>
                  <Th color="gray.700" w="100%" pl="0">
                    品名
                  </Th>
                  <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                    数量
                  </Th>
                  <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                    単価
                  </Th>
                  <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
                    金額 (税抜)
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {values.details.map((detail, i) => (
                  <Tr key={`detail-item-${detail.name}-${i}`} verticalAlign="top">
                    <Td pl="0" whiteSpace="pre-wrap">
                      {detail.name}
                    </Td>
                    <Td isNumeric color="gray.700">
                      {detailsForCalculation[i].quantity.toLocaleString()}
                    </Td>
                    <Td isNumeric color="gray.700">
                      {detailsForCalculation[i].unitPrice.toLocaleString()}
                    </Td>
                    <Td isNumeric color="gray.700" pr="0">
                      {calculateDetailAmount({
                        quantity: detailsForCalculation[i].quantity,
                        price: detailsForCalculation[i].unitPrice,
                      }).toLocaleString()}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </VStack>
          <HStack pb="1rem" justify="space-between" align="flex-start">
            <VStack spacing={4} w="400px" align="stretch">
              <VStack spacing={1} alignItems="flex-start">
                <Text fontWeight="bold">希望納品日</Text>
                <Text whiteSpace="pre-wrap" maxW="100%">
                  {values.deliveryInfo?.desiredDeliveryDate}
                </Text>
              </VStack>
              <VStack spacing={1} alignItems="flex-start">
                <Text fontWeight="bold">納品先</Text>
                <Text whiteSpace="pre-wrap" maxW="100%">
                  {values.deliveryInfo?.recipientsText}
                </Text>
              </VStack>
              <VStack spacing={1} alignItems="flex-start">
                <Text fontWeight="bold">備考</Text>
                <Text whiteSpace="pre-wrap" maxW="100%">
                  {values.detailMessage}
                </Text>
              </VStack>
            </VStack>
            <VStack align="left" spacing={2} w="240px">
              <Flex mt={2} justify="space-between">
                <Box w="140px" fontSize="sm">
                  小計
                </Box>
                <Box fontSize="sm">
                  {calculateSubtotalAmount(detailsForCalculation).toLocaleString()}
                </Box>
              </Flex>
              <Flex mt={4} justify="space-between">
                <Box whiteSpace="nowrap" w="140px" fontSize="sm">
                  消費税
                </Box>
                <Box fontSize="sm">
                  {calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}
                </Box>
              </Flex>
              <Flex mt={4} justify="space-between">
                <Box w="140px" fontWeight="bold">
                  合計金額
                </Box>
                <Box fontWeight="bold">
                  {calculateTotalAmount(detailsForCalculation).toLocaleString()}
                </Box>
              </Flex>
            </VStack>
          </HStack>
        </VStack>

        <Box w="768px" mb={6}>
          <Box color="gray.500" mb={1}>
            担当者
          </Box>
          <HStack wrap="wrap" spacing={0}>
            {(values.internalAssignees || []).map((assignee, index) => (
              <Fragment key={assignee.value}>
                {index !== 0 && (
                  <Box color="gray.200" px={2}>
                    /
                  </Box>
                )}
                <HStack>
                  <Box whiteSpace="nowrap">{assignee.label}</Box>
                </HStack>
              </Fragment>
            ))}
          </HStack>
        </Box>

        <Button
          colorScheme="blue"
          w="full"
          type="button"
          isDisabled={isMutationInFlight}
          onClick={handleSubmit}
        >
          {getSubmitButtonLabel(type)}
        </Button>
      </Box>
    </>
  );
};

const getSubmitButtonLabel = (type: OrderFormType) => {
  switch (type) {
    case 'new':
      return '作成してダウンロード';
    case 'edit':
      return '更新してダウンロード';
  }
};
