import { useBeforeUnload, unstable_usePrompt as usePrompt } from 'react-router-dom';

/**
 * フォーム等の離脱を防止するダイアログを表示する
 * @param show 離脱時にダイアログを表示するかどうか
 */
export const usePreventNavigation = (show: boolean) => {
  useBeforeUnload((e) => {
    // リロード時、タブが閉じられる際の処理
    if (show) {
      e.preventDefault();
      e.returnValue = '';
    }
  });

  // ページ遷移時の処理
  usePrompt({
    when: show,
    message: '入力内容が破棄されますがよろしいですか？',
  });
};
