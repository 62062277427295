import {
  Box,
  Button,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import {
  SALES_ORDER_FORM_TYPE,
  SalesOrderFormType,
  SalesOrderFormValuesType,
} from 'features/salesOrders/form';
import { Fragment } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { dateFormat } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

const getSubmitButtonLabel = (type: SalesOrderFormType) => {
  switch (type) {
    case SALES_ORDER_FORM_TYPE.new:
      return '作成';
    case SALES_ORDER_FORM_TYPE.edit:
      return '更新';
  }
};

const getLoadingButtonLabel = (type: SalesOrderFormType) => {
  switch (type) {
    case SALES_ORDER_FORM_TYPE.new:
      return '作成中';
    case SALES_ORDER_FORM_TYPE.edit:
      return '更新中';
  }
};

export const SalesOrderConfirm = ({
  type = SALES_ORDER_FORM_TYPE.new,
  values,
  onClickSubmit,
  isMutationInFlight,
  onClickToNewForm,
}: {
  type?: SalesOrderFormType;
  values: SalesOrderFormValuesType;
  onClickSubmit: () => void;
  isMutationInFlight: boolean;
  onClickToNewForm: () => void;
}) => {
  const handleSubmit = () => onClickSubmit();

  return (
    <>
      <ScrollRestoration />
      <Box mb={6}>
        <PageBack onClick={onClickToNewForm} />
      </Box>
      <Box mb={6}>
        <SubHeading label="内容の確認" />
      </Box>

      <VStack w="992px" spacing={6} align="flex-start">
        <VStack spacing={4} align="flex-start" w="full">
          <VStack spacing={1} alignItems="flex-start">
            <Box color="gray.500">デマンド</Box>
            <Box>{values.company.name}</Box>
          </VStack>
          <VStack spacing={1} alignItems="flex-start">
            <Box color="gray.500">サプライヤー</Box>
            <Box>{values.supplier.name}</Box>
          </VStack>
          <VStack spacing={1} alignItems="flex-start">
            <Box color="gray.500">件名</Box>
            <Text>{values.title}</Text>
          </VStack>
          <VStack spacing={1} alignItems="flex-start">
            <Box color="gray.500">受注日</Box>
            <Text>{dateFormat(values.receivedDate)}</Text>
          </VStack>
        </VStack>

        <Table w="full">
          <Thead>
            <Tr color="gray.700">
              <Th minW="26rem" pl="0">
                品名
              </Th>
              <Th minW="7.5rem" whiteSpace="nowrap">
                確定納品日
              </Th>
              <Th isNumeric minW="7rem" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric minW="7rem" whiteSpace="nowrap">
                仕入単価
              </Th>
              <Th isNumeric minW="7rem" whiteSpace="nowrap">
                顧客単価
              </Th>
              <Th isNumeric minW="7rem" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {values.details.length > 0 &&
              values.details.map((detail, index) => (
                <Tr key={index} verticalAlign="top">
                  <Td pl="0" whiteSpace="pre-wrap">
                    {detail.name}
                  </Td>
                  <Td color="gray.700" whiteSpace="nowrap">
                    {detail.fixedDeliveryDate ? dateFormat(detail.fixedDeliveryDate) : '-'}
                  </Td>
                  <Td isNumeric color="gray.700">
                    {detail?.quantity?.toLocaleString() || '-'}
                  </Td>
                  <Td isNumeric color="gray.700">
                    {detail?.unitPrice?.toLocaleString() || '-'}
                  </Td>
                  <Td isNumeric color="gray.700">
                    {detail?.unitSellingPrice?.toLocaleString() || '-'}
                  </Td>
                  <Td isNumeric color="gray.700" pr="0">
                    {calculateDetailAmount({
                      quantity: detail?.quantity || 0,
                      price: detail?.unitSellingPrice || 0,
                    }).toLocaleString()}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        <HStack pb="1rem" justify="space-between" align="flex-start" w="full">
          <VStack spacing={2} w="400px" align="start">
            <VStack spacing={1} align="start">
              <Text fontWeight="bold">備考</Text>
              <Text whiteSpace="pre-wrap">{values.detailMessage || '-'}</Text>
            </VStack>
            <VStack spacing={1} align="start">
              <Text fontWeight="bold">社内メモ</Text>
              <Text whiteSpace="pre-wrap">{values.memo || '-'}</Text>
            </VStack>
          </VStack>
          <VStack align="left" spacing={2} w="240px">
            <Flex mt={2} justify="space-between">
              <Box w="140px" fontSize="sm" whiteSpace="nowrap">
                小計
              </Box>
              <Box fontSize="sm">
                {calculateSubtotalAmount([
                  ...(values.details || []).map((detail) => ({
                    unitSellingPrice: detail.unitSellingPrice || 0,
                    quantity: detail?.quantity || 0,
                  })),
                ]).toLocaleString()}
              </Box>
            </Flex>
            <Flex mt={4} justify="space-between">
              <Box whiteSpace="nowrap" w="140px" fontSize="sm">
                消費税
              </Box>
              <Box fontSize="sm">
                {calculateTotalTaxAmount([
                  ...(values.details || []).map((detail) => ({
                    unitSellingPrice: detail.unitSellingPrice || 0,
                    quantity: detail?.quantity || 0,
                    tax: {
                      rate: detail?.tax.rate,
                    },
                  })),
                ]).toLocaleString()}
              </Box>
            </Flex>
            <Flex mt={4} justify="space-between">
              <Box w="140px" fontWeight="bold" whiteSpace="nowrap">
                合計金額
              </Box>
              <Box fontWeight="bold">
                {calculateTotalAmount([
                  ...(values.details || []).map((detail) => ({
                    unitSellingPrice: detail.unitSellingPrice || 0,
                    quantity: detail?.quantity || 0,
                    tax: {
                      rate: detail?.tax.rate,
                    },
                  })),
                ]).toLocaleString()}
              </Box>
            </Flex>
          </VStack>
        </HStack>

        <Box w="768px" mb={6}>
          <Box color="gray.500" mb={1}>
            担当者
          </Box>
          <HStack wrap="wrap" spacing={0}>
            {(values.internalAssignees || []).map((assignee, index) => (
              <Fragment key={assignee.value}>
                {index !== 0 && (
                  <Box color="gray.200" px={2}>
                    /
                  </Box>
                )}
                <HStack>
                  <Box whiteSpace="nowrap">{assignee.label}</Box>
                </HStack>
              </Fragment>
            ))}
          </HStack>
        </Box>

        <Button
          colorScheme="blue"
          w="full"
          type="button"
          onClick={handleSubmit}
          isDisabled={isMutationInFlight}
          isLoading={isMutationInFlight}
          loadingText={getLoadingButtonLabel(type)}
        >
          {getSubmitButtonLabel(type)}
        </Button>
      </VStack>
    </>
  );
};
