// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file model/search/v1/type.proto (package model.search.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum model.search.v1.SearchType
 */
export enum SearchType {
  /**
   * @generated from enum value: SEARCH_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SEARCH_TYPE_DEMAND = 1;
   */
  DEMAND = 1,

  /**
   * @generated from enum value: SEARCH_TYPE_ESTIMATION_REQUEST = 2;
   */
  ESTIMATION_REQUEST = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SearchType)
proto3.util.setEnumType(SearchType, "model.search.v1.SearchType", [
  { no: 0, name: "SEARCH_TYPE_UNSPECIFIED" },
  { no: 1, name: "SEARCH_TYPE_DEMAND" },
  { no: 2, name: "SEARCH_TYPE_ESTIMATION_REQUEST" },
]);

