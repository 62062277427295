/**
 * @generated SignedSource<<a16534e5c017ebfa77478401ea91c75a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type new_OrderNewPageFragment$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber: string | null;
    readonly userID: string;
  };
  readonly salesOrders?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly demand: {
          readonly id: string;
          readonly name: string;
        };
        readonly details: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly item: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly taxCategory: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitPrice: number;
              } | null;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly email: string;
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
              readonly phoneNumber: string | null;
            }> | null;
          };
        }> | null;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "new_OrderNewPageFragment";
};
export type new_OrderNewPageFragment$key = {
  readonly " $data"?: new_OrderNewPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"new_OrderNewPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isSkipToLoadSalesOrder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "salesOrderId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "new_OrderNewPageFragment",
  "selections": [
    {
      "condition": "isSkipToLoadSalesOrder",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "salesOrderId"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "SalesOrderConnection",
          "kind": "LinkedField",
          "name": "salesOrders",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrder",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Company",
                      "kind": "LinkedField",
                      "name": "demand",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Supplier",
                      "kind": "LinkedField",
                      "name": "supplier",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderDetailConnection",
                      "kind": "LinkedField",
                      "name": "details",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderDetailEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SalesOrderDetail",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderItem",
                                  "kind": "LinkedField",
                                  "name": "item",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "quantity",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "unitPrice",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "TaxCategory",
                                      "kind": "LinkedField",
                                      "name": "taxCategory",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v3/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderInternalAssignee",
                      "kind": "LinkedField",
                      "name": "internalAssignees",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "email",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserProfile",
                              "kind": "LinkedField",
                              "name": "profile",
                              "plural": true,
                              "selections": [
                                (v4/*: any*/),
                                (v5/*: any*/),
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userID",
          "storageKey": null
        },
        (v5/*: any*/),
        (v4/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryConnection",
      "kind": "LinkedField",
      "name": "taxCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "c663243f8c067ea9b3e0763bed973d3a";

export default node;
