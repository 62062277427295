import { Box, VStack } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { PageHeading } from 'components/PageHeading';
import { layout_DemandItemDetailLayoutQuery } from 'gql/__generated__/layout_DemandItemDetailLayoutQuery.graphql';
import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';

const DemandItemDetailLayoutQuery = graphql`
  query layout_DemandItemDetailLayoutQuery  ($code: String!) {
    companies(where: {code: $code}) {
      edges {
        node {
          name
        }
      }
    }
  }
`;

export const DemandItemDetailLayout = () => {
  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { companies } = useLazyLoadQuery<layout_DemandItemDetailLayoutQuery>(
    DemandItemDetailLayoutQuery,
    { code: demandCode },
    { fetchPolicy: 'network-only' },
  );

  if (
    !companies ||
    !companies.edges ||
    companies.edges.length === 0 ||
    !companies.edges[0]?.node ||
    companies.edges[0]?.node === null
  )
    return null;

  const demand = companies.edges[0].node;

  return (
    <VStack spacing={6} alignItems="flex-start">
      <PageHeading label={`デマンド：${demand.name}`} />
      <React.Suspense
        fallback={
          <Box width="1024px" textAlign="center" mt={40}>
            <Loading />
          </Box>
        }
      >
        <Outlet />
      </React.Suspense>
    </VStack>
  );
};
