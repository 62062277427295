import { HStack } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};
export const DemandRecurringCostBlockBody = ({ children }: Props) => {
  return (
    <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%" px={4}>
      {children}
    </HStack>
  );
};
