import { ZodEstimationRequestAddAssigneesFormData } from 'features/estimationRequest/zod';
import { useAddAssigneesToEstimationRequestFormMutation } from 'gql/__generated__/useAddAssigneesToEstimationRequestFormMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { toast } from 'lib/toast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

export type AddAssigneesToEstimationRequestFormState = {
  id: string;
  specText: string;
  mailSubject: string;
  suppliers: {
    id: string;
    name: string;
    contacts: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      memo?: string;
    }[];
    headerMessage: string;
    footerMessage: string;
  }[];
};

export const initialAddAssigneesToEstimationRequestFormState: AddAssigneesToEstimationRequestFormState =
  {
    id: '',
    specText: '',
    mailSubject: '',
    suppliers: [
      {
        id: '',
        name: '',
        contacts: [],
        headerMessage: '',
        footerMessage: '',
      },
    ],
  };

export const useAddAssigneesToEstimationRequestForm = (
  state: AddAssigneesToEstimationRequestFormState = initialAddAssigneesToEstimationRequestFormState,
) => {
  const versionWatcher = useContext(VersionWatcherContext);
  useEffect(versionWatcher.disableSoftDeleteEffect, []);
  const navigate = useNavigate();
  const [data, setData] = useState(state);
  const [showPreview, setPreview] = useState(false);
  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<useAddAssigneesToEstimationRequestFormMutation>(
      graphql`
      mutation useAddAssigneesToEstimationRequestFormMutation(
        $id: ID!
        $input: AddAssigneesToEstimationRequestInput!
      ) {
      addAssigneesToEstimationRequest(id: $id, input: $input) {
        id
        requestTitle
      }
    }
    `,
    );

  const onClickToConfirm = (data: AddAssigneesToEstimationRequestFormState) => {
    setData({ ...data });
    setPreview(true);
  };

  const onSubmit = (data: ZodEstimationRequestAddAssigneesFormData) => {
    commitMutation({
      variables: {
        id: data.id,
        input: {
          specText: data.specText,
          mailSubject: data.mailSubject,
          suppliers: data.suppliers.map((supplier) => ({
            id: supplier.id,
            toContactIDs: supplier.contacts.map((c) => c.id),
            headerMessage: supplier.headerMessage,
            footerMessage: supplier.footerMessage,
            defaultDetailMessage: supplier.defaultDetailMessage,
          })),
        },
      },
      onCompleted: () => {
        toast({
          title: '見積依頼を追加しました',
          status: 'success',
        });
        navigate(paths.estimationRequest.url());
      },
    });
  };

  const onClickToForm = () => setPreview(false);

  return {
    data,
    setData,
    showPreview,
    setPreview,
    onClickToConfirm,
    onSubmit,
    isMutationInFlight,
    onClickToForm,
  };
};
