import { Textarea, TextareaProps, forwardRef } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';

export const AutoResizeTextarea = forwardRef<TextareaProps, 'textarea'>((props, ref) => (
  <Textarea
    minH="unset"
    overflow="hidden"
    resize="none"
    minRows={1}
    as={ResizeTextarea}
    ref={ref}
    {...props}
  />
));
