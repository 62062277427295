/**
 * @generated SignedSource<<c50f3b89c63785506068ba1d0bcb63dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyDetailedInfoFragment$data = {
  readonly createdAt: any;
  readonly id: string;
  readonly internalMemos: ReadonlyArray<{
    readonly body: string;
  }> | null;
  readonly name: string;
  readonly " $fragmentType": "CompanyDetailedInfoFragment";
};
export type CompanyDetailedInfoFragment$key = {
  readonly " $data"?: CompanyDetailedInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyDetailedInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyDetailedInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandInternalMemo",
      "kind": "LinkedField",
      "name": "internalMemos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "8d905e212d50a9c87763e5a26698181b";

export default node;
