import { SalesOrderDetailFragment$data } from 'gql/__generated__/SalesOrderDetailFragment.graphql';

type SalesOrderDetailType = NonNullable<SalesOrderDetailFragment$data['details']>;
type SalesOrderDetailEdgesType = NonNullable<SalesOrderDetailType['edges']>;
type SalesOrderDetailEdgeType = NonNullable<SalesOrderDetailEdgesType[number]>;
type SalesOrderDetailNodeType = NonNullable<SalesOrderDetailEdgeType['node']>;

export const convertItemFormatWithDetailNumber = (
  transactionId: number,
  detail: {
    branchNumber: number;
    item: NonNullable<SalesOrderDetailNodeType['item']>;
    deliveryEvents: NonNullable<SalesOrderDetailNodeType['deliveryEvents']>;
    notionPage: SalesOrderDetailNodeType['notionPage'];
  },
) => {
  const fixedDeliveryDate = detail.deliveryEvents?.edges?.at(0)?.node?.fixedDeliveryDate;
  return {
    id: detail.item.id,
    detailNumber: detailNumber(transactionId, detail.branchNumber),
    name: detail.item.name,
    quantity: detail.item.quantity,
    unitPrice: detail.item.unitPrice,
    unitSellingPrice: detail.item.unitSellingPrice,
    tax: {
      id: detail.item.taxCategory.id,
      rate: detail.item.taxCategory.rate,
    },
    fixedDeliveryDate,
    notionPageUrl: detail.notionPage?.notionPageURL || '',
  };
};

export const detailNumber = (transactionId: number, branchNumber: number): string => {
  // transactionId-branchNumberの形式, branchNumberは2桁で0埋めする
  return `${transactionId}-${branchNumber.toString().padStart(2, '0')}`;
};
