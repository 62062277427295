import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddOne, DownloadThree, Mail } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { ErrorMessage } from 'components/ErrorMessage';
import { ItemAmountPreview } from 'components/ItemAmountPreview';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import { useQueryParams } from 'features/order/hooks/useOrderQueryPrams';
import { ORDER_SUBMIT_TYPE, OrderDetailType, OrderFormType } from 'features/order/type';
import { ZodOrderFormData, orderFormSchema } from 'features/order/zod';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import {
  OrderFormFragment$data,
  OrderFormFragment$key,
} from 'gql/__generated__/OrderFormFragment.graphql';
import { usePreventNavigation } from 'hooks/usePreventNavigation';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

type Props = {
  type?: OrderFormType;
  data: ZodOrderFormData;
  queryRef: OrderFormFragment$key;
  onClickToConfirm: (data: ZodOrderFormData) => void;
  updateSubmitType: (type: 'sending' | 'download') => void;
  previousUrl?: string;
};

const MIN_RECIPIENTS_TEXT_ROWS = 3;
const MIN_DETAIL_NESSAGE_ROWS = 3;

const orderFormFragment = graphql`
  fragment OrderFormFragment on Query {
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
    ...TaxSelectBoxFragment
  }
`;

const getBaseDetail = (taxCategories: OrderFormFragment$data['taxCategories']) => ({
  type: OrderDetailType.Item,
  name: '',
  unitPrice: '',
  quantity: '',
  unitSellingPrice: '',
  tax: {
    id: (taxCategories?.edges || [])[0]?.node?.id || '',
    rate: (taxCategories?.edges || [])[0]?.node?.rate || '',
  },
});

const getPageLabel = (type: OrderFormType) => {
  switch (type) {
    case 'new':
      return '発注書の作成';
    case 'edit':
      return '発注書の編集';
  }
};

export const OrderForm = ({
  type = 'new',
  data,
  queryRef,
  onClickToConfirm,
  updateSubmitType,
  previousUrl,
}: Props) => {
  const { queryParams } = useQueryParams();
  const query = useFragment(orderFormFragment, queryRef);
  const { taxCategories } = query;
  const [isDisabledDelete, setIsDisabledDelete] = useState(data.details.length === 1);

  const {
    register,
    control,
    formState: { errors, isSubmitting, isDirty, isValid },
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = useForm<ZodOrderFormData>({
    resolver: zodResolver(orderFormSchema),
    defaultValues: data,
  });

  const demandId = watch('company').id;

  const [showBlocker, setShowBlocker] = useState(isDirty);

  const watchDetails = watch('details');
  const detailsForCalculation = watchDetails.map((detail) => ({
    quantity: detail.quantity || 0,
    unitSellingPrice: detail.unitPrice || 0,
    price: detail.unitPrice || 0,
    tax: detail.tax,
    salesOrderDetailId: detail.salesOrderDetailId,
  }));

  watchDetails.forEach((detail, index) => {
    // nameにタブ文字を含む場合は全角スペースに置換する
    if (/\t/.test(detail.name)) {
      setValue(`details.${index}.name`, detail.name.replace(/\t/g, '　'));
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details',
  });

  const onSubmit = (data: ZodOrderFormData) => {
    onClickToConfirm(data);
  };

  const addDetail = () => {
    append({ ...getBaseDetail(taxCategories) });
  };

  const onClearCompanyInput = () => {
    setValue('company', { id: '', name: '' });
  };

  const onClearSupplierInput = () => {
    setValue('supplier', { id: '', name: '' });
  };

  useEffect(() => {
    if (fields.length === 1) {
      setIsDisabledDelete(true);
    } else {
      setIsDisabledDelete(false);
    }
  }, [fields]);

  usePreventNavigation(showBlocker);

  useEffect(() => {
    setShowBlocker(isDirty);
  }, [isDirty]);

  return (
    <>
      <Box mb={6}>
        <Link
          to={
            previousUrl ??
            paths.order.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              supplier: queryParams.supplier,
            })
          }
        >
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label={getPageLabel(type)} />
      </Box>
      <Box w="992px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={6} alignItems="flex-start">
            <HStack w="3xl" spacing={4} align="start">
              <FormControl isInvalid={!!errors.company} isRequired>
                <VStack gap={2} align="stretch">
                  <Box>
                    <FormLabel>デマンド</FormLabel>
                    <Controller
                      name="company"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CompanyComboBox
                          onChangeSelected={(value) => {
                            onChange(value);
                          }}
                          defaultSelectedItem={getValues('company')}
                          onClearInput={onClearCompanyInput}
                          isDisabled={type === 'edit'}
                        />
                      )}
                    />
                    <ErrorMessage name="company" errors={errors} />
                  </Box>
                  <DemandMemo demandId={demandId} />
                </VStack>
              </FormControl>
              <VStack w="100%" spacing={4}>
                <FormControl isInvalid={!!errors.supplier} isRequired>
                  <FormLabel>サプライヤー</FormLabel>
                  <Controller
                    name="supplier"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <SupplierComboBox
                        onChangeSelected={(value) => {
                          onChange(value);
                        }}
                        defaultSelectedItem={getValues('supplier')}
                        onClearInput={onClearSupplierInput}
                        isDisabled={type === 'edit'}
                      />
                    )}
                  />
                  <ErrorMessage name="supplier" errors={errors} />
                </FormControl>
              </VStack>
            </HStack>

            <Box w="3xl">
              <FormControl isInvalid={!!errors.title} isRequired>
                <FormLabel>件名</FormLabel>
                <Input type="string" {...register('title')} />
                <ErrorMessage name="title" errors={errors} />
              </FormControl>
            </Box>

            <VStack align="stretch" spacing={0} w="full">
              <HStack align="flex-start" spacing={2}>
                <CustomFormLabel isRequired w="27rem">
                  品名
                </CustomFormLabel>
                <CustomFormLabel isRequired w="7.5rem">
                  数量
                </CustomFormLabel>
                <CustomFormLabel isRequired w="7.5rem">
                  単価
                </CustomFormLabel>
                <CustomFormLabel isRequired w="7.5rem">
                  税区分
                </CustomFormLabel>
                <CustomFormLabel w="7.5rem">金額 (税抜)</CustomFormLabel>
              </HStack>
              <VStack align="stretch" spacing={4}>
                {fields.map((field, index) => (
                  <HStack key={`detail-${field.id}-${index}`} align="flex-start" spacing={2}>
                    {/* 品名 */}
                    <FormControl
                      isInvalid={!!(errors.details || [])[index]?.name}
                      isRequired
                      w="27rem"
                    >
                      <AutoResizeTextarea {...register(`details.${index}.name`)} />
                      <ErrorMessage name={`details.${index}.name`} errors={errors} />
                    </FormControl>

                    {/* 数量 */}
                    <FormControl
                      isInvalid={!!(errors.details || [])[index]?.quantity}
                      isRequired
                      flex={1}
                      w="7.5rem"
                    >
                      <ConfigurableNumberInput {...register(`details.${index}.quantity`)} />
                      <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
                    </FormControl>

                    {/* 単価 */}
                    <FormControl
                      isInvalid={!!(errors.details || [])[index]?.unitPrice}
                      isRequired
                      flex={1}
                      w="7.5rem"
                    >
                      <ConfigurableNumberInput
                        {...register(`details.${index}.unitPrice`)}
                        step="0.01"
                      />
                      <ErrorMessage name={`details.${index}.unitPrice`} errors={errors} />
                    </FormControl>

                    {/* 税区分 */}
                    <FormControl
                      isInvalid={!!(errors.details || [])[index]?.unitPrice}
                      isRequired
                      flex={1}
                      w="7.5rem"
                    >
                      <Controller
                        name={`details.${index}.tax`}
                        control={control}
                        render={() => (
                          <TaxSelectBox
                            queryRef={query}
                            defaultValue={getValues(`details.${index}.tax.id`)}
                            onChange={(e) => {
                              setValue(`details.${index}.tax`, {
                                ...getValues(`details.${index}.tax`),
                                ...{
                                  id: e.target.value,
                                  rate: e.target[e.target.selectedIndex].getAttribute(
                                    'data-rate',
                                  ) as string,
                                },
                              });
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
                    </FormControl>

                    {/* 金額 */}
                    <Box w="7.5rem">
                      <ItemAmountPreview
                        amount={calculateDetailAmount(detailsForCalculation[index])}
                      />
                    </Box>

                    {/* 削除ボタン */}
                    <Box>
                      <IconButton
                        onClick={() => remove(index)}
                        variant="ghost"
                        isDisabled={isDisabledDelete}
                        isRound
                        aria-label="close"
                        icon={<CloseIcon />}
                      />
                    </Box>
                  </HStack>
                ))}
                <Button w="full" colorScheme="gray" leftIcon={<AddOne />} onClick={addDetail}>
                  追加
                </Button>
              </VStack>
            </VStack>

            <HStack spacing={6} align="flex-start" justifyContent="space-between" w="3xl">
              <VStack w="70%" spacing={6} align="start">
                <FormControl isInvalid={!!errors.deliveryInfo?.desiredDeliveryDate}>
                  <FormLabel>希望納品日</FormLabel>
                  <AutoResizeTextarea {...register(`deliveryInfo.desiredDeliveryDate`)} />
                  <ErrorMessage name="deliveryInfo.desiredDeliveryDate" errors={errors} />
                </FormControl>
              </VStack>
              <Box w="240px">
                <Flex mt={2} justify="space-between">
                  <Box w="140px" fontSize="sm">
                    小計
                  </Box>
                  <Box fontSize="sm">
                    <>{calculateSubtotalAmount(detailsForCalculation).toLocaleString()}</>
                  </Box>
                </Flex>
                <Flex mt={4} justify="space-between">
                  <Box whiteSpace="nowrap" w="140px" fontSize="sm">
                    消費税
                  </Box>
                  <Box fontSize="sm">
                    <>{calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}</>
                  </Box>
                </Flex>
                <Flex mt={4} justify="space-between">
                  <Box w="140px" fontWeight="bold">
                    合計金額
                  </Box>
                  <Box fontWeight="bold">
                    <>{calculateTotalAmount(detailsForCalculation).toLocaleString()}</>
                  </Box>
                </Flex>
              </Box>
            </HStack>

            <HStack spacing={6} align="flex-start" justifyContent="space-between" w="3xl">
              <VStack w="100%" spacing={6} align="start">
                <FormControl isInvalid={!!errors.deliveryInfo?.recipientsText}>
                  <VStack spacing={2} align="stretch">
                    <FormLabel m={0}>納品先</FormLabel>
                    <Textarea
                      {...register('deliveryInfo.recipientsText')}
                      rows={
                        (getValues('deliveryInfo.recipientsText') ?? '').split(/\n/).length >
                        MIN_RECIPIENTS_TEXT_ROWS
                          ? (getValues('deliveryInfo.recipientsText') ?? '').split(/\n/).length
                          : MIN_RECIPIENTS_TEXT_ROWS
                      }
                    />
                    <Text color="gray.500">郵便番号・住所・電話番号・宛名を記載しましょう</Text>
                    <ErrorMessage name="deliveryInfo.recipientsText" errors={errors} />
                  </VStack>
                </FormControl>

                <FormControl isInvalid={!!errors.detailMessage}>
                  <FormLabel>備考</FormLabel>
                  <Textarea
                    {...register('detailMessage')}
                    rows={
                      getValues('detailMessage').split(/\n/).length > MIN_DETAIL_NESSAGE_ROWS
                        ? getValues('detailMessage').split(/\n/).length
                        : MIN_DETAIL_NESSAGE_ROWS
                    }
                  />
                  <ErrorMessage name="detailMessage" errors={errors} />
                </FormControl>

                <FormControl isInvalid={!!errors.internalAssignees} isRequired>
                  <FormLabel>担当者</FormLabel>
                  <Controller
                    name="internalAssignees"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <UserMultipleSelectBox
                        onChange={onChange}
                        defaultValue={getValues('internalAssignees')}
                        menuPlacement="top"
                      />
                    )}
                  />
                  <ErrorMessage name="internalAssignees" errors={errors} />
                </FormControl>
              </VStack>
            </HStack>

            <HStack w="3xl" spacing={6}>
              <Button
                colorScheme="blue"
                w="full"
                type="submit"
                isLoading={isSubmitting}
                leftIcon={<DownloadThree />}
                data-send-email={false}
                onClick={() => {
                  if (isValid) {
                    setShowBlocker(false);
                    updateSubmitType(ORDER_SUBMIT_TYPE.download);
                  }
                }}
              >
                内容確認後にPDFをダウンロード
              </Button>
              <Button
                colorScheme="blue"
                w="full"
                type="submit"
                isLoading={isSubmitting}
                leftIcon={<Mail />}
                data-location={true}
                onClick={() => {
                  if (isValid) {
                    setShowBlocker(false);
                    updateSubmitType(ORDER_SUBMIT_TYPE.sending);
                  }
                }}
              >
                内容確認後にメールで送信
              </Button>
            </HStack>
          </VStack>
        </form>
      </Box>
    </>
  );
};
