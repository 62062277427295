import {
  BuildingOne,
  Deposit,
  FactoryBuilding,
  Order,
  Seal,
  SendEmail,
  TriangleRuler,
} from '@icon-park/react';
import { paths } from 'utils/paths';

export const SideBarList = [
  {
    category: '書類一覧',
    items: [
      {
        label: '見積依頼',
        icon: SendEmail,
        href: paths.estimationRequest.url(),
      },
      {
        label: '見積書',
        icon: TriangleRuler,
        href: paths.estimation.url(),
      },
      {
        label: '発注請書',
        icon: Seal,
        href: paths.salesOrder.url(),
      },
      {
        label: '発注書',
        icon: Order,
        href: paths.order.url(),
      },
      {
        label: '請求書',
        icon: Deposit,
        href: paths.invoice.url(),
      },
    ],
  },
  {
    category: 'マスタ',
    items: [
      {
        label: 'デマンド',
        icon: BuildingOne,
        href: paths.company.url(),
      },
      {
        label: 'サプライヤー',
        icon: FactoryBuilding,
        href: paths.supplier.url(),
      },
    ],
  },
];
