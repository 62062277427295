import { Box, Button, VStack } from '@chakra-ui/react';
import { Link } from 'components/Link';
import { SalesOrderDetailSideBarFragment$key } from 'gql/__generated__/SalesOrderDetailSideBarFragment.graphql';
import { useFragment } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat, dateFormatWithTime } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';
import { SalesOrderDetailsTransactionId } from './SalesOrderDetailsTransactionId';

const SalesOrderDetailSideBarFragment = graphql`
  fragment SalesOrderDetailSideBarFragment on SalesOrder
  {
    id
    title
    createdAt
    demand {
      id
      name
    }
    supplier {
      id
      name
    }
    createdBy {
      profile {
        lastName
        firstName
      }
    }
    transactionID
    receivedDate
    estimations {
        id
        estimationResponse {
            assignee {
                estimationRequest {
                    id
                }
            }
        }
    }
    orders {
      id
    }
    invoices {
        id
    }
    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

type Props = {
  queryRef: SalesOrderDetailSideBarFragment$key;
};

export const SalesOrderDetailSideBar = ({ queryRef }: Props) => {
  const {
    id,
    transactionID,
    createdAt,
    demand,
    supplier,
    createdBy,
    estimations,
    orders,
    invoices,
    receivedDate,
    internalAssignees: internalAssigneesData,
  } = useFragment(SalesOrderDetailSideBarFragment, queryRef);
  const navigate = useNavigate();

  const estimationId = estimations?.[0]?.id;
  const estimationRequestId = estimations?.[0]?.estimationResponse?.assignee?.estimationRequest?.id;
  const order = orders ? orders[0] : null;
  const invoice = invoices ? invoices[0] : null;

  return (
    <VStack spacing={6} alignItems="flex-start">
      <SalesOrderDetailsTransactionId transactionID={transactionID} />
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          作成日時
        </Box>
        <Box>{dateFormatWithTime(createdAt)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          受注日
        </Box>
        <Box>{dateFormat(receivedDate)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          デマンド
        </Box>
        <Box>
          <Link to={paths.company._id(demand.id).url}>{demand.name}</Link>
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          サプライヤー
        </Box>
        <Box>
          <Box>
            <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
          </Box>
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          担当者
        </Box>
        <Box>
          {internalAssigneesData?.map((assignee) =>
            assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
              <Box key={assignee.user.id}>
                {concatFullName({
                  lastName: assignee.user.profile[0].lastName,
                  firstName: assignee.user.profile[0].firstName,
                })}
              </Box>
            ) : (
              <Box key={assignee.user.id}>-</Box>
            ),
          ) || '-'}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          作成者
        </Box>
        <Box>
          {createdBy.profile?.[0]?.lastName && createdBy.profile?.[0]?.firstName
            ? concatFullName({
                lastName: createdBy.profile[0].lastName,
                firstName: createdBy.profile[0].firstName,
              })
            : '-'}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          見積依頼
        </Box>
        <Box>
          {estimationRequestId ? (
            <Link to={paths.estimationRequest._id(estimationRequestId)}>{estimationRequestId}</Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          見積書
        </Box>
        <Box>
          {estimationId ? (
            <Link to={paths.estimation._id(estimationId).url}>{estimationId}</Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          発注書
        </Box>
        {order ? (
          <Link to={paths.order._id(order.id)}>{order.id}</Link>
        ) : (
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => navigate(paths.order.new.urlWithSalesOrderId(id))}
          >
            内容を引用して作成
          </Button>
        )}
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          請求書
        </Box>
        <Box>{invoice ? <Link to={paths.invoice._id(invoice.id)}>{invoice.id}</Link> : 'なし'}</Box>
      </VStack>
    </VStack>
  );
};
