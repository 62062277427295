import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';
import { z } from 'zod';
import { DEMAND_ITEM_CATEGORY } from '../models';

export type DemandItemFormType = z.infer<typeof demandItemFormSchema>;
export type DemandItemFormCardboard = DemandItemFormType['cardboard'];
export type DemandItemFormFlexiblePackage = DemandItemFormType['flexiblePackage'];
export type DemandItemFormGiftBox = DemandItemFormType['giftBox'];
export type DemandItemFormPaperBag = DemandItemFormType['paperBag'];
export type DemandItemFormOther = DemandItemFormType['other'];
export type DemandItemFormSpec =
  | DemandItemFormCardboard
  | DemandItemFormFlexiblePackage
  | DemandItemFormGiftBox
  | DemandItemFormPaperBag
  | DemandItemFormOther;

export const demandItemFormSchema = z.object({
  name: z.string().min(1, { message: errorMessages.REQUIRED }),
  supplier: z.object({
    id: z.string().min(1, { message: errorMessages.REQUIRED }),
    name: z.string(),
  }),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),

  category: z.nativeEnum(DEMAND_ITEM_CATEGORY),
  cardboard: z
    .object({
      size: z.string(),
      type: z.string(),
      material: z.string(),
      thickness: z.string(),
      printingColor: z.string(),
      processing: z.string(),
      other: z.string(),
    })
    .optional(),
  flexiblePackage: z
    .object({
      size: z.string(),
      type: z.string(),
      material: z.string(),
      printingColor: z.string(),
      processing: z.string(),
      other: z.string(),
    })
    .optional(),
  giftBox: z
    .object({
      size: z.string(),
      type: z.string(),
      paperType: z.string(),
      printingColor: z.string(),
      processing: z.string(),
      other: z.string(),
    })
    .optional(),
  paperBag: z
    .object({
      size: z.string(),
      paperType: z.string(),
      printingColor: z.string(),
      processing: z.string(),
      handle: z.string(),
      other: z.string(),
    })
    .optional(),
  other: z
    .object({
      specText: z.string(),
    })
    .optional(),

  taxId: z.string(),
  prices: z
    .array(
      z.object({
        id: z.string(),
        unitPrice: unitPriceSchema(),
        unitSellingPrice: unitSellingPriceSchema,
        quantity: quantitySchema(),
      }),
    )
    .superRefine((prices, ctx) => {
      const quantities = new Set<number>();

      prices.forEach((price, index) => {
        if (quantities.has(Number(price.quantity))) {
          ctx.addIssue({
            code: 'custom',
            path: [index, 'quantity'],
            message: `数量は重複しないようにしましょう`,
          });
        } else {
          quantities.add(Number(price.quantity));
        }
      });
    }),
});
