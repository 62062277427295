/**
 * @generated SignedSource<<742ec8ad7dd5be3c8a3cdd0294853748>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type InvoiceInput = {
  bookedAt: any;
  demandID: string;
  detailMessage: string;
  details: ReadonlyArray<CreateInvoiceDetailInput>;
  internalMemo?: string | null;
  paymentDueAt: any;
  title: string;
};
export type CreateInvoiceDetailInput = {
  Item?: InvoiceItemInput | null;
  orderNumber: number;
  salesDate?: any | null;
  salesOrderDetailID?: string | null;
  type: InvoiceDetailType;
};
export type InvoiceItemInput = {
  name: string;
  quantity: number;
  taxCategoryID: string;
  unitSellingPrice: number;
};
export type useInvoiceCreateFormMutation$variables = {
  input: InvoiceInput;
};
export type useInvoiceCreateFormMutation$data = {
  readonly createInvoice: {
    readonly id: string;
  };
};
export type useInvoiceCreateFormMutation = {
  response: useInvoiceCreateFormMutation$data;
  variables: useInvoiceCreateFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "createInvoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useInvoiceCreateFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useInvoiceCreateFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5532a7b74d2b56af0e4e3432029352e9",
    "id": null,
    "metadata": {},
    "name": "useInvoiceCreateFormMutation",
    "operationKind": "mutation",
    "text": "mutation useInvoiceCreateFormMutation(\n  $input: InvoiceInput!\n) {\n  createInvoice(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "686af66867a8e4327e659252f4effc45";

export default node;
