import {
  Box,
  Flex,
  HStack,
  Img,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { SalesOrderDetailFragment$key } from 'gql/__generated__/SalesOrderDetailFragment.graphql';
import NotionLogo from 'public/images/notion_logo.svg';
import { useState } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';
import { convertItemFormatWithDetailNumber } from '../../util';

const SalesOrderDetailFragment = graphql`
  fragment SalesOrderDetailFragment on SalesOrder
  {
    internalMemos {
      body
    }
    title
    transactionID
    detailMessage
    details(orderBy: { direction: ASC, field: ORDER_NUMBER }) {
      edges {
        node {
          id
          branchNumber
          deliveryEvents(where: {isValid: true}) {
            edges {
              node {
                fixedDeliveryDate
              }
            }
          }
          item {
            id
            name
            unitPrice
            unitSellingPrice
            quantity
            taxCategory {
                id
                rate
            }
          }
          notionPage {
            notionPageURL
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: SalesOrderDetailFragment$key;
};

export const SalesOrderDetail = ({ queryRef }: Props) => {
  const { transactionID, detailMessage, details, title, internalMemos } = useFragment(
    SalesOrderDetailFragment,
    queryRef,
  );
  const [isDragging, setIsDragging] = useState(false);

  const items = [...(details.edges || [])]
    .map((edge) => {
      const detail = edge?.node;
      if (detail == null) return null;

      const item = detail.item;
      if (item == null) return null;
      return convertItemFormatWithDetailNumber(transactionID, {
        branchNumber: detail.branchNumber,
        item,
        deliveryEvents: detail.deliveryEvents,
        notionPage: detail.notionPage,
      });
    })
    .filter((v): v is NonNullable<typeof v> => v != null);

  const memo = internalMemos?.at(0);

  return (
    <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full" mb={6}>
      <Text fontWeight="bold">{title}</Text>

      <VStack align="start" justify="space-between">
        <Table>
          <Thead>
            <Tr fontSize="xs">
              <Th color="gray.700" minW="19rem" pl="0">
                品名
              </Th>
              <Th color="gray.700" minW="7rem">
                確定納品日
              </Th>
              <Th isNumeric color="gray.700" minW="7rem" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric color="gray.700" minW="7rem" whiteSpace="nowrap">
                仕入単価
              </Th>
              <Th isNumeric color="gray.700" minW="7rem" whiteSpace="nowrap">
                顧客単価
              </Th>
              <Th isNumeric color="gray.700" minW="7rem" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) => (
              <Tr
                key={item.id}
                fontSize="sm"
                verticalAlign="top"
                _hover={
                  item.notionPageUrl
                    ? {
                        bgColor: 'gray.50',
                        cursor: 'pointer',
                      }
                    : {}
                }
                onPointerDown={() => setIsDragging(false)}
                onPointerMove={() => setIsDragging(true)}
                onPointerUp={() => {
                  if (!item.notionPageUrl) return;
                  if (!isDragging) {
                    window.open(item.notionPageUrl, '_blank', 'noopener, noreferrer');
                  }
                  setIsDragging(false);
                }}
              >
                <Td pl="0" whiteSpace="pre-wrap">
                  <HStack alignItems="start">
                    {item.notionPageUrl && <Img src={NotionLogo} alt="notion logo" />}
                    <Text>{[item.detailNumber, item.name].filter(Boolean).join(' ')}</Text>
                  </HStack>
                </Td>
                <Td whiteSpace="nowrap">
                  {item.fixedDeliveryDate ? dateFormat(item.fixedDeliveryDate) : '-'}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.quantity.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.unitPrice.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.unitSellingPrice.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700" pr="0">
                  {calculateDetailAmount({
                    quantity: item.quantity,
                    price: item.unitSellingPrice,
                  }).toLocaleString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>

      <HStack pb="1rem" justify="space-between" align="flex-start">
        <VStack spacing={2} w="400px" align="start">
          <VStack spacing={1} align="start">
            <Text fontSize="sm" fontWeight="bold">
              備考
            </Text>
            <Text whiteSpace="pre-wrap" fontSize="sm">
              {detailMessage}
            </Text>
          </VStack>
          {memo && (
            <VStack spacing={1} align="start">
              <Text fontSize="sm" fontWeight="bold">
                社内メモ
              </Text>
              <Text fontSize="sm" whiteSpace="pre-wrap">
                {memo.body}
              </Text>
            </VStack>
          )}
        </VStack>
        <VStack align="left" spacing={2} w="240px">
          <Flex mt={2} justify="space-between">
            <Box w="140px" fontSize="sm">
              小計
            </Box>
            <Box fontSize="sm">{calculateSubtotalAmount(items).toLocaleString()}</Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box whiteSpace="nowrap" w="140px" fontSize="sm">
              消費税
            </Box>
            <Box fontSize="sm">{calculateTotalTaxAmount(items).toLocaleString()}</Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box w="140px" fontWeight="bold">
              合計金額
            </Box>
            <Box fontWeight="bold">{calculateTotalAmount(items).toLocaleString()}</Box>
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  );
};
