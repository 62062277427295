import { Select } from '@chakra-ui/react';
import { TaxSelectBoxFragment$key } from 'gql/__generated__/TaxSelectBoxFragment.graphql';
import { ChangeEvent } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

type Props = {
  queryRef: TaxSelectBoxFragment$key;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
};

const taxSelectBoxFragment = graphql`
  fragment TaxSelectBoxFragment on Query {
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
  }
`;

export const TaxSelectBox = ({ queryRef, onChange, defaultValue }: Props) => {
  const { taxCategories } = useFragment(taxSelectBoxFragment, queryRef);

  if (!taxCategories) return null;

  return (
    <Select
      defaultValue={defaultValue ?? (taxCategories?.edges || [])[0]?.node?.id}
      onChange={onChange}
    >
      {(taxCategories.edges || []).map((taxCategory, i) => (
        <option
          key={`tax-category-${taxCategory?.node?.id}-${i}`}
          value={taxCategory?.node?.id}
          data-rate={taxCategory?.node?.rate}
        >
          {taxCategory?.node?.name}
        </option>
      ))}
    </Select>
  );
};
