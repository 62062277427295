import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { AddOne, CloseSmall, LoadingOne } from '@icon-park/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { EstimationRequestSupplierFormElementQuery as EstimationRequestSupplierFormElementQueryType } from 'gql/__generated__/EstimationRequestSupplierFormElementQuery.graphql';
import { Suspense, useEffect, useRef, useState } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { concatFullName } from 'utils/label';

type Supplier = {
  id: string;
  name: string;
  contacts: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    memo?: string;
  }[];
  headerMessage: string;
  footerMessage: string;
  defaultDetailMessage?: string;
};

type SupplierErrors = Merge<FieldError, FieldErrorsImpl<Supplier>> | undefined;

type SupplierListErrors = Merge<FieldError, SupplierErrors[]> | undefined;

type Props = {
  suppliers: Supplier[];
  onChange: (suppliers: Supplier[]) => void;
  errors?: SupplierListErrors;
  excludeSupplierIds?: string[];
};

export const EstimationRequestSupplierForm = ({
  suppliers: defaultSuppliers,
  onChange,
  errors,
  excludeSupplierIds = [],
}: Props) => {
  const [suppliers, setSuppliers] = useState<Supplier[]>(defaultSuppliers);
  const [canAdd, setCanAdd] = useState<boolean>(true);
  const [prevErrors, setPrevErrors] = useState<SupplierListErrors>(errors);
  const [errorIndex, setErrorIndex] = useState<number>(-1);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (errors !== prevErrors) {
      setPrevErrors(errors);
      if (!errors) {
        setErrorIndex(-1);
      }

      for (let i = 0; i < (errors?.length || 0); i++) {
        if (errors?.[i]) {
          setErrorIndex(i);
          break;
        }
      }
    }
  }, [errors]);

  return (
    <VStack w="768px" alignItems="stretch" spacing={4}>
      {suppliers.map((supplier, index) => (
        <Suspense key={`supplier-form-${supplier.id}`} fallback={<LoadingOne />}>
          <EstimationRequestSupplierFormElement
            index={index}
            supplier={supplier}
            onChange={(index, supplier) => {
              const newSuppliers = [...suppliers];
              newSuppliers[index] = supplier;
              setSuppliers(newSuppliers);
              onChange(newSuppliers);
            }}
            excludeSupplierIds={[...excludeSupplierIds, ...suppliers.map((s) => s.id)]}
            canDelete={suppliers.length > 1}
            onDelete={(index) => {
              const newSuppliers = [...suppliers];
              newSuppliers.splice(index, 1);
              setSuppliers(newSuppliers);
              onChange(newSuppliers);
            }}
            errors={errors?.[index]}
            onFetchCompanies={
              index === 0
                ? (companies) => {
                    setCanAdd(suppliers.filter((s) => s.id === '').length < companies.length);
                  }
                : undefined
            }
            focus={index === errorIndex}
          />
        </Suspense>
      ))}
      <Button
        leftIcon={<AddOne />}
        onClick={() => {
          const newSuppliers = [
            ...suppliers,
            {
              id: '',
              name: '',
              contacts: [],
              headerMessage: '',
              footerMessage: '',
            },
          ];
          setSuppliers(newSuppliers);
          onChange(newSuppliers);
        }}
        isDisabled={!canAdd}
      >
        追加
      </Button>
      ;
    </VStack>
  );
};

type ElementProps = {
  index: number;
  supplier: Supplier;
  onChange: (index: number, supplier: Supplier) => void;
  onDelete?: (index: number) => void;
  excludeSupplierIds?: string[];
  canDelete: boolean;
  errors: SupplierErrors;
  onFetchCompanies?: (companies: { id: string; name: string }[]) => void;
  focus?: boolean;
};

const EstimationRequestSupplierFormElementQuery = graphql`
  query EstimationRequestSupplierFormElementQuery ($supplierId: ID!, $skip: Boolean!) {
    supplierContacts(where: { supplierID: $supplierId }) @skip(if: $skip) {
        edges {
            node {
                id
                firstName
                lastName
                email
                memos {
                  body
                }
            }
        }
    }
  }
`;

export const EstimationRequestSupplierFormElement = ({
  index,
  supplier,
  onChange,
  excludeSupplierIds = [],
  canDelete,
  onDelete,
  errors,
  onFetchCompanies,
  focus = false,
}: ElementProps) => {
  const { supplierContacts } = useLazyLoadQuery<EstimationRequestSupplierFormElementQueryType>(
    EstimationRequestSupplierFormElementQuery,
    {
      supplierId: supplier?.id || '',
      skip: !supplier?.id,
    },
  );

  const contacts =
    supplierContacts?.edges
      ?.map((edge) => edge?.node)
      ?.filter((contact): contact is NonNullable<typeof contact> => contact != null) || [];

  const headerMessageRef = useRef<HTMLTextAreaElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (headerMessageRef.current) {
      headerMessageRef.current.value = supplier?.headerMessage || '';
    }
  }, [headerMessageRef, supplier?.headerMessage]);

  const footerMessageRef = useRef<HTMLTextAreaElement>(null);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (footerMessageRef.current) {
      footerMessageRef.current.value = supplier?.footerMessage || '';
    }
  }, [footerMessageRef, supplier?.footerMessage]);

  const defaultDetailMessageRef = useRef<HTMLTextAreaElement>(null);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (defaultDetailMessageRef.current) {
      defaultDetailMessageRef.current.value = supplier?.defaultDetailMessage || '';
    }
  }, [defaultDetailMessageRef, supplier?.defaultDetailMessage]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (focus) {
      ref.current?.scrollIntoView();
    }
  }, [focus]);

  return (
    <VStack
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="6px"
      p={4}
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={4}
      ref={ref}
    >
      <HStack justifyContent="space-between" spacing={0}>
        <FormLabel fontSize="xl">見積先{index + 1}</FormLabel>

        <ConfirmModalButton
          header={`見積先${index + 1}を削除しますか？`}
          button={({ onOpen }) => (
            <Button
              bgColor="gray.100"
              borderRadius={100}
              w={8}
              minW={8}
              maxW={8}
              h={8}
              minH={8}
              maxH={8}
              isDisabled={!canDelete}
              onClick={() => {
                if (!canDelete) {
                  return;
                }

                if (needConfirmToDelete(supplier)) {
                  onOpen();
                } else {
                  onDelete?.(index);
                }
              }}
            >
              <Icon as={CloseSmall} w={4} h={4} />
            </Button>
          )}
          footer={({ onClose }) => (
            <HStack spacing={3}>
              <Button onClick={onClose}>キャンセル</Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  onClose();
                  onDelete?.(index);
                }}
              >
                削除
              </Button>
            </HStack>
          )}
        />
      </HStack>

      <VStack spacing={2} alignItems="stretch">
        <FormControl isInvalid={!!errors?.id}>
          <CustomFormLabel isRequired>サプライヤー</CustomFormLabel>
          <SupplierComboBox
            defaultSelectedItem={supplier}
            onChangeSelected={(v) => {
              onChange(index, {
                ...supplier,
                id: v?.id || '',
                name: v?.name || '',
                contacts: [],
              });
            }}
            isDisabled={false}
            onClearInput={() => {
              onChange(index, {
                ...supplier,
                id: '',
                name: '',
                contacts: [],
              });
            }}
            excludeIds={() => excludeSupplierIds}
            onFetchSuppliers={onFetchCompanies}
          />
          <FormErrorMessage>{errors?.id?.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      {contacts.length > 0 && (
        <Box>
          <FormControl isInvalid={!!errors?.contacts}>
            <CheckboxGroup
              onChange={(contactIds) => {
                const newContacts = contactIds.map((id) => {
                  return contacts.find((contact) => contact.id === id);
                });
                onChange(index, {
                  ...supplier,
                  contacts: newContacts
                    .filter((contact): contact is NonNullable<typeof contact> => contact !== null)
                    .map((c) => ({
                      id: c.id,
                      firstName: c.firstName,
                      lastName: c.lastName,
                      email: c.email,
                      memo: c.memos && c.memos.length > 0 ? c.memos[0].body : undefined,
                    })),
                });
              }}
              value={supplier?.contacts?.map((c) => c.id) || []}
            >
              <VStack spacing={4} alignItems="flex-start">
                {contacts.map((contact) => {
                  return (
                    <Checkbox
                      id={`supplier-contact-${contact.id}`}
                      key={`contact-${contact.id}`}
                      mr={2}
                      value={contact.id}
                      alignItems="flex-start"
                    >
                      <VStack alignItems="flex-start" spacing={0}>
                        <HStack lineHeight={1}>
                          <Box whiteSpace="nowrap">
                            {concatFullName({
                              lastName: contact.lastName,
                              firstName: contact.firstName,
                            })}
                          </Box>
                          <Box color="gray.600" fontSize="sm">
                            {contact.email}
                          </Box>
                        </HStack>
                        <HStack alignItems="flex-start" fontSize="sm" color="gray.500">
                          <Box minWidth="fit-content">メモ:</Box>
                          <Box whiteSpace="pre-wrap">
                            {contact.memos && contact.memos.length > 0
                              ? contact.memos[0].body
                              : '-'}
                          </Box>
                        </HStack>
                      </VStack>
                    </Checkbox>
                  );
                })}
              </VStack>
            </CheckboxGroup>
            <FormErrorMessage>{errors?.contacts?.message}</FormErrorMessage>
          </FormControl>
        </Box>
      )}

      <VStack spacing={2} alignItems="stretch">
        <Box>
          <CustomFormLabel isRequired>メッセージ</CustomFormLabel>
          <FormControl isInvalid={!!errors?.headerMessage}>
            <Textarea
              rows={9}
              ref={headerMessageRef}
              onBlur={() => {
                onChange(index, {
                  ...supplier,
                  headerMessage: headerMessageRef.current?.value || '',
                });
              }}
            ></Textarea>
            <FormErrorMessage>{errors?.headerMessage?.message}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box paddingInlineStart={4} paddingInlineEnd={4}>
          お見積内容は下記URLよりご入力をお願いいたします。 <br />
          <Text color="blue.500" display="inline" textDecoration="underline">
            https://lube.shiza1.com/〜
          </Text>
        </Box>

        <FormControl isInvalid={!!errors?.footerMessage}>
          <Textarea
            rows={3}
            ref={footerMessageRef}
            onBlur={() => {
              onChange(index, {
                ...supplier,
                footerMessage: footerMessageRef.current?.value || '',
              });
            }}
          ></Textarea>
          <FormErrorMessage>{errors?.footerMessage?.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      <VStack spacing={2} alignItems="stretch">
        <CustomFormLabel>見積回答備考欄のデフォルトメッセージ</CustomFormLabel>
        <Textarea
          rows={3}
          ref={defaultDetailMessageRef}
          onBlur={() => {
            onChange(index, {
              ...supplier,
              defaultDetailMessage: defaultDetailMessageRef.current?.value || undefined,
            });
          }}
        ></Textarea>
        <Text color="gray.500" fontSize="sm">
          サプライヤーに必ず記載してほしい内容がある場合にご利用ください
        </Text>
      </VStack>
    </VStack>
  );
};

const needConfirmToDelete = (supplier: Supplier) => {
  return supplier.id !== '' || supplier.headerMessage || supplier.footerMessage;
};
