import { FormErrorMessage } from '@chakra-ui/react';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { ComponentProps } from 'react';

type Props = {
  name: ComponentProps<typeof HookFormErrorMessage>['name'];
  errors: ComponentProps<typeof HookFormErrorMessage>['errors'];
};

export function ErrorMessage({ name, errors }: Props) {
  return (
    <HookFormErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
    />
  );
}
