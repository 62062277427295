import { Button, Td, Tr } from '@chakra-ui/react';
import { CompanyTableRowFragment$key } from 'gql/__generated__/CompanyTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const CompanyTableRowFragment = graphql`
  fragment CompanyTableRowFragment on Company {
    id
    name
  }
`;

export const CompanyTableRow = ({ queryRef }: { queryRef: CompanyTableRowFragment$key }) => {
  const { name, id } = useFragment(CompanyTableRowFragment, queryRef);

  return (
    <Tr>
      <Td>{id}</Td>
      <Td>{name || '-'} </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="sm" to={paths.company._id(id).url}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
