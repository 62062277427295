/**
 * @generated SignedSource<<d6541225eddb9f5c4b129928960e1587>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EstimationResponseDetailType = "item" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EstimationResponseItemTableFragment$data = {
  readonly details: ReadonlyArray<{
    readonly item: {
      readonly name: string;
      readonly quantity: any;
      readonly specJSON: any;
      readonly unitPrice: number;
    } | null;
    readonly type: EstimationResponseDetailType;
  }> | null;
  readonly " $fragmentType": "EstimationResponseItemTableFragment";
};
export type EstimationResponseItemTableFragment$key = {
  readonly " $data"?: EstimationResponseItemTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationResponseItemTableFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationResponseItemTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationResponseDetail",
      "kind": "LinkedField",
      "name": "details",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationResponseItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "specJSON",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unitPrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EstimationResponse",
  "abstractKey": null
};

(node as any).hash = "443633785aecbcf4f1d6cc441a6ef32e";

export default node;
