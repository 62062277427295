import { Input, InputProps, forwardRef } from '@chakra-ui/react';
import { useEffect, useLayoutEffect, useRef } from 'react';

type NumberInputProps = {
  /**
   * ステップ動作を許可するかどうか
   * @default false
   */
  allowStepBehavior?: boolean;
};

type Props = NumberInputProps & InputProps;

/**
 * stepの挙動などをプロパティで設定可能なNumberInput
 */
const ConfigurableNumberInput = forwardRef<Props, 'input'>(
  ({ allowStepBehavior = false, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useLayoutEffect(() => {
      if (typeof ref === 'function') {
        ref(inputRef.current);
      } else if (ref) {
        ref.current = inputRef.current;
      }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
      if (allowStepBehavior) {
        return;
      }

      inputRef.current?.addEventListener('wheel', (e) => {
        e.preventDefault();
        return false;
      });

      inputRef.current?.addEventListener('keydown', (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
          return false;
        }
        return true;
      });
    }, []);

    return <Input type="number" textAlign="right" ref={inputRef} required={false} {...props} />;
  },
);

ConfigurableNumberInput.displayName = 'ConfigurableNumberInput';
export { ConfigurableNumberInput };
