// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file model/search/v1/result.proto (package model.search.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message model.search.v1.SearchResult
 */
export class SearchResult extends Message<SearchResult> {
  /**
   * @generated from oneof model.search.v1.SearchResult.result
   */
  result: {
    /**
     * @generated from field: model.search.v1.DemandResult demand = 1;
     */
    value: DemandResult;
    case: "demand";
  } | {
    /**
     * @generated from field: model.search.v1.EstimationRequestResult estimation_request = 2;
     */
    value: EstimationRequestResult;
    case: "estimationRequest";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SearchResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.search.v1.SearchResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "demand", kind: "message", T: DemandResult, oneof: "result" },
    { no: 2, name: "estimation_request", kind: "message", T: EstimationRequestResult, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchResult {
    return new SearchResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchResult {
    return new SearchResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchResult {
    return new SearchResult().fromJsonString(jsonString, options);
  }

  static equals(a: SearchResult | PlainMessage<SearchResult> | undefined, b: SearchResult | PlainMessage<SearchResult> | undefined): boolean {
    return proto3.util.equals(SearchResult, a, b);
  }
}

/**
 * @generated from message model.search.v1.DemandResult
 */
export class DemandResult extends Message<DemandResult> {
  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<DemandResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.search.v1.DemandResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DemandResult {
    return new DemandResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DemandResult {
    return new DemandResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DemandResult {
    return new DemandResult().fromJsonString(jsonString, options);
  }

  static equals(a: DemandResult | PlainMessage<DemandResult> | undefined, b: DemandResult | PlainMessage<DemandResult> | undefined): boolean {
    return proto3.util.equals(DemandResult, a, b);
  }
}

/**
 * @generated from message model.search.v1.EstimationRequestResult
 */
export class EstimationRequestResult extends Message<EstimationRequestResult> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string estimation_request_id = 2;
   */
  estimationRequestId = "";

  constructor(data?: PartialMessage<EstimationRequestResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.search.v1.EstimationRequestResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "estimation_request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimationRequestResult {
    return new EstimationRequestResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimationRequestResult {
    return new EstimationRequestResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimationRequestResult {
    return new EstimationRequestResult().fromJsonString(jsonString, options);
  }

  static equals(a: EstimationRequestResult | PlainMessage<EstimationRequestResult> | undefined, b: EstimationRequestResult | PlainMessage<EstimationRequestResult> | undefined): boolean {
    return proto3.util.equals(EstimationRequestResult, a, b);
  }
}

