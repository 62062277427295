import { Box } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { SupplierForm } from 'features/supplier/components/SupplierForm';
import { useEditSupplier } from 'features/supplier/hooks/useEditSupplier';
import { edit_SupplierEditPageQuery } from 'gql/__generated__/edit_SupplierEditPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const SupplierEditPageQuery = graphql`
  query edit_SupplierEditPageQuery  ($id: ID!) {
    suppliers(where: {id: $id}) {
      edges {
        node {
          name
          contacts {
            id
            firstName
            lastName
            email
            memos {
              body
            }
          }
        }
      }
    }
  }
`;

export function SupplierEditPage() {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { suppliers } = useLazyLoadQuery<edit_SupplierEditPageQuery>(
    SupplierEditPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const supplier = (suppliers?.edges || [])[0]?.node;
  const { onSubmit, isMutationInFlight } = useEditSupplier(id);
  const contacts = [
    ...(supplier?.contacts || []).map((contact) => ({
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      memo: contact.memos && contact.memos.length > 0 ? contact.memos[0].body : '',
    })),
  ];

  if (!supplier) return null;

  return (
    <>
      <Box mb={6}>
        <Link to={paths.supplier._id(id).url()}>
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label="サプライヤーの編集" />
      </Box>
      <Box w="480px">
        <SupplierForm
          type="edit"
          value={{
            name: supplier.name,
            contacts,
          }}
          onSubmit={onSubmit}
          isMutationInFlight={isMutationInFlight}
        />
      </Box>
    </>
  );
}
