/**
 * @generated SignedSource<<1bb42552de606f0d3affb1adac7022da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationRequestDetailType = "item" | "%future added value";
export type addAssignees_estimationRequestAddAssigneesPageQuery$variables = {
  id: string;
};
export type addAssignees_estimationRequestAddAssigneesPageQuery$data = {
  readonly estimationRequests: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly details: ReadonlyArray<{
          readonly item: {
            readonly name: string;
            readonly quantity: any;
            readonly specJSON: any;
          } | null;
          readonly type: EstimationRequestDetailType;
        }> | null;
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly id: string;
          readonly user: {
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
            }> | null;
          };
        }> | null;
        readonly requestAttachments: ReadonlyArray<{
          readonly byteSize: any;
          readonly name: string;
        }> | null;
        readonly requestTitle: string;
        readonly specText: string;
        readonly " $fragmentSpreads": FragmentRefs<"EstimationRequestAddAssigneeFormFragment">;
      } | null;
    } | null> | null;
  };
};
export type addAssignees_estimationRequestAddAssigneesPageQuery = {
  response: addAssignees_estimationRequestAddAssigneesPageQuery$data;
  variables: addAssignees_estimationRequestAddAssigneesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestTitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specText",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "byteSize",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specJSON",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = [
  (v2/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addAssignees_estimationRequestAddAssigneesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EstimationRequestConnection",
        "kind": "LinkedField",
        "name": "estimationRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EstimationRequestEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EstimationRequest",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EstimationRequestAddAssigneeFormFragment"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationRequestAttachment",
                    "kind": "LinkedField",
                    "name": "requestAttachments",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationRequestDetail",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationRequestItem",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationRequestInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addAssignees_estimationRequestAddAssigneesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EstimationRequestConnection",
        "kind": "LinkedField",
        "name": "estimationRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EstimationRequestEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EstimationRequest",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationRequestDetail",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationRequestItem",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationRequestAttachment",
                    "kind": "LinkedField",
                    "name": "requestAttachments",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationRequestAssignee",
                    "kind": "LinkedField",
                    "name": "requestAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Supplier",
                        "kind": "LinkedField",
                        "name": "supplier",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationRequestInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9af663754e4ef29d22e660cc43658ed",
    "id": null,
    "metadata": {},
    "name": "addAssignees_estimationRequestAddAssigneesPageQuery",
    "operationKind": "query",
    "text": "query addAssignees_estimationRequestAddAssigneesPageQuery(\n  $id: ID!\n) {\n  estimationRequests(where: {id: $id}) {\n    edges {\n      node {\n        ...EstimationRequestAddAssigneeFormFragment\n        id\n        requestTitle\n        specText\n        requestAttachments {\n          name\n          byteSize\n          id\n        }\n        details {\n          type\n          item {\n            name\n            specJSON\n            quantity\n            id\n          }\n          id\n        }\n        internalAssignees {\n          id\n          user {\n            profile {\n              firstName\n              lastName\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EstimationRequestAddAssigneeFormFragment on EstimationRequest {\n  ...EstimationRequestPreviewItemsFragment\n  ...EstimationRequestAttachmentFilesFragment\n  company {\n    id\n    name\n  }\n  requestTitle\n  specText\n  requestAssignees {\n    supplier {\n      id\n      name\n    }\n    id\n  }\n  requestAttachments {\n    id\n  }\n}\n\nfragment EstimationRequestAttachmentFilesFragment on EstimationRequest {\n  requestAttachments {\n    id\n    name\n    byteSize\n  }\n}\n\nfragment EstimationRequestPreviewItemsFragment on EstimationRequest {\n  details {\n    type\n    item {\n      name\n      specJSON\n      quantity\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a71f3375dc8bf2a55123adb9d3cd04a";

export default node;
