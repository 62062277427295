import { DETAIL_MESSAGE_TEMPLATE, getSalesOrderInitialValues } from 'features/salesOrders/form';
import { edit_SalesOrderEditPageQuery$data } from 'gql/__generated__/edit_SalesOrderEditPageQuery.graphql';
import { new_SalesOrderNewPageQuery$data } from 'gql/__generated__/new_SalesOrderNewPageQuery.graphql';
import { dateFormatHyphenDate } from 'utils/date';
import { concatFullName } from 'utils/label';

export const buildDefaultValue = ({
  salesOrders,
  tax,
  currentUser,
}: {
  salesOrders: edit_SalesOrderEditPageQuery$data['salesOrders'];
  tax: edit_SalesOrderEditPageQuery$data['taxCategories'];
  currentUser: edit_SalesOrderEditPageQuery$data['currentUser'];
}) => {
  const salesOrder = salesOrders?.edges?.at(0)?.node;
  if (!salesOrder) return getSalesOrderInitialValues({ tax: buildTaxValue(tax), currentUser });

  const internalAssignees =
    salesOrder?.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    id: salesOrder.id,
    company: {
      id: salesOrder.demand.id || '',
      name: salesOrder.demand.name,
    },
    supplier: {
      id: salesOrder.supplier.id,
      name: salesOrder.supplier.name,
    },
    title: salesOrder.title || '',
    detailMessage: salesOrder.detailMessage || DETAIL_MESSAGE_TEMPLATE,
    receivedDate: dateFormatHyphenDate(salesOrder.receivedDate),
    details: (salesOrder.details?.edges || [])
      .map((detail) => {
        const item = detail?.node?.item;
        const fixedDeliveryDate =
          detail?.node?.deliveryEvents?.edges?.at(0)?.node?.fixedDeliveryDate;
        if (detail && item) {
          return {
            id: detail.node.id,
            name: item.name || '',
            quantity: item.quantity as number | undefined,
            unitPrice: item.unitPrice,
            unitSellingPrice: item.unitSellingPrice,
            tax: {
              id: item.tax.id,
              rate: item.tax.rate,
            },
            fixedDeliveryDate: fixedDeliveryDate
              ? dateFormatHyphenDate(fixedDeliveryDate)
              : undefined,
          };
        }
      })
      .filter((v): v is NonNullable<typeof v> => v != null),
    internalAssignees,
    memo: salesOrder.internalMemos?.at(0)?.body || '',
  };
};

const buildTaxValue = (taxCategories: new_SalesOrderNewPageQuery$data['taxCategories']) => {
  const tax = taxCategories?.edges?.at(0)?.node;

  return {
    id: tax?.id || '',
    rate: tax?.rate || 0,
  };
};
