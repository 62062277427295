import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

type Props = {
  onClick?: () => void;
};

export function PageBack({ onClick }: Props) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button w={10} h={10} borderRadius={50} onClick={handleClick}>
      <ArrowBackIcon />
    </Button>
  );
}
