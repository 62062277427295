import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Export } from '@icon-park/react';
import { SubHeading } from 'components/SubHeading';
import {
  getLatestEstimationResponse,
  hasEstimationResponse,
} from 'features/estimationRequest/utils';
import { EstimationResponsesFragment$key } from 'gql/__generated__/EstimationResponsesFragment.graphql';
import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { paths } from 'utils/paths';
import { EstimationResponseItemTable } from '../EstimationResponseItemTable';

const EstimationResponsesFragment = graphql`
  fragment EstimationResponsesFragment on EstimationRequest
  {
    requestAssignees {
      id
      supplier {
        id
        name
      }
      token
      responses {
        id
        responseDetailMessage
        expirationPeriod
        importantNotes
        createdAt
        ...EstimationResponseItemTableFragment
      }
    }
  }
`;

export const EstimationResponses = ({
  queryRef,
  onClickExport,
  isExporting,
}: {
  queryRef: EstimationResponsesFragment$key;
  onClickExport: () => void;
  isExporting: boolean;
}) => {
  const { requestAssignees } = useFragment(EstimationResponsesFragment, queryRef);

  return (
    <Box mb={6}>
      <Flex mb={6} height={9} alignItems="flex-end" justifyContent="space-between">
        <SubHeading label="見積結果" />
        <Button
          onClick={onClickExport}
          fontSize="sm"
          fontWeight="semibold"
          colorScheme="blue"
          pt={1}
          pb={1}
          height="2rem"
          leftIcon={<Export />}
          iconSpacing={1}
          isLoading={isExporting}
          loadingText="見積結果を書き出し中"
        >
          見積結果を書き出す
        </Button>
      </Flex>
      <VStack spacing={8} w="100%">
        {requestAssignees?.map((requestAssign) => {
          const hasResponse = hasEstimationResponse(requestAssign.responses);

          return (
            <VStack
              borderRadius="0.5rem"
              borderColor="gray.300"
              borderWidth="1px"
              p={6}
              key={`request-assignee-${requestAssign.id}`}
              w="100%"
              alignItems="stretch"
              spacing={4}
            >
              <HStack justify="space-between" alignItems="center">
                <Heading size="md">{requestAssign.supplier.name}</Heading>
                {(() => {
                  const response = getLatestEstimationResponse(requestAssign.responses);
                  return (
                    <Wrap>
                      <WrapItem>
                        <Button
                          as={Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          to={paths.estimationResponse.url(requestAssign.token)}
                        >
                          回答を編集
                        </Button>
                      </WrapItem>
                      <WrapItem>
                        <Tooltip
                          label="回答がないため引用出来ません。サプライヤーに回答いただけない場合は、「回答を編集」から代理で回答しましょう。"
                          isDisabled={hasResponse}
                        >
                          <Button
                            as={Link}
                            colorScheme="blue"
                            isDisabled={!hasResponse}
                            to={
                              hasResponse
                                ? paths.estimation.new.url({
                                    responseId: response?.id,
                                    requestAssigneeId: requestAssign.id,
                                  })
                                : ''
                            }
                          >
                            引用して見積書作成
                          </Button>
                        </Tooltip>
                      </WrapItem>
                    </Wrap>
                  );
                })()}
              </HStack>
              {hasResponse ? (
                (() => {
                  const response = getLatestEstimationResponse(requestAssign.responses);
                  if (!response) return null;

                  return (
                    <>
                      <HStack spacing={4}>
                        <Text>回答日：{dateFormat(response.createdAt)}</Text>
                        <Text>有効期限：{response.expirationPeriod}</Text>
                      </HStack>
                      <Box>
                        <EstimationResponseItemTable queryRef={response} />
                        <Box mt={6}>
                          <Heading as="h3" size="sm" mb={1}>
                            諸条件
                          </Heading>
                          <Text whiteSpace="pre-wrap">{response.importantNotes}</Text>
                        </Box>
                        {response.responseDetailMessage && (
                          <Box mt={6}>
                            <Heading as="h3" size="sm" mb={1}>
                              備考
                            </Heading>
                            <Text whiteSpace="pre-wrap">{response.responseDetailMessage}</Text>
                          </Box>
                        )}
                      </Box>
                    </>
                  );
                })()
              ) : (
                <Text color="gray.500" fontSize="sm">
                  回答待ちです…
                </Text>
              )}
            </VStack>
          );
        })}
      </VStack>
    </Box>
  );
};
