import { zodResolver } from '@hookform/resolvers/zod';
import { DemandItemFormType, demandItemFormSchema } from 'features/demandItem/form';
import {
  DEMAND_ITEM_CATEGORY,
  DemandItemCategory,
  buildDemandItemWhere,
  parseDemandItemCodeForUrl,
} from 'features/demandItem/models';
import { layout_DemandItemEditLayoutQuery } from 'gql/__generated__/layout_DemandItemEditLayoutQuery.graphql';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useParams } from 'react-router-dom';
import { MissingCategoryError, MissingRequiredParamError } from 'utils/error';
import {
  DemandItemContext,
  KeepVersionContext,
  PricesMemoContext,
  PricesMemoType,
} from './context';

const DemandItemEditLayoutQuery = graphql`
  query layout_DemandItemEditLayoutQuery($where: DemandItemWhereInput!) {
    demandItems(where: $where) {
      edges {
        node {
          id
          name
          supplier {
            id
            name
          }
          internalMemos {
            body
          }
          category
          cardboard {
            size
            type
            material
            thickness
            printingColor
            processing
            other
          }
          flexiblePackage {
            size
            material
            printingColor
            processing
            other
          }
          giftBox {
            size
            type
            paperType
            printingColor
            processing
            other
          }
          paperBag {
            size
            paperType
            printingColor
            processing
            handle
            other
          }
          other {
            specText
          }
          taxCategoryID
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
          isCurrent
        }
      }
    }
  }
`;

export const DemandItemEditLayout = () => {
  const { demandCode, itemCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }
  if (!itemCode) {
    throw new MissingRequiredParamError('itemCode');
  }

  const { category, itemNumber, version } = parseDemandItemCodeForUrl(itemCode);
  if (!category) {
    throw new MissingCategoryError();
  }

  const where = buildDemandItemWhere({ category, itemNumber, version });
  const { demandItems } = useLazyLoadQuery<layout_DemandItemEditLayoutQuery>(
    DemandItemEditLayoutQuery,
    { where },
    { fetchPolicy: 'network-only' },
  );

  const form = useForm<DemandItemFormType>({
    resolver: zodResolver(demandItemFormSchema),
    defaultValues: {
      name: demandItems?.edges?.[0]?.node?.name ?? '',
      supplier: {
        id: demandItems?.edges?.[0]?.node?.supplier?.id ?? '',
        name: demandItems?.edges?.[0]?.node?.supplier?.name ?? '',
      },
      memo: demandItems?.edges?.[0]?.node?.internalMemos?.[0]?.body ?? '',
      category:
        (demandItems?.edges?.[0]?.node?.category as DemandItemCategory) ??
        DEMAND_ITEM_CATEGORY.Cardboard,
      cardboard: demandItems?.edges?.[0]?.node?.cardboard ?? {
        size: '',
        type: '',
        material: '',
        thickness: '',
        printingColor: '',
        processing: '',
        other: '',
      },
      flexiblePackage: demandItems?.edges?.[0]?.node?.flexiblePackage ?? {
        size: '',
        type: '',
        material: '',
        printingColor: '',
        processing: '',
        other: '',
      },
      giftBox: demandItems?.edges?.[0]?.node?.giftBox ?? {
        size: '',
        type: '',
        paperType: '',
        printingColor: '',
        processing: '',
        other: '',
      },
      paperBag: demandItems?.edges?.[0]?.node?.paperBag ?? {
        size: '',
        paperType: '',
        printingColor: '',
        processing: '',
        handle: '',
        other: '',
      },
      other: demandItems?.edges?.[0]?.node?.other ?? {
        specText: '',
      },
      taxId: demandItems?.edges?.[0]?.node?.taxCategoryID ?? '',
      prices:
        demandItems?.edges?.[0]?.node?.prices?.map((price) => ({
          id: price.id,
          quantity: price.quantity,
          unitPrice: price.unitPrice,
          unitSellingPrice: price.unitSellingPrice,
        })) ?? [],
    },
  });

  const [keepVersion, setKeepVersion] = useState(true);

  if (
    !demandItems ||
    !demandItems.edges ||
    demandItems.edges.length === 0 ||
    !demandItems.edges[0]?.node ||
    demandItems.edges[0]?.node === null
  )
    return null;

  const pricesMap = new Map<string, PricesMemoType>();
  demandItems?.edges?.[0]?.node?.prices?.forEach((price) => {
    pricesMap.set(price.id, {
      quantity: price.quantity,
      unitPrice: price.unitPrice,
      unitSellingPrice: price.unitSellingPrice,
    });
  });

  return (
    <DemandItemContext.Provider
      value={{
        itemId: demandItems?.edges?.[0]?.node?.id ?? '',
        isCurrentVersion: demandItems?.edges?.[0]?.node?.isCurrent ?? false,
      }}
    >
      <PricesMemoContext.Provider value={pricesMap}>
        <KeepVersionContext.Provider value={{ keepVersion, setKeepVersion }}>
          <FormProvider {...form}>
            <Outlet />
          </FormProvider>
        </KeepVersionContext.Provider>
      </PricesMemoContext.Provider>
    </DemandItemContext.Provider>
  );
};
