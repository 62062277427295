import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

type Props = {
  onClickSubmit: () => void;
  onClose: () => void;
  isOpen: boolean;
  isMutationInFlight: boolean;
};

export const AttentionDomainModal = ({
  onClickSubmit,
  onClose,
  isOpen,
  isMutationInFlight,
}: Props) => {
  const { onClose: onModalClose } = useDisclosure();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      isCentered
      closeOnOverlayClick={!isMutationInFlight}
    >
      <ModalOverlay />
      <ModalContent data-testid="attention-domain-modal-content">
        <ModalHeader>ドメインが違うメールアドレスが存在します</ModalHeader>
        <ModalBody>
          複数のドメインが存在する場合、入力されているメールアドレスが間違っている可能性があります。正しく入力が行われているか再度確認を行いましょう。
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onClose();
              onModalClose();
            }}
            isDisabled={isMutationInFlight}
            data-testid="attention-domain-modal-cancel-button"
          >
            キャンセル
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            onClick={onClickSubmit}
            isDisabled={isMutationInFlight}
            data-testid="attention-domain-modal-submit-button"
          >
            現状のまま追加
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
