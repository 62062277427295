import Dexie, { Table } from 'dexie';
import { EstimationRequestFormState } from 'features/estimationRequest/hooks/useEstimationRequestForm';

// スキーマに変更を入れる際は必ずバージョンを変更する
const SCHEMA_VERSION = 1;

export type EstimationRequestDraftColumn = {
  id?: number;
  body: EstimationRequestFormState;
  createdAt: number;
};

export class LubeIndexedDB extends Dexie {
  estimationRequestDrafts!: Table<EstimationRequestDraftColumn>;

  constructor() {
    super('lube');
    this.version(SCHEMA_VERSION).stores({
      estimationRequestDrafts: '++id, body, createdAt',
    });
  }
}

export const db = new LubeIndexedDB();
