import { Loading } from '@icon-park/react';
import { LinkageFreeeModal } from 'components/LinkageFreeeModal';
import { InvoiceConfirm } from 'features/invoice/components/InvoiceConfirm';
import { InvoiceInputForm } from 'features/invoice/components/InvoiceInputForm';
import { InvoiceSalesOrdersSelectForm } from 'features/invoice/components/InvoiceSalesOrdersSelectForm';
import { INVOICE_FORM_TYPE, INVOICE_SUBMIT_TYPE } from 'features/invoice/form';
import { useInvoiceEditForm } from 'features/invoice/hooks/useInvoiceEditForm';
import { useLinkageFreee } from 'features/invoice/hooks/useLinkageFreee';
import { edit_InvoiceEditPageQuery } from 'gql/__generated__/edit_InvoiceEditPageQuery.graphql';
import { Suspense, useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormatHyphenDate } from 'utils/date';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const InvoiceEditPageQuery = graphql`
  query edit_InvoiceEditPageQuery  ($id: ID!) {
    invoices(where: {id: $id}) {
      edges {
        node {
          id
          title
          bookedAt
          paymentDueAt
          detailMessage
          internalMemos {
            body
          }
          demand: company {
            id
            name
          }
          details(orderBy: { direction: ASC, field: ORDER_NUMBER }) {
            edges {
              node {
                id
                orderNumber
                salesOrderDetailID
                salesOrderDetail {
                  salesOrderID
                }
                item {
                  id
                  name
                  quantity
                  unitSellingPrice
                  taxCategory {
                    id
                    rate
                  }
                }
                invoiceDetailsSales (where: {isValid: true}) {
                  edges {
                    node {
                      salesDate
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    userInfo {
      connectedServices {
        freee
      }
    }
  }
`;

export const InvoiceEditPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');

  const { invoices, userInfo } = useLazyLoadQuery<edit_InvoiceEditPageQuery>(
    InvoiceEditPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );
  const invoice = invoices.edges?.at(0)?.node;

  const {
    salesOrderSelectValues,
    invoiceInputValues,
    fetchIds,
    currentPage,
    relatedSalesOrderDetailIds,
    fromSelectToInput,
    fromInputToSelect,
    fromConfirmToInput,
    fromInputToConfirm,
    onSubmit,
    isMutationInFlight,
  } = useInvoiceEditForm(id, {
    demand: { id: invoice?.demand.id || '', name: invoice?.demand.name || '' },
    salesOrderDetailIds: (invoice?.details?.edges || [])
      .map((detail) => detail?.node?.salesOrderDetailID)
      .filter((v): v is NonNullable<typeof v> => v != null),
    title: invoice?.title || '',
    dates: {
      bookedAt: invoice?.bookedAt,
      paymentDueAt: invoice?.paymentDueAt,
    },
    detailMessage: invoice?.detailMessage || '',
    memo: invoice?.internalMemos?.at(0)?.body || '',
    initialDetails:
      invoice?.details?.edges?.map((detail) => ({
        invoiceDetailId: detail?.node?.id || undefined,
        salesOrderDetailId: detail?.node?.salesOrderDetailID || undefined,
        name: detail?.node?.item?.name || '',
        quantity: detail?.node?.item?.quantity || 0,
        unitSellingPrice: detail?.node?.item?.unitSellingPrice || 0,
        tax: {
          id: detail?.node?.item?.taxCategory?.id || '',
          rate: detail?.node?.item?.taxCategory?.rate || 0,
        },
        salesDate: detail?.node?.invoiceDetailsSales?.edges?.at(0)?.node?.salesDate
          ? dateFormatHyphenDate(detail?.node?.invoiceDetailsSales?.edges?.at(0)?.node?.salesDate)
          : '',
      })) || [],
  });

  const isOpenLinkageDialog = !userInfo.connectedServices.freee;
  const navigate = useNavigate();
  const { onLinkageFreeeApi } = useLinkageFreee();

  useEffect(() => {
    if (type && currentPage === INVOICE_SUBMIT_TYPE.select) {
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [currentPage, searchParams, setSearchParams, type]);

  if (!invoice) return null;

  return (
    <>
      <LinkageFreeeModal
        isOpen={isOpenLinkageDialog}
        closeButtonLabel="請求書詳細へ"
        onClose={() => {
          navigate(paths.invoice._id(id));
        }}
        onClickLinkage={onLinkageFreeeApi}
      ></LinkageFreeeModal>
      <Suspense fallback={<Loading />}>
        {currentPage === INVOICE_SUBMIT_TYPE.confirm && type === INVOICE_SUBMIT_TYPE.confirm && (
          <InvoiceConfirm
            type={INVOICE_FORM_TYPE.edit}
            demand={salesOrderSelectValues.demand.name}
            values={invoiceInputValues}
            isMutationInFlight={isMutationInFlight}
            toInputForm={fromConfirmToInput}
            onSubmit={onSubmit}
          />
        )}
        {currentPage === INVOICE_SUBMIT_TYPE.input && type === INVOICE_SUBMIT_TYPE.input && (
          <InvoiceInputForm
            type={INVOICE_FORM_TYPE.edit}
            defaultValues={invoiceInputValues}
            selectedSalesOrderDetailIds={salesOrderSelectValues.salesOrderDetailIds}
            fetchIds={fetchIds}
            toSelectForm={fromInputToSelect}
            onSubmit={fromInputToConfirm}
          />
        )}
        {INVOICE_SUBMIT_TYPE.select && !type && (
          <InvoiceSalesOrdersSelectForm
            type={INVOICE_FORM_TYPE.edit}
            defaultValues={salesOrderSelectValues}
            relatedSalesOrderDetailIds={relatedSalesOrderDetailIds}
            onSubmit={fromSelectToInput}
            previousUrl={paths.invoice._id(id)}
          />
        )}
      </Suspense>
    </>
  );
};
