import { HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { deduplicateAdjacentNames } from 'features/estimation/components/helper';
import { calculateDetailAmount } from 'utils/priceCalculation';

type Props = {
  details: Readonly<{
    name: string;
    unitPrice: number;
    quantity: number;
    unitSellingPrice: number;
  }>[];
};

export const ItemPriceList = ({ details }: Props) => {
  const items = deduplicateAdjacentNames(details);

  return (
    <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full" mb={6}>
      <HStack justify="space-between" align="center">
        <Text fontSize="md" fontWeight="bold">
          単価表
        </Text>
        <Text color="gray.500" fontSize="sm">
          ※見積書には記載されません
        </Text>
      </HStack>
      <Table>
        <Thead>
          <Tr>
            <Th color="gray.700" w="100%" pl="0">
              品名
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
              数量
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
              仕入単価
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
              顧客単価
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
              金額 (税抜)
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, i) => (
            <Tr key={`${item.name}-${i}`} verticalAlign="top">
              <Td pl="0" whiteSpace="pre-wrap">
                {item.name}
              </Td>
              <Td isNumeric color="gray.700">
                {item.quantity.toLocaleString()}
              </Td>
              <Td isNumeric color="gray.700">
                {item.unitPrice.toLocaleString()}
              </Td>
              <Td isNumeric color="gray.700">
                {item.unitSellingPrice.toLocaleString()}
              </Td>
              <Td isNumeric color="gray.700" pr="0">
                {calculateDetailAmount({
                  quantity: item.quantity,
                  price: item.unitSellingPrice,
                }).toLocaleString()}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};
