import { useLiveQuery } from 'dexie-react-hooks';
import { EstimationRequestConfirm } from 'features/estimationRequest/components/EstimationRequestConfirm';
import { EstimationRequestForm } from 'features/estimationRequest/components/EstimationRequestForm';
import {
  EstimationRequestFormState,
  initialEstimationRequestFormState,
  useEstimationRequestForm,
} from 'features/estimationRequest/hooks/useEstimationRequestForm';
import { new_EstimationRequestNewPageQuery } from 'gql/__generated__/new_EstimationRequestNewPageQuery.graphql';
import { toast } from 'lib/toast';
import { captureException } from 'logrocket';
import { Suspense, useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { getFirstEstimationRequestDraft } from 'repositories/estimationRequestDraftRepository';
import { concatFullName } from 'utils/label';

const EstimationRequestNewPageQuery = graphql`
  query new_EstimationRequestNewPageQuery {
    currentUser {
      userID
      firstName
      lastName
    }
  }
`;

export function EstimationRequestNewPage() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const { currentUser } = useLazyLoadQuery<new_EstimationRequestNewPageQuery>(
    EstimationRequestNewPageQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const [formState, setFormState] = useState<EstimationRequestFormState>({
    ...initialEstimationRequestFormState,
    internalAssignees: [
      {
        value: currentUser.userID,
        label: concatFullName({ lastName: currentUser.lastName, firstName: currentUser.firstName }),
      },
    ],
  });

  const draftData = useLiveQuery(async () => {
    try {
      return await getFirstEstimationRequestDraft();
    } catch (error) {
      toast({
        title: '下書きの取得に失敗しました',
        status: 'error',
      });
      captureException(error as Error);
    }
  });

  const { data, showPreview, onClickToConfirm, onSubmit, isMutationInFlight, onClickToNewForm } =
    useEstimationRequestForm(formState);

  useEffect(() => {
    if (type === 'draft' && draftData && draftData.body) {
      setFormState(draftData.body);
    }
  }, [draftData, type]);

  if (!data) return null;

  return (
    <>
      <Suspense fallback={<div>loading...</div>}>
        {showPreview ? (
          <EstimationRequestConfirm
            onClickSubmit={() => {
              onSubmit(data);
            }}
            values={{
              mailSubject: data.mailSubject,
              specText: data.specText,
              suppliers: data.suppliers,
              attachments: Array.from(data.attachments).map((a) => ({
                name: a.name,
                size: a.size,
              })),
              items: data.items.map((item) => ({ ...item, quantity: Number(item.quantity) || 0 })),
              internalAssignees: data.internalAssignees || [],
            }}
            isMutationInFlight={isMutationInFlight}
            onClickToForm={onClickToNewForm}
          />
        ) : (
          <EstimationRequestForm onClickToConfirm={onClickToConfirm} data={data} />
        )}
      </Suspense>
    </>
  );
}
