import { Box, Flex } from '@chakra-ui/react';
import { SideBar } from 'components/SideBar';
import { ReactElement, createContext, useState } from 'react';
import { ScrollRestoration } from 'react-router-dom';

type Props = {
  children: ReactElement;
};

type LayoutProps = {
  width?: number | string;
};

type context = {
  layoutProps?: LayoutProps;
  setLayoutProps?: (props: LayoutProps) => void;
};

export const LayoutContext = createContext<context>({});

export function Layout({ children }: Props) {
  const [layoutProps, setLayoutProps] = useState<LayoutProps>({
    width: '100%',
  });

  return (
    <LayoutContext.Provider value={{ layoutProps, setLayoutProps }}>
      <ScrollRestoration />
      <Flex>
        <SideBar />
        <Box width={layoutProps.width} padding={10}>
          {children}
        </Box>
      </Flex>
    </LayoutContext.Provider>
  );
}
