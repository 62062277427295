import { useSession } from 'hooks/useSession';
import { Navigate } from 'react-router-dom';
import { paths } from 'utils/paths';

export const Root = () => {
  const { currentUser } = useSession();

  if (!currentUser) {
    return <Navigate to={paths.login.url()} />;
  }

  return <Navigate to={paths.estimationRequest.url()} />;
};
