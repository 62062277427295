/**
 * @generated SignedSource<<039490ccb9242193f3b527646c398d40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationRequestTableRowFragment$data = {
  readonly company: {
    readonly id: string;
    readonly name: string;
  };
  readonly createdAt: any;
  readonly id: string;
  readonly internalAssignees: ReadonlyArray<{
    readonly user: {
      readonly id: string;
      readonly profile: ReadonlyArray<{
        readonly firstName: string;
        readonly lastName: string;
      }> | null;
    };
  }> | null;
  readonly requestAssignees: ReadonlyArray<{
    readonly supplier: {
      readonly id: string;
      readonly name: string;
    };
  }> | null;
  readonly requestTitle: string;
  readonly " $fragmentType": "EstimationRequestTableRowFragment";
};
export type EstimationRequestTableRowFragment$key = {
  readonly " $data"?: EstimationRequestTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationRequestTableRowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationRequestTableRowFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationRequestAssignee",
      "kind": "LinkedField",
      "name": "requestAssignees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Supplier",
          "kind": "LinkedField",
          "name": "supplier",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationRequestInternalAssignee",
      "kind": "LinkedField",
      "name": "internalAssignees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EstimationRequest",
  "abstractKey": null
};
})();

(node as any).hash = "50efe04580c8f76db79847c8ff72f76e";

export default node;
