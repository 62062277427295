// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file model/item/v1/summarize_item.proto (package model.item.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Empty, Message, proto3, Struct, Value } from "@bufbuild/protobuf";
import { CardboardSpec, FlexiblePackageSpec, GiftBoxSpec, OtherSpec, PaperBagSpec } from "./category_pb.js";

/**
 * 商品カテゴリ
 *
 * @generated from enum model.item.v1.ItemCategory
 */
export enum ItemCategory {
  /**
   * @generated from enum value: ITEM_CATEGORY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 段ボール
   *
   * @generated from enum value: ITEM_CATEGORY_CARDBOARD = 1;
   */
  CARDBOARD = 1,

  /**
   * 化粧箱
   *
   * @generated from enum value: ITEM_CATEGORY_GIFT_BOX = 2;
   */
  GIFT_BOX = 2,

  /**
   * 軟包材
   *
   * @generated from enum value: ITEM_CATEGORY_FLEXIBLE_PACKAGE = 3;
   */
  FLEXIBLE_PACKAGE = 3,

  /**
   * 紙袋
   *
   * @generated from enum value: ITEM_CATEGORY_PAPER_BAG = 4;
   */
  PAPER_BAG = 4,

  /**
   * その他
   *
   * @generated from enum value: ITEM_CATEGORY_OTHER = 5;
   */
  OTHER = 5,

  /**
   * 不明
   *
   * @generated from enum value: ITEM_CATEGORY_UNKNOWN = 6;
   */
  UNKNOWN = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ItemCategory)
proto3.util.setEnumType(ItemCategory, "model.item.v1.ItemCategory", [
  { no: 0, name: "ITEM_CATEGORY_UNSPECIFIED" },
  { no: 1, name: "ITEM_CATEGORY_CARDBOARD" },
  { no: 2, name: "ITEM_CATEGORY_GIFT_BOX" },
  { no: 3, name: "ITEM_CATEGORY_FLEXIBLE_PACKAGE" },
  { no: 4, name: "ITEM_CATEGORY_PAPER_BAG" },
  { no: 5, name: "ITEM_CATEGORY_OTHER" },
  { no: 6, name: "ITEM_CATEGORY_UNKNOWN" },
]);

/**
 * @generated from enum model.item.v1.ItemCategoryDataType
 */
export enum ItemCategoryDataType {
  /**
   * @generated from enum value: ITEM_CATEGORY_DATA_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 文字列
   *
   * @generated from enum value: ITEM_CATEGORY_DATA_TYPE_STRING = 1;
   */
  STRING = 1,

  /**
   * 数値
   *
   * @generated from enum value: ITEM_CATEGORY_DATA_TYPE_NUMBER = 2;
   */
  NUMBER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ItemCategoryDataType)
proto3.util.setEnumType(ItemCategoryDataType, "model.item.v1.ItemCategoryDataType", [
  { no: 0, name: "ITEM_CATEGORY_DATA_TYPE_UNSPECIFIED" },
  { no: 1, name: "ITEM_CATEGORY_DATA_TYPE_STRING" },
  { no: 2, name: "ITEM_CATEGORY_DATA_TYPE_NUMBER" },
]);

/**
 * カラム定義
 *
 * @generated from message model.item.v1.ItemSpecColumn
 */
export class ItemSpecColumn extends Message<ItemSpecColumn> {
  /**
   * カラム英名
   *
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * カラム名
   *
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * カラムのデータ型
   *
   * @generated from field: model.item.v1.ItemCategoryDataType data_type = 3;
   */
  dataType = ItemCategoryDataType.UNSPECIFIED;

  /**
   * 変更可能かどうか
   *
   * @generated from field: bool editable = 4;
   */
  editable = false;

  constructor(data?: PartialMessage<ItemSpecColumn>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.ItemSpecColumn";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data_type", kind: "enum", T: proto3.getEnumType(ItemCategoryDataType) },
    { no: 4, name: "editable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ItemSpecColumn {
    return new ItemSpecColumn().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ItemSpecColumn {
    return new ItemSpecColumn().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ItemSpecColumn {
    return new ItemSpecColumn().fromJsonString(jsonString, options);
  }

  static equals(a: ItemSpecColumn | PlainMessage<ItemSpecColumn> | undefined, b: ItemSpecColumn | PlainMessage<ItemSpecColumn> | undefined): boolean {
    return proto3.util.equals(ItemSpecColumn, a, b);
  }
}

/**
 * @generated from message model.item.v1.ItemsBySpec
 */
export class ItemsBySpec extends Message<ItemsBySpec> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 親仕様のIDs（0番目が一番近い親）
   *
   * @generated from field: repeated string ancestor_ids = 2;
   */
  ancestorIds: string[] = [];

  /**
   * 末尾の仕様にのみ設定される、仕様の元となったアイテムのID
   *
   * @generated from field: optional string source_item_id = 3;
   */
  sourceItemId?: string;

  /**
   * 仕様名
   *
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * 値
   *
   * @generated from field: google.protobuf.Value value = 5;
   */
  value?: Value;

  /**
   * 子仕様
   *
   * @generated from field: repeated model.item.v1.ItemsBySpec items = 6;
   */
  items: ItemsBySpec[] = [];

  /**
   * @generated from oneof model.item.v1.ItemsBySpec.spec
   */
  spec: {
    /**
     * @generated from field: model.item.v1.CardboardSpec cardboard_spec = 7;
     */
    value: CardboardSpec;
    case: "cardboardSpec";
  } | {
    /**
     * @generated from field: model.item.v1.FlexiblePackageSpec flexible_package_spec = 8;
     */
    value: FlexiblePackageSpec;
    case: "flexiblePackageSpec";
  } | {
    /**
     * @generated from field: model.item.v1.GiftBoxSpec gift_box_spec = 9;
     */
    value: GiftBoxSpec;
    case: "giftBoxSpec";
  } | {
    /**
     * @generated from field: model.item.v1.PaperBagSpec paper_bag_spec = 10;
     */
    value: PaperBagSpec;
    case: "paperBagSpec";
  } | {
    /**
     * @generated from field: model.item.v1.OtherSpec other_spec = 11;
     */
    value: OtherSpec;
    case: "otherSpec";
  } | {
    /**
     * @generated from field: google.protobuf.Empty unknown_spec = 12;
     */
    value: Empty;
    case: "unknownSpec";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * 年間発注量
   *
   * @generated from field: optional string annual_quantity = 13;
   */
  annualQuantity?: string;

  /**
   * 希望単価
   *
   * @generated from field: optional string asking_unit_price = 14;
   */
  askingUnitPrice?: string;

  constructor(data?: PartialMessage<ItemsBySpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.ItemsBySpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ancestor_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "source_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "value", kind: "message", T: Value },
    { no: 6, name: "items", kind: "message", T: ItemsBySpec, repeated: true },
    { no: 7, name: "cardboard_spec", kind: "message", T: CardboardSpec, oneof: "spec" },
    { no: 8, name: "flexible_package_spec", kind: "message", T: FlexiblePackageSpec, oneof: "spec" },
    { no: 9, name: "gift_box_spec", kind: "message", T: GiftBoxSpec, oneof: "spec" },
    { no: 10, name: "paper_bag_spec", kind: "message", T: PaperBagSpec, oneof: "spec" },
    { no: 11, name: "other_spec", kind: "message", T: OtherSpec, oneof: "spec" },
    { no: 12, name: "unknown_spec", kind: "message", T: Empty, oneof: "spec" },
    { no: 13, name: "annual_quantity", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "asking_unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ItemsBySpec {
    return new ItemsBySpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ItemsBySpec {
    return new ItemsBySpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ItemsBySpec {
    return new ItemsBySpec().fromJsonString(jsonString, options);
  }

  static equals(a: ItemsBySpec | PlainMessage<ItemsBySpec> | undefined, b: ItemsBySpec | PlainMessage<ItemsBySpec> | undefined): boolean {
    return proto3.util.equals(ItemsBySpec, a, b);
  }
}

/**
 * カテゴリごとのサマリ
 *
 * @generated from message model.item.v1.CategoryItemsSummary
 */
export class CategoryItemsSummary extends Message<CategoryItemsSummary> {
  /**
   * カテゴリー
   *
   * @generated from field: model.item.v1.ItemCategory category = 1;
   */
  category = ItemCategory.UNSPECIFIED;

  /**
   * 利用カラム一覧（すべてのアイテムで利用していないカラムがある場合は除外される）
   *
   * @generated from field: repeated model.item.v1.ItemSpecColumn columns = 2;
   */
  columns: ItemSpecColumn[] = [];

  /**
   * サマリ済みアイテム一覧
   *
   * @generated from field: repeated model.item.v1.ItemsBySpec items = 3;
   */
  items: ItemsBySpec[] = [];

  constructor(data?: PartialMessage<CategoryItemsSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.CategoryItemsSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "enum", T: proto3.getEnumType(ItemCategory) },
    { no: 2, name: "columns", kind: "message", T: ItemSpecColumn, repeated: true },
    { no: 3, name: "items", kind: "message", T: ItemsBySpec, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryItemsSummary {
    return new CategoryItemsSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryItemsSummary {
    return new CategoryItemsSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryItemsSummary {
    return new CategoryItemsSummary().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryItemsSummary | PlainMessage<CategoryItemsSummary> | undefined, b: CategoryItemsSummary | PlainMessage<CategoryItemsSummary> | undefined): boolean {
    return proto3.util.equals(CategoryItemsSummary, a, b);
  }
}

/**
 * サマリ前のアイテム
 *
 * @generated from message model.item.v1.RawItem
 */
export class RawItem extends Message<RawItem> {
  /**
   * ID
   *
   * @generated from field: optional string id = 1;
   */
  id?: string;

  /**
   * アイテム名
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * 仕様
   *
   * @generated from field: google.protobuf.Struct spec_json = 3;
   */
  specJson?: Struct;

  /**
   * ロット数
   *
   * @generated from field: optional uint32 quantity = 4;
   */
  quantity?: number;

  /**
   * 単価
   *
   * @generated from field: optional double unit_price = 5;
   */
  unitPrice?: number;

  constructor(data?: PartialMessage<RawItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.RawItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "spec_json", kind: "message", T: Struct },
    { no: 4, name: "quantity", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
    { no: 5, name: "unit_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RawItem {
    return new RawItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RawItem {
    return new RawItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RawItem {
    return new RawItem().fromJsonString(jsonString, options);
  }

  static equals(a: RawItem | PlainMessage<RawItem> | undefined, b: RawItem | PlainMessage<RawItem> | undefined): boolean {
    return proto3.util.equals(RawItem, a, b);
  }
}

