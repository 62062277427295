import { HStack, Text, VStack } from '@chakra-ui/react';
import { OrderSendingFormData } from 'features/order/zod';
import { SupplierContactCheckboxGroup } from 'features/supplierContact/components/SupplierContactCheckboxGroup';
import { Control, useWatch } from 'react-hook-form';

type Props = {
  control: Control<OrderSendingFormData>;
  onChange: (selectedContact: { id: string; firstName: string; lastName: string }[]) => void;
  supplierId: string;
};

export const OrderSupplierContactsToCheckboxGroup = ({ control, onChange, supplierId }: Props) => {
  const ids = useWatch({ name: 'supplierContactsTo', control });

  return (
    <HStack align="start">
      <Text fontSize="sm" lineHeight={1}>
        TO：
      </Text>
      <VStack wrap="wrap" spacing={3} alignItems="flex-start">
        <SupplierContactCheckboxGroup
          supplierId={supplierId}
          value={ids?.map((id) => id) || []}
          addressTarget="to"
          onChange={onChange}
        />
      </VStack>
    </HStack>
  );
};
