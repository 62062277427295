import { VStack } from '@chakra-ui/react';
import { AttachmentFileButton } from 'components/AttachmentFileButton';
import { useDownloadEstimationRequestAttachment } from 'features/estimationRequest/hooks/useDownloadEstimationRequestAttachment';
import { EstimationRequestAttachmentFilesFragment$key } from 'gql/__generated__/EstimationRequestAttachmentFilesFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { formatBytes } from 'utils/number';

const EstimationRequestAttachmentFilesFragment = graphql`
  fragment EstimationRequestAttachmentFilesFragment on EstimationRequest
  {
    requestAttachments {
      id
      name
      byteSize
    }
  }
`;

export const EstimationRequestAttachmentFiles = ({
  queryRef,
}: { queryRef: EstimationRequestAttachmentFilesFragment$key }) => {
  const { downloadEstimationRequestAttachment } = useDownloadEstimationRequestAttachment();
  const { requestAttachments } = useFragment(EstimationRequestAttachmentFilesFragment, queryRef);

  if (!requestAttachments) return null;

  return (
    <VStack spacing={2} alignItems="start" mt={4}>
      {requestAttachments.map((attachment, i) => (
        <AttachmentFileButton
          key={`attachment-${attachment.id}-${i}`}
          name={attachment.name}
          size={formatBytes(attachment.byteSize)}
          onClick={() => downloadEstimationRequestAttachment(attachment.id)}
        />
      ))}
    </VStack>
  );
};
