/**
 * @generated SignedSource<<00d28f5eeeeda37436cc0675ad24aa3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type new_OrderNewPageQuery$variables = {
  isSkipToLoadSalesOrder: boolean;
  salesOrderId: string;
};
export type new_OrderNewPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrderFormFragment" | "new_OrderNewPageFragment">;
};
export type new_OrderNewPageQuery = {
  response: new_OrderNewPageQuery$data;
  variables: new_OrderNewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSkipToLoadSalesOrder"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "salesOrderId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "new_OrderNewPageQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isSkipToLoadSalesOrder",
            "variableName": "isSkipToLoadSalesOrder"
          },
          {
            "kind": "Variable",
            "name": "salesOrderId",
            "variableName": "salesOrderId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "new_OrderNewPageFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OrderFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "new_OrderNewPageQuery",
    "selections": [
      {
        "condition": "isSkipToLoadSalesOrder",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "salesOrderId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "where"
              }
            ],
            "concreteType": "SalesOrderConnection",
            "kind": "LinkedField",
            "name": "salesOrders",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrderEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrder",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Company",
                        "kind": "LinkedField",
                        "name": "demand",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Supplier",
                        "kind": "LinkedField",
                        "name": "supplier",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderDetailConnection",
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderDetailEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrderItem",
                                    "kind": "LinkedField",
                                    "name": "item",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "quantity",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "unitPrice",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TaxCategory",
                                        "kind": "LinkedField",
                                        "name": "taxCategory",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v5/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderInternalAssignee",
                        "kind": "LinkedField",
                        "name": "internalAssignees",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserProfile",
                                "kind": "LinkedField",
                                "name": "profile",
                                "plural": true,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userID",
            "storageKey": null
          },
          (v7/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxCategoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxCategory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b914e9ce31b8aa20d9359bb7ad7c0fb8",
    "id": null,
    "metadata": {},
    "name": "new_OrderNewPageQuery",
    "operationKind": "query",
    "text": "query new_OrderNewPageQuery(\n  $salesOrderId: ID!\n  $isSkipToLoadSalesOrder: Boolean!\n) {\n  ...new_OrderNewPageFragment_20fX5a\n  ...OrderFormFragment\n}\n\nfragment OrderFormFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n  ...TaxSelectBoxFragment\n}\n\nfragment TaxSelectBoxFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n\nfragment new_OrderNewPageFragment_20fX5a on Query {\n  salesOrders(where: {id: $salesOrderId}) @skip(if: $isSkipToLoadSalesOrder) {\n    edges {\n      node {\n        id\n        demand {\n          id\n          name\n        }\n        supplier {\n          id\n          name\n        }\n        details {\n          edges {\n            node {\n              id\n              item {\n                id\n                name\n                quantity\n                unitPrice\n                taxCategory {\n                  id\n                  rate\n                }\n              }\n            }\n          }\n        }\n        internalAssignees {\n          user {\n            id\n            email\n            profile {\n              lastName\n              firstName\n              phoneNumber\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  currentUser {\n    userID\n    firstName\n    lastName\n    phoneNumber\n  }\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0da4373c8ef1b91e82a11fa815a5f037";

export default node;
