import { useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Variables } from 'relay-runtime';
import { useQueryParams } from './useOrderQueryPrams';

export const getOrderWhere = ({
  userId,
  demandId,
  supplierId,
}: { userId?: string; demandId?: string; supplierId?: string }) => {
  const conditions: Array<Record<string, Record<string, string>>> = [];

  if (demandId) {
    conditions.push({ hasCompanyWith: { id: demandId } });
  }

  if (supplierId) {
    conditions.push({ hasSupplierWith: { id: supplierId } });
  }

  if (userId) {
    conditions.push({ hasInternalAssigneesWith: { userID: userId } });
  }

  if (conditions.length === 0) return {};

  return {
    where: {
      and: conditions,
    },
  };
};

type ParamsType = 'demand' | 'assignee' | 'supplier';

const buildQueryParams = (
  searchParams: URLSearchParams,
  { userId, demandId, supplierId }: { userId?: string; demandId?: string; supplierId?: string },
) => {
  const params: { [key in ParamsType]?: string } = {};

  const demand = demandId ?? searchParams.get('demand');
  const supplier = supplierId ?? searchParams.get('supplier');
  const assignee = userId ?? searchParams.get('assignee');

  if (demand) params.demand = demand;
  if (supplier) params.supplier = supplier;
  if (assignee) params.assignee = assignee;

  return params;
};

export const useOrderFilter = (refetch: (vars: Partial<Variables>) => void) => {
  const { queryParams, updateQueryParams } = useQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPending, startTransition] = useTransition();

  const onRefetch = ({
    userId,
    demandId,
    supplierId,
  }: { userId: string; demandId: string; supplierId: string }) => {
    startTransition(() => {
      refetch(getOrderWhere({ userId, demandId, supplierId }));
    });
  };

  const onClearDemandInput = () => {
    const params = buildQueryParams(searchParams, { demandId: '' });
    if (params.demand !== queryParams.demand) {
      onRefetch({ demandId: '', userId: params.assignee || '', supplierId: params.supplier || '' });
      setSearchParams(params);
      updateQueryParams({ demand: '' });
    }
  };

  const onClearSupplierInput = () => {
    const params = buildQueryParams(searchParams, { supplierId: '' });
    if (params.supplier !== queryParams.supplier) {
      onRefetch({
        supplierId: '',
        userId: params.assignee || '',
        demandId: params.demand || '',
      });
      setSearchParams(params);
      updateQueryParams({ supplier: '' });
    }
  };

  const onChangeUser = (value: string) => {
    const params = buildQueryParams(searchParams, { userId: value });
    onRefetch({
      demandId: params.demand || '',
      userId: params.assignee || '',
      supplierId: params.supplier || '',
    });
    setSearchParams(params);
    updateQueryParams({ assignee: params.assignee || '' });
  };

  const onChangeDemand = (value: { id: string; name: string } | null | undefined) => {
    const params = buildQueryParams(searchParams, { demandId: value?.id || '' });
    const currentDemandId = searchParams.get('demand');

    if (value?.id !== currentDemandId) {
      onRefetch({
        demandId: params.demand || '',
        userId: params.assignee || '',
        supplierId: params.supplier || '',
      });

      setSearchParams(params);
      updateQueryParams({
        demand: params.demand || '',
      });
    }
  };

  const onChangeSupplier = (value: { id: string; name: string } | null | undefined) => {
    const params = buildQueryParams(searchParams, { supplierId: value?.id });
    const currentSupplierId = searchParams.get('supplier');

    if (value?.id !== currentSupplierId) {
      onRefetch({
        demandId: params.demand || '',
        userId: params.assignee || '',
        supplierId: params.supplier || '',
      });
      setSearchParams(params);
      updateQueryParams({ supplier: params.supplier || '' });
    }
  };

  return {
    onClearDemandInput,
    onClearSupplierInput,
    onChangeUser,
    onChangeDemand,
    onChangeSupplier,
    isPending,
  };
};
