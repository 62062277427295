import { InvoiceInputFormValueType } from 'features/invoice/form';
import { InvoiceInputFormFragment$data } from 'gql/__generated__/InvoiceInputFormFragment.graphql';
import { dateFormatHyphenDate } from 'utils/date';

export const buildDefaultDetailsValue = ({
  inputDetails,
  salesOrderDetails,
  selectedSalesOrderIds,
  tax,
}: {
  inputDetails: InvoiceInputFormValueType['details'];
  salesOrderDetails: InvoiceInputFormFragment$data['salesOrderDetails'];
  selectedSalesOrderIds: string[];
  tax: { id: string; rate: number };
}): InvoiceInputFormValueType['details'] => {
  const details = (salesOrderDetails?.edges || []).map((detail) => {
    // 請求書データがすでに存在していれば請求書の明細を返す（編集時）
    if (detail?.node?.invoiceDetails.edges && detail?.node?.invoiceDetails?.edges.length > 0) {
      const invoice = detail.node.invoiceDetails.edges.at(0)?.node;
      const item = invoice?.item;
      const salesDate = invoice?.invoiceDetailsSales?.edges?.at(0)?.node?.salesDate;

      if (invoice && item) {
        return {
          salesDate: salesDate ? dateFormatHyphenDate(salesDate) : '',
          invoiceDetailId: invoice.id,
          salesOrderDetailId: detail.node.id || '',
          name: replaceNewlinesToSlash(item.name),
          quantity: item.quantity,
          unitSellingPrice: item.unitSellingPrice || 0,
          tax: {
            id: item.tax.id,
            rate: item.tax.rate,
          },
        };
      }
      return {
        salesDate: '',
        salesOrderDetailId: '',
        name: '',
        tax,
      };
    }

    const salesDate = detail?.node?.deliveryEvents.edges?.at(0)?.node?.fixedDeliveryDate;
    const item = detail?.node?.item;
    if (item) {
      return {
        salesDate: salesDate ? dateFormatHyphenDate(salesDate) : '',
        salesOrderDetailId: detail?.node?.id || '',
        name: replaceNewlinesToSlash(item.name),
        quantity: item.quantity,
        unitSellingPrice: item.unitSellingPrice,
        tax: {
          id: item.tax.id,
          rate: item.tax.rate,
        },
      };
    }

    return {
      salesDate: '',
      salesOrderDetailId: '',
      name: '',
      tax,
    };
  });

  // 表示順を選択された順にするための整列
  const sortedDetails = selectedSalesOrderIds.map(
    (id) => details.find((d) => d.salesOrderDetailId === id) as (typeof details)[0],
  );

  return [...inputDetails, ...sortedDetails].filter((v): v is NonNullable<typeof v> => v != null);
};

const replaceNewlinesToSlash = (input: string) => {
  // 半角スペースと全角スペースを「/」に置き換える
  return input.replace(/\r\n|\r|\n/g, '／');
};
