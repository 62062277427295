import { useDeleteEstimationRequestMutation } from 'gql/__generated__/useDeleteEstimationRequestMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { toast } from 'lib/toast';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

export const useDeleteEstimationRequest = () => {
  const versionWatcher = useContext(VersionWatcherContext);

  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<useDeleteEstimationRequestMutation>(
      graphql`
      mutation useDeleteEstimationRequestMutation(
        $id: ID!
      ) {
        deleteEstimationRequest(id: $id)
      }
    `,
    );

  const onSubmit = (id: string) => {
    const enableSoftDelete = versionWatcher.disableSoftDeleteEffect();
    commitMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        enableSoftDelete();
        toast({
          title: '見積書を削除しました',
          status: 'success',
        });
        navigate(paths.estimationRequest.url());
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
