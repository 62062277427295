import { Box, Button, Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { CompanyDetailedInfo } from 'features/company/components/CompanyDetailedInfo';
import { detail_CompanyDetailPageQuery } from 'gql/__generated__/detail_CompanyDetailPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const CompanyDetailPageQuery = graphql`
  query detail_CompanyDetailPageQuery  ($id: ID!) {
    companies(where: {id: $id}) {
      edges {
        node {
          ...CompanyDetailedInfoFragment
        }
      }
    }
  }
`;

export function CompanyDetailPage() {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { companies } = useLazyLoadQuery<detail_CompanyDetailPageQuery>(
    CompanyDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  if (
    !companies ||
    !companies.edges ||
    companies.edges.length === 0 ||
    !companies.edges[0]?.node ||
    companies.edges[0]?.node === null
  )
    return null;

  return (
    <>
      <Box mb={6} width="768px">
        <Flex justify="space-between">
          <RouterLink to={paths.company.url()}>
            <PageBack />
          </RouterLink>
          <RouterLink to={paths.company.edit._id(id)}>
            <Button colorScheme="blue">編集</Button>
          </RouterLink>
        </Flex>
      </Box>
      <Box mb={6}>
        <SubHeading label="デマンドの詳細" />
      </Box>
      <Box mb={7}>
        <CompanyDetailedInfo queryRef={companies.edges[0]?.node} />
      </Box>
    </>
  );
}
