/**
 * @generated SignedSource<<a30e135f36b7aca74ec51facafd635cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderTableRowFragment$data = {
  readonly company: {
    readonly id: string;
    readonly name: string;
  };
  readonly createdAt: any;
  readonly createdBy: {
    readonly profile: ReadonlyArray<{
      readonly firstName: string;
      readonly lastName: string;
    }> | null;
  };
  readonly id: string;
  readonly internalAssignees: ReadonlyArray<{
    readonly user: {
      readonly id: string;
      readonly profile: ReadonlyArray<{
        readonly firstName: string;
        readonly lastName: string;
      }> | null;
    };
  }> | null;
  readonly salesOrders: ReadonlyArray<{
    readonly estimations: ReadonlyArray<{
      readonly estimationResponse: {
        readonly assignee: {
          readonly estimationRequestID: string;
          readonly id: string;
        };
        readonly id: string;
      } | null;
      readonly id: string;
    }> | null;
    readonly id: string;
  }> | null;
  readonly supplier: {
    readonly id: string;
    readonly name: string;
  };
  readonly title: string;
  readonly " $fragmentType": "OrderTableRowFragment";
};
export type OrderTableRowFragment$key = {
  readonly " $data"?: OrderTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderTableRowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderTableRowFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Supplier",
      "kind": "LinkedField",
      "name": "supplier",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrder",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Estimation",
          "kind": "LinkedField",
          "name": "estimations",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationResponse",
              "kind": "LinkedField",
              "name": "estimationResponse",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequestAssignee",
                  "kind": "LinkedField",
                  "name": "assignee",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimationRequestID",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderInternalAssignee",
      "kind": "LinkedField",
      "name": "internalAssignees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "6cf6f76b1e44a7cb601f755f04f366de";

export default node;
