import {
  Button,
  ButtonProps,
  FormControl,
  FormErrorMessage,
  InputGroup,
  useMergeRefs,
} from '@chakra-ui/react';
import { ChangeEvent, useRef } from 'react';
import { FieldError, Merge, UseFormRegister } from 'react-hook-form';

type ErrorType = Merge<FieldError, (FieldError | undefined)[]> | undefined;

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // biome-ignore lint/suspicious/noExplicitAny: 薄いコンポーネントのためregisterの型はanyで許容する
  register: UseFormRegister<any>;
  name: string;
  label: string;
  errors: ErrorType;
  multiple?: boolean;
  accept?: string;
  leftIcon?: React.ReactElement;
  buttonProps?: ButtonProps;
};

export const FileUploadButton = ({
  onChange,
  register,
  name,
  label,
  errors,
  multiple = false,
  accept = '*',
  leftIcon,
  buttonProps,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { ref, ...rest } = register(name);
  const mergedRef = useMergeRefs(ref, inputRef);

  return (
    <FormControl isInvalid={!!errors}>
      <InputGroup>
        <input
          type="file"
          multiple={multiple}
          accept={accept}
          style={{ display: 'none' }}
          {...rest}
          ref={mergedRef}
          onChange={onChange}
        />
        <Button
          leftIcon={leftIcon}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.value = '';
            }
            inputRef?.current?.click();
          }}
          {...buttonProps}
        >
          {label}
        </Button>
      </InputGroup>
      <FormErrorMessage mt="1rem">{errors && errors.message}</FormErrorMessage>
    </FormControl>
  );
};
