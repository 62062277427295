import { useCreateSupplierMutation } from 'gql/__generated__/useCreateSupplierMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { SupplierFormDataType } from '../zod';

export const useCreateSupplier = () => {
  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useCreateSupplierMutation>(
    graphql`
      mutation useCreateSupplierMutation(
        $input: SupplierInput!
      ) {
        createSupplier(input: $input) {
          id
        }
      }
    `,
  );

  const onSubmit = (data: SupplierFormDataType) => {
    commitMutation({
      variables: {
        input: {
          name: data.name,
          contacts: data.contacts.map((contact) => ({
            email: contact.email,
            lastName: contact.lastName,
            firstName: contact.firstName || '',
            memo: contact.memo || '',
          })),
        },
      },
      onCompleted: () => {
        toast({
          title: 'サプライヤーを作成しました',
          status: 'success',
        });
        navigate(paths.supplier.url());
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
