/**
 * @generated SignedSource<<504710a171c256674ce6d7eb0e08f770>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrderAssigneeRecipientType = "cc" | "to" | "%future added value";
export type OrderUpdateInput = {
  deliveryInfo?: OrderDeliveryInfoInput | null;
  detailMessage?: string | null;
  internalAssignees?: ReadonlyArray<CreateOrderInternalAssigneeInput> | null;
  items: ReadonlyArray<OrderDetailUpdateInput>;
  mailBodyMessage?: string | null;
  supplierContacts?: ReadonlyArray<OrderSupplieerContactInput> | null;
  title: string;
};
export type OrderDeliveryInfoInput = {
  desiredDeliveryDate?: string | null;
  recipientsText?: string | null;
};
export type CreateOrderInternalAssigneeInput = {
  userID: string;
};
export type OrderDetailUpdateInput = {
  name: string;
  orderNumber: any;
  quantity: number;
  salesOrderDetailID?: string | null;
  taxCategoryID: string;
  unitPrice: number;
};
export type OrderSupplieerContactInput = {
  ID: string;
  recipientType: OrderAssigneeRecipientType;
};
export type useOrderEditFormMutation$variables = {
  id: string;
  input: OrderUpdateInput;
};
export type useOrderEditFormMutation$data = {
  readonly updateOrder: {
    readonly id: string;
  };
};
export type useOrderEditFormMutation = {
  response: useOrderEditFormMutation$data;
  variables: useOrderEditFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Order",
    "kind": "LinkedField",
    "name": "updateOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrderEditFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useOrderEditFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cd5e482b392c6e833d6a923256fc563e",
    "id": null,
    "metadata": {},
    "name": "useOrderEditFormMutation",
    "operationKind": "mutation",
    "text": "mutation useOrderEditFormMutation(\n  $id: ID!\n  $input: OrderUpdateInput!\n) {\n  updateOrder(id: $id, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "03415550201fcf9fb388e164b6babf86";

export default node;
