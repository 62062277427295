import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';

type Props = {
  onClickLinkage: () => void;
  onClose: () => void;
  closeButtonLabel: string;
  isOpen: boolean;
};

export const LinkageFreeeModal = ({ onClickLinkage, onClose, closeButtonLabel, isOpen }: Props) => {
  const { onClose: onModalClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} isCentered closeOnOverlayClick={!isSubmitting}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>freee連携が行われていません</ModalHeader>
        <ModalBody>
          請求書の作成・編集・削除にはfreeeとの連携が必要です。連携ボタンからfreeeにログインを行いましょう。
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsSubmitting(true);
              onClose();
              onModalClose();
            }}
            isDisabled={isSubmitting}
          >
            {closeButtonLabel}
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            onClick={() => {
              setIsSubmitting(true);
              onClickLinkage();
            }}
            isDisabled={isSubmitting}
          >
            連携
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
