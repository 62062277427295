/**
 * @generated SignedSource<<c842512b8d5e7aaafc6b9af6f75e0109>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandRecurringCostBlocksRefetchQuery$variables = {
  demandId?: string | null;
};
export type DemandRecurringCostBlocksRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DemandRecurringCostBlocksRefetchFragment">;
};
export type DemandRecurringCostBlocksRefetchQuery = {
  response: DemandRecurringCostBlocksRefetchQuery$data;
  variables: DemandRecurringCostBlocksRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "demandId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DemandRecurringCostBlocksRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "demandId",
            "variableName": "demandId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "DemandRecurringCostBlocksRefetchFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DemandRecurringCostBlocksRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": {
              "direction": "DESC",
              "field": "UPDATED_AT"
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "demandID",
                "variableName": "demandId"
              },
              {
                "kind": "Literal",
                "name": "isCurrent",
                "value": true
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "DemandRecurringCostConnection",
        "kind": "LinkedField",
        "name": "demandRecurringCosts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandRecurringCostEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandRecurringCost",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "costNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Supplier",
                    "kind": "LinkedField",
                    "name": "supplier",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandRecurringCostInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandRecurringCostPrice",
                    "kind": "LinkedField",
                    "name": "prices",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitSellingPrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "direction": "DESC",
                          "field": "CREATED_AT"
                        }
                      }
                    ],
                    "concreteType": "OrderRecurringCostConnection",
                    "kind": "LinkedField",
                    "name": "orderRecurringCosts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderRecurringCostEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrderRecurringCost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderDetail",
                                "kind": "LinkedField",
                                "name": "orderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Order",
                                    "kind": "LinkedField",
                                    "name": "order",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "orderRecurringCosts(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "768052dc01c368e65c432e82112b2e5f",
    "id": null,
    "metadata": {},
    "name": "DemandRecurringCostBlocksRefetchQuery",
    "operationKind": "query",
    "text": "query DemandRecurringCostBlocksRefetchQuery(\n  $demandId: ID\n) {\n  ...DemandRecurringCostBlocksRefetchFragment_RMyVA\n}\n\nfragment DemandRecurringCostBlocksRefetchFragment_RMyVA on Query {\n  demandRecurringCosts(where: {isCurrent: true, demandID: $demandId}, orderBy: {field: UPDATED_AT, direction: DESC}) {\n    edges {\n      node {\n        id\n        name\n        note\n        costNumber\n        supplier {\n          name\n          id\n        }\n        internalMemos {\n          body\n          id\n        }\n        prices {\n          id\n          quantity\n          unitPrice\n          unitSellingPrice\n        }\n        demand {\n          code\n          id\n        }\n        orderRecurringCosts(orderBy: {direction: DESC, field: CREATED_AT}, first: 1) {\n          edges {\n            node {\n              orderDetail {\n                order {\n                  id\n                  title\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7f3c7b88ed1a8a31f6e3c8e7338a1a3";

export default node;
