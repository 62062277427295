/**
 * @generated SignedSource<<4e8e01d0f520465bf08c2e704b1421e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InvoiceConfirmQuery$variables = {
  salesOrderIds?: ReadonlyArray<string> | null;
};
export type InvoiceConfirmQuery$data = {
  readonly salesOrderDetails: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly branchNumber: any;
        readonly id: string;
        readonly salesOrder: {
          readonly transactionID: any;
        };
      } | null;
    } | null> | null;
  };
};
export type InvoiceConfirmQuery = {
  response: InvoiceConfirmQuery$data;
  variables: InvoiceConfirmQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "salesOrderIds"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "idIn",
        "variableName": "salesOrderIds"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "branchNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceConfirmQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SalesOrderDetailConnection",
        "kind": "LinkedField",
        "name": "salesOrderDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrderDetailEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrderDetail",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrder",
                    "kind": "LinkedField",
                    "name": "salesOrder",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoiceConfirmQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SalesOrderDetailConnection",
        "kind": "LinkedField",
        "name": "salesOrderDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrderDetailEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrderDetail",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrder",
                    "kind": "LinkedField",
                    "name": "salesOrder",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb87538120f94bc352ede2b42cc53709",
    "id": null,
    "metadata": {},
    "name": "InvoiceConfirmQuery",
    "operationKind": "query",
    "text": "query InvoiceConfirmQuery(\n  $salesOrderIds: [ID!]\n) {\n  salesOrderDetails(where: {idIn: $salesOrderIds}) {\n    edges {\n      node {\n        id\n        branchNumber\n        salesOrder {\n          transactionID\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbf19552f1f28e9aa7930fe1ad248bc9";

export default node;
