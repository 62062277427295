import { PartialMessage, Struct } from '@bufbuild/protobuf';
import { Box, VStack } from '@chakra-ui/react';
import { ItemTable } from 'features/item/components/ItemTable';
import { EstimationRequestPreviewItemsFragment$key } from 'gql/__generated__/EstimationRequestPreviewItemsFragment.graphql';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { RawItem } from 'proto/model/item/v1/summarize_item_pb';
import { ItemService } from 'proto/service/item/v1/item_connect';
import { SummarizeItemsResponse } from 'proto/service/item/v1/item_pb';
import { useEffect, useState } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { extractItemFromDetail } from 'utils/detail';

const EstimationRequestPreviewItemsFragment = graphql`
  fragment EstimationRequestPreviewItemsFragment on EstimationRequest
  {
    details {
      type
      item {
        name
        specJSON
        quantity
      }
    }
  }
`;

export const EstimationRequestPreviewItems = ({
  heading,
  queryRef,
}: { queryRef: EstimationRequestPreviewItemsFragment$key; heading?: React.ReactNode }) => {
  const { details } = useFragment(EstimationRequestPreviewItemsFragment, queryRef);
  const { grpcClient, authorized } = useGrpcClient(ItemService);
  const [summarizedItems, setSummarizedItems] = useState<PartialMessage<SummarizeItemsResponse>>({
    items: [],
  });

  useEffect(() => {
    (async () => {
      if (!authorized) return;

      let unmounted = false;
      if (!details) return;

      const rawItems = details.map((d): PartialMessage<RawItem> => {
        const item = extractItemFromDetail(d);

        const specJson = item.specJSON as { [key: string]: string | number | boolean | null };
        return {
          name: item.name,
          quantity: item.quantity,
          specJson: Struct.fromJson(specJson),
        };
      });

      const summary = await grpcClient.summarizeItems({ items: rawItems });
      summary.items = summary.items.map((item) => {
        item.columns = item.columns.filter((col) => col.key !== 'unitPrice');
        return item;
      });

      if (unmounted) return;
      setSummarizedItems(summary);

      return () => {
        unmounted = true;
      };
    })();
  }, [details, authorized, grpcClient]);

  return (
    <>
      {heading}
      <VStack align="start" width="100%" spacing={4}>
        {(summarizedItems.items || []).map((item, index) => (
          <Box key={item.category}>
            <ItemTable
              key={`item-table-${index}`}
              readonly
              items={summarizedItems.items || []}
              index={index}
            />
          </Box>
        ))}
      </VStack>
    </>
  );
};
