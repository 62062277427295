import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { Loading } from '@icon-park/react';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { SupplierTableRow } from 'features/supplier/components/SupplierTableRow';
import { supplierPageFragment$key } from 'gql/__generated__/supplierPageFragment.graphql';
import { supplierPageQuery } from 'gql/__generated__/supplierPageQuery.graphql';
import { KeyboardEvent, useState, useTransition } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const SupplierPageQuery = graphql`
  query supplierPageQuery {
    ...supplierPageFragment
  }
`;

const SupplierPageFragment = graphql`
  fragment supplierPageFragment on Query
    @refetchable(queryName: "supplierPageFragmentRefetchQuery")
    @argumentDefinitions (
      count: { type: "Int", defaultValue: 100 }
      after: { type: "Cursor" }
      direction: { type: "OrderDirection", defaultValue: DESC }
      field: { type: "SupplierOrderField", defaultValue: CREATED_AT }
      where: {type: "SupplierWhereInput", defaultValue: null}
    )
  {
    suppliers(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "supplierPageFragment_suppliers") {
      edges {
        cursor
        node {
          id
          ...SupplierTableRowFragment
        }
      }
      totalCount
    }
  }
`;

export const SupplierPage = () => {
  const [isPending, startTransition] = useTransition();
  const [searchValue, setSearchValue] = useState('');
  const query = useLazyLoadQuery<supplierPageQuery>(
    SupplierPageQuery,
    {},
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    SupplierPageFragment,
    query as supplierPageFragment$key,
  );

  const handleClickMore = () => loadNext(100);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      startTransition(() => {
        refetch({ where: { nameContains: searchValue } });
      });
    }
  };

  return (
    <>
      <VStack spacing={6} align="flex-start">
        <Link to={paths.supplier.new.url()}>
          <Button colorScheme="blue" width="208px">
            サプライヤー追加
          </Button>
        </Link>
        <FormControl width="360px" mt={6}>
          <FormLabel mb={1}>企業名</FormLabel>
          <Input
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="検索"
            onKeyPress={handleKeyPress}
          />
        </FormControl>
      </VStack>
      {isPending ? (
        <Loading />
      ) : (
        <Box mt="1.5rem">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th w="47%">企業名</Th>
                <Th w="48%">取引先担当者</Th>
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {data.suppliers.edges?.map(
                (edge) =>
                  edge?.node && <SupplierTableRow key={edge.node.id} queryRef={edge?.node} />,
              )}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.suppliers.totalCount}
              currentLength={data.suppliers.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
