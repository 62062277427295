import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ErrorMessage } from 'components/ErrorMessage';
import { DemandItemFormType } from 'features/demandItem/form';
import { DEMAND_ITEM_CATEGORY_LABEL } from 'features/demandItem/models';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { DemandItemFormFragment$key } from 'gql/__generated__/DemandItemFormFragment.graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { DemandItemPriceForm } from './DemandItemPriceForm';
import { DemandItemSpecForm } from './DemandItemSpecForm';

type Props = {
  queryRef: DemandItemFormFragment$key;
  navigateToConfirm: () => void;
  isDisabledSubmit?: boolean;
  isDisabledCategory?: boolean;
};

const demandItemFormFragment = graphql`
  fragment DemandItemFormFragment on Query {
    taxCategories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const DemandItemForm = ({
  queryRef,
  navigateToConfirm,
  isDisabledSubmit = false,
  isDisabledCategory = false,
}: Props) => {
  const { taxCategories } = useFragment(demandItemFormFragment, queryRef);

  const form = useFormContext<DemandItemFormType>();

  const {
    control,
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = form;

  const onClearSupplierInput = () => setValue('supplier', { id: '', name: '' });

  if (
    !taxCategories ||
    !taxCategories.edges ||
    taxCategories.edges.length === 0 ||
    !taxCategories.edges[0]?.node ||
    taxCategories.edges[0]?.node === null
  )
    return null;

  const taxs = taxCategories.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  return (
    <form onSubmit={handleSubmit(navigateToConfirm)}>
      <VStack spacing={10} alignItems="flex-start" width="100%">
        <VStack spacing={4} alignItems="flex-start" width="512px">
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>商品名</FormLabel>
            <Input type="string" {...register('name')} />
            <ErrorMessage name="name" errors={errors} />
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>サプライヤー</FormLabel>
            <Controller
              name="supplier"
              control={control}
              render={({ field: { onChange } }) => (
                <SupplierComboBox
                  onChangeSelected={onChange}
                  defaultSelectedItem={getValues('supplier')}
                  onClearInput={onClearSupplierInput}
                />
              )}
            />
            <ErrorMessage name="supplier.id" errors={errors} />
          </FormControl>
          <FormControl>
            <FormLabel>社内メモ</FormLabel>
            <AutoResizeTextarea {...register('memo')} minHeight="105px" />
            <ErrorMessage name="memo" errors={errors} />
          </FormControl>
        </VStack>

        <VStack spacing={4} alignItems="flex-start" width="768px">
          <Heading as="h3" fontSize="xl">
            仕様
          </Heading>
          <Box w="168px">
            <FormControl isRequired isInvalid={!!errors.category}>
              <FormLabel>カテゴリー</FormLabel>
              <Select {...register('category')} disabled={isDisabledCategory}>
                {Object.entries(DEMAND_ITEM_CATEGORY_LABEL).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value.categoryName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <VStack spacing={2} alignItems="flex-start" width="768px">
            <DemandItemSpecForm />
          </VStack>
        </VStack>

        <VStack spacing={4} alignItems="flex-start" width="512px">
          <Heading as="h3" fontSize="xl">
            単価表
          </Heading>
          <Box w="168px">
            <FormControl isRequired isInvalid={!!errors.taxId}>
              <FormLabel>税区分</FormLabel>
              <Select {...register('taxId')}>
                {taxs.map((tax) => (
                  <option key={tax.id} value={tax.id}>
                    {tax.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <DemandItemPriceForm />
        </VStack>

        <Button
          colorScheme="blue"
          w="full"
          type="submit"
          isLoading={isSubmitting}
          isDisabled={isDisabledSubmit}
        >
          確認画面へ
        </Button>
      </VStack>
    </form>
  );
};
