/**
 * @generated SignedSource<<ec189996a8e2c3ce985fef692d3da049>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type edit_SupplierEditPageQuery$variables = {
  id: string;
};
export type edit_SupplierEditPageQuery$data = {
  readonly suppliers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly contacts: ReadonlyArray<{
          readonly email: string;
          readonly firstName: string;
          readonly id: string;
          readonly lastName: string;
          readonly memos: ReadonlyArray<{
            readonly body: string;
          }> | null;
        }> | null;
        readonly name: string;
      } | null;
    } | null> | null;
  };
};
export type edit_SupplierEditPageQuery = {
  response: edit_SupplierEditPageQuery$data;
  variables: edit_SupplierEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "edit_SupplierEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupplierConnection",
        "kind": "LinkedField",
        "name": "suppliers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SupplierEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Supplier",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplierContact",
                    "kind": "LinkedField",
                    "name": "contacts",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SupplierContactMemo",
                        "kind": "LinkedField",
                        "name": "memos",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "edit_SupplierEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupplierConnection",
        "kind": "LinkedField",
        "name": "suppliers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SupplierEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Supplier",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplierContact",
                    "kind": "LinkedField",
                    "name": "contacts",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SupplierContactMemo",
                        "kind": "LinkedField",
                        "name": "memos",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eee6ff9e1eec74c6909dba909006666d",
    "id": null,
    "metadata": {},
    "name": "edit_SupplierEditPageQuery",
    "operationKind": "query",
    "text": "query edit_SupplierEditPageQuery(\n  $id: ID!\n) {\n  suppliers(where: {id: $id}) {\n    edges {\n      node {\n        name\n        contacts {\n          id\n          firstName\n          lastName\n          email\n          memos {\n            body\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c465a986b982c22c47f8f3e24eb28c94";

export default node;
