/**
 * @generated SignedSource<<9a93bbf390dcaa58f14eceba2830c992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceInputFormFragment$data = {
  readonly salesOrderDetails?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly branchNumber: any;
        readonly deliveryEvents: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fixedDeliveryDate: any;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly invoiceDetails: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly invoiceDetailsSales: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly salesDate: any;
                  } | null;
                } | null> | null;
              };
              readonly item: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly tax: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitSellingPrice: number;
              } | null;
            } | null;
          } | null> | null;
        };
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly quantity: any;
          readonly tax: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitSellingPrice: number;
        } | null;
        readonly salesOrder: {
          readonly title: string;
          readonly transactionID: any;
        };
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "InvoiceInputFormFragment";
};
export type InvoiceInputFormFragment$key = {
  readonly " $data"?: InvoiceInputFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceInputFormFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "direction": "ASC",
    "field": "ORDER_NUMBER"
  }
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "isValid": true
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unitSellingPrice",
    "storageKey": null
  },
  {
    "alias": "tax",
    "args": null,
    "concreteType": "TaxCategory",
    "kind": "LinkedField",
    "name": "taxCategory",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isSkip"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceInputFormFragment",
  "selections": [
    {
      "condition": "isSkip",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "where"
            }
          ],
          "concreteType": "SalesOrderDetailConnection",
          "kind": "LinkedField",
          "name": "salesOrderDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderDetailEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderDetail",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "branchNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v0/*: any*/)
                      ],
                      "concreteType": "InvoiceDetailConnection",
                      "kind": "LinkedField",
                      "name": "invoiceDetails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "InvoiceDetailEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "InvoiceDetail",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": (v2/*: any*/),
                                  "concreteType": "InvoiceDetailSaleConnection",
                                  "kind": "LinkedField",
                                  "name": "invoiceDetailsSales",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "InvoiceDetailSaleEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "InvoiceDetailSale",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "salesDate",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": "invoiceDetailsSales(where:{\"isValid\":true})"
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "InvoiceItem",
                                  "kind": "LinkedField",
                                  "name": "item",
                                  "plural": false,
                                  "selections": (v5/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "invoiceDetails(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                    },
                    {
                      "alias": null,
                      "args": (v2/*: any*/),
                      "concreteType": "SalesOrderDetailDeliveryEventConnection",
                      "kind": "LinkedField",
                      "name": "deliveryEvents",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderDetailDeliveryEventEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SalesOrderDetailDeliveryEvent",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "fixedDeliveryDate",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderItem",
                      "kind": "LinkedField",
                      "name": "item",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrder",
                      "kind": "LinkedField",
                      "name": "salesOrder",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "transactionID",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryConnection",
      "kind": "LinkedField",
      "name": "taxCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9c6b2bba27f0f0ee04da924a36588a42";

export default node;
