/**
 * @generated SignedSource<<b8080331ef65438ab1293361d0a46818>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useDownloadEstimationRequestAttachmentQuery$variables = {
  id: string;
};
export type useDownloadEstimationRequestAttachmentQuery$data = {
  readonly estimationRequestAttachmentURL: {
    readonly url: string;
  };
};
export type useDownloadEstimationRequestAttachmentQuery = {
  response: useDownloadEstimationRequestAttachmentQuery$data;
  variables: useDownloadEstimationRequestAttachmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "EstimationRequestAttachmentURL",
    "kind": "LinkedField",
    "name": "estimationRequestAttachmentURL",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadEstimationRequestAttachmentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadEstimationRequestAttachmentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "af5873ab70c8ff219c5218181ab6af6d",
    "id": null,
    "metadata": {},
    "name": "useDownloadEstimationRequestAttachmentQuery",
    "operationKind": "query",
    "text": "query useDownloadEstimationRequestAttachmentQuery(\n  $id: ID!\n) {\n  estimationRequestAttachmentURL(id: $id) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "65c337a7b16248dc3b0a1cbe74db3f21";

export default node;
