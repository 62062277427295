import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AppRoutes } from 'AppRoutes';
import { Environments } from 'constants/environment';
import { VersionWatcherContext, useVersionWatcher } from 'hooks/useVersionWatcher';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { Suspense } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import theme from 'theme/theme';
import { getEnvironment } from 'utils/environment';

if (getEnvironment() === Environments.Production || getEnvironment() === Environments.Preview) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    environment: import.meta.env.VITE_ENV,
    tracesSampleRate: 1.0,
  });
}

function App() {
  const versionMatcherCtx = useVersionWatcher();

  return (
    <VersionWatcherContext.Provider value={versionMatcherCtx}>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <ChakraProvider theme={theme}>
          <Suspense fallback={<div>loading...</div>}>
            <AppRoutes />
          </Suspense>
        </ChakraProvider>
      </RelayEnvironmentProvider>
    </VersionWatcherContext.Provider>
  );
}

export default App;
