import { z } from 'zod';

export const zipCodeFormat = new RegExp('^[0-9]{7}$');

export const errorMessages = {
  REQUIRED: '入力しましょう',
  INVALID_ZIP_CODE_FORMAT: '7桁の半角数字にしましょう',
  MAX_LENGTH: (limit: number) => `${limit}文字以内にしましょう`,
  REQUIRED_POSITIVE_NUMBER: '1以上の整数にしましょう',
  REQUIRED_DECIMAL_PLACE: '0以上、小数点第2位までの数字にしましょう',
  REQUIRED_UNIT_PRICE: '小数点第2位までの数字にしましょう',
  QUANTITY_NUMBER_FORMAT: '0以上、小数点を含まない数字にしましょう',
  QUANTITY_MAX_DIGIT: '8桁以下にしましょう',
  GROSS_MARGIN_RATIO: '1以上、小数点第1位までの数字にしましょう',
  UNIT_PRICE_DIGIT: '-999,999,999.99~999,999,999.99の範囲で入力しましょう',
};

export type QuantityErrorMessages = {
  REQUIRED_POSITIVE_NUMBER: string;
  QUANTITY_NUMBER_FORMAT: string;
  QUANTITY_MAX_DIGIT: string;
};

export const quantitySchema = (messages: QuantityErrorMessages = errorMessages) => {
  return z.preprocess(
    (v) => Number(v),
    z
      .union([
        z
          .number({ required_error: messages.REQUIRED_POSITIVE_NUMBER })
          .multipleOf(1, { message: messages.QUANTITY_NUMBER_FORMAT })
          .positive({
            message: messages.REQUIRED_POSITIVE_NUMBER,
          })
          .max(99_999_999, {
            message: messages.QUANTITY_MAX_DIGIT,
          }),
        z.string(), // 明細行を追加する際、追加前に明細行を削除していると値が引き継がれる可能性があるためstring型も許容し、追加時に空文字を指定する
      ])
      .optional(), // 実際には必須だが、空の状態を許容するためにスキーマ上はoptionalにする
  );
};

export type UnitPriceErrorMessages = {
  REQUIRED: string;
  REQUIRED_UNIT_PRICE: string;
  UNIT_PRICE_DIGIT: string;
};

export const unitPriceSchema = (messages: UnitPriceErrorMessages = errorMessages) => {
  return z.preprocess(
    (v) => (v === '' ? '' : Number(v)),
    z
      .union([
        z
          .number({
            required_error: messages.REQUIRED_UNIT_PRICE,
          })
          .max(999_999_999.99, {
            message: messages.UNIT_PRICE_DIGIT,
          })
          .min(-999_999_999.99, {
            message: messages.UNIT_PRICE_DIGIT,
          }),
        z.string().refine((str) => str.trim().length > 0, {
          message: errorMessages.REQUIRED,
        }),
      ])
      .optional(), // 実際には必須だが、空の状態を許容するためにスキーマ上はoptionalにする
  );
};

export const unitSellingPriceSchema = z.preprocess(
  (v) => (v === '' ? '' : Number(v)),
  z
    .union([
      z
        .number({
          required_error: errorMessages.REQUIRED_UNIT_PRICE,
        })
        .max(999_999_999.99, {
          message: errorMessages.UNIT_PRICE_DIGIT,
        })
        .min(-999_999_999.99, {
          message: errorMessages.UNIT_PRICE_DIGIT,
        }),
      z.string().refine((str) => str.trim().length > 0, {
        message: errorMessages.REQUIRED,
      }),
    ])
    .optional(), // 実際には必須だが、空の状態を許容するためにスキーマ上はoptionalにする);
);
