import { Loading } from 'components/Loading';
import { EstimationConfirm } from 'features/estimation/components/EstimationConfirm';
import { EstimationForm } from 'features/estimation/components/EstimationForm';
import { useEstimationDuplication } from 'features/estimation/hooks/useEstimationDuplication';
import { buildDuplicateEstimationFormState } from 'features/estimation/util';
import { duplication_EstimationDuplicationPageFragment$key } from 'gql/__generated__/duplication_EstimationDuplicationPageFragment.graphql';
import { duplication_EstimationDuplicationPageQuery } from 'gql/__generated__/duplication_EstimationDuplicationPageQuery.graphql';
import { Suspense } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const estimationDuplicationPagQuery = graphql`
  query duplication_EstimationDuplicationPageQuery  ($id: ID!) {
    ...duplication_EstimationDuplicationPageFragment
    ...EstimationFormFragment
  }
`;

const estimationDuplicationPagFragment = graphql`
  fragment duplication_EstimationDuplicationPageFragment on Query {
    estimations(where: {id: $id}) {
      edges {
        node {
          id
          company {
            id
            name
          }
          supplier {
            id
            name
          }
          title
          detailMessage
          expirationPeriod
          showAmountSummary
          createdAt
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
            edges {
              node {
                type
                orderNumber
                documentDisplayStatus
                item {
                  id
                  name
                  unitPrice
                  unitSellingPrice
                  quantity
                  taxCategory {
                    id
                    rate
                  }
                }
              }
            }
          }
          estimationResponse {
            assignee {
              estimationRequest {
                id
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }

    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }

    currentUser {
      userID
      firstName
      lastName
    }
  }
`;

export const EstimationDuplicationPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const query = useLazyLoadQuery<duplication_EstimationDuplicationPageQuery>(
    estimationDuplicationPagQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );
  const { estimations, currentUser, taxCategories } = useFragment(
    estimationDuplicationPagFragment,
    query as duplication_EstimationDuplicationPageFragment$key,
  );

  const state = estimations?.edges?.at(0)?.node;
  const tax = taxCategories?.edges?.at(0)?.node;

  const { data, showPreview, onClickToConfirm, onSubmit, onClickToNewForm, isMutationInFlight } =
    useEstimationDuplication({
      state: buildDuplicateEstimationFormState({
        value: state,
        currentUser,
        taxCategories: {
          id: tax?.id || '',
          rate: tax?.rate || 0,
        },
      }),
    });

  if (!state) return null;

  return (
    <Suspense fallback={<Loading />}>
      {showPreview ? (
        <EstimationConfirm
          onClickSubmit={onSubmit}
          data={data}
          isMutationInFlight={isMutationInFlight}
          onClickToNewForm={onClickToNewForm}
        />
      ) : (
        <EstimationForm
          onClickToConfirm={onClickToConfirm}
          data={data}
          queryRef={query}
          previousUrl={paths.estimation._id(id).url}
        />
      )}
    </Suspense>
  );
};
