import * as path from 'path';

export const BlockAttachmentExtensions = [
  '.ade',
  '.adp',
  '.apk',
  '.appx',
  '.appxbundle',
  '.bat',
  '.cab',
  '.chm',
  '.cmd',
  '.com',
  '.cpl',
  '.dll',
  '.dmg',
  '.ex',
  '.ex_',
  '.exe',
  '.hta',
  '.ins',
  '.isp',
  '.iso',
  '.jar',
  '.js',
  '.jse',
  '.lib',
  '.lnk',
  '.mde',
  '.msc',
  '.msi',
  '.msix',
  '.msixbundle',
  '.msp',
  '.mst',
  '.nsh',
  '.pif',
  '.ps1',
  '.scr',
  '.sct',
  '.shb',
  '.sys',
  '.vb',
  '.vbe',
  '.vbs',
  '.vxd',
  '.wsc',
  '.wsf',
  '.wsh',
];

export const encodeFileListNames = (fileList: FileList | null): FileList => {
  const dataTransfer = new DataTransfer();
  if (!fileList) {
    return dataTransfer.files;
  }

  for (let i = 0; i < fileList.length; i++) {
    dataTransfer.items.add(
      new File([fileList[i]], encodeURIComponent(fileList[i].name), {
        type: fileList[i].type,
        lastModified: fileList[i].lastModified,
      }),
    );
  }

  return dataTransfer.files;
};

export const mergeFileList = (previous: FileList | null, current: FileList | null): FileList => {
  const fileNames = new Map();
  const dataTransfer = new DataTransfer();
  if (previous) {
    for (let i = 0; i < previous.length; i++) {
      if (!fileNames.has(previous[i].name)) {
        fileNames.set(previous[i].name, previous[i]);
        dataTransfer.items.add(previous[i]);
      }
    }
  }

  if (current) {
    for (let i = 0; i < current.length; i++) {
      if (!fileNames.has(current[i].name)) {
        fileNames.set(current[i].name, current[i]);
        dataTransfer.items.add(current[i]);
      }
    }
  }

  return dataTransfer.files;
};

export const getTotalFileBytes = (fileList: FileList | null): number => {
  if (!fileList) {
    return 0;
  }

  return [...Array(fileList.length)].map((_, i) => fileList[i].size).reduce((a, b) => a + b, 0);
};

export const hasContainBlockExtensions = (
  fileList: FileList | null,
  rejectedFileExtensions: string[],
): boolean => {
  if (!fileList) {
    return false;
  }

  for (let i = 0; i < fileList.length; i++) {
    const ext = path.extname(fileList[i].name);
    if (rejectedFileExtensions.includes(ext)) {
      return true;
    }
  }
  return false;
};
