import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from '@chakra-ui/react';
import { More, Order, SendEmail, TriangleRuler } from '@icon-park/react';
import { Link } from 'components/Link';
import { SalesOrderTableRowFragment$key } from 'gql/__generated__/SalesOrderTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const SalesOrderTableRowFragment = graphql`
  fragment SalesOrderTableRowFragment on SalesOrder {
    id
    transactionID
    title

    demand {
      id
      name
    }
    supplier {
      id
      name
    }

    receivedDate

    estimations {
        id
        estimationResponse {
            id
            assignee {
                id
                estimationRequest {
                    id
                }
            }
        }
    }
    
    orders {
      id
    }

    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

export const SalesOrderTableRow = ({ queryRef }: { queryRef: SalesOrderTableRowFragment$key }) => {
  const {
    id,
    demand,
    estimations,
    supplier,
    transactionID,
    title,
    orders,
    receivedDate,
    internalAssignees,
  } = useFragment(SalesOrderTableRowFragment, queryRef);
  const order = orders?.[0];
  const estimation = estimations?.[0];
  const estimationRequestId = estimation?.estimationResponse?.assignee.estimationRequest.id;

  return (
    <Tr>
      <Td>{transactionID}</Td>
      <Td>{title || '-'}</Td>
      <Td>
        <Link to={paths.company._id(demand.id).url}>{demand.name}</Link>
      </Td>
      <Td>
        <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
      </Td>
      <Td whiteSpace="nowrap">{dateFormat(receivedDate)}</Td>
      <Td>
        {internalAssignees?.map((assignee) =>
          assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
            <Box key={assignee.user.id}>
              {concatFullName({
                lastName: assignee.user.profile[0].lastName,
                firstName: assignee.user.profile[0].firstName,
              })}
            </Box>
          ) : (
            <Box key={assignee.user.id}>-</Box>
          ),
        ) || '-'}
      </Td>
      <Td align="center" textAlign="center">
        {estimationRequestId ? (
          <Button
            as={RouterLink}
            w={10}
            h={10}
            borderRadius={50}
            to={paths.estimationRequest._id(estimationRequestId)}
            mx="auto"
          >
            <SendEmail />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td align="center" textAlign="center">
        {estimation ? (
          <Button
            as={RouterLink}
            w={10}
            h={10}
            borderRadius={50}
            to={paths.estimation._id(estimation.id).url}
            mx="auto"
          >
            <TriangleRuler />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td align="center" textAlign="center">
        {order ? (
          <Button
            as={RouterLink}
            w={10}
            h={10}
            borderRadius={50}
            to={paths.order._id(order.id)}
            mx="auto"
          >
            <Order />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td>
        <Menu>
          <MenuButton>
            <More />
          </MenuButton>
          <MenuList>
            <MenuItem as={RouterLink} to={paths.salesOrder.id(id).edit.url()}>
              編集
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="sm" to={paths.salesOrder.id(id).url()}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
