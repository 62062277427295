/**
 * @generated SignedSource<<3b313995230c8c1e82b0bebc4abbe6b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useDownloadOrderPdfQuery$variables = {
  id: string;
};
export type useDownloadOrderPdfQuery$data = {
  readonly downloadOrderPdf: string;
};
export type useDownloadOrderPdfQuery = {
  response: useDownloadOrderPdfQuery$data;
  variables: useDownloadOrderPdfQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "downloadOrderPdf",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadOrderPdfQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadOrderPdfQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2a69b9ab268dcfb25f0218b277087f21",
    "id": null,
    "metadata": {},
    "name": "useDownloadOrderPdfQuery",
    "operationKind": "query",
    "text": "query useDownloadOrderPdfQuery(\n  $id: ID!\n) {\n  downloadOrderPdf(id: $id)\n}\n"
  }
};
})();

(node as any).hash = "5f9b618eb73a54e425a41da3d40feff3";

export default node;
