import { Loading } from '@icon-park/react';
import { SalesOrderConfirm } from 'features/salesOrders/components/SalesOrderConfirm';
import { SalesOrderForm } from 'features/salesOrders/components/SalesOrderForm';
import { SALES_ORDER_FORM_TYPE, SALES_ORDER_SUBMIT_TYPE } from 'features/salesOrders/form';
import { useSalesOrderEditForm } from 'features/salesOrders/hooks/useSalesOrderEditForm';
import { edit_SalesOrderEditPageQuery } from 'gql/__generated__/edit_SalesOrderEditPageQuery.graphql';
import { Suspense, useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useParams, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { buildDefaultValue } from './helper';

const salesOrderEditPageQuery = graphql`
  query edit_SalesOrderEditPageQuery ($id: ID!){
    salesOrders(where: {id: $id}){
      edges {
        node {
          id
          demand {
            id
            name
          }
          supplier {
            id
            name
          }

          orders {
            id
          }

          invoices {
            id
          }
          
          internalMemos {
            body
          }

          title
          detailMessage
          receivedDate
          details(orderBy: { direction: ASC, field: ORDER_NUMBER }) {
            edges {
              node {
                id
                deliveryEvents(where: {isValid: true}) {
                  edges {
                    node {
                      fixedDeliveryDate
                    }
                  }
                }
                item {
                  id
                  name
                  unitPrice
                  unitSellingPrice
                  quantity
                  tax: taxCategory {
                    id
                    rate
                  }
                }
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }
    taxCategories {
      edges {
        node {
          id
          rate
        }
      }
    }
    currentUser {
      userID
      firstName
      lastName
    }
  }
`;

export const SalesOrderEditPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');

  const { salesOrders, taxCategories, currentUser } =
    useLazyLoadQuery<edit_SalesOrderEditPageQuery>(
      salesOrderEditPageQuery,
      {
        id,
      },
      { fetchPolicy: 'network-only' },
    );

  const defaultValue = buildDefaultValue({ salesOrders, tax: taxCategories, currentUser });

  const {
    showPreview,
    values,
    isMutationInFlight,
    onSubmit,
    toConfirm,
    toForm,
    setShowBlocker,
    isDirtyMemo,
  } = useSalesOrderEditForm({ defaultValue, salesOrderId: id });

  useEffect(() => {
    if (!showPreview && type) {
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, showPreview, type]);

  const salesOrder = salesOrders?.edges?.at(0)?.node;
  if (!id || !salesOrder) return null;

  const hasRelatedOrder = (salesOrders?.edges?.[0]?.node?.orders?.length || 0) > 0;
  const hasRelatedInvoice = (salesOrders?.edges?.[0]?.node?.invoices?.length || 0) > 0;

  return (
    <Suspense fallback={<Loading />}>
      {showPreview && type === SALES_ORDER_SUBMIT_TYPE.confirm ? (
        <SalesOrderConfirm
          type={SALES_ORDER_FORM_TYPE.edit}
          values={values}
          onClickSubmit={onSubmit}
          isMutationInFlight={isMutationInFlight}
          onClickToNewForm={toForm}
        />
      ) : (
        <SalesOrderForm
          defaultValues={values}
          onClickToConfirm={toConfirm}
          previousUrl={paths.salesOrder.id(id).url()}
          type={SALES_ORDER_FORM_TYPE.edit}
          hasRelatedOrder={hasRelatedOrder}
          hasRelatedInvoice={hasRelatedInvoice}
          setShowBlocker={setShowBlocker}
          isDirtyMemo={isDirtyMemo}
        />
      )}
    </Suspense>
  );
};
