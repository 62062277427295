import { Empty, NullValue, PartialMessage, Value } from '@bufbuild/protobuf';
import {
  CardboardSpec,
  FlexiblePackageSpec,
  GiftBoxSpec,
  OtherSpec,
  PaperBagSpec,
} from 'proto/model/item/v1/category_pb';
import { CategoryItemsSummary, ItemsBySpec } from 'proto/model/item/v1/summarize_item_pb';
import { ItemTableCell, ItemTableRow } from './types';

export const getItemById = (
  items: PartialMessage<ItemsBySpec>[],
  id: string,
): PartialMessage<ItemsBySpec> | undefined => {
  for (const item of items) {
    if (item.id === id) {
      return item;
    }

    if (item.items) {
      const foundItem = getItemById(item.items, id);
      if (foundItem) {
        return foundItem;
      }
    }
  }
};

export const createTableRowData = (
  items: PartialMessage<ItemsBySpec>[],
  getItemById: (parentsId: string) => PartialMessage<ItemsBySpec> | undefined,
): ItemTableRow[] => {
  let rows: ItemTableRow[] = [];

  for (let index = 0; index < items.length; index++) {
    const item = items[index];

    const newRows: ItemTableRow[] = createTableRowData(item.items || [], getItemById);

    let value: string | number = '';

    switch (item.value?.kind?.case) {
      case 'numberValue':
      case 'stringValue':
        value = item.value.kind.value;
    }

    const type = item.name || 'string';
    const id = item.id || '';
    const ancestorIds = item.ancestorIds || [];

    // 最後の列かどうか
    const isLast = (item.items?.length || 0) === 0;

    // 同じ仕様のアイテムがあるかどうか（削除可能かどうかの判定に利用）
    const hasSameSpecItems = isLast && (getItemById(ancestorIds[1])?.items?.length || 0) > 1;
    const sourceItemId = item.sourceItemId;

    if (newRows.length === 0) {
      rows.push({
        cells: [
          {
            value,
            rowSpan: 1,
            type,
            id,
            ancestorIds,
            isLast,
            hasSameSpecItems,
            sourceItemId,
            spec: buildSpec(item.spec),
          },
        ],
      });
    } else {
      newRows[0].cells.unshift({
        value,
        rowSpan: newRows.length,
        type,
        id,
        ancestorIds,
        isLast,
        hasSameSpecItems,
        sourceItemId,
        spec: buildSpec(item.spec),
      });
      rows = [...rows, ...newRows];
    }
  }

  return rows;
};

const buildSpec = (spec: PartialMessage<ItemsBySpec>['spec']): ItemTableCell['spec'] => {
  switch (spec?.case) {
    case 'cardboardSpec':
      return {
        case: spec.case,
        value: new CardboardSpec(spec.value),
      };
    case 'flexiblePackageSpec':
      return {
        case: spec.case,
        value: new FlexiblePackageSpec(spec.value),
      };
    case 'giftBoxSpec':
      return {
        case: spec.case,
        value: new GiftBoxSpec(spec.value),
      };
    case 'paperBagSpec':
      return {
        case: spec.case,
        value: new PaperBagSpec(spec.value),
      };
    case 'otherSpec':
      return {
        case: spec.case,
        value: new OtherSpec(spec.value),
      };
    case 'unknownSpec':
      return {
        case: spec.case,
        value: new Empty(),
      };
  }

  return undefined;
};

export const updateItemValue = (
  rootItems: PartialMessage<CategoryItemsSummary>[],
  id: string,
  value: PartialMessage<Value>,
): PartialMessage<CategoryItemsSummary>[] => {
  return rootItems.map((rootItem) => {
    return { ...rootItem, items: updateItemsValueById(rootItem.items || [], id, value) };
  });
};

export const addItem = (
  rootItems: PartialMessage<CategoryItemsSummary>[],
  id: string,
  prevId: string,
  sourceItemId?: string,
  spec?: ItemTableCell['spec'],
): PartialMessage<CategoryItemsSummary>[] => {
  return rootItems.map((rootItem) => {
    const result = addItemAfterTargetId(rootItem.items || [], id, prevId, sourceItemId, spec);
    return { ...rootItem, items: result.items };
  });
};

export const removeItem = (
  rootItems: PartialMessage<CategoryItemsSummary>[],
  id: string,
): PartialMessage<CategoryItemsSummary>[] => {
  const newItems = rootItems.map((rootItem) => {
    const newRootItem = { ...rootItem };
    if (newRootItem.items) {
      newRootItem.items = removeItemById(newRootItem.items, id);
    }
    return newRootItem;
  });

  return newItems;
};

const removeItemById = (
  items: PartialMessage<ItemsBySpec>[],
  id: string,
): PartialMessage<ItemsBySpec>[] => {
  const newItems = items
    .map((item) => {
      const newItem = { ...item };
      if (newItem.items) {
        newItem.items = removeItemById(newItem.items, id);
      }
      return newItem;
    })
    .filter((item) => item.id !== id);

  return newItems;
};

const addItemAfterTargetId = (
  items: PartialMessage<ItemsBySpec>[],
  ancestorId: string,
  targetId: string,
  sourceItemId?: string,
  spec?: ItemTableCell['spec'],
): { items: PartialMessage<ItemsBySpec>[]; found: boolean } => {
  const newItems = [...items];
  let found = false;

  for (let i = 0; i < newItems.length; i++) {
    if (newItems[i].id === targetId) {
      newItems.splice(i + 1, 0, getAddItemObject(ancestorId, sourceItemId, spec));
      found = true;
      break;
    }

    const newItemsItems = newItems[i].items || null;
    if (newItemsItems) {
      const result = addItemAfterTargetId(newItemsItems, ancestorId, targetId, sourceItemId, spec);
      if (result.found) {
        newItems[i] = { ...newItems[i], items: result.items };
        found = true;
        break;
      }
    }
  }

  return { items: newItems, found: found };
};

const updateItemsValueById = (
  itemList: PartialMessage<ItemsBySpec>[],
  id: string,
  value: PartialMessage<Value>,
): PartialMessage<ItemsBySpec>[] => {
  return itemList.map((item) => {
    if (item.id === id) {
      return { ...item, value: value };
    }

    if (item.items) {
      return { ...item, items: updateItemsValueById(item.items, id, value) };
    }

    return item;
  });
};

const getAddItemObject = (
  ancestorId: string,
  sourceItemId?: string,
  spec?: ItemTableCell['spec'],
): PartialMessage<ItemsBySpec> => {
  const quantityId = Date.now().toString();
  // 重複を避けるために+1
  const unitPriceId = (Date.now() + 1).toString();

  return {
    id: quantityId,
    ancestorIds: [ancestorId],
    name: 'quantity',
    value: { kind: { case: 'numberValue', value: NullValue.NULL_VALUE } },
    items: [
      {
        id: unitPriceId,
        ancestorIds: [quantityId, ancestorId],
        name: 'unitPrice',
        value: { kind: { case: 'numberValue', value: NullValue.NULL_VALUE } },
        sourceItemId,
        spec,
      },
    ],
  };
};
