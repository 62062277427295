/**
 * @generated SignedSource<<5343f1e6e4e5e305e46049fec172e7f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SupplierDetailedInfoFragment$data = {
  readonly contacts: ReadonlyArray<{
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly memos: ReadonlyArray<{
      readonly body: string;
    }> | null;
  }> | null;
  readonly createdAt: any;
  readonly createdBy: {
    readonly profile: ReadonlyArray<{
      readonly firstName: string;
      readonly lastName: string;
    }> | null;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "SupplierDetailedInfoFragment";
};
export type SupplierDetailedInfoFragment$key = {
  readonly " $data"?: SupplierDetailedInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SupplierDetailedInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SupplierDetailedInfoFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SupplierContact",
      "kind": "LinkedField",
      "name": "contacts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SupplierContactMemo",
          "kind": "LinkedField",
          "name": "memos",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "body",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Supplier",
  "abstractKey": null
};
})();

(node as any).hash = "062b39e223971f3cd23360aa3a9036aa";

export default node;
