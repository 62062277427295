/**
 * @generated SignedSource<<e54ba48ecb15586bbb3d6f4850964548>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrderAssigneeRecipientType = "cc" | "to" | "%future added value";
export type OrderSendEmailInput = {
  mailBodyMessage?: string | null;
  supplierContacts?: ReadonlyArray<OrderSupplieerContactInput> | null;
};
export type OrderSupplieerContactInput = {
  ID: string;
  recipientType: OrderAssigneeRecipientType;
};
export type useOrderSendingMutation$variables = {
  id: string;
  input: OrderSendEmailInput;
};
export type useOrderSendingMutation$data = {
  readonly sendOrderEmail: boolean;
};
export type useOrderSendingMutation = {
  response: useOrderSendingMutation$data;
  variables: useOrderSendingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "sendOrderEmail",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrderSendingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useOrderSendingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "33cff413523ab89111f38d6488e30d28",
    "id": null,
    "metadata": {},
    "name": "useOrderSendingMutation",
    "operationKind": "mutation",
    "text": "mutation useOrderSendingMutation(\n  $id: ID!\n  $input: OrderSendEmailInput!\n) {\n  sendOrderEmail(id: $id, input: $input)\n}\n"
  }
};
})();

(node as any).hash = "0fc6434be1998146474b65f0fe23c5f7";

export default node;
