/**
 * @generated SignedSource<<60b7d968d0228d824096f3eae392cda8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type EstimationDetailType = "cost" | "item" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type edit_EstimationEditPageFragment$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly userID: string;
  };
  readonly estimations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly company: {
          readonly id: string;
          readonly name: string;
        };
        readonly createdAt: any;
        readonly detailMessage: string | null;
        readonly details: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly documentDisplayStatus: EstimationDetailDocumentDisplayStatus;
              readonly item: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly taxCategory: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitPrice: number;
                readonly unitSellingPrice: number;
              } | null;
              readonly orderNumber: any;
              readonly type: EstimationDetailType;
            } | null;
          } | null> | null;
        };
        readonly estimationResponse: {
          readonly assignee: {
            readonly estimationRequest: {
              readonly id: string;
            };
          };
        } | null;
        readonly expirationPeriod: string;
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
            }> | null;
          };
        }> | null;
        readonly showAmountSummary: boolean;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
        readonly title: string;
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "edit_EstimationEditPageFragment";
};
export type edit_EstimationEditPageFragment$key = {
  readonly " $data"?: edit_EstimationEditPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"edit_EstimationEditPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "edit_EstimationEditPageFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EstimationConnection",
      "kind": "LinkedField",
      "name": "estimations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Estimation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Supplier",
                  "kind": "LinkedField",
                  "name": "supplier",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "detailMessage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expirationPeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showAmountSummary",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": {
                        "direction": "ASC",
                        "field": "ORDER_NUMBER"
                      }
                    }
                  ],
                  "concreteType": "EstimationDetailConnection",
                  "kind": "LinkedField",
                  "name": "details",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationDetailEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EstimationDetail",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "type",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "orderNumber",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "documentDisplayStatus",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "EstimationItem",
                              "kind": "LinkedField",
                              "name": "item",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "unitPrice",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "unitSellingPrice",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "quantity",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "TaxCategory",
                                  "kind": "LinkedField",
                                  "name": "taxCategory",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v3/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationResponse",
                  "kind": "LinkedField",
                  "name": "estimationResponse",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationRequestAssignee",
                      "kind": "LinkedField",
                      "name": "assignee",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EstimationRequest",
                          "kind": "LinkedField",
                          "name": "estimationRequest",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationInternalAssignee",
                  "kind": "LinkedField",
                  "name": "internalAssignees",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserProfile",
                          "kind": "LinkedField",
                          "name": "profile",
                          "plural": true,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryConnection",
      "kind": "LinkedField",
      "name": "taxCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userID",
          "storageKey": null
        },
        (v5/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2073d07f67991fc2b9c06b2338a51b21";

export default node;
