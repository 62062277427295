import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: {
    html: {
      fontSize: 16,
      color: 'rgba(0, 26, 64, 0.87)',
      height: '100vh',
      margin: 0,
    },
    'body, h1, h2, h3, p': {
      margin: 0,
    },
  },
};

export default styles;
