import { Box } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';
import { Outlet } from 'react-router-dom';

export const CompanyPageLayout = () => (
  <>
    <Box mb={8}>
      <PageHeading label="デマンド" />
    </Box>
    <Outlet />
  </>
);
