/**
 * @generated SignedSource<<836e34e214ebf5a6e5e670a45a94a217>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type companyPageQuery$variables = {};
export type companyPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"companyPageFragment">;
};
export type companyPageQuery = {
  response: companyPageQuery$data;
  variables: companyPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "direction",
        "value": "DESC"
      },
      {
        "kind": "Literal",
        "name": "field",
        "value": "CREATED_AT"
      }
    ],
    "kind": "ObjectValue",
    "name": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "companyPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "companyPageFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "companyPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "companies(first:100,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "orderBy",
          "where"
        ],
        "handle": "connection",
        "key": "companyPageFragment_companies",
        "kind": "LinkedHandle",
        "name": "companies"
      }
    ]
  },
  "params": {
    "cacheID": "d68a6f5de00c34950df4c9723417cee6",
    "id": null,
    "metadata": {},
    "name": "companyPageQuery",
    "operationKind": "query",
    "text": "query companyPageQuery {\n  ...companyPageFragment\n}\n\nfragment CompanyTableRowFragment on Company {\n  id\n  name\n}\n\nfragment companyPageFragment on Query {\n  companies(first: 100, orderBy: {direction: DESC, field: CREATED_AT}) {\n    edges {\n      cursor\n      node {\n        id\n        ...CompanyTableRowFragment\n        __typename\n      }\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3efea6083614c8113ec7f85eb17ccfb0";

export default node;
