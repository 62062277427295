import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandItemForm } from 'features/demandItem/components/DemandItemForm';
import { DemandItemFormType } from 'features/demandItem/form';
import { create_DemandItemCreatePageQuery } from 'gql/__generated__/create_DemandItemCreatePageQuery.graphql';
import { useFormContext } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const DemandItemCreatePageQuery = graphql`
  query create_DemandItemCreatePageQuery {
    ...DemandItemFormFragment
  }
`;

export const DemandItemCreatePage = () => {
  const navigate = useNavigate();
  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const query = useLazyLoadQuery<create_DemandItemCreatePageQuery>(DemandItemCreatePageQuery, {
    fetchPolicy: 'network-only',
  });

  const form = useFormContext<DemandItemFormType>();

  const handleFormConfirm = form.handleSubmit(() => {
    navigate(paths.demands._demandCode(demandCode).items.new.confirm.url());
  });

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.demands._demandCode(demandCode).url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="新規商品の追加" />
      <DemandItemForm navigateToConfirm={handleFormConfirm} queryRef={query} />
    </>
  );
};
