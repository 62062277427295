import { Box, Flex, HStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { OrderDetailSideBar } from 'features/order/components/OrderDetailSideBar';
import { OrderPreview } from 'features/order/components/OrderPreview';
import { OrderPreviewHeader } from 'features/order/components/OrderPreviewHeader';
import { useQueryParams } from 'features/order/hooks/useOrderQueryPrams';
import { detail_OrderDetailPageQuery as detail_OrderDetailPageQueryType } from 'gql/__generated__/detail_OrderDetailPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const OrderDetailPageQuery = graphql`
  query detail_OrderDetailPageQuery  ($id: ID!) {
    orders(where: {id: $id}) {
      edges {
        node {
          ...OrderDetailSideBarFragment
          ...OrderPreviewFragment
        }
      }
    }
  }
`;

export const OrderDetailPage = () => {
  const { queryParams } = useQueryParams();
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { orders } = useLazyLoadQuery<detail_OrderDetailPageQueryType>(
    OrderDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const order = (orders.edges || [])[0]?.node;

  if (!order) return null;

  return (
    <Box w="992px">
      <Box mb={6}>
        <Flex justify="space-between">
          <RouterLink
            to={paths.order.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              supplier: queryParams.supplier,
            })}
          >
            <PageBack />
          </RouterLink>
          <OrderPreviewHeader id={id} />
        </Flex>
      </Box>
      <Box mb={10}>
        <Box mb={6}>
          <SubHeading label="発注書の詳細" />
        </Box>
        <HStack spacing={4} align="start">
          <Box w="208px">
            <OrderDetailSideBar queryRef={order} />
          </Box>
          <Box w="768px">
            <OrderPreview queryRef={order} />
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
