// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file model/item/v1/category.proto (package model.item.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message model.item.v1.CardboardSpec
 */
export class CardboardSpec extends Message<CardboardSpec> {
  /**
   * サイズ
   *
   * @generated from field: optional string size = 1;
   */
  size?: string;

  /**
   * 形状
   *
   * @generated from field: optional string type = 2;
   */
  type?: string;

  /**
   * 構成
   *
   * @generated from field: optional string material = 3;
   */
  material?: string;

  /**
   * 段厚
   *
   * @generated from field: optional string thickness = 4;
   */
  thickness?: string;

  /**
   * 印刷
   *
   * @generated from field: optional string printing_color = 5;
   */
  printingColor?: string;

  /**
   * 加工
   *
   * @generated from field: optional string processing = 6;
   */
  processing?: string;

  /**
   * その他仕様
   *
   * @generated from field: optional string other = 7;
   */
  other?: string;

  constructor(data?: PartialMessage<CardboardSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.CardboardSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "material", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "thickness", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "printing_color", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "processing", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "other", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardboardSpec {
    return new CardboardSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardboardSpec {
    return new CardboardSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardboardSpec {
    return new CardboardSpec().fromJsonString(jsonString, options);
  }

  static equals(a: CardboardSpec | PlainMessage<CardboardSpec> | undefined, b: CardboardSpec | PlainMessage<CardboardSpec> | undefined): boolean {
    return proto3.util.equals(CardboardSpec, a, b);
  }
}

/**
 * @generated from message model.item.v1.FlexiblePackageSpec
 */
export class FlexiblePackageSpec extends Message<FlexiblePackageSpec> {
  /**
   * サイズ
   *
   * @generated from field: optional string size = 1;
   */
  size?: string;

  /**
   * 形状
   *
   * @generated from field: optional string type = 2;
   */
  type?: string;

  /**
   * 構成
   *
   * @generated from field: optional string material = 3;
   */
  material?: string;

  /**
   * 印刷
   *
   * @generated from field: optional string printing_color = 4;
   */
  printingColor?: string;

  /**
   * 加工
   *
   * @generated from field: optional string processing = 5;
   */
  processing?: string;

  /**
   * その他仕様
   *
   * @generated from field: optional string other = 6;
   */
  other?: string;

  constructor(data?: PartialMessage<FlexiblePackageSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.FlexiblePackageSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "material", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "printing_color", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "processing", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "other", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlexiblePackageSpec {
    return new FlexiblePackageSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlexiblePackageSpec {
    return new FlexiblePackageSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlexiblePackageSpec {
    return new FlexiblePackageSpec().fromJsonString(jsonString, options);
  }

  static equals(a: FlexiblePackageSpec | PlainMessage<FlexiblePackageSpec> | undefined, b: FlexiblePackageSpec | PlainMessage<FlexiblePackageSpec> | undefined): boolean {
    return proto3.util.equals(FlexiblePackageSpec, a, b);
  }
}

/**
 * @generated from message model.item.v1.GiftBoxSpec
 */
export class GiftBoxSpec extends Message<GiftBoxSpec> {
  /**
   * サイズ
   *
   * @generated from field: optional string size = 1;
   */
  size?: string;

  /**
   * 形状
   *
   * @generated from field: optional string type = 2;
   */
  type?: string;

  /**
   * 用紙
   *
   * @generated from field: optional string paper_type = 3;
   */
  paperType?: string;

  /**
   * 印刷
   *
   * @generated from field: optional string printing_color = 4;
   */
  printingColor?: string;

  /**
   * 加工
   *
   * @generated from field: optional string processing = 5;
   */
  processing?: string;

  /**
   * その他仕様
   *
   * @generated from field: optional string other = 6;
   */
  other?: string;

  constructor(data?: PartialMessage<GiftBoxSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.GiftBoxSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "paper_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "printing_color", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "processing", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "other", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftBoxSpec {
    return new GiftBoxSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftBoxSpec {
    return new GiftBoxSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftBoxSpec {
    return new GiftBoxSpec().fromJsonString(jsonString, options);
  }

  static equals(a: GiftBoxSpec | PlainMessage<GiftBoxSpec> | undefined, b: GiftBoxSpec | PlainMessage<GiftBoxSpec> | undefined): boolean {
    return proto3.util.equals(GiftBoxSpec, a, b);
  }
}

/**
 * @generated from message model.item.v1.PaperBagSpec
 */
export class PaperBagSpec extends Message<PaperBagSpec> {
  /**
   * サイズ
   *
   * @generated from field: optional string size = 1;
   */
  size?: string;

  /**
   * 用紙
   *
   * @generated from field: optional string paper_type = 2;
   */
  paperType?: string;

  /**
   * 印刷
   *
   * @generated from field: optional string printing_color = 3;
   */
  printingColor?: string;

  /**
   * 加工
   *
   * @generated from field: optional string processing = 4;
   */
  processing?: string;

  /**
   * 持ち手
   *
   * @generated from field: optional string handle = 5;
   */
  handle?: string;

  /**
   * その他仕様
   *
   * @generated from field: optional string other = 6;
   */
  other?: string;

  constructor(data?: PartialMessage<PaperBagSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.PaperBagSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "paper_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "printing_color", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "processing", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "other", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaperBagSpec {
    return new PaperBagSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaperBagSpec {
    return new PaperBagSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaperBagSpec {
    return new PaperBagSpec().fromJsonString(jsonString, options);
  }

  static equals(a: PaperBagSpec | PlainMessage<PaperBagSpec> | undefined, b: PaperBagSpec | PlainMessage<PaperBagSpec> | undefined): boolean {
    return proto3.util.equals(PaperBagSpec, a, b);
  }
}

/**
 * @generated from message model.item.v1.OtherSpec
 */
export class OtherSpec extends Message<OtherSpec> {
  /**
   * その他仕様
   *
   * @generated from field: optional string other = 1;
   */
  other?: string;

  constructor(data?: PartialMessage<OtherSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.OtherSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "other", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OtherSpec {
    return new OtherSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OtherSpec {
    return new OtherSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OtherSpec {
    return new OtherSpec().fromJsonString(jsonString, options);
  }

  static equals(a: OtherSpec | PlainMessage<OtherSpec> | undefined, b: OtherSpec | PlainMessage<OtherSpec> | undefined): boolean {
    return proto3.util.equals(OtherSpec, a, b);
  }
}

