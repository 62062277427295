/**
 * @generated SignedSource<<2dc3e3e1c8bff1e0d3bf8691bb70a854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderPreviewFragment$data = {
  readonly company: {
    readonly id: string;
    readonly name: string;
  };
  readonly createdAt: any;
  readonly deliveryInfo: {
    readonly desiredDeliveryDate: string;
    readonly id: string;
    readonly recipientsText: string | null;
  } | null;
  readonly detailMessage: string | null;
  readonly details: ReadonlyArray<{
    readonly branchNumber: any;
    readonly id: string;
    readonly item: {
      readonly id: string;
      readonly name: string;
      readonly quantity: any;
      readonly taxCategory: {
        readonly rate: number;
      };
      readonly unitPrice: number;
    } | null;
    readonly type: OrderDetailType;
  }> | null;
  readonly salesOrders: ReadonlyArray<{
    readonly id: string;
    readonly transactionID: any;
  }> | null;
  readonly supplier: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "OrderPreviewFragment";
};
export type OrderPreviewFragment$key = {
  readonly " $data"?: OrderPreviewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderPreviewFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderPreviewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Supplier",
      "kind": "LinkedField",
      "name": "supplier",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrder",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionID",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detailMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDeliveryInfo",
      "kind": "LinkedField",
      "name": "deliveryInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "desiredDeliveryDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipientsText",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDetail",
      "kind": "LinkedField",
      "name": "details",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "branchNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unitPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxCategory",
              "kind": "LinkedField",
              "name": "taxCategory",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "ded30d28ff113ad922d2eb979aed285b";

export default node;
