import Excel from 'exceljs';
import { twoBytesToSingleByteNumber } from './number';

const categoryKeyMap = new Map<string, string>([
  ['ダンボール', 'cardboard'],
  ['化粧箱', 'giftBox'],
  ['軟包材', 'flexiblePackage'],
  ['紙袋', 'paperBag'],
  ['その他', 'other'],
]);

const headerNameKeyMap = new Map<string, string>([
  ['品名', 'name'],
  ['寸法種別', 'cardboardSizeType'],
  ['W (mm)', 'cardboardWidth'],
  ['D (mm)', 'cardboardDepth'],
  ['H (mm)', 'cardboardHeight'],
  ['サイズ', 'size'],
  ['形式', 'type'],
  ['形状', 'type'],
  ['構成', 'material'],
  ['段厚', 'thickness'],
  ['素材', 'material'],
  ['印刷', 'printingColor'],
  ['加工', 'processings'],
  ['用紙', 'paperType'],
  ['原紙', 'paperType'],
  ['持ち手', 'handle'],
  ['厚さ', 'weight'],
  ['粘着', 'adhesive'],
  ['仕上げ', 'finishedForm'],
  ['その他の仕様', 'other'],
  ['希望価格', 'askingUnitPrice'],
  ['年間発注数量', 'annualQuantity'],
]);

/**
 * 見積依頼、Excelをパースする処理
 * @param excel
 * @returns
 */
export const readEstimationRequestExcelData = async (excel: File) => {
  const data: {
    quantity: number;
    name: string;
    specJson?: Map<string, Excel.CellValue>;
  }[] = [];
  const error: { message: string } = { message: '' };

  const arrayBuffer = await excel.arrayBuffer();
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(arrayBuffer);

  for (const worksheet of workbook.worksheets) {
    const categoryName = categoryKeyMap.get(worksheet.name);
    if (!categoryName) continue;

    const header = worksheet.findRow(1);
    if (!header?.hasValues) continue;

    const lotColumnNumber = (header.values as Excel.CellValue[]).indexOf('ロット');
    if (!lotColumnNumber) continue;

    // 列番号(左端列が1〜の整数)とfieldKeyを対応させたMapを作成しておく
    // 「品名」〜「ロット」の間の列のみを対象とする、「ロット」は含まない
    // ex) { 1 => 'name', 2 => 'size' }
    const columnNumFieldKeyMap = new Map<number, string>();
    for (let index = 1; index < lotColumnNumber; index++) {
      const headerCell = (header.values as Excel.CellValue[])[index];
      const fieldKey = headerNameKeyMap.get(headerCell as string);
      if (!fieldKey) continue;
      columnNumFieldKeyMap.set(index, fieldKey);
    }

    for (let index = 1; index < worksheet.rowCount + 1; index++) {
      if (index === 1) continue;

      const row = worksheet.getRow(index);

      if (!row.values || row.values.length === 0) continue;

      /**
       * 品名〜ロットのセル以外の入力はバリデーションおよび入力の対象外とする。
       * 品名〜ロットのセルの入力が空か半角・全角スペースのみであればloopを抜ける
       */
      const values = (row.values as Excel.CellValue[])
        .slice(0, lotColumnNumber + 1)
        .filter(
          (value) =>
            !(value === null || value === undefined || value.toString().match(/^[\s　]*$/)),
        );

      if (values.length === 0) continue;

      if (!isValidItemName(row) || !isValidLot(row, lotColumnNumber)) {
        error.message = `${worksheet.name}の品名またはロットが入力されていない箇所があります`;
        data.splice(0);
        break;
      }

      const itemValues = (row.values as Excel.CellValue[]).slice(1, lotColumnNumber);
      const specJson = new Map<string, Excel.CellValue>([['category', categoryName]]);

      const cardbordSizeSpec: cardboardSizeSpecType = {
        cardboardSizeType: undefined,
        cardboardWidth: undefined,
        cardboardDepth: undefined,
        cardboardHeight: undefined,
      };

      itemValues.forEach((value, i) => {
        const fieldKey = columnNumFieldKeyMap.get(i + 1);

        if (fieldKey && (value || value === 0)) {
          let rawValue: string | number | undefined;
          if (['number', 'string', 'boolean', 'Date'].includes(typeof value)) {
            rawValue = formatStringNumber(value.toString());
          } else {
            if (typeof value === 'object' && 'result' in value) {
              rawValue = formatStringNumber(value.result?.toString() || '');
            } else if (typeof value === 'object' && 'text' in value) {
              rawValue = value.text;
            } else if (typeof value === 'object' && 'richText' in value) {
              rawValue = '';
              value.richText.forEach((richText) => (rawValue += richText.text));
            }
          }

          if (rawValue || rawValue === 0) {
            if (categoryName === 'cardboard' && fieldKey in cardbordSizeSpec) {
              // NOTE: ダンボールはサイズ指定が特殊。寸法種別とW, D, Hの4つの値を組み合わせて指定する
              cardbordSizeSpec[fieldKey as cardboardSizeKeys] = rawValue.toString();
              return;
            }

            if (typeof rawValue === 'string') {
              rawValue = rawValue.replace(/\t/g, '　');
            }
            specJson.set(fieldKey, rawValue);
          }
        }
      });

      if (categoryName === 'cardboard' && hasAnyCardboardSizeTypeValue(cardbordSizeSpec)) {
        // ダンボールのサイズ指定は寸法種別, W, D, Hの4つの値を組み合わせて指定する
        // どの項目も空の場合はサイズ指定なしとみなす
        const type = cardbordSizeSpec.cardboardSizeType;
        const isBladeSpanType = type === '刃渡寸法';

        const widthPrefix = isBladeSpanType ? '流れ' : 'W';
        const depthPrefix = isBladeSpanType ? '巾' : 'D';
        const heightPrefix = 'H';

        const sizes: string[] = [];

        if (cardbordSizeSpec.cardboardWidth) {
          sizes.push(`${widthPrefix}${cardbordSizeSpec.cardboardWidth}`);
        }

        if (cardbordSizeSpec.cardboardDepth) {
          sizes.push(`${depthPrefix}${cardbordSizeSpec.cardboardDepth}`);
        }

        if (!isBladeSpanType && cardbordSizeSpec.cardboardHeight) {
          // 刃渡寸法の場合は流れと巾のみで高さは指定しない
          sizes.push(`${heightPrefix}${cardbordSizeSpec.cardboardHeight}`);
        }

        const size = `${type ? `${type} ` : ''}${sizes.join('×')}mm`;
        specJson.set('size', size);
      }

      const item = {
        name: createItemName(itemValues),
        specJson: specJson,
        quantity: createQuantity(row.getCell(lotColumnNumber).value),
      };

      data.push(item);
    }

    if (error.message !== '' || data.length > 0) {
      break;
    }
  }

  return { data, error };
};

const isValidItemName = (row: Excel.Row) => {
  const itemName = row.getCell(1);
  return !!itemName.value;
};

const isValidLot = (row: Excel.Row, cellNumber: number): boolean => {
  const lot = row.getCell(cellNumber);
  return !!lot.value;
};

const createItemName = (itemValues: Excel.CellValue[]) =>
  itemValues
    .map((value) => {
      if (!value) return value;

      if (typeof value === 'object' && 'result' in value) {
        return value.result;
      }

      if (typeof value === 'object' && 'text' in value) {
        return value.text;
      }

      if (typeof value === 'object' && 'richText' in value) {
        let concatValue = '';
        value.richText.forEach((richText) => (concatValue += richText.text));
        return concatValue;
      }

      return value;
    })
    .filter((v): v is NonNullable<typeof v> => v != null)
    // アイテム名と各仕様は全角スペースで繋ぐ
    .join('　');

const createQuantity = (value: Excel.CellValue): number => {
  if (!value) return 0;

  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    return twoBytesToSingleByteNumber(value.replace(/,/g, ''));
  }

  if (typeof value === 'object' && 'result' in value) {
    if (typeof value.result === 'number') {
      return value.result;
    }

    if (typeof value.result === 'string') {
      return twoBytesToSingleByteNumber(value.result.replace(/,/g, ''));
    }
  }

  return 0;
};

export const formatStringNumber = (value: string) => {
  if (/^\d+(\.\d+)?$/.test(value)) {
    const number = Number(value);
    return number.toLocaleString();
  }
  return value;
};

type cardboardSizeSpecType = {
  cardboardSizeType?: string;
  cardboardWidth?: string;
  cardboardDepth?: string;
  cardboardHeight?: string;
};

type cardboardSizeKeys = keyof cardboardSizeSpecType;

/**
 * ダンボールサイズ仕様の寸法種別、W, D, Hのいずれかの値があるかどうか
 * @param spec ダンボールサイズの仕様
 * @returns boolean
 */
const hasAnyCardboardSizeTypeValue = (spec: cardboardSizeSpecType) => {
  return (
    spec.cardboardSizeType || spec.cardboardWidth || spec.cardboardDepth || spec.cardboardHeight
  );
};
