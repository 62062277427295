import { StyleFunctionProps, theme } from '@chakra-ui/react';

export const Alert = {
  variants: {
    toast: (props: StyleFunctionProps) => {
      if (props.status === 'success') {
        return {
          container: {
            bg: 'blue.500',
            color: 'white',
          },
        };
      }
      return theme.components.Alert.variants?.solid(props);
    },
  },
};
