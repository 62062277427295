import { KeyboardEvent, useEffect, useState, useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Variables } from 'relay-runtime';
import { useQueryParams } from './useSalesOrderQueryPrams';

export const getSalesOrderWhere = ({
  userId,
  demandId,
  supplierId,
  transactionId,
}: { userId?: string; demandId?: string; supplierId?: string; transactionId?: string }) => {
  const conditions: Array<Record<string, Record<string, string> | string>> = [];

  if (demandId) {
    conditions.push({ hasDemandWith: { id: demandId } });
  }

  if (supplierId) {
    conditions.push({ hasSupplierWith: { id: supplierId } });
  }

  if (userId) {
    conditions.push({ hasInternalAssigneesWith: { userID: userId } });
  }

  if (transactionId) {
    conditions.push({ transactionID: transactionId });
  }

  if (conditions.length === 0) return {};

  return {
    where: {
      and: conditions,
    },
  };
};

type ParamsType = 'demand' | 'supplier' | 'assignee' | 'transactionId';

const buildQueryParams = (
  searchParams: URLSearchParams,
  {
    userId,
    demandId,
    supplierId,
    transactionId: txnId,
  }: { userId?: string; demandId?: string; supplierId?: string; transactionId?: string },
) => {
  const params: { [key in ParamsType]?: string } = {};

  const demand = demandId ?? searchParams.get('demand');
  const supplier = supplierId ?? searchParams.get('supplier');
  const assignee = userId ?? searchParams.get('assignee');
  const transactionId = txnId ?? searchParams.get('transactionId');

  if (demand) params.demand = demand;
  if (supplier) params.supplier = supplier;
  if (assignee) params.assignee = assignee;
  if (transactionId) params.transactionId = transactionId;

  return params;
};

export const useSalesOrderFilter = (
  refetch: (vars: Partial<Variables>) => void,
  transactionIdParam: string,
) => {
  const { queryParams, updateQueryParams } = useQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPending, startTransition] = useTransition();
  const [transactionId, setTransactionId] = useState(transactionIdParam);

  const onRefetch = ({
    userId,
    demandId,
    transactionId,
    supplierId,
  }: { userId: string; demandId: string; transactionId: string; supplierId: string }) => {
    startTransition(() => {
      refetch(getSalesOrderWhere({ userId, demandId, transactionId, supplierId }));
    });
  };

  const onClearDemandInput = () => {
    const params = buildQueryParams(searchParams, { demandId: '' });
    if (params.demand !== queryParams.demand) {
      onRefetch({
        demandId: '',
        userId: params.assignee || '',
        transactionId: params.transactionId || '',
        supplierId: params.supplier || '',
      });
      setSearchParams(params);
      updateQueryParams({ demand: '' });
    }
  };

  const onClearSupplierInput = () => {
    const params = buildQueryParams(searchParams, { supplierId: '' });
    if (params.supplier !== queryParams.supplier) {
      onRefetch({
        supplierId: '',
        userId: params.assignee || '',
        transactionId: params.transactionId || '',
        demandId: params.demand || '',
      });
      setSearchParams(params);
      updateQueryParams({ supplier: '' });
    }
  };

  const onChangeUser = (value: string) => {
    const params = buildQueryParams(searchParams, { userId: value });
    onRefetch({
      demandId: params.demand || '',
      userId: params.assignee || '',
      transactionId: params.transactionId || '',
      supplierId: params.supplier || '',
    });
    setSearchParams(params);
    updateQueryParams({ assignee: params.assignee || '' });
  };

  const onChangeDemand = (value: { id: string; name: string } | null | undefined) => {
    const params = buildQueryParams(searchParams, { demandId: value?.id });
    const currentDemandId = searchParams.get('demand');

    if (value?.id !== currentDemandId) {
      onRefetch({
        demandId: params.demand || '',
        userId: params.assignee || '',
        transactionId: params.transactionId || '',
        supplierId: params.supplier || '',
      });
      setSearchParams(params);
      updateQueryParams({ demand: params.demand || '' });
    }
  };

  const onChangeSupplier = (value: { id: string; name: string } | null | undefined) => {
    const params = buildQueryParams(searchParams, { supplierId: value?.id });
    const currentSupplierId = searchParams.get('supplier');

    if (value?.id !== currentSupplierId) {
      onRefetch({
        demandId: params.demand || '',
        userId: params.assignee || '',
        transactionId: params.transactionId || '',
        supplierId: params.supplier || '',
      });
      setSearchParams(params);
      updateQueryParams({ supplier: params.supplier || '' });
    }
  };

  const onChangeId = (value: string) => {
    setTransactionId(value);
  };

  const onKeyPressIdInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const params = buildQueryParams(searchParams, { transactionId });

      startTransition(() => {
        onRefetch({
          demandId: params.demand || '',
          supplierId: params.supplier || '',
          userId: params.assignee || '',
          transactionId: params.transactionId || '',
        });
      });
      setSearchParams(params);
      updateQueryParams({ transactionId: params.transactionId });
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (transactionIdParam !== transactionId) {
      setTransactionId(transactionIdParam);
    }
  }, [transactionIdParam]);

  return {
    onChangeUser,
    onChangeDemand,
    onChangeId,
    onChangeSupplier,
    onKeyPressIdInput,
    onClearDemandInput,
    onClearSupplierInput,
    isPending,
    transactionId,
  };
};
