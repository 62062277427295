/**
 * @generated SignedSource<<be608040eaf609234b5fcdba6e911ea8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SalesOrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type UpdateSalesOrderInput = {
  demandID: string;
  detailMessage: string;
  details: ReadonlyArray<UpdateSalesOrderDetailInput>;
  id: string;
  internalAssignees?: ReadonlyArray<SalesOrderInternalAssigneeInput> | null;
  internalMemo?: string | null;
  receivedDate: any;
  supplierID: string;
  title: string;
};
export type UpdateSalesOrderDetailInput = {
  Item?: UpdateSalesOrderItemInput | null;
  fixedDeliveryDate?: any | null;
  id?: string | null;
  orderNumber: number;
  type: SalesOrderDetailType;
};
export type UpdateSalesOrderItemInput = {
  name: string;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type SalesOrderInternalAssigneeInput = {
  userID: string;
};
export type useSalesOrderEditFormMutation$variables = {
  input: UpdateSalesOrderInput;
};
export type useSalesOrderEditFormMutation$data = {
  readonly updateSalesOrder: {
    readonly id: string;
  };
};
export type useSalesOrderEditFormMutation = {
  response: useSalesOrderEditFormMutation$data;
  variables: useSalesOrderEditFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SalesOrder",
    "kind": "LinkedField",
    "name": "updateSalesOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSalesOrderEditFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSalesOrderEditFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b5fd4c6901cfc02092b1fbb8950be924",
    "id": null,
    "metadata": {},
    "name": "useSalesOrderEditFormMutation",
    "operationKind": "mutation",
    "text": "mutation useSalesOrderEditFormMutation(\n  $input: UpdateSalesOrderInput!\n) {\n  updateSalesOrder(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0833c61ca6c7dc8bd06a1751762b94d2";

export default node;
