import { errorMessages } from 'utils/validation';
import { z } from 'zod';

export const supplierFormSchema = z.object({
  name: z.string().max(255, { message: errorMessages.MAX_LENGTH(255) }),
  contacts: z.array(
    z.object({
      id: z.string().nullable(),
      lastName: z.string(),
      firstName: z.string().optional(),
      email: z.string().email(),
      memo: z
        .string()
        .max(500, { message: errorMessages.MAX_LENGTH(500) })
        .optional(),
    }),
  ),
});

export type SupplierFormDataType = z.infer<typeof supplierFormSchema>;
