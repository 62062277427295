/**
 * @generated SignedSource<<e1d86819636927b4200ff4d0a916bb7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EstimationRequestDetailType = "item" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type duplication_estimationRequestDuplicationPageFragment$data = {
  readonly estimationRequests: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly company: {
          readonly id: string;
          readonly name: string;
        };
        readonly details: ReadonlyArray<{
          readonly item: {
            readonly name: string;
            readonly quantity: any;
            readonly specJSON: any;
          } | null;
          readonly type: EstimationRequestDetailType;
        }> | null;
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
            }> | null;
          };
        }> | null;
        readonly requestAssignees: ReadonlyArray<{
          readonly supplier: {
            readonly id: string;
            readonly name: string;
          };
          readonly supplierContacts: ReadonlyArray<{
            readonly email: string;
            readonly firstName: string;
            readonly id: string;
            readonly lastName: string;
            readonly memos: ReadonlyArray<{
              readonly body: string;
            }> | null;
            readonly supplier: {
              readonly id: string;
              readonly name: string;
            };
          }> | null;
        }> | null;
        readonly requestTitle: string;
        readonly specText: string;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "duplication_estimationRequestDuplicationPageFragment";
};
export type duplication_estimationRequestDuplicationPageFragment$key = {
  readonly " $data"?: duplication_estimationRequestDuplicationPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"duplication_estimationRequestDuplicationPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "duplication_estimationRequestDuplicationPageFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EstimationRequestConnection",
      "kind": "LinkedField",
      "name": "estimationRequests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationRequestEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationRequest",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "requestTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequestDetail",
                  "kind": "LinkedField",
                  "name": "details",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationRequestItem",
                      "kind": "LinkedField",
                      "name": "item",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "specJSON",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "quantity",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequestInternalAssignee",
                  "kind": "LinkedField",
                  "name": "internalAssignees",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserProfile",
                          "kind": "LinkedField",
                          "name": "profile",
                          "plural": true,
                          "selections": [
                            (v3/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequestAssignee",
                  "kind": "LinkedField",
                  "name": "requestAssignees",
                  "plural": true,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SupplierContact",
                      "kind": "LinkedField",
                      "name": "supplierContacts",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SupplierContactMemo",
                          "kind": "LinkedField",
                          "name": "memos",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "body",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cedad369984861b253a6704516629166";

export default node;
