/**
 * @generated SignedSource<<153336f354c964f4b71ef3cbaf2030c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type new_EstimationRequestNewPageQuery$variables = {};
export type new_EstimationRequestNewPageQuery$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly userID: string;
  };
};
export type new_EstimationRequestNewPageQuery = {
  response: new_EstimationRequestNewPageQuery$data;
  variables: new_EstimationRequestNewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CurrentUser",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "new_EstimationRequestNewPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "new_EstimationRequestNewPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "390884142c00a55e0c9c46cda1297361",
    "id": null,
    "metadata": {},
    "name": "new_EstimationRequestNewPageQuery",
    "operationKind": "query",
    "text": "query new_EstimationRequestNewPageQuery {\n  currentUser {\n    userID\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "97256055a7dea80807778404ee566c15";

export default node;
