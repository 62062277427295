import { useOrderDeleteMutation } from 'gql/__generated__/useOrderDeleteMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { toast } from 'lib/toast';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

export const useOrderDelete = () => {
  const versionWatcher = useContext(VersionWatcherContext);
  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useOrderDeleteMutation>(
    graphql`
      mutation useOrderDeleteMutation(
        $id: ID!
      ) {
        deleteOrder(id: $id)
      }
    `,
  );

  const onSubmit = (id: string) => {
    const enableSoftDelete = versionWatcher.disableSoftDeleteEffect();
    commitMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        enableSoftDelete();
        toast({
          title: '発注書を削除しました',
          status: 'success',
        });
        navigate(paths.order.url());
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
