import { Box, Heading } from '@chakra-ui/react';
import { EstimationRequestItemMessageFragment$key } from 'gql/__generated__/EstimationRequestItemMessageFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

const EstimationRequestItemMessageFragment = graphql`
  fragment EstimationRequestItemMessageFragment on EstimationRequest
  {
    specText
  }
`;

export const EstimationRequestItemMessage = ({
  queryRef,
}: { queryRef: EstimationRequestItemMessageFragment$key }) => {
  const { specText } = useFragment(EstimationRequestItemMessageFragment, queryRef);

  if (!specText) return null;

  return (
    <Box my={4} whiteSpace="pre-wrap">
      <Heading as="h3" size="sm" mb={1}>
        その他の条件
      </Heading>
      {specText}
    </Box>
  );
};
