import { Box, Flex, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { InvoiceDetailBillingInfoFragment$key } from 'gql/__generated__/InvoiceDetailBillingInfoFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';
import { buildDetailName } from '../helper';

type Props = {
  queryRef: InvoiceDetailBillingInfoFragment$key;
};

const InvoiceDetailBillingInfoFragment = graphql`
  fragment InvoiceDetailBillingInfoFragment on Invoice
  {
    title
    detailMessage
    internalMemos {
      body
    }
    details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
      edges {
        node {
          id
          invoiceDetailsSales(where: {isValid: true}) {
            edges {
              node {
                salesDate
              }
            }
          }
          item {
            id
            name
            quantity
            unitSellingPrice
            tax: taxCategory {
              rate
            }
          }

          salesOrderDetail {
            id
            branchNumber
            salesOrder {
              id
              transactionID
            }
          }
        }
      }
    }
  }
`;

export const InvoiceDetailBillingInfo = ({ queryRef }: Props) => {
  const { details, detailMessage, internalMemos, title } = useFragment(
    InvoiceDetailBillingInfoFragment,
    queryRef,
  );

  const items = (details.edges || [])
    .map((detail) => {
      if (!detail?.node?.item) return null;
      return {
        fixedDeliveryDate: detail?.node?.invoiceDetailsSales.edges?.at(0)?.node?.salesDate,
        ...detail?.node?.item,
        name: detail.node.salesOrderDetail?.salesOrder.transactionID
          ? buildDetailName({
              name: detail.node.item.name,
              info: {
                transactionId: detail.node.salesOrderDetail.salesOrder.transactionID,
                branchNumber: detail.node.salesOrderDetail.branchNumber,
              },
            })
          : detail.node.item.name,
      };
    })
    .filter((v): v is NonNullable<typeof v> => v != null);

  return (
    <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full" mb={6}>
      <Text fontWeight="bold">{title}</Text>

      <VStack align="start" justify="space-between">
        <Table>
          <Thead>
            <Tr fontSize="xs">
              <Th color="gray.700" minW="14rem" pl="0">
                品名
              </Th>
              <Th color="gray.700" minW="8rem">
                確定納品日
              </Th>
              <Th isNumeric color="gray.700" minW="6rem" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric color="gray.700" minW="6rem" whiteSpace="nowrap">
                単価
              </Th>
              <Th isNumeric color="gray.700" minW="6rem" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) => (
              <Tr key={item.id} fontSize="sm" verticalAlign="top">
                <Td pl="0" whiteSpace="pre-wrap">
                  {item.name}
                </Td>
                <Td whiteSpace="nowrap">
                  {item.fixedDeliveryDate ? dateFormat(item.fixedDeliveryDate) : '-'}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.quantity.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.unitSellingPrice.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700" pr="0">
                  {calculateDetailAmount({
                    quantity: item.quantity,
                    price: item.unitSellingPrice,
                  }).toLocaleString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>

      <HStack pb="1rem" justify="space-between" align="flex-start">
        <VStack spacing={2} w="400px" align="start">
          <VStack spacing={1} align="start">
            <Text fontWeight="bold">備考</Text>
            <Text fontSize="sm" whiteSpace="pre-wrap">
              {detailMessage || '-'}
            </Text>
          </VStack>
          <VStack spacing={1} align="start">
            <Text fontWeight="bold">社内メモ</Text>
            <Text fontSize="sm" whiteSpace="pre-wrap">
              {internalMemos?.at(0)?.body || ''}
            </Text>
          </VStack>
        </VStack>
        <VStack align="left" spacing={2} w="240px">
          <Flex mt={2} justify="space-between">
            <Box w="140px" fontSize="sm">
              小計
            </Box>
            <Box fontSize="sm">{calculateSubtotalAmount(items).toLocaleString()}</Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box whiteSpace="nowrap" w="140px" fontSize="sm">
              消費税
            </Box>
            <Box fontSize="sm">{calculateTotalTaxAmount(items).toLocaleString()}</Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box w="140px" fontWeight="bold">
              合計金額
            </Box>
            <Box fontWeight="bold">{calculateTotalAmount(items).toLocaleString()}</Box>
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  );
};
