import { VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PageHeading } from 'components/PageHeading';
import {
  DemandRecurringCostFormType,
  demandRecurringCostFormSchema,
} from 'features/demandRecurringCost/form';
import { layout_RecurringCostEditLayoutQuery } from 'gql/__generated__/layout_RecurringCostEditLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { DemandRecurringCostIdContext } from './context';

const RecurringCostEditLayoutQuery = graphql`
  query layout_RecurringCostEditLayoutQuery  ($code: String!, $costNumber: Uint32!) {
    companies(where: {code: $code}) {
      edges {
        node {
          id
          name
          demandRecurringCosts(where: {costNumber: $costNumber, isCurrent: true}) {
            edges {
              node {
                id
                supplier {
                  id
                  name
                }
                name
                note
                internalMemos {
                  body
                }
                taxCategoryID
                prices {
                  id
                  unitPrice
                  unitSellingPrice
                  quantity
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DemandRecurringCostEditLayout = () => {
  const { demandCode, costNumber } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  if (!costNumber) {
    throw new MissingRequiredParamError('costNumber');
  }

  const { companies } = useLazyLoadQuery<layout_RecurringCostEditLayoutQuery>(
    RecurringCostEditLayoutQuery,
    { code: demandCode, costNumber: Number(costNumber) },
    { fetchPolicy: 'network-only' },
  );

  const company = companies?.edges?.[0]?.node;
  const demandRecurringCost = company?.demandRecurringCosts?.edges?.[0]?.node;

  const form = useForm<DemandRecurringCostFormType>({
    resolver: zodResolver(demandRecurringCostFormSchema),
    defaultValues: {
      supplier: {
        id: demandRecurringCost?.supplier?.id ?? '',
        name: demandRecurringCost?.supplier?.name ?? '',
      },
      name: demandRecurringCost?.name ?? '',
      note: demandRecurringCost?.note ?? '',
      memo: demandRecurringCost?.internalMemos?.[0]?.body ?? '',
      taxId: demandRecurringCost?.taxCategoryID ?? '',
      prices:
        demandRecurringCost?.prices?.map((price) => ({
          id: price.id,
          quantity: price.quantity,
          unitPrice: price.unitPrice,
          unitSellingPrice: price.unitSellingPrice,
        })) ?? [],
    },
  });

  if (
    !companies ||
    !companies.edges ||
    companies.edges.length === 0 ||
    !companies.edges[0]?.node ||
    companies.edges[0]?.node === null
  )
    return null;

  if (!demandRecurringCost) return null;

  const demand = companies.edges[0].node;

  return (
    <VStack spacing={6} alignItems="flex-start">
      <PageHeading label={`デマンド：${demand.name}`} />

      <DemandRecurringCostIdContext.Provider value={demandRecurringCost.id}>
        <FormProvider {...form}>
          <Outlet />
        </FormProvider>
      </DemandRecurringCostIdContext.Provider>
    </VStack>
  );
};
