import Excel from 'exceljs';
import { EstimationRequestFormState } from 'features/estimationRequest/hooks/useEstimationRequestForm';
import { duplication_estimationRequestDuplicationPageFragment$data } from 'gql/__generated__/duplication_estimationRequestDuplicationPageFragment.graphql';
import { extractItemFromDetail } from 'utils/detail';
import { concatFullName } from 'utils/label';
import { ResponsesType } from '../type';

export const toFormState = (
  queryData: duplication_estimationRequestDuplicationPageFragment$data,
) => {
  const estimationRequest = (queryData.estimationRequests?.edges || [])[0]?.node;

  if (!estimationRequest) {
    return null;
  }

  const items = estimationRequest?.details?.map((detail) => {
    const item = extractItemFromDetail(detail);

    let specJson = new Map<string, Excel.CellValue>();
    if (item.specJSON) {
      specJson = new Map<string, Excel.CellValue>(Object.entries(item.specJSON));
    }
    return {
      quantity: item.quantity,
      name: item.name,
      specJson: specJson,
    };
  }) || [
    {
      name: '',
      specJson: new Map<string, Excel.CellValue>(),
      quantity: 0,
    },
  ];

  const internalAssignees =
    estimationRequest?.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  const dt = new DataTransfer();

  return {
    company: {
      id: estimationRequest?.company.id,
      name: estimationRequest?.company.name,
    },
    requestTitle: estimationRequest?.requestTitle,
    mailSubject: '',
    message: '',
    specText: estimationRequest?.specText,
    items: [...items],
    suppliers: estimationRequest?.requestAssignees?.map((assignee) => ({
      id: assignee.supplier.id,
      name: assignee.supplier.name,
      contacts: assignee.supplierContacts?.map((contact) => ({
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        memo: contact.memos && contact.memos.length > 0 ? contact.memos[0].body : undefined,
      })),
      headerMessage: '',
      footerMessage: '',
    })),
    attachments: dt.files,
    internalAssignees,
  } as EstimationRequestFormState;
};

export const getLatestEstimationResponse = (responses: ResponsesType | null) => {
  return responses?.at(-1);
};

export const hasEstimationResponse = (responses: ResponsesType | null) => {
  return !!responses && responses.length > 0;
};
