import { PartialMessage, Struct } from '@bufbuild/protobuf';
import { Box, VStack } from '@chakra-ui/react';
import { ItemTable } from 'features/item/components/ItemTable';
import { EstimationResponseItemTableFragment$key } from 'gql/__generated__/EstimationResponseItemTableFragment.graphql';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { RawItem } from 'proto/model/item/v1/summarize_item_pb';
import { ItemService } from 'proto/service/item/v1/item_connect';
import { SummarizeItemsResponse } from 'proto/service/item/v1/item_pb';
import { useEffect, useState } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { extractItemFromDetail } from 'utils/detail';

const EstimationResponseItemTableFragment = graphql`
  fragment EstimationResponseItemTableFragment on EstimationResponse
  {
    details {
      type
      item {
        name
        specJSON
        quantity
        unitPrice
      }
    }
  }
`;

export const EstimationResponseItemTable = ({
  queryRef,
}: { queryRef: EstimationResponseItemTableFragment$key }) => {
  const { details } = useFragment(EstimationResponseItemTableFragment, queryRef);

  const { grpcClient, authorized } = useGrpcClient(ItemService);
  const [summarizedItems, setSummarizedItems] = useState<PartialMessage<SummarizeItemsResponse>>({
    items: [],
  });

  useEffect(() => {
    (async () => {
      if (!authorized) return;

      let unmounted = false;
      if (details === null || details.length === 0) return;

      const rawItems: PartialMessage<RawItem>[] = [];

      details.forEach((d) => {
        const item = extractItemFromDetail(d);

        rawItems.push({
          name: item.name,
          quantity: item.quantity,
          specJson: Struct.fromJson(item.specJSON),
          unitPrice: item.unitPrice,
        });
      });

      const summary = await grpcClient.summarizeItems({ items: rawItems });

      if (unmounted) return;
      setSummarizedItems(summary);

      return () => {
        unmounted = true;
      };
    })();
  }, [details, authorized, grpcClient]);

  return (
    <VStack spacing={8} align="start" w="100%">
      {(summarizedItems.items || []).map((item, index) => (
        <Box key={item.category}>
          <ItemTable
            key={`item-table-${index}`}
            readonly
            items={summarizedItems.items || []}
            index={index}
          />
        </Box>
      ))}
    </VStack>
  );
};
