import BigNumber from 'bignumber.js';

/**
 * 金額計算用の型
 * @field {string | number} quantity - 数量
 * @field {string | number} unitPrice - 粗利抜きの単価
 * @field {string | number} unitSellingPrice - 粗利込みの単亜（顧客単価）
 * @field {string | number} tax.rate - 消費税率。10%なら10.0が格納されている
 */

type CalculateTotalType<T extends 'unitPrice' | 'unitSellingPrice'> = {
  quantity: string | number;
  tax: {
    rate: string | number;
  };
} & {
  [K in T]: string | number;
};

type CalculateTotalTypeWithoutTax<T extends 'unitPrice' | 'unitSellingPrice'> = Omit<
  CalculateTotalType<T>,
  'tax'
>;

/**
 * 粗利単価を計算する
 * @summary アイテム単価 × 粗利率。端数処理は四捨五入で行う
 * @returns {number} - 粗利単価
 */
export const calculateGrossProfitUnitPrice = ({
  grossProfitMargin,
  unitPrice,
}: {
  unitPrice: string | number;
  grossProfitMargin: string | number;
}): number => {
  if (!grossProfitMargin || !unitPrice) return 0;
  const number = BigNumber(unitPrice)
    .times(grossProfitMargin)
    .dp(2, BigNumber.ROUND_HALF_UP)
    .toNumber();
  return number;
};

/**
 * 粗利率の計算する
 * @summary （(顧客単価 - 仕入単価)/ 顧客単価）× 100。小数点第二位を四捨五入。
 * @returns {number} - 粗利率
 */
export const calculateGrossProfitMargin = (unitPrice: number, unitSellingPrice: number): number => {
  if (unitPrice <= 0 || unitSellingPrice <= 0) return 0;

  const profitAmount = BigNumber(unitSellingPrice).minus(unitPrice);
  const grossProfitMargin = BigNumber(BigNumber(profitAmount).div(unitSellingPrice))
    .times(100)
    .toNumber();

  return BigNumber(grossProfitMargin).dp(1, BigNumber.ROUND_HALF_UP).toNumber();
};

/**
 * アイテムの小計
 * @summary アイテム数 × (粗利込みの単価（顧客単価）OR 粗利込みじゃない単価)
 * @returns {number} - アイテムの小計
 */
export const calculateDetailAmount = ({
  quantity,
  price,
}: { quantity: string | number; price: string | number }): number => {
  if (!quantity || !price) return 0;
  const number = BigNumber(quantity).times(price).toNumber();
  return BigNumber(number).dp(0, BigNumber.ROUND_HALF_UP).toNumber();
};

/**
 * 小計を算出する
 * @summary 各アイテムの小計を合計する
 * @returns {number} - 小計
 */
export const calculateSubtotalAmount = (
  details: CalculateTotalTypeWithoutTax<'unitSellingPrice'>[],
): number => {
  const total = details.reduce((sum, detail) => {
    return BigNumber(sum)
      .plus(
        calculateDetailAmount({
          quantity: detail.quantity,
          price: detail.unitSellingPrice,
        }),
      )
      .toNumber();
  }, 0);

  return BigNumber(total).toNumber();
};

/**
 * 粗利の合計金額を算出する
 * @summary 粗利込みの小計 - 粗利抜きの小計
 * @returns {number} - 粗利の合計金額
 */
export const calculateTotalGrossProfitAmount = (
  details: {
    quantity: string | number;
    unitPrice: string | number;
    unitSellingPrice: string | number;
  }[],
): number => {
  // 粗利込みの小計
  const subtotalPriceWithGrossProfitMargin = calculateSubtotalAmount(details);
  // 粗利抜きの小計
  const subtotalPriceWithoutGrossProfitMargin = calculateNetSubtotalAmount(details);

  return subtotalPriceWithGrossProfitMargin - subtotalPriceWithoutGrossProfitMargin;
};

/**
 * 合計金額と粗利の合計金額から粗利率を算出する
 * @summary 粗利の合計金額 / 合計金額 × 100。小数点第二位を四捨五入。
 * @returns {number} - 粗利率
 */
export const calculateTotalGrossProfitMargin = (
  details: {
    quantity: string | number;
    unitPrice: string | number;
    unitSellingPrice: string | number;
  }[],
): number => {
  const totalPrice = details.reduce((sum, detail) => {
    return BigNumber(sum)
      .plus(
        calculateDetailAmount({
          quantity: detail.quantity,
          price: detail.unitPrice,
        }),
      )
      .toNumber();
  }, 0);

  const totalSellingPrice = details.reduce((sum, detail) => {
    return BigNumber(sum)
      .plus(
        calculateDetailAmount({
          quantity: detail.quantity,
          price: detail.unitSellingPrice,
        }),
      )
      .toNumber();
  }, 0);

  if (totalPrice <= 0 || totalSellingPrice <= 0) {
    return 0;
  }

  return calculateGrossProfitMargin(totalPrice, totalSellingPrice);
};

/**
 * 消費税の合計金額を算出する
 * @summary 各アイテムの消費税の合計金額を算出する。端数処理は切り捨てで税率毎に行う
 * @returns {number} - 消費税の合計金額
 */
export const calculateTotalTaxAmount = (
  details: CalculateTotalType<'unitSellingPrice'>[],
): number => {
  // 税率毎にアイテムを分ける
  const detailsByTaxRates: {
    [key: string]: CalculateTotalType<'unitSellingPrice'>[];
  } = {};
  details.forEach((detail) => {
    const { rate } = detail.tax;
    if (!detailsByTaxRates[rate]) {
      detailsByTaxRates[rate] = [];
    }
    detailsByTaxRates[rate].push(detail);
  });

  const total = Object.values(detailsByTaxRates).reduce((sum, details) => {
    const totalTaxAmount = details
      .reduce((sum, detail) => {
        return sum.plus(
          calculateTaxAmount({
            quantity: detail.quantity,
            unitSellingPrice: detail.unitSellingPrice,
            taxRate: detail.tax.rate,
          }),
        );
      }, BigNumber(0))
      .dp(0, BigNumber.ROUND_DOWN); // 税率毎に端数処理を行う
    return sum.plus(totalTaxAmount);
  }, BigNumber(0));

  return total.toNumber();
};

/**
 * 合計金額を算出する
 * @summary 小計 + 消費税の合計金額
 * @returns {number} - 合計金額
 */
export const calculateTotalAmount = (details: CalculateTotalType<'unitSellingPrice'>[]) =>
  BigNumber(calculateSubtotalAmount(details))
    .plus(calculateTotalTaxAmount(details))
    .dp(0, BigNumber.ROUND_DOWN)
    .toNumber();

// #####################################
// ############## private ##############
// #####################################

/**
 * アイテム毎の消費税の金額を算出する
 * @summary アイテム単価 ×（rate × 0.01）
 * @returns {number} - 消費税の金額
 */
const calculateTaxAmount = ({
  quantity,
  unitSellingPrice,
  taxRate,
}: {
  quantity: string | number;
  unitSellingPrice: string | number;
  taxRate: string | number;
}): number => {
  if (!quantity || !unitSellingPrice) return 0;
  const tax = BigNumber(taxRate).times(0.01).toNumber();
  const number = BigNumber(calculateDetailAmount({ quantity, price: unitSellingPrice }))
    .times(tax)
    .toNumber();
  return number;
};

/**
 * 粗利抜きの小計を算出する
 * @summary 各アイテムの粗利抜き単価の小計を合計する
 * @returns {number} - 小計
 */
const calculateNetSubtotalAmount = (
  details: CalculateTotalTypeWithoutTax<'unitPrice'>[],
): number => {
  const total = details.reduce((sum, detail) => {
    return BigNumber(sum)
      .plus(
        calculateDetailAmount({
          quantity: detail.quantity,
          price: detail.unitPrice,
        }),
      )
      .toNumber();
  }, 0);

  return BigNumber(total).toNumber();
};
