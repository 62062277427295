import { InitializeSetup } from 'components/initialize';
import { Layout } from 'components/layout/Layout';
import { useRegularlyRefreshFirebaseToken } from 'hooks/useRegularlyRefreshFirebaseToken';
import { useSession } from 'hooks/useSession';
import { useUserTracking } from 'hooks/useUserTracking';
import { Navigate, Outlet } from 'react-router-dom';
import { paths } from 'utils/paths';

export function AuthProvider() {
  const { isLoading, currentUser } = useSession();
  useRegularlyRefreshFirebaseToken();
  useUserTracking();

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to={paths.login.url()} />;
  }

  return (
    <Layout>
      <>
        <Outlet />
        <InitializeSetup />
      </>
    </Layout>
  );
}
