/**
 * @generated SignedSource<<dd3954b95963d78fce74391bb59f66c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DemandRecurringCostUpdateInput = {
  endedAt?: any | null;
  internalMemo?: string | null;
  name: string;
  note: string;
  prices: ReadonlyArray<DemandRecurringCostPriceInput>;
  startedAt?: any | null;
  supplierID?: string | null;
  taxCategoryID: string;
};
export type DemandRecurringCostPriceInput = {
  quantity: number;
  unitPrice: number;
  unitSellingPrice: number;
};
export type confirm_RecurringCostEditConfirmPageMutation$variables = {
  id: string;
  input: DemandRecurringCostUpdateInput;
};
export type confirm_RecurringCostEditConfirmPageMutation$data = {
  readonly updateDemandRecurringCost: {
    readonly id: string;
  };
};
export type confirm_RecurringCostEditConfirmPageMutation = {
  response: confirm_RecurringCostEditConfirmPageMutation$data;
  variables: confirm_RecurringCostEditConfirmPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DemandRecurringCost",
    "kind": "LinkedField",
    "name": "updateDemandRecurringCost",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_RecurringCostEditConfirmPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirm_RecurringCostEditConfirmPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "12354680cdbd933461976a7062952b66",
    "id": null,
    "metadata": {},
    "name": "confirm_RecurringCostEditConfirmPageMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_RecurringCostEditConfirmPageMutation(\n  $id: ID!\n  $input: DemandRecurringCostUpdateInput!\n) {\n  updateDemandRecurringCost(id: $id, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf94ce200bc225f77ce564b19061cca4";

export default node;
