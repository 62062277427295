import { useContext } from 'react';
import { QueryParamsContext } from '../components/provider/SearchQueryProvider';

export const useQueryParams = () => {
  const context = useContext(QueryParamsContext);
  if (!context) {
    throw new Error('useQueryParams must be used within a QueryParamsProvider');
  }
  return context;
};
