import { DemandItemFormType } from 'features/demandItem/form';
import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { KeepVersionContext, PricesMemoContext } from '../context';

type NestedObject = {
  [key: string]: boolean | NestedObject | NestedObject[] | boolean[];
};

function getTruePropertyNames(data: NestedObject): string[] {
  const result: string[] = [];

  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'boolean') {
      if (value === true) {
        result.push(key);
      }
    } else if (Array.isArray(value)) {
      // 配列の場合、中にtrueが含まれるかチェック
      if (value.some((item) => isTrueInObject(item))) {
        result.push(key);
      }
    } else if (isTrueInObject(value)) {
      // ネストされたオブジェクトの場合
      result.push(key);
    }
  }

  return result;
}

function isTrueInObject(obj: boolean | NestedObject | NestedObject[] | boolean[]): boolean {
  if (typeof obj === 'boolean') {
    return obj === true;
  }
  if (Array.isArray(obj)) {
    return obj.some(isTrueInObject);
  }
  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).some(isTrueInObject);
  }
  return false;
}

const versionCheckFields = ['name', 'supplier', 'category', 'taxId'];

export const useVersionCheck = () => {
  const keepVersionContext = useContext(KeepVersionContext);
  if (!keepVersionContext) {
    throw new Error('KeepVersionContext is null');
  }
  const { setKeepVersion } = keepVersionContext;

  const pricesMemoContext = useContext(PricesMemoContext);
  if (!pricesMemoContext) {
    throw new Error('PricesMemoContext is null');
  }
  const pricesMemo = pricesMemoContext;

  const form = useFormContext<DemandItemFormType>();
  const dirtyFields = form.formState.dirtyFields;

  const hasVersionCheckField = () => {
    // `useFieldArray`で行追加をするとすべての項目がdirtyFieldsに入る(変更されていない項目はfalse)
    // そのため、trueの項目のkeyを取得する。ネストされているオブジェクトは第一階層目を取得
    const dirtyFieldKeys = getTruePropertyNames(dirtyFields);

    if (dirtyFieldKeys.length === 0) return false;

    if (dirtyFieldKeys.some((key) => versionCheckFields.includes(key))) {
      return true;
    }

    // specはカテゴリーよって異なるため個別にチェック
    const category = form.getValues('category');
    if (
      category === DEMAND_ITEM_CATEGORY.Cardboard &&
      dirtyFieldKeys.includes(DEMAND_ITEM_CATEGORY.Cardboard)
    ) {
      return true;
    }
    if (
      category === DEMAND_ITEM_CATEGORY.FlexiblePackage &&
      dirtyFieldKeys.includes(DEMAND_ITEM_CATEGORY.FlexiblePackage)
    ) {
      return true;
    }
    if (
      category === DEMAND_ITEM_CATEGORY.GiftBox &&
      dirtyFieldKeys.includes(DEMAND_ITEM_CATEGORY.GiftBox)
    ) {
      return true;
    }
    if (
      category === DEMAND_ITEM_CATEGORY.PaperBag &&
      dirtyFieldKeys.includes(DEMAND_ITEM_CATEGORY.PaperBag)
    ) {
      return true;
    }
    if (
      category === DEMAND_ITEM_CATEGORY.Other &&
      dirtyFieldKeys.includes(DEMAND_ITEM_CATEGORY.Other)
    ) {
      return true;
    }

    // `useFieldArray`を使用している部分はdirtyFieldの挙動が怪しいため自前でチェック
    const prices = form.getValues('prices');

    // 単価表の数が変更されていれば変更が入っていると判断
    if (prices.length !== pricesMemo.size) return true;

    // 変更前にMemoした価格と変更後の価格をチェック
    // 1つでも変更があればtrueを返す
    const result = prices.some((price) => {
      if (pricesMemo.has(price.id)) {
        const memoPrice = pricesMemo.get(price.id);
        if (
          memoPrice?.quantity !== Number(price.quantity) ||
          memoPrice?.unitPrice !== Number(price.unitPrice) ||
          memoPrice?.unitSellingPrice !== Number(price.unitSellingPrice)
        ) {
          return true;
        }
        // idが空の場合は行追加した時なので新規追加と判断
      } else if (price.id === '') {
        return true;
      }

      return false;
    });

    if (result) return true;

    return false;
  };

  const updateVersion = () => setKeepVersion(false);

  const keepVersion = () => setKeepVersion(true);

  return { hasVersionCheckField, updateVersion, keepVersion };
};
