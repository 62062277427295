import { EstimationRequestConfirm } from 'features/estimationRequest/components/EstimationRequestConfirm';
import { EstimationRequestForm } from 'features/estimationRequest/components/EstimationRequestForm';
import {
  initialEstimationRequestFormState,
  useEstimationRequestForm,
} from 'features/estimationRequest/hooks/useEstimationRequestForm';
import { toFormState } from 'features/estimationRequest/utils';
import { duplication_estimationRequestDuplicationPageFragment$key } from 'gql/__generated__/duplication_estimationRequestDuplicationPageFragment.graphql';
import { duplication_estimationRequestDuplicationPageQuery } from 'gql/__generated__/duplication_estimationRequestDuplicationPageQuery.graphql';
import { Suspense, useEffect, useState } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const EstimationRequestDuplicationPageQuery = graphql`
  query duplication_estimationRequestDuplicationPageQuery ($id: ID!) {
    ...duplication_estimationRequestDuplicationPageFragment @arguments(id: $id)
  }
`;

const EstimationRequestDuplicationPageFragment = graphql`
  fragment duplication_estimationRequestDuplicationPageFragment on Query
    @argumentDefinitions (
      id: { type: "ID" defaultValue: null }
    )
    {
    estimationRequests(where: {id: $id}) {
      edges {
        node {
          id
          requestTitle
          specText
          company {
            id
            name
          }
          details {
            type
            item {
              name
              specJSON
              quantity
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
          requestAssignees {
            supplier {
              id
              name
            }
            supplierContacts {
              id
              firstName
              lastName
              email
              memos {
                body
              }
              supplier {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export function EstimationRequestDuplicationPage() {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const query = useLazyLoadQuery<duplication_estimationRequestDuplicationPageQuery>(
    EstimationRequestDuplicationPageQuery,
    { id },
    { fetchPolicy: 'network-only' },
  );
  const result = useFragment(
    EstimationRequestDuplicationPageFragment,
    query as duplication_estimationRequestDuplicationPageFragment$key,
  );

  const [duplicationState, setDuplicationState] = useState(toFormState(result));
  useEffect(() => {
    setDuplicationState(toFormState(result));
  }, [result]);

  const { data, showPreview, onClickToConfirm, onSubmit, isMutationInFlight, onClickToNewForm } =
    useEstimationRequestForm(duplicationState || initialEstimationRequestFormState);

  if (!data) return null;

  return (
    <>
      <Suspense fallback={<div>loading...</div>}>
        {showPreview ? (
          <EstimationRequestConfirm
            onClickSubmit={() => {
              onSubmit(data);
            }}
            values={{
              mailSubject: data.mailSubject,
              specText: data.specText,
              suppliers: data.suppliers,
              attachments: Array.from(data.attachments).map((a) => ({
                name: a.name,
                size: a.size,
              })),
              items: data.items.map((item) => ({ ...item, quantity: Number(item.quantity) || 0 })),
              internalAssignees: data.internalAssignees || [],
            }}
            isMutationInFlight={isMutationInFlight}
            onClickToForm={onClickToNewForm}
          />
        ) : (
          <EstimationRequestForm
            onClickToConfirm={onClickToConfirm}
            data={data}
            previousUrl={paths.estimationRequest._id(id)}
          />
        )}
      </Suspense>
    </>
  );
}
