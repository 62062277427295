/**
 * @generated SignedSource<<7850e2ac2d19d2da6d01180c6d9ef922>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesOrderDetailFragment$data = {
  readonly detailMessage: string | null;
  readonly details: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly branchNumber: any;
        readonly deliveryEvents: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fixedDeliveryDate: any;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly quantity: any;
          readonly taxCategory: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        } | null;
        readonly notionPage: {
          readonly notionPageURL: string;
        } | null;
      } | null;
    } | null> | null;
  };
  readonly internalMemos: ReadonlyArray<{
    readonly body: string;
  }> | null;
  readonly title: string;
  readonly transactionID: any;
  readonly " $fragmentType": "SalesOrderDetailFragment";
};
export type SalesOrderDetailFragment$key = {
  readonly " $data"?: SalesOrderDetailFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesOrderDetailFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrderDetailFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrderInternalMemo",
      "kind": "LinkedField",
      "name": "internalMemos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detailMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "ORDER_NUMBER"
          }
        }
      ],
      "concreteType": "SalesOrderDetailConnection",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderDetailEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderDetail",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "branchNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "isValid": true
                      }
                    }
                  ],
                  "concreteType": "SalesOrderDetailDeliveryEventConnection",
                  "kind": "LinkedField",
                  "name": "deliveryEvents",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderDetailDeliveryEventEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderDetailDeliveryEvent",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fixedDeliveryDate",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderItem",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitSellingPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaxCategory",
                      "kind": "LinkedField",
                      "name": "taxCategory",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderDetailNotionPage",
                  "kind": "LinkedField",
                  "name": "notionPage",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notionPageURL",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};
})();

(node as any).hash = "fba05e96e18d3d7928a6f0fc395de883";

export default node;
