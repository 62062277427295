import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';
import { z } from 'zod';
import { DOCUMENT_DISPLAY_STATUS } from '../type';

export const estimationDetailFormSchema = z.object({
  responseItemId: z.string().optional(),
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) }),
  unitPrice: unitPriceSchema(),
  quantity: quantitySchema(),
  unitSellingPrice: unitSellingPriceSchema,
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
  }),
  documentDisplayStatus: z.enum([DOCUMENT_DISPLAY_STATUS.shown, DOCUMENT_DISPLAY_STATUS.hidden]),
});
export const estimationFormSchema = z.object({
  company: z.object({
    id: z.string(),
    name: z.string(),
  }),
  supplier: z.object({
    id: z.string(),
    name: z.string(),
  }),
  title: z.string().max(70, { message: errorMessages.MAX_LENGTH(70) }),
  detailMessage: z.string().max(2000, { message: errorMessages.MAX_LENGTH(2000) }),
  expirationPeriod: z.string().max(30, { message: errorMessages.MAX_LENGTH(30) }),
  estimationResponseID: z.string().optional(),
  details: z.array(estimationDetailFormSchema),
  showAmountSummary: z.boolean(),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
});

export const grossMarginRatioSchema = z.object({
  grossMarginRatio: z
    .number({ invalid_type_error: errorMessages.GROSS_MARGIN_RATIO })
    .min(1, {
      message: errorMessages.GROSS_MARGIN_RATIO,
    })
    .multipleOf(0.1, {
      message: errorMessages.GROSS_MARGIN_RATIO,
    })
    .or(z.any().refine(Number.isNaN)),
});

export type ZodEstimationDetail = z.infer<typeof estimationDetailFormSchema>;

export type ZodEstimationFormData = z.infer<typeof estimationFormSchema>;

export type ZodGrossMarginRatio = z.infer<typeof grossMarginRatioSchema>;
