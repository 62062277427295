import { Box, Button } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EstimationRequestPreview, PreviewProps } from '../EstimationRequestPreview';

type EstimationRequestConfirmState = PreviewProps;

export function EstimationRequestConfirm({
  values,
  onClickSubmit,
  onClickToForm,
  isMutationInFlight,
}: {
  values: EstimationRequestConfirmState;
  onClickSubmit: () => void;
  isMutationInFlight: boolean;
  onClickToForm: () => void;
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({});

  const onSubmit = () => {
    onClickSubmit();
  };

  const s = handleSubmit(onSubmit);

  return (
    <>
      <Box mb={6}>
        <PageBack onClick={onClickToForm} />
      </Box>
      <Box mb={6}>
        <SubHeading label="内容の確認" />
      </Box>

      <form onSubmit={s}>
        <EstimationRequestPreview {...values}>
          <Button
            colorScheme="blue"
            w="full"
            type="submit"
            isLoading={isMutationInFlight}
            isDisabled={isSubmitting}
          >
            送信
          </Button>
        </EstimationRequestPreview>
      </form>
    </>
  );
}
