import { VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PageHeading } from 'components/PageHeading';
import {
  DemandRecurringCostFormType,
  demandRecurringCostFormSchema,
} from 'features/demandRecurringCost/form';
import { layout_RecurringCostCreateLayoutQuery } from 'gql/__generated__/layout_RecurringCostCreateLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { DemandIdContext } from './context';

const RecurringCostCreateLayoutQuery = graphql`
  query layout_RecurringCostCreateLayoutQuery  ($code: String!) {
    companies(where: {code: $code}) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const DemandRecurringCostCreateLayout = () => {
  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { companies } = useLazyLoadQuery<layout_RecurringCostCreateLayoutQuery>(
    RecurringCostCreateLayoutQuery,
    { code: demandCode },
    { fetchPolicy: 'network-only' },
  );

  const form = useForm<DemandRecurringCostFormType>({
    resolver: zodResolver(demandRecurringCostFormSchema),
    defaultValues: {
      prices: [{ id: '', quantity: '', unitPrice: '', unitSellingPrice: '' }],
    },
  });

  if (
    !companies ||
    !companies.edges ||
    companies.edges.length === 0 ||
    !companies.edges[0]?.node ||
    companies.edges[0]?.node === null
  )
    return null;

  const demand = companies.edges[0].node;

  return (
    <VStack spacing={6} alignItems="flex-start">
      <PageHeading label={`デマンド：${demand.name}`} />

      <DemandIdContext.Provider value={demand.id}>
        <FormProvider {...form}>
          <Outlet />
        </FormProvider>
      </DemandIdContext.Provider>
    </VStack>
  );
};
