import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddOne, CloseSmall } from '@icon-park/react';
import { AttentionDomainModal } from 'components/AttentionDomainModal';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { ErrorMessage } from 'components/ErrorMessage';
import { SupplierFormDataType, supplierFormSchema } from 'features/supplier/zod';
import { useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

type FormType = 'new' | 'edit';

const getSubmitButtonLabel = (type: FormType) => {
  switch (type) {
    case 'new':
      return '追加';
    case 'edit':
      return '更新';
  }
};

const extractDomain = (email: string): string => email.split('@')[1];

const checkAllSameDomain = (emails: string[]): boolean => {
  if (emails.length === 0) return true;

  const firstDomain = extractDomain(emails[0]);
  for (const email of emails.slice(1)) {
    if (extractDomain(email) !== firstDomain) {
      return false;
    }
  }

  return true;
};

type Props = {
  type?: FormType;
  onSubmit: (data: SupplierFormDataType) => void;
  isMutationInFlight: boolean;
  value?: SupplierFormDataType;
};

export const SupplierForm = ({
  type = 'new',
  onSubmit,
  isMutationInFlight,
  value = {
    name: '',
    contacts: [
      {
        id: null,
        lastName: '',
        firstName: '',
        email: '',
        memo: '',
      },
    ],
  },
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    getValues,
    control,
    formState: { errors },
    handleSubmit: hookFormSubmit,
  } = useForm<SupplierFormDataType>({
    resolver: zodResolver(supplierFormSchema),
    defaultValues: value,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const addContact = () => {
    append({
      id: null,
      lastName: '',
      firstName: '',
      email: '',
    });
  };

  const isEdit = useMemo(() => type === 'edit', [type]);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const registeredContactIds = useMemo(
    () =>
      fields
        .filter((_, i) => {
          return value.contacts[i].id !== null;
        })
        .map((field) => field.id),
    [],
  );

  const handleSubmit = (data: SupplierFormDataType) => {
    const emails = getValues('contacts').map((contact) => contact.email);
    const isAllSameDomain = checkAllSameDomain(emails);
    if (isAllSameDomain) {
      setOpenModal(false);
      onSubmit(data);
    } else {
      setOpenModal(true);
    }
  };

  const handleSubmitWithModal = () => {
    setOpenModal(false);
    onSubmit(getValues());
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <form onSubmit={hookFormSubmit(handleSubmit)}>
      <VStack spacing={8} alignItems="flex-start">
        <Box w="full">
          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>企業名</FormLabel>
            <Input
              type="string"
              {...register('name')}
              disabled={isEdit}
              data-testid="supplier-form-supplier-name-input"
            />
            <ErrorMessage name="name" errors={errors} />
          </FormControl>
        </Box>

        {fields.map((field, index) => {
          return (
            <VStack
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="6px"
              p={4}
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={4}
              key={field.id}
              w="full"
            >
              <HStack justifyContent="space-between" spacing={0}>
                <FormLabel fontSize="xl">担当者{index + 1}</FormLabel>
                <ConfirmModalButton
                  header={`担当者${index + 1}を削除しますか？`}
                  button={({ onOpen }) => (
                    <Button
                      bgColor="gray.100"
                      borderRadius={100}
                      w={8}
                      minW={8}
                      maxW={8}
                      h={8}
                      minH={8}
                      maxH={8}
                      onClick={onOpen}
                      isDisabled={isEdit && registeredContactIds.includes(field.id)}
                      data-testid={`supplier-form-contact-delete-icon-button-${index}`}
                    >
                      <Icon as={CloseSmall} w={4} h={4} />
                    </Button>
                  )}
                  footer={({ onClose }) => (
                    <HStack spacing={3}>
                      <Button
                        onClick={onClose}
                        data-testid={`supplier-form-contact-delete-cancel-button-${index}`}
                      >
                        キャンセル
                      </Button>
                      <Button
                        colorScheme="red"
                        ml={3}
                        onClick={() => {
                          onClose();
                          remove(index);
                        }}
                        data-testid={`supplier-form-contact-delete-button-${index}`}
                      >
                        削除
                      </Button>
                    </HStack>
                  )}
                />
              </HStack>
              <HStack justifyContent="space-between" spacing={2}>
                <FormControl isInvalid={!!(errors.contacts || [])[index]?.lastName} isRequired>
                  <FormLabel>姓</FormLabel>
                  <Input
                    type="string"
                    {...register(`contacts.${index}.lastName`)}
                    data-testid={`supplier-form-lastname-input-${index}`}
                  />
                  <ErrorMessage name={`contacts.${index}.lastName`} errors={errors} />
                </FormControl>
                <FormControl isInvalid={!!(errors.contacts || [])[index]?.firstName}>
                  <FormLabel>名</FormLabel>
                  <Input
                    type="string"
                    {...register(`contacts.${index}.firstName`)}
                    data-testid={`supplier-form-firstname-input-${index}`}
                  />
                  <ErrorMessage name={`contacts.${index}.firstName`} errors={errors} />
                </FormControl>
              </HStack>
              <FormControl isInvalid={!!(errors.contacts || [])[index]?.email} isRequired>
                <FormLabel>メールアドレス</FormLabel>
                <Input
                  type="email"
                  {...register(`contacts.${index}.email`)}
                  disabled={isEdit && registeredContactIds.includes(field.id)}
                  data-testid={`supplier-form-email-input-${index}`}
                />
                <ErrorMessage name={`contacts.${index}.email`} errors={errors} />
              </FormControl>
              <FormControl isInvalid={!!(errors.contacts || [])[index]?.memo}>
                <FormLabel>メモ</FormLabel>
                <Textarea
                  {...register(`contacts.${index}.memo`)}
                  data-testid={`supplier-form-memo-input-${index}`}
                />
                <ErrorMessage name={`contacts.${index}.memo`} errors={errors} />
              </FormControl>
            </VStack>
          );
        })}

        <Button
          w="full"
          colorScheme="gray"
          leftIcon={<AddOne />}
          onClick={addContact}
          data-testid="supplier-form-add-contact-button"
        >
          追加
        </Button>

        <Box w="full">
          <Button
            colorScheme="blue"
            w="full"
            type="submit"
            isDisabled={isMutationInFlight}
            data-testid="supplier-form-submit-button"
          >
            {getSubmitButtonLabel(type)}
          </Button>
        </Box>
      </VStack>
      <AttentionDomainModal
        isOpen={openModal}
        onClickSubmit={handleSubmitWithModal}
        onClose={handleCloseModal}
        isMutationInFlight={isMutationInFlight}
      />
    </form>
  );
};
