import { Box, Button, HStack, Select, Text } from '@chakra-ui/react';
import { CheckCorrect } from '@icon-park/react';
import { cdate } from 'cdate';
import { InvoiceSalesOrdersSelectFormFragment$data } from 'gql/__generated__/InvoiceSalesOrdersSelectFormFragment.graphql';
import { ChangeEvent, useState } from 'react';
import { cdateJST } from 'utils/date';

type Props = {
  salesOrders: NonNullable<
    NonNullable<InvoiceSalesOrdersSelectFormFragment$data['salesOrders']>['edges']
  >;
  onClickAllSelect: (values: string[]) => void;
  onClickClearSelections: () => void;
};

const selectDateLabels = (
  salesOrders: NonNullable<
    NonNullable<InvoiceSalesOrdersSelectFormFragment$data['salesOrders']>['edges']
  >,
) => {
  const labels = salesOrders
    ?.flatMap((salesOrder) =>
      salesOrder?.node?.details?.edges?.map(
        (detail) => detail?.node?.deliveryEvents?.edges?.at(0)?.node?.fixedDeliveryDate,
      ),
    )
    .sort((a, b) => (a > b ? -1 : 1))
    .filter((v): v is NonNullable<typeof v> => v != null)
    .map((dt) => {
      const year = cdate(dt).get('y');
      const month = cdate(dt).get('M') + 1;
      return {
        [`${cdateJST(dt).locale('ja').format('YYYY-MM')}`]: `${year}年${month}月納品分`,
      };
    });

  return Array.from(new Map(labels.map((label) => [Object.keys(label).at(0), label])).values());
};

export const SelectPeriodSalesOrdersForm = ({
  salesOrders,
  onClickAllSelect,
  onClickClearSelections,
}: Props) => {
  const labels = selectDateLabels(salesOrders);
  const [selectedValue, setSelectedValue] = useState<string>(
    Object.keys(Array.from(labels).at(0) || {}).at(0) || '',
  );

  const handleChangeSelect = (e: ChangeEvent<HTMLSelectElement>) =>
    setSelectedValue(e.target.value);

  const handledClickSelectButton = () => {
    if (selectedValue === '') {
      const allIds = salesOrders
        ?.flatMap((salesOrder) =>
          salesOrder?.node?.details?.edges?.map((detail) => {
            return detail?.node?.id;
          }),
        )
        .filter((v): v is NonNullable<typeof v> => v != null);
      return onClickAllSelect(allIds);
    }

    const selectedSalesOrderIds = salesOrders
      ?.flatMap((salesOrder) =>
        salesOrder?.node?.details?.edges?.map((detail) => {
          const fixedDeliveryDate =
            detail?.node?.deliveryEvents?.edges?.at(0)?.node?.fixedDeliveryDate;
          if (!fixedDeliveryDate) return null;
          const date = cdateJST(fixedDeliveryDate).locale('ja').format('YYYY-MM');

          if (date !== selectedValue) return null;
          return detail?.node?.id;
        }),
      )
      .filter((v): v is NonNullable<typeof v> => v != null);

    if (!selectedSalesOrderIds || selectedSalesOrderIds.length === 0) return onClickAllSelect([]);

    return onClickAllSelect(selectedSalesOrderIds);
  };

  return (
    <HStack spacing={3}>
      <Box w="12.5rem">
        <Select defaultValue={selectedValue} onChange={handleChangeSelect}>
          {Array.from(labels).map((obj, i) => {
            const key = Object.keys(obj);
            const value = Object.values(obj);
            return (
              <option key={i} value={key}>
                {value}
              </option>
            );
          })}
          <option value="">すべて</option>
        </Select>
      </Box>
      <Text>を</Text>
      <Button
        size={'sm'}
        colorScheme="blue"
        leftIcon={<CheckCorrect />}
        onClick={handledClickSelectButton}
      >
        すべて選択
      </Button>
      <Button size={'sm'} colorScheme="gray" onClick={onClickClearSelections}>
        クリア
      </Button>
    </HStack>
  );
};
