import { Box } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandForm } from 'features/company/components/DemandForm';
import { useCreateDemand } from 'features/company/hooks/useCreateDemand';
import { Link } from 'react-router-dom';
import { paths } from 'utils/paths';

export function CompanyNewPage() {
  const { onSubmit, isMutationInFlight } = useCreateDemand();

  return (
    <>
      <Box mb={6}>
        <Link to={paths.company.url()}>
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label="デマンドの追加" />
      </Box>
      <Box w="480px">
        <DemandForm onSubmit={onSubmit} isMutationInFlight={isMutationInFlight} />
      </Box>
    </>
  );
}
