import { Box } from '@chakra-ui/react';
import { DemandMemoQuery as DemandMemoQueryType } from 'gql/__generated__/DemandMemoQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

const DemandMemoQuery = graphql`
  query DemandMemoQuery ($id: ID!, $isSkip: Boolean!)  {
    companies(where: { id: $id }) @skip(if: $isSkip) {
      edges {
        node {
          id
          internalMemos {
            id
            body
          }
        }
      }
    }
  }
`;

export type Props = {
  demandId?: string;
};

export const DemandMemo = ({ demandId }: Props) => {
  const { companies: demandQuery } = useLazyLoadQuery<DemandMemoQueryType>(
    DemandMemoQuery,
    {
      id: demandId || '',
      isSkip: !demandId,
    },
    { fetchPolicy: 'network-only' },
  );

  const memo = demandQuery?.edges?.[0]?.node?.internalMemos?.[0]?.body;

  if (!memo) return null;

  return (
    <Box
      fontSize="xs"
      whiteSpace="pre-wrap"
      p={3}
      borderWidth={1}
      borderColor="yellow.500"
      borderRadius="0.5rem"
    >
      {memo}
    </Box>
  );
};
