import { Box } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { SupplierForm } from 'features/supplier/components/SupplierForm';
import { useCreateSupplier } from 'features/supplier/hooks/useCreateSupplier';
import { Link } from 'react-router-dom';
import { paths } from 'utils/paths';

export function SupplierNewPage() {
  const { onSubmit, isMutationInFlight } = useCreateSupplier();

  return (
    <>
      <Box mb={6}>
        <Link to={paths.supplier.url()}>
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label="サプライヤーの追加" />
      </Box>
      <Box w="480px">
        <SupplierForm onSubmit={onSubmit} isMutationInFlight={isMutationInFlight} />
      </Box>
    </>
  );
}
