/**
 * @generated SignedSource<<0907576be02ea60ead5242696c5820ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profilePageFragment$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber: string | null;
    readonly userID: string;
  };
  readonly " $fragmentType": "profilePageFragment";
};
export type profilePageFragment$key = {
  readonly " $data"?: profilePageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"profilePageFragment">;
};

import profilePageFragmentRefetchQuery_graphql from './profilePageFragmentRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": profilePageFragmentRefetchQuery_graphql
    }
  },
  "name": "profilePageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userID",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "e8973de8965ec12446182e3eb7d50328";

export default node;
