import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Loading } from '@icon-park/react';
import { Link } from 'components/Link';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { CompanyTableRow } from 'features/company/components/CompanyTableRow';
import { companyPageFragment$key } from 'gql/__generated__/companyPageFragment.graphql';
import { companyPageQuery } from 'gql/__generated__/companyPageQuery.graphql';
import { KeyboardEvent, useState, useTransition } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const CompanyPageQuery = graphql`
  query companyPageQuery {
    ...companyPageFragment
  }
`;

const CompanyPageFragment = graphql`
  fragment companyPageFragment on Query
    @refetchable(queryName: "companyPageFragmentRefetchQuery")
    @argumentDefinitions (
      count: { type: "Int", defaultValue: 100 }
      after: { type: "Cursor" }
      direction: { type: "OrderDirection", defaultValue: DESC }
      field: { type: "CompanyOrderField", defaultValue: CREATED_AT }
      where: {type: "CompanyWhereInput", defaultValue: null}
    )
  {
    companies(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "companyPageFragment_companies") {
      edges {
        cursor
        node {
          id
          ...CompanyTableRowFragment
        }
      }
      totalCount
    }
  }
`;

export function CompanyPage() {
  const [isPending, startTransition] = useTransition();
  const [searchValue, setSearchValue] = useState('');
  const query = useLazyLoadQuery<companyPageQuery>(
    CompanyPageQuery,
    {},
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    CompanyPageFragment,
    query as companyPageFragment$key,
  );

  const handleClickMore = () => loadNext(100);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      startTransition(() => {
        refetch({ where: { nameContains: searchValue } });
      });
    }
  };

  return (
    <>
      <Button colorScheme="blue" width="208px" as={Link} to={paths.company.new.url()}>
        デマンド追加
      </Button>
      <FormControl width="360px" mt={6}>
        <FormLabel mb={1}>企業名</FormLabel>
        <Input
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="検索"
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      {isPending ? (
        <Loading />
      ) : (
        <Box mt="1.5rem">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th w="12rem">ID</Th>
                <Th>企業名</Th>
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {data.companies.edges?.map(
                (edge, i) =>
                  edge?.node && (
                    <CompanyTableRow key={`company-${edge?.cursor}-${i}`} queryRef={edge?.node} />
                  ),
              )}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.companies.totalCount}
              currentLength={data.companies.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
