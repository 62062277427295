import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { InformationShizai } from 'components/InformationShizai';
import { OrderDetailType } from 'features/order/type';
import { convertItemFormat, detailNumber } from 'features/order/util';
import { OrderPreviewFragment$key } from 'gql/__generated__/OrderPreviewFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

const OrderPreviewFragment = graphql`
  fragment OrderPreviewFragment on Order
  {
    supplier {
      id
      name
    }
    company {
      id
      name
    }
    salesOrders {
      id
      transactionID
    }
    detailMessage
    deliveryInfo {
      id
      desiredDeliveryDate
      recipientsText
    }
    createdAt
    details {
      id
      branchNumber
      type
      item {
        id
        name
        unitPrice
        quantity
        taxCategory {
          rate
        }
      }
    }
  }
`;

type Props = {
  queryRef: OrderPreviewFragment$key;
};

export const OrderPreview = ({ queryRef }: Props) => {
  const { supplier, company, salesOrders, createdAt, detailMessage, deliveryInfo, details } =
    useFragment(OrderPreviewFragment, queryRef);

  const items = [...(details || [])]
    .map((detail) => {
      if (detail.item !== null && detail.type === OrderDetailType.Item) {
        return convertItemFormat({ branchNumber: detail.branchNumber, item: detail.item });
      }
    })
    .filter((v): v is NonNullable<typeof v> => v != null);

  const orders = [...items];

  const salesOrder = salesOrders?.[0];

  return (
    <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full" mb={6}>
      <VStack align="stretch" spacing={0}>
        <HStack align="end" pb="1rem" justify="space-between">
          <Heading as="h2" fontSize={24}>
            発注書
          </Heading>
          <HStack spacing={4}>
            <Text fontSize="sm">{dateFormat(createdAt)}</Text>
          </HStack>
        </HStack>
        <Divider />
      </VStack>
      <HStack pb="1rem" justify="space-between" align="flex-start">
        <VStack spacing={2} w="320px" align="start">
          <Text fontSize="xl" fontWeight="bold">
            {supplier.name} 御中
          </Text>
          <Text color="gray.500" fontSize="sm">
            下記のとおり発注いたします
          </Text>
          <VStack spacing={1} w="full">
            <HStack align="end" justify="space-between" w="full">
              <Text>合計金額</Text>
              <HStack spacing={1} align="end">
                <Text fontSize="2xl" fontWeight="bold">
                  {calculateTotalAmount(
                    orders.map((detail) => ({
                      quantity: detail.quantity,
                      unitSellingPrice: detail.unitPrice,
                      tax: {
                        rate: detail.tax.rate,
                      },
                    })),
                  ).toLocaleString()}
                </Text>
                <Text>円</Text>
              </HStack>
            </HStack>
            <Divider borderColor="gray.700" />
          </VStack>
        </VStack>
        <Box w="320px">
          <InformationShizai />
        </Box>
      </HStack>
      <VStack align="start" justify="space-between">
        <Table>
          <Thead>
            <Tr>
              <Th color="gray.700" w="100%" pl="0">
                品名
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                単価
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {orders.map((item) => (
              <Tr key={`item-${item.id}`} verticalAlign="top">
                <Td pl="0" whiteSpace="pre-wrap">
                  {salesOrder && `${detailNumber(salesOrder.transactionID, item.branchNumber)} `}
                  {`<${company.name}>`} {item.name}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.quantity.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700">
                  {item.unitPrice.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700" pr="0">
                  {calculateDetailAmount({
                    quantity: item.quantity,
                    price: item.unitPrice,
                  }).toLocaleString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
      <HStack pb="1rem" justify="space-between" align="flex-start">
        <VStack spacing={4} w="400px" align="stretch">
          <VStack spacing={1} align="start">
            <Text fontWeight="bold">希望納品日</Text>
            <Text whiteSpace="pre-wrap" maxW="100%">
              {deliveryInfo?.desiredDeliveryDate}
            </Text>
          </VStack>
          <VStack spacing={1} align="start">
            <Text fontWeight="bold">納品先</Text>
            <Text whiteSpace="pre-wrap" maxW="100%">
              {deliveryInfo?.recipientsText}
            </Text>
          </VStack>
          <VStack spacing={1} align="start">
            <Text fontWeight="bold">備考</Text>
            <Text whiteSpace="pre-wrap" maxW="100%">
              {detailMessage}
            </Text>
          </VStack>
        </VStack>
        <VStack align="left" spacing={2} w="240px">
          <Flex mt={2} justify="space-between">
            <Box w="140px" fontSize="sm">
              小計
            </Box>
            <Box fontSize="sm">
              {calculateSubtotalAmount(
                orders.map((detail) => ({
                  quantity: detail.quantity,
                  unitSellingPrice: detail.unitPrice,
                })),
              ).toLocaleString()}
            </Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box whiteSpace="nowrap" w="140px" fontSize="sm">
              消費税
            </Box>
            <Box fontSize="sm">
              {calculateTotalTaxAmount(
                orders.map((detail) => ({
                  quantity: detail.quantity,
                  unitSellingPrice: detail.unitPrice,
                  tax: {
                    rate: detail.tax.rate,
                  },
                })),
              ).toLocaleString()}
            </Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box w="140px" fontWeight="bold">
              合計金額
            </Box>
            <Box fontWeight="bold">
              {calculateTotalAmount(
                orders.map((detail) => ({
                  quantity: detail.quantity,
                  unitSellingPrice: detail.unitPrice,
                  tax: {
                    rate: detail.tax.rate,
                  },
                })),
              ).toLocaleString()}
            </Box>
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  );
};
