import { Box, Divider, Flex, List, ListIcon, ListItem, Text, VStack } from '@chakra-ui/react';
import { Logout, SettingOne } from '@icon-park/react';
import { AppLogo } from 'components/AppLogo';
import { SideBarList } from 'constants/sidebar';
import { SideBarQuery } from 'gql/__generated__/SideBarQuery.graphql';
import { useSession } from 'hooks/useSession';
import { useLazyLoadQuery } from 'react-relay';
import { Link, useLocation } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const sideBarQuery = graphql`
  query SideBarQuery {
    currentUser {
      firstName
      lastName
      email
    }
  }
`;

const ESTIMATION_REQUEST_PATH = paths.estimationRequest.url();

const checkCurrentPath = (currentPath: string, href: string) => {
  if (currentPath.includes(ESTIMATION_REQUEST_PATH)) {
    return href.includes(ESTIMATION_REQUEST_PATH);
  } else {
    return currentPath.startsWith(href);
  }
};

export function SideBar() {
  const { pathname } = useLocation();
  const { signOut } = useSession();

  const { currentUser } = useLazyLoadQuery<SideBarQuery>(
    sideBarQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  return (
    <Box
      as="nav"
      position="sticky"
      left={0}
      top={0}
      height="100vh"
      background="gray.800"
      width="200px"
      px={4}
      pt={8}
      pb={5}
      flexShrink="0"
      zIndex="sticky"
    >
      <Flex justify="space-between" direction="column" height="100%">
        <Box>
          <Box mb={5}>
            <Link to={paths.root()}>
              <AppLogo />
            </Link>
          </Box>
          <Box>
            {SideBarList.map((list, i) => (
              <Box key={`sidebar-${list.category}-${i}`} mb={6}>
                <Box color="whiteAlpha.800" fontSize="xs" mb={4}>
                  {list.category}
                </Box>
                <List>
                  {list.items.map((item, j) => (
                    <ListItem key={`sidebar-${list.category}-${i}-item-${item.label}-${j}`}>
                      <Link to={item.href}>
                        <Flex
                          as="span"
                          color={checkCurrentPath(pathname, item.href) ? 'blue.400' : 'white'}
                          fontSize="sm"
                          width="100%"
                          py={2}
                          px={2.5}
                          align="center"
                          _hover={{
                            background: 'gray.700',
                            borderRadius: '6px',
                          }}
                        >
                          <ListIcon as={item.icon} />
                          {item.label}
                        </Flex>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <Divider mb={4} />
          <VStack spacing={4} align="start">
            <Box>
              <Text fontSize="sm" color="white">
                {concatFullName({
                  lastName: currentUser.lastName,
                  firstName: currentUser.firstName,
                })}
              </Text>
              <Text fontSize="xs" color="whiteAlpha.700">
                {currentUser.email}
              </Text>
            </Box>
            <List w="full" spacing={4}>
              <ListItem>
                <Link to={paths.settings.profile.url()}>
                  <Flex
                    as="span"
                    color={
                      checkCurrentPath(pathname, paths.settings.profile.url())
                        ? 'blue.400'
                        : 'white'
                    }
                    fontSize="sm"
                    width="100%"
                    align="center"
                    _hover={{
                      background: 'gray.700',
                      borderRadius: '6px',
                    }}
                    p={2}
                  >
                    <ListIcon as={SettingOne} />
                    設定
                  </Flex>
                </Link>
              </ListItem>
              <ListItem>
                <Flex
                  onClick={signOut}
                  as="span"
                  color="white"
                  fontSize="sm"
                  width="100%"
                  align="center"
                  _hover={{
                    background: 'gray.700',
                    borderRadius: '6px',
                    cursor: 'pointer',
                  }}
                  p={2}
                >
                  <ListIcon as={Logout} />
                  ログアウト
                </Flex>
              </ListItem>
            </List>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
}
