import { ItemPriceList } from 'components/ItemPriceList';
import { EstimationItemPriceListFragment$key } from 'gql/__generated__/EstimationItemPriceListFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

const EstimationItemPriceListFragment = graphql`
  fragment EstimationItemPriceListFragment on Estimation
  {
    priceListDetails: details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
      edges {
        node {
          orderNumber
          item {
            id
            name
            unitPrice
            quantity
            unitSellingPrice
            taxCategory {
              id
              rate
            }
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: EstimationItemPriceListFragment$key;
};

export const EstimationItemPriceList = ({ queryRef }: Props) => {
  const { priceListDetails } = useFragment(EstimationItemPriceListFragment, queryRef);

  const items = [...(priceListDetails.edges || [])]
    .map((edge) => edge?.node?.item)
    .filter((v): v is NonNullable<typeof v> => v != null);

  return (
    <ItemPriceList
      details={items.map((item) => ({
        name: item.name,
        unitPrice: item.unitPrice,
        unitSellingPrice: item.unitSellingPrice,
        quantity: item.quantity,
      }))}
    />
  );
};
