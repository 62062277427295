import { errorMessages, quantitySchema, unitPriceSchema } from 'utils/validation';
import { z } from 'zod';

export const orderDetailFormSchema = z.object({
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) }),
  unitPrice: unitPriceSchema(),
  quantity: quantitySchema(),
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
  }),
  salesOrderDetailId: z.string().optional(),
});

export const orderDeliveryInfoSchema = z.object({
  desiredDeliveryDate: z
    .string()
    .max(250, { message: errorMessages.MAX_LENGTH(250) })
    .optional(),
  recipientsText: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
});

export const orderFormSchema = z.object({
  company: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  supplier: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  title: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(70, { message: errorMessages.MAX_LENGTH(70) }),
  detailMessage: z.string().max(2000, { message: errorMessages.MAX_LENGTH(2000) }),
  deliveryInfo: orderDeliveryInfoSchema,
  salesOrderId: z.string().optional(),
  details: z.array(orderDetailFormSchema),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
});

export const orderSendingFormSchema = z
  .object({
    mailBodyMessage: z.string().max(500, { message: errorMessages.MAX_LENGTH(500) }),
    supplierContactsTo: z.array(z.string()).min(1, { message: 'TOで送る担当者を選択しましょう' }),
    supplierContactsCc: z.array(z.string()),
  })
  .refine(
    (data) => {
      if (data.supplierContactsTo.length === 0 || data.supplierContactsCc.length === 0) return true;
      return (
        data.supplierContactsCc.filter((contact) => data.supplierContactsTo.includes(contact))
          .length === 0
      );
    },
    {
      message: 'TOかCCどちらかを選択しましょう',
      path: ['supplierContactsCc'],
    },
  );

export type ZodOrderDetail = z.infer<typeof orderDetailFormSchema>;

export type ZodOrderFormData = z.infer<typeof orderFormSchema>;
export type OrderSendingFormData = z.infer<typeof orderSendingFormSchema>;
