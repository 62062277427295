import { Select } from 'chakra-react-select';
import type { Props as SelectProps } from 'chakra-react-select';
import { UserMultipleSelectBoxQuery as UserMultipleSelectBoxQueryType } from 'gql/__generated__/UserMultipleSelectBoxQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { concatFullName } from 'utils/label';

type Props = Omit<SelectProps, 'isMulti' | 'options' | 'selectedOptionStyle' | 'useBasicStyles'>;

const UserMultipleSelectBoxQuery = graphql`
  query UserMultipleSelectBoxQuery {
    users {
      edges {
        node {
          id
          profile {
            lastName
            firstName
          }
        }
      }
    }
  }
`;

export const UserMultipleSelectBox = ({ ...props }: Props) => {
  const { users } = useLazyLoadQuery<UserMultipleSelectBoxQueryType>(
    UserMultipleSelectBoxQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const options = users.edges?.map((user) => ({
    value: user?.node?.id,
    label: concatFullName({
      lastName: user?.node?.profile?.at(0)?.lastName || '',
      firstName: user?.node?.profile?.at(0)?.firstName || '',
    }),
  }));

  if (!users || !users.edges || users.edges.length === 0) return null;

  return (
    <Select
      isMulti
      useBasicStyles
      options={[
        {
          label: 'リストから選択しましょう',
          options,
        },
      ]}
      selectedOptionStyle="check"
      closeMenuOnSelect={false}
      chakraStyles={{
        multiValue: (provided) => ({
          ...provided,
          borderRadius: '999px',
        }),
        groupHeading: (provided) => ({
          ...provided,
          color: 'gray.500',
          fontWeight: 'normal',
          paddingX: 2.5,
          paddingY: 2,
          fontSize: 'md',
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingX: 1,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          display: 'none',
        }),
      }}
      {...props}
    />
  );
};
