import { Box, Text } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';

export const EstimationResponseNotFound = () => (
  <>
    <Box mb={6}>
      <PageHeading label="このURLは無効です" />
    </Box>
    <Text color="gray.500">
      この見積依頼は依頼者によって取り消されたか、見積依頼が存在しません。
    </Text>
  </>
);
