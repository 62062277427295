import { Box } from '@chakra-ui/react';
import { ProfileForm } from 'features/user/components/ProfileForm';
import { ProfileFormDataType } from 'features/user/form';
import { useEditProfile } from 'features/user/hooks/useEditProfile';
import { profilePageFragment$key } from 'gql/__generated__/profilePageFragment.graphql';
import { profilePageQuery as ProfilePageQuery } from 'gql/__generated__/profilePageQuery.graphql';
import { useEffect, useState } from 'react';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { useBlocker } from 'react-router-dom';
import { graphql } from 'relay-runtime';

const profilePageQuery = graphql`
  query profilePageQuery {
    ...profilePageFragment
  }
`;

const profilePageFragment = graphql`
  fragment profilePageFragment on Query
  @refetchable(queryName: "profilePageFragmentRefetchQuery")
  {
    currentUser {
      userID
      firstName
      lastName
      phoneNumber
    }
  }
`;

// react-router-domのunstable_usePromptはalertの表示ができないので自作
// useBlockerもunstableなため、既存のusePreventNavigationを置き換えることはしない
const usePrompt = ({ when, message }: { when: boolean; message: string }) => {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      window.alert(message);
      blocker.reset();
    }
  }, [blocker, message]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when]);
};

export const ProfilePage = () => {
  const query = useLazyLoadQuery<ProfilePageQuery>(
    profilePageQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const [data, refetch] = useRefetchableFragment(
    profilePageFragment,
    query as profilePageFragment$key,
  );
  const { currentUser } = data;

  const { onSubmit, isMutationInFlight } = useEditProfile(currentUser.userID, refetch);
  const [showAlert, setShowAlert] = useState(!currentUser.firstName && !currentUser.lastName);

  usePrompt({
    when: showAlert,
    message: 'lubeを使用するには、姓名を登録しましょう',
  });

  const handleSubmit = (data: ProfileFormDataType) => {
    setShowAlert(false);
    onSubmit(data);
  };

  return (
    <Box w="480px">
      <ProfileForm
        isMutationInFlight={isMutationInFlight}
        onSubmit={handleSubmit}
        value={{
          firstName: currentUser.firstName || '',
          lastName: currentUser.lastName || '',
          phoneNumber: currentUser.phoneNumber || '',
        }}
      />
    </Box>
  );
};
