import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { LayoutContext } from 'components/layout/Layout';
import { EstimationRequestAttachmentFiles } from 'features/estimationRequest/components/EstimationRequestAttachmentFiles';
import { EstimationRequestInfo } from 'features/estimationRequest/components/EstimationRequestInfo';
import { EstimationRequestItemMessage } from 'features/estimationRequest/components/EstimationRequestItemMessage';
import { EstimationRequestPreviewItems } from 'features/estimationRequest/components/EstimationRequestPreviewItems';
import { EstimationResponses } from 'features/estimationRequest/components/EstimationResponses';
import { useDeleteEstimationRequest } from 'features/estimationRequest/hooks/useDeleteEstimationRequest';
import { useQueryParams } from 'features/estimationRequest/hooks/useEstimationRequestQueryParams';
import { useExportEstimationRequest } from 'features/estimationRequest/hooks/useExportEstimationRequest';
import { detail_estimationRequestDetailPageFragment$key } from 'gql/__generated__/detail_estimationRequestDetailPageFragment.graphql';
import { detail_estimationRequestDetailPageQuery } from 'gql/__generated__/detail_estimationRequestDetailPageQuery.graphql';
import { useContext, useEffect } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { Link, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const EstimationRequestDetailPageQuery = graphql`
  query detail_estimationRequestDetailPageQuery ($id: ID!) {
    ...detail_estimationRequestDetailPageFragment @arguments(id: $id)
  }
`;

const EstimationRequestDetailPageFragment = graphql`
  fragment detail_estimationRequestDetailPageFragment on Query
    @argumentDefinitions (
      id: { type: "ID" defaultValue: null }
    )
  {
    estimationRequests(where: {id: $id}) {
      edges {
        node {
          ...EstimationRequestInfoFragment
          ...EstimationRequestPreviewItemsFragment
          ...EstimationRequestItemMessageFragment
          ...EstimationRequestAttachmentFilesFragment
          ...EstimationResponsesFragment
        }
      }
    }
  }
`;

const DEFAULT_WIDTH = 768;

export const EstimationRequestDetailPage = () => {
  const { queryParams } = useQueryParams();
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }
  const { layoutProps, setLayoutProps } = useContext(LayoutContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!id) return;
    if (!layoutProps || !setLayoutProps) return;

    const width = layoutProps.width;
    setLayoutProps({ width: undefined });

    return () => {
      setLayoutProps({ width });
    };
  }, []);

  const query = useLazyLoadQuery<detail_estimationRequestDetailPageQuery>(
    EstimationRequestDetailPageQuery,
    { id: id },
    { fetchPolicy: 'network-only' },
  );

  const { estimationRequests } = useFragment(
    EstimationRequestDetailPageFragment,
    query as detail_estimationRequestDetailPageFragment$key,
  );
  const { onSubmit } = useDeleteEstimationRequest();
  const { onSubmit: onSubmitExport, isMutationInFlight: isExportMutationInFlight } =
    useExportEstimationRequest();
  const estimationRequest = (estimationRequests?.edges || [])[0]?.node;

  if (!estimationRequest) return null;

  return (
    <Box>
      <Box mb={6} w={DEFAULT_WIDTH}>
        <Flex justify="space-between">
          <Link
            to={paths.estimationRequest.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              supplier: queryParams.supplier,
              searchWord: queryParams.searchWord,
            })}
          >
            <PageBack />
          </Link>
          <HStack spacing={4}>
            <ConfirmModalButton
              header="削除しますか？"
              button={({ onOpen }) => <Button onClick={onOpen}>削除</Button>}
              footer={({ onClose }) => (
                <HStack spacing={3}>
                  <Button onClick={onClose}>キャンセル</Button>
                  <Button
                    colorScheme="red"
                    ml={3}
                    onClick={() => {
                      onClose();
                      onSubmit(id);
                    }}
                  >
                    削除
                  </Button>
                </HStack>
              )}
            />
            <Link to={paths.estimationRequest.duplication._id(id)}>
              <Button>複製</Button>
            </Link>
            <Link to={paths.estimationRequest.addAssignees._id(id)}>
              <Button colorScheme="blue">追加見積</Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
      <Box mb={10}>
        <Box mb={6}>
          <SubHeading label="見積依頼の詳細" />
        </Box>
        <Box mb={4}>
          <EstimationRequestInfo queryRef={estimationRequest} />
        </Box>
        <EstimationRequestPreviewItems queryRef={estimationRequest} />
        <EstimationRequestItemMessage queryRef={estimationRequest} />
        <EstimationRequestAttachmentFiles queryRef={estimationRequest} />
      </Box>
      <EstimationResponses
        queryRef={estimationRequest}
        onClickExport={() => onSubmitExport(id)}
        isExporting={isExportMutationInFlight}
      />
    </Box>
  );
};
