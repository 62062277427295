import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
  useMergeRefs,
} from '@chakra-ui/react';
import { AddOne } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ChangeOrderButton } from 'components/ChangeOrderButton';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { ItemAmountPreview } from 'components/ItemAmountPreview';
import { InvoiceInputFormValueType, getInvoiceDetailInitialValues } from 'features/invoice/form';
import { detailNumber } from 'features/order/util';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { InvoiceDetailsInputFormFragment$key } from 'gql/__generated__/InvoiceDetailsInputFormFragment.graphql';
import { useRef } from 'react';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { calculateDetailAmount } from 'utils/priceCalculation';

const InvoiceDetailsInputFormFragment = graphql`
  fragment InvoiceDetailsInputFormFragment on Query 
    @argumentDefinitions (
      salesOrderDetailIds: {type: "[ID!]", defaultValue: null}
    )
  {
    details: salesOrderDetails(where: {idIn: $salesOrderDetailIds}) {
      edges {
        node {
          id
          branchNumber
          salesOrder {
            title
            transactionID
          }
        }
      }
    }
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
    ...TaxSelectBoxFragment
  }
`;

export const InvoiceDetailsInputForm = ({
  control,
  getValues,
  setValue,
  register,
  errors,
  queryRef,
}: {
  control: Control<InvoiceInputFormValueType>;
  getValues: UseFormGetValues<InvoiceInputFormValueType>;
  setValue: UseFormSetValue<InvoiceInputFormValueType>;
  register: UseFormRegister<InvoiceInputFormValueType>;
  errors: Partial<FieldErrorsImpl<InvoiceInputFormValueType>>;
  queryRef: InvoiceDetailsInputFormFragment$key;
}) => {
  const query = useFragment(InvoiceDetailsInputFormFragment, queryRef);
  const tax = query.taxCategories?.edges?.at(0)?.node;
  const salesOrderDetails = query.details.edges;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details',
  });

  const watchDetails = useWatch({ control, name: 'details' });

  watchDetails.forEach((detail, index) => {
    // nameにタブ文字を含む場合は全角スペースに置換する
    if (/\t/.test(detail.name)) {
      setValue(`details.${index}.name`, detail.name.replace(/\t/g, '　'));
    }
  });

  const addDetail = () => {
    append({
      ...getInvoiceDetailInitialValues({ id: tax?.id || '', rate: tax?.rate || '' }),
      quantity: '',
      unitSellingPrice: '',
    });
  };

  const handleClickUp = (index: number) => {
    const details = getValues('details');
    details.splice(index - 1, 2, details[index], details[index - 1]);
    setValue('details', details);
  };

  const handleClickDown = (index: number) => {
    const details = getValues('details');
    details.splice(index, 2, details[index + 1], details[index]);
    setValue('details', details);
  };

  const isDisabledUpButton = (index: number) => index === 0;
  const isDisabledDownButton = (index: number) => index + 1 === fields.length;

  const salesOrderInfo = new Map<
    string,
    { branchNumber: string; transactionId: string; title: string }
  >();
  (salesOrderDetails || []).map((detail) => {
    if (detail && detail.node) {
      salesOrderInfo.set(detail.node.id, {
        branchNumber: detail.node.branchNumber,
        transactionId: detail.node.salesOrder.transactionID,
        title: detail?.node.salesOrder.title,
      });
    }
  });

  return (
    <VStack align="stretch" spacing={4}>
      {fields.map((field, index) => {
        const info = salesOrderInfo.get(field.salesOrderDetailId || '');
        return (
          <HStack key={field.id} align="flex-start" spacing={2}>
            {/* 並び順変更する矢印 */}
            <VStack>
              <ChangeOrderButton
                type="up"
                onClick={() => handleClickUp(index)}
                isDisabled={isDisabledUpButton(index)}
              />
              <ChangeOrderButton
                type="down"
                onClick={() => handleClickDown(index)}
                isDisabled={isDisabledDownButton(index)}
              />
            </VStack>

            {/* 品名 */}
            <FormControl isInvalid={!!(errors.details || [])[index]?.name} isRequired w="22.5rem">
              <NameInput info={info} index={index} register={register} setValue={setValue} />
              <ErrorMessage name={`details.${index}.name`} errors={errors} />
            </FormControl>

            {/* 取引日 */}
            <FormControl isInvalid={!!(errors.details || [])[index]?.salesDate} w="10rem">
              <Input type="date" {...register(`details.${index}.salesDate`)} />
              <ErrorMessage name={`details.${index}.salesDate`} errors={errors} />
            </FormControl>

            {/* 数量 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.quantity}
              isRequired
              flex={1}
              w="7.5rem"
            >
              <ConfigurableNumberInput {...register(`details.${index}.quantity`)} />
              <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
            </FormControl>

            {/* 単価 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.unitSellingPrice}
              isRequired
              flex={1}
              w="7.5rem"
            >
              <ConfigurableNumberInput
                {...register(`details.${index}.unitSellingPrice`)}
                step="0.01"
              />
              <ErrorMessage name={`details.${index}.unitSellingPrice`} errors={errors} />
            </FormControl>

            {/* 税区分 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.tax?.id}
              isRequired
              flex={1}
              w="7rem"
            >
              <Controller
                name={`details.${index}.tax`}
                control={control}
                render={() => (
                  <TaxSelectBox
                    defaultValue={getValues(`details.${index}.tax.id`)}
                    queryRef={query}
                    onChange={(e) => {
                      setValue(`details.${index}.tax`, {
                        ...getValues(`details.${index}.tax`),
                        ...{
                          id: e.target.value,
                          rate: e.target[e.target.selectedIndex].getAttribute(
                            'data-rate',
                          ) as string,
                        },
                      });
                    }}
                  />
                )}
              />
              <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
            </FormControl>

            {/* 金額 */}
            <Box w="7.5rem">
              <ItemAmountPreview
                amount={calculateDetailAmount({
                  quantity: getValues(`details.${index}.quantity`) || 0,
                  price: getValues(`details.${index}.unitSellingPrice`) || 0,
                })}
              />
            </Box>

            {/* 削除ボタン */}
            <Box>
              <IconButton
                onClick={() => remove(index)}
                variant="ghost"
                isRound
                aria-label="close"
                icon={<CloseIcon />}
                isDisabled={!!field.salesOrderDetailId}
              />
            </Box>
          </HStack>
        );
      })}
      <Button w="full" colorScheme="gray" leftIcon={<AddOne />} onClick={addDetail}>
        追加
      </Button>
    </VStack>
  );
};

const NameInput = ({
  info,
  register,
  index,
  setValue,
}: {
  index: number;
  info:
    | {
        branchNumber: string;
        transactionId: string;
        title: string;
      }
    | undefined;
  register: UseFormRegister<InvoiceInputFormValueType>;
  setValue: UseFormSetValue<InvoiceInputFormValueType>;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { ref, ...rest } = register(`details.${index}.name`);
  const mergedRef = useMergeRefs(ref, textareaRef);

  return (
    <>
      <AutoResizeTextarea
        {...rest}
        ref={mergedRef}
        onChange={(e) => {
          setValue(`details.${index}.name`, removeNewlines(e.target.value));
        }}
      />
      {info && (
        <Text
          color="gray.500"
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          mt={1}
        >
          {detailNumber(Number(info.transactionId), Number(info.branchNumber))}：{info.title}
        </Text>
      )}
    </>
  );
};

const removeNewlines = (input: string) => {
  return input.replace(/\r?\n|\r/g, '');
};
