import { Heading } from '@chakra-ui/react';

type Props = {
  label: string;
};

export function SubHeading({ label }: Props) {
  return (
    <Heading as="h2" size="md">
      {label}
    </Heading>
  );
}
