import { ServiceType } from '@bufbuild/protobuf';
import { Client, createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { useEffect, useMemo, useState } from 'react';
import { useSession } from './useSession';

const baseUrl = new URL(import.meta.env.VITE_LUBE_API_DOMAIN);
baseUrl.pathname = '/grpc';

export function useGrpcClient<T extends ServiceType>(
  service: T,
): {
  grpcClient: Client<T>;
  authorized: boolean;
} {
  const { currentUser, isLoading, error } = useSession();
  const [token, setToken] = useState<string | undefined>(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    (async () => {
      if (!currentUser) return;
      const token = await currentUser?.getIdToken();
      setToken(token);
    })();
  }, [currentUser, isLoading, error]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const transport = useMemo(() => {
    return createConnectTransport({
      baseUrl: baseUrl.toString(),
      interceptors: [
        (next) => async (req) => {
          req.header.set('Authorization', `Bearer ${token}`);
          return await next(req);
        },
      ],
    });
  }, [service, token]);

  const client = useMemo(() => {
    const client = createClient(service, transport);
    return client;
  }, [service, transport]);

  return { grpcClient: client, authorized: token !== undefined };
}
