/**
 * @generated SignedSource<<e8a47b4b1e41301dbe20a34bc8df2292>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationRequestAddAssigneeFormFragment$data = {
  readonly company: {
    readonly id: string;
    readonly name: string;
  };
  readonly requestAssignees: ReadonlyArray<{
    readonly supplier: {
      readonly id: string;
      readonly name: string;
    };
  }> | null;
  readonly requestAttachments: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly requestTitle: string;
  readonly specText: string;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationRequestAttachmentFilesFragment" | "EstimationRequestPreviewItemsFragment">;
  readonly " $fragmentType": "EstimationRequestAddAssigneeFormFragment";
};
export type EstimationRequestAddAssigneeFormFragment$key = {
  readonly " $data"?: EstimationRequestAddAssigneeFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationRequestAddAssigneeFormFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationRequestAddAssigneeFormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EstimationRequestPreviewItemsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EstimationRequestAttachmentFilesFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationRequestAssignee",
      "kind": "LinkedField",
      "name": "requestAssignees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Supplier",
          "kind": "LinkedField",
          "name": "supplier",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationRequestAttachment",
      "kind": "LinkedField",
      "name": "requestAttachments",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "EstimationRequest",
  "abstractKey": null
};
})();

(node as any).hash = "993691423e4925da0e3cd2030aac5477";

export default node;
