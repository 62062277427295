// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file model/common/v1/month.proto (package model.common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum model.common.v1.Month
 */
export enum Month {
  /**
   * @generated from enum value: MONTH_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MONTH_JANUARY = 1;
   */
  JANUARY = 1,

  /**
   * @generated from enum value: MONTH_FEBRUARY = 2;
   */
  FEBRUARY = 2,

  /**
   * @generated from enum value: MONTH_MARCH = 3;
   */
  MARCH = 3,

  /**
   * @generated from enum value: MONTH_APRIL = 4;
   */
  APRIL = 4,

  /**
   * @generated from enum value: MONTH_MAY = 5;
   */
  MAY = 5,

  /**
   * @generated from enum value: MONTH_JUNE = 6;
   */
  JUNE = 6,

  /**
   * @generated from enum value: MONTH_JULY = 7;
   */
  JULY = 7,

  /**
   * @generated from enum value: MONTH_AUGUST = 8;
   */
  AUGUST = 8,

  /**
   * @generated from enum value: MONTH_SEPTEMBER = 9;
   */
  SEPTEMBER = 9,

  /**
   * @generated from enum value: MONTH_OCTOBER = 10;
   */
  OCTOBER = 10,

  /**
   * @generated from enum value: MONTH_NOVEMBER = 11;
   */
  NOVEMBER = 11,

  /**
   * @generated from enum value: MONTH_DECEMBER = 12;
   */
  DECEMBER = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(Month)
proto3.util.setEnumType(Month, "model.common.v1.Month", [
  { no: 0, name: "MONTH_UNSPECIFIED" },
  { no: 1, name: "MONTH_JANUARY" },
  { no: 2, name: "MONTH_FEBRUARY" },
  { no: 3, name: "MONTH_MARCH" },
  { no: 4, name: "MONTH_APRIL" },
  { no: 5, name: "MONTH_MAY" },
  { no: 6, name: "MONTH_JUNE" },
  { no: 7, name: "MONTH_JULY" },
  { no: 8, name: "MONTH_AUGUST" },
  { no: 9, name: "MONTH_SEPTEMBER" },
  { no: 10, name: "MONTH_OCTOBER" },
  { no: 11, name: "MONTH_NOVEMBER" },
  { no: 12, name: "MONTH_DECEMBER" },
]);

