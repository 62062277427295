import { Box, Button, Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { SupplierDetailedInfo } from 'features/supplier/components/SupplierDetailedInfo';
import { detail_SupplierDetailPageQuery } from 'gql/__generated__/detail_SupplierDetailPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const SupplierDetailPageQuery = graphql`
  query detail_SupplierDetailPageQuery  ($id: ID!) {
    suppliers(where: {id: $id}) {
      edges {
        node {
          ...SupplierDetailedInfoFragment
        }
      }
    }
  }
`;

export const SupplierDetailPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { suppliers } = useLazyLoadQuery<detail_SupplierDetailPageQuery>(
    SupplierDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  if (
    !suppliers ||
    !suppliers.edges ||
    suppliers.edges.length === 0 ||
    !suppliers.edges[0]?.node ||
    suppliers.edges[0]?.node === null
  )
    return null;

  return (
    <>
      <Box mb={6} width="768px">
        <Flex justify="space-between">
          <RouterLink to={paths.supplier.url()}>
            <PageBack />
          </RouterLink>
          <RouterLink to={paths.supplier.edit._id(id)}>
            <Button colorScheme="blue">編集</Button>
          </RouterLink>
        </Flex>
      </Box>
      <Box mb={6}>
        <SubHeading label="サプライヤーの詳細" />
      </Box>
      <Box mb={7} maxWidth="768px">
        <SupplierDetailedInfo queryRef={suppliers.edges[0]?.node} />
      </Box>
    </>
  );
};
