import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { Down, History } from '@icon-park/react';
import { DemandItemHistoryMenuFragment$key } from 'gql/__generated__/DemandItemHistoryMenuFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormatWithTimeWithoutDayOfWeek } from 'utils/date';

const DemandItemHistoryMenuFragment = graphql`
  fragment DemandItemHistoryMenuFragment on DemandItem {
    currentPageVersion: version
    original {
      children {
        id
        startedAt
        endedAt
        version
      }
    }
  }
`;

type Props = {
  queryRef: DemandItemHistoryMenuFragment$key;
  onChange: (value: string) => void;
};

const getValidityPeriod = (startedAt: string, endedAt: string) => {
  const startedAtDate = dateFormatWithTimeWithoutDayOfWeek(startedAt);

  if (!endedAt) {
    return startedAtDate;
  }

  const endedAtDate = dateFormatWithTimeWithoutDayOfWeek(endedAt);
  return `${startedAtDate} - ${endedAtDate}`;
};

export const DemandItemHistoryMenu = ({ queryRef, onChange }: Props) => {
  const { original, currentPageVersion } = useFragment(DemandItemHistoryMenuFragment, queryRef);

  const historyList = (original?.children || []).map((child) => ({
    id: child.id,
    version: child.version,
    period: getValidityPeriod(child.startedAt, child.endedAt),
  }));

  const handleChangeHistory = (value: string | string[]) => {
    onChange(value as string);
  };

  return (
    <Menu>
      <MenuButton as={Button} leftIcon={<History />} rightIcon={<Down />}>
        履歴
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={currentPageVersion.toString()}
          type="radio"
          onChange={handleChangeHistory}
        >
          {historyList.map((history) => (
            <MenuItemOption key={history?.id} value={history?.version.toString()}>
              {history?.period}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
