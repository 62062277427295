import {
  useDownloadEstimationRequestAttachmentQuery$data,
  useDownloadEstimationRequestAttachmentQuery as useDownloadEstimationRequestAttachmentQueryType,
} from 'gql/__generated__/useDownloadEstimationRequestAttachmentQuery.graphql';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useContext } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

const useDownloadEstimationRequestAttachmentQuery = graphql`
  query useDownloadEstimationRequestAttachmentQuery ($id: ID!) {
    estimationRequestAttachmentURL(id: $id) {
      url
    }
  }
`;

/**
 * 見積依頼の添付ファイルをダウンロードするhook
 * @returns downloadEstimationRequestAttachment: 添付ファイルIDを引数に取り、ダウンロードする関数
 */
export const useDownloadEstimationRequestAttachment = () => {
  const versionWatcher = useContext(VersionWatcherContext);
  /**
   *  添付ファイルをダウンロードする
   * @param id 添付ファイルID
   */
  const downloadEstimationRequestAttachment = async (id: string) => {
    const enableSoftDelete = versionWatcher.disableSoftDeleteEffect();
    let data: useDownloadEstimationRequestAttachmentQuery$data | undefined;
    try {
      data = await fetchQuery<useDownloadEstimationRequestAttachmentQueryType>(
        RelayEnvironment,
        useDownloadEstimationRequestAttachmentQuery,
        {
          id,
        },
        {
          fetchPolicy: 'network-only',
        },
      ).toPromise();
    } catch (_error) {
      // nop: useErrorHandlableRelayEnvironmentでエラー処理している
      return;
    } finally {
      enableSoftDelete();
    }

    window.open(data?.estimationRequestAttachmentURL.url, '_blank', 'noopener, noreferrer');
  };

  return {
    downloadEstimationRequestAttachment,
  };
};
