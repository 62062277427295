import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddOne, Copy, Eyes, PreviewClose } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ChangeOrderButton } from 'components/ChangeOrderButton';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { ErrorMessage } from 'components/ErrorMessage';
import { ItemAmountPreview } from 'components/ItemAmountPreview';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import { useQueryParams } from 'features/estimation/hooks/useEstimatoinQueryPrams';
import {
  DOCUMENT_DISPLAY_STATUS,
  DocumentDisplayStatusType,
  FormType,
} from 'features/estimation/type';
import {
  filterByDocumentDisplayStatusShown,
  isDocumentDisplayStatusHidden,
  isDocumentDisplayStatusShown,
} from 'features/estimation/util';
import {
  ZodEstimationFormData,
  ZodGrossMarginRatio,
  estimationFormSchema,
  grossMarginRatioSchema,
} from 'features/estimation/zod';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import {
  EstimationFormFragment$data,
  EstimationFormFragment$key,
} from 'gql/__generated__/EstimationFormFragment.graphql';
import { toast } from 'lib/toast';
import TextEstimationDocumentDisplayStatus from 'public/images/text_estimation_document_display_status.svg';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import {
  calculateDetailAmount,
  calculateGrossProfitMargin,
  calculateGrossProfitUnitPrice,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalGrossProfitAmount,
  calculateTotalGrossProfitMargin,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

type Props = {
  type?: FormType;
  data: ZodEstimationFormData;
  queryRef: EstimationFormFragment$key;
  defaultGrossMarginRatio?: number;
  previousUrl?: string;
  onClickToConfirm: (data: ZodEstimationFormData) => void;
};

const getBaseDetail = (
  taxCategories: EstimationFormFragment$data['taxCategories'],
): {
  name: string;
  unitPrice: string;
  quantity: string;
  unitSellingPrice: string;
  documentDisplayStatus: DocumentDisplayStatusType;
  tax: { id: string; rate: string | number };
} => {
  return {
    name: '',
    unitPrice: '',
    quantity: '',
    unitSellingPrice: '',
    documentDisplayStatus: DOCUMENT_DISPLAY_STATUS.shown,
    tax: {
      id: (taxCategories?.edges || [])[0]?.node?.id || '',
      rate: (taxCategories?.edges || [])[0]?.node?.rate || '',
    },
  };
};

const getPageLabel = (type: FormType) => {
  switch (type) {
    case 'new':
      return '見積書の作成';
    case 'edit':
      return '見積書の編集';
  }
};

const estimationFormFragment = graphql`
  fragment EstimationFormFragment on Query {
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
    ...TaxSelectBoxFragment
  }
`;

const GrossMarginRatioInput = ({
  grossMarginRatio,
  onChange,
}: { grossMarginRatio?: number; onChange: (grossMarginRatio: number) => void }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ZodGrossMarginRatio>({
    resolver: zodResolver(grossMarginRatioSchema),
    defaultValues: {
      grossMarginRatio,
    },
  });

  const handleGrossMarginRatioSubmit = handleSubmit((data) => {
    if (Number.isNaN(data.grossMarginRatio)) return;
    onChange(data.grossMarginRatio);
  });

  return (
    <FormControl isInvalid={!!errors?.grossMarginRatio} w="auto">
      <VStack align="stretch" spacing={2}>
        <HStack
          align="center"
          borderRadius="md"
          backgroundColor="gray.50"
          py={2}
          px={4}
          spacing={8}
        >
          <Text>仕入れ単価 ×</Text>
          <HStack spacing={2}>
            <HStack spacing={1}>
              <ConfigurableNumberInput
                {...register(`grossMarginRatio`, { valueAsNumber: true })}
                w="5rem"
                size="sm"
                borderRadius="md"
                backgroundColor="white"
                type="decimal"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleGrossMarginRatioSubmit();
                  }
                }}
              />
              <Text>%で顧客単価を</Text>
            </HStack>
            <Button colorScheme="blue" size="sm" onClick={handleGrossMarginRatioSubmit}>
              一括変更
            </Button>
          </HStack>
        </HStack>
        <ErrorMessage errors={errors} name="grossMarginRatio" />
      </VStack>
    </FormControl>
  );
};

export const EstimationForm = ({
  data,
  queryRef,
  onClickToConfirm,
  type = 'new',
  defaultGrossMarginRatio,
  previousUrl,
}: Props) => {
  const { queryParams } = useQueryParams();
  const query = useFragment(estimationFormFragment, queryRef);
  const { taxCategories } = query;
  const [isDisabledDelete, setIsDisabledDelete] = useState(data.details.length === 1);
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = useForm<ZodEstimationFormData>({
    resolver: zodResolver(estimationFormSchema),
    defaultValues: data,
  });

  const watchShowAmountSummary = watch('showAmountSummary');
  const watchDetails = watch('details');
  const detailsForCalculation = filterByDocumentDisplayStatusShown(watchDetails).map((detail) => ({
    quantity: detail.quantity || 0,
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    tax: detail.tax,
  }));

  const demandId = watch('company').id;

  watchDetails.forEach((detail, index) => {
    // nameにタブ文字を含む場合は全角スペースに置換する
    if (/\t/.test(detail.name)) {
      setValue(`details.${index}.name`, detail.name.replace(/\t/g, '　'));
    }
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'details',
  });

  const onSubmit = (data: ZodEstimationFormData) => onClickToConfirm(data);

  const appendDetail = () => {
    append({ ...getBaseDetail(taxCategories) });
  };

  const onClearCompanyInput = () => {
    setValue('company', { id: '', name: '' });
  };

  const onClearSupplierInput = () => setValue('supplier', { id: '', name: '' });

  useEffect(() => {
    if (fields.length === 1) {
      setIsDisabledDelete(true);
    } else {
      setIsDisabledDelete(false);
    }
  }, [fields]);

  const handleClickUp = (index: number) => {
    const details = getValues('details');
    details.splice(index - 1, 2, details[index], details[index - 1]);
    setValue('details', details);
  };

  const handleClickDown = (index: number) => {
    const details = getValues('details');
    details.splice(index, 2, details[index + 1], details[index]);
    setValue('details', details);
  };

  const handleDuplicationItem = (index: number) => {
    const value = getValues(`details.${index}`);
    insert(
      index + 1,
      { ...value },
      { shouldFocus: true, focusName: `details.${index + 1}.quantity` },
    );
  };

  const handleChangeGrossMarginRatio = (grossMarginRatio: number) => {
    const details = getValues('details');
    const newDetails = details.map((detail) => {
      if (!detail.unitPrice) {
        return detail;
      }
      const unitPrice = detail.unitPrice;
      const unitSellingPrice = calculateGrossProfitUnitPrice({
        unitPrice,
        grossProfitMargin: grossMarginRatio / 100,
      });
      return {
        ...detail,
        unitSellingPrice,
      };
    });
    setValue('details', newDetails);
    toast({
      title: '粗利率を適用しました',
      status: 'success',
    });
  };

  const isDisabledUpButton = (index: number) => index === 0;

  const isDisabledDownButton = (index: number) => index + 1 === fields.length;

  const isDisabledDocumentDisplayStatusButton = (index: number) =>
    filterByDocumentDisplayStatusShown(getValues('details')).length === 1 &&
    isDocumentDisplayStatusShown(getValues(`details.${index}.documentDisplayStatus`));

  return (
    <>
      <Box mb={6}>
        <Link
          to={
            previousUrl ??
            paths.estimation.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              supplier: queryParams.supplier,
            })
          }
        >
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label={getPageLabel(type)} />
      </Box>
      <Box w="992px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={6} alignItems="flex-start">
            <HStack gap={4} align="top" w="4xl">
              <FormControl isInvalid={!!errors.company} isRequired>
                <VStack align="stretch" spacing={2}>
                  <Box>
                    <FormLabel>デマンド</FormLabel>
                    <Controller
                      name="company"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CompanyComboBox
                          onChangeSelected={(value) => {
                            onChange(value);
                          }}
                          defaultSelectedItem={getValues('company')}
                          onClearInput={onClearCompanyInput}
                        />
                      )}
                    />
                    <ErrorMessage name="company" errors={errors} />
                  </Box>
                  <DemandMemo demandId={demandId} />
                </VStack>
              </FormControl>
              <FormControl isInvalid={!!errors.supplier} isRequired>
                <FormLabel>サプライヤー</FormLabel>
                <Controller
                  name="supplier"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <SupplierComboBox
                      onChangeSelected={onChange}
                      defaultSelectedItem={getValues('supplier')}
                      onClearInput={onClearSupplierInput}
                    />
                  )}
                />
                <ErrorMessage name="supplier" errors={errors} />
              </FormControl>
            </HStack>

            <Box w="4xl">
              <HStack spacing={5} align="top" w="4xl">
                <FormControl isInvalid={!!errors.title} isRequired>
                  <FormLabel>件名</FormLabel>
                  <Input type="string" {...register('title')} />
                  <ErrorMessage name="title" errors={errors} />
                </FormControl>
                <FormControl isInvalid={!!errors.expirationPeriod} isRequired w={200}>
                  <FormLabel>有効期限</FormLabel>
                  <Input type="string" {...register('expirationPeriod')} />
                  <ErrorMessage name="expirationPeriod" errors={errors} />
                </FormControl>
              </HStack>
            </Box>

            <GrossMarginRatioInput
              grossMarginRatio={defaultGrossMarginRatio}
              onChange={handleChangeGrossMarginRatio}
            />
            <VStack align="start">
              <Image
                src={TextEstimationDocumentDisplayStatus}
                alt="開いた目を閉じた目にすると、見積書に記載せず、単価を記録しておけます"
              />

              <VStack align="stretch" spacing={0}>
                <HStack align="flex-start" spacing={2}>
                  <Box w={16} />
                  <CustomFormLabel isRequired w="16rem">
                    品名
                  </CustomFormLabel>
                  <CustomFormLabel isRequired w="7.5rem">
                    数量
                  </CustomFormLabel>
                  <CustomFormLabel isRequired w="7.5rem">
                    仕入単価
                  </CustomFormLabel>
                  <CustomFormLabel isRequired w="7.5rem">
                    顧客単価
                  </CustomFormLabel>
                  <CustomFormLabel isRequired w="7.5rem">
                    税区分
                  </CustomFormLabel>
                  <CustomFormLabel w="7.5rem">金額 (税抜)</CustomFormLabel>
                </HStack>
                <VStack align="stretch" spacing={4}>
                  {fields.map((field, index) => (
                    <HStack key={field.id} align="flex-start" spacing={2}>
                      {/* 並び順変更する矢印 */}
                      <VStack>
                        <ChangeOrderButton
                          type="up"
                          onClick={() => handleClickUp(index)}
                          isDisabled={isDisabledUpButton(index)}
                        />
                        <ChangeOrderButton
                          type="down"
                          onClick={() => handleClickDown(index)}
                          isDisabled={isDisabledDownButton(index)}
                        />
                      </VStack>

                      <IconButton
                        variant="solid"
                        isRound
                        aria-label="shown"
                        bgColor={
                          isDocumentDisplayStatusShown(
                            getValues(`details.${index}.documentDisplayStatus`),
                          )
                            ? 'blue.100'
                            : ''
                        }
                        _hover={{
                          bgColor: !isDisabledDocumentDisplayStatusButton(index)
                            ? isDocumentDisplayStatusShown(
                                getValues(`details.${index}.documentDisplayStatus`),
                              )
                              ? 'blue.200'
                              : 'gray.200'
                            : '',
                        }}
                        icon={
                          isDocumentDisplayStatusShown(
                            getValues(`details.${index}.documentDisplayStatus`),
                          ) ? (
                            <Eyes color="blue.700" />
                          ) : (
                            <PreviewClose />
                          )
                        }
                        onClick={() => {
                          const currentValue = getValues(`details.${index}.documentDisplayStatus`);
                          if (isDocumentDisplayStatusShown(currentValue)) {
                            setValue(
                              `details.${index}.documentDisplayStatus`,
                              DOCUMENT_DISPLAY_STATUS.hidden,
                            );
                          } else if (isDocumentDisplayStatusHidden(currentValue)) {
                            setValue(
                              `details.${index}.documentDisplayStatus`,
                              DOCUMENT_DISPLAY_STATUS.shown,
                            );
                          }
                        }}
                        isDisabled={isDisabledDocumentDisplayStatusButton(index)}
                      />

                      {/* 品名 */}
                      <FormControl
                        isInvalid={!!(errors.details || [])[index]?.name}
                        isRequired
                        w="16rem"
                      >
                        <AutoResizeTextarea {...register(`details.${index}.name`)} />
                        <ErrorMessage name={`details.${index}.name`} errors={errors} />
                      </FormControl>

                      {/* 数量 */}
                      <FormControl
                        isInvalid={!!(errors.details || [])[index]?.quantity}
                        isRequired
                        flex={1}
                        w="7.5rem"
                      >
                        <ConfigurableNumberInput {...register(`details.${index}.quantity`)} />
                        <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
                      </FormControl>

                      {/* 仕入単価 */}
                      <FormControl
                        isInvalid={!!(errors.details || [])[index]?.unitPrice}
                        isRequired
                        flex={1}
                        w="7.5rem"
                      >
                        <ConfigurableNumberInput
                          {...register(`details.${index}.unitPrice`)}
                          step="0.01"
                        />
                        <ErrorMessage name={`details.${index}.unitPrice`} errors={errors} />
                      </FormControl>

                      {/* 顧客単価 */}
                      <FormControl
                        isInvalid={!!(errors.details || [])[index]?.unitSellingPrice}
                        isRequired
                        flex={1}
                        w="7.5rem"
                      >
                        <ConfigurableNumberInput
                          {...register(`details.${index}.unitSellingPrice`)}
                          step="0.01"
                        />
                        <Text fontSize="sm" color="gray.500" textAlign="right" mt={2}>
                          粗利率:{' '}
                          {calculateGrossProfitMargin(
                            Number(getValues(`details.${index}.unitPrice`)) || 0,
                            Number(getValues(`details.${index}.unitSellingPrice`)) || 0,
                          )}
                          %
                        </Text>
                        <ErrorMessage name={`details.${index}.unitSellingPrice`} errors={errors} />
                      </FormControl>

                      {/* 税区分 */}
                      <FormControl
                        isInvalid={!!(errors.details || [])[index]?.tax?.id}
                        isRequired
                        flex={1}
                        w="7.5rem"
                      >
                        <Controller
                          name={`details.${index}.tax`}
                          control={control}
                          render={() => (
                            <TaxSelectBox
                              defaultValue={getValues(`details.${index}.tax.id`)}
                              queryRef={query}
                              onChange={(e) => {
                                setValue(`details.${index}.tax`, {
                                  ...getValues(`details.${index}.tax`),
                                  ...{
                                    id: e.target.value,
                                    rate: e.target[e.target.selectedIndex].getAttribute(
                                      'data-rate',
                                    ) as string,
                                  },
                                });
                              }}
                            />
                          )}
                        />
                        <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
                      </FormControl>

                      {/* 金額 */}
                      <Box w="7.5rem">
                        <ItemAmountPreview
                          amount={calculateDetailAmount({
                            quantity: getValues(`details.${index}.quantity`) || 0,
                            price: getValues(`details.${index}.unitSellingPrice`) || 0,
                          })}
                        />
                      </Box>

                      {/* 複製ボタン */}
                      <Box>
                        <IconButton
                          onClick={() => handleDuplicationItem(index)}
                          variant="ghost"
                          isRound
                          aria-label="copy"
                          icon={<Copy />}
                        />
                      </Box>

                      {/* 削除ボタン */}
                      <Box>
                        <IconButton
                          onClick={() => remove(index)}
                          variant="ghost"
                          isDisabled={isDisabledDelete}
                          isRound
                          aria-label="close"
                          icon={<CloseIcon />}
                        />
                      </Box>
                    </HStack>
                  ))}
                  <Button
                    w="full"
                    colorScheme="gray"
                    leftIcon={<AddOne />}
                    onClick={() => appendDetail()}
                  >
                    追加
                  </Button>
                </VStack>
              </VStack>
            </VStack>

            <HStack spacing={6} align="flex-start" justifyContent="space-between" w="4xl">
              <VStack w="70%">
                <FormControl isInvalid={!!errors.detailMessage}>
                  <FormLabel>備考</FormLabel>
                  <Textarea {...register('detailMessage')} rows={8} />
                  <ErrorMessage name="detailMessage" errors={errors} />
                </FormControl>
                <FormControl isInvalid={!!errors.internalAssignees} isRequired>
                  <FormLabel>担当者</FormLabel>
                  <Controller
                    name="internalAssignees"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <UserMultipleSelectBox
                        onChange={onChange}
                        defaultValue={getValues('internalAssignees')}
                        menuPlacement="top"
                      />
                    )}
                  />
                  <ErrorMessage name="internalAssignees" errors={errors} />
                </FormControl>
              </VStack>
              <Flex direction="column" w="240px" gap={2}>
                <Flex justify="space-between" opacity={watchShowAmountSummary ? 1 : 0.3}>
                  <Box w="140px" fontSize="sm">
                    小計
                  </Box>
                  <Box fontSize="sm">
                    <>{calculateSubtotalAmount(detailsForCalculation).toLocaleString()}</>
                  </Box>
                </Flex>
                <Flex
                  justify="space-between"
                  color={watchShowAmountSummary ? 'gray.500' : 'inherit'}
                  opacity={watchShowAmountSummary ? 1 : 0.3}
                >
                  <Box
                    w="140px"
                    fontSize="xs"
                    borderColor="gray.200"
                    borderStyle="solid"
                    borderLeftWidth="2px"
                    pl={2}
                    height="1.5rem"
                  >
                    粗利
                  </Box>
                  <Box fontSize="xs">
                    <>
                      {`${calculateTotalGrossProfitAmount(
                        detailsForCalculation,
                      ).toLocaleString()} (${calculateTotalGrossProfitMargin(
                        detailsForCalculation,
                      )}%)`}
                    </>
                  </Box>
                </Flex>
                <Flex justify="space-between" opacity={watchShowAmountSummary ? 1 : 0.3}>
                  <Box whiteSpace="nowrap" w="140px" fontSize="sm">
                    消費税
                  </Box>
                  <Box fontSize="sm">
                    <>{calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}</>
                  </Box>
                </Flex>
                <Flex justify="space-between" opacity={watchShowAmountSummary ? 1 : 0.3}>
                  <Box w="140px" fontWeight="bold">
                    合計金額
                  </Box>
                  <Box fontWeight="bold">
                    <>{calculateTotalAmount(detailsForCalculation).toLocaleString()}</>
                  </Box>
                </Flex>

                <Checkbox {...register('showAmountSummary')} spacing={1}>
                  合計金額を見積書に表示
                </Checkbox>
              </Flex>
            </HStack>
            <Box w="4xl">
              <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
                確認画面へ進む
              </Button>
            </Box>
          </VStack>
        </form>
      </Box>
    </>
  );
};
