import { Box } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';
import { EstimationRequestSearchQueryProvider } from 'features/estimationRequest/components/provider/SearchQueryProvider';
import { Outlet } from 'react-router-dom';

export const EstimationRequestPageLayout = () => (
  <EstimationRequestSearchQueryProvider>
    <>
      <Box mb={8}>
        <PageHeading label="見積依頼" />
      </Box>
      <Outlet />
    </>
  </EstimationRequestSearchQueryProvider>
);
