import { Heading } from '@chakra-ui/react';

type Props = {
  label: string;
};

export function PageHeading({ label }: Props) {
  return (
    <Heading as="h1" size="lg">
      {label}
    </Heading>
  );
}
