/**
 * @generated SignedSource<<28698a1c6bf50ef5ba776c8aa8d880e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type edit_SalesOrderEditPageQuery$variables = {
  id: string;
};
export type edit_SalesOrderEditPageQuery$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly userID: string;
  };
  readonly salesOrders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly demand: {
          readonly id: string;
          readonly name: string;
        };
        readonly detailMessage: string | null;
        readonly details: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly deliveryEvents: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly fixedDeliveryDate: any;
                  } | null;
                } | null> | null;
              };
              readonly id: string;
              readonly item: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly tax: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitPrice: number;
                readonly unitSellingPrice: number;
              } | null;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
            }> | null;
          };
        }> | null;
        readonly internalMemos: ReadonlyArray<{
          readonly body: string;
        }> | null;
        readonly invoices: ReadonlyArray<{
          readonly id: string;
        }> | null;
        readonly orders: ReadonlyArray<{
          readonly id: string;
        }> | null;
        readonly receivedDate: any;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
        readonly title: string;
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
};
export type edit_SalesOrderEditPageQuery = {
  response: edit_SalesOrderEditPageQuery$data;
  variables: edit_SalesOrderEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "demand",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = [
  (v2/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Order",
  "kind": "LinkedField",
  "name": "orders",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Invoice",
  "kind": "LinkedField",
  "name": "invoices",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detailMessage",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedDate",
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "ASC",
      "field": "ORDER_NUMBER"
    }
  }
],
v15 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "isValid": true
    }
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixedDeliveryDate",
  "storageKey": null
},
v17 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rate",
    "storageKey": null
  }
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "SalesOrderItem",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitSellingPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": "tax",
      "args": null,
      "concreteType": "TaxCategory",
      "kind": "LinkedField",
      "name": "taxCategory",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "TaxCategoryConnection",
  "kind": "LinkedField",
  "name": "taxCategories",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategory",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userID",
      "storageKey": null
    },
    (v20/*: any*/),
    (v19/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "edit_SalesOrderEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SalesOrderConnection",
        "kind": "LinkedField",
        "name": "salesOrders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": (v14/*: any*/),
                    "concreteType": "SalesOrderDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": (v15/*: any*/),
                                "concreteType": "SalesOrderDetailDeliveryEventConnection",
                                "kind": "LinkedField",
                                "name": "deliveryEvents",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrderDetailDeliveryEventEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SalesOrderDetailDeliveryEvent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v16/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                              },
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v21/*: any*/),
      (v22/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "edit_SalesOrderEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SalesOrderConnection",
        "kind": "LinkedField",
        "name": "salesOrders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": (v14/*: any*/),
                    "concreteType": "SalesOrderDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": (v15/*: any*/),
                                "concreteType": "SalesOrderDetailDeliveryEventConnection",
                                "kind": "LinkedField",
                                "name": "deliveryEvents",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrderDetailDeliveryEventEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SalesOrderDetailDeliveryEvent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v16/*: any*/),
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                              },
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v21/*: any*/),
      (v22/*: any*/)
    ]
  },
  "params": {
    "cacheID": "54d9661381a723599c355168ad65e792",
    "id": null,
    "metadata": {},
    "name": "edit_SalesOrderEditPageQuery",
    "operationKind": "query",
    "text": "query edit_SalesOrderEditPageQuery(\n  $id: ID!\n) {\n  salesOrders(where: {id: $id}) {\n    edges {\n      node {\n        id\n        demand {\n          id\n          name\n        }\n        supplier {\n          id\n          name\n        }\n        orders {\n          id\n        }\n        invoices {\n          id\n        }\n        internalMemos {\n          body\n          id\n        }\n        title\n        detailMessage\n        receivedDate\n        details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {\n          edges {\n            node {\n              id\n              deliveryEvents(where: {isValid: true}) {\n                edges {\n                  node {\n                    fixedDeliveryDate\n                    id\n                  }\n                }\n              }\n              item {\n                id\n                name\n                unitPrice\n                unitSellingPrice\n                quantity\n                tax: taxCategory {\n                  id\n                  rate\n                }\n              }\n            }\n          }\n        }\n        internalAssignees {\n          user {\n            id\n            profile {\n              lastName\n              firstName\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  taxCategories {\n    edges {\n      node {\n        id\n        rate\n      }\n    }\n  }\n  currentUser {\n    userID\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "16448904845323ffeefb26a766e9370d";

export default node;
