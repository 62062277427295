import { cdate } from 'cdate';
import { Environments } from 'constants/environment';
import { getEnvironment } from './environment';

const LOG_LEVEL_DEBUG = 'DEBUG';
const LOG_LEVEL_INFO = 'INFO';
const LOG_LEVEL_WARN = 'WARN';
const LOG_LEVEL_ERROR = 'ERR';
const LOG_LEVEL_FATAL = 'FATAL';

type LogLevel =
  | typeof LOG_LEVEL_DEBUG
  | typeof LOG_LEVEL_INFO
  | typeof LOG_LEVEL_WARN
  | typeof LOG_LEVEL_ERROR
  | typeof LOG_LEVEL_FATAL;

const log = (level: LogLevel, message?: unknown, ...optionalParams: unknown[]) => {
  const timestamp = cdate().format('YYYY-MM-DD HH:mm:ss.SSS');
  switch (level) {
    case LOG_LEVEL_DEBUG:
      if (getEnvironment() === Environments.Production) return;
      console.log(`[${timestamp}]`, `[${level}]`, message, ...optionalParams);
      break;
    case LOG_LEVEL_INFO:
      console.info(`[${timestamp}]`, `[${level}]`, message, ...optionalParams);
      break;
    case LOG_LEVEL_WARN:
      console.warn(`[${timestamp}]`, `[${level}]`, message, ...optionalParams);
      break;
    case LOG_LEVEL_ERROR:
    case LOG_LEVEL_FATAL:
      // TODO: Sentryに送信したい
      console.error(`[${timestamp}]`, `[${level}]`, message, ...optionalParams);
      break;
  }
};

const debug = (message?: unknown, ...optionalParams: unknown[]) => {
  log(LOG_LEVEL_DEBUG, message, ...optionalParams);
};

const info = (message?: unknown, ...optionalParams: unknown[]) => {
  log(LOG_LEVEL_INFO, message, ...optionalParams);
};

const warn = (message?: unknown, ...optionalParams: unknown[]) => {
  log(LOG_LEVEL_WARN, message, ...optionalParams);
};

const error = (message?: unknown, ...optionalParams: unknown[]) => {
  log(LOG_LEVEL_ERROR, message, ...optionalParams);
};

const fatal = (message?: unknown, ...optionalParams: unknown[]) => {
  log(LOG_LEVEL_FATAL, message, ...optionalParams);
};

export const logger = {
  debug,
  info,
  warn,
  error,
  fatal,
};
