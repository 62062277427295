import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Heading: ComponentStyleConfig = {
  sizes: {
    md: {
      fontSize: '1.5rem',
    },
    lg: {
      fontSize: '2rem',
    },
  },
  defaultProps: {
    colorScheme: 'gray.800',
  },
};
