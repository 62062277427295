import { useInvoiceCreateFormMutation } from 'gql/__generated__/useInvoiceCreateFormMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { cdateJST } from 'utils/date';
import { paths } from 'utils/paths';
import {
  INVOICE_SUBMIT_TYPE,
  InvoiceInputFormValueType,
  InvoiceSalesOrdersSelectFormValueType,
  InvoiceSubmitType,
} from '../form';

const DETAIL_MESSAGE_TEMPLATE = `・恐れ入りますが、振込手数料は貴社にてご負担ください。
`;

const initialSelectValue = { demand: { id: '', name: '' }, salesOrderDetailIds: [] };
export const initialInputValue = {
  title: '',
  dates: {
    bookedAt: '',
    paymentDueAt: '',
  },
  detailMessage: DETAIL_MESSAGE_TEMPLATE,
  memo: '',
  details: [],
};

export const useInvoiceCreateForm = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<InvoiceSubmitType>(INVOICE_SUBMIT_TYPE.select);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fetchIds, setFetchIds] = useState<string[]>([]);
  const [salesOrderSelectValues, setSalesOrderSelectValues] =
    useState<InvoiceSalesOrdersSelectFormValueType>(initialSelectValue);
  const [invoiceInputValues, setInvoiceInputValues] =
    useState<InvoiceInputFormValueType>(initialInputValue);

  const { commitMutation, isMutationInFlight } = useMutationWrapper<useInvoiceCreateFormMutation>(
    graphql`
        mutation useInvoiceCreateFormMutation(
          $input: InvoiceInput!
        ) {
          createInvoice(input: $input) {
            id
          }
        }
      `,
  );

  const fromSelectToInput = (data: InvoiceSalesOrdersSelectFormValueType) => {
    setSalesOrderSelectValues({ ...data });

    // select → input → selectと遷移した時の対応
    // 選択された明細のIDとすでに入力されている明細のIDを比較して、入力されていない明細のIDを抽出する
    // 抽出されたIDを入力画面にてfetchする
    const inputedIds = invoiceInputValues.details
      .flatMap((detail) => detail.salesOrderDetailId)
      .filter((v): v is NonNullable<typeof v> => v != null);
    const ids = data.salesOrderDetailIds.filter((id) => !inputedIds.includes(id));
    setFetchIds(ids);

    // select → input → selectと遷移した時の対応
    // 選択画面で選択解除された明細をすでに入力されている明細から取り除く
    const details = invoiceInputValues.details
      .filter(
        (detail) =>
          !detail.salesOrderDetailId ||
          data.salesOrderDetailIds.includes(detail.salesOrderDetailId || ''),
      )
      .filter((v): v is NonNullable<typeof v> => v != null);
    setInvoiceInputValues((state) => ({ ...state, details: details }));

    // 画面遷移
    setCurrentPage(INVOICE_SUBMIT_TYPE.input);
    setSearchParams({
      type: INVOICE_SUBMIT_TYPE.input,
    });
  };

  const fromInputToSelect = (data: InvoiceInputFormValueType) => {
    setInvoiceInputValues({ ...data });
    setCurrentPage(INVOICE_SUBMIT_TYPE.select);
    searchParams.delete('type');
    setSearchParams(searchParams);
    setFetchIds([]);
  };

  const fromInputToConfirm = (data: InvoiceInputFormValueType) => {
    setInvoiceInputValues({ ...data });
    setCurrentPage(INVOICE_SUBMIT_TYPE.confirm);
    setSearchParams({
      type: INVOICE_SUBMIT_TYPE.confirm,
    });
    setFetchIds([]);
  };

  const fromConfirmToInput = () => {
    setCurrentPage(INVOICE_SUBMIT_TYPE.input);
    setSearchParams({
      type: INVOICE_SUBMIT_TYPE.input,
    });
  };

  const onSubmit = () => {
    const details = invoiceInputValues.details.map((detail, index) => ({
      ...detail,
      orderNumber: index + 1,
    }));

    commitMutation({
      variables: {
        input: {
          demandID: salesOrderSelectValues.demand.id,
          title: invoiceInputValues.title,
          detailMessage: invoiceInputValues.detailMessage || '',
          bookedAt: cdateJST(invoiceInputValues.dates.bookedAt).toDate(),
          paymentDueAt: cdateJST(invoiceInputValues.dates.paymentDueAt).toDate(),
          internalMemo: invoiceInputValues.memo,
          details: details.map((detail) => ({
            // salesOrderDetailIDが空文字の場合はundefinedに変換する(xidのパースエラーになってしまうため)
            salesOrderDetailID: detail.salesOrderDetailId || undefined,
            salesDate: detail.salesDate ? cdateJST(detail.salesDate).toDate() : null,
            orderNumber: detail.orderNumber,
            type: 'item',
            Item: {
              name: detail.name,
              unitSellingPrice: Number(detail.unitSellingPrice) || 0,
              quantity: Number(detail.quantity) || 0,
              taxCategoryID: detail.tax.id,
            },
          })),
        },
      },
      onCompleted: (res) => {
        toast({
          title: '請求書を作成しました',
          status: 'success',
        });
        navigate(paths.invoice._id(res.createInvoice.id));
      },
    });
  };

  return {
    currentPage,
    salesOrderSelectValues,
    invoiceInputValues,
    fromSelectToInput,
    fromInputToSelect,
    fromConfirmToInput,
    fromInputToConfirm,
    onSubmit,
    fetchIds,
    isMutationInFlight,
  };
};
