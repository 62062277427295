import { Box } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';
import { Outlet } from 'react-router-dom';

export const SupplierPageLayout = () => (
  <>
    <Box mb={8}>
      <PageHeading label="サプライヤー" />
    </Box>
    <Outlet />
  </>
);
