import { Button, HStack } from '@chakra-ui/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { LinkageFreeeModal } from 'components/LinkageFreeeModal';
import { useDeleteInvoice } from 'features/invoice/hooks/useDeleteInvoice';
import { useLinkageFreee } from 'features/invoice/hooks/useLinkageFreee';
import { InvoiceDetailHeaderFragment$key } from 'gql/__generated__/InvoiceDetailHeaderFragment.graphql';
import { useState } from 'react';
import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

type Props = {
  invoiceId: string;
  queryRef: InvoiceDetailHeaderFragment$key;
};

const InvoiceDetailHeaderFragment = graphql`
  fragment InvoiceDetailHeaderFragment on Query {
    userInfo {
      connectedServices {
        freee
      }
    }
  }
`;

export const InvoiceDetailHeader = ({ invoiceId, queryRef }: Props) => {
  const { userInfo } = useFragment(InvoiceDetailHeaderFragment, queryRef);
  const { onSubmit, isMutationInFlight } = useDeleteInvoice();

  const needFreeeLinkage = !userInfo.connectedServices.freee;
  const [isOpenLinkageDialog, setIsOpenLinkageDialog] = useState(false);
  const { onLinkageFreeeApi } = useLinkageFreee();

  return (
    <HStack spacing={4}>
      <LinkageFreeeModal
        isOpen={isOpenLinkageDialog}
        closeButtonLabel="キャンセル"
        onClose={() => {
          setIsOpenLinkageDialog(false);
        }}
        onClickLinkage={onLinkageFreeeApi}
      ></LinkageFreeeModal>
      <ConfirmModalButton
        header="削除しますか？"
        button={({ onOpen }) => (
          <Button
            onClick={
              needFreeeLinkage
                ? () => {
                    setIsOpenLinkageDialog(true);
                  }
                : onOpen
            }
            isDisabled={isMutationInFlight}
            isLoading={isMutationInFlight}
            loadingText="削除中"
          >
            削除
          </Button>
        )}
        footer={({ onClose }) => (
          <HStack spacing={3}>
            <Button onClick={onClose}>キャンセル</Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                onClose();
                onSubmit(invoiceId);
              }}
            >
              削除
            </Button>
          </HStack>
        )}
      />
      <Button
        isDisabled={isMutationInFlight}
        colorScheme="blue"
        as={Link}
        to={paths.invoice.edit._id(invoiceId)}
      >
        編集
      </Button>
    </HStack>
  );
};
