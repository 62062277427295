import { Text, TextProps, useStyleConfig } from '@chakra-ui/react';
import { memo } from 'react';

type Props = {
  children: React.ReactNode;
  isRequired?: boolean;
} & TextProps;

export const CustomFormLabel = memo(({ children, isRequired = false, ...props }: Props) => {
  const styles = useStyleConfig('FormLabel');

  return (
    <Text {...props} sx={{ ...styles, display: 'block', textAlign: 'start', marginEnd: 0 }}>
      {children}
      {isRequired && (
        <Text
          as="span"
          role="presentation"
          aria-hidden="true"
          // FYI: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/form-control/src/form-label.tsx
          className="chakra-form__required-indicator css-1tfjd1n"
        >
          *
        </Text>
      )}
    </Text>
  );
});

CustomFormLabel.displayName = 'CustomFormLabel';
