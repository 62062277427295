import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { DemandItemFormSpec, DemandItemFormType } from 'features/demandItem/form';
import {
  DEMAND_ITEM_CATEGORY,
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
} from 'features/demandItem/models';
import { useFormContext } from 'react-hook-form';
import { DemandItemPriceTable } from '../DemandItemPriceTable';
import { DemandItemConfirmSpec } from './DemandItemConfirmSpec';

type Props = {
  onSubmit: (data: DemandItemFormType) => void;
  isSubmitting: boolean;
  isDisabledSubmit?: boolean;
};

export const DemandItemConfirm = ({ onSubmit, isDisabledSubmit = false }: Props) => {
  const form = useFormContext<DemandItemFormType>();
  const {
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const {
    name,
    category,
    supplier,
    memo,
    prices,
    cardboard,
    flexiblePackage,
    giftBox,
    paperBag,
    other,
  } = getValues();

  const specValueMap: Record<DemandItemCategory, DemandItemFormSpec> = {
    [DEMAND_ITEM_CATEGORY.Cardboard]: cardboard,
    [DEMAND_ITEM_CATEGORY.FlexiblePackage]: flexiblePackage,
    [DEMAND_ITEM_CATEGORY.GiftBox]: giftBox,
    [DEMAND_ITEM_CATEGORY.PaperBag]: paperBag,
    [DEMAND_ITEM_CATEGORY.Other]: other,
  } as const;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w="1024px" pb={8}>
        <VStack spacing={4} alignItems="flex-start" justifyContent="space-between">
          <DemandItemConfirmSpec
            category={category}
            itemName={name}
            values={specValueMap[category]}
          />
          <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%">
            <VStack spacing={3} alignItems="flex-start" fontSize="sm">
              <HStack spacing={2}>
                <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right">
                  サプライヤー
                </Box>
                <Box>{supplier.name}</Box>
              </HStack>
              <HStack spacing={2}>
                <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right">
                  カテゴリー
                </Box>
                <Box>{DEMAND_ITEM_CATEGORY_LABEL[category].categoryName}</Box>
              </HStack>
              <HStack spacing={2} alignItems="flex-start">
                <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right">
                  社内メモ
                </Box>
                <Box whiteSpace="pre-wrap">{memo}</Box>
              </HStack>
            </VStack>
            <Box w="382px">
              <DemandItemPriceTable
                values={prices.map((price, index) => ({
                  id: index.toString(),
                  quantity: price.quantity || 0,
                  unitPrice: price.unitPrice || 0,
                  unitSellingPrice: price.unitSellingPrice || 0,
                }))}
              />
            </Box>
          </HStack>
          <Button
            colorScheme="blue"
            w="full"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isDisabledSubmit}
          >
            登録
          </Button>
        </VStack>
      </Box>
    </form>
  );
};
