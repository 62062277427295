// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file model/estimation_response/v1/create_estimation_response.proto (package model.estimation_response.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Empty, Message, proto3 } from "@bufbuild/protobuf";
import { CardboardSpec, FlexiblePackageSpec, GiftBoxSpec, OtherSpec, PaperBagSpec } from "../../item/v1/category_pb.js";

/**
 * @generated from message model.estimation_response.v1.CreateEstimationResponse
 */
export class CreateEstimationResponse extends Message<CreateEstimationResponse> {
  /**
   * @generated from field: string uuid = 1;
   */
  uuid = "";

  /**
   * @generated from field: string detail_message = 2;
   */
  detailMessage = "";

  /**
   * @generated from field: string important_notes = 3;
   */
  importantNotes = "";

  /**
   * @generated from field: string expiration_period = 4;
   */
  expirationPeriod = "";

  /**
   * @generated from field: repeated model.estimation_response.v1.CreateEstimationResponseDetail details = 6;
   */
  details: CreateEstimationResponseDetail[] = [];

  constructor(data?: PartialMessage<CreateEstimationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.estimation_response.v1.CreateEstimationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "detail_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "important_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "expiration_period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "details", kind: "message", T: CreateEstimationResponseDetail, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEstimationResponse {
    return new CreateEstimationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEstimationResponse {
    return new CreateEstimationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEstimationResponse {
    return new CreateEstimationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEstimationResponse | PlainMessage<CreateEstimationResponse> | undefined, b: CreateEstimationResponse | PlainMessage<CreateEstimationResponse> | undefined): boolean {
    return proto3.util.equals(CreateEstimationResponse, a, b);
  }
}

/**
 * 見積回答明細
 *
 * @generated from message model.estimation_response.v1.CreateEstimationResponseDetail
 */
export class CreateEstimationResponseDetail extends Message<CreateEstimationResponseDetail> {
  /**
   * @generated from oneof model.estimation_response.v1.CreateEstimationResponseDetail.value
   */
  value: {
    /**
     * 商品の場合は必須
     *
     * @generated from field: model.estimation_response.v1.EstimationResponseDetailTypeItem item = 2;
     */
    value: EstimationResponseDetailTypeItem;
    case: "item";
  } | {
    /**
     * 費用の場合は必須
     *
     * @generated from field: model.estimation_response.v1.EstimationResponseDetailTypeCost cost = 3;
     */
    value: EstimationResponseDetailTypeCost;
    case: "cost";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateEstimationResponseDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.estimation_response.v1.CreateEstimationResponseDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "item", kind: "message", T: EstimationResponseDetailTypeItem, oneof: "value" },
    { no: 3, name: "cost", kind: "message", T: EstimationResponseDetailTypeCost, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEstimationResponseDetail {
    return new CreateEstimationResponseDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEstimationResponseDetail {
    return new CreateEstimationResponseDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEstimationResponseDetail {
    return new CreateEstimationResponseDetail().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEstimationResponseDetail | PlainMessage<CreateEstimationResponseDetail> | undefined, b: CreateEstimationResponseDetail | PlainMessage<CreateEstimationResponseDetail> | undefined): boolean {
    return proto3.util.equals(CreateEstimationResponseDetail, a, b);
  }
}

/**
 * 商品明細
 *
 * @generated from message model.estimation_response.v1.EstimationResponseDetailTypeItem
 */
export class EstimationResponseDetailTypeItem extends Message<EstimationResponseDetailTypeItem> {
  /**
   * 品名
   *
   * @generated from field: optional string name = 1;
   */
  name?: string;

  /**
   * 数量
   *
   * @generated from field: optional int64 quantity = 2;
   */
  quantity?: bigint;

  /**
   * 単価
   *
   * @generated from field: optional double unit_price = 3;
   */
  unitPrice?: number;

  /**
   * 関連する見積依頼明細ID
   *
   * @generated from field: optional string source_detail_id = 4;
   */
  sourceDetailId?: string;

  /**
   * 仕様
   *
   * @generated from oneof model.estimation_response.v1.EstimationResponseDetailTypeItem.spec
   */
  spec: {
    /**
     * 段ボールの場合
     *
     * @generated from field: model.item.v1.CardboardSpec cardboard_spec = 5;
     */
    value: CardboardSpec;
    case: "cardboardSpec";
  } | {
    /**
     * 軟包材の場合
     *
     * @generated from field: model.item.v1.FlexiblePackageSpec flexible_package_spec = 6;
     */
    value: FlexiblePackageSpec;
    case: "flexiblePackageSpec";
  } | {
    /**
     * 化粧箱の場合
     *
     * @generated from field: model.item.v1.GiftBoxSpec gift_box_spec = 7;
     */
    value: GiftBoxSpec;
    case: "giftBoxSpec";
  } | {
    /**
     * 紙袋の場合
     *
     * @generated from field: model.item.v1.PaperBagSpec paper_bag_spec = 8;
     */
    value: PaperBagSpec;
    case: "paperBagSpec";
  } | {
    /**
     * その他の場合
     *
     * @generated from field: model.item.v1.OtherSpec other_spec = 9;
     */
    value: OtherSpec;
    case: "otherSpec";
  } | {
    /**
     * 未知の場合: 仕様導入以前のデータ
     *
     * @generated from field: google.protobuf.Empty unknown_spec = 10;
     */
    value: Empty;
    case: "unknownSpec";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * 年間発注量
   *
   * @generated from field: optional string annual_quantity = 11;
   */
  annualQuantity?: string;

  /**
   * 希望単価
   *
   * @generated from field: optional string asking_unit_price = 12;
   */
  askingUnitPrice?: string;

  constructor(data?: PartialMessage<EstimationResponseDetailTypeItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.estimation_response.v1.EstimationResponseDetailTypeItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "unit_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "source_detail_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "cardboard_spec", kind: "message", T: CardboardSpec, oneof: "spec" },
    { no: 6, name: "flexible_package_spec", kind: "message", T: FlexiblePackageSpec, oneof: "spec" },
    { no: 7, name: "gift_box_spec", kind: "message", T: GiftBoxSpec, oneof: "spec" },
    { no: 8, name: "paper_bag_spec", kind: "message", T: PaperBagSpec, oneof: "spec" },
    { no: 9, name: "other_spec", kind: "message", T: OtherSpec, oneof: "spec" },
    { no: 10, name: "unknown_spec", kind: "message", T: Empty, oneof: "spec" },
    { no: 11, name: "annual_quantity", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "asking_unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimationResponseDetailTypeItem {
    return new EstimationResponseDetailTypeItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimationResponseDetailTypeItem {
    return new EstimationResponseDetailTypeItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimationResponseDetailTypeItem {
    return new EstimationResponseDetailTypeItem().fromJsonString(jsonString, options);
  }

  static equals(a: EstimationResponseDetailTypeItem | PlainMessage<EstimationResponseDetailTypeItem> | undefined, b: EstimationResponseDetailTypeItem | PlainMessage<EstimationResponseDetailTypeItem> | undefined): boolean {
    return proto3.util.equals(EstimationResponseDetailTypeItem, a, b);
  }
}

/**
 * 費用明細
 *
 * @generated from message model.estimation_response.v1.EstimationResponseDetailTypeCost
 */
export class EstimationResponseDetailTypeCost extends Message<EstimationResponseDetailTypeCost> {
  /**
   * 費用名
   *
   * @generated from field: optional string name = 1;
   */
  name?: string;

  /**
   * 数量
   *
   * @generated from field: optional int64 quantity = 2;
   */
  quantity?: bigint;

  /**
   * 単価
   *
   * @generated from field: optional double unit_price = 3;
   */
  unitPrice?: number;

  constructor(data?: PartialMessage<EstimationResponseDetailTypeCost>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.estimation_response.v1.EstimationResponseDetailTypeCost";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "unit_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimationResponseDetailTypeCost {
    return new EstimationResponseDetailTypeCost().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimationResponseDetailTypeCost {
    return new EstimationResponseDetailTypeCost().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimationResponseDetailTypeCost {
    return new EstimationResponseDetailTypeCost().fromJsonString(jsonString, options);
  }

  static equals(a: EstimationResponseDetailTypeCost | PlainMessage<EstimationResponseDetailTypeCost> | undefined, b: EstimationResponseDetailTypeCost | PlainMessage<EstimationResponseDetailTypeCost> | undefined): boolean {
    return proto3.util.equals(EstimationResponseDetailTypeCost, a, b);
  }
}

