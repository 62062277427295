/**
 * @generated SignedSource<<231adb392b3c7466896b6332f9373327>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandItemDetailBlocksRefetchQuery$variables = {
  demandId?: string | null;
};
export type DemandItemDetailBlocksRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailBlocksRefetchFragment">;
};
export type DemandItemDetailBlocksRefetchQuery = {
  response: DemandItemDetailBlocksRefetchQuery$data;
  variables: DemandItemDetailBlocksRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "demandId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "material",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printingColor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processing",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paperType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DemandItemDetailBlocksRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "demandId",
            "variableName": "demandId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "DemandItemDetailBlocksRefetchFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DemandItemDetailBlocksRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": {
              "direction": "DESC",
              "field": "UPDATED_AT"
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "demandID",
                "variableName": "demandId"
              },
              {
                "kind": "Literal",
                "name": "isCurrent",
                "value": true
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "DemandItemConnection",
        "kind": "LinkedField",
        "name": "demandItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Supplier",
                    "kind": "LinkedField",
                    "name": "supplier",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemPrice",
                    "kind": "LinkedField",
                    "name": "prices",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitSellingPrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "version",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "direction": "DESC",
                          "field": "CREATED_AT"
                        }
                      }
                    ],
                    "concreteType": "OrderItemConnection",
                    "kind": "LinkedField",
                    "name": "orderItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrderItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderDetail",
                                "kind": "LinkedField",
                                "name": "orderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Order",
                                    "kind": "LinkedField",
                                    "name": "order",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "orderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemCardboard",
                    "kind": "LinkedField",
                    "name": "cardboard",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thickness",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemFlexiblePackage",
                    "kind": "LinkedField",
                    "name": "flexiblePackage",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemGiftBox",
                    "kind": "LinkedField",
                    "name": "giftBox",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v10/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemPaperBag",
                    "kind": "LinkedField",
                    "name": "paperBag",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v10/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemOther",
                    "kind": "LinkedField",
                    "name": "other",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specText",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ba8dd19c52c743a4e5b85b5194301cb",
    "id": null,
    "metadata": {},
    "name": "DemandItemDetailBlocksRefetchQuery",
    "operationKind": "query",
    "text": "query DemandItemDetailBlocksRefetchQuery(\n  $demandId: ID\n) {\n  ...DemandItemDetailBlocksRefetchFragment_RMyVA\n}\n\nfragment DemandItemDetailBlocksHeaderFragment on DemandItem {\n  name\n  category\n  cardboard {\n    __typename\n    size\n    type\n    material\n    thickness\n    printingColor\n    processing\n    other\n    id\n  }\n  flexiblePackage {\n    __typename\n    size\n    type\n    material\n    printingColor\n    processing\n    other\n    id\n  }\n  giftBox {\n    __typename\n    size\n    type\n    paperType\n    printingColor\n    processing\n    other\n    id\n  }\n  paperBag {\n    __typename\n    size\n    paperType\n    printingColor\n    processing\n    handle\n    other\n    id\n  }\n  other {\n    __typename\n    specText\n    id\n  }\n}\n\nfragment DemandItemDetailBlocksRefetchFragment_RMyVA on Query {\n  demandItems(where: {isCurrent: true, demandID: $demandId}, orderBy: {field: UPDATED_AT, direction: DESC}) {\n    edges {\n      node {\n        id\n        name\n        category\n        supplier {\n          name\n          id\n        }\n        demand {\n          id\n          code\n        }\n        prices {\n          id\n          quantity\n          unitPrice\n          unitSellingPrice\n        }\n        itemNumber\n        version\n        orderItems(orderBy: {direction: DESC, field: CREATED_AT}, first: 1) {\n          edges {\n            node {\n              orderDetail {\n                order {\n                  id\n                  title\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n        ...DemandItemDetailBlocksHeaderFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7761b3c1b891f29cd5c6567eb798602c";

export default node;
