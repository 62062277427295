/**
 * @generated SignedSource<<3517cb383220b238c6d89cd27db1d214>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EstimationRequestDetailType = "item" | "%future added value";
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
export type CreateBulkEstimationRequestInput = {
  attachments?: ReadonlyArray<UploadFile> | null;
  companyID: string;
  details: ReadonlyArray<CreateEstimationRequestDetailInput>;
  internalAssignees?: ReadonlyArray<CreateEstimationRequestInternalAssigneeInput> | null;
  mailSubject: string;
  requestTitle: string;
  specText: string;
  suppliers?: ReadonlyArray<EstimationRequestSupplier> | null;
};
export type UploadFile = {
  file: any;
};
export type CreateEstimationRequestDetailInput = {
  type: EstimationRequestDetailType;
  value: EstimationDetailValueInput;
};
export type EstimationDetailValueInput = {
  item?: EstimationDetailTypeItemInput | null;
};
export type EstimationDetailTypeItemInput = {
  annualQuantity?: string | null;
  askingUnitPrice?: string | null;
  category: ItemCategory;
  name: string;
  quantity: number;
  spec?: EstimationDetailTypeItemSpecInput | null;
};
export type EstimationDetailTypeItemSpecInput = {
  cardboard?: EstimationDetailTypeItemCardboardSpecInput | null;
  flexiblePackage?: EstimationDetailTypeItemFlexiblePackageSpecInput | null;
  giftBox?: EstimationDetailTypeItemGiftBoxSpecInput | null;
  other?: EstimationDetailTypeItemOtherSpecInput | null;
  paperBag?: EstimationDetailTypeItemPaperBagSpecInput | null;
};
export type EstimationDetailTypeItemCardboardSpecInput = {
  material?: string | null;
  other?: string | null;
  printingColor?: string | null;
  processing?: string | null;
  size?: string | null;
  thickness?: string | null;
  type?: string | null;
};
export type EstimationDetailTypeItemFlexiblePackageSpecInput = {
  material?: string | null;
  other?: string | null;
  printingColor?: string | null;
  processing?: string | null;
  size?: string | null;
  type?: string | null;
};
export type EstimationDetailTypeItemGiftBoxSpecInput = {
  other?: string | null;
  paperType?: string | null;
  printingColor?: string | null;
  processing?: string | null;
  size?: string | null;
  type?: string | null;
};
export type EstimationDetailTypeItemOtherSpecInput = {
  other?: string | null;
};
export type EstimationDetailTypeItemPaperBagSpecInput = {
  handle?: string | null;
  other?: string | null;
  paperType?: string | null;
  printingColor?: string | null;
  processing?: string | null;
  size?: string | null;
};
export type CreateEstimationRequestInternalAssigneeInput = {
  userID: string;
};
export type EstimationRequestSupplier = {
  defaultDetailMessage?: string | null;
  footerMessage: string;
  headerMessage: string;
  id: string;
  toContactIDs: ReadonlyArray<string>;
};
export type useEstimationRequestFormMutation$variables = {
  input: CreateBulkEstimationRequestInput;
};
export type useEstimationRequestFormMutation$data = {
  readonly createBulkEstimationRequest: {
    readonly id: string;
    readonly requestTitle: string;
  };
};
export type useEstimationRequestFormMutation = {
  response: useEstimationRequestFormMutation$data;
  variables: useEstimationRequestFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EstimationRequest",
    "kind": "LinkedField",
    "name": "createBulkEstimationRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEstimationRequestFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEstimationRequestFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e722979ff4d35cc4c3699f9e864507fa",
    "id": null,
    "metadata": {},
    "name": "useEstimationRequestFormMutation",
    "operationKind": "mutation",
    "text": "mutation useEstimationRequestFormMutation(\n  $input: CreateBulkEstimationRequestInput!\n) {\n  createBulkEstimationRequest(input: $input) {\n    id\n    requestTitle\n  }\n}\n"
  }
};
})();

(node as any).hash = "572b20fe69d509e42a89347414f26aca";

export default node;
