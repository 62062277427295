import { ReactElement, createContext, useState } from 'react';

type QueryParams = {
  assignee?: string;
  demand?: string;
  supplier?: string;
  transactionId?: string;
};

type QueryParamsContextProps = {
  queryParams: QueryParams;
  updateQueryParams: (newParams: QueryParams) => void;
};

// クエリパラメータを保存するためのコンテキストを作成
export const QueryParamsContext = createContext<QueryParamsContextProps | undefined>(undefined);

type Props = {
  children: ReactElement;
};

export const SalesOrderSearchQueryProvider = ({ children }: Props) => {
  const [queryParams, setQueryParams] = useState<QueryParams>({
    assignee: '',
    demand: '',
    supplier: '',
    transactionId: '',
  });

  const updateQueryParams = (newParams: QueryParams) => {
    setQueryParams({ ...queryParams, ...newParams });
  };

  return (
    <QueryParamsContext.Provider value={{ queryParams, updateQueryParams }}>
      {children}
    </QueryParamsContext.Provider>
  );
};
