import { cdate } from 'cdate';
import type { IdTokenResult, User } from 'firebase/auth';
import { useEffect } from 'react';
import { getFirebaseAuth } from 'service/firebase/index';
import { logger } from 'utils/logger';

const REFRESH_INTERVAL = 5 * 60 * 1000; // 5分

/**
 * Refresh firebase token regularly
 */
export const useRegularlyRefreshFirebaseToken = () => {
  useEffect(() => {
    // mountされたタイミングで一度refreshを試行する
    getOrRefreshFirebaseToken();

    logger.debug('Start refreshing firebase token regularly');
    const handle = setInterval(async () => {
      await getOrRefreshFirebaseToken();
    }, REFRESH_INTERVAL);

    // コンポーネントがアンマウントされたらクリアする
    return () => {
      logger.debug('Stop refreshing firebase token regularly');
      clearInterval(handle);
    };
  }, []);
};

/**
 * 有効期限切れかどうかを判定する
 * @note intervalの間隔を考慮し、実際の有効期限の5分5秒前に有効期限切れとしている
 * @param user ログイン中のユーザー
 * @returns 有効期限切れの場合true
 */
const isTokenExpired = async (user: User) => {
  const targetTime = cdate().add(5, 'minutes').add(5, 'seconds');
  try {
    const tokenResult = await user.getIdTokenResult();
    return targetTime > cdate(tokenResult.expirationTime);
  } catch (_) {
    return true;
  }
};

/**
 * Firebaseのtokenを取得する。有効期限切れの場合はrefreshしたものを返す
 * @returns
 */
export const getOrRefreshFirebaseToken = async (): Promise<IdTokenResult | null> => {
  const user = getFirebaseAuth().currentUser;
  if (user) {
    // NOTE: forceRefreshはしていないが、有効期限5分前になると自動的に更新される
    //       ref: https://github.com/firebase/firebase-js-sdk/blob/f741ac16207f27ebf19ad4a63e4eae337e497b62/docs-devsite/auth.user.md#usergetidtokenresult
    const expired = await isTokenExpired(user);
    const idToken = await user.getIdTokenResult(expired);
    const exiprationTime = cdate(idToken.expirationTime).format('YYYY-MM-DD HH:mm:ss');
    logger.debug(`Refresh firebase token expiration: ${exiprationTime}`);
    return idToken;
  } else {
    logger.debug('No user found');
    return null;
  }
};
