// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file service/estimation_response_external/v1/estimation_response_external.proto (package service.estimation_response_external.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { CategoryItemsSummary } from "../../../model/item/v1/summarize_item_pb.js";
import { CreateEstimationResponse } from "../../../model/estimation_response/v1/create_estimation_response_pb.js";

/**
 * @generated from message service.estimation_response_external.v1.GetInitialDataByTokenRequest
 */
export class GetInitialDataByTokenRequest extends Message<GetInitialDataByTokenRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetInitialDataByTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.estimation_response_external.v1.GetInitialDataByTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInitialDataByTokenRequest {
    return new GetInitialDataByTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInitialDataByTokenRequest {
    return new GetInitialDataByTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInitialDataByTokenRequest {
    return new GetInitialDataByTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInitialDataByTokenRequest | PlainMessage<GetInitialDataByTokenRequest> | undefined, b: GetInitialDataByTokenRequest | PlainMessage<GetInitialDataByTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetInitialDataByTokenRequest, a, b);
  }
}

/**
 * @generated from message service.estimation_response_external.v1.GetInitialDataByTokenResponse
 */
export class GetInitialDataByTokenResponse extends Message<GetInitialDataByTokenResponse> {
  /**
   * 案件名
   *
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * 仕様
   *
   * @generated from field: string spec_text = 2;
   */
  specText = "";

  /**
   * サマリ済みアイテム一覧
   *
   * @generated from field: repeated model.item.v1.CategoryItemsSummary items = 3;
   */
  items: CategoryItemsSummary[] = [];

  /**
   * 備考
   *
   * @generated from field: string detail_message = 4;
   */
  detailMessage = "";

  /**
   * 前回回答時の諸条件（担当者ごとでなくサプライヤごとに保存されている）
   *
   * @generated from field: string latest_responded_important_notes = 5;
   */
  latestRespondedImportantNotes = "";

  /**
   * 前回回答時の見積有効期限
   *
   * @generated from field: string latest_responded_expiration_period = 6;
   */
  latestRespondedExpirationPeriod = "";

  constructor(data?: PartialMessage<GetInitialDataByTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.estimation_response_external.v1.GetInitialDataByTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "spec_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "items", kind: "message", T: CategoryItemsSummary, repeated: true },
    { no: 4, name: "detail_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "latest_responded_important_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "latest_responded_expiration_period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInitialDataByTokenResponse {
    return new GetInitialDataByTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInitialDataByTokenResponse {
    return new GetInitialDataByTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInitialDataByTokenResponse {
    return new GetInitialDataByTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInitialDataByTokenResponse | PlainMessage<GetInitialDataByTokenResponse> | undefined, b: GetInitialDataByTokenResponse | PlainMessage<GetInitialDataByTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetInitialDataByTokenResponse, a, b);
  }
}

/**
 * @generated from message service.estimation_response_external.v1.RegisterRequest
 */
export class RegisterRequest extends Message<RegisterRequest> {
  /**
   * @generated from field: model.estimation_response.v1.CreateEstimationResponse estimation_response = 1;
   */
  estimationResponse?: CreateEstimationResponse;

  constructor(data?: PartialMessage<RegisterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.estimation_response_external.v1.RegisterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "estimation_response", kind: "message", T: CreateEstimationResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterRequest {
    return new RegisterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterRequest {
    return new RegisterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterRequest {
    return new RegisterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterRequest | PlainMessage<RegisterRequest> | undefined, b: RegisterRequest | PlainMessage<RegisterRequest> | undefined): boolean {
    return proto3.util.equals(RegisterRequest, a, b);
  }
}

/**
 * @generated from message service.estimation_response_external.v1.RegisterResponse
 */
export class RegisterResponse extends Message<RegisterResponse> {
  /**
   * @generated from field: bool succeed = 1;
   */
  succeed = false;

  constructor(data?: PartialMessage<RegisterResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.estimation_response_external.v1.RegisterResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "succeed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterResponse {
    return new RegisterResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterResponse {
    return new RegisterResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterResponse {
    return new RegisterResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterResponse | PlainMessage<RegisterResponse> | undefined, b: RegisterResponse | PlainMessage<RegisterResponse> | undefined): boolean {
    return proto3.util.equals(RegisterResponse, a, b);
  }
}

