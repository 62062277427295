import { FormControl, FormLabel, HStack, Input } from '@chakra-ui/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ErrorMessage } from 'components/ErrorMessage';
import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { FieldValues, FormState, UseFormRegister, useFormContext, useWatch } from 'react-hook-form';

export const DemandItemSpecForm = () => {
  const form = useFormContext();
  const category = useWatch({ name: 'category', control: form.control });

  switch (category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return <CardboardSpecForm {...form} />;
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return <FlexiblePackageForm {...form} />;
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return <GiftBoxForm {...form} />;
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return <PaperBagForm {...form} />;
    case DEMAND_ITEM_CATEGORY.Other:
      return <OtherForm {...form} />;
    default:
      return <OtherForm {...form} />;
  }
};

const CardboardSpecForm = ({
  register,
  formState,
}: { register: UseFormRegister<FieldValues>; formState: FormState<FieldValues> }) => {
  const { errors } = formState;

  return (
    <>
      <FormControl>
        <FormLabel>サイズ</FormLabel>
        <Input type="string" {...register('cardboard.size')} />
        <ErrorMessage name="cardboard.size" errors={errors} />
      </FormControl>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>形状</FormLabel>
          <Input type="string" {...register('cardboard.type')} />
          <ErrorMessage name="cardboard.type" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>構成</FormLabel>
          <Input type="string" {...register('cardboard.material')} />
          <ErrorMessage name="cardboard.material" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>段厚</FormLabel>
          <Input type="string" {...register('cardboard.thickness')} />
          <ErrorMessage name="cardboard.thickness" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>印刷</FormLabel>
          <Input type="string" {...register('cardboard.printingColor')} />
          <ErrorMessage name="cardboard.printingColor" errors={errors} />
        </FormControl>
      </HStack>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>加工</FormLabel>
          <Input type="string" {...register('cardboard.processing')} />
          <ErrorMessage name="cardboard.processing" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>その他</FormLabel>
          <Input type="string" {...register('cardboard.other')} />
          <ErrorMessage name="cardboard.other" errors={errors} />
        </FormControl>
      </HStack>
    </>
  );
};

const FlexiblePackageForm = ({
  register,
  formState,
}: { register: UseFormRegister<FieldValues>; formState: FormState<FieldValues> }) => {
  const { errors } = formState;

  return (
    <>
      <FormControl>
        <FormLabel>サイズ</FormLabel>
        <Input type="string" {...register('flexiblePackage.size')} />
        <ErrorMessage name="flexiblePackage.size" errors={errors} />
      </FormControl>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>形状</FormLabel>
          <Input type="string" {...register('flexiblePackage.type')} />
          <ErrorMessage name="flexiblePackage.type" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>構成</FormLabel>
          <Input type="string" {...register('flexiblePackage.material')} />
          <ErrorMessage name="flexiblePackage.material" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>印刷</FormLabel>
          <Input type="string" {...register('flexiblePackage.printingColor')} />
          <ErrorMessage name="flexiblePackage.printingColor" errors={errors} />
        </FormControl>
      </HStack>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>加工</FormLabel>
          <Input type="string" {...register('flexiblePackage.processing')} />
          <ErrorMessage name="flexiblePackage.processing" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>その他</FormLabel>
          <Input type="string" {...register('flexiblePackage.other')} />
          <ErrorMessage name="flexiblePackage.other" errors={errors} />
        </FormControl>
      </HStack>
    </>
  );
};

const GiftBoxForm = ({
  register,
  formState,
}: { register: UseFormRegister<FieldValues>; formState: FormState<FieldValues> }) => {
  const { errors } = formState;

  return (
    <>
      <FormControl>
        <FormLabel>サイズ</FormLabel>
        <Input type="string" {...register('giftBox.size')} />
        <ErrorMessage name="giftBox.size" errors={errors} />
      </FormControl>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>形状</FormLabel>
          <Input type="string" {...register('giftBox.type')} />
          <ErrorMessage name="giftBox.type" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>用紙</FormLabel>
          <Input type="string" {...register('giftBox.paperType')} />
          <ErrorMessage name="giftBox.material" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>印刷</FormLabel>
          <Input type="string" {...register('giftBox.printingColor')} />
          <ErrorMessage name="giftBox.printingColor" errors={errors} />
        </FormControl>
      </HStack>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>加工</FormLabel>
          <Input type="string" {...register('giftBox.processing')} />
          <ErrorMessage name="giftBox.processing" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>その他</FormLabel>
          <Input type="string" {...register('giftBox.other')} />
          <ErrorMessage name="giftBox.other" errors={errors} />
        </FormControl>
      </HStack>
    </>
  );
};
const PaperBagForm = ({
  register,
  formState,
}: { register: UseFormRegister<FieldValues>; formState: FormState<FieldValues> }) => {
  const { errors } = formState;

  return (
    <>
      <FormControl>
        <FormLabel>サイズ</FormLabel>
        <Input type="string" {...register('paperBag.size')} />
        <ErrorMessage name="paperBag.size" errors={errors} />
      </FormControl>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>用紙</FormLabel>
          <Input type="string" {...register('paperBag.paperType')} />
          <ErrorMessage name="paperBag.material" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>印刷</FormLabel>
          <Input type="string" {...register('paperBag.printingColor')} />
          <ErrorMessage name="paperBag.printingColor" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>加工</FormLabel>
          <Input type="string" {...register('paperBag.processing')} />
          <ErrorMessage name="paperBag.processing" errors={errors} />
        </FormControl>
      </HStack>
      <HStack spacing={2} alignItems="flex-start" width="768px">
        <FormControl>
          <FormLabel>持ち手</FormLabel>
          <Input type="string" {...register('paperBag.handle')} />
          <ErrorMessage name="paperBag.handle" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel>その他</FormLabel>
          <Input type="string" {...register('paperBag.other')} />
          <ErrorMessage name="paperBag.other" errors={errors} />
        </FormControl>
      </HStack>
    </>
  );
};
const OtherForm = ({
  register,
  formState,
}: { register: UseFormRegister<FieldValues>; formState: FormState<FieldValues> }) => {
  const { errors } = formState;

  return (
    <FormControl>
      <FormLabel>その他</FormLabel>
      <AutoResizeTextarea {...register('other.specText')} minHeight="105px" />
      <ErrorMessage name="other.specText" errors={errors} />
    </FormControl>
  );
};
