import { Loading } from 'components/Loading';
import { EstimationConfirm } from 'features/estimation/components/EstimationConfirm';
import { EstimationForm } from 'features/estimation/components/EstimationForm';
import { DEFAULT_GROSS_MARGIN_RATIO } from 'features/estimation/constant';
import { useEstimationEdit } from 'features/estimation/hooks/useEstimationEdit';
import { buildEditEstimationFormState } from 'features/estimation/util';
import { edit_EstimationEditPageFragment$key } from 'gql/__generated__/edit_EstimationEditPageFragment.graphql';
import { edit_EstimationEditPageQuery } from 'gql/__generated__/edit_EstimationEditPageQuery.graphql';
import { Suspense } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const estimationEditPageQuery = graphql`
  query edit_EstimationEditPageQuery  ($id: ID!) {
    ...edit_EstimationEditPageFragment
    ...EstimationFormFragment
  }
`;

const estimationEditPageFragment = graphql`
  fragment edit_EstimationEditPageFragment on Query {
    estimations(where: {id: $id}) {
      edges {
        node {
          id
          company {
            id
            name
          }
          supplier {
            id
            name
          }
          title
          detailMessage
          expirationPeriod
          showAmountSummary
          createdAt
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
            edges {
              node {
                type
                orderNumber
                documentDisplayStatus
                item {
                  id
                  name
                  unitPrice
                  unitSellingPrice
                  quantity
                  taxCategory {
                    id
                    rate
                  }
                }
              }
            }
          }
          estimationResponse {
            assignee {
              estimationRequest {
                id
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }

    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }

    currentUser {
      userID
      firstName
      lastName
    }
  }
`;

export const EstimationEditPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const query = useLazyLoadQuery<edit_EstimationEditPageQuery>(
    estimationEditPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );
  const { estimations, currentUser, taxCategories } = useFragment(
    estimationEditPageFragment,
    query as edit_EstimationEditPageFragment$key,
  );

  const state = estimations?.edges?.at(0)?.node;
  const tax = taxCategories?.edges?.at(0)?.node;

  const { data, showPreview, onClickToConfirm, onSubmit, onClickToNewForm, isMutationInFlight } =
    useEstimationEdit({
      estimationId: id,
      state: buildEditEstimationFormState({
        value: state,
        currentUser,
        taxCategories: {
          id: tax?.id || '',
          rate: tax?.rate || '',
        },
      }),
    });

  if (!state) return null;

  return (
    <Suspense fallback={<Loading />}>
      {showPreview ? (
        <EstimationConfirm
          type="edit"
          onClickSubmit={onSubmit}
          data={data}
          isMutationInFlight={isMutationInFlight}
          onClickToNewForm={onClickToNewForm}
        />
      ) : (
        <EstimationForm
          type="edit"
          onClickToConfirm={onClickToConfirm}
          data={data}
          queryRef={query}
          defaultGrossMarginRatio={DEFAULT_GROSS_MARGIN_RATIO}
          previousUrl={paths.estimation._id(id).url}
        />
      )}
    </Suspense>
  );
};
