import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from 'components/ErrorMessage';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandMemo } from 'features/company/components/DemandMemo';
import { AddAssigneesToEstimationRequestFormState } from 'features/estimationRequest/hooks/useAddAssigneesToEstimationRequestForm';
import {
  ZodEstimationRequestAddAssigneesFormData,
  addAssigneesToEstimationRequestFormSchema,
} from 'features/estimationRequest/zod';
import { EstimationRequestAddAssigneeFormFragment$key } from 'gql/__generated__/EstimationRequestAddAssigneeFormFragment.graphql';
import { useForm } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { EstimationRequestAttachmentFiles } from '../EstimationRequestAttachmentFiles';
import { EstimationRequestPreviewItems } from '../EstimationRequestPreviewItems';
import { EstimationRequestSupplierForm } from '../EstimationRequestSupplierForm';

const MIN_TEXTAREA_ROWS = 3;
const DEFAULT_WIDTH = 640;

const EstimationRequestAddAssigneeFormFragment = graphql`
  fragment EstimationRequestAddAssigneeFormFragment on EstimationRequest {
    ...EstimationRequestPreviewItemsFragment
    ...EstimationRequestAttachmentFilesFragment
    company {
        id
        name
    }
    requestTitle
    specText
    requestAssignees {
      supplier {
        id
        name
      }
    }
    requestAttachments {
      id
    }
  }
`;

export function EstimationRequestAddAssigneesForm({
  data,
  onClickToConfirm,
  queryRef,
}: {
  data: AddAssigneesToEstimationRequestFormState;
  queryRef: EstimationRequestAddAssigneeFormFragment$key;
  onClickToConfirm: (data: AddAssigneesToEstimationRequestFormState) => void;
}) {
  const request = useFragment(EstimationRequestAddAssigneeFormFragment, queryRef);
  const requestedCompanies =
    request.requestAssignees?.map((requestAssignee) => {
      return requestAssignee.supplier;
    }) || [];

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<ZodEstimationRequestAddAssigneesFormData>({
    resolver: zodResolver(addAssigneesToEstimationRequestFormSchema),
    defaultValues: data,
  });

  const onSubmit = (data: AddAssigneesToEstimationRequestFormState) => {
    onClickToConfirm(data);
  };

  return (
    <>
      <Box mb={6}>
        <Link to={paths.estimationRequest._id(data.id)}>
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label="見積依頼の追加" />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box w="100%">
          <VStack spacing={6} alignItems="flex-start">
            <VStack spacing={1} alignItems="flex-start" w={DEFAULT_WIDTH}>
              <Heading as="h3" color="gray.500" fontSize="md" fontWeight="normal">
                デマンド
              </Heading>
              <Box>{request.company.name}</Box>

              <DemandMemo demandId={request.company.id} />
            </VStack>

            <VStack spacing={1} alignItems="flex-start" w={DEFAULT_WIDTH}>
              <Heading as="h3" color="gray.500" fontSize="md" fontWeight="normal">
                案件名
              </Heading>
              <Box>{request.requestTitle}</Box>
            </VStack>

            <FormControl isRequired isInvalid={!!errors.mailSubject} w={DEFAULT_WIDTH}>
              <FormLabel fontWeight="normal">メール件名</FormLabel>
              <Input type="string" {...register('mailSubject')} />
              <ErrorMessage name="mailSubject" errors={errors} />
            </FormControl>

            <Box w={DEFAULT_WIDTH}>
              <Heading as="h3" color="gray.500" fontSize="md" fontWeight="normal">
                見積依頼済みサプライヤー
              </Heading>
              <Text>{requestedCompanies.map((company) => company.name).join(' / ')}</Text>
            </Box>

            <EstimationRequestSupplierForm
              suppliers={getValues('suppliers')}
              onChange={(suppliers) => {
                setValue('suppliers', suppliers);
              }}
              errors={errors.suppliers}
              excludeSupplierIds={requestedCompanies.map((company) => company.id)}
            />

            <VStack spacing={2} alignItems="flex-start">
              <EstimationRequestPreviewItems
                heading={
                  <Heading as="h3" color="gray.500" fontSize="md" fontWeight="normal">
                    見積条件
                  </Heading>
                }
                queryRef={request}
              />
            </VStack>

            <FormControl isRequired isInvalid={!!errors.specText} w={DEFAULT_WIDTH}>
              <FormLabel>その他の条件</FormLabel>
              <Textarea
                {...register('specText')}
                rows={
                  getValues('specText').split(/\n/).length > MIN_TEXTAREA_ROWS
                    ? getValues('specText').split(/\n/).length
                    : MIN_TEXTAREA_ROWS
                }
              />
              <Text color="gray.500" mt={2}>
                必要に応じて項目を調整し、記入を行いましょう
              </Text>
              <ErrorMessage name="specText" errors={errors} />
            </FormControl>

            {(request.requestAttachments?.length || 0) > 0 && (
              <VStack spacing={1} alignItems="flex-start" w={DEFAULT_WIDTH}>
                <Heading as="h3" color="gray.500" fontSize="md" fontWeight="normal">
                  添付データ
                </Heading>
                <EstimationRequestAttachmentFiles queryRef={request} />
              </VStack>
            )}

            <Button colorScheme="blue" type="submit" isLoading={isSubmitting} w={DEFAULT_WIDTH}>
              確認画面へ進む
            </Button>
          </VStack>
        </Box>
      </form>
    </>
  );
}
