import { Box, VStack } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};
export const DemandRecurringCostBlock = ({ children }: Props) => {
  return (
    <Box w="100%" pb={8}>
      <VStack spacing={4} alignItems="flex-start" justifyContent="space-between">
        {children}
      </VStack>
    </Box>
  );
};
