import { Box, Button, Text } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';

type Props = {
  onClickToForm: () => void;
};

export const EstimationResponseThanks = ({ onClickToForm }: Props) => (
  <>
    <Box mb={6}>
      <PageHeading label="お見積ありがとうございました" />
    </Box>
    <Box mb={6}>
      <Text color="gray.500">
        下記の内容でshizaiに見積結果を送信しました。
        <br />
        ミスや記入漏れがある場合は「入力しなおす」ボタンから再度お見積ください
      </Text>
    </Box>
    <Button onClick={onClickToForm}>入力しなおす</Button>
  </>
);
