/**
 * @generated SignedSource<<ccb29123aee224ebe104e7b00ef036e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type edit_InvoiceEditPageQuery$variables = {
  id: string;
};
export type edit_InvoiceEditPageQuery$data = {
  readonly invoices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly bookedAt: any;
        readonly demand: {
          readonly id: string;
          readonly name: string;
        };
        readonly detailMessage: string | null;
        readonly details: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly invoiceDetailsSales: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly salesDate: any;
                  } | null;
                } | null> | null;
              };
              readonly item: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly taxCategory: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitSellingPrice: number;
              } | null;
              readonly orderNumber: any;
              readonly salesOrderDetail: {
                readonly salesOrderID: string;
              } | null;
              readonly salesOrderDetailID: string | null;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly internalMemos: ReadonlyArray<{
          readonly body: string;
        }> | null;
        readonly paymentDueAt: any;
        readonly title: string;
      } | null;
    } | null> | null;
  };
  readonly userInfo: {
    readonly connectedServices: {
      readonly freee: boolean;
    };
  };
};
export type edit_InvoiceEditPageQuery = {
  response: edit_InvoiceEditPageQuery$data;
  variables: edit_InvoiceEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentDueAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detailMessage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": "demand",
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "ASC",
      "field": "ORDER_NUMBER"
    }
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salesOrderDetailID",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salesOrderID",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceItem",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitSellingPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategory",
      "kind": "LinkedField",
      "name": "taxCategory",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "isValid": true
    }
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salesDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "UserInfo",
  "kind": "LinkedField",
  "name": "userInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConnectedServices",
      "kind": "LinkedField",
      "name": "connectedServices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freee",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "edit_InvoiceEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceConnection",
        "kind": "LinkedField",
        "name": "invoices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "InvoiceDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoiceDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": (v15/*: any*/),
                                "concreteType": "InvoiceDetailSaleConnection",
                                "kind": "LinkedField",
                                "name": "invoiceDetailsSales",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "InvoiceDetailSaleEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "InvoiceDetailSale",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v16/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "invoiceDetailsSales(where:{\"isValid\":true})"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v17/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "edit_InvoiceEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceConnection",
        "kind": "LinkedField",
        "name": "invoices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "InvoiceDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoiceDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": (v15/*: any*/),
                                "concreteType": "InvoiceDetailSaleConnection",
                                "kind": "LinkedField",
                                "name": "invoiceDetailsSales",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "InvoiceDetailSaleEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "InvoiceDetailSale",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v16/*: any*/),
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "invoiceDetailsSales(where:{\"isValid\":true})"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v17/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c3cdc4d23ab8b3653ea9a7266c2f4ef1",
    "id": null,
    "metadata": {},
    "name": "edit_InvoiceEditPageQuery",
    "operationKind": "query",
    "text": "query edit_InvoiceEditPageQuery(\n  $id: ID!\n) {\n  invoices(where: {id: $id}) {\n    edges {\n      node {\n        id\n        title\n        bookedAt\n        paymentDueAt\n        detailMessage\n        internalMemos {\n          body\n          id\n        }\n        demand: company {\n          id\n          name\n        }\n        details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {\n          edges {\n            node {\n              id\n              orderNumber\n              salesOrderDetailID\n              salesOrderDetail {\n                salesOrderID\n                id\n              }\n              item {\n                id\n                name\n                quantity\n                unitSellingPrice\n                taxCategory {\n                  id\n                  rate\n                }\n              }\n              invoiceDetailsSales(where: {isValid: true}) {\n                edges {\n                  node {\n                    salesDate\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  userInfo {\n    connectedServices {\n      freee\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "655279a8093fddb3855b404fd5eb3757";

export default node;
