import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

export function ConfirmModalButton({
  header,
  body,
  footer,
  button,
}: {
  header?: ReactNode;
  body?: ReactNode;
  button: ({ onOpen, isSubmitting }: { onOpen: () => void; isSubmitting: boolean }) => ReactNode;
  footer: ({
    onClose,
  }: {
    onClose: () => void;
    isSubmitting: boolean;
    handleSubmit: (onSubmit: () => Promise<void>) => Promise<void>;
  }) => ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (onSubmit: () => Promise<void>) => {
    setIsSubmitting(true);
    try {
      await onSubmit();
      onClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {button({ onOpen, isSubmitting })}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={!isSubmitting}>
          <ModalOverlay />
          <ModalContent data-testid="confirm-modal-button-modal-content">
            {header && <ModalHeader>{header}</ModalHeader>}
            {body && <ModalBody>{body}</ModalBody>}
            <ModalFooter>{footer({ onClose, isSubmitting, handleSubmit })}</ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
