import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { Environments } from 'constants/environment';
import { getEnvironment } from './environment';

export const captureException = (error: Error | unknown, captureContext?: CaptureContext) => {
  if (getEnvironment() === Environments.Production || getEnvironment() === Environments.Preview) {
    Sentry.captureException(error, captureContext);
  }
};

export class MissingRequiredParamError extends Error {
  constructor(paramName: string) {
    super(`Required parameter ${paramName} is missing`);
    this.name = 'RequiredParamError';
  }
}

export class MissingCategoryError extends Error {
  constructor() {
    super(`Category does not exist.`);
    this.name = 'MissingCategoryError';
  }
}
