import { Box, VStack } from '@chakra-ui/react';
import { Link } from 'components/Link';
import { OrderDetailSideBarFragment$key } from 'gql/__generated__/OrderDetailSideBarFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormatWithTime } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const OrderDetailSideBarFragment = graphql`
  fragment OrderDetailSideBarFragment on Order
  {
    id
    title
    createdAt
    company {
      id
      name
    }
    supplier {
      id
      name
    }
    createdBy {
      profile {
        lastName
        firstName
      }
    }

    salesOrders {
      id
      estimations {
        id
        estimationResponse {
          id
          assignee {
            id
            estimationRequestID
          }
        }
      }
    }

    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

type Props = {
  queryRef: OrderDetailSideBarFragment$key;
};

export const OrderDetailSideBar = ({ queryRef }: Props) => {
  const {
    id,
    title,
    createdAt,
    company,
    createdBy,
    supplier,
    salesOrders,
    internalAssignees: internalAssigneesData,
  } = useFragment(OrderDetailSideBarFragment, queryRef);

  const salesOrder = salesOrders?.[0];
  const estimation = salesOrder?.estimations?.[0];
  const estimationRequestId = estimation?.estimationResponse?.assignee.estimationRequestID;

  return (
    <VStack spacing={6} alignItems="flex-start">
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          ID
        </Box>
        <Box>{id}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          作成日時
        </Box>
        <Box>{dateFormatWithTime(createdAt)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          件名
        </Box>
        <Box>{title}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          デマンド
        </Box>
        <Box>
          <Link to={paths.company._id(company.id).url}>{company.name}</Link>
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          サプライヤー
        </Box>
        <Box>
          <Box>
            <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
          </Box>
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          担当者
        </Box>
        <Box>
          {internalAssigneesData?.map((assignee) =>
            assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
              <Box key={assignee.user.id}>
                {concatFullName({
                  lastName: assignee.user.profile[0].lastName,
                  firstName: assignee.user.profile[0].firstName,
                })}
              </Box>
            ) : (
              <Box key={assignee.user.id}>-</Box>
            ),
          ) || '-'}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          作成者
        </Box>
        <Box>
          {createdBy.profile?.[0]?.lastName && createdBy.profile?.[0]?.firstName
            ? concatFullName({
                lastName: createdBy.profile[0].lastName,
                firstName: createdBy.profile[0].firstName,
              })
            : '-'}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          見積依頼
        </Box>
        <Box>
          {estimationRequestId ? (
            <Link to={paths.estimationRequest._id(estimationRequestId)}>{estimationRequestId}</Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          見積
        </Box>
        <Box>
          {estimation ? (
            <Link to={paths.estimation._id(estimation.id).url}>{estimation.id}</Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          発注請書
        </Box>
        <Box>
          {salesOrder ? (
            <Link to={paths.salesOrder.id(salesOrder.id).url()}>{salesOrder.id}</Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>
    </VStack>
  );
};
