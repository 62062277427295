/**
 * @generated SignedSource<<709ccb5e68e17948706c6bf54677a91d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesOrderTableRowFragment$data = {
  readonly demand: {
    readonly id: string;
    readonly name: string;
  };
  readonly estimations: ReadonlyArray<{
    readonly estimationResponse: {
      readonly assignee: {
        readonly estimationRequest: {
          readonly id: string;
        };
        readonly id: string;
      };
      readonly id: string;
    } | null;
    readonly id: string;
  }> | null;
  readonly id: string;
  readonly internalAssignees: ReadonlyArray<{
    readonly user: {
      readonly id: string;
      readonly profile: ReadonlyArray<{
        readonly firstName: string;
        readonly lastName: string;
      }> | null;
    };
  }> | null;
  readonly orders: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly receivedDate: any;
  readonly supplier: {
    readonly id: string;
    readonly name: string;
  };
  readonly title: string;
  readonly transactionID: any;
  readonly " $fragmentType": "SalesOrderTableRowFragment";
};
export type SalesOrderTableRowFragment$key = {
  readonly " $data"?: SalesOrderTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesOrderTableRowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrderTableRowFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "demand",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Supplier",
      "kind": "LinkedField",
      "name": "supplier",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Estimation",
      "kind": "LinkedField",
      "name": "estimations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationResponse",
          "kind": "LinkedField",
          "name": "estimationResponse",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationRequestAssignee",
              "kind": "LinkedField",
              "name": "assignee",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequest",
                  "kind": "LinkedField",
                  "name": "estimationRequest",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "orders",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrderInternalAssignee",
      "kind": "LinkedField",
      "name": "internalAssignees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};
})();

(node as any).hash = "19403eba760970bb205d84d47d4c87ed";

export default node;
