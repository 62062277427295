import { EstimationRequestAddAssigneesForm } from 'features/estimationRequest/components/EstimationRequestAddAssigneeForm';
import { EstimationRequestConfirm } from 'features/estimationRequest/components/EstimationRequestConfirm';
import { PreviewProps } from 'features/estimationRequest/components/EstimationRequestPreview';
import {
  AddAssigneesToEstimationRequestFormState,
  useAddAssigneesToEstimationRequestForm,
} from 'features/estimationRequest/hooks/useAddAssigneesToEstimationRequestForm';
import { addAssignees_estimationRequestAddAssigneesPageQuery } from 'gql/__generated__/addAssignees_estimationRequestAddAssigneesPageQuery.graphql';
import { useMemo } from 'react';
import { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { extractItemFromDetail } from 'utils/detail';
import { MissingRequiredParamError } from 'utils/error';
import { concatFullName } from 'utils/label';

const EstimationRequestAddAssigneesPageQuery = graphql`
  query addAssignees_estimationRequestAddAssigneesPageQuery ($id: ID!) {
    estimationRequests(where: {id: $id}) {
      edges {
        node {
            ...EstimationRequestAddAssigneeFormFragment
            id
            requestTitle
            specText
            requestAttachments {
              name
              byteSize
            }
            details {
              type
              item {
                name
                specJSON
                quantity
              }
            }
            internalAssignees {
              id
              user {
                profile {
                  firstName
                  lastName
                }
              }
            }
        }
      }
    }
  }
`;

export const EstimationRequestAddAssigneesPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const query = useLazyLoadQuery<addAssignees_estimationRequestAddAssigneesPageQuery>(
    EstimationRequestAddAssigneesPageQuery,
    { id },
    { fetchPolicy: 'network-only' },
  );
  const request = query.estimationRequests.edges?.at(0)?.node;

  const state: AddAssigneesToEstimationRequestFormState = {
    id: request?.id || '',
    specText: request?.specText || '',
    mailSubject: '',
    suppliers: [{ id: '', name: '', contacts: [], headerMessage: '', footerMessage: '' }],
  };
  const { data, showPreview, onClickToConfirm, onSubmit, isMutationInFlight, onClickToForm } =
    useAddAssigneesToEstimationRequestForm(state);

  const items: PreviewProps['items'] = useMemo(() => {
    if (!request?.details) return [];

    return request?.details?.map((d) => {
      const item = extractItemFromDetail(d);

      return {
        name: item.name,
        quantity: item.quantity,
        specJson: new Map(Object.entries(item.specJSON)),
      };
    });
  }, [request]);

  if (!request || !data) return null;

  return (
    <Suspense fallback={<div>loading...</div>}>
      {showPreview ? (
        <EstimationRequestConfirm
          onClickSubmit={() => {
            onSubmit(data);
          }}
          values={{
            mailSubject: data.mailSubject,
            specText: data.specText,
            suppliers: data.suppliers,
            attachments: request.requestAttachments?.map((a) => ({
              name: a.name,
              size: a.byteSize,
            })),
            items,
            internalAssignees:
              request.internalAssignees?.map((a) => ({
                label: concatFullName({
                  firstName: a.user.profile?.[0].firstName || '',
                  lastName: a.user.profile?.[0].lastName || '',
                }),
                value: a.id,
              })) || [],
          }}
          isMutationInFlight={isMutationInFlight}
          onClickToForm={onClickToForm}
        />
      ) : (
        <EstimationRequestAddAssigneesForm
          data={data}
          queryRef={request}
          onClickToConfirm={onClickToConfirm}
        />
      )}
    </Suspense>
  );
};
