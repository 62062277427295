import { Struct } from '@bufbuild/protobuf';
import { Loading } from 'components/Loading';
import { EstimationConfirm } from 'features/estimation/components/EstimationConfirm';
import { EstimationForm } from 'features/estimation/components/EstimationForm';
import { DEFAULT_GROSS_MARGIN_RATIO } from 'features/estimation/constant';
import { useEstimationForm } from 'features/estimation/hooks/useEstimationForm';
import { buildCreateEstimationFormState } from 'features/estimation/util';
import { new_EstimationNewPageFragment$key } from 'gql/__generated__/new_EstimationNewPageFragment.graphql';
import { new_EstimationNewPageQuery } from 'gql/__generated__/new_EstimationNewPageQuery.graphql';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { SortedSpec, SortedSpecType, SpecOnlyItem } from 'proto/model/item/v1/spec_pb';
import { ItemService } from 'proto/service/item/v1/item_connect';
import { Suspense, useEffect, useState } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { extractItemFromDetail } from 'utils/detail';
import { captureException } from 'utils/error';
import { paths } from 'utils/paths';

const estimationNewPageQuery = graphql`
  query new_EstimationNewPageQuery  ($responseId: ID!, $isSkipResponse: Boolean!,$assigneeId: ID!, $isSkipAssignee: Boolean!) {
    ...new_EstimationNewPageFragment @arguments(responseId: $responseId, isSkipResponse: $isSkipResponse, assigneeId: $assigneeId, isSkipAssignee: $isSkipAssignee)
    ...EstimationFormFragment
  }
`;

const estimationNewPageFragment = graphql`
  fragment new_EstimationNewPageFragment on Query
    @argumentDefinitions(
      responseId: {type: "ID"}
      isSkipResponse: {type: "Boolean", defaultValue: true}
      assigneeId: {type: "ID"}
      isSkipAssignee: {type: "Boolean", defaultValue: true}
    ) {
    estimationResponses(where: {id: $responseId}) @skip(if: $isSkipResponse) {
      edges {
        node {
          id
          importantNotes
          details {
            type
            item {
              id
              name
              quantity
              unitPrice
              specJSON
            }
          }
          assignee {
            supplier {
              id
              name
            }
            estimationRequest {
              id
              company {
                id
                name
              }
              internalAssignees {
                user {
                  id
                  profile {
                    lastName
                    firstName
                  }
                }
              }
            }
          }
        }
      }
    }
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }

    assignees: estimationRequestAssignees(where: {id: $assigneeId}) @skip(if: $isSkipAssignee) {
      edges {
        node {
          id
          supplier {
            id
            name
          }
          estimationRequest {
            id
            company {
              id
              name
            }
            details {
              type
              item {
                id
                name
                quantity
                specJSON
              }
            }
            internalAssignees {
              user {
                id
                profile {
                  lastName
                  firstName
                }
              }
            }
          }
        }
      }
    }

    currentUser {
      userID
      firstName
      lastName
      phoneNumber
    }
  }
`;

export const EstimationNewPage = () => {
  const [searchParams] = useSearchParams();
  const responseId = searchParams.get('responseId');
  const assigneeId = searchParams.get('requestAssigneeId');
  const query = useLazyLoadQuery<new_EstimationNewPageQuery>(
    estimationNewPageQuery,
    {
      responseId: responseId || '',
      isSkipResponse: !responseId,
      assigneeId: assigneeId || '',
      isSkipAssignee: !assigneeId,
    },
    { fetchPolicy: 'network-only' },
  );

  const { taxCategories, estimationResponses, assignees, currentUser } = useFragment(
    estimationNewPageFragment,
    query as new_EstimationNewPageFragment$key,
  );

  const sourceResponse = (estimationResponses?.edges || [])[0]?.node;
  const sourceAssignee = (assignees?.edges || [])[0]?.node;

  const estimationRequestId =
    sourceResponse?.assignee.estimationRequest.id || sourceAssignee?.estimationRequest.id;

  const { grpcClient, authorized } = useGrpcClient(ItemService);

  const items =
    sourceResponse?.details?.map((detail): SpecOnlyItem => {
      const item = extractItemFromDetail(detail);

      return new SpecOnlyItem({
        id: item.id,
        specJson: Struct.fromJson(item.specJSON),
      });
    }) ||
    sourceAssignee?.estimationRequest.details?.map((detail): SpecOnlyItem => {
      const item = extractItemFromDetail(detail);

      return new SpecOnlyItem({
        id: item.id,
        specJson: Struct.fromJson(item.specJSON),
      });
    }) ||
    [];

  const [sortedSpecs, setSortedSpecs] = useState<SortedSpec[]>([]);
  const [loadedSortedSpecs, setLoadedSortedSpecs] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    // ソート済みの仕様リストに変換する
    (async () => {
      if (!authorized) return;
      if (items.length === 0) {
        setLoadedSortedSpecs(true);
        return;
      }

      try {
        const res = await grpcClient.convertToSortedSpec({
          type: SortedSpecType.PUBLIC,
          items,
        });
        setSortedSpecs(res.sortedSpecs);
      } catch (e) {
        captureException(e);
      } finally {
        setLoadedSortedSpecs(true);
      }
    })();
  }, [sourceResponse?.details, sourceAssignee?.estimationRequest.details, authorized, grpcClient]);

  const { data, showPreview, onClickToConfirm, onSubmit, onClickToNewForm, isMutationInFlight } =
    useEstimationForm({
      state: buildCreateEstimationFormState({
        responseData: sourceResponse,
        assigneeData: sourceAssignee,
        taxCategories,
        currentUser,
      }),
    });

  if ((responseId || assigneeId) && !loadedSortedSpecs) return null;

  data.details = data.details.map((detail) => {
    const sortedSpec = sortedSpecs.find((spec) => spec.id === detail.responseItemId);
    if (!sortedSpec) return detail;

    const itemSpecs = sortedSpec.values.slice(0).map((spec, i) => {
      if (spec.isNull) return null;
      switch (spec.value.case) {
        case 'stringValue':
          if (spec.value.value === '') return null;
          if (i === 0) return spec.value.value;
          return `${spec.label}：${spec.value.value}`;
        case 'intValue':
          return `${spec.label}：${spec.value.value}`;
        case 'doubleValue':
          return `${spec.label}：${spec.value.value}`;
        case 'boolValue':
          return `${spec.label}：${spec.value.value}`;
      }
    });

    const name = `${itemSpecs.filter((s) => s !== null).join('\n')}`;
    return {
      ...detail,
      name,
    };
  });

  // 確認画面の遷移時に再度上のループが回って品名が戻ってしまうのを防ぐため空にする
  if (sortedSpecs.length > 0) {
    setSortedSpecs([]);
  }

  return (
    <Suspense fallback={<Loading />}>
      {showPreview ? (
        <EstimationConfirm
          onClickSubmit={onSubmit}
          data={data}
          isMutationInFlight={isMutationInFlight}
          onClickToNewForm={onClickToNewForm}
        />
      ) : (
        <EstimationForm
          onClickToConfirm={onClickToConfirm}
          data={data}
          queryRef={query}
          defaultGrossMarginRatio={responseId ? DEFAULT_GROSS_MARGIN_RATIO : undefined}
          previousUrl={
            estimationRequestId ? paths.estimationRequest._id(estimationRequestId) : undefined
          }
        />
      )}
    </Suspense>
  );
};
