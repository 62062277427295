/**
 * @generated SignedSource<<322e2aab24639cb46e63c31ef6b9dc0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useExportEstimationRequestMutation$variables = {
  id: string;
};
export type useExportEstimationRequestMutation$data = {
  readonly exportEstimationRequest: {
    readonly url: string;
  };
};
export type useExportEstimationRequestMutation = {
  response: useExportEstimationRequestMutation$data;
  variables: useExportEstimationRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "EstimationRequestExportData",
    "kind": "LinkedField",
    "name": "exportEstimationRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useExportEstimationRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useExportEstimationRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "47b9f05304d3500533ae3a838935b7d7",
    "id": null,
    "metadata": {},
    "name": "useExportEstimationRequestMutation",
    "operationKind": "mutation",
    "text": "mutation useExportEstimationRequestMutation(\n  $id: ID!\n) {\n  exportEstimationRequest(id: $id) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "d3cb36651ef278f2b08b60e08a54e28a";

export default node;
