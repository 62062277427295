export const request = {
  get: async (path: string, token: string | undefined = undefined) => {
    const url = new URL(import.meta.env.VITE_LUBE_API_DOMAIN);
    url.pathname = path;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return await handleResponse(response);
  },
  post: async (
    path: string,
    data: { [key: string]: unknown },
    token: string | undefined = undefined,
  ) => {
    const url = new URL(import.meta.env.VITE_LUBE_API_DOMAIN);
    url.pathname = path;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(data),
    });
    return await handleResponse(response);
  },
};

const handleResponse = async (response: Response) => {
  if (!response.ok) {
    const err = await response.json();
    throw new Error(err);
  }

  return await response;
};
