import { createContext } from 'react';

export const KeepVersionContext = createContext<{
  keepVersion: boolean;
  setKeepVersion: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export type PricesMemoType = {
  quantity: number | string;
  unitPrice: number | string;
  unitSellingPrice: number | string;
};

export const PricesMemoContext = createContext<Map<string, PricesMemoType> | null>(null);

export const DemandItemContext = createContext<{
  itemId: string;
  isCurrentVersion: boolean;
} | null>(null);
