import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandItemConfirm } from 'features/demandItem/components/DemandItemConfirm/DemandItemConfirm';
import { DemandItemFormType } from 'features/demandItem/form';
import {} from 'features/demandItem/models';
import { confirm_DemandItemCreateConfirmPageMutation } from 'gql/__generated__/confirm_DemandItemCreateConfirmPageMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Navigate, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandIdContext } from '../context';

export const DemandItemCreateConfirmPage = () => {
  const navigate = useNavigate();
  const form = useFormContext<DemandItemFormType>();

  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<confirm_DemandItemCreateConfirmPageMutation>(
      graphql`
      mutation confirm_DemandItemCreateConfirmPageMutation(
        $input: DemandItemInput!
      ) {
        createDemandItem(input: $input) {
          id
        }
      }
    `,
    );

  const { getValues } = form;
  const { name } = getValues();

  const demandId = useContext(DemandIdContext);
  if (!demandId) {
    throw new Error('demandId is null');
  }

  if (!name) return <Navigate to={paths.demands._demandCode(demandCode).items.new.url()} />;

  const handleSubmit = (data: DemandItemFormType) => {
    commitMutation({
      variables: {
        input: {
          category: data.category,
          demandID: demandId,
          name: data.name,
          prices: data.prices.map((price) => ({
            quantity: Number(price.quantity) || 0,
            unitPrice: Number(price.unitPrice) || 0,
            unitSellingPrice: Number(price.unitSellingPrice) || 0,
          })),
          spec: {
            cardboard: data.cardboard,
            flexiblePackage: data.flexiblePackage,
            giftBox: data.giftBox,
            paperBag: data.paperBag,
            other: data.other,
          },
          supplierID: data.supplier.id,
          taxCategoryID: data.taxId,
          internalMemo: data.memo,
        },
      },
      onError() {
        toast({
          title: '商品の作成に失敗しました',
          status: 'error',
        });
      },
      onCompleted: () => {
        toast({
          title: '商品を作成しました',
          status: 'success',
        });
        navigate(paths.demands._demandCode(demandCode).url());
      },
    });
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.demands._demandCode(demandCode).items.new.url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="新規商品の追加" />
      <DemandItemConfirm onSubmit={handleSubmit} isSubmitting={isMutationInFlight} />
    </>
  );
};
