import { db } from 'db/LubeIndexedDB';
import { EstimationRequestFormState } from 'features/estimationRequest/hooks/useEstimationRequestForm';

export async function getFirstEstimationRequestDraft() {
  return db.estimationRequestDrafts.toCollection().first();
}

export async function addEstimationRequestDraft(data: EstimationRequestFormState) {
  return await db.estimationRequestDrafts.add({
    body: data,
    createdAt: Date.now(),
  });
}

export async function updateEstimationRequestDraft(data: EstimationRequestFormState) {
  return db.transaction('rw', db.estimationRequestDrafts, async () => {
    await removeEstimationRequestDraft();
    await addEstimationRequestDraft(data);
  });
}

export async function removeEstimationRequestDraft() {
  return await db.estimationRequestDrafts.toCollection().delete();
}

export async function getEstimationRequestDraftCount() {
  return await db.estimationRequestDrafts.count();
}
