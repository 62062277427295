/**
 * @generated SignedSource<<5884dd130714e5bdba7032813a15dc48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type supplierPageQuery$variables = {};
export type supplierPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"supplierPageFragment">;
};
export type supplierPageQuery = {
  response: supplierPageQuery$data;
  variables: supplierPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "direction",
        "value": "DESC"
      },
      {
        "kind": "Literal",
        "name": "field",
        "value": "CREATED_AT"
      }
    ],
    "kind": "ObjectValue",
    "name": "orderBy"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "supplierPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "supplierPageFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "supplierPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "SupplierConnection",
        "kind": "LinkedField",
        "name": "suppliers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SupplierEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Supplier",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplierContact",
                    "kind": "LinkedField",
                    "name": "contacts",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "suppliers(first:100,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "orderBy",
          "where"
        ],
        "handle": "connection",
        "key": "supplierPageFragment_suppliers",
        "kind": "LinkedHandle",
        "name": "suppliers"
      }
    ]
  },
  "params": {
    "cacheID": "9d22a2facec603f7f01dfca0b03b2483",
    "id": null,
    "metadata": {},
    "name": "supplierPageQuery",
    "operationKind": "query",
    "text": "query supplierPageQuery {\n  ...supplierPageFragment\n}\n\nfragment SupplierTableRowFragment on Supplier {\n  id\n  name\n  contacts {\n    id\n    lastName\n    firstName\n  }\n}\n\nfragment supplierPageFragment on Query {\n  suppliers(first: 100, orderBy: {direction: DESC, field: CREATED_AT}) {\n    edges {\n      cursor\n      node {\n        id\n        ...SupplierTableRowFragment\n        __typename\n      }\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7afb2c5a19a4ec5a1a4a89265978b338";

export default node;
