/**
 * @generated SignedSource<<08b552d30a0351b817254a4dfa92d13b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type new_SalesOrderNewPageQuery$variables = {
  id: string;
  isSkip: boolean;
};
export type new_SalesOrderNewPageQuery$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly userID: string;
  };
  readonly estimations?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly company: {
          readonly id: string;
          readonly name: string;
        };
        readonly detailMessage: string | null;
        readonly details: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly documentDisplayStatus: EstimationDetailDocumentDisplayStatus;
              readonly item: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly tax: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitPrice: number;
                readonly unitSellingPrice: number;
              } | null;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
            }> | null;
          };
        }> | null;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
        readonly title: string;
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
};
export type new_SalesOrderNewPageQuery = {
  response: new_SalesOrderNewPageQuery$data;
  variables: new_SalesOrderNewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSkip"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detailMessage",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "ASC",
      "field": "ORDER_NUMBER"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "documentDisplayStatus": "shown"
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "documentDisplayStatus",
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rate",
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "EstimationItem",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitSellingPrice",
      "storageKey": null
    },
    {
      "alias": "tax",
      "args": null,
      "concreteType": "TaxCategory",
      "kind": "LinkedField",
      "name": "taxCategory",
      "plural": false,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "TaxCategoryConnection",
  "kind": "LinkedField",
  "name": "taxCategories",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategory",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userID",
      "storageKey": null
    },
    (v14/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "new_SalesOrderNewPageQuery",
    "selections": [
      {
        "condition": "isSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EstimationConnection",
            "kind": "LinkedField",
            "name": "estimations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EstimationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Estimation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": (v9/*: any*/),
                        "concreteType": "EstimationDetailConnection",
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationDetailEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationDetail",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"},where:{\"documentDisplayStatus\":\"shown\"})"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationInternalAssignee",
                        "kind": "LinkedField",
                        "name": "internalAssignees",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserProfile",
                                "kind": "LinkedField",
                                "name": "profile",
                                "plural": true,
                                "selections": [
                                  (v13/*: any*/),
                                  (v14/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v15/*: any*/),
      (v16/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "new_SalesOrderNewPageQuery",
    "selections": [
      {
        "condition": "isSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EstimationConnection",
            "kind": "LinkedField",
            "name": "estimations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EstimationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Estimation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": (v9/*: any*/),
                        "concreteType": "EstimationDetailConnection",
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationDetailEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationDetail",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v12/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"},where:{\"documentDisplayStatus\":\"shown\"})"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationInternalAssignee",
                        "kind": "LinkedField",
                        "name": "internalAssignees",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserProfile",
                                "kind": "LinkedField",
                                "name": "profile",
                                "plural": true,
                                "selections": [
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v15/*: any*/),
      (v16/*: any*/)
    ]
  },
  "params": {
    "cacheID": "b10e11d85ee7da7ccc16170baf17d491",
    "id": null,
    "metadata": {},
    "name": "new_SalesOrderNewPageQuery",
    "operationKind": "query",
    "text": "query new_SalesOrderNewPageQuery(\n  $id: ID!\n  $isSkip: Boolean!\n) {\n  estimations(where: {id: $id}) @skip(if: $isSkip) {\n    edges {\n      node {\n        id\n        company {\n          id\n          name\n        }\n        supplier {\n          id\n          name\n        }\n        title\n        detailMessage\n        details(orderBy: {direction: ASC, field: ORDER_NUMBER}, where: {documentDisplayStatus: shown}) {\n          edges {\n            node {\n              documentDisplayStatus\n              item {\n                id\n                name\n                quantity\n                unitPrice\n                unitSellingPrice\n                tax: taxCategory {\n                  id\n                  rate\n                }\n              }\n              id\n            }\n          }\n        }\n        internalAssignees {\n          user {\n            id\n            profile {\n              lastName\n              firstName\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  taxCategories {\n    edges {\n      node {\n        id\n        rate\n      }\n    }\n  }\n  currentUser {\n    userID\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "20f06da4c68695e1c481d5230dae6094";

export default node;
