import { useSalesOrderCreateFormMutation } from 'gql/__generated__/useSalesOrderCreateFormMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { usePreventNavigation } from 'hooks/usePreventNavigation';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { toast } from 'lib/toast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { cdateJST } from 'utils/date';
import { paths } from 'utils/paths';
import { SALES_ORDER_SUBMIT_TYPE, SalesOrderFormValuesType } from '../form';

export const useSalesOrderCreateForm = ({
  defaultValue,
  estimationId,
}: { defaultValue: SalesOrderFormValuesType; estimationId: string }) => {
  const versionWatcher = useContext(VersionWatcherContext);
  useEffect(versionWatcher.disableSoftDeleteEffect, []);

  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const [showPreview, setPreview] = useState(false);
  const [values, setValues] = useState(defaultValue);
  const [showBlocker, setShowBlocker] = useState(false);
  const [isDirtyMemo, setDirtyMemo] = useState(false);

  const toConfirm = (data: SalesOrderFormValuesType, isDirty: boolean) => {
    setValues({ ...data });
    setPreview(true);
    setDirtyMemo(isDirty);

    const params = {} as { [key: string]: string };
    if (estimationId) params.estimationId = estimationId;
    params.type = SALES_ORDER_SUBMIT_TYPE.confirm;
    setSearchParams(params);
  };

  const toForm = () => {
    setPreview(false);
  };

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<useSalesOrderCreateFormMutation>(
      graphql`
      mutation useSalesOrderCreateFormMutation(
        $input: CreateSalesOrderInput!
      ) {
        createSalesOrder(input: $input) {
          id
        }
      }
    `,
    );

  const onSubmit = () => {
    const details = values.details.map((detail, index) => ({ ...detail, orderNumber: index + 1 }));

    commitMutation({
      variables: {
        input: {
          demandID: values.company.id,
          supplierID: values.supplier.id,
          title: values.title,
          detailMessage: values.detailMessage,
          estimationID: values.estimationID || null,
          receivedDate: cdateJST(values.receivedDate).toDate(),
          details: details.map((detail) => ({
            orderNumber: detail.orderNumber,
            fixedDeliveryDate: detail.fixedDeliveryDate
              ? cdateJST(detail.fixedDeliveryDate).toDate()
              : null,
            type: 'item',
            Item: {
              name: detail.name,
              unitPrice: Number(detail.unitPrice) || 0,
              unitSellingPrice: Number(detail.unitSellingPrice) || 0,
              quantity: Number(detail.quantity) || 0,
              taxCategoryID: detail.tax.id,
            },
          })),
          internalAssignees: [
            ...(values.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
          internalMemo: values.memo,
        },
      },
      onCompleted: (res) => {
        toast({
          title: '発注請書を作成しました',
          description: '受注DBと案件DBの紐付けをお願いします',
          status: 'success',
        });
        navigate(paths.salesOrder.id(res.createSalesOrder.id).url());
      },
    });
  };

  usePreventNavigation(showBlocker);

  return {
    showPreview,
    values,
    isMutationInFlight,
    onSubmit,
    toConfirm,
    toForm,
    setShowBlocker,
    isDirtyMemo,
  };
};
