import { Loading } from '@icon-park/react';
import { LinkageFreeeModal } from 'components/LinkageFreeeModal';
import { InvoiceConfirm } from 'features/invoice/components/InvoiceConfirm';
import { InvoiceInputForm } from 'features/invoice/components/InvoiceInputForm';
import { InvoiceSalesOrdersSelectForm } from 'features/invoice/components/InvoiceSalesOrdersSelectForm';
import { INVOICE_SUBMIT_TYPE } from 'features/invoice/form';
import { useInvoiceCreateForm } from 'features/invoice/hooks/useInvoiceCreateForm';
import { useQueryParams } from 'features/invoice/hooks/useInvoiceQueryPrams';
import { useLinkageFreee } from 'features/invoice/hooks/useLinkageFreee';
import { new_InvoiceNewPageQuery } from 'gql/__generated__/new_InvoiceNewPageQuery.graphql';
import { Suspense, useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const NewInvoicePageQuery = graphql`
  query new_InvoiceNewPageQuery {
    userInfo {
      connectedServices {
        freee
      }
    }
  }
`;

export const InvoiceNewPage = () => {
  const { queryParams } = useQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');

  const {
    salesOrderSelectValues,
    invoiceInputValues,
    fetchIds,
    currentPage,
    fromSelectToInput,
    fromInputToSelect,
    fromConfirmToInput,
    fromInputToConfirm,
    onSubmit,
    isMutationInFlight,
  } = useInvoiceCreateForm();

  const { userInfo } = useLazyLoadQuery<new_InvoiceNewPageQuery>(
    NewInvoicePageQuery,
    {},
    { fetchPolicy: 'network-only' },
  );
  const isOpenLinkageDialog = !userInfo.connectedServices.freee;
  const navigate = useNavigate();
  const { onLinkageFreeeApi } = useLinkageFreee();

  useEffect(() => {
    if (type && currentPage === INVOICE_SUBMIT_TYPE.select) {
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [currentPage, searchParams, setSearchParams, type]);

  return (
    <>
      <LinkageFreeeModal
        isOpen={isOpenLinkageDialog}
        closeButtonLabel="請求書一覧へ"
        onClose={() => {
          navigate(
            paths.invoice.url({
              creator: queryParams.creator,
              demand: queryParams.demand,
            }),
          );
        }}
        onClickLinkage={onLinkageFreeeApi}
      ></LinkageFreeeModal>
      <Suspense fallback={<Loading />}>
        {currentPage === INVOICE_SUBMIT_TYPE.confirm && type === INVOICE_SUBMIT_TYPE.confirm && (
          <InvoiceConfirm
            demand={salesOrderSelectValues.demand.name}
            values={invoiceInputValues}
            isMutationInFlight={isMutationInFlight}
            toInputForm={fromConfirmToInput}
            onSubmit={onSubmit}
          />
        )}
        {currentPage === INVOICE_SUBMIT_TYPE.input && type === INVOICE_SUBMIT_TYPE.input && (
          <InvoiceInputForm
            defaultValues={invoiceInputValues}
            selectedSalesOrderDetailIds={salesOrderSelectValues.salesOrderDetailIds}
            fetchIds={fetchIds}
            toSelectForm={fromInputToSelect}
            onSubmit={fromInputToConfirm}
          />
        )}
        {INVOICE_SUBMIT_TYPE.select && !type && (
          <InvoiceSalesOrdersSelectForm
            defaultValues={salesOrderSelectValues}
            onSubmit={fromSelectToInput}
          />
        )}
      </Suspense>
    </>
  );
};
