import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { redirect } from 'react-router-dom';
import { auth } from 'service/firebase';

export const useSession = () => {
  const [currentUser, isLoading, error] = useAuthState(auth);

  const signOut = () => {
    auth.signOut().then(() => {
      redirect('/login');
    });
  };

  const signIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      hd: 'shiza1.com',
    });
    signInWithPopup(auth, provider)
      .then(async (_result) => {
        redirect('/');
      })
      .catch((error) => {
        // TODO: login error process
        console.log(error);
      });
  };

  return {
    currentUser,
    isLoading,
    error,
    signOut,
    signIn,
  };
};
