export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  Cursor: { input: any; output: any; }
  Map: { input: any; output: any; }
  Time: { input: any; output: any; }
  TransactionID: { input: any; output: any; }
  Uint32: { input: any; output: any; }
  Uint64: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AddAssigneesToEstimationRequestInput = {
  mailSubject: Scalars['String']['input'];
  specText: Scalars['String']['input'];
  suppliers?: InputMaybe<Array<EstimationRequestSupplier>>;
};

export type Company = Node & {
  __typename?: 'Company';
  code: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandItems: DemandItemConnection;
  demandRecurringCosts: DemandRecurringCostConnection;
  estimationRequests?: Maybe<Array<EstimationRequest>>;
  estimations?: Maybe<Array<Estimation>>;
  id: Scalars['ID']['output'];
  internalMemos?: Maybe<Array<DemandInternalMemo>>;
  invoices?: Maybe<Array<Invoice>>;
  name: Scalars['String']['output'];
  orders?: Maybe<Array<Order>>;
  updatedAt: Scalars['Time']['output'];
};


export type CompanyDemandItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DemandItemOrder>;
  where?: InputMaybe<DemandItemWhereInput>;
};


export type CompanyDemandRecurringCostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DemandRecurringCostOrder>;
  where?: InputMaybe<DemandRecurringCostWhereInput>;
};

/** A connection to a list of items. */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Company>;
};

/** Ordering options for Company connections */
export type CompanyOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Companies. */
  field: CompanyOrderField;
};

/** Properties by which Company connections can be ordered. */
export enum CompanyOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * CompanyWhereInput is used for filtering Company objects.
 * Input was generated by ent.
 */
export type CompanyWhereInput = {
  and?: InputMaybe<Array<CompanyWhereInput>>;
  /** code field predicates */
  code?: InputMaybe<Scalars['String']['input']>;
  codeContains?: InputMaybe<Scalars['String']['input']>;
  codeContainsFold?: InputMaybe<Scalars['String']['input']>;
  codeEqualFold?: InputMaybe<Scalars['String']['input']>;
  codeGT?: InputMaybe<Scalars['String']['input']>;
  codeGTE?: InputMaybe<Scalars['String']['input']>;
  codeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  codeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  codeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  codeLT?: InputMaybe<Scalars['String']['input']>;
  codeLTE?: InputMaybe<Scalars['String']['input']>;
  codeNEQ?: InputMaybe<Scalars['String']['input']>;
  codeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_items edge predicates */
  hasDemandItems?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandItemsWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** demand_recurring_costs edge predicates */
  hasDemandRecurringCosts?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandRecurringCostsWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** estimation_requests edge predicates */
  hasEstimationRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestsWith?: InputMaybe<Array<EstimationRequestWhereInput>>;
  /** estimations edge predicates */
  hasEstimations?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationsWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** internal_memos edge predicates */
  hasInternalMemos?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalMemosWith?: InputMaybe<Array<DemandInternalMemoWhereInput>>;
  /** invoices edge predicates */
  hasInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoicesWith?: InputMaybe<Array<InvoiceWhereInput>>;
  /** orders edge predicates */
  hasOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrdersWith?: InputMaybe<Array<OrderWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<CompanyWhereInput>;
  or?: InputMaybe<Array<CompanyWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ConnectedServices = {
  __typename?: 'ConnectedServices';
  freee: Scalars['Boolean']['output'];
};

export type CreateBulkEstimationRequestInput = {
  attachments?: InputMaybe<Array<UploadFile>>;
  companyID: Scalars['ID']['input'];
  details: Array<CreateEstimationRequestDetailInput>;
  internalAssignees?: InputMaybe<Array<CreateEstimationRequestInternalAssigneeInput>>;
  mailSubject: Scalars['String']['input'];
  requestTitle: Scalars['String']['input'];
  specText: Scalars['String']['input'];
  suppliers?: InputMaybe<Array<EstimationRequestSupplier>>;
};

export type CreateDemandInput = {
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/**
 * CreateEstimationDetailInput is used for create EstimationDetail object.
 * Input was generated by ent.
 */
export type CreateEstimationDetailInput = {
  costID?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  /** 帳票表示ステータス(shown=表示する, hidden=非表示) */
  documentDisplayStatus?: InputMaybe<EstimationDetailDocumentDisplayStatus>;
  estimationID: Scalars['ID']['input'];
  itemID?: InputMaybe<Scalars['ID']['input']>;
  orderNumber: Scalars['Uint32']['input'];
  textID?: InputMaybe<Scalars['ID']['input']>;
  /** item=商品, cost=費用, text=テキスト */
  type: EstimationDetailType;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateEstimationInput is used for create Estimation object.
 * Input was generated by ent.
 */
export type CreateEstimationInput = {
  companyID: Scalars['ID']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  detailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  estimationResponseID?: InputMaybe<Scalars['ID']['input']>;
  expirationPeriod: Scalars['String']['input'];
  internalAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  showAmountSummary?: InputMaybe<Scalars['Boolean']['input']>;
  supplierID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

/**
 * CreateEstimationItemInput is used for create EstimationItem object.
 * Input was generated by ent.
 */
export type CreateEstimationItemInput = {
  category?: InputMaybe<ItemCategory>;
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  quantity: Scalars['Uint32']['input'];
  taxCategoryID: Scalars['ID']['input'];
  unitPrice: Scalars['Float']['input'];
  unitSellingPrice: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateEstimationRequestAssigneeInput is used for create EstimationRequestAssignee object.
 * Input was generated by ent.
 */
export type CreateEstimationRequestAssigneeInput = {
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  defaultDetailMessageID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestID: Scalars['ID']['input'];
  mailSubject: Scalars['String']['input'];
  responseIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierContactIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierID: Scalars['ID']['input'];
  /** テキストメール本文 */
  textMailBody: Scalars['String']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

/**
 * CreateEstimationRequestAttachmentInput is used for create EstimationRequestAttachment object.
 * Input was generated by ent.
 */
export type CreateEstimationRequestAttachmentInput = {
  byteSize: Scalars['Uint64']['input'];
  contentType: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  estimationRequestID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

export type CreateEstimationRequestDetailInput = {
  type: EstimationRequestDetailType;
  value: EstimationDetailValueInput;
};

/**
 * CreateEstimationRequestInput is used for create EstimationRequest object.
 * Input was generated by ent.
 */
export type CreateEstimationRequestInput = {
  companyID: Scalars['ID']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  detailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  internalAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  requestAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  requestAttachmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  requestTitle: Scalars['String']['input'];
  /** 商品仕様/納期/納品場所など(フリーテキスト) */
  specText: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

export type CreateEstimationRequestInternalAssigneeInput = {
  userID: Scalars['ID']['input'];
};

/**
 * CreateEstimationRequestItemInput is used for create EstimationRequestItem object.
 * Input was generated by ent.
 */
export type CreateEstimationRequestItemInput = {
  annualQuantity?: InputMaybe<Scalars['String']['input']>;
  askingUnitPrice?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<ItemCategory>;
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  detailID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  quantity: Scalars['Uint32']['input'];
  /** TODO: 2023/06現在、必要となる仕様が確定していないため一旦JSONカラムを採用する。仕様確定したら正規化を行う */
  specJSON?: InputMaybe<Scalars['Map']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateEstimationResponseInput is used for create EstimationResponse object.
 * Input was generated by ent.
 */
export type CreateEstimationResponseInput = {
  assigneeID: Scalars['ID']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  detailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  expirationPeriod: Scalars['String']['input'];
  importantNotes: Scalars['String']['input'];
  responseDetailMessage?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateEstimationResponseItemInput is used for create EstimationResponseItem object.
 * Input was generated by ent.
 */
export type CreateEstimationResponseItemInput = {
  annualQuantity?: InputMaybe<Scalars['String']['input']>;
  askingUnitPrice?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<ItemCategory>;
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  detailID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  quantity: Scalars['Uint32']['input'];
  /** TODO: 2023/06現在、必要となる仕様が確定していないため一旦JSONカラムを採用する。仕様確定したら正規化を行う */
  specJSON?: InputMaybe<Scalars['Map']['input']>;
  unitPrice: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

export type CreateInvoiceDetailInput = {
  Item?: InputMaybe<InvoiceItemInput>;
  orderNumber: Scalars['Int']['input'];
  salesDate?: InputMaybe<Scalars['Time']['input']>;
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  type: InvoiceDetailType;
};

/**
 * CreateInvoiceInput is used for create Invoice object.
 * Input was generated by ent.
 */
export type CreateInvoiceInput = {
  /** 会計計上日 */
  bookedAt: Scalars['Time']['input'];
  companyID: Scalars['ID']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  detailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  estimationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  freeeInvoiceID?: InputMaybe<Scalars['ID']['input']>;
  internalMemoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 支払期日 */
  paymentDueAt: Scalars['Time']['input'];
  salesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

/**
 * CreateOrderAssigneeInput is used for create OrderAssignee object.
 * Input was generated by ent.
 */
export type CreateOrderAssigneeInput = {
  contactID: Scalars['ID']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  orderID: Scalars['ID']['input'];
  recipientType: OrderAssigneeRecipientType;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateOrderDeliveryInfoInput is used for create OrderDeliveryInfo object.
 * Input was generated by ent.
 */
export type CreateOrderDeliveryInfoInput = {
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  /** 希望納品日: 「最短」のように指定することもあるので文字列 */
  desiredDeliveryDate: Scalars['String']['input'];
  orderID: Scalars['ID']['input'];
  /** 配送先。入力の簡素化のため正規化は見送る。複数先の配送先情報が入力される場合もある。 */
  recipientsText?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

/**
 * CreateOrderDetailInput is used for create OrderDetail object.
 * Input was generated by ent.
 */
export type CreateOrderDetailInput = {
  /** 枝番 */
  branchNumber: Scalars['Uint32']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  displayOrder: Scalars['Uint32']['input'];
  itemID?: InputMaybe<Scalars['ID']['input']>;
  onetimeCostID?: InputMaybe<Scalars['ID']['input']>;
  orderID: Scalars['ID']['input'];
  recurringCostID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  /** item=商品, onetime_cost=単発費用, recurring_cost=継続費用 */
  type: OrderDetailType;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateOrderInput is used for create Order object.
 * Input was generated by ent.
 */
export type CreateOrderInput = {
  assigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyID: Scalars['ID']['input'];
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deliveryInfoID?: InputMaybe<Scalars['ID']['input']>;
  detailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  estimationID?: InputMaybe<Scalars['ID']['input']>;
  internalAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  mailBodyMessage?: InputMaybe<Scalars['String']['input']>;
  salesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

export type CreateOrderInternalAssigneeInput = {
  userID: Scalars['ID']['input'];
};

/**
 * CreateOrderItemInput is used for create OrderItem object.
 * Input was generated by ent.
 */
export type CreateOrderItemInput = {
  category?: InputMaybe<ItemCategory>;
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  orderDetailID: Scalars['ID']['input'];
  quantity: Scalars['Uint32']['input'];
  taxCategoryID: Scalars['ID']['input'];
  unitPrice: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

export type CreateSalesOrderDetailInput = {
  Item?: InputMaybe<CreateSalesOrderItemInput>;
  fixedDeliveryDate?: InputMaybe<Scalars['Time']['input']>;
  orderNumber: Scalars['Int']['input'];
  type: SalesOrderDetailType;
};

export type CreateSalesOrderInput = {
  demandID: Scalars['ID']['input'];
  detailMessage: Scalars['String']['input'];
  details: Array<CreateSalesOrderDetailInput>;
  estimationID?: InputMaybe<Scalars['ID']['input']>;
  internalAssignees?: InputMaybe<Array<SalesOrderInternalAssigneeInput>>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  receivedDate: Scalars['Time']['input'];
  supplierID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateSalesOrderItemInput = {
  name: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  taxCategoryID: Scalars['ID']['input'];
  unitPrice: Scalars['Float']['input'];
  unitSellingPrice: Scalars['Float']['input'];
};

/**
 * CreateSupplierContactInput is used for create SupplierContact object.
 * Input was generated by ent.
 */
export type CreateSupplierContactInput = {
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  email: Scalars['String']['input'];
  estimationRequestAsigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  memoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierID: Scalars['ID']['input'];
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

/**
 * CreateSupplierInput is used for create Supplier object.
 * Input was generated by ent.
 */
export type CreateSupplierInput = {
  contactIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdByID: Scalars['ID']['input'];
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  estimationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  orderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID: Scalars['ID']['input'];
};

/**
 * CreateUserInput is used for create User object.
 * Input was generated by ent.
 */
export type CreateUserInput = {
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  email: Scalars['String']['input'];
  fbAuthUUID?: InputMaybe<Scalars['String']['input']>;
  oauthAccessTokenIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  profileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userID: Scalars['ID']['output'];
};

export type DeletedSalesOrder = {
  __typename?: 'DeletedSalesOrder';
  id: Scalars['ID']['output'];
};

export type DemandInternalMemo = Node & {
  __typename?: 'DemandInternalMemo';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demand: Company;
  demandID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** Ordering options for DemandInternalMemo connections */
export type DemandInternalMemoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandInternalMemos. */
  field: DemandInternalMemoOrderField;
};

/** Properties by which DemandInternalMemo connections can be ordered. */
export enum DemandInternalMemoOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * DemandInternalMemoWhereInput is used for filtering DemandInternalMemo objects.
 * Input was generated by ent.
 */
export type DemandInternalMemoWhereInput = {
  and?: InputMaybe<Array<DemandInternalMemoWhereInput>>;
  /** body field predicates */
  body?: InputMaybe<Scalars['String']['input']>;
  bodyContains?: InputMaybe<Scalars['String']['input']>;
  bodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  bodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  bodyGT?: InputMaybe<Scalars['String']['input']>;
  bodyGTE?: InputMaybe<Scalars['String']['input']>;
  bodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyLT?: InputMaybe<Scalars['String']['input']>;
  bodyLTE?: InputMaybe<Scalars['String']['input']>;
  bodyNEQ?: InputMaybe<Scalars['String']['input']>;
  bodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_id field predicates */
  demandID?: InputMaybe<Scalars['ID']['input']>;
  demandIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** demand edge predicates */
  hasDemand?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandInternalMemoWhereInput>;
  or?: InputMaybe<Array<DemandInternalMemoWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DemandItem = Node & {
  __typename?: 'DemandItem';
  attachments?: Maybe<Array<DemandItemAttachment>>;
  cardboard?: Maybe<DemandItemCardboard>;
  category: ItemCategory;
  children?: Maybe<Array<DemandItem>>;
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demand: Company;
  demandID: Scalars['ID']['output'];
  /** アイテムがいつまで有効か */
  endedAt?: Maybe<Scalars['Time']['output']>;
  estimationItems?: Maybe<Array<EstimationItem>>;
  flexiblePackage?: Maybe<DemandItemFlexiblePackage>;
  giftBox?: Maybe<DemandItemGiftBox>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<DemandItemImage>>;
  internalMemos?: Maybe<Array<DemandItemInternalMemo>>;
  invoiceItems?: Maybe<Array<InvoiceItem>>;
  /** 現在有効なアイテムかどうか */
  isCurrent: Scalars['Boolean']['output'];
  itemNumber: Scalars['Uint32']['output'];
  name: Scalars['String']['output'];
  orderItems: OrderItemConnection;
  original: DemandItem;
  other?: Maybe<DemandItemOther>;
  paperBag?: Maybe<DemandItemPaperBag>;
  prices?: Maybe<Array<DemandItemPrice>>;
  salesOrderItems?: Maybe<Array<SalesOrderItem>>;
  /** アイテムがいつから有効か */
  startedAt?: Maybe<Scalars['Time']['output']>;
  supplier: Supplier;
  supplierID: Scalars['ID']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
  version: Scalars['Uint32']['output'];
};


export type DemandItemOrderItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderItemOrder>;
  where?: InputMaybe<OrderItemWhereInput>;
};

export type DemandItemAttachment = Node & {
  __typename?: 'DemandItemAttachment';
  byteSize: Scalars['Uint64']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  demandItemID: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  /** CloudStorage上のオブジェクト名(パス) */
  objectName: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DemandItemAttachmentConnection = {
  __typename?: 'DemandItemAttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemAttachmentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemAttachmentEdge = {
  __typename?: 'DemandItemAttachmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemAttachment>;
};

/** Ordering options for DemandItemAttachment connections */
export type DemandItemAttachmentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemAttachments. */
  field: DemandItemAttachmentOrderField;
};

/** Properties by which DemandItemAttachment connections can be ordered. */
export enum DemandItemAttachmentOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * DemandItemAttachmentWhereInput is used for filtering DemandItemAttachment objects.
 * Input was generated by ent.
 */
export type DemandItemAttachmentWhereInput = {
  and?: InputMaybe<Array<DemandItemAttachmentWhereInput>>;
  /** byte_size field predicates */
  byteSize?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeGT?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeGTE?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  byteSizeLT?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeLTE?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeNEQ?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeNotIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  /** content_type field predicates */
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentTypeContains?: InputMaybe<Scalars['String']['input']>;
  contentTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  contentTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  contentTypeGT?: InputMaybe<Scalars['String']['input']>;
  contentTypeGTE?: InputMaybe<Scalars['String']['input']>;
  contentTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  contentTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  contentTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contentTypeLT?: InputMaybe<Scalars['String']['input']>;
  contentTypeLTE?: InputMaybe<Scalars['String']['input']>;
  contentTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  contentTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** file_name field predicates */
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileNameContains?: InputMaybe<Scalars['String']['input']>;
  fileNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fileNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fileNameGT?: InputMaybe<Scalars['String']['input']>;
  fileNameGTE?: InputMaybe<Scalars['String']['input']>;
  fileNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fileNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fileNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fileNameLT?: InputMaybe<Scalars['String']['input']>;
  fileNameLTE?: InputMaybe<Scalars['String']['input']>;
  fileNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fileNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandItemAttachmentWhereInput>;
  /** object_name field predicates */
  objectName?: InputMaybe<Scalars['String']['input']>;
  objectNameContains?: InputMaybe<Scalars['String']['input']>;
  objectNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  objectNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  objectNameGT?: InputMaybe<Scalars['String']['input']>;
  objectNameGTE?: InputMaybe<Scalars['String']['input']>;
  objectNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  objectNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  objectNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  objectNameLT?: InputMaybe<Scalars['String']['input']>;
  objectNameLTE?: InputMaybe<Scalars['String']['input']>;
  objectNameNEQ?: InputMaybe<Scalars['String']['input']>;
  objectNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<DemandItemAttachmentWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DemandItemCardboard = Node & {
  __typename?: 'DemandItemCardboard';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 段厚 */
  thickness: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DemandItemCardboardConnection = {
  __typename?: 'DemandItemCardboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemCardboardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemCardboardEdge = {
  __typename?: 'DemandItemCardboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemCardboard>;
};

/** Ordering options for DemandItemCardboard connections */
export type DemandItemCardboardOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemCardboards. */
  field: DemandItemCardboardOrderField;
};

/** Properties by which DemandItemCardboard connections can be ordered. */
export enum DemandItemCardboardOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type DemandItemCardboardSpecInput = {
  material: Scalars['String']['input'];
  other: Scalars['String']['input'];
  printingColor: Scalars['String']['input'];
  processing: Scalars['String']['input'];
  size: Scalars['String']['input'];
  thickness: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

/**
 * DemandItemCardboardWhereInput is used for filtering DemandItemCardboard objects.
 * Input was generated by ent.
 */
export type DemandItemCardboardWhereInput = {
  and?: InputMaybe<Array<DemandItemCardboardWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<DemandItemCardboardWhereInput>;
  or?: InputMaybe<Array<DemandItemCardboardWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** thickness field predicates */
  thickness?: InputMaybe<Scalars['String']['input']>;
  thicknessContains?: InputMaybe<Scalars['String']['input']>;
  thicknessContainsFold?: InputMaybe<Scalars['String']['input']>;
  thicknessEqualFold?: InputMaybe<Scalars['String']['input']>;
  thicknessGT?: InputMaybe<Scalars['String']['input']>;
  thicknessGTE?: InputMaybe<Scalars['String']['input']>;
  thicknessHasPrefix?: InputMaybe<Scalars['String']['input']>;
  thicknessHasSuffix?: InputMaybe<Scalars['String']['input']>;
  thicknessIn?: InputMaybe<Array<Scalars['String']['input']>>;
  thicknessLT?: InputMaybe<Scalars['String']['input']>;
  thicknessLTE?: InputMaybe<Scalars['String']['input']>;
  thicknessNEQ?: InputMaybe<Scalars['String']['input']>;
  thicknessNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type DemandItemConnection = {
  __typename?: 'DemandItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemEdge = {
  __typename?: 'DemandItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItem>;
};

export type DemandItemFlexiblePackage = Node & {
  __typename?: 'DemandItemFlexiblePackage';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DemandItemFlexiblePackageConnection = {
  __typename?: 'DemandItemFlexiblePackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemFlexiblePackageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemFlexiblePackageEdge = {
  __typename?: 'DemandItemFlexiblePackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemFlexiblePackage>;
};

/** Ordering options for DemandItemFlexiblePackage connections */
export type DemandItemFlexiblePackageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemFlexiblePackages. */
  field: DemandItemFlexiblePackageOrderField;
};

/** Properties by which DemandItemFlexiblePackage connections can be ordered. */
export enum DemandItemFlexiblePackageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type DemandItemFlexiblePackageSpecInput = {
  material: Scalars['String']['input'];
  other: Scalars['String']['input'];
  printingColor: Scalars['String']['input'];
  processing: Scalars['String']['input'];
  size: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

/**
 * DemandItemFlexiblePackageWhereInput is used for filtering DemandItemFlexiblePackage objects.
 * Input was generated by ent.
 */
export type DemandItemFlexiblePackageWhereInput = {
  and?: InputMaybe<Array<DemandItemFlexiblePackageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<DemandItemFlexiblePackageWhereInput>;
  or?: InputMaybe<Array<DemandItemFlexiblePackageWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DemandItemGiftBox = Node & {
  __typename?: 'DemandItemGiftBox';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DemandItemGiftBoxConnection = {
  __typename?: 'DemandItemGiftBoxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemGiftBoxEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemGiftBoxEdge = {
  __typename?: 'DemandItemGiftBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemGiftBox>;
};

/** Ordering options for DemandItemGiftBox connections */
export type DemandItemGiftBoxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemGiftBoxes. */
  field: DemandItemGiftBoxOrderField;
};

/** Properties by which DemandItemGiftBox connections can be ordered. */
export enum DemandItemGiftBoxOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type DemandItemGiftBoxSpecInput = {
  other: Scalars['String']['input'];
  paperType: Scalars['String']['input'];
  printingColor: Scalars['String']['input'];
  processing: Scalars['String']['input'];
  size: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

/**
 * DemandItemGiftBoxWhereInput is used for filtering DemandItemGiftBox objects.
 * Input was generated by ent.
 */
export type DemandItemGiftBoxWhereInput = {
  and?: InputMaybe<Array<DemandItemGiftBoxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandItemGiftBoxWhereInput>;
  or?: InputMaybe<Array<DemandItemGiftBoxWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DemandItemImage = Node & {
  __typename?: 'DemandItemImage';
  byteSize: Scalars['Uint64']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  demandItemID: Scalars['ID']['output'];
  /** 画面上での表示順 */
  displayOrder: Scalars['Uint32']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  /** CloudStorage上のオブジェクト名(パス) */
  objectName: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DemandItemImageConnection = {
  __typename?: 'DemandItemImageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemImageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemImageEdge = {
  __typename?: 'DemandItemImageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemImage>;
};

/** Ordering options for DemandItemImage connections */
export type DemandItemImageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemImages. */
  field: DemandItemImageOrderField;
};

/** Properties by which DemandItemImage connections can be ordered. */
export enum DemandItemImageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export enum DemandItemImageSize {
  Size216x216 = 'Size216x216',
  Size440x440 = 'Size440x440',
  Size896x896 = 'Size896x896',
  Size1440x1440 = 'Size1440x1440'
}

/**
 * DemandItemImageWhereInput is used for filtering DemandItemImage objects.
 * Input was generated by ent.
 */
export type DemandItemImageWhereInput = {
  and?: InputMaybe<Array<DemandItemImageWhereInput>>;
  /** byte_size field predicates */
  byteSize?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeGT?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeGTE?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  byteSizeLT?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeLTE?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeNEQ?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeNotIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  /** content_type field predicates */
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentTypeContains?: InputMaybe<Scalars['String']['input']>;
  contentTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  contentTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  contentTypeGT?: InputMaybe<Scalars['String']['input']>;
  contentTypeGTE?: InputMaybe<Scalars['String']['input']>;
  contentTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  contentTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  contentTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contentTypeLT?: InputMaybe<Scalars['String']['input']>;
  contentTypeLTE?: InputMaybe<Scalars['String']['input']>;
  contentTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  contentTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_order field predicates */
  displayOrder?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderGT?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderGTE?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  displayOrderLT?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderLTE?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** file_name field predicates */
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileNameContains?: InputMaybe<Scalars['String']['input']>;
  fileNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fileNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fileNameGT?: InputMaybe<Scalars['String']['input']>;
  fileNameGTE?: InputMaybe<Scalars['String']['input']>;
  fileNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fileNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fileNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fileNameLT?: InputMaybe<Scalars['String']['input']>;
  fileNameLTE?: InputMaybe<Scalars['String']['input']>;
  fileNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fileNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandItemImageWhereInput>;
  /** object_name field predicates */
  objectName?: InputMaybe<Scalars['String']['input']>;
  objectNameContains?: InputMaybe<Scalars['String']['input']>;
  objectNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  objectNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  objectNameGT?: InputMaybe<Scalars['String']['input']>;
  objectNameGTE?: InputMaybe<Scalars['String']['input']>;
  objectNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  objectNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  objectNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  objectNameLT?: InputMaybe<Scalars['String']['input']>;
  objectNameLTE?: InputMaybe<Scalars['String']['input']>;
  objectNameNEQ?: InputMaybe<Scalars['String']['input']>;
  objectNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<DemandItemImageWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DemandItemInput = {
  category: ItemCategory;
  demandID: Scalars['ID']['input'];
  endedAt?: InputMaybe<Scalars['Time']['input']>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  prices: Array<DemandItemPriceInput>;
  spec: DemandItemSpecInput;
  startedAt?: InputMaybe<Scalars['Time']['input']>;
  supplierID: Scalars['ID']['input'];
  taxCategoryID: Scalars['ID']['input'];
};

export type DemandItemInternalMemo = Node & {
  __typename?: 'DemandItemInternalMemo';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type DemandItemInternalMemoConnection = {
  __typename?: 'DemandItemInternalMemoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemInternalMemoEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemInternalMemoEdge = {
  __typename?: 'DemandItemInternalMemoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemInternalMemo>;
};

/** Ordering options for DemandItemInternalMemo connections */
export type DemandItemInternalMemoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemInternalMemos. */
  field: DemandItemInternalMemoOrderField;
};

/** Properties by which DemandItemInternalMemo connections can be ordered. */
export enum DemandItemInternalMemoOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * DemandItemInternalMemoWhereInput is used for filtering DemandItemInternalMemo objects.
 * Input was generated by ent.
 */
export type DemandItemInternalMemoWhereInput = {
  and?: InputMaybe<Array<DemandItemInternalMemoWhereInput>>;
  /** body field predicates */
  body?: InputMaybe<Scalars['String']['input']>;
  bodyContains?: InputMaybe<Scalars['String']['input']>;
  bodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  bodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  bodyGT?: InputMaybe<Scalars['String']['input']>;
  bodyGTE?: InputMaybe<Scalars['String']['input']>;
  bodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyLT?: InputMaybe<Scalars['String']['input']>;
  bodyLTE?: InputMaybe<Scalars['String']['input']>;
  bodyNEQ?: InputMaybe<Scalars['String']['input']>;
  bodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandItemInternalMemoWhereInput>;
  or?: InputMaybe<Array<DemandItemInternalMemoWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Ordering options for DemandItem connections */
export type DemandItemOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItems. */
  field: DemandItemOrderField;
};

/** Properties by which DemandItem connections can be ordered. */
export enum DemandItemOrderField {
  CreatedAt = 'CREATED_AT',
  EndedAt = 'ENDED_AT',
  Id = 'ID',
  StartedAt = 'STARTED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type DemandItemOther = Node & {
  __typename?: 'DemandItemOther';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DemandItemOtherConnection = {
  __typename?: 'DemandItemOtherConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemOtherEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemOtherEdge = {
  __typename?: 'DemandItemOtherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemOther>;
};

/** Ordering options for DemandItemOther connections */
export type DemandItemOtherOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemOthers. */
  field: DemandItemOtherOrderField;
};

/** Properties by which DemandItemOther connections can be ordered. */
export enum DemandItemOtherOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type DemandItemOtherSpecInput = {
  specText: Scalars['String']['input'];
};

/**
 * DemandItemOtherWhereInput is used for filtering DemandItemOther objects.
 * Input was generated by ent.
 */
export type DemandItemOtherWhereInput = {
  and?: InputMaybe<Array<DemandItemOtherWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandItemOtherWhereInput>;
  or?: InputMaybe<Array<DemandItemOtherWhereInput>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DemandItemPaperBag = Node & {
  __typename?: 'DemandItemPaperBag';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandItemID: Scalars['ID']['output'];
  /** 持ち手 */
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DemandItemPaperBagConnection = {
  __typename?: 'DemandItemPaperBagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemPaperBagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemPaperBagEdge = {
  __typename?: 'DemandItemPaperBagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemPaperBag>;
};

/** Ordering options for DemandItemPaperBag connections */
export type DemandItemPaperBagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemPaperBags. */
  field: DemandItemPaperBagOrderField;
};

/** Properties by which DemandItemPaperBag connections can be ordered. */
export enum DemandItemPaperBagOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type DemandItemPaperBagSpecInput = {
  handle: Scalars['String']['input'];
  other: Scalars['String']['input'];
  paperType: Scalars['String']['input'];
  printingColor: Scalars['String']['input'];
  processing: Scalars['String']['input'];
  size: Scalars['String']['input'];
};

/**
 * DemandItemPaperBagWhereInput is used for filtering DemandItemPaperBag objects.
 * Input was generated by ent.
 */
export type DemandItemPaperBagWhereInput = {
  and?: InputMaybe<Array<DemandItemPaperBagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** handle field predicates */
  handle?: InputMaybe<Scalars['String']['input']>;
  handleContains?: InputMaybe<Scalars['String']['input']>;
  handleContainsFold?: InputMaybe<Scalars['String']['input']>;
  handleEqualFold?: InputMaybe<Scalars['String']['input']>;
  handleGT?: InputMaybe<Scalars['String']['input']>;
  handleGTE?: InputMaybe<Scalars['String']['input']>;
  handleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  handleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  handleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  handleLT?: InputMaybe<Scalars['String']['input']>;
  handleLTE?: InputMaybe<Scalars['String']['input']>;
  handleNEQ?: InputMaybe<Scalars['String']['input']>;
  handleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandItemPaperBagWhereInput>;
  or?: InputMaybe<Array<DemandItemPaperBagWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DemandItemPrice = Node & {
  __typename?: 'DemandItemPrice';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  demandItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: DemandItem;
  quantity: Scalars['Uint32']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type DemandItemPriceConnection = {
  __typename?: 'DemandItemPriceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandItemPriceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandItemPriceEdge = {
  __typename?: 'DemandItemPriceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandItemPrice>;
};

export type DemandItemPriceInput = {
  quantity: Scalars['Int']['input'];
  unitPrice: Scalars['Float']['input'];
  unitSellingPrice: Scalars['Float']['input'];
};

/** Ordering options for DemandItemPrice connections */
export type DemandItemPriceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandItemPrices. */
  field: DemandItemPriceOrderField;
};

/** Properties by which DemandItemPrice connections can be ordered. */
export enum DemandItemPriceOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * DemandItemPriceWhereInput is used for filtering DemandItemPrice objects.
 * Input was generated by ent.
 */
export type DemandItemPriceWhereInput = {
  and?: InputMaybe<Array<DemandItemPriceWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandItemPriceWhereInput>;
  or?: InputMaybe<Array<DemandItemPriceWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DemandItemSpecInput = {
  cardboard?: InputMaybe<DemandItemCardboardSpecInput>;
  flexiblePackage?: InputMaybe<DemandItemFlexiblePackageSpecInput>;
  giftBox?: InputMaybe<DemandItemGiftBoxSpecInput>;
  other?: InputMaybe<DemandItemOtherSpecInput>;
  paperBag?: InputMaybe<DemandItemPaperBagSpecInput>;
};

export type DemandItemUpdateInput = {
  category: ItemCategory;
  endedAt?: InputMaybe<Scalars['Time']['input']>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  prices: Array<DemandItemPriceInput>;
  spec: DemandItemSpecInput;
  startedAt?: InputMaybe<Scalars['Time']['input']>;
  supplierID: Scalars['ID']['input'];
  taxCategoryID: Scalars['ID']['input'];
};

/**
 * DemandItemWhereInput is used for filtering DemandItem objects.
 * Input was generated by ent.
 */
export type DemandItemWhereInput = {
  and?: InputMaybe<Array<DemandItemWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<ItemCategory>;
  categoryIn?: InputMaybe<Array<ItemCategory>>;
  categoryNEQ?: InputMaybe<ItemCategory>;
  categoryNotIn?: InputMaybe<Array<ItemCategory>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_id field predicates */
  demandID?: InputMaybe<Scalars['ID']['input']>;
  demandIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** ended_at field predicates */
  endedAt?: InputMaybe<Scalars['Time']['input']>;
  endedAtGT?: InputMaybe<Scalars['Time']['input']>;
  endedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  endedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endedAtLT?: InputMaybe<Scalars['Time']['input']>;
  endedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  endedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  endedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** attachments edge predicates */
  hasAttachments?: InputMaybe<Scalars['Boolean']['input']>;
  hasAttachmentsWith?: InputMaybe<Array<DemandItemAttachmentWhereInput>>;
  /** cardboard edge predicates */
  hasCardboard?: InputMaybe<Scalars['Boolean']['input']>;
  hasCardboardWith?: InputMaybe<Array<DemandItemCardboardWhereInput>>;
  /** children edge predicates */
  hasChildren?: InputMaybe<Scalars['Boolean']['input']>;
  hasChildrenWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** demand edge predicates */
  hasDemand?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** estimation_items edge predicates */
  hasEstimationItems?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationItemsWith?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** flexible_package edge predicates */
  hasFlexiblePackage?: InputMaybe<Scalars['Boolean']['input']>;
  hasFlexiblePackageWith?: InputMaybe<Array<DemandItemFlexiblePackageWhereInput>>;
  /** gift_box edge predicates */
  hasGiftBox?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftBoxWith?: InputMaybe<Array<DemandItemGiftBoxWhereInput>>;
  /** images edge predicates */
  hasImages?: InputMaybe<Scalars['Boolean']['input']>;
  hasImagesWith?: InputMaybe<Array<DemandItemImageWhereInput>>;
  /** internal_memos edge predicates */
  hasInternalMemos?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalMemosWith?: InputMaybe<Array<DemandItemInternalMemoWhereInput>>;
  /** invoice_items edge predicates */
  hasInvoiceItems?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceItemsWith?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** order_items edge predicates */
  hasOrderItems?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderItemsWith?: InputMaybe<Array<OrderItemWhereInput>>;
  /** original edge predicates */
  hasOriginal?: InputMaybe<Scalars['Boolean']['input']>;
  hasOriginalWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** other edge predicates */
  hasOther?: InputMaybe<Scalars['Boolean']['input']>;
  hasOtherWith?: InputMaybe<Array<DemandItemOtherWhereInput>>;
  /** paper_bag edge predicates */
  hasPaperBag?: InputMaybe<Scalars['Boolean']['input']>;
  hasPaperBagWith?: InputMaybe<Array<DemandItemPaperBagWhereInput>>;
  /** prices edge predicates */
  hasPrices?: InputMaybe<Scalars['Boolean']['input']>;
  hasPricesWith?: InputMaybe<Array<DemandItemPriceWhereInput>>;
  /** sales_order_items edge predicates */
  hasSalesOrderItems?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderItemsWith?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** supplier edge predicates */
  hasSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierWith?: InputMaybe<Array<SupplierWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_current field predicates */
  isCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  isCurrentNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** item_number field predicates */
  itemNumber?: InputMaybe<Scalars['Uint32']['input']>;
  itemNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  itemNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  itemNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  itemNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  itemNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  itemNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  itemNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<DemandItemWhereInput>;
  or?: InputMaybe<Array<DemandItemWhereInput>>;
  /** started_at field predicates */
  startedAt?: InputMaybe<Scalars['Time']['input']>;
  startedAtGT?: InputMaybe<Scalars['Time']['input']>;
  startedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  startedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  startedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  startedAtLT?: InputMaybe<Scalars['Time']['input']>;
  startedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  startedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  startedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  startedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** supplier_id field predicates */
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** version field predicates */
  version?: InputMaybe<Scalars['Uint32']['input']>;
  versionGT?: InputMaybe<Scalars['Uint32']['input']>;
  versionGTE?: InputMaybe<Scalars['Uint32']['input']>;
  versionIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  versionLT?: InputMaybe<Scalars['Uint32']['input']>;
  versionLTE?: InputMaybe<Scalars['Uint32']['input']>;
  versionNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  versionNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
};

export type DemandRecurringCost = Node & {
  __typename?: 'DemandRecurringCost';
  children?: Maybe<Array<DemandRecurringCost>>;
  costNumber: Scalars['Uint32']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demand: Company;
  demandID: Scalars['ID']['output'];
  /** 費用がいつまで有効か */
  endedAt?: Maybe<Scalars['Time']['output']>;
  estimationCosts?: Maybe<Array<EstimationCost>>;
  id: Scalars['ID']['output'];
  internalMemos?: Maybe<Array<DemandRecurringCostInternalMemo>>;
  invoiceRecurringCosts?: Maybe<Array<InvoiceRecurringCost>>;
  /** 現在有効な費用かどうか */
  isCurrent: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  orderRecurringCosts: OrderRecurringCostConnection;
  original: DemandRecurringCost;
  prices?: Maybe<Array<DemandRecurringCostPrice>>;
  salesOrderRecurringCosts?: Maybe<Array<SalesOrderRecurringCost>>;
  /** 費用がいつから有効か */
  startedAt?: Maybe<Scalars['Time']['output']>;
  supplier?: Maybe<Supplier>;
  supplierID?: Maybe<Scalars['ID']['output']>;
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
  version: Scalars['Uint32']['output'];
};


export type DemandRecurringCostOrderRecurringCostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderRecurringCostOrder>;
  where?: InputMaybe<OrderRecurringCostWhereInput>;
};

/** A connection to a list of items. */
export type DemandRecurringCostConnection = {
  __typename?: 'DemandRecurringCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandRecurringCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandRecurringCostEdge = {
  __typename?: 'DemandRecurringCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandRecurringCost>;
};

export type DemandRecurringCostInput = {
  demandID: Scalars['ID']['input'];
  endedAt?: InputMaybe<Scalars['Time']['input']>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note: Scalars['String']['input'];
  prices: Array<DemandRecurringCostPriceInput>;
  startedAt?: InputMaybe<Scalars['Time']['input']>;
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryID: Scalars['ID']['input'];
};

export type DemandRecurringCostInternalMemo = Node & {
  __typename?: 'DemandRecurringCostInternalMemo';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demandRecurringCostID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  recurringCost: DemandRecurringCost;
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type DemandRecurringCostInternalMemoConnection = {
  __typename?: 'DemandRecurringCostInternalMemoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandRecurringCostInternalMemoEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandRecurringCostInternalMemoEdge = {
  __typename?: 'DemandRecurringCostInternalMemoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandRecurringCostInternalMemo>;
};

/** Ordering options for DemandRecurringCostInternalMemo connections */
export type DemandRecurringCostInternalMemoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandRecurringCostInternalMemos. */
  field: DemandRecurringCostInternalMemoOrderField;
};

/** Properties by which DemandRecurringCostInternalMemo connections can be ordered. */
export enum DemandRecurringCostInternalMemoOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * DemandRecurringCostInternalMemoWhereInput is used for filtering DemandRecurringCostInternalMemo objects.
 * Input was generated by ent.
 */
export type DemandRecurringCostInternalMemoWhereInput = {
  and?: InputMaybe<Array<DemandRecurringCostInternalMemoWhereInput>>;
  /** body field predicates */
  body?: InputMaybe<Scalars['String']['input']>;
  bodyContains?: InputMaybe<Scalars['String']['input']>;
  bodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  bodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  bodyGT?: InputMaybe<Scalars['String']['input']>;
  bodyGTE?: InputMaybe<Scalars['String']['input']>;
  bodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyLT?: InputMaybe<Scalars['String']['input']>;
  bodyLTE?: InputMaybe<Scalars['String']['input']>;
  bodyNEQ?: InputMaybe<Scalars['String']['input']>;
  bodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_recurring_cost_id field predicates */
  demandRecurringCostID?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** recurring_cost edge predicates */
  hasRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringCostWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandRecurringCostInternalMemoWhereInput>;
  or?: InputMaybe<Array<DemandRecurringCostInternalMemoWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Ordering options for DemandRecurringCost connections */
export type DemandRecurringCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandRecurringCosts. */
  field: DemandRecurringCostOrderField;
};

/** Properties by which DemandRecurringCost connections can be ordered. */
export enum DemandRecurringCostOrderField {
  CreatedAt = 'CREATED_AT',
  EndedAt = 'ENDED_AT',
  Id = 'ID',
  StartedAt = 'STARTED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type DemandRecurringCostPrice = Node & {
  __typename?: 'DemandRecurringCostPrice';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  demandRecurringCostID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Uint32']['output'];
  recurringCost: DemandRecurringCost;
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type DemandRecurringCostPriceConnection = {
  __typename?: 'DemandRecurringCostPriceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DemandRecurringCostPriceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DemandRecurringCostPriceEdge = {
  __typename?: 'DemandRecurringCostPriceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DemandRecurringCostPrice>;
};

export type DemandRecurringCostPriceInput = {
  quantity: Scalars['Int']['input'];
  unitPrice: Scalars['Float']['input'];
  unitSellingPrice: Scalars['Float']['input'];
};

/** Ordering options for DemandRecurringCostPrice connections */
export type DemandRecurringCostPriceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DemandRecurringCostPrices. */
  field: DemandRecurringCostPriceOrderField;
};

/** Properties by which DemandRecurringCostPrice connections can be ordered. */
export enum DemandRecurringCostPriceOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * DemandRecurringCostPriceWhereInput is used for filtering DemandRecurringCostPrice objects.
 * Input was generated by ent.
 */
export type DemandRecurringCostPriceWhereInput = {
  and?: InputMaybe<Array<DemandRecurringCostPriceWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** demand_recurring_cost_id field predicates */
  demandRecurringCostID?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** recurring_cost edge predicates */
  hasRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringCostWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DemandRecurringCostPriceWhereInput>;
  or?: InputMaybe<Array<DemandRecurringCostPriceWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DemandRecurringCostUpdateInput = {
  endedAt?: InputMaybe<Scalars['Time']['input']>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note: Scalars['String']['input'];
  prices: Array<DemandRecurringCostPriceInput>;
  startedAt?: InputMaybe<Scalars['Time']['input']>;
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryID: Scalars['ID']['input'];
};

/**
 * DemandRecurringCostWhereInput is used for filtering DemandRecurringCost objects.
 * Input was generated by ent.
 */
export type DemandRecurringCostWhereInput = {
  and?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** cost_number field predicates */
  costNumber?: InputMaybe<Scalars['Uint32']['input']>;
  costNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  costNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  costNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  costNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  costNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  costNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  costNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_id field predicates */
  demandID?: InputMaybe<Scalars['ID']['input']>;
  demandIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** ended_at field predicates */
  endedAt?: InputMaybe<Scalars['Time']['input']>;
  endedAtGT?: InputMaybe<Scalars['Time']['input']>;
  endedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  endedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endedAtLT?: InputMaybe<Scalars['Time']['input']>;
  endedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  endedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  endedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** children edge predicates */
  hasChildren?: InputMaybe<Scalars['Boolean']['input']>;
  hasChildrenWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** demand edge predicates */
  hasDemand?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** estimation_costs edge predicates */
  hasEstimationCosts?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationCostsWith?: InputMaybe<Array<EstimationCostWhereInput>>;
  /** internal_memos edge predicates */
  hasInternalMemos?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalMemosWith?: InputMaybe<Array<DemandRecurringCostInternalMemoWhereInput>>;
  /** invoice_recurring_costs edge predicates */
  hasInvoiceRecurringCosts?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceRecurringCostsWith?: InputMaybe<Array<InvoiceRecurringCostWhereInput>>;
  /** order_recurring_costs edge predicates */
  hasOrderRecurringCosts?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderRecurringCostsWith?: InputMaybe<Array<OrderRecurringCostWhereInput>>;
  /** original edge predicates */
  hasOriginal?: InputMaybe<Scalars['Boolean']['input']>;
  hasOriginalWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** prices edge predicates */
  hasPrices?: InputMaybe<Scalars['Boolean']['input']>;
  hasPricesWith?: InputMaybe<Array<DemandRecurringCostPriceWhereInput>>;
  /** sales_order_recurring_costs edge predicates */
  hasSalesOrderRecurringCosts?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderRecurringCostsWith?: InputMaybe<Array<SalesOrderRecurringCostWhereInput>>;
  /** supplier edge predicates */
  hasSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierWith?: InputMaybe<Array<SupplierWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_current field predicates */
  isCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  isCurrentNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<DemandRecurringCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** started_at field predicates */
  startedAt?: InputMaybe<Scalars['Time']['input']>;
  startedAtGT?: InputMaybe<Scalars['Time']['input']>;
  startedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  startedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  startedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  startedAtLT?: InputMaybe<Scalars['Time']['input']>;
  startedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  startedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  startedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  startedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** supplier_id field predicates */
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  supplierIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** version field predicates */
  version?: InputMaybe<Scalars['Uint32']['input']>;
  versionGT?: InputMaybe<Scalars['Uint32']['input']>;
  versionGTE?: InputMaybe<Scalars['Uint32']['input']>;
  versionIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  versionLT?: InputMaybe<Scalars['Uint32']['input']>;
  versionLTE?: InputMaybe<Scalars['Uint32']['input']>;
  versionNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  versionNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
};

export type Estimation = Node & {
  __typename?: 'Estimation';
  company: Company;
  companyID: Scalars['ID']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  detailMessage?: Maybe<Scalars['String']['output']>;
  details: EstimationDetailConnection;
  estimationResponse?: Maybe<EstimationResponse>;
  estimationResponseID?: Maybe<Scalars['ID']['output']>;
  expirationPeriod: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalAssignees?: Maybe<Array<EstimationInternalAssignee>>;
  orders?: Maybe<Array<Order>>;
  salesOrders?: Maybe<Array<SalesOrder>>;
  showAmountSummary: Scalars['Boolean']['output'];
  supplier: Supplier;
  supplierID: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};


export type EstimationDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationDetailOrder>;
  where?: InputMaybe<EstimationDetailWhereInput>;
};

/** A connection to a list of items. */
export type EstimationConnection = {
  __typename?: 'EstimationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type EstimationCost = Node & {
  __typename?: 'EstimationCost';
  createdAt: Scalars['Time']['output'];
  demandRecurringCost?: Maybe<DemandRecurringCost>;
  /** マスタと紐づくとは限らないためNULLを許容する */
  demandRecurringCostID?: Maybe<Scalars['ID']['output']>;
  estimationDetail: EstimationDetail;
  estimationDetailID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationCostConnection = {
  __typename?: 'EstimationCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationCostEdge = {
  __typename?: 'EstimationCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationCost>;
};

/** Ordering options for EstimationCost connections */
export type EstimationCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationCosts. */
  field: EstimationCostOrderField;
};

/** Properties by which EstimationCost connections can be ordered. */
export enum EstimationCostOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationCostWhereInput is used for filtering EstimationCost objects.
 * Input was generated by ent.
 */
export type EstimationCostWhereInput = {
  and?: InputMaybe<Array<EstimationCostWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_recurring_cost_id field predicates */
  demandRecurringCostID?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandRecurringCostIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** estimation_detail_id field predicates */
  estimationDetailID?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** demand_recurring_cost edge predicates */
  hasDemandRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandRecurringCostWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** estimation_detail edge predicates */
  hasEstimationDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationDetailWith?: InputMaybe<Array<EstimationDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<EstimationCostWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationDetail = Node & {
  __typename?: 'EstimationDetail';
  cost?: Maybe<EstimationCost>;
  createdAt: Scalars['Time']['output'];
  /** 帳票表示ステータス(shown=表示する, hidden=非表示) */
  documentDisplayStatus: EstimationDetailDocumentDisplayStatus;
  estimation: Estimation;
  estimationID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item?: Maybe<EstimationItem>;
  orderNumber: Scalars['Uint32']['output'];
  text?: Maybe<EstimationText>;
  /** item=商品, cost=費用, text=テキスト */
  type: EstimationDetailType;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationDetailConnection = {
  __typename?: 'EstimationDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationDetailEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** EstimationDetailDocumentDisplayStatus is enum for the field document_display_status */
export enum EstimationDetailDocumentDisplayStatus {
  Hidden = 'hidden',
  Shown = 'shown'
}

/** An edge in a connection. */
export type EstimationDetailEdge = {
  __typename?: 'EstimationDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationDetail>;
};

export type EstimationDetailInput = {
  documentDisplayStatus?: InputMaybe<EstimationDetailDocumentDisplayStatus>;
  name: Scalars['String']['input'];
  orderNumber: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  taxCategoryID: Scalars['ID']['input'];
  unitPrice: Scalars['Float']['input'];
  unitSellingPrice: Scalars['Float']['input'];
};

/** Ordering options for EstimationDetail connections */
export type EstimationDetailOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationDetails. */
  field: EstimationDetailOrderField;
};

/** Properties by which EstimationDetail connections can be ordered. */
export enum EstimationDetailOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  OrderNumber = 'ORDER_NUMBER',
  UpdatedAt = 'UPDATED_AT'
}

/** EstimationDetailType is enum for the field type */
export enum EstimationDetailType {
  Cost = 'cost',
  Item = 'item',
  Text = 'text'
}

export type EstimationDetailTypeItemCardboardSpecInput = {
  material?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
  printingColor?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  thickness?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type EstimationDetailTypeItemFlexiblePackageSpecInput = {
  material?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
  printingColor?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type EstimationDetailTypeItemGiftBoxSpecInput = {
  other?: InputMaybe<Scalars['String']['input']>;
  paperType?: InputMaybe<Scalars['String']['input']>;
  printingColor?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type EstimationDetailTypeItemInput = {
  annualQuantity?: InputMaybe<Scalars['String']['input']>;
  askingUnitPrice?: InputMaybe<Scalars['String']['input']>;
  category: ItemCategory;
  name: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  spec?: InputMaybe<EstimationDetailTypeItemSpecInput>;
};

export type EstimationDetailTypeItemOtherSpecInput = {
  other?: InputMaybe<Scalars['String']['input']>;
};

export type EstimationDetailTypeItemPaperBagSpecInput = {
  handle?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
  paperType?: InputMaybe<Scalars['String']['input']>;
  printingColor?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
};

export type EstimationDetailTypeItemSpecInput = {
  cardboard?: InputMaybe<EstimationDetailTypeItemCardboardSpecInput>;
  flexiblePackage?: InputMaybe<EstimationDetailTypeItemFlexiblePackageSpecInput>;
  giftBox?: InputMaybe<EstimationDetailTypeItemGiftBoxSpecInput>;
  other?: InputMaybe<EstimationDetailTypeItemOtherSpecInput>;
  paperBag?: InputMaybe<EstimationDetailTypeItemPaperBagSpecInput>;
};

export type EstimationDetailValueInput = {
  item?: InputMaybe<EstimationDetailTypeItemInput>;
};

/**
 * EstimationDetailWhereInput is used for filtering EstimationDetail objects.
 * Input was generated by ent.
 */
export type EstimationDetailWhereInput = {
  and?: InputMaybe<Array<EstimationDetailWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** document_display_status field predicates */
  documentDisplayStatus?: InputMaybe<EstimationDetailDocumentDisplayStatus>;
  documentDisplayStatusIn?: InputMaybe<Array<EstimationDetailDocumentDisplayStatus>>;
  documentDisplayStatusNEQ?: InputMaybe<EstimationDetailDocumentDisplayStatus>;
  documentDisplayStatusNotIn?: InputMaybe<Array<EstimationDetailDocumentDisplayStatus>>;
  /** estimation_id field predicates */
  estimationID?: InputMaybe<Scalars['ID']['input']>;
  estimationIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** cost edge predicates */
  hasCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasCostWith?: InputMaybe<Array<EstimationCostWhereInput>>;
  /** estimation edge predicates */
  hasEstimation?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** text edge predicates */
  hasText?: InputMaybe<Scalars['Boolean']['input']>;
  hasTextWith?: InputMaybe<Array<EstimationTextWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationDetailWhereInput>;
  or?: InputMaybe<Array<EstimationDetailWhereInput>>;
  /** order_number field predicates */
  orderNumber?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  orderNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** type field predicates */
  type?: InputMaybe<EstimationDetailType>;
  typeIn?: InputMaybe<Array<EstimationDetailType>>;
  typeNEQ?: InputMaybe<EstimationDetailType>;
  typeNotIn?: InputMaybe<Array<EstimationDetailType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** An edge in a connection. */
export type EstimationEdge = {
  __typename?: 'EstimationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Estimation>;
};

export type EstimationInput = {
  companyID: Scalars['ID']['input'];
  detailMessage: Scalars['String']['input'];
  estimationResponseID?: InputMaybe<Scalars['ID']['input']>;
  expirationPeriod: Scalars['String']['input'];
  internalAssignees?: InputMaybe<Array<EstimationInternalAssigneeInput>>;
  items: Array<EstimationDetailInput>;
  showAmountSummary: Scalars['Boolean']['input'];
  supplierID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type EstimationInternalAssignee = Node & {
  __typename?: 'EstimationInternalAssignee';
  estimation: Estimation;
  estimationID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  user: User;
  userID: Scalars['ID']['output'];
};

export type EstimationInternalAssigneeInput = {
  userID: Scalars['ID']['input'];
};

/** Ordering options for EstimationInternalAssignee connections */
export type EstimationInternalAssigneeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationInternalAssignees. */
  field: EstimationInternalAssigneeOrderField;
};

/** Properties by which EstimationInternalAssignee connections can be ordered. */
export enum EstimationInternalAssigneeOrderField {
  Id = 'ID'
}

/**
 * EstimationInternalAssigneeWhereInput is used for filtering EstimationInternalAssignee objects.
 * Input was generated by ent.
 */
export type EstimationInternalAssigneeWhereInput = {
  and?: InputMaybe<Array<EstimationInternalAssigneeWhereInput>>;
  /** estimation_id field predicates */
  estimationID?: InputMaybe<Scalars['ID']['input']>;
  estimationIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** estimation edge predicates */
  hasEstimation?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationInternalAssigneeWhereInput>;
  or?: InputMaybe<Array<EstimationInternalAssigneeWhereInput>>;
  /** user_id field predicates */
  userID?: InputMaybe<Scalars['ID']['input']>;
  userIDContains?: InputMaybe<Scalars['ID']['input']>;
  userIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  userIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  userIDGT?: InputMaybe<Scalars['ID']['input']>;
  userIDGTE?: InputMaybe<Scalars['ID']['input']>;
  userIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  userIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  userIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIDLT?: InputMaybe<Scalars['ID']['input']>;
  userIDLTE?: InputMaybe<Scalars['ID']['input']>;
  userIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type EstimationItem = Node & {
  __typename?: 'EstimationItem';
  category?: Maybe<ItemCategory>;
  createdAt: Scalars['Time']['output'];
  demandItem?: Maybe<DemandItem>;
  /** 見積書作成時点ではアイテムがマスタ登録されていないためNULLを許容している */
  demandItemID?: Maybe<Scalars['ID']['output']>;
  estimationDetail: EstimationDetail;
  estimationDetailID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type EstimationItemCardboard = Node & {
  __typename?: 'EstimationItemCardboard';
  createdAt: Scalars['Time']['output'];
  estimationItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 段厚 */
  thickness: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationItemCardboardConnection = {
  __typename?: 'EstimationItemCardboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationItemCardboardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationItemCardboardEdge = {
  __typename?: 'EstimationItemCardboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationItemCardboard>;
};

/** Ordering options for EstimationItemCardboard connections */
export type EstimationItemCardboardOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationItemCardboards. */
  field: EstimationItemCardboardOrderField;
};

/** Properties by which EstimationItemCardboard connections can be ordered. */
export enum EstimationItemCardboardOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationItemCardboardWhereInput is used for filtering EstimationItemCardboard objects.
 * Input was generated by ent.
 */
export type EstimationItemCardboardWhereInput = {
  and?: InputMaybe<Array<EstimationItemCardboardWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_item_id field predicates */
  estimationItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationItemCardboardWhereInput>;
  or?: InputMaybe<Array<EstimationItemCardboardWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** thickness field predicates */
  thickness?: InputMaybe<Scalars['String']['input']>;
  thicknessContains?: InputMaybe<Scalars['String']['input']>;
  thicknessContainsFold?: InputMaybe<Scalars['String']['input']>;
  thicknessEqualFold?: InputMaybe<Scalars['String']['input']>;
  thicknessGT?: InputMaybe<Scalars['String']['input']>;
  thicknessGTE?: InputMaybe<Scalars['String']['input']>;
  thicknessHasPrefix?: InputMaybe<Scalars['String']['input']>;
  thicknessHasSuffix?: InputMaybe<Scalars['String']['input']>;
  thicknessIn?: InputMaybe<Array<Scalars['String']['input']>>;
  thicknessLT?: InputMaybe<Scalars['String']['input']>;
  thicknessLTE?: InputMaybe<Scalars['String']['input']>;
  thicknessNEQ?: InputMaybe<Scalars['String']['input']>;
  thicknessNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationItemFlexiblePackage = Node & {
  __typename?: 'EstimationItemFlexiblePackage';
  createdAt: Scalars['Time']['output'];
  estimationItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationItemFlexiblePackageConnection = {
  __typename?: 'EstimationItemFlexiblePackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationItemFlexiblePackageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationItemFlexiblePackageEdge = {
  __typename?: 'EstimationItemFlexiblePackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationItemFlexiblePackage>;
};

/** Ordering options for EstimationItemFlexiblePackage connections */
export type EstimationItemFlexiblePackageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationItemFlexiblePackages. */
  field: EstimationItemFlexiblePackageOrderField;
};

/** Properties by which EstimationItemFlexiblePackage connections can be ordered. */
export enum EstimationItemFlexiblePackageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationItemFlexiblePackageWhereInput is used for filtering EstimationItemFlexiblePackage objects.
 * Input was generated by ent.
 */
export type EstimationItemFlexiblePackageWhereInput = {
  and?: InputMaybe<Array<EstimationItemFlexiblePackageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_item_id field predicates */
  estimationItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationItemFlexiblePackageWhereInput>;
  or?: InputMaybe<Array<EstimationItemFlexiblePackageWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationItemGiftBox = Node & {
  __typename?: 'EstimationItemGiftBox';
  createdAt: Scalars['Time']['output'];
  estimationItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationItemGiftBoxConnection = {
  __typename?: 'EstimationItemGiftBoxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationItemGiftBoxEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationItemGiftBoxEdge = {
  __typename?: 'EstimationItemGiftBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationItemGiftBox>;
};

/** Ordering options for EstimationItemGiftBox connections */
export type EstimationItemGiftBoxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationItemGiftBoxes. */
  field: EstimationItemGiftBoxOrderField;
};

/** Properties by which EstimationItemGiftBox connections can be ordered. */
export enum EstimationItemGiftBoxOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationItemGiftBoxWhereInput is used for filtering EstimationItemGiftBox objects.
 * Input was generated by ent.
 */
export type EstimationItemGiftBoxWhereInput = {
  and?: InputMaybe<Array<EstimationItemGiftBoxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_item_id field predicates */
  estimationItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationItemGiftBoxWhereInput>;
  or?: InputMaybe<Array<EstimationItemGiftBoxWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for EstimationItem connections */
export type EstimationItemOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationItems. */
  field: EstimationItemOrderField;
};

/** Properties by which EstimationItem connections can be ordered. */
export enum EstimationItemOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type EstimationItemOther = Node & {
  __typename?: 'EstimationItemOther';
  createdAt: Scalars['Time']['output'];
  estimationItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationItem;
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationItemOtherConnection = {
  __typename?: 'EstimationItemOtherConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationItemOtherEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationItemOtherEdge = {
  __typename?: 'EstimationItemOtherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationItemOther>;
};

/** Ordering options for EstimationItemOther connections */
export type EstimationItemOtherOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationItemOthers. */
  field: EstimationItemOtherOrderField;
};

/** Properties by which EstimationItemOther connections can be ordered. */
export enum EstimationItemOtherOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationItemOtherWhereInput is used for filtering EstimationItemOther objects.
 * Input was generated by ent.
 */
export type EstimationItemOtherWhereInput = {
  and?: InputMaybe<Array<EstimationItemOtherWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_item_id field predicates */
  estimationItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationItemOtherWhereInput>;
  or?: InputMaybe<Array<EstimationItemOtherWhereInput>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationItemPaperBag = Node & {
  __typename?: 'EstimationItemPaperBag';
  createdAt: Scalars['Time']['output'];
  estimationItemID: Scalars['ID']['output'];
  /** 持ち手 */
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: EstimationItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationItemPaperBagConnection = {
  __typename?: 'EstimationItemPaperBagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationItemPaperBagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationItemPaperBagEdge = {
  __typename?: 'EstimationItemPaperBagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationItemPaperBag>;
};

/** Ordering options for EstimationItemPaperBag connections */
export type EstimationItemPaperBagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationItemPaperBags. */
  field: EstimationItemPaperBagOrderField;
};

/** Properties by which EstimationItemPaperBag connections can be ordered. */
export enum EstimationItemPaperBagOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationItemPaperBagWhereInput is used for filtering EstimationItemPaperBag objects.
 * Input was generated by ent.
 */
export type EstimationItemPaperBagWhereInput = {
  and?: InputMaybe<Array<EstimationItemPaperBagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_item_id field predicates */
  estimationItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** handle field predicates */
  handle?: InputMaybe<Scalars['String']['input']>;
  handleContains?: InputMaybe<Scalars['String']['input']>;
  handleContainsFold?: InputMaybe<Scalars['String']['input']>;
  handleEqualFold?: InputMaybe<Scalars['String']['input']>;
  handleGT?: InputMaybe<Scalars['String']['input']>;
  handleGTE?: InputMaybe<Scalars['String']['input']>;
  handleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  handleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  handleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  handleLT?: InputMaybe<Scalars['String']['input']>;
  handleLTE?: InputMaybe<Scalars['String']['input']>;
  handleNEQ?: InputMaybe<Scalars['String']['input']>;
  handleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationItemPaperBagWhereInput>;
  or?: InputMaybe<Array<EstimationItemPaperBagWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * EstimationItemWhereInput is used for filtering EstimationItem objects.
 * Input was generated by ent.
 */
export type EstimationItemWhereInput = {
  and?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<ItemCategory>;
  categoryIn?: InputMaybe<Array<ItemCategory>>;
  categoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  categoryNEQ?: InputMaybe<ItemCategory>;
  categoryNotIn?: InputMaybe<Array<ItemCategory>>;
  categoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** estimation_detail_id field predicates */
  estimationDetailID?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** demand_item edge predicates */
  hasDemandItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** estimation_detail edge predicates */
  hasEstimationDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationDetailWith?: InputMaybe<Array<EstimationDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationItemWhereInput>;
  or?: InputMaybe<Array<EstimationItemWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for Estimation connections */
export type EstimationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Estimations. */
  field: EstimationOrderField;
};

/** Properties by which Estimation connections can be ordered. */
export enum EstimationOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type EstimationRequest = Node & {
  __typename?: 'EstimationRequest';
  company: Company;
  companyID: Scalars['ID']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  details?: Maybe<Array<EstimationRequestDetail>>;
  id: Scalars['ID']['output'];
  internalAssignees?: Maybe<Array<EstimationRequestInternalAssignee>>;
  requestAssignees?: Maybe<Array<EstimationRequestAssignee>>;
  requestAttachments?: Maybe<Array<EstimationRequestAttachment>>;
  requestTitle: Scalars['String']['output'];
  /** 商品仕様/納期/納品場所など(フリーテキスト) */
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

export type EstimationRequestAssignee = Node & {
  __typename?: 'EstimationRequestAssignee';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  estimationRequest: EstimationRequest;
  estimationRequestAssigneeContacts?: Maybe<Array<EstimationRequestAssigneeContact>>;
  estimationRequestID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mailSubject: Scalars['String']['output'];
  responses?: Maybe<Array<EstimationResponse>>;
  supplier: Supplier;
  supplierContacts?: Maybe<Array<SupplierContact>>;
  supplierID: Scalars['ID']['output'];
  /** テキストメール本文 */
  textMailBody: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestAssigneeConnection = {
  __typename?: 'EstimationRequestAssigneeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestAssigneeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type EstimationRequestAssigneeContact = Node & {
  __typename?: 'EstimationRequestAssigneeContact';
  assignee: EstimationRequestAssignee;
  contact: SupplierContact;
  createdAt: Scalars['Time']['output'];
  estimationRequestAssigneeID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  supplierContactID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestAssigneeContactConnection = {
  __typename?: 'EstimationRequestAssigneeContactConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestAssigneeContactEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestAssigneeContactEdge = {
  __typename?: 'EstimationRequestAssigneeContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestAssigneeContact>;
};

/** Ordering options for EstimationRequestAssigneeContact connections */
export type EstimationRequestAssigneeContactOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestAssigneeContacts. */
  field: EstimationRequestAssigneeContactOrderField;
};

/** Properties by which EstimationRequestAssigneeContact connections can be ordered. */
export enum EstimationRequestAssigneeContactOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationRequestAssigneeContactWhereInput is used for filtering EstimationRequestAssigneeContact objects.
 * Input was generated by ent.
 */
export type EstimationRequestAssigneeContactWhereInput = {
  and?: InputMaybe<Array<EstimationRequestAssigneeContactWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationRequestAssigneeContactWhereInput>;
  or?: InputMaybe<Array<EstimationRequestAssigneeContactWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** An edge in a connection. */
export type EstimationRequestAssigneeEdge = {
  __typename?: 'EstimationRequestAssigneeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestAssignee>;
};

/** Ordering options for EstimationRequestAssignee connections */
export type EstimationRequestAssigneeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestAssignees. */
  field: EstimationRequestAssigneeOrderField;
};

/** Properties by which EstimationRequestAssignee connections can be ordered. */
export enum EstimationRequestAssigneeOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationRequestAssigneeWhereInput is used for filtering EstimationRequestAssignee objects.
 * Input was generated by ent.
 */
export type EstimationRequestAssigneeWhereInput = {
  and?: InputMaybe<Array<EstimationRequestAssigneeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** estimation_request_id field predicates */
  estimationRequestID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** estimation_request edge predicates */
  hasEstimationRequest?: InputMaybe<Scalars['Boolean']['input']>;
  /** estimation_request_assignee_contacts edge predicates */
  hasEstimationRequestAssigneeContacts?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestAssigneeContactsWith?: InputMaybe<Array<EstimationRequestAssigneeContactWhereInput>>;
  hasEstimationRequestWith?: InputMaybe<Array<EstimationRequestWhereInput>>;
  /** responses edge predicates */
  hasResponses?: InputMaybe<Scalars['Boolean']['input']>;
  hasResponsesWith?: InputMaybe<Array<EstimationResponseWhereInput>>;
  /** supplier edge predicates */
  hasSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  /** supplier_contacts edge predicates */
  hasSupplierContacts?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierContactsWith?: InputMaybe<Array<SupplierContactWhereInput>>;
  hasSupplierWith?: InputMaybe<Array<SupplierWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** mail_subject field predicates */
  mailSubject?: InputMaybe<Scalars['String']['input']>;
  mailSubjectContains?: InputMaybe<Scalars['String']['input']>;
  mailSubjectContainsFold?: InputMaybe<Scalars['String']['input']>;
  mailSubjectEqualFold?: InputMaybe<Scalars['String']['input']>;
  mailSubjectGT?: InputMaybe<Scalars['String']['input']>;
  mailSubjectGTE?: InputMaybe<Scalars['String']['input']>;
  mailSubjectHasPrefix?: InputMaybe<Scalars['String']['input']>;
  mailSubjectHasSuffix?: InputMaybe<Scalars['String']['input']>;
  mailSubjectIn?: InputMaybe<Array<Scalars['String']['input']>>;
  mailSubjectLT?: InputMaybe<Scalars['String']['input']>;
  mailSubjectLTE?: InputMaybe<Scalars['String']['input']>;
  mailSubjectNEQ?: InputMaybe<Scalars['String']['input']>;
  mailSubjectNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationRequestAssigneeWhereInput>;
  or?: InputMaybe<Array<EstimationRequestAssigneeWhereInput>>;
  /** supplier_id field predicates */
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** text_mail_body field predicates */
  textMailBody?: InputMaybe<Scalars['String']['input']>;
  textMailBodyContains?: InputMaybe<Scalars['String']['input']>;
  textMailBodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  textMailBodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  textMailBodyGT?: InputMaybe<Scalars['String']['input']>;
  textMailBodyGTE?: InputMaybe<Scalars['String']['input']>;
  textMailBodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  textMailBodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  textMailBodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  textMailBodyLT?: InputMaybe<Scalars['String']['input']>;
  textMailBodyLTE?: InputMaybe<Scalars['String']['input']>;
  textMailBodyNEQ?: InputMaybe<Scalars['String']['input']>;
  textMailBodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** token field predicates */
  token?: InputMaybe<Scalars['String']['input']>;
  tokenContains?: InputMaybe<Scalars['String']['input']>;
  tokenContainsFold?: InputMaybe<Scalars['String']['input']>;
  tokenEqualFold?: InputMaybe<Scalars['String']['input']>;
  tokenGT?: InputMaybe<Scalars['String']['input']>;
  tokenGTE?: InputMaybe<Scalars['String']['input']>;
  tokenHasPrefix?: InputMaybe<Scalars['String']['input']>;
  tokenHasSuffix?: InputMaybe<Scalars['String']['input']>;
  tokenIn?: InputMaybe<Array<Scalars['String']['input']>>;
  tokenLT?: InputMaybe<Scalars['String']['input']>;
  tokenLTE?: InputMaybe<Scalars['String']['input']>;
  tokenNEQ?: InputMaybe<Scalars['String']['input']>;
  tokenNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type EstimationRequestAttachment = Node & {
  __typename?: 'EstimationRequestAttachment';
  byteSize: Scalars['Uint64']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  estimationRequest: EstimationRequest;
  estimationRequestID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestAttachmentConnection = {
  __typename?: 'EstimationRequestAttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestAttachmentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestAttachmentEdge = {
  __typename?: 'EstimationRequestAttachmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestAttachment>;
};

/** Ordering options for EstimationRequestAttachment connections */
export type EstimationRequestAttachmentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestAttachments. */
  field: EstimationRequestAttachmentOrderField;
};

/** Properties by which EstimationRequestAttachment connections can be ordered. */
export enum EstimationRequestAttachmentOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type EstimationRequestAttachmentUrl = {
  __typename?: 'EstimationRequestAttachmentURL';
  url: Scalars['String']['output'];
};

/**
 * EstimationRequestAttachmentWhereInput is used for filtering EstimationRequestAttachment objects.
 * Input was generated by ent.
 */
export type EstimationRequestAttachmentWhereInput = {
  and?: InputMaybe<Array<EstimationRequestAttachmentWhereInput>>;
  /** byte_size field predicates */
  byteSize?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeGT?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeGTE?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  byteSizeLT?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeLTE?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeNEQ?: InputMaybe<Scalars['Uint64']['input']>;
  byteSizeNotIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  /** content_type field predicates */
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentTypeContains?: InputMaybe<Scalars['String']['input']>;
  contentTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  contentTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  contentTypeGT?: InputMaybe<Scalars['String']['input']>;
  contentTypeGTE?: InputMaybe<Scalars['String']['input']>;
  contentTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  contentTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  contentTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contentTypeLT?: InputMaybe<Scalars['String']['input']>;
  contentTypeLTE?: InputMaybe<Scalars['String']['input']>;
  contentTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  contentTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_id field predicates */
  estimationRequestID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** estimation_request edge predicates */
  hasEstimationRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestWith?: InputMaybe<Array<EstimationRequestWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationRequestAttachmentWhereInput>;
  or?: InputMaybe<Array<EstimationRequestAttachmentWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type EstimationRequestConnection = {
  __typename?: 'EstimationRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type EstimationRequestDetail = Node & {
  __typename?: 'EstimationRequestDetail';
  createdAt: Scalars['Time']['output'];
  estimationRequest: EstimationRequest;
  estimationRequestID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item?: Maybe<EstimationRequestItem>;
  orderNumber: Scalars['Uint32']['output'];
  type: EstimationRequestDetailType;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestDetailConnection = {
  __typename?: 'EstimationRequestDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestDetailEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestDetailEdge = {
  __typename?: 'EstimationRequestDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestDetail>;
};

/** Ordering options for EstimationRequestDetail connections */
export type EstimationRequestDetailOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestDetails. */
  field: EstimationRequestDetailOrderField;
};

/** Properties by which EstimationRequestDetail connections can be ordered. */
export enum EstimationRequestDetailOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  OrderNumber = 'ORDER_NUMBER',
  UpdatedAt = 'UPDATED_AT'
}

/** EstimationRequestDetailType is enum for the field type */
export enum EstimationRequestDetailType {
  Item = 'item'
}

/**
 * EstimationRequestDetailWhereInput is used for filtering EstimationRequestDetail objects.
 * Input was generated by ent.
 */
export type EstimationRequestDetailWhereInput = {
  and?: InputMaybe<Array<EstimationRequestDetailWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_id field predicates */
  estimationRequestID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** estimation_request edge predicates */
  hasEstimationRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestWith?: InputMaybe<Array<EstimationRequestWhereInput>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationRequestDetailWhereInput>;
  or?: InputMaybe<Array<EstimationRequestDetailWhereInput>>;
  /** order_number field predicates */
  orderNumber?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  orderNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** type field predicates */
  type?: InputMaybe<EstimationRequestDetailType>;
  typeIn?: InputMaybe<Array<EstimationRequestDetailType>>;
  typeNEQ?: InputMaybe<EstimationRequestDetailType>;
  typeNotIn?: InputMaybe<Array<EstimationRequestDetailType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** An edge in a connection. */
export type EstimationRequestEdge = {
  __typename?: 'EstimationRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequest>;
};

export type EstimationRequestExportData = {
  __typename?: 'EstimationRequestExportData';
  url: Scalars['String']['output'];
};

export type EstimationRequestInternalAssignee = Node & {
  __typename?: 'EstimationRequestInternalAssignee';
  estimationRequest: EstimationRequest;
  estimationRequestID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  user: User;
  userID: Scalars['ID']['output'];
};

/** Ordering options for EstimationRequestInternalAssignee connections */
export type EstimationRequestInternalAssigneeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestInternalAssignees. */
  field: EstimationRequestInternalAssigneeOrderField;
};

/** Properties by which EstimationRequestInternalAssignee connections can be ordered. */
export enum EstimationRequestInternalAssigneeOrderField {
  Id = 'ID'
}

/**
 * EstimationRequestInternalAssigneeWhereInput is used for filtering EstimationRequestInternalAssignee objects.
 * Input was generated by ent.
 */
export type EstimationRequestInternalAssigneeWhereInput = {
  and?: InputMaybe<Array<EstimationRequestInternalAssigneeWhereInput>>;
  /** estimation_request_id field predicates */
  estimationRequestID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** estimation_request edge predicates */
  hasEstimationRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestWith?: InputMaybe<Array<EstimationRequestWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationRequestInternalAssigneeWhereInput>;
  or?: InputMaybe<Array<EstimationRequestInternalAssigneeWhereInput>>;
  /** user_id field predicates */
  userID?: InputMaybe<Scalars['ID']['input']>;
  userIDContains?: InputMaybe<Scalars['ID']['input']>;
  userIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  userIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  userIDGT?: InputMaybe<Scalars['ID']['input']>;
  userIDGTE?: InputMaybe<Scalars['ID']['input']>;
  userIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  userIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  userIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIDLT?: InputMaybe<Scalars['ID']['input']>;
  userIDLTE?: InputMaybe<Scalars['ID']['input']>;
  userIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type EstimationRequestItem = Node & {
  __typename?: 'EstimationRequestItem';
  annualQuantity?: Maybe<Scalars['String']['output']>;
  askingUnitPrice?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ItemCategory>;
  createdAt: Scalars['Time']['output'];
  detail: EstimationRequestDetail;
  estimationRequestDetailID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  /** TODO: 2023/06現在、必要となる仕様が確定していないため一旦JSONカラムを採用する。仕様確定したら正規化を行う */
  specJSON: Scalars['Map']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type EstimationRequestItemCardboard = Node & {
  __typename?: 'EstimationRequestItemCardboard';
  createdAt: Scalars['Time']['output'];
  estimationRequestItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationRequestItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 段厚 */
  thickness: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestItemCardboardConnection = {
  __typename?: 'EstimationRequestItemCardboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestItemCardboardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestItemCardboardEdge = {
  __typename?: 'EstimationRequestItemCardboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestItemCardboard>;
};

/** Ordering options for EstimationRequestItemCardboard connections */
export type EstimationRequestItemCardboardOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestItemCardboards. */
  field: EstimationRequestItemCardboardOrderField;
};

/** Properties by which EstimationRequestItemCardboard connections can be ordered. */
export enum EstimationRequestItemCardboardOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationRequestItemCardboardWhereInput is used for filtering EstimationRequestItemCardboard objects.
 * Input was generated by ent.
 */
export type EstimationRequestItemCardboardWhereInput = {
  and?: InputMaybe<Array<EstimationRequestItemCardboardWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_item_id field predicates */
  estimationRequestItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationRequestItemCardboardWhereInput>;
  or?: InputMaybe<Array<EstimationRequestItemCardboardWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** thickness field predicates */
  thickness?: InputMaybe<Scalars['String']['input']>;
  thicknessContains?: InputMaybe<Scalars['String']['input']>;
  thicknessContainsFold?: InputMaybe<Scalars['String']['input']>;
  thicknessEqualFold?: InputMaybe<Scalars['String']['input']>;
  thicknessGT?: InputMaybe<Scalars['String']['input']>;
  thicknessGTE?: InputMaybe<Scalars['String']['input']>;
  thicknessHasPrefix?: InputMaybe<Scalars['String']['input']>;
  thicknessHasSuffix?: InputMaybe<Scalars['String']['input']>;
  thicknessIn?: InputMaybe<Array<Scalars['String']['input']>>;
  thicknessLT?: InputMaybe<Scalars['String']['input']>;
  thicknessLTE?: InputMaybe<Scalars['String']['input']>;
  thicknessNEQ?: InputMaybe<Scalars['String']['input']>;
  thicknessNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type EstimationRequestItemConnection = {
  __typename?: 'EstimationRequestItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestItemEdge = {
  __typename?: 'EstimationRequestItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestItem>;
};

export type EstimationRequestItemFlexiblePackage = Node & {
  __typename?: 'EstimationRequestItemFlexiblePackage';
  createdAt: Scalars['Time']['output'];
  estimationRequestItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationRequestItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestItemFlexiblePackageConnection = {
  __typename?: 'EstimationRequestItemFlexiblePackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestItemFlexiblePackageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestItemFlexiblePackageEdge = {
  __typename?: 'EstimationRequestItemFlexiblePackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestItemFlexiblePackage>;
};

/** Ordering options for EstimationRequestItemFlexiblePackage connections */
export type EstimationRequestItemFlexiblePackageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestItemFlexiblePackages. */
  field: EstimationRequestItemFlexiblePackageOrderField;
};

/** Properties by which EstimationRequestItemFlexiblePackage connections can be ordered. */
export enum EstimationRequestItemFlexiblePackageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationRequestItemFlexiblePackageWhereInput is used for filtering EstimationRequestItemFlexiblePackage objects.
 * Input was generated by ent.
 */
export type EstimationRequestItemFlexiblePackageWhereInput = {
  and?: InputMaybe<Array<EstimationRequestItemFlexiblePackageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_item_id field predicates */
  estimationRequestItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationRequestItemFlexiblePackageWhereInput>;
  or?: InputMaybe<Array<EstimationRequestItemFlexiblePackageWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationRequestItemGiftBox = Node & {
  __typename?: 'EstimationRequestItemGiftBox';
  createdAt: Scalars['Time']['output'];
  estimationRequestItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationRequestItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestItemGiftBoxConnection = {
  __typename?: 'EstimationRequestItemGiftBoxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestItemGiftBoxEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestItemGiftBoxEdge = {
  __typename?: 'EstimationRequestItemGiftBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestItemGiftBox>;
};

/** Ordering options for EstimationRequestItemGiftBox connections */
export type EstimationRequestItemGiftBoxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestItemGiftBoxes. */
  field: EstimationRequestItemGiftBoxOrderField;
};

/** Properties by which EstimationRequestItemGiftBox connections can be ordered. */
export enum EstimationRequestItemGiftBoxOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationRequestItemGiftBoxWhereInput is used for filtering EstimationRequestItemGiftBox objects.
 * Input was generated by ent.
 */
export type EstimationRequestItemGiftBoxWhereInput = {
  and?: InputMaybe<Array<EstimationRequestItemGiftBoxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_item_id field predicates */
  estimationRequestItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationRequestItemGiftBoxWhereInput>;
  or?: InputMaybe<Array<EstimationRequestItemGiftBoxWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for EstimationRequestItem connections */
export type EstimationRequestItemOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestItems. */
  field: EstimationRequestItemOrderField;
};

/** Properties by which EstimationRequestItem connections can be ordered. */
export enum EstimationRequestItemOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type EstimationRequestItemOther = Node & {
  __typename?: 'EstimationRequestItemOther';
  createdAt: Scalars['Time']['output'];
  estimationRequestItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationRequestItem;
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestItemOtherConnection = {
  __typename?: 'EstimationRequestItemOtherConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestItemOtherEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestItemOtherEdge = {
  __typename?: 'EstimationRequestItemOtherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestItemOther>;
};

/** Ordering options for EstimationRequestItemOther connections */
export type EstimationRequestItemOtherOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestItemOthers. */
  field: EstimationRequestItemOtherOrderField;
};

/** Properties by which EstimationRequestItemOther connections can be ordered. */
export enum EstimationRequestItemOtherOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationRequestItemOtherWhereInput is used for filtering EstimationRequestItemOther objects.
 * Input was generated by ent.
 */
export type EstimationRequestItemOtherWhereInput = {
  and?: InputMaybe<Array<EstimationRequestItemOtherWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_item_id field predicates */
  estimationRequestItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationRequestItemOtherWhereInput>;
  or?: InputMaybe<Array<EstimationRequestItemOtherWhereInput>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationRequestItemPaperBag = Node & {
  __typename?: 'EstimationRequestItemPaperBag';
  createdAt: Scalars['Time']['output'];
  estimationRequestItemID: Scalars['ID']['output'];
  /** 持ち手 */
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: EstimationRequestItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationRequestItemPaperBagConnection = {
  __typename?: 'EstimationRequestItemPaperBagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationRequestItemPaperBagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationRequestItemPaperBagEdge = {
  __typename?: 'EstimationRequestItemPaperBagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationRequestItemPaperBag>;
};

/** Ordering options for EstimationRequestItemPaperBag connections */
export type EstimationRequestItemPaperBagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequestItemPaperBags. */
  field: EstimationRequestItemPaperBagOrderField;
};

/** Properties by which EstimationRequestItemPaperBag connections can be ordered. */
export enum EstimationRequestItemPaperBagOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationRequestItemPaperBagWhereInput is used for filtering EstimationRequestItemPaperBag objects.
 * Input was generated by ent.
 */
export type EstimationRequestItemPaperBagWhereInput = {
  and?: InputMaybe<Array<EstimationRequestItemPaperBagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_item_id field predicates */
  estimationRequestItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** handle field predicates */
  handle?: InputMaybe<Scalars['String']['input']>;
  handleContains?: InputMaybe<Scalars['String']['input']>;
  handleContainsFold?: InputMaybe<Scalars['String']['input']>;
  handleEqualFold?: InputMaybe<Scalars['String']['input']>;
  handleGT?: InputMaybe<Scalars['String']['input']>;
  handleGTE?: InputMaybe<Scalars['String']['input']>;
  handleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  handleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  handleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  handleLT?: InputMaybe<Scalars['String']['input']>;
  handleLTE?: InputMaybe<Scalars['String']['input']>;
  handleNEQ?: InputMaybe<Scalars['String']['input']>;
  handleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationRequestItemPaperBagWhereInput>;
  or?: InputMaybe<Array<EstimationRequestItemPaperBagWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * EstimationRequestItemWhereInput is used for filtering EstimationRequestItem objects.
 * Input was generated by ent.
 */
export type EstimationRequestItemWhereInput = {
  and?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** annual_quantity field predicates */
  annualQuantity?: InputMaybe<Scalars['String']['input']>;
  annualQuantityContains?: InputMaybe<Scalars['String']['input']>;
  annualQuantityContainsFold?: InputMaybe<Scalars['String']['input']>;
  annualQuantityEqualFold?: InputMaybe<Scalars['String']['input']>;
  annualQuantityGT?: InputMaybe<Scalars['String']['input']>;
  annualQuantityGTE?: InputMaybe<Scalars['String']['input']>;
  annualQuantityHasPrefix?: InputMaybe<Scalars['String']['input']>;
  annualQuantityHasSuffix?: InputMaybe<Scalars['String']['input']>;
  annualQuantityIn?: InputMaybe<Array<Scalars['String']['input']>>;
  annualQuantityIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualQuantityLT?: InputMaybe<Scalars['String']['input']>;
  annualQuantityLTE?: InputMaybe<Scalars['String']['input']>;
  annualQuantityNEQ?: InputMaybe<Scalars['String']['input']>;
  annualQuantityNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  annualQuantityNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asking_unit_price field predicates */
  askingUnitPrice?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceContains?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceContainsFold?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceEqualFold?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceGT?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceGTE?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceHasPrefix?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceHasSuffix?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceIn?: InputMaybe<Array<Scalars['String']['input']>>;
  askingUnitPriceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  askingUnitPriceLT?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceLTE?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceNEQ?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  askingUnitPriceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** category field predicates */
  category?: InputMaybe<ItemCategory>;
  categoryIn?: InputMaybe<Array<ItemCategory>>;
  categoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  categoryNEQ?: InputMaybe<ItemCategory>;
  categoryNotIn?: InputMaybe<Array<ItemCategory>>;
  categoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_request_detail_id field predicates */
  estimationRequestDetailID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** detail edge predicates */
  hasDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailWith?: InputMaybe<Array<EstimationRequestDetailWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationRequestItemWhereInput>;
  or?: InputMaybe<Array<EstimationRequestItemWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for EstimationRequest connections */
export type EstimationRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationRequests. */
  field: EstimationRequestOrderField;
};

/** Properties by which EstimationRequest connections can be ordered. */
export enum EstimationRequestOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type EstimationRequestSupplier = {
  defaultDetailMessage?: InputMaybe<Scalars['String']['input']>;
  footerMessage: Scalars['String']['input'];
  headerMessage: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  toContactIDs: Array<Scalars['ID']['input']>;
};

/**
 * EstimationRequestWhereInput is used for filtering EstimationRequest objects.
 * Input was generated by ent.
 */
export type EstimationRequestWhereInput = {
  and?: InputMaybe<Array<EstimationRequestWhereInput>>;
  /** company_id field predicates */
  companyID?: InputMaybe<Scalars['ID']['input']>;
  companyIDContains?: InputMaybe<Scalars['ID']['input']>;
  companyIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDGT?: InputMaybe<Scalars['ID']['input']>;
  companyIDGTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  companyIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIDLT?: InputMaybe<Scalars['ID']['input']>;
  companyIDLTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  companyIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** company edge predicates */
  hasCompany?: InputMaybe<Scalars['Boolean']['input']>;
  hasCompanyWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** details edge predicates */
  hasDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailsWith?: InputMaybe<Array<EstimationRequestDetailWhereInput>>;
  /** internal_assignees edge predicates */
  hasInternalAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalAssigneesWith?: InputMaybe<Array<EstimationRequestInternalAssigneeWhereInput>>;
  /** request_assignees edge predicates */
  hasRequestAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasRequestAssigneesWith?: InputMaybe<Array<EstimationRequestAssigneeWhereInput>>;
  /** request_attachments edge predicates */
  hasRequestAttachments?: InputMaybe<Scalars['Boolean']['input']>;
  hasRequestAttachmentsWith?: InputMaybe<Array<EstimationRequestAttachmentWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationRequestWhereInput>;
  or?: InputMaybe<Array<EstimationRequestWhereInput>>;
  /** request_title field predicates */
  requestTitle?: InputMaybe<Scalars['String']['input']>;
  requestTitleContains?: InputMaybe<Scalars['String']['input']>;
  requestTitleContainsFold?: InputMaybe<Scalars['String']['input']>;
  requestTitleEqualFold?: InputMaybe<Scalars['String']['input']>;
  requestTitleGT?: InputMaybe<Scalars['String']['input']>;
  requestTitleGTE?: InputMaybe<Scalars['String']['input']>;
  requestTitleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  requestTitleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  requestTitleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  requestTitleLT?: InputMaybe<Scalars['String']['input']>;
  requestTitleLTE?: InputMaybe<Scalars['String']['input']>;
  requestTitleNEQ?: InputMaybe<Scalars['String']['input']>;
  requestTitleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type EstimationResponse = Node & {
  __typename?: 'EstimationResponse';
  assignee: EstimationRequestAssignee;
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  details?: Maybe<Array<EstimationResponseDetail>>;
  estimationRequestAssigneeID: Scalars['ID']['output'];
  estimations?: Maybe<Array<Estimation>>;
  expirationPeriod: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importantNotes: Scalars['String']['output'];
  responseDetailMessage?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationResponseConnection = {
  __typename?: 'EstimationResponseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationResponseEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type EstimationResponseDetail = Node & {
  __typename?: 'EstimationResponseDetail';
  createdAt: Scalars['Time']['output'];
  estimationResponse: EstimationResponse;
  estimationResponseID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item?: Maybe<EstimationResponseItem>;
  orderNumber: Scalars['Uint32']['output'];
  type: EstimationResponseDetailType;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationResponseDetailConnection = {
  __typename?: 'EstimationResponseDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationResponseDetailEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationResponseDetailEdge = {
  __typename?: 'EstimationResponseDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationResponseDetail>;
};

/** Ordering options for EstimationResponseDetail connections */
export type EstimationResponseDetailOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponseDetails. */
  field: EstimationResponseDetailOrderField;
};

/** Properties by which EstimationResponseDetail connections can be ordered. */
export enum EstimationResponseDetailOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  OrderNumber = 'ORDER_NUMBER',
  UpdatedAt = 'UPDATED_AT'
}

/** EstimationResponseDetailType is enum for the field type */
export enum EstimationResponseDetailType {
  Item = 'item'
}

/**
 * EstimationResponseDetailWhereInput is used for filtering EstimationResponseDetail objects.
 * Input was generated by ent.
 */
export type EstimationResponseDetailWhereInput = {
  and?: InputMaybe<Array<EstimationResponseDetailWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_response_id field predicates */
  estimationResponseID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** estimation_response edge predicates */
  hasEstimationResponse?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationResponseWith?: InputMaybe<Array<EstimationResponseWhereInput>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationResponseDetailWhereInput>;
  or?: InputMaybe<Array<EstimationResponseDetailWhereInput>>;
  /** order_number field predicates */
  orderNumber?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  orderNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** type field predicates */
  type?: InputMaybe<EstimationResponseDetailType>;
  typeIn?: InputMaybe<Array<EstimationResponseDetailType>>;
  typeNEQ?: InputMaybe<EstimationResponseDetailType>;
  typeNotIn?: InputMaybe<Array<EstimationResponseDetailType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** An edge in a connection. */
export type EstimationResponseEdge = {
  __typename?: 'EstimationResponseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationResponse>;
};

export type EstimationResponseItem = Node & {
  __typename?: 'EstimationResponseItem';
  annualQuantity?: Maybe<Scalars['String']['output']>;
  askingUnitPrice?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ItemCategory>;
  createdAt: Scalars['Time']['output'];
  detail: EstimationResponseDetail;
  estimationResponseDetailID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  /** TODO: 2023/06現在、必要となる仕様が確定していないため一旦JSONカラムを採用する。仕様確定したら正規化を行う */
  specJSON: Scalars['Map']['output'];
  unitPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type EstimationResponseItemCardboard = Node & {
  __typename?: 'EstimationResponseItemCardboard';
  createdAt: Scalars['Time']['output'];
  estimationResponseItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationResponseItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 段厚 */
  thickness: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationResponseItemCardboardConnection = {
  __typename?: 'EstimationResponseItemCardboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationResponseItemCardboardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationResponseItemCardboardEdge = {
  __typename?: 'EstimationResponseItemCardboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationResponseItemCardboard>;
};

/** Ordering options for EstimationResponseItemCardboard connections */
export type EstimationResponseItemCardboardOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponseItemCardboards. */
  field: EstimationResponseItemCardboardOrderField;
};

/** Properties by which EstimationResponseItemCardboard connections can be ordered. */
export enum EstimationResponseItemCardboardOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationResponseItemCardboardWhereInput is used for filtering EstimationResponseItemCardboard objects.
 * Input was generated by ent.
 */
export type EstimationResponseItemCardboardWhereInput = {
  and?: InputMaybe<Array<EstimationResponseItemCardboardWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_response_item_id field predicates */
  estimationResponseItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationResponseItemCardboardWhereInput>;
  or?: InputMaybe<Array<EstimationResponseItemCardboardWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** thickness field predicates */
  thickness?: InputMaybe<Scalars['String']['input']>;
  thicknessContains?: InputMaybe<Scalars['String']['input']>;
  thicknessContainsFold?: InputMaybe<Scalars['String']['input']>;
  thicknessEqualFold?: InputMaybe<Scalars['String']['input']>;
  thicknessGT?: InputMaybe<Scalars['String']['input']>;
  thicknessGTE?: InputMaybe<Scalars['String']['input']>;
  thicknessHasPrefix?: InputMaybe<Scalars['String']['input']>;
  thicknessHasSuffix?: InputMaybe<Scalars['String']['input']>;
  thicknessIn?: InputMaybe<Array<Scalars['String']['input']>>;
  thicknessLT?: InputMaybe<Scalars['String']['input']>;
  thicknessLTE?: InputMaybe<Scalars['String']['input']>;
  thicknessNEQ?: InputMaybe<Scalars['String']['input']>;
  thicknessNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationResponseItemFlexiblePackage = Node & {
  __typename?: 'EstimationResponseItemFlexiblePackage';
  createdAt: Scalars['Time']['output'];
  estimationResponseItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationResponseItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationResponseItemFlexiblePackageConnection = {
  __typename?: 'EstimationResponseItemFlexiblePackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationResponseItemFlexiblePackageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationResponseItemFlexiblePackageEdge = {
  __typename?: 'EstimationResponseItemFlexiblePackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationResponseItemFlexiblePackage>;
};

/** Ordering options for EstimationResponseItemFlexiblePackage connections */
export type EstimationResponseItemFlexiblePackageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponseItemFlexiblePackages. */
  field: EstimationResponseItemFlexiblePackageOrderField;
};

/** Properties by which EstimationResponseItemFlexiblePackage connections can be ordered. */
export enum EstimationResponseItemFlexiblePackageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationResponseItemFlexiblePackageWhereInput is used for filtering EstimationResponseItemFlexiblePackage objects.
 * Input was generated by ent.
 */
export type EstimationResponseItemFlexiblePackageWhereInput = {
  and?: InputMaybe<Array<EstimationResponseItemFlexiblePackageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_response_item_id field predicates */
  estimationResponseItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationResponseItemFlexiblePackageWhereInput>;
  or?: InputMaybe<Array<EstimationResponseItemFlexiblePackageWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationResponseItemGiftBox = Node & {
  __typename?: 'EstimationResponseItemGiftBox';
  createdAt: Scalars['Time']['output'];
  estimationResponseItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationResponseItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationResponseItemGiftBoxConnection = {
  __typename?: 'EstimationResponseItemGiftBoxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationResponseItemGiftBoxEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationResponseItemGiftBoxEdge = {
  __typename?: 'EstimationResponseItemGiftBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationResponseItemGiftBox>;
};

/** Ordering options for EstimationResponseItemGiftBox connections */
export type EstimationResponseItemGiftBoxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponseItemGiftBoxes. */
  field: EstimationResponseItemGiftBoxOrderField;
};

/** Properties by which EstimationResponseItemGiftBox connections can be ordered. */
export enum EstimationResponseItemGiftBoxOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationResponseItemGiftBoxWhereInput is used for filtering EstimationResponseItemGiftBox objects.
 * Input was generated by ent.
 */
export type EstimationResponseItemGiftBoxWhereInput = {
  and?: InputMaybe<Array<EstimationResponseItemGiftBoxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_response_item_id field predicates */
  estimationResponseItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationResponseItemGiftBoxWhereInput>;
  or?: InputMaybe<Array<EstimationResponseItemGiftBoxWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for EstimationResponseItem connections */
export type EstimationResponseItemOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponseItems. */
  field: EstimationResponseItemOrderField;
};

/** Properties by which EstimationResponseItem connections can be ordered. */
export enum EstimationResponseItemOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type EstimationResponseItemOther = Node & {
  __typename?: 'EstimationResponseItemOther';
  createdAt: Scalars['Time']['output'];
  estimationResponseItemID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  item: EstimationResponseItem;
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationResponseItemOtherConnection = {
  __typename?: 'EstimationResponseItemOtherConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationResponseItemOtherEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationResponseItemOtherEdge = {
  __typename?: 'EstimationResponseItemOtherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationResponseItemOther>;
};

/** Ordering options for EstimationResponseItemOther connections */
export type EstimationResponseItemOtherOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponseItemOthers. */
  field: EstimationResponseItemOtherOrderField;
};

/** Properties by which EstimationResponseItemOther connections can be ordered. */
export enum EstimationResponseItemOtherOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationResponseItemOtherWhereInput is used for filtering EstimationResponseItemOther objects.
 * Input was generated by ent.
 */
export type EstimationResponseItemOtherWhereInput = {
  and?: InputMaybe<Array<EstimationResponseItemOtherWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_response_item_id field predicates */
  estimationResponseItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationResponseItemOtherWhereInput>;
  or?: InputMaybe<Array<EstimationResponseItemOtherWhereInput>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationResponseItemPaperBag = Node & {
  __typename?: 'EstimationResponseItemPaperBag';
  createdAt: Scalars['Time']['output'];
  estimationResponseItemID: Scalars['ID']['output'];
  /** 持ち手 */
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: EstimationResponseItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationResponseItemPaperBagConnection = {
  __typename?: 'EstimationResponseItemPaperBagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationResponseItemPaperBagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationResponseItemPaperBagEdge = {
  __typename?: 'EstimationResponseItemPaperBagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationResponseItemPaperBag>;
};

/** Ordering options for EstimationResponseItemPaperBag connections */
export type EstimationResponseItemPaperBagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponseItemPaperBags. */
  field: EstimationResponseItemPaperBagOrderField;
};

/** Properties by which EstimationResponseItemPaperBag connections can be ordered. */
export enum EstimationResponseItemPaperBagOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationResponseItemPaperBagWhereInput is used for filtering EstimationResponseItemPaperBag objects.
 * Input was generated by ent.
 */
export type EstimationResponseItemPaperBagWhereInput = {
  and?: InputMaybe<Array<EstimationResponseItemPaperBagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_response_item_id field predicates */
  estimationResponseItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** handle field predicates */
  handle?: InputMaybe<Scalars['String']['input']>;
  handleContains?: InputMaybe<Scalars['String']['input']>;
  handleContainsFold?: InputMaybe<Scalars['String']['input']>;
  handleEqualFold?: InputMaybe<Scalars['String']['input']>;
  handleGT?: InputMaybe<Scalars['String']['input']>;
  handleGTE?: InputMaybe<Scalars['String']['input']>;
  handleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  handleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  handleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  handleLT?: InputMaybe<Scalars['String']['input']>;
  handleLTE?: InputMaybe<Scalars['String']['input']>;
  handleNEQ?: InputMaybe<Scalars['String']['input']>;
  handleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationResponseItemPaperBagWhereInput>;
  or?: InputMaybe<Array<EstimationResponseItemPaperBagWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * EstimationResponseItemWhereInput is used for filtering EstimationResponseItem objects.
 * Input was generated by ent.
 */
export type EstimationResponseItemWhereInput = {
  and?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** annual_quantity field predicates */
  annualQuantity?: InputMaybe<Scalars['String']['input']>;
  annualQuantityContains?: InputMaybe<Scalars['String']['input']>;
  annualQuantityContainsFold?: InputMaybe<Scalars['String']['input']>;
  annualQuantityEqualFold?: InputMaybe<Scalars['String']['input']>;
  annualQuantityGT?: InputMaybe<Scalars['String']['input']>;
  annualQuantityGTE?: InputMaybe<Scalars['String']['input']>;
  annualQuantityHasPrefix?: InputMaybe<Scalars['String']['input']>;
  annualQuantityHasSuffix?: InputMaybe<Scalars['String']['input']>;
  annualQuantityIn?: InputMaybe<Array<Scalars['String']['input']>>;
  annualQuantityIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualQuantityLT?: InputMaybe<Scalars['String']['input']>;
  annualQuantityLTE?: InputMaybe<Scalars['String']['input']>;
  annualQuantityNEQ?: InputMaybe<Scalars['String']['input']>;
  annualQuantityNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  annualQuantityNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asking_unit_price field predicates */
  askingUnitPrice?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceContains?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceContainsFold?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceEqualFold?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceGT?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceGTE?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceHasPrefix?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceHasSuffix?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceIn?: InputMaybe<Array<Scalars['String']['input']>>;
  askingUnitPriceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  askingUnitPriceLT?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceLTE?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceNEQ?: InputMaybe<Scalars['String']['input']>;
  askingUnitPriceNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  askingUnitPriceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** category field predicates */
  category?: InputMaybe<ItemCategory>;
  categoryIn?: InputMaybe<Array<ItemCategory>>;
  categoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  categoryNEQ?: InputMaybe<ItemCategory>;
  categoryNotIn?: InputMaybe<Array<ItemCategory>>;
  categoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_response_detail_id field predicates */
  estimationResponseDetailID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** detail edge predicates */
  hasDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailWith?: InputMaybe<Array<EstimationResponseDetailWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationResponseItemWhereInput>;
  or?: InputMaybe<Array<EstimationResponseItemWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for EstimationResponse connections */
export type EstimationResponseOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationResponses. */
  field: EstimationResponseOrderField;
};

/** Properties by which EstimationResponse connections can be ordered. */
export enum EstimationResponseOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationResponseWhereInput is used for filtering EstimationResponse objects.
 * Input was generated by ent.
 */
export type EstimationResponseWhereInput = {
  and?: InputMaybe<Array<EstimationResponseWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** estimation_request_assignee_id field predicates */
  estimationRequestAssigneeID?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationRequestAssigneeIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationRequestAssigneeIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** expiration_period field predicates */
  expirationPeriod?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodContains?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodContainsFold?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodEqualFold?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodGT?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodGTE?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodHasPrefix?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodHasSuffix?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodIn?: InputMaybe<Array<Scalars['String']['input']>>;
  expirationPeriodLT?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodLTE?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodNEQ?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** assignee edge predicates */
  hasAssignee?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssigneeWith?: InputMaybe<Array<EstimationRequestAssigneeWhereInput>>;
  /** details edge predicates */
  hasDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailsWith?: InputMaybe<Array<EstimationResponseDetailWhereInput>>;
  /** estimations edge predicates */
  hasEstimations?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationsWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** important_notes field predicates */
  importantNotes?: InputMaybe<Scalars['String']['input']>;
  importantNotesContains?: InputMaybe<Scalars['String']['input']>;
  importantNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  importantNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  importantNotesGT?: InputMaybe<Scalars['String']['input']>;
  importantNotesGTE?: InputMaybe<Scalars['String']['input']>;
  importantNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  importantNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  importantNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  importantNotesLT?: InputMaybe<Scalars['String']['input']>;
  importantNotesLTE?: InputMaybe<Scalars['String']['input']>;
  importantNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  importantNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<EstimationResponseWhereInput>;
  or?: InputMaybe<Array<EstimationResponseWhereInput>>;
  /** response_detail_message field predicates */
  responseDetailMessage?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageContains?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageContainsFold?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageEqualFold?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageGT?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageGTE?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  responseDetailMessageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  responseDetailMessageLT?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageLTE?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageNEQ?: InputMaybe<Scalars['String']['input']>;
  responseDetailMessageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  responseDetailMessageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationText = Node & {
  __typename?: 'EstimationText';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  estimationDetail: EstimationDetail;
  estimationDetailID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type EstimationTextConnection = {
  __typename?: 'EstimationTextConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstimationTextEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimationTextEdge = {
  __typename?: 'EstimationTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimationText>;
};

/** Ordering options for EstimationText connections */
export type EstimationTextOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstimationTexts. */
  field: EstimationTextOrderField;
};

/** Properties by which EstimationText connections can be ordered. */
export enum EstimationTextOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * EstimationTextWhereInput is used for filtering EstimationText objects.
 * Input was generated by ent.
 */
export type EstimationTextWhereInput = {
  and?: InputMaybe<Array<EstimationTextWhereInput>>;
  /** body field predicates */
  body?: InputMaybe<Scalars['String']['input']>;
  bodyContains?: InputMaybe<Scalars['String']['input']>;
  bodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  bodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  bodyGT?: InputMaybe<Scalars['String']['input']>;
  bodyGTE?: InputMaybe<Scalars['String']['input']>;
  bodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyLT?: InputMaybe<Scalars['String']['input']>;
  bodyLTE?: InputMaybe<Scalars['String']['input']>;
  bodyNEQ?: InputMaybe<Scalars['String']['input']>;
  bodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** estimation_detail_id field predicates */
  estimationDetailID?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** estimation_detail edge predicates */
  hasEstimationDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationDetailWith?: InputMaybe<Array<EstimationDetailWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationTextWhereInput>;
  or?: InputMaybe<Array<EstimationTextWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstimationUpdateInput = {
  companyID: Scalars['ID']['input'];
  detailMessage: Scalars['String']['input'];
  expirationPeriod: Scalars['String']['input'];
  internalAssignees?: InputMaybe<Array<EstimationInternalAssigneeInput>>;
  items: Array<EstimationDetailInput>;
  showAmountSummary: Scalars['Boolean']['input'];
  supplierID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/**
 * EstimationWhereInput is used for filtering Estimation objects.
 * Input was generated by ent.
 */
export type EstimationWhereInput = {
  and?: InputMaybe<Array<EstimationWhereInput>>;
  /** company_id field predicates */
  companyID?: InputMaybe<Scalars['ID']['input']>;
  companyIDContains?: InputMaybe<Scalars['ID']['input']>;
  companyIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDGT?: InputMaybe<Scalars['ID']['input']>;
  companyIDGTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  companyIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIDLT?: InputMaybe<Scalars['ID']['input']>;
  companyIDLTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  companyIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** detail_message field predicates */
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  detailMessageContains?: InputMaybe<Scalars['String']['input']>;
  detailMessageContainsFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageEqualFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageGT?: InputMaybe<Scalars['String']['input']>;
  detailMessageGTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  detailMessageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  detailMessageLT?: InputMaybe<Scalars['String']['input']>;
  detailMessageLTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageNEQ?: InputMaybe<Scalars['String']['input']>;
  detailMessageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** estimation_response_id field predicates */
  estimationResponseID?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  estimationResponseIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationResponseIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationResponseIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expiration_period field predicates */
  expirationPeriod?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodContains?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodContainsFold?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodEqualFold?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodGT?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodGTE?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodHasPrefix?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodHasSuffix?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodIn?: InputMaybe<Array<Scalars['String']['input']>>;
  expirationPeriodLT?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodLTE?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodNEQ?: InputMaybe<Scalars['String']['input']>;
  expirationPeriodNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** company edge predicates */
  hasCompany?: InputMaybe<Scalars['Boolean']['input']>;
  hasCompanyWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** details edge predicates */
  hasDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailsWith?: InputMaybe<Array<EstimationDetailWhereInput>>;
  /** estimation_response edge predicates */
  hasEstimationResponse?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationResponseWith?: InputMaybe<Array<EstimationResponseWhereInput>>;
  /** internal_assignees edge predicates */
  hasInternalAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalAssigneesWith?: InputMaybe<Array<EstimationInternalAssigneeWhereInput>>;
  /** orders edge predicates */
  hasOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrdersWith?: InputMaybe<Array<OrderWhereInput>>;
  /** sales_orders edge predicates */
  hasSalesOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrdersWith?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** supplier edge predicates */
  hasSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierWith?: InputMaybe<Array<SupplierWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstimationWhereInput>;
  or?: InputMaybe<Array<EstimationWhereInput>>;
  /** show_amount_summary field predicates */
  showAmountSummary?: InputMaybe<Scalars['Boolean']['input']>;
  showAmountSummaryNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** supplier_id field predicates */
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FreeeInvoice = Node & {
  __typename?: 'FreeeInvoice';
  /** Freee請求書ID ( 1 〜 2,147,483,647 ) */
  freeeInvoiceID: Scalars['Uint64']['output'];
  id: Scalars['ID']['output'];
  invoice: Invoice;
  invoiceID: Scalars['ID']['output'];
};

/** Ordering options for FreeeInvoice connections */
export type FreeeInvoiceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FreeeInvoices. */
  field: FreeeInvoiceOrderField;
};

/** Properties by which FreeeInvoice connections can be ordered. */
export enum FreeeInvoiceOrderField {
  Id = 'ID'
}

/**
 * FreeeInvoiceWhereInput is used for filtering FreeeInvoice objects.
 * Input was generated by ent.
 */
export type FreeeInvoiceWhereInput = {
  and?: InputMaybe<Array<FreeeInvoiceWhereInput>>;
  /** freee_invoice_id field predicates */
  freeeInvoiceID?: InputMaybe<Scalars['Uint64']['input']>;
  freeeInvoiceIDGT?: InputMaybe<Scalars['Uint64']['input']>;
  freeeInvoiceIDGTE?: InputMaybe<Scalars['Uint64']['input']>;
  freeeInvoiceIDIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  freeeInvoiceIDLT?: InputMaybe<Scalars['Uint64']['input']>;
  freeeInvoiceIDLTE?: InputMaybe<Scalars['Uint64']['input']>;
  freeeInvoiceIDNEQ?: InputMaybe<Scalars['Uint64']['input']>;
  freeeInvoiceIDNotIn?: InputMaybe<Array<Scalars['Uint64']['input']>>;
  /** invoice edge predicates */
  hasInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceWith?: InputMaybe<Array<InvoiceWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_id field predicates */
  invoiceID?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<FreeeInvoiceWhereInput>;
  or?: InputMaybe<Array<FreeeInvoiceWhereInput>>;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  /** 会計計上日 */
  bookedAt: Scalars['Time']['output'];
  company: Company;
  companyID: Scalars['ID']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  detailMessage?: Maybe<Scalars['String']['output']>;
  details: InvoiceDetailConnection;
  estimations?: Maybe<Array<Estimation>>;
  freeeInvoice?: Maybe<FreeeInvoice>;
  id: Scalars['ID']['output'];
  internalMemos?: Maybe<Array<InvoiceInternalMemo>>;
  /** 支払期日 */
  paymentDueAt: Scalars['Time']['output'];
  salesOrders?: Maybe<Array<SalesOrder>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};


export type InvoiceDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceDetailOrder>;
  where?: InputMaybe<InvoiceDetailWhereInput>;
};

/** A connection to a list of items. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceDetail = Node & {
  __typename?: 'InvoiceDetail';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  invoiceDetailsSales: InvoiceDetailSaleConnection;
  invoiceID: Scalars['ID']['output'];
  item?: Maybe<InvoiceItem>;
  onetimeCost?: Maybe<InvoiceOnetimeCost>;
  /** 表示順序 */
  orderNumber: Scalars['Uint32']['output'];
  recurringCost?: Maybe<InvoiceRecurringCost>;
  salesOrderDetail?: Maybe<SalesOrderDetail>;
  salesOrderDetailID?: Maybe<Scalars['ID']['output']>;
  /** item=商品, onetime_cost=単発費用, recurring_cost=継続費用 */
  type: InvoiceDetailType;
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};


export type InvoiceDetailInvoiceDetailsSalesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceDetailSaleOrder>;
  where?: InputMaybe<InvoiceDetailSaleWhereInput>;
};

/** A connection to a list of items. */
export type InvoiceDetailConnection = {
  __typename?: 'InvoiceDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceDetailEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceDetailEdge = {
  __typename?: 'InvoiceDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceDetail>;
};

/** Ordering options for InvoiceDetail connections */
export type InvoiceDetailOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceDetails. */
  field: InvoiceDetailOrderField;
};

/** Properties by which InvoiceDetail connections can be ordered. */
export enum InvoiceDetailOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  OrderNumber = 'ORDER_NUMBER',
  UpdatedAt = 'UPDATED_AT'
}

export type InvoiceDetailSale = Node & {
  __typename?: 'InvoiceDetailSale';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoiceDetail: InvoiceDetail;
  invoiceDetailID: Scalars['ID']['output'];
  /** 有効なレコードかどうか */
  isValid: Scalars['Boolean']['output'];
  /** 取引日 */
  salesDate: Scalars['Time']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type InvoiceDetailSaleConnection = {
  __typename?: 'InvoiceDetailSaleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceDetailSaleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceDetailSaleEdge = {
  __typename?: 'InvoiceDetailSaleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceDetailSale>;
};

/** Ordering options for InvoiceDetailSale connections */
export type InvoiceDetailSaleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceDetailSales. */
  field: InvoiceDetailSaleOrderField;
};

/** Properties by which InvoiceDetailSale connections can be ordered. */
export enum InvoiceDetailSaleOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  SalesDate = 'SALES_DATE',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceDetailSaleWhereInput is used for filtering InvoiceDetailSale objects.
 * Input was generated by ent.
 */
export type InvoiceDetailSaleWhereInput = {
  and?: InputMaybe<Array<InvoiceDetailSaleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** invoice_detail edge predicates */
  hasInvoiceDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceDetailWith?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_detail_id field predicates */
  invoiceDetailID?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_valid field predicates */
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  isValidNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<InvoiceDetailSaleWhereInput>;
  or?: InputMaybe<Array<InvoiceDetailSaleWhereInput>>;
  /** sales_date field predicates */
  salesDate?: InputMaybe<Scalars['Time']['input']>;
  salesDateGT?: InputMaybe<Scalars['Time']['input']>;
  salesDateGTE?: InputMaybe<Scalars['Time']['input']>;
  salesDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  salesDateLT?: InputMaybe<Scalars['Time']['input']>;
  salesDateLTE?: InputMaybe<Scalars['Time']['input']>;
  salesDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  salesDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** InvoiceDetailType is enum for the field type */
export enum InvoiceDetailType {
  Item = 'item',
  OnetimeCost = 'onetime_cost',
  RecurringCost = 'recurring_cost'
}

/**
 * InvoiceDetailWhereInput is used for filtering InvoiceDetail objects.
 * Input was generated by ent.
 */
export type InvoiceDetailWhereInput = {
  and?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** invoice edge predicates */
  hasInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  /** invoice_details_sales edge predicates */
  hasInvoiceDetailsSales?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceDetailsSalesWith?: InputMaybe<Array<InvoiceDetailSaleWhereInput>>;
  hasInvoiceWith?: InputMaybe<Array<InvoiceWhereInput>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** onetime_cost edge predicates */
  hasOnetimeCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasOnetimeCostWith?: InputMaybe<Array<InvoiceOnetimeCostWhereInput>>;
  /** recurring_cost edge predicates */
  hasRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringCostWith?: InputMaybe<Array<InvoiceRecurringCostWhereInput>>;
  /** sales_order_detail edge predicates */
  hasSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderDetailWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_id field predicates */
  invoiceID?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InvoiceDetailWhereInput>;
  or?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** order_number field predicates */
  orderNumber?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  orderNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** sales_order_detail_id field predicates */
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  salesOrderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<InvoiceDetailType>;
  typeIn?: InputMaybe<Array<InvoiceDetailType>>;
  typeNEQ?: InputMaybe<InvoiceDetailType>;
  typeNotIn?: InputMaybe<Array<InvoiceDetailType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export type InvoiceInput = {
  bookedAt: Scalars['Time']['input'];
  demandID: Scalars['ID']['input'];
  detailMessage: Scalars['String']['input'];
  details: Array<CreateInvoiceDetailInput>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  paymentDueAt: Scalars['Time']['input'];
  title: Scalars['String']['input'];
};

export type InvoiceInternalMemo = Node & {
  __typename?: 'InvoiceInternalMemo';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  invoiceID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type InvoiceInternalMemoConnection = {
  __typename?: 'InvoiceInternalMemoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceInternalMemoEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceInternalMemoEdge = {
  __typename?: 'InvoiceInternalMemoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceInternalMemo>;
};

/** Ordering options for InvoiceInternalMemo connections */
export type InvoiceInternalMemoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceInternalMemos. */
  field: InvoiceInternalMemoOrderField;
};

/** Properties by which InvoiceInternalMemo connections can be ordered. */
export enum InvoiceInternalMemoOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceInternalMemoWhereInput is used for filtering InvoiceInternalMemo objects.
 * Input was generated by ent.
 */
export type InvoiceInternalMemoWhereInput = {
  and?: InputMaybe<Array<InvoiceInternalMemoWhereInput>>;
  /** body field predicates */
  body?: InputMaybe<Scalars['String']['input']>;
  bodyContains?: InputMaybe<Scalars['String']['input']>;
  bodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  bodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  bodyGT?: InputMaybe<Scalars['String']['input']>;
  bodyGTE?: InputMaybe<Scalars['String']['input']>;
  bodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyLT?: InputMaybe<Scalars['String']['input']>;
  bodyLTE?: InputMaybe<Scalars['String']['input']>;
  bodyNEQ?: InputMaybe<Scalars['String']['input']>;
  bodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** invoice edge predicates */
  hasInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceWith?: InputMaybe<Array<InvoiceWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_id field predicates */
  invoiceID?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InvoiceInternalMemoWhereInput>;
  or?: InputMaybe<Array<InvoiceInternalMemoWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type InvoiceItem = Node & {
  __typename?: 'InvoiceItem';
  category?: Maybe<ItemCategory>;
  createdAt: Scalars['Time']['output'];
  demandItem?: Maybe<DemandItem>;
  demandItemID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  invoiceDetail: InvoiceDetail;
  invoiceDetailID: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type InvoiceItemCardboard = Node & {
  __typename?: 'InvoiceItemCardboard';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  invoiceItemID: Scalars['ID']['output'];
  item: InvoiceItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 段厚 */
  thickness: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InvoiceItemCardboardConnection = {
  __typename?: 'InvoiceItemCardboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceItemCardboardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceItemCardboardEdge = {
  __typename?: 'InvoiceItemCardboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceItemCardboard>;
};

/** Ordering options for InvoiceItemCardboard connections */
export type InvoiceItemCardboardOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceItemCardboards. */
  field: InvoiceItemCardboardOrderField;
};

/** Properties by which InvoiceItemCardboard connections can be ordered. */
export enum InvoiceItemCardboardOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceItemCardboardWhereInput is used for filtering InvoiceItemCardboard objects.
 * Input was generated by ent.
 */
export type InvoiceItemCardboardWhereInput = {
  and?: InputMaybe<Array<InvoiceItemCardboardWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_item_id field predicates */
  invoiceItemID?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<InvoiceItemCardboardWhereInput>;
  or?: InputMaybe<Array<InvoiceItemCardboardWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** thickness field predicates */
  thickness?: InputMaybe<Scalars['String']['input']>;
  thicknessContains?: InputMaybe<Scalars['String']['input']>;
  thicknessContainsFold?: InputMaybe<Scalars['String']['input']>;
  thicknessEqualFold?: InputMaybe<Scalars['String']['input']>;
  thicknessGT?: InputMaybe<Scalars['String']['input']>;
  thicknessGTE?: InputMaybe<Scalars['String']['input']>;
  thicknessHasPrefix?: InputMaybe<Scalars['String']['input']>;
  thicknessHasSuffix?: InputMaybe<Scalars['String']['input']>;
  thicknessIn?: InputMaybe<Array<Scalars['String']['input']>>;
  thicknessLT?: InputMaybe<Scalars['String']['input']>;
  thicknessLTE?: InputMaybe<Scalars['String']['input']>;
  thicknessNEQ?: InputMaybe<Scalars['String']['input']>;
  thicknessNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type InvoiceItemConnection = {
  __typename?: 'InvoiceItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceItemEdge = {
  __typename?: 'InvoiceItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceItem>;
};

export type InvoiceItemFlexiblePackage = Node & {
  __typename?: 'InvoiceItemFlexiblePackage';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  invoiceItemID: Scalars['ID']['output'];
  item: InvoiceItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InvoiceItemFlexiblePackageConnection = {
  __typename?: 'InvoiceItemFlexiblePackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceItemFlexiblePackageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceItemFlexiblePackageEdge = {
  __typename?: 'InvoiceItemFlexiblePackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceItemFlexiblePackage>;
};

/** Ordering options for InvoiceItemFlexiblePackage connections */
export type InvoiceItemFlexiblePackageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceItemFlexiblePackages. */
  field: InvoiceItemFlexiblePackageOrderField;
};

/** Properties by which InvoiceItemFlexiblePackage connections can be ordered. */
export enum InvoiceItemFlexiblePackageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceItemFlexiblePackageWhereInput is used for filtering InvoiceItemFlexiblePackage objects.
 * Input was generated by ent.
 */
export type InvoiceItemFlexiblePackageWhereInput = {
  and?: InputMaybe<Array<InvoiceItemFlexiblePackageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_item_id field predicates */
  invoiceItemID?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<InvoiceItemFlexiblePackageWhereInput>;
  or?: InputMaybe<Array<InvoiceItemFlexiblePackageWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InvoiceItemGiftBox = Node & {
  __typename?: 'InvoiceItemGiftBox';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  invoiceItemID: Scalars['ID']['output'];
  item: InvoiceItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InvoiceItemGiftBoxConnection = {
  __typename?: 'InvoiceItemGiftBoxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceItemGiftBoxEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceItemGiftBoxEdge = {
  __typename?: 'InvoiceItemGiftBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceItemGiftBox>;
};

/** Ordering options for InvoiceItemGiftBox connections */
export type InvoiceItemGiftBoxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceItemGiftBoxes. */
  field: InvoiceItemGiftBoxOrderField;
};

/** Properties by which InvoiceItemGiftBox connections can be ordered. */
export enum InvoiceItemGiftBoxOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceItemGiftBoxWhereInput is used for filtering InvoiceItemGiftBox objects.
 * Input was generated by ent.
 */
export type InvoiceItemGiftBoxWhereInput = {
  and?: InputMaybe<Array<InvoiceItemGiftBoxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_item_id field predicates */
  invoiceItemID?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InvoiceItemGiftBoxWhereInput>;
  or?: InputMaybe<Array<InvoiceItemGiftBoxWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InvoiceItemInput = {
  name: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  taxCategoryID: Scalars['ID']['input'];
  unitSellingPrice: Scalars['Float']['input'];
};

/** Ordering options for InvoiceItem connections */
export type InvoiceItemOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceItems. */
  field: InvoiceItemOrderField;
};

/** Properties by which InvoiceItem connections can be ordered. */
export enum InvoiceItemOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type InvoiceItemOther = Node & {
  __typename?: 'InvoiceItemOther';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  invoiceItemID: Scalars['ID']['output'];
  item: InvoiceItem;
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InvoiceItemOtherConnection = {
  __typename?: 'InvoiceItemOtherConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceItemOtherEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceItemOtherEdge = {
  __typename?: 'InvoiceItemOtherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceItemOther>;
};

/** Ordering options for InvoiceItemOther connections */
export type InvoiceItemOtherOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceItemOthers. */
  field: InvoiceItemOtherOrderField;
};

/** Properties by which InvoiceItemOther connections can be ordered. */
export enum InvoiceItemOtherOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceItemOtherWhereInput is used for filtering InvoiceItemOther objects.
 * Input was generated by ent.
 */
export type InvoiceItemOtherWhereInput = {
  and?: InputMaybe<Array<InvoiceItemOtherWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_item_id field predicates */
  invoiceItemID?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InvoiceItemOtherWhereInput>;
  or?: InputMaybe<Array<InvoiceItemOtherWhereInput>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InvoiceItemPaperBag = Node & {
  __typename?: 'InvoiceItemPaperBag';
  createdAt: Scalars['Time']['output'];
  /** 持ち手 */
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceItemID: Scalars['ID']['output'];
  item: InvoiceItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InvoiceItemPaperBagConnection = {
  __typename?: 'InvoiceItemPaperBagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceItemPaperBagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceItemPaperBagEdge = {
  __typename?: 'InvoiceItemPaperBagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceItemPaperBag>;
};

/** Ordering options for InvoiceItemPaperBag connections */
export type InvoiceItemPaperBagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceItemPaperBags. */
  field: InvoiceItemPaperBagOrderField;
};

/** Properties by which InvoiceItemPaperBag connections can be ordered. */
export enum InvoiceItemPaperBagOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceItemPaperBagWhereInput is used for filtering InvoiceItemPaperBag objects.
 * Input was generated by ent.
 */
export type InvoiceItemPaperBagWhereInput = {
  and?: InputMaybe<Array<InvoiceItemPaperBagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** handle field predicates */
  handle?: InputMaybe<Scalars['String']['input']>;
  handleContains?: InputMaybe<Scalars['String']['input']>;
  handleContainsFold?: InputMaybe<Scalars['String']['input']>;
  handleEqualFold?: InputMaybe<Scalars['String']['input']>;
  handleGT?: InputMaybe<Scalars['String']['input']>;
  handleGTE?: InputMaybe<Scalars['String']['input']>;
  handleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  handleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  handleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  handleLT?: InputMaybe<Scalars['String']['input']>;
  handleLTE?: InputMaybe<Scalars['String']['input']>;
  handleNEQ?: InputMaybe<Scalars['String']['input']>;
  handleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_item_id field predicates */
  invoiceItemID?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InvoiceItemPaperBagWhereInput>;
  or?: InputMaybe<Array<InvoiceItemPaperBagWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * InvoiceItemWhereInput is used for filtering InvoiceItem objects.
 * Input was generated by ent.
 */
export type InvoiceItemWhereInput = {
  and?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<ItemCategory>;
  categoryIn?: InputMaybe<Array<ItemCategory>>;
  categoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  categoryNEQ?: InputMaybe<ItemCategory>;
  categoryNotIn?: InputMaybe<Array<ItemCategory>>;
  categoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item edge predicates */
  hasDemandItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** invoice_detail edge predicates */
  hasInvoiceDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceDetailWith?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_detail_id field predicates */
  invoiceDetailID?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<InvoiceItemWhereInput>;
  or?: InputMaybe<Array<InvoiceItemWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InvoiceOnetimeCost = Node & {
  __typename?: 'InvoiceOnetimeCost';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  invoiceDetail: InvoiceDetail;
  invoiceDetailID: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InvoiceOnetimeCostConnection = {
  __typename?: 'InvoiceOnetimeCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceOnetimeCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceOnetimeCostEdge = {
  __typename?: 'InvoiceOnetimeCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceOnetimeCost>;
};

/** Ordering options for InvoiceOnetimeCost connections */
export type InvoiceOnetimeCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceOnetimeCosts. */
  field: InvoiceOnetimeCostOrderField;
};

/** Properties by which InvoiceOnetimeCost connections can be ordered. */
export enum InvoiceOnetimeCostOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceOnetimeCostWhereInput is used for filtering InvoiceOnetimeCost objects.
 * Input was generated by ent.
 */
export type InvoiceOnetimeCostWhereInput = {
  and?: InputMaybe<Array<InvoiceOnetimeCostWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** invoice_detail edge predicates */
  hasInvoiceDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceDetailWith?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_detail_id field predicates */
  invoiceDetailID?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<InvoiceOnetimeCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<InvoiceOnetimeCostWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for Invoice connections */
export type InvoiceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Invoices. */
  field: InvoiceOrderField;
};

/** Properties by which Invoice connections can be ordered. */
export enum InvoiceOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type InvoiceRecurringCost = Node & {
  __typename?: 'InvoiceRecurringCost';
  createdAt: Scalars['Time']['output'];
  demandRecurringCost?: Maybe<DemandRecurringCost>;
  /** 費用マスタを削除できるようにするためNULLを許容する */
  demandRecurringCostID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  invoiceDetail: InvoiceDetail;
  invoiceDetailID: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InvoiceRecurringCostConnection = {
  __typename?: 'InvoiceRecurringCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceRecurringCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceRecurringCostEdge = {
  __typename?: 'InvoiceRecurringCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceRecurringCost>;
};

/** Ordering options for InvoiceRecurringCost connections */
export type InvoiceRecurringCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InvoiceRecurringCosts. */
  field: InvoiceRecurringCostOrderField;
};

/** Properties by which InvoiceRecurringCost connections can be ordered. */
export enum InvoiceRecurringCostOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * InvoiceRecurringCostWhereInput is used for filtering InvoiceRecurringCost objects.
 * Input was generated by ent.
 */
export type InvoiceRecurringCostWhereInput = {
  and?: InputMaybe<Array<InvoiceRecurringCostWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_recurring_cost_id field predicates */
  demandRecurringCostID?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandRecurringCostIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_recurring_cost edge predicates */
  hasDemandRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandRecurringCostWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** invoice_detail edge predicates */
  hasInvoiceDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceDetailWith?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invoice_detail_id field predicates */
  invoiceDetailID?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  invoiceDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<InvoiceRecurringCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<InvoiceRecurringCostWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InvoiceUpdateInput = {
  bookedAt: Scalars['Time']['input'];
  detailMessage: Scalars['String']['input'];
  details: Array<UpdateInvoiceDetailInput>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  paymentDueAt: Scalars['Time']['input'];
  title: Scalars['String']['input'];
};

/**
 * InvoiceWhereInput is used for filtering Invoice objects.
 * Input was generated by ent.
 */
export type InvoiceWhereInput = {
  and?: InputMaybe<Array<InvoiceWhereInput>>;
  /** booked_at field predicates */
  bookedAt?: InputMaybe<Scalars['Time']['input']>;
  bookedAtGT?: InputMaybe<Scalars['Time']['input']>;
  bookedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  bookedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  bookedAtLT?: InputMaybe<Scalars['Time']['input']>;
  bookedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  bookedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  bookedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** company_id field predicates */
  companyID?: InputMaybe<Scalars['ID']['input']>;
  companyIDContains?: InputMaybe<Scalars['ID']['input']>;
  companyIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDGT?: InputMaybe<Scalars['ID']['input']>;
  companyIDGTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  companyIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIDLT?: InputMaybe<Scalars['ID']['input']>;
  companyIDLTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  companyIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** detail_message field predicates */
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  detailMessageContains?: InputMaybe<Scalars['String']['input']>;
  detailMessageContainsFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageEqualFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageGT?: InputMaybe<Scalars['String']['input']>;
  detailMessageGTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  detailMessageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  detailMessageLT?: InputMaybe<Scalars['String']['input']>;
  detailMessageLTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageNEQ?: InputMaybe<Scalars['String']['input']>;
  detailMessageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** company edge predicates */
  hasCompany?: InputMaybe<Scalars['Boolean']['input']>;
  hasCompanyWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** details edge predicates */
  hasDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailsWith?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** estimations edge predicates */
  hasEstimations?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationsWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** freee_invoice edge predicates */
  hasFreeeInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  hasFreeeInvoiceWith?: InputMaybe<Array<FreeeInvoiceWhereInput>>;
  /** internal_memos edge predicates */
  hasInternalMemos?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalMemosWith?: InputMaybe<Array<InvoiceInternalMemoWhereInput>>;
  /** sales_orders edge predicates */
  hasSalesOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrdersWith?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InvoiceWhereInput>;
  or?: InputMaybe<Array<InvoiceWhereInput>>;
  /** payment_due_at field predicates */
  paymentDueAt?: InputMaybe<Scalars['Time']['input']>;
  paymentDueAtGT?: InputMaybe<Scalars['Time']['input']>;
  paymentDueAtGTE?: InputMaybe<Scalars['Time']['input']>;
  paymentDueAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  paymentDueAtLT?: InputMaybe<Scalars['Time']['input']>;
  paymentDueAtLTE?: InputMaybe<Scalars['Time']['input']>;
  paymentDueAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  paymentDueAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** ItemCategory is enum for the field category */
export enum ItemCategory {
  Cardboard = 'cardboard',
  FlexiblePackage = 'flexiblePackage',
  GiftBox = 'giftBox',
  Other = 'other',
  PaperBag = 'paperBag'
}

export type Mutation = {
  __typename?: 'Mutation';
  addAssigneesToEstimationRequest: EstimationRequest;
  createBulkEstimationRequest: EstimationRequest;
  createDemand: Company;
  createDemandItem: DemandItem;
  createDemandRecurringCost: DemandRecurringCost;
  createEstimation: Estimation;
  createInvoice: Invoice;
  createOrder: Order;
  createSalesOrder: SalesOrder;
  createSupplier: Supplier;
  deleteDemandItem: Scalars['Boolean']['output'];
  deleteDemandRecurringCost: Scalars['Boolean']['output'];
  deleteEstimation: Scalars['Boolean']['output'];
  deleteEstimationRequest: Scalars['Boolean']['output'];
  deleteInvoice: Scalars['Boolean']['output'];
  deleteOrder: Scalars['Boolean']['output'];
  deleteSalesOrder: DeletedSalesOrder;
  exportEstimationRequest: EstimationRequestExportData;
  sendOrderEmail: Scalars['Boolean']['output'];
  updateDemand: Company;
  updateDemandItem: DemandItem;
  updateDemandRecurringCost: DemandRecurringCost;
  updateEstimation: Estimation;
  updateInvoice: Invoice;
  updateOrder: Order;
  updateSalesOrder: SalesOrder;
  updateSupplier: Supplier;
  updateUserProfile: UserProfile;
  uploadDemandItemTempImage: UploadedDemandItemTempImage;
};


export type MutationAddAssigneesToEstimationRequestArgs = {
  id: Scalars['ID']['input'];
  input: AddAssigneesToEstimationRequestInput;
};


export type MutationCreateBulkEstimationRequestArgs = {
  input: CreateBulkEstimationRequestInput;
};


export type MutationCreateDemandArgs = {
  input: CreateDemandInput;
};


export type MutationCreateDemandItemArgs = {
  input: DemandItemInput;
};


export type MutationCreateDemandRecurringCostArgs = {
  input: DemandRecurringCostInput;
};


export type MutationCreateEstimationArgs = {
  input: EstimationInput;
};


export type MutationCreateInvoiceArgs = {
  input: InvoiceInput;
};


export type MutationCreateOrderArgs = {
  input: OrderInput;
};


export type MutationCreateSalesOrderArgs = {
  input: CreateSalesOrderInput;
};


export type MutationCreateSupplierArgs = {
  input: SupplierInput;
};


export type MutationDeleteDemandItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDemandRecurringCostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEstimationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEstimationRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSalesOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExportEstimationRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendOrderEmailArgs = {
  id: Scalars['ID']['input'];
  input: OrderSendEmailInput;
};


export type MutationUpdateDemandArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDemandInput;
};


export type MutationUpdateDemandItemArgs = {
  id: Scalars['ID']['input'];
  input: DemandItemUpdateInput;
  keepVersion: Scalars['Boolean']['input'];
};


export type MutationUpdateDemandRecurringCostArgs = {
  id: Scalars['ID']['input'];
  input: DemandRecurringCostUpdateInput;
};


export type MutationUpdateEstimationArgs = {
  id: Scalars['ID']['input'];
  input: EstimationUpdateInput;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID']['input'];
  input: InvoiceUpdateInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['ID']['input'];
  input: OrderUpdateInput;
};


export type MutationUpdateSalesOrderArgs = {
  input: UpdateSalesOrderInput;
};


export type MutationUpdateSupplierArgs = {
  id: Scalars['ID']['input'];
  input: SupplierUpdateInput;
};


export type MutationUpdateUserProfileArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserProfileInput;
};


export type MutationUploadDemandItemTempImageArgs = {
  input: UploadDemandItemTempImageInput;
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output'];
};

export type Order = Node & {
  __typename?: 'Order';
  assignees?: Maybe<Array<OrderAssignee>>;
  company: Company;
  companyID: Scalars['ID']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  deliveryInfo?: Maybe<OrderDeliveryInfo>;
  detailMessage?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<OrderDetail>>;
  estimation?: Maybe<Estimation>;
  estimationID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  internalAssignees?: Maybe<Array<OrderInternalAssignee>>;
  mailBodyMessage?: Maybe<Scalars['String']['output']>;
  salesOrders?: Maybe<Array<SalesOrder>>;
  supplier: Supplier;
  supplierID: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

export type OrderAssignee = Node & {
  __typename?: 'OrderAssignee';
  contact: SupplierContact;
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  orderID: Scalars['ID']['output'];
  recipientType: OrderAssigneeRecipientType;
  supplierContactID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** Ordering options for OrderAssignee connections */
export type OrderAssigneeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderAssignees. */
  field: OrderAssigneeOrderField;
};

/** Properties by which OrderAssignee connections can be ordered. */
export enum OrderAssigneeOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/** OrderAssigneeRecipientType is enum for the field recipient_type */
export enum OrderAssigneeRecipientType {
  Cc = 'cc',
  To = 'to'
}

/**
 * OrderAssigneeWhereInput is used for filtering OrderAssignee objects.
 * Input was generated by ent.
 */
export type OrderAssigneeWhereInput = {
  and?: InputMaybe<Array<OrderAssigneeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** contact edge predicates */
  hasContact?: InputMaybe<Scalars['Boolean']['input']>;
  hasContactWith?: InputMaybe<Array<SupplierContactWhereInput>>;
  /** order edge predicates */
  hasOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderWith?: InputMaybe<Array<OrderWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderAssigneeWhereInput>;
  or?: InputMaybe<Array<OrderAssigneeWhereInput>>;
  /** order_id field predicates */
  orderID?: InputMaybe<Scalars['ID']['input']>;
  orderIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** recipient_type field predicates */
  recipientType?: InputMaybe<OrderAssigneeRecipientType>;
  recipientTypeIn?: InputMaybe<Array<OrderAssigneeRecipientType>>;
  recipientTypeNEQ?: InputMaybe<OrderAssigneeRecipientType>;
  recipientTypeNotIn?: InputMaybe<Array<OrderAssigneeRecipientType>>;
  /** supplier_contact_id field predicates */
  supplierContactID?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierContactIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type OrderDeliveryInfo = Node & {
  __typename?: 'OrderDeliveryInfo';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  /** 希望納品日: 「最短」のように指定することもあるので文字列 */
  desiredDeliveryDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  orderID: Scalars['ID']['output'];
  /** 配送先。入力の簡素化のため正規化は見送る。複数先の配送先情報が入力される場合もある。 */
  recipientsText?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

export type OrderDeliveryInfoInput = {
  desiredDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  recipientsText?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options for OrderDeliveryInfo connections */
export type OrderDeliveryInfoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderDeliveryInfos. */
  field: OrderDeliveryInfoOrderField;
};

/** Properties by which OrderDeliveryInfo connections can be ordered. */
export enum OrderDeliveryInfoOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderDeliveryInfoWhereInput is used for filtering OrderDeliveryInfo objects.
 * Input was generated by ent.
 */
export type OrderDeliveryInfoWhereInput = {
  and?: InputMaybe<Array<OrderDeliveryInfoWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** desired_delivery_date field predicates */
  desiredDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateContains?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateContainsFold?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateEqualFold?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateGT?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateGTE?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateHasPrefix?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateHasSuffix?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateIn?: InputMaybe<Array<Scalars['String']['input']>>;
  desiredDeliveryDateLT?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateLTE?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateNEQ?: InputMaybe<Scalars['String']['input']>;
  desiredDeliveryDateNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** order edge predicates */
  hasOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderWith?: InputMaybe<Array<OrderWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderDeliveryInfoWhereInput>;
  or?: InputMaybe<Array<OrderDeliveryInfoWhereInput>>;
  /** order_id field predicates */
  orderID?: InputMaybe<Scalars['ID']['input']>;
  orderIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** recipients_text field predicates */
  recipientsText?: InputMaybe<Scalars['String']['input']>;
  recipientsTextContains?: InputMaybe<Scalars['String']['input']>;
  recipientsTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  recipientsTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  recipientsTextGT?: InputMaybe<Scalars['String']['input']>;
  recipientsTextGTE?: InputMaybe<Scalars['String']['input']>;
  recipientsTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  recipientsTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  recipientsTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  recipientsTextIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  recipientsTextLT?: InputMaybe<Scalars['String']['input']>;
  recipientsTextLTE?: InputMaybe<Scalars['String']['input']>;
  recipientsTextNEQ?: InputMaybe<Scalars['String']['input']>;
  recipientsTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  recipientsTextNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OrderDetail = Node & {
  __typename?: 'OrderDetail';
  /** 枝番 */
  branchNumber: Scalars['Uint32']['output'];
  createdAt: Scalars['Time']['output'];
  displayOrder: Scalars['Uint32']['output'];
  id: Scalars['ID']['output'];
  item?: Maybe<OrderItem>;
  onetimeCost?: Maybe<OrderOnetimeCost>;
  order: Order;
  orderID: Scalars['ID']['output'];
  recurringCost?: Maybe<OrderRecurringCost>;
  salesOrderDetail?: Maybe<SalesOrderDetail>;
  salesOrderDetailID?: Maybe<Scalars['ID']['output']>;
  /** item=商品, onetime_cost=単発費用, recurring_cost=継続費用 */
  type: OrderDetailType;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderDetailConnection = {
  __typename?: 'OrderDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderDetailEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderDetailEdge = {
  __typename?: 'OrderDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderDetail>;
};

export type OrderDetailInput = {
  name: Scalars['String']['input'];
  orderNumber: Scalars['Uint32']['input'];
  quantity: Scalars['Int']['input'];
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryID: Scalars['ID']['input'];
  unitPrice: Scalars['Float']['input'];
};

/** Ordering options for OrderDetail connections */
export type OrderDetailOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderDetails. */
  field: OrderDetailOrderField;
};

/** Properties by which OrderDetail connections can be ordered. */
export enum OrderDetailOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/** OrderDetailType is enum for the field type */
export enum OrderDetailType {
  Item = 'item',
  OnetimeCost = 'onetime_cost',
  RecurringCost = 'recurring_cost'
}

export type OrderDetailUpdateInput = {
  name: Scalars['String']['input'];
  orderNumber: Scalars['Uint32']['input'];
  quantity: Scalars['Int']['input'];
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryID: Scalars['ID']['input'];
  unitPrice: Scalars['Float']['input'];
};

/**
 * OrderDetailWhereInput is used for filtering OrderDetail objects.
 * Input was generated by ent.
 */
export type OrderDetailWhereInput = {
  and?: InputMaybe<Array<OrderDetailWhereInput>>;
  /** branch_number field predicates */
  branchNumber?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  branchNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** display_order field predicates */
  displayOrder?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderGT?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderGTE?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  displayOrderLT?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderLTE?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  displayOrderNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<OrderItemWhereInput>>;
  /** onetime_cost edge predicates */
  hasOnetimeCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasOnetimeCostWith?: InputMaybe<Array<OrderOnetimeCostWhereInput>>;
  /** order edge predicates */
  hasOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderWith?: InputMaybe<Array<OrderWhereInput>>;
  /** recurring_cost edge predicates */
  hasRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringCostWith?: InputMaybe<Array<OrderRecurringCostWhereInput>>;
  /** sales_order_detail edge predicates */
  hasSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderDetailWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderDetailWhereInput>;
  or?: InputMaybe<Array<OrderDetailWhereInput>>;
  /** order_id field predicates */
  orderID?: InputMaybe<Scalars['ID']['input']>;
  orderIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** sales_order_detail_id field predicates */
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  salesOrderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<OrderDetailType>;
  typeIn?: InputMaybe<Array<OrderDetailType>>;
  typeNEQ?: InputMaybe<OrderDetailType>;
  typeNotIn?: InputMaybe<Array<OrderDetailType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = 'DESC'
}

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type OrderInput = {
  companyID: Scalars['ID']['input'];
  deliveryInfo?: InputMaybe<OrderDeliveryInfoInput>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  internalAssignees?: InputMaybe<Array<CreateOrderInternalAssigneeInput>>;
  items: Array<OrderDetailInput>;
  mailBodyMessage?: InputMaybe<Scalars['String']['input']>;
  salesOrderID?: InputMaybe<Scalars['ID']['input']>;
  supplierContacts?: InputMaybe<Array<OrderSupplieerContactInput>>;
  supplierID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type OrderInternalAssignee = Node & {
  __typename?: 'OrderInternalAssignee';
  id: Scalars['ID']['output'];
  order: Order;
  orderID: Scalars['ID']['output'];
  user: User;
  userID: Scalars['ID']['output'];
};

/** Ordering options for OrderInternalAssignee connections */
export type OrderInternalAssigneeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderInternalAssignees. */
  field: OrderInternalAssigneeOrderField;
};

/** Properties by which OrderInternalAssignee connections can be ordered. */
export enum OrderInternalAssigneeOrderField {
  Id = 'ID'
}

/**
 * OrderInternalAssigneeWhereInput is used for filtering OrderInternalAssignee objects.
 * Input was generated by ent.
 */
export type OrderInternalAssigneeWhereInput = {
  and?: InputMaybe<Array<OrderInternalAssigneeWhereInput>>;
  /** order edge predicates */
  hasOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderWith?: InputMaybe<Array<OrderWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderInternalAssigneeWhereInput>;
  or?: InputMaybe<Array<OrderInternalAssigneeWhereInput>>;
  /** order_id field predicates */
  orderID?: InputMaybe<Scalars['ID']['input']>;
  orderIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** user_id field predicates */
  userID?: InputMaybe<Scalars['ID']['input']>;
  userIDContains?: InputMaybe<Scalars['ID']['input']>;
  userIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  userIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  userIDGT?: InputMaybe<Scalars['ID']['input']>;
  userIDGTE?: InputMaybe<Scalars['ID']['input']>;
  userIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  userIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  userIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIDLT?: InputMaybe<Scalars['ID']['input']>;
  userIDLTE?: InputMaybe<Scalars['ID']['input']>;
  userIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OrderItem = Node & {
  __typename?: 'OrderItem';
  category?: Maybe<ItemCategory>;
  createdAt: Scalars['Time']['output'];
  demandItem?: Maybe<DemandItem>;
  demandItemID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orderDetail: OrderDetail;
  orderDetailID: Scalars['ID']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type OrderItemCardboard = Node & {
  __typename?: 'OrderItemCardboard';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: OrderItem;
  /** 構成 */
  material: Scalars['String']['output'];
  orderItemID: Scalars['ID']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 段厚 */
  thickness: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderItemCardboardConnection = {
  __typename?: 'OrderItemCardboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemCardboardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderItemCardboardEdge = {
  __typename?: 'OrderItemCardboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItemCardboard>;
};

/** Ordering options for OrderItemCardboard connections */
export type OrderItemCardboardOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderItemCardboards. */
  field: OrderItemCardboardOrderField;
};

/** Properties by which OrderItemCardboard connections can be ordered. */
export enum OrderItemCardboardOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderItemCardboardWhereInput is used for filtering OrderItemCardboard objects.
 * Input was generated by ent.
 */
export type OrderItemCardboardWhereInput = {
  and?: InputMaybe<Array<OrderItemCardboardWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<OrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<OrderItemCardboardWhereInput>;
  or?: InputMaybe<Array<OrderItemCardboardWhereInput>>;
  /** order_item_id field predicates */
  orderItemID?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** thickness field predicates */
  thickness?: InputMaybe<Scalars['String']['input']>;
  thicknessContains?: InputMaybe<Scalars['String']['input']>;
  thicknessContainsFold?: InputMaybe<Scalars['String']['input']>;
  thicknessEqualFold?: InputMaybe<Scalars['String']['input']>;
  thicknessGT?: InputMaybe<Scalars['String']['input']>;
  thicknessGTE?: InputMaybe<Scalars['String']['input']>;
  thicknessHasPrefix?: InputMaybe<Scalars['String']['input']>;
  thicknessHasSuffix?: InputMaybe<Scalars['String']['input']>;
  thicknessIn?: InputMaybe<Array<Scalars['String']['input']>>;
  thicknessLT?: InputMaybe<Scalars['String']['input']>;
  thicknessLTE?: InputMaybe<Scalars['String']['input']>;
  thicknessNEQ?: InputMaybe<Scalars['String']['input']>;
  thicknessNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderItemEdge = {
  __typename?: 'OrderItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItem>;
};

export type OrderItemFlexiblePackage = Node & {
  __typename?: 'OrderItemFlexiblePackage';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: OrderItem;
  /** 構成 */
  material: Scalars['String']['output'];
  orderItemID: Scalars['ID']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderItemFlexiblePackageConnection = {
  __typename?: 'OrderItemFlexiblePackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemFlexiblePackageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderItemFlexiblePackageEdge = {
  __typename?: 'OrderItemFlexiblePackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItemFlexiblePackage>;
};

/** Ordering options for OrderItemFlexiblePackage connections */
export type OrderItemFlexiblePackageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderItemFlexiblePackages. */
  field: OrderItemFlexiblePackageOrderField;
};

/** Properties by which OrderItemFlexiblePackage connections can be ordered. */
export enum OrderItemFlexiblePackageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderItemFlexiblePackageWhereInput is used for filtering OrderItemFlexiblePackage objects.
 * Input was generated by ent.
 */
export type OrderItemFlexiblePackageWhereInput = {
  and?: InputMaybe<Array<OrderItemFlexiblePackageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<OrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<OrderItemFlexiblePackageWhereInput>;
  or?: InputMaybe<Array<OrderItemFlexiblePackageWhereInput>>;
  /** order_item_id field predicates */
  orderItemID?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type OrderItemGiftBox = Node & {
  __typename?: 'OrderItemGiftBox';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: OrderItem;
  orderItemID: Scalars['ID']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderItemGiftBoxConnection = {
  __typename?: 'OrderItemGiftBoxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemGiftBoxEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderItemGiftBoxEdge = {
  __typename?: 'OrderItemGiftBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItemGiftBox>;
};

/** Ordering options for OrderItemGiftBox connections */
export type OrderItemGiftBoxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderItemGiftBoxes. */
  field: OrderItemGiftBoxOrderField;
};

/** Properties by which OrderItemGiftBox connections can be ordered. */
export enum OrderItemGiftBoxOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderItemGiftBoxWhereInput is used for filtering OrderItemGiftBox objects.
 * Input was generated by ent.
 */
export type OrderItemGiftBoxWhereInput = {
  and?: InputMaybe<Array<OrderItemGiftBoxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<OrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderItemGiftBoxWhereInput>;
  or?: InputMaybe<Array<OrderItemGiftBoxWhereInput>>;
  /** order_item_id field predicates */
  orderItemID?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for OrderItem connections */
export type OrderItemOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderItems. */
  field: OrderItemOrderField;
};

/** Properties by which OrderItem connections can be ordered. */
export enum OrderItemOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type OrderItemOther = Node & {
  __typename?: 'OrderItemOther';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: OrderItem;
  orderItemID: Scalars['ID']['output'];
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderItemOtherConnection = {
  __typename?: 'OrderItemOtherConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemOtherEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderItemOtherEdge = {
  __typename?: 'OrderItemOtherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItemOther>;
};

/** Ordering options for OrderItemOther connections */
export type OrderItemOtherOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderItemOthers. */
  field: OrderItemOtherOrderField;
};

/** Properties by which OrderItemOther connections can be ordered. */
export enum OrderItemOtherOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderItemOtherWhereInput is used for filtering OrderItemOther objects.
 * Input was generated by ent.
 */
export type OrderItemOtherWhereInput = {
  and?: InputMaybe<Array<OrderItemOtherWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<OrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderItemOtherWhereInput>;
  or?: InputMaybe<Array<OrderItemOtherWhereInput>>;
  /** order_item_id field predicates */
  orderItemID?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type OrderItemPaperBag = Node & {
  __typename?: 'OrderItemPaperBag';
  createdAt: Scalars['Time']['output'];
  /** 持ち手 */
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: OrderItem;
  orderItemID: Scalars['ID']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderItemPaperBagConnection = {
  __typename?: 'OrderItemPaperBagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemPaperBagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderItemPaperBagEdge = {
  __typename?: 'OrderItemPaperBagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItemPaperBag>;
};

/** Ordering options for OrderItemPaperBag connections */
export type OrderItemPaperBagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderItemPaperBags. */
  field: OrderItemPaperBagOrderField;
};

/** Properties by which OrderItemPaperBag connections can be ordered. */
export enum OrderItemPaperBagOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderItemPaperBagWhereInput is used for filtering OrderItemPaperBag objects.
 * Input was generated by ent.
 */
export type OrderItemPaperBagWhereInput = {
  and?: InputMaybe<Array<OrderItemPaperBagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** handle field predicates */
  handle?: InputMaybe<Scalars['String']['input']>;
  handleContains?: InputMaybe<Scalars['String']['input']>;
  handleContainsFold?: InputMaybe<Scalars['String']['input']>;
  handleEqualFold?: InputMaybe<Scalars['String']['input']>;
  handleGT?: InputMaybe<Scalars['String']['input']>;
  handleGTE?: InputMaybe<Scalars['String']['input']>;
  handleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  handleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  handleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  handleLT?: InputMaybe<Scalars['String']['input']>;
  handleLTE?: InputMaybe<Scalars['String']['input']>;
  handleNEQ?: InputMaybe<Scalars['String']['input']>;
  handleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<OrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderItemPaperBagWhereInput>;
  or?: InputMaybe<Array<OrderItemPaperBagWhereInput>>;
  /** order_item_id field predicates */
  orderItemID?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * OrderItemWhereInput is used for filtering OrderItem objects.
 * Input was generated by ent.
 */
export type OrderItemWhereInput = {
  and?: InputMaybe<Array<OrderItemWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<ItemCategory>;
  categoryIn?: InputMaybe<Array<ItemCategory>>;
  categoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  categoryNEQ?: InputMaybe<ItemCategory>;
  categoryNotIn?: InputMaybe<Array<ItemCategory>>;
  categoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item edge predicates */
  hasDemandItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** order_detail edge predicates */
  hasOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderDetailWith?: InputMaybe<Array<OrderDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<OrderItemWhereInput>;
  or?: InputMaybe<Array<OrderItemWhereInput>>;
  /** order_detail_id field predicates */
  orderDetailID?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type OrderOnetimeCost = Node & {
  __typename?: 'OrderOnetimeCost';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  orderDetail: OrderDetail;
  orderDetailID: Scalars['ID']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderOnetimeCostConnection = {
  __typename?: 'OrderOnetimeCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderOnetimeCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderOnetimeCostEdge = {
  __typename?: 'OrderOnetimeCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderOnetimeCost>;
};

/** Ordering options for OrderOnetimeCost connections */
export type OrderOnetimeCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderOnetimeCosts. */
  field: OrderOnetimeCostOrderField;
};

/** Properties by which OrderOnetimeCost connections can be ordered. */
export enum OrderOnetimeCostOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderOnetimeCostWhereInput is used for filtering OrderOnetimeCost objects.
 * Input was generated by ent.
 */
export type OrderOnetimeCostWhereInput = {
  and?: InputMaybe<Array<OrderOnetimeCostWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** order_detail edge predicates */
  hasOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderDetailWith?: InputMaybe<Array<OrderDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<OrderOnetimeCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<OrderOnetimeCostWhereInput>>;
  /** order_detail_id field predicates */
  orderDetailID?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for Order connections */
export type OrderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Orders. */
  field: OrderOrderField;
};

/** Properties by which Order connections can be ordered. */
export enum OrderOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type OrderRecurringCost = Node & {
  __typename?: 'OrderRecurringCost';
  createdAt: Scalars['Time']['output'];
  demandRecurringCost?: Maybe<DemandRecurringCost>;
  /** 費用マスタを削除できるようにするためNULLを許容する */
  demandRecurringCostID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  orderDetail: OrderDetail;
  orderDetailID: Scalars['ID']['output'];
  quantity: Scalars['Uint32']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type OrderRecurringCostConnection = {
  __typename?: 'OrderRecurringCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderRecurringCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderRecurringCostEdge = {
  __typename?: 'OrderRecurringCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderRecurringCost>;
};

/** Ordering options for OrderRecurringCost connections */
export type OrderRecurringCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OrderRecurringCosts. */
  field: OrderRecurringCostOrderField;
};

/** Properties by which OrderRecurringCost connections can be ordered. */
export enum OrderRecurringCostOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * OrderRecurringCostWhereInput is used for filtering OrderRecurringCost objects.
 * Input was generated by ent.
 */
export type OrderRecurringCostWhereInput = {
  and?: InputMaybe<Array<OrderRecurringCostWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_recurring_cost_id field predicates */
  demandRecurringCostID?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandRecurringCostIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_recurring_cost edge predicates */
  hasDemandRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandRecurringCostWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** order_detail edge predicates */
  hasOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderDetailWith?: InputMaybe<Array<OrderDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<OrderRecurringCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<OrderRecurringCostWhereInput>>;
  /** order_detail_id field predicates */
  orderDetailID?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  orderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type OrderSendEmailInput = {
  mailBodyMessage?: InputMaybe<Scalars['String']['input']>;
  supplierContacts?: InputMaybe<Array<OrderSupplieerContactInput>>;
};

export type OrderSupplieerContactInput = {
  ID: Scalars['ID']['input'];
  recipientType: OrderAssigneeRecipientType;
};

export type OrderUpdateInput = {
  deliveryInfo?: InputMaybe<OrderDeliveryInfoInput>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  internalAssignees?: InputMaybe<Array<CreateOrderInternalAssigneeInput>>;
  items: Array<OrderDetailUpdateInput>;
  mailBodyMessage?: InputMaybe<Scalars['String']['input']>;
  supplierContacts?: InputMaybe<Array<OrderSupplieerContactInput>>;
  title: Scalars['String']['input'];
};

/**
 * OrderWhereInput is used for filtering Order objects.
 * Input was generated by ent.
 */
export type OrderWhereInput = {
  and?: InputMaybe<Array<OrderWhereInput>>;
  /** company_id field predicates */
  companyID?: InputMaybe<Scalars['ID']['input']>;
  companyIDContains?: InputMaybe<Scalars['ID']['input']>;
  companyIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  companyIDGT?: InputMaybe<Scalars['ID']['input']>;
  companyIDGTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  companyIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  companyIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIDLT?: InputMaybe<Scalars['ID']['input']>;
  companyIDLTE?: InputMaybe<Scalars['ID']['input']>;
  companyIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  companyIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** detail_message field predicates */
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  detailMessageContains?: InputMaybe<Scalars['String']['input']>;
  detailMessageContainsFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageEqualFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageGT?: InputMaybe<Scalars['String']['input']>;
  detailMessageGTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  detailMessageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  detailMessageLT?: InputMaybe<Scalars['String']['input']>;
  detailMessageLTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageNEQ?: InputMaybe<Scalars['String']['input']>;
  detailMessageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** estimation_id field predicates */
  estimationID?: InputMaybe<Scalars['ID']['input']>;
  estimationIDContains?: InputMaybe<Scalars['ID']['input']>;
  estimationIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  estimationIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  estimationIDGT?: InputMaybe<Scalars['ID']['input']>;
  estimationIDGTE?: InputMaybe<Scalars['ID']['input']>;
  estimationIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  estimationIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  estimationIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  estimationIDLT?: InputMaybe<Scalars['ID']['input']>;
  estimationIDLTE?: InputMaybe<Scalars['ID']['input']>;
  estimationIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  estimationIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  estimationIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** assignees edge predicates */
  hasAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssigneesWith?: InputMaybe<Array<OrderAssigneeWhereInput>>;
  /** company edge predicates */
  hasCompany?: InputMaybe<Scalars['Boolean']['input']>;
  hasCompanyWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** delivery_info edge predicates */
  hasDeliveryInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hasDeliveryInfoWith?: InputMaybe<Array<OrderDeliveryInfoWhereInput>>;
  /** details edge predicates */
  hasDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailsWith?: InputMaybe<Array<OrderDetailWhereInput>>;
  /** estimation edge predicates */
  hasEstimation?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** internal_assignees edge predicates */
  hasInternalAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalAssigneesWith?: InputMaybe<Array<OrderInternalAssigneeWhereInput>>;
  /** sales_orders edge predicates */
  hasSalesOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrdersWith?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** supplier edge predicates */
  hasSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierWith?: InputMaybe<Array<SupplierWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** mail_body_message field predicates */
  mailBodyMessage?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageContains?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageContainsFold?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageEqualFold?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageGT?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageGTE?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  mailBodyMessageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  mailBodyMessageLT?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageLTE?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageNEQ?: InputMaybe<Scalars['String']['input']>;
  mailBodyMessageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  mailBodyMessageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<OrderWhereInput>;
  or?: InputMaybe<Array<OrderWhereInput>>;
  /** supplier_id field predicates */
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OwnCompanyInfo = {
  __typename?: 'OwnCompanyInfo';
  address: Scalars['String']['output'];
  addressBuilding?: Maybe<Scalars['String']['output']>;
  corporateNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/**
 * Information about pagination in a connection.
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type Permission = Node & {
  __typename?: 'Permission';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rolePermissions?: Maybe<Array<RolePermission>>;
  roles?: Maybe<Array<Role>>;
};

/** Ordering options for Permission connections */
export type PermissionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Permissions. */
  field: PermissionOrderField;
};

/** Properties by which Permission connections can be ordered. */
export enum PermissionOrderField {
  Id = 'ID'
}

/**
 * PermissionWhereInput is used for filtering Permission objects.
 * Input was generated by ent.
 */
export type PermissionWhereInput = {
  and?: InputMaybe<Array<PermissionWhereInput>>;
  /** role_permissions edge predicates */
  hasRolePermissions?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolePermissionsWith?: InputMaybe<Array<RolePermissionWhereInput>>;
  /** roles edge predicates */
  hasRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolesWith?: InputMaybe<Array<RoleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key field predicates */
  key?: InputMaybe<Scalars['String']['input']>;
  keyContains?: InputMaybe<Scalars['String']['input']>;
  keyContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyGT?: InputMaybe<Scalars['String']['input']>;
  keyGTE?: InputMaybe<Scalars['String']['input']>;
  keyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyLT?: InputMaybe<Scalars['String']['input']>;
  keyLTE?: InputMaybe<Scalars['String']['input']>;
  keyNEQ?: InputMaybe<Scalars['String']['input']>;
  keyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<PermissionWhereInput>;
  or?: InputMaybe<Array<PermissionWhereInput>>;
};

export type Query = {
  __typename?: 'Query';
  companies: CompanyConnection;
  currentUser: CurrentUser;
  demandItems: DemandItemConnection;
  demandRecurringCosts: DemandRecurringCostConnection;
  downloadEstimationPdf: Scalars['String']['output'];
  downloadOrderPdf: Scalars['String']['output'];
  downloadSalesOrderPdf: Scalars['String']['output'];
  estimationRequestAssigneeContacts: EstimationRequestAssigneeContactConnection;
  estimationRequestAssignees: EstimationRequestAssigneeConnection;
  estimationRequestAttachmentURL: EstimationRequestAttachmentUrl;
  estimationRequestAttachments: EstimationRequestAttachmentConnection;
  estimationRequestItems: EstimationRequestItemConnection;
  estimationRequests: EstimationRequestConnection;
  estimationResponses: EstimationResponseConnection;
  estimations: EstimationConnection;
  invoiceDetailSales: InvoiceDetailSaleConnection;
  invoiceDetails: InvoiceDetailConnection;
  invoiceInternalMemos: InvoiceInternalMemoConnection;
  invoiceItems: InvoiceItemConnection;
  invoices: InvoiceConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  orderDetails: OrderDetailConnection;
  orders: OrderConnection;
  ownCompanyInfo: OwnCompanyInfo;
  salesOrderDetailDeliveryEvents: SalesOrderDetailDeliveryEventConnection;
  salesOrderDetails: SalesOrderDetailConnection;
  salesOrderInternalMemos: SalesOrderInternalMemoConnection;
  salesOrders: SalesOrderConnection;
  supplierContactMemos: SupplierContactMemoConnection;
  supplierContacts: SupplierContactConnection;
  suppliers: SupplierConnection;
  taxCategories: TaxCategoryConnection;
  userInfo: UserInfo;
  userProfiles: UserProfileConnection;
  users: UserConnection;
};


export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CompanyOrder>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryDemandItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DemandItemOrder>;
  where?: InputMaybe<DemandItemWhereInput>;
};


export type QueryDemandRecurringCostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DemandRecurringCostOrder>;
  where?: InputMaybe<DemandRecurringCostWhereInput>;
};


export type QueryDownloadEstimationPdfArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDownloadOrderPdfArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDownloadSalesOrderPdfArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEstimationRequestAssigneeContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationRequestAssigneeContactOrder>;
  where?: InputMaybe<EstimationRequestAssigneeContactWhereInput>;
};


export type QueryEstimationRequestAssigneesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationRequestAssigneeOrder>;
  where?: InputMaybe<EstimationRequestAssigneeWhereInput>;
};


export type QueryEstimationRequestAttachmentUrlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEstimationRequestAttachmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationRequestAttachmentOrder>;
  where?: InputMaybe<EstimationRequestAttachmentWhereInput>;
};


export type QueryEstimationRequestItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationRequestItemOrder>;
  where?: InputMaybe<EstimationRequestItemWhereInput>;
};


export type QueryEstimationRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationRequestOrder>;
  where?: InputMaybe<EstimationRequestWhereInput>;
};


export type QueryEstimationResponsesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationResponseOrder>;
  where?: InputMaybe<EstimationResponseWhereInput>;
};


export type QueryEstimationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EstimationOrder>;
  where?: InputMaybe<EstimationWhereInput>;
};


export type QueryInvoiceDetailSalesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceDetailSaleOrder>;
  where?: InputMaybe<InvoiceDetailSaleWhereInput>;
};


export type QueryInvoiceDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceDetailOrder>;
  where?: InputMaybe<InvoiceDetailWhereInput>;
};


export type QueryInvoiceInternalMemosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceInternalMemoOrder>;
  where?: InputMaybe<InvoiceInternalMemoWhereInput>;
};


export type QueryInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceItemOrder>;
  where?: InputMaybe<InvoiceItemWhereInput>;
};


export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceOrder>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderDetailOrder>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderOrder>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QuerySalesOrderDetailDeliveryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SalesOrderDetailDeliveryEventOrder>;
  where?: InputMaybe<SalesOrderDetailDeliveryEventWhereInput>;
};


export type QuerySalesOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SalesOrderDetailOrder>;
  where?: InputMaybe<SalesOrderDetailWhereInput>;
};


export type QuerySalesOrderInternalMemosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SalesOrderInternalMemoOrder>;
  where?: InputMaybe<SalesOrderInternalMemoWhereInput>;
};


export type QuerySalesOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SalesOrderOrder>;
  where?: InputMaybe<SalesOrderWhereInput>;
};


export type QuerySupplierContactMemosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SupplierContactMemoOrder>;
  where?: InputMaybe<SupplierContactMemoWhereInput>;
};


export type QuerySupplierContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SupplierContactOrder>;
  where?: InputMaybe<SupplierContactWhereInput>;
};


export type QuerySuppliersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SupplierOrder>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryTaxCategoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TaxCategoryOrder>;
  where?: InputMaybe<TaxCategoryWhereInput>;
};


export type QueryUserProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserProfileOrder>;
  where?: InputMaybe<UserProfileWhereInput>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
  where?: InputMaybe<UserWhereInput>;
};

export type Role = Node & {
  __typename?: 'Role';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Permission>>;
  rolePermissions?: Maybe<Array<RolePermission>>;
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
  userRoles?: Maybe<Array<UserRole>>;
  users?: Maybe<Array<User>>;
};

/** Ordering options for Role connections */
export type RoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Roles. */
  field: RoleOrderField;
};

/** Properties by which Role connections can be ordered. */
export enum RoleOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type RolePermission = Node & {
  __typename?: 'RolePermission';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  permission: Permission;
  permissionID: Scalars['ID']['output'];
  role: Role;
  roleID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** Ordering options for RolePermission connections */
export type RolePermissionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RolePermissions. */
  field: RolePermissionOrderField;
};

/** Properties by which RolePermission connections can be ordered. */
export enum RolePermissionOrderField {
  Id = 'ID'
}

/**
 * RolePermissionWhereInput is used for filtering RolePermission objects.
 * Input was generated by ent.
 */
export type RolePermissionWhereInput = {
  and?: InputMaybe<Array<RolePermissionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RolePermissionWhereInput>;
  or?: InputMaybe<Array<RolePermissionWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * RoleWhereInput is used for filtering Role objects.
 * Input was generated by ent.
 */
export type RoleWhereInput = {
  and?: InputMaybe<Array<RoleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** permissions edge predicates */
  hasPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  hasPermissionsWith?: InputMaybe<Array<PermissionWhereInput>>;
  /** role_permissions edge predicates */
  hasRolePermissions?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolePermissionsWith?: InputMaybe<Array<RolePermissionWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** user_roles edge predicates */
  hasUserRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserRolesWith?: InputMaybe<Array<UserRoleWhereInput>>;
  /** users edge predicates */
  hasUsers?: InputMaybe<Scalars['Boolean']['input']>;
  hasUsersWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<RoleWhereInput>;
  or?: InputMaybe<Array<RoleWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SalesOrder = Node & {
  __typename?: 'SalesOrder';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  demand: Company;
  demandID: Scalars['ID']['output'];
  detailMessage?: Maybe<Scalars['String']['output']>;
  details: SalesOrderDetailConnection;
  estimations?: Maybe<Array<Estimation>>;
  id: Scalars['ID']['output'];
  internalAssignees?: Maybe<Array<SalesOrderInternalAssignee>>;
  internalMemos?: Maybe<Array<SalesOrderInternalMemo>>;
  invoices?: Maybe<Array<Invoice>>;
  orders?: Maybe<Array<Order>>;
  /** 受注日 */
  receivedDate: Scalars['Time']['output'];
  supplier: Supplier;
  supplierID: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  /** サプライヤからの請求書に記載されるID。入金と見積書/発注書の突き合わせに利用する。 */
  transactionID: Scalars['TransactionID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};


export type SalesOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SalesOrderDetailOrder>;
  where?: InputMaybe<SalesOrderDetailWhereInput>;
};

/** A connection to a list of items. */
export type SalesOrderConnection = {
  __typename?: 'SalesOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SalesOrderDetail = Node & {
  __typename?: 'SalesOrderDetail';
  /** 枝番 */
  branchNumber: Scalars['Uint32']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  deliveryEvents: SalesOrderDetailDeliveryEventConnection;
  id: Scalars['ID']['output'];
  invoiceDetails: InvoiceDetailConnection;
  item?: Maybe<SalesOrderItem>;
  notionPage?: Maybe<SalesOrderDetailNotionPage>;
  onetimeCost?: Maybe<SalesOrderOnetimeCost>;
  orderDetails: OrderDetailConnection;
  /** 表示順序 */
  orderNumber: Scalars['Uint32']['output'];
  recurringCost?: Maybe<SalesOrderRecurringCost>;
  salesOrder: SalesOrder;
  salesOrderID: Scalars['ID']['output'];
  /** item=商品, onetime_cost=単発費用, recurring_cost=継続費用 */
  type: SalesOrderDetailType;
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};


export type SalesOrderDetailDeliveryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SalesOrderDetailDeliveryEventOrder>;
  where?: InputMaybe<SalesOrderDetailDeliveryEventWhereInput>;
};


export type SalesOrderDetailInvoiceDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceDetailOrder>;
  where?: InputMaybe<InvoiceDetailWhereInput>;
};


export type SalesOrderDetailOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderDetailOrder>;
  where?: InputMaybe<OrderDetailWhereInput>;
};

/** A connection to a list of items. */
export type SalesOrderDetailConnection = {
  __typename?: 'SalesOrderDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderDetailEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SalesOrderDetailDeliveryEvent = Node & {
  __typename?: 'SalesOrderDetailDeliveryEvent';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  /** 確定納品日 */
  fixedDeliveryDate: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** 有効なレコードかどうか */
  isValid: Scalars['Boolean']['output'];
  salesOrderDetail: SalesOrderDetail;
  salesOrderDetailID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type SalesOrderDetailDeliveryEventConnection = {
  __typename?: 'SalesOrderDetailDeliveryEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderDetailDeliveryEventEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderDetailDeliveryEventEdge = {
  __typename?: 'SalesOrderDetailDeliveryEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderDetailDeliveryEvent>;
};

/** Ordering options for SalesOrderDetailDeliveryEvent connections */
export type SalesOrderDetailDeliveryEventOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderDetailDeliveryEvents. */
  field: SalesOrderDetailDeliveryEventOrderField;
};

/** Properties by which SalesOrderDetailDeliveryEvent connections can be ordered. */
export enum SalesOrderDetailDeliveryEventOrderField {
  CreatedAt = 'CREATED_AT',
  FixedDeliveryDate = 'FIXED_DELIVERY_DATE',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderDetailDeliveryEventWhereInput is used for filtering SalesOrderDetailDeliveryEvent objects.
 * Input was generated by ent.
 */
export type SalesOrderDetailDeliveryEventWhereInput = {
  and?: InputMaybe<Array<SalesOrderDetailDeliveryEventWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** fixed_delivery_date field predicates */
  fixedDeliveryDate?: InputMaybe<Scalars['Time']['input']>;
  fixedDeliveryDateGT?: InputMaybe<Scalars['Time']['input']>;
  fixedDeliveryDateGTE?: InputMaybe<Scalars['Time']['input']>;
  fixedDeliveryDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  fixedDeliveryDateLT?: InputMaybe<Scalars['Time']['input']>;
  fixedDeliveryDateLTE?: InputMaybe<Scalars['Time']['input']>;
  fixedDeliveryDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  fixedDeliveryDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** sales_order_detail edge predicates */
  hasSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderDetailWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_valid field predicates */
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  isValidNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<SalesOrderDetailDeliveryEventWhereInput>;
  or?: InputMaybe<Array<SalesOrderDetailDeliveryEventWhereInput>>;
  /** sales_order_detail_id field predicates */
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** An edge in a connection. */
export type SalesOrderDetailEdge = {
  __typename?: 'SalesOrderDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderDetail>;
};

export type SalesOrderDetailNotionPage = Node & {
  __typename?: 'SalesOrderDetailNotionPage';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  notionPageID: Scalars['String']['output'];
  notionPageURL: Scalars['String']['output'];
  salesOrderDetail: SalesOrderDetail;
  salesOrderDetailID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** Ordering options for SalesOrderDetailNotionPage connections */
export type SalesOrderDetailNotionPageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderDetailNotionPages. */
  field: SalesOrderDetailNotionPageOrderField;
};

/** Properties by which SalesOrderDetailNotionPage connections can be ordered. */
export enum SalesOrderDetailNotionPageOrderField {
  Id = 'ID'
}

/**
 * SalesOrderDetailNotionPageWhereInput is used for filtering SalesOrderDetailNotionPage objects.
 * Input was generated by ent.
 */
export type SalesOrderDetailNotionPageWhereInput = {
  and?: InputMaybe<Array<SalesOrderDetailNotionPageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** sales_order_detail edge predicates */
  hasSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderDetailWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderDetailNotionPageWhereInput>;
  /** notion_page_id field predicates */
  notionPageID?: InputMaybe<Scalars['String']['input']>;
  notionPageIDContains?: InputMaybe<Scalars['String']['input']>;
  notionPageIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  notionPageIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  notionPageIDGT?: InputMaybe<Scalars['String']['input']>;
  notionPageIDGTE?: InputMaybe<Scalars['String']['input']>;
  notionPageIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notionPageIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notionPageIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notionPageIDLT?: InputMaybe<Scalars['String']['input']>;
  notionPageIDLTE?: InputMaybe<Scalars['String']['input']>;
  notionPageIDNEQ?: InputMaybe<Scalars['String']['input']>;
  notionPageIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** notion_page_url field predicates */
  notionPageURL?: InputMaybe<Scalars['String']['input']>;
  notionPageURLContains?: InputMaybe<Scalars['String']['input']>;
  notionPageURLContainsFold?: InputMaybe<Scalars['String']['input']>;
  notionPageURLEqualFold?: InputMaybe<Scalars['String']['input']>;
  notionPageURLGT?: InputMaybe<Scalars['String']['input']>;
  notionPageURLGTE?: InputMaybe<Scalars['String']['input']>;
  notionPageURLHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notionPageURLHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notionPageURLIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notionPageURLLT?: InputMaybe<Scalars['String']['input']>;
  notionPageURLLTE?: InputMaybe<Scalars['String']['input']>;
  notionPageURLNEQ?: InputMaybe<Scalars['String']['input']>;
  notionPageURLNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<SalesOrderDetailNotionPageWhereInput>>;
  /** sales_order_detail_id field predicates */
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for SalesOrderDetail connections */
export type SalesOrderDetailOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderDetails. */
  field: SalesOrderDetailOrderField;
};

/** Properties by which SalesOrderDetail connections can be ordered. */
export enum SalesOrderDetailOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  OrderNumber = 'ORDER_NUMBER',
  UpdatedAt = 'UPDATED_AT'
}

/** SalesOrderDetailType is enum for the field type */
export enum SalesOrderDetailType {
  Item = 'item',
  OnetimeCost = 'onetime_cost',
  RecurringCost = 'recurring_cost'
}

/**
 * SalesOrderDetailWhereInput is used for filtering SalesOrderDetail objects.
 * Input was generated by ent.
 */
export type SalesOrderDetailWhereInput = {
  and?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** branch_number field predicates */
  branchNumber?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  branchNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  branchNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** delivery_events edge predicates */
  hasDeliveryEvents?: InputMaybe<Scalars['Boolean']['input']>;
  hasDeliveryEventsWith?: InputMaybe<Array<SalesOrderDetailDeliveryEventWhereInput>>;
  /** invoice_details edge predicates */
  hasInvoiceDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoiceDetailsWith?: InputMaybe<Array<InvoiceDetailWhereInput>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** notion_page edge predicates */
  hasNotionPage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotionPageWith?: InputMaybe<Array<SalesOrderDetailNotionPageWhereInput>>;
  /** onetime_cost edge predicates */
  hasOnetimeCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasOnetimeCostWith?: InputMaybe<Array<SalesOrderOnetimeCostWhereInput>>;
  /** order_details edge predicates */
  hasOrderDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrderDetailsWith?: InputMaybe<Array<OrderDetailWhereInput>>;
  /** recurring_cost edge predicates */
  hasRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringCostWith?: InputMaybe<Array<SalesOrderRecurringCostWhereInput>>;
  /** sales_order edge predicates */
  hasSalesOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderWith?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderDetailWhereInput>;
  or?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** order_number field predicates */
  orderNumber?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberGTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  orderNumberLT?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberLTE?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  orderNumberNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** sales_order_id field predicates */
  salesOrderID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<SalesOrderDetailType>;
  typeIn?: InputMaybe<Array<SalesOrderDetailType>>;
  typeNEQ?: InputMaybe<SalesOrderDetailType>;
  typeNotIn?: InputMaybe<Array<SalesOrderDetailType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** An edge in a connection. */
export type SalesOrderEdge = {
  __typename?: 'SalesOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrder>;
};

export type SalesOrderInternalAssignee = Node & {
  __typename?: 'SalesOrderInternalAssignee';
  id: Scalars['ID']['output'];
  salesOrder: SalesOrder;
  salesOrderID: Scalars['ID']['output'];
  user: User;
  userID: Scalars['ID']['output'];
};

export type SalesOrderInternalAssigneeInput = {
  userID: Scalars['ID']['input'];
};

/** Ordering options for SalesOrderInternalAssignee connections */
export type SalesOrderInternalAssigneeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderInternalAssignees. */
  field: SalesOrderInternalAssigneeOrderField;
};

/** Properties by which SalesOrderInternalAssignee connections can be ordered. */
export enum SalesOrderInternalAssigneeOrderField {
  Id = 'ID'
}

/**
 * SalesOrderInternalAssigneeWhereInput is used for filtering SalesOrderInternalAssignee objects.
 * Input was generated by ent.
 */
export type SalesOrderInternalAssigneeWhereInput = {
  and?: InputMaybe<Array<SalesOrderInternalAssigneeWhereInput>>;
  /** sales_order edge predicates */
  hasSalesOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderWith?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderInternalAssigneeWhereInput>;
  or?: InputMaybe<Array<SalesOrderInternalAssigneeWhereInput>>;
  /** sales_order_id field predicates */
  salesOrderID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** user_id field predicates */
  userID?: InputMaybe<Scalars['ID']['input']>;
  userIDContains?: InputMaybe<Scalars['ID']['input']>;
  userIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  userIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  userIDGT?: InputMaybe<Scalars['ID']['input']>;
  userIDGTE?: InputMaybe<Scalars['ID']['input']>;
  userIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  userIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  userIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIDLT?: InputMaybe<Scalars['ID']['input']>;
  userIDLTE?: InputMaybe<Scalars['ID']['input']>;
  userIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SalesOrderInternalMemo = Node & {
  __typename?: 'SalesOrderInternalMemo';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  salesOrder: SalesOrder;
  salesOrderID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type SalesOrderInternalMemoConnection = {
  __typename?: 'SalesOrderInternalMemoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderInternalMemoEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderInternalMemoEdge = {
  __typename?: 'SalesOrderInternalMemoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderInternalMemo>;
};

/** Ordering options for SalesOrderInternalMemo connections */
export type SalesOrderInternalMemoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderInternalMemos. */
  field: SalesOrderInternalMemoOrderField;
};

/** Properties by which SalesOrderInternalMemo connections can be ordered. */
export enum SalesOrderInternalMemoOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderInternalMemoWhereInput is used for filtering SalesOrderInternalMemo objects.
 * Input was generated by ent.
 */
export type SalesOrderInternalMemoWhereInput = {
  and?: InputMaybe<Array<SalesOrderInternalMemoWhereInput>>;
  /** body field predicates */
  body?: InputMaybe<Scalars['String']['input']>;
  bodyContains?: InputMaybe<Scalars['String']['input']>;
  bodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  bodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  bodyGT?: InputMaybe<Scalars['String']['input']>;
  bodyGTE?: InputMaybe<Scalars['String']['input']>;
  bodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyLT?: InputMaybe<Scalars['String']['input']>;
  bodyLTE?: InputMaybe<Scalars['String']['input']>;
  bodyNEQ?: InputMaybe<Scalars['String']['input']>;
  bodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** sales_order edge predicates */
  hasSalesOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderWith?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderInternalMemoWhereInput>;
  or?: InputMaybe<Array<SalesOrderInternalMemoWhereInput>>;
  /** sales_order_id field predicates */
  salesOrderID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SalesOrderItem = Node & {
  __typename?: 'SalesOrderItem';
  category?: Maybe<ItemCategory>;
  createdAt: Scalars['Time']['output'];
  demandItem?: Maybe<DemandItem>;
  demandItemID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  salesOrderDetail: SalesOrderDetail;
  salesOrderDetailID: Scalars['ID']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type SalesOrderItemCardboard = Node & {
  __typename?: 'SalesOrderItemCardboard';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: SalesOrderItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  salesOrderItemID: Scalars['ID']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 段厚 */
  thickness: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SalesOrderItemCardboardConnection = {
  __typename?: 'SalesOrderItemCardboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderItemCardboardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderItemCardboardEdge = {
  __typename?: 'SalesOrderItemCardboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderItemCardboard>;
};

/** Ordering options for SalesOrderItemCardboard connections */
export type SalesOrderItemCardboardOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderItemCardboards. */
  field: SalesOrderItemCardboardOrderField;
};

/** Properties by which SalesOrderItemCardboard connections can be ordered. */
export enum SalesOrderItemCardboardOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderItemCardboardWhereInput is used for filtering SalesOrderItemCardboard objects.
 * Input was generated by ent.
 */
export type SalesOrderItemCardboardWhereInput = {
  and?: InputMaybe<Array<SalesOrderItemCardboardWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SalesOrderItemCardboardWhereInput>;
  or?: InputMaybe<Array<SalesOrderItemCardboardWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sales_order_item_id field predicates */
  salesOrderItemID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** thickness field predicates */
  thickness?: InputMaybe<Scalars['String']['input']>;
  thicknessContains?: InputMaybe<Scalars['String']['input']>;
  thicknessContainsFold?: InputMaybe<Scalars['String']['input']>;
  thicknessEqualFold?: InputMaybe<Scalars['String']['input']>;
  thicknessGT?: InputMaybe<Scalars['String']['input']>;
  thicknessGTE?: InputMaybe<Scalars['String']['input']>;
  thicknessHasPrefix?: InputMaybe<Scalars['String']['input']>;
  thicknessHasSuffix?: InputMaybe<Scalars['String']['input']>;
  thicknessIn?: InputMaybe<Array<Scalars['String']['input']>>;
  thicknessLT?: InputMaybe<Scalars['String']['input']>;
  thicknessLTE?: InputMaybe<Scalars['String']['input']>;
  thicknessNEQ?: InputMaybe<Scalars['String']['input']>;
  thicknessNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SalesOrderItemFlexiblePackage = Node & {
  __typename?: 'SalesOrderItemFlexiblePackage';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: SalesOrderItem;
  /** 構成 */
  material: Scalars['String']['output'];
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  salesOrderItemID: Scalars['ID']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SalesOrderItemFlexiblePackageConnection = {
  __typename?: 'SalesOrderItemFlexiblePackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderItemFlexiblePackageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderItemFlexiblePackageEdge = {
  __typename?: 'SalesOrderItemFlexiblePackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderItemFlexiblePackage>;
};

/** Ordering options for SalesOrderItemFlexiblePackage connections */
export type SalesOrderItemFlexiblePackageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderItemFlexiblePackages. */
  field: SalesOrderItemFlexiblePackageOrderField;
};

/** Properties by which SalesOrderItemFlexiblePackage connections can be ordered. */
export enum SalesOrderItemFlexiblePackageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderItemFlexiblePackageWhereInput is used for filtering SalesOrderItemFlexiblePackage objects.
 * Input was generated by ent.
 */
export type SalesOrderItemFlexiblePackageWhereInput = {
  and?: InputMaybe<Array<SalesOrderItemFlexiblePackageWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** material field predicates */
  material?: InputMaybe<Scalars['String']['input']>;
  materialContains?: InputMaybe<Scalars['String']['input']>;
  materialContainsFold?: InputMaybe<Scalars['String']['input']>;
  materialEqualFold?: InputMaybe<Scalars['String']['input']>;
  materialGT?: InputMaybe<Scalars['String']['input']>;
  materialGTE?: InputMaybe<Scalars['String']['input']>;
  materialHasPrefix?: InputMaybe<Scalars['String']['input']>;
  materialHasSuffix?: InputMaybe<Scalars['String']['input']>;
  materialIn?: InputMaybe<Array<Scalars['String']['input']>>;
  materialLT?: InputMaybe<Scalars['String']['input']>;
  materialLTE?: InputMaybe<Scalars['String']['input']>;
  materialNEQ?: InputMaybe<Scalars['String']['input']>;
  materialNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SalesOrderItemFlexiblePackageWhereInput>;
  or?: InputMaybe<Array<SalesOrderItemFlexiblePackageWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sales_order_item_id field predicates */
  salesOrderItemID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SalesOrderItemGiftBox = Node & {
  __typename?: 'SalesOrderItemGiftBox';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: SalesOrderItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  salesOrderItemID: Scalars['ID']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  /** 形状 */
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SalesOrderItemGiftBoxConnection = {
  __typename?: 'SalesOrderItemGiftBoxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderItemGiftBoxEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderItemGiftBoxEdge = {
  __typename?: 'SalesOrderItemGiftBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderItemGiftBox>;
};

/** Ordering options for SalesOrderItemGiftBox connections */
export type SalesOrderItemGiftBoxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderItemGiftBoxes. */
  field: SalesOrderItemGiftBoxOrderField;
};

/** Properties by which SalesOrderItemGiftBox connections can be ordered. */
export enum SalesOrderItemGiftBoxOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderItemGiftBoxWhereInput is used for filtering SalesOrderItemGiftBox objects.
 * Input was generated by ent.
 */
export type SalesOrderItemGiftBoxWhereInput = {
  and?: InputMaybe<Array<SalesOrderItemGiftBoxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderItemGiftBoxWhereInput>;
  or?: InputMaybe<Array<SalesOrderItemGiftBoxWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sales_order_item_id field predicates */
  salesOrderItemID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for SalesOrderItem connections */
export type SalesOrderItemOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderItems. */
  field: SalesOrderItemOrderField;
};

/** Properties by which SalesOrderItem connections can be ordered. */
export enum SalesOrderItemOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type SalesOrderItemOther = Node & {
  __typename?: 'SalesOrderItemOther';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  item: SalesOrderItem;
  salesOrderItemID: Scalars['ID']['output'];
  specText: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SalesOrderItemOtherConnection = {
  __typename?: 'SalesOrderItemOtherConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderItemOtherEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderItemOtherEdge = {
  __typename?: 'SalesOrderItemOtherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderItemOther>;
};

/** Ordering options for SalesOrderItemOther connections */
export type SalesOrderItemOtherOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderItemOthers. */
  field: SalesOrderItemOtherOrderField;
};

/** Properties by which SalesOrderItemOther connections can be ordered. */
export enum SalesOrderItemOtherOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderItemOtherWhereInput is used for filtering SalesOrderItemOther objects.
 * Input was generated by ent.
 */
export type SalesOrderItemOtherWhereInput = {
  and?: InputMaybe<Array<SalesOrderItemOtherWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderItemOtherWhereInput>;
  or?: InputMaybe<Array<SalesOrderItemOtherWhereInput>>;
  /** sales_order_item_id field predicates */
  salesOrderItemID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** spec_text field predicates */
  specText?: InputMaybe<Scalars['String']['input']>;
  specTextContains?: InputMaybe<Scalars['String']['input']>;
  specTextContainsFold?: InputMaybe<Scalars['String']['input']>;
  specTextEqualFold?: InputMaybe<Scalars['String']['input']>;
  specTextGT?: InputMaybe<Scalars['String']['input']>;
  specTextGTE?: InputMaybe<Scalars['String']['input']>;
  specTextHasPrefix?: InputMaybe<Scalars['String']['input']>;
  specTextHasSuffix?: InputMaybe<Scalars['String']['input']>;
  specTextIn?: InputMaybe<Array<Scalars['String']['input']>>;
  specTextLT?: InputMaybe<Scalars['String']['input']>;
  specTextLTE?: InputMaybe<Scalars['String']['input']>;
  specTextNEQ?: InputMaybe<Scalars['String']['input']>;
  specTextNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SalesOrderItemPaperBag = Node & {
  __typename?: 'SalesOrderItemPaperBag';
  createdAt: Scalars['Time']['output'];
  /** 持ち手 */
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item: SalesOrderItem;
  /** その他の仕様 */
  other: Scalars['String']['output'];
  /** 用紙 */
  paperType: Scalars['String']['output'];
  /** 印刷 */
  printingColor: Scalars['String']['output'];
  /** 加工 */
  processing: Scalars['String']['output'];
  salesOrderItemID: Scalars['ID']['output'];
  /** サイズ */
  size: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SalesOrderItemPaperBagConnection = {
  __typename?: 'SalesOrderItemPaperBagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderItemPaperBagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderItemPaperBagEdge = {
  __typename?: 'SalesOrderItemPaperBagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderItemPaperBag>;
};

/** Ordering options for SalesOrderItemPaperBag connections */
export type SalesOrderItemPaperBagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderItemPaperBags. */
  field: SalesOrderItemPaperBagOrderField;
};

/** Properties by which SalesOrderItemPaperBag connections can be ordered. */
export enum SalesOrderItemPaperBagOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderItemPaperBagWhereInput is used for filtering SalesOrderItemPaperBag objects.
 * Input was generated by ent.
 */
export type SalesOrderItemPaperBagWhereInput = {
  and?: InputMaybe<Array<SalesOrderItemPaperBagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** handle field predicates */
  handle?: InputMaybe<Scalars['String']['input']>;
  handleContains?: InputMaybe<Scalars['String']['input']>;
  handleContainsFold?: InputMaybe<Scalars['String']['input']>;
  handleEqualFold?: InputMaybe<Scalars['String']['input']>;
  handleGT?: InputMaybe<Scalars['String']['input']>;
  handleGTE?: InputMaybe<Scalars['String']['input']>;
  handleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  handleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  handleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  handleLT?: InputMaybe<Scalars['String']['input']>;
  handleLTE?: InputMaybe<Scalars['String']['input']>;
  handleNEQ?: InputMaybe<Scalars['String']['input']>;
  handleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** item edge predicates */
  hasItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasItemWith?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderItemPaperBagWhereInput>;
  or?: InputMaybe<Array<SalesOrderItemPaperBagWhereInput>>;
  /** other field predicates */
  other?: InputMaybe<Scalars['String']['input']>;
  otherContains?: InputMaybe<Scalars['String']['input']>;
  otherContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherGT?: InputMaybe<Scalars['String']['input']>;
  otherGTE?: InputMaybe<Scalars['String']['input']>;
  otherHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherLT?: InputMaybe<Scalars['String']['input']>;
  otherLTE?: InputMaybe<Scalars['String']['input']>;
  otherNEQ?: InputMaybe<Scalars['String']['input']>;
  otherNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** paper_type field predicates */
  paperType?: InputMaybe<Scalars['String']['input']>;
  paperTypeContains?: InputMaybe<Scalars['String']['input']>;
  paperTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  paperTypeGT?: InputMaybe<Scalars['String']['input']>;
  paperTypeGTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  paperTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  paperTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  paperTypeLT?: InputMaybe<Scalars['String']['input']>;
  paperTypeLTE?: InputMaybe<Scalars['String']['input']>;
  paperTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  paperTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** printing_color field predicates */
  printingColor?: InputMaybe<Scalars['String']['input']>;
  printingColorContains?: InputMaybe<Scalars['String']['input']>;
  printingColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  printingColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  printingColorGT?: InputMaybe<Scalars['String']['input']>;
  printingColorGTE?: InputMaybe<Scalars['String']['input']>;
  printingColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  printingColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  printingColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  printingColorLT?: InputMaybe<Scalars['String']['input']>;
  printingColorLTE?: InputMaybe<Scalars['String']['input']>;
  printingColorNEQ?: InputMaybe<Scalars['String']['input']>;
  printingColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** processing field predicates */
  processing?: InputMaybe<Scalars['String']['input']>;
  processingContains?: InputMaybe<Scalars['String']['input']>;
  processingContainsFold?: InputMaybe<Scalars['String']['input']>;
  processingEqualFold?: InputMaybe<Scalars['String']['input']>;
  processingGT?: InputMaybe<Scalars['String']['input']>;
  processingGTE?: InputMaybe<Scalars['String']['input']>;
  processingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  processingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  processingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  processingLT?: InputMaybe<Scalars['String']['input']>;
  processingLTE?: InputMaybe<Scalars['String']['input']>;
  processingNEQ?: InputMaybe<Scalars['String']['input']>;
  processingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sales_order_item_id field predicates */
  salesOrderItemID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** size field predicates */
  size?: InputMaybe<Scalars['String']['input']>;
  sizeContains?: InputMaybe<Scalars['String']['input']>;
  sizeContainsFold?: InputMaybe<Scalars['String']['input']>;
  sizeEqualFold?: InputMaybe<Scalars['String']['input']>;
  sizeGT?: InputMaybe<Scalars['String']['input']>;
  sizeGTE?: InputMaybe<Scalars['String']['input']>;
  sizeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sizeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sizeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sizeLT?: InputMaybe<Scalars['String']['input']>;
  sizeLTE?: InputMaybe<Scalars['String']['input']>;
  sizeNEQ?: InputMaybe<Scalars['String']['input']>;
  sizeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * SalesOrderItemWhereInput is used for filtering SalesOrderItem objects.
 * Input was generated by ent.
 */
export type SalesOrderItemWhereInput = {
  and?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<ItemCategory>;
  categoryIn?: InputMaybe<Array<ItemCategory>>;
  categoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  categoryNEQ?: InputMaybe<ItemCategory>;
  categoryNotIn?: InputMaybe<Array<ItemCategory>>;
  categoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_item_id field predicates */
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandItemIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandItemIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandItemIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_item edge predicates */
  hasDemandItem?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandItemWith?: InputMaybe<Array<DemandItemWhereInput>>;
  /** sales_order_detail edge predicates */
  hasSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderDetailWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SalesOrderItemWhereInput>;
  or?: InputMaybe<Array<SalesOrderItemWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** sales_order_detail_id field predicates */
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SalesOrderOnetimeCost = Node & {
  __typename?: 'SalesOrderOnetimeCost';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  salesOrderDetail: SalesOrderDetail;
  salesOrderDetailID: Scalars['ID']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SalesOrderOnetimeCostConnection = {
  __typename?: 'SalesOrderOnetimeCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderOnetimeCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderOnetimeCostEdge = {
  __typename?: 'SalesOrderOnetimeCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderOnetimeCost>;
};

/** Ordering options for SalesOrderOnetimeCost connections */
export type SalesOrderOnetimeCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderOnetimeCosts. */
  field: SalesOrderOnetimeCostOrderField;
};

/** Properties by which SalesOrderOnetimeCost connections can be ordered. */
export enum SalesOrderOnetimeCostOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderOnetimeCostWhereInput is used for filtering SalesOrderOnetimeCost objects.
 * Input was generated by ent.
 */
export type SalesOrderOnetimeCostWhereInput = {
  and?: InputMaybe<Array<SalesOrderOnetimeCostWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** sales_order_detail edge predicates */
  hasSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderDetailWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SalesOrderOnetimeCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<SalesOrderOnetimeCostWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** sales_order_detail_id field predicates */
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for SalesOrder connections */
export type SalesOrderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrders. */
  field: SalesOrderOrderField;
};

/** Properties by which SalesOrder connections can be ordered. */
export enum SalesOrderOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type SalesOrderRecurringCost = Node & {
  __typename?: 'SalesOrderRecurringCost';
  createdAt: Scalars['Time']['output'];
  demandRecurringCost?: Maybe<DemandRecurringCost>;
  /** 費用マスタを削除できるようにするためNULLを許容する */
  demandRecurringCostID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** 補足欄 */
  note: Scalars['String']['output'];
  quantity: Scalars['Uint32']['output'];
  salesOrderDetail: SalesOrderDetail;
  salesOrderDetailID: Scalars['ID']['output'];
  taxCategory: TaxCategory;
  taxCategoryID: Scalars['ID']['output'];
  unitPrice: Scalars['Float']['output'];
  unitSellingPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SalesOrderRecurringCostConnection = {
  __typename?: 'SalesOrderRecurringCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesOrderRecurringCostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrderRecurringCostEdge = {
  __typename?: 'SalesOrderRecurringCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesOrderRecurringCost>;
};

/** Ordering options for SalesOrderRecurringCost connections */
export type SalesOrderRecurringCostOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SalesOrderRecurringCosts. */
  field: SalesOrderRecurringCostOrderField;
};

/** Properties by which SalesOrderRecurringCost connections can be ordered. */
export enum SalesOrderRecurringCostOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * SalesOrderRecurringCostWhereInput is used for filtering SalesOrderRecurringCost objects.
 * Input was generated by ent.
 */
export type SalesOrderRecurringCostWhereInput = {
  and?: InputMaybe<Array<SalesOrderRecurringCostWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** demand_recurring_cost_id field predicates */
  demandRecurringCostID?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  demandRecurringCostIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandRecurringCostIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandRecurringCostIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_recurring_cost edge predicates */
  hasDemandRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandRecurringCostWith?: InputMaybe<Array<DemandRecurringCostWhereInput>>;
  /** sales_order_detail edge predicates */
  hasSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hasSalesOrderDetailWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** tax_category edge predicates */
  hasTaxCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaxCategoryWith?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SalesOrderRecurringCostWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<SalesOrderRecurringCostWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityGTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  quantityLT?: InputMaybe<Scalars['Uint32']['input']>;
  quantityLTE?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Uint32']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Uint32']['input']>>;
  /** sales_order_detail_id field predicates */
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContains?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDGTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  salesOrderDetailIDLT?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDLTE?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** tax_category_id field predicates */
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContains?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDGTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxCategoryIDLT?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDLTE?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** unit_selling_price field predicates */
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceGTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  unitSellingPriceLT?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceLTE?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNEQ?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPriceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * SalesOrderWhereInput is used for filtering SalesOrder objects.
 * Input was generated by ent.
 */
export type SalesOrderWhereInput = {
  and?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** demand_id field predicates */
  demandID?: InputMaybe<Scalars['ID']['input']>;
  demandIDContains?: InputMaybe<Scalars['ID']['input']>;
  demandIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  demandIDGT?: InputMaybe<Scalars['ID']['input']>;
  demandIDGTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  demandIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  demandIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  demandIDLT?: InputMaybe<Scalars['ID']['input']>;
  demandIDLTE?: InputMaybe<Scalars['ID']['input']>;
  demandIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  demandIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** detail_message field predicates */
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  detailMessageContains?: InputMaybe<Scalars['String']['input']>;
  detailMessageContainsFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageEqualFold?: InputMaybe<Scalars['String']['input']>;
  detailMessageGT?: InputMaybe<Scalars['String']['input']>;
  detailMessageGTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  detailMessageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  detailMessageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  detailMessageLT?: InputMaybe<Scalars['String']['input']>;
  detailMessageLTE?: InputMaybe<Scalars['String']['input']>;
  detailMessageNEQ?: InputMaybe<Scalars['String']['input']>;
  detailMessageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailMessageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** demand edge predicates */
  hasDemand?: InputMaybe<Scalars['Boolean']['input']>;
  hasDemandWith?: InputMaybe<Array<CompanyWhereInput>>;
  /** details edge predicates */
  hasDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasDetailsWith?: InputMaybe<Array<SalesOrderDetailWhereInput>>;
  /** estimations edge predicates */
  hasEstimations?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationsWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** internal_assignees edge predicates */
  hasInternalAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalAssigneesWith?: InputMaybe<Array<SalesOrderInternalAssigneeWhereInput>>;
  /** internal_memos edge predicates */
  hasInternalMemos?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternalMemosWith?: InputMaybe<Array<SalesOrderInternalMemoWhereInput>>;
  /** invoices edge predicates */
  hasInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvoicesWith?: InputMaybe<Array<InvoiceWhereInput>>;
  /** orders edge predicates */
  hasOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrdersWith?: InputMaybe<Array<OrderWhereInput>>;
  /** supplier edge predicates */
  hasSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierWith?: InputMaybe<Array<SupplierWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SalesOrderWhereInput>;
  or?: InputMaybe<Array<SalesOrderWhereInput>>;
  /** received_date field predicates */
  receivedDate?: InputMaybe<Scalars['Time']['input']>;
  receivedDateGT?: InputMaybe<Scalars['Time']['input']>;
  receivedDateGTE?: InputMaybe<Scalars['Time']['input']>;
  receivedDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  receivedDateLT?: InputMaybe<Scalars['Time']['input']>;
  receivedDateLTE?: InputMaybe<Scalars['Time']['input']>;
  receivedDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  receivedDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** supplier_id field predicates */
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** transaction_id field predicates */
  transactionID?: InputMaybe<Scalars['TransactionID']['input']>;
  transactionIDGT?: InputMaybe<Scalars['TransactionID']['input']>;
  transactionIDGTE?: InputMaybe<Scalars['TransactionID']['input']>;
  transactionIDIn?: InputMaybe<Array<Scalars['TransactionID']['input']>>;
  transactionIDLT?: InputMaybe<Scalars['TransactionID']['input']>;
  transactionIDLTE?: InputMaybe<Scalars['TransactionID']['input']>;
  transactionIDNEQ?: InputMaybe<Scalars['TransactionID']['input']>;
  transactionIDNotIn?: InputMaybe<Array<Scalars['TransactionID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Supplier = Node & {
  __typename?: 'Supplier';
  contacts?: Maybe<Array<SupplierContact>>;
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  estimationRequestAssignees?: Maybe<Array<EstimationRequestAssignee>>;
  estimations?: Maybe<Array<Estimation>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orders?: Maybe<Array<Order>>;
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplierEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SupplierContact = Node & {
  __typename?: 'SupplierContact';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  email: Scalars['String']['output'];
  estimationRequestAsignees?: Maybe<Array<EstimationRequestAssignee>>;
  estimationRequestAssigneeContacts?: Maybe<Array<EstimationRequestAssigneeContact>>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  memos?: Maybe<Array<SupplierContactMemo>>;
  supplier: Supplier;
  supplierID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type SupplierContactConnection = {
  __typename?: 'SupplierContactConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplierContactEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SupplierContactEdge = {
  __typename?: 'SupplierContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SupplierContact>;
};

export type SupplierContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierContactMemo = Node & {
  __typename?: 'SupplierContactMemo';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  supplierContact: SupplierContact;
  supplierContactID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type SupplierContactMemoConnection = {
  __typename?: 'SupplierContactMemoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplierContactMemoEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SupplierContactMemoEdge = {
  __typename?: 'SupplierContactMemoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SupplierContactMemo>;
};

/** Ordering options for SupplierContactMemo connections */
export type SupplierContactMemoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SupplierContactMemos. */
  field: SupplierContactMemoOrderField;
};

/** Properties by which SupplierContactMemo connections can be ordered. */
export enum SupplierContactMemoOrderField {
  Id = 'ID'
}

/**
 * SupplierContactMemoWhereInput is used for filtering SupplierContactMemo objects.
 * Input was generated by ent.
 */
export type SupplierContactMemoWhereInput = {
  and?: InputMaybe<Array<SupplierContactMemoWhereInput>>;
  /** body field predicates */
  body?: InputMaybe<Scalars['String']['input']>;
  bodyContains?: InputMaybe<Scalars['String']['input']>;
  bodyContainsFold?: InputMaybe<Scalars['String']['input']>;
  bodyEqualFold?: InputMaybe<Scalars['String']['input']>;
  bodyGT?: InputMaybe<Scalars['String']['input']>;
  bodyGTE?: InputMaybe<Scalars['String']['input']>;
  bodyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bodyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bodyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyLT?: InputMaybe<Scalars['String']['input']>;
  bodyLTE?: InputMaybe<Scalars['String']['input']>;
  bodyNEQ?: InputMaybe<Scalars['String']['input']>;
  bodyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** supplier_contact edge predicates */
  hasSupplierContact?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierContactWith?: InputMaybe<Array<SupplierContactWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SupplierContactMemoWhereInput>;
  or?: InputMaybe<Array<SupplierContactMemoWhereInput>>;
  /** supplier_contact_id field predicates */
  supplierContactID?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierContactIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierContactIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Ordering options for SupplierContact connections */
export type SupplierContactOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SupplierContacts. */
  field: SupplierContactOrderField;
};

/** Properties by which SupplierContact connections can be ordered. */
export enum SupplierContactOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type SupplierContactUpdateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
};

/**
 * SupplierContactWhereInput is used for filtering SupplierContact objects.
 * Input was generated by ent.
 */
export type SupplierContactWhereInput = {
  and?: InputMaybe<Array<SupplierContactWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** estimation_request_asignees edge predicates */
  hasEstimationRequestAsignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestAsigneesWith?: InputMaybe<Array<EstimationRequestAssigneeWhereInput>>;
  /** estimation_request_assignee_contacts edge predicates */
  hasEstimationRequestAssigneeContacts?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestAssigneeContactsWith?: InputMaybe<Array<EstimationRequestAssigneeContactWhereInput>>;
  /** memos edge predicates */
  hasMemos?: InputMaybe<Scalars['Boolean']['input']>;
  hasMemosWith?: InputMaybe<Array<SupplierContactMemoWhereInput>>;
  /** supplier edge predicates */
  hasSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  hasSupplierWith?: InputMaybe<Array<SupplierWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SupplierContactWhereInput>;
  or?: InputMaybe<Array<SupplierContactWhereInput>>;
  /** supplier_id field predicates */
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContains?: InputMaybe<Scalars['ID']['input']>;
  supplierIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDGTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  supplierIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIDLT?: InputMaybe<Scalars['ID']['input']>;
  supplierIDLTE?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  supplierIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** An edge in a connection. */
export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Supplier>;
};

export type SupplierInput = {
  contacts: Array<SupplierContactInput>;
  name: Scalars['String']['input'];
};

/** Ordering options for Supplier connections */
export type SupplierOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Suppliers. */
  field: SupplierOrderField;
};

/** Properties by which Supplier connections can be ordered. */
export enum SupplierOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type SupplierUpdateInput = {
  contacts: Array<SupplierContactUpdateInput>;
  name: Scalars['String']['input'];
};

/**
 * SupplierWhereInput is used for filtering Supplier objects.
 * Input was generated by ent.
 */
export type SupplierWhereInput = {
  and?: InputMaybe<Array<SupplierWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** creator_id field predicates */
  creatorID?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContains?: InputMaybe<Scalars['ID']['input']>;
  creatorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  creatorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorIDLT?: InputMaybe<Scalars['ID']['input']>;
  creatorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  creatorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** contacts edge predicates */
  hasContacts?: InputMaybe<Scalars['Boolean']['input']>;
  hasContactsWith?: InputMaybe<Array<SupplierContactWhereInput>>;
  /** created_by edge predicates */
  hasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** estimation_request_assignees edge predicates */
  hasEstimationRequestAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationRequestAssigneesWith?: InputMaybe<Array<EstimationRequestAssigneeWhereInput>>;
  /** estimations edge predicates */
  hasEstimations?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstimationsWith?: InputMaybe<Array<EstimationWhereInput>>;
  /** orders edge predicates */
  hasOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrdersWith?: InputMaybe<Array<OrderWhereInput>>;
  /** updated_by edge predicates */
  hasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdatedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SupplierWhereInput>;
  or?: InputMaybe<Array<SupplierWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updater_id field predicates */
  updaterID?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContains?: InputMaybe<Scalars['ID']['input']>;
  updaterIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDGTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updaterIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updaterIDLT?: InputMaybe<Scalars['ID']['input']>;
  updaterIDLTE?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  updaterIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TaxCategory = Node & {
  __typename?: 'TaxCategory';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type TaxCategoryConnection = {
  __typename?: 'TaxCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TaxCategoryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TaxCategoryEdge = {
  __typename?: 'TaxCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TaxCategory>;
};

/** Ordering options for TaxCategory connections */
export type TaxCategoryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TaxCategories. */
  field: TaxCategoryOrderField;
};

/** Properties by which TaxCategory connections can be ordered. */
export enum TaxCategoryOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * TaxCategoryWhereInput is used for filtering TaxCategory objects.
 * Input was generated by ent.
 */
export type TaxCategoryWhereInput = {
  and?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<TaxCategoryWhereInput>;
  or?: InputMaybe<Array<TaxCategoryWhereInput>>;
  /** rate field predicates */
  rate?: InputMaybe<Scalars['Float']['input']>;
  rateGT?: InputMaybe<Scalars['Float']['input']>;
  rateGTE?: InputMaybe<Scalars['Float']['input']>;
  rateIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  rateLT?: InputMaybe<Scalars['Float']['input']>;
  rateLTE?: InputMaybe<Scalars['Float']['input']>;
  rateNEQ?: InputMaybe<Scalars['Float']['input']>;
  rateNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type UpdateDemandInput = {
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/**
 * UpdateEstimationDetailInput is used for update EstimationDetail object.
 * Input was generated by ent.
 */
export type UpdateEstimationDetailInput = {
  clearCost?: InputMaybe<Scalars['Boolean']['input']>;
  clearItem?: InputMaybe<Scalars['Boolean']['input']>;
  clearText?: InputMaybe<Scalars['Boolean']['input']>;
  costID?: InputMaybe<Scalars['ID']['input']>;
  /** 帳票表示ステータス(shown=表示する, hidden=非表示) */
  documentDisplayStatus?: InputMaybe<EstimationDetailDocumentDisplayStatus>;
  estimationID?: InputMaybe<Scalars['ID']['input']>;
  itemID?: InputMaybe<Scalars['ID']['input']>;
  orderNumber?: InputMaybe<Scalars['Uint32']['input']>;
  textID?: InputMaybe<Scalars['ID']['input']>;
  /** item=商品, cost=費用, text=テキスト */
  type?: InputMaybe<EstimationDetailType>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * UpdateEstimationInput is used for update Estimation object.
 * Input was generated by ent.
 */
export type UpdateEstimationInput = {
  addDetailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addInternalAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSalesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearDetailMessage?: InputMaybe<Scalars['Boolean']['input']>;
  clearDetails?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstimationResponse?: InputMaybe<Scalars['Boolean']['input']>;
  clearInternalAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  clearOrders?: InputMaybe<Scalars['Boolean']['input']>;
  clearSalesOrders?: InputMaybe<Scalars['Boolean']['input']>;
  companyID?: InputMaybe<Scalars['ID']['input']>;
  createdByID?: InputMaybe<Scalars['ID']['input']>;
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  estimationResponseID?: InputMaybe<Scalars['ID']['input']>;
  expirationPeriod?: InputMaybe<Scalars['String']['input']>;
  removeDetailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeInternalAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSalesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  showAmountSummary?: InputMaybe<Scalars['Boolean']['input']>;
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateEstimationItemInput is used for update EstimationItem object.
 * Input was generated by ent.
 */
export type UpdateEstimationItemInput = {
  category?: InputMaybe<ItemCategory>;
  clearCategory?: InputMaybe<Scalars['Boolean']['input']>;
  clearDemandItem?: InputMaybe<Scalars['Boolean']['input']>;
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  estimationDetailID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

export type UpdateInvoiceDetailInput = {
  Item?: InputMaybe<InvoiceItemInput>;
  invoiceDetailID?: InputMaybe<Scalars['ID']['input']>;
  orderNumber: Scalars['Int']['input'];
  salesDate?: InputMaybe<Scalars['Time']['input']>;
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  type: InvoiceDetailType;
};

/**
 * UpdateInvoiceInput is used for update Invoice object.
 * Input was generated by ent.
 */
export type UpdateInvoiceInput = {
  addDetailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEstimationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addInternalMemoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSalesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 会計計上日 */
  bookedAt?: InputMaybe<Scalars['Time']['input']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearDetailMessage?: InputMaybe<Scalars['Boolean']['input']>;
  clearDetails?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstimations?: InputMaybe<Scalars['Boolean']['input']>;
  clearFreeeInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  clearInternalMemos?: InputMaybe<Scalars['Boolean']['input']>;
  clearSalesOrders?: InputMaybe<Scalars['Boolean']['input']>;
  companyID?: InputMaybe<Scalars['ID']['input']>;
  createdByID?: InputMaybe<Scalars['ID']['input']>;
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  freeeInvoiceID?: InputMaybe<Scalars['ID']['input']>;
  /** 支払期日 */
  paymentDueAt?: InputMaybe<Scalars['Time']['input']>;
  removeDetailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEstimationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeInternalMemoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSalesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateOrderAssigneeInput is used for update OrderAssignee object.
 * Input was generated by ent.
 */
export type UpdateOrderAssigneeInput = {
  contactID?: InputMaybe<Scalars['ID']['input']>;
  orderID?: InputMaybe<Scalars['ID']['input']>;
  recipientType?: InputMaybe<OrderAssigneeRecipientType>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * UpdateOrderDeliveryInfoInput is used for update OrderDeliveryInfo object.
 * Input was generated by ent.
 */
export type UpdateOrderDeliveryInfoInput = {
  clearRecipientsText?: InputMaybe<Scalars['Boolean']['input']>;
  createdByID?: InputMaybe<Scalars['ID']['input']>;
  /** 希望納品日: 「最短」のように指定することもあるので文字列 */
  desiredDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  orderID?: InputMaybe<Scalars['ID']['input']>;
  /** 配送先。入力の簡素化のため正規化は見送る。複数先の配送先情報が入力される場合もある。 */
  recipientsText?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateOrderDetailInput is used for update OrderDetail object.
 * Input was generated by ent.
 */
export type UpdateOrderDetailInput = {
  /** 枝番 */
  branchNumber?: InputMaybe<Scalars['Uint32']['input']>;
  clearItem?: InputMaybe<Scalars['Boolean']['input']>;
  clearOnetimeCost?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecurringCost?: InputMaybe<Scalars['Boolean']['input']>;
  clearSalesOrderDetail?: InputMaybe<Scalars['Boolean']['input']>;
  displayOrder?: InputMaybe<Scalars['Uint32']['input']>;
  itemID?: InputMaybe<Scalars['ID']['input']>;
  onetimeCostID?: InputMaybe<Scalars['ID']['input']>;
  orderID?: InputMaybe<Scalars['ID']['input']>;
  recurringCostID?: InputMaybe<Scalars['ID']['input']>;
  salesOrderDetailID?: InputMaybe<Scalars['ID']['input']>;
  /** item=商品, onetime_cost=単発費用, recurring_cost=継続費用 */
  type?: InputMaybe<OrderDetailType>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * UpdateOrderInput is used for update Order object.
 * Input was generated by ent.
 */
export type UpdateOrderInput = {
  addAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDetailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addInternalAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSalesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearDeliveryInfo?: InputMaybe<Scalars['Boolean']['input']>;
  clearDetailMessage?: InputMaybe<Scalars['Boolean']['input']>;
  clearDetails?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstimation?: InputMaybe<Scalars['Boolean']['input']>;
  clearInternalAssignees?: InputMaybe<Scalars['Boolean']['input']>;
  clearMailBodyMessage?: InputMaybe<Scalars['Boolean']['input']>;
  clearSalesOrders?: InputMaybe<Scalars['Boolean']['input']>;
  companyID?: InputMaybe<Scalars['ID']['input']>;
  createdByID?: InputMaybe<Scalars['ID']['input']>;
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deliveryInfoID?: InputMaybe<Scalars['ID']['input']>;
  detailMessage?: InputMaybe<Scalars['String']['input']>;
  estimationID?: InputMaybe<Scalars['ID']['input']>;
  mailBodyMessage?: InputMaybe<Scalars['String']['input']>;
  removeAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDetailIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeInternalAssigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSalesOrderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateOrderItemInput is used for update OrderItem object.
 * Input was generated by ent.
 */
export type UpdateOrderItemInput = {
  category?: InputMaybe<ItemCategory>;
  clearCategory?: InputMaybe<Scalars['Boolean']['input']>;
  clearDemandItem?: InputMaybe<Scalars['Boolean']['input']>;
  demandItemID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderDetailID?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Uint32']['input']>;
  taxCategoryID?: InputMaybe<Scalars['ID']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
};

export type UpdateSalesOrderDetailInput = {
  Item?: InputMaybe<UpdateSalesOrderItemInput>;
  fixedDeliveryDate?: InputMaybe<Scalars['Time']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  orderNumber: Scalars['Int']['input'];
  type: SalesOrderDetailType;
};

export type UpdateSalesOrderInput = {
  demandID: Scalars['ID']['input'];
  detailMessage: Scalars['String']['input'];
  details: Array<UpdateSalesOrderDetailInput>;
  id: Scalars['ID']['input'];
  internalAssignees?: InputMaybe<Array<SalesOrderInternalAssigneeInput>>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  receivedDate: Scalars['Time']['input'];
  supplierID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateSalesOrderItemInput = {
  name: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  taxCategoryID: Scalars['ID']['input'];
  unitPrice: Scalars['Float']['input'];
  unitSellingPrice: Scalars['Float']['input'];
};

/**
 * UpdateSupplierContactInput is used for update SupplierContact object.
 * Input was generated by ent.
 */
export type UpdateSupplierContactInput = {
  addEstimationRequestAsigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addMemoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstimationRequestAsignees?: InputMaybe<Scalars['Boolean']['input']>;
  clearMemos?: InputMaybe<Scalars['Boolean']['input']>;
  createdByID?: InputMaybe<Scalars['ID']['input']>;
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  removeEstimationRequestAsigneeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeMemoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierID?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedByID?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateUserProfileInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UploadDemandItemTempImageInput = {
  image: UploadFile;
};

export type UploadFile = {
  file: Scalars['Upload']['input'];
};

export type UploadedDemandItemTempImage = {
  __typename?: 'UploadedDemandItemTempImage';
  objectName: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
};

export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  email: Scalars['String']['output'];
  fbAuthUUID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  profile?: Maybe<Array<UserProfile>>;
  roles?: Maybe<Array<Role>>;
  updatedAt: Scalars['Time']['output'];
  userRoles?: Maybe<Array<UserRole>>;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  connectedServices: ConnectedServices;
  permissions: Array<Scalars['String']['output']>;
};

/** Ordering options for User connections */
export type UserOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Users. */
  field: UserOrderField;
};

/** Properties by which User connections can be ordered. */
export enum UserOrderField {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type UserProfile = Node & {
  __typename?: 'UserProfile';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: User;
  userID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type UserProfileConnection = {
  __typename?: 'UserProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserProfileEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserProfileEdge = {
  __typename?: 'UserProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserProfile>;
};

/** Ordering options for UserProfile connections */
export type UserProfileOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserProfiles. */
  field: UserProfileOrderField;
};

/** Properties by which UserProfile connections can be ordered. */
export enum UserProfileOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * UserProfileWhereInput is used for filtering UserProfile objects.
 * Input was generated by ent.
 */
export type UserProfileWhereInput = {
  and?: InputMaybe<Array<UserProfileWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<UserProfileWhereInput>;
  or?: InputMaybe<Array<UserProfileWhereInput>>;
  /** phone_number field predicates */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberContains?: InputMaybe<Scalars['String']['input']>;
  phoneNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  phoneNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  phoneNumberGT?: InputMaybe<Scalars['String']['input']>;
  phoneNumberGTE?: InputMaybe<Scalars['String']['input']>;
  phoneNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  phoneNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberLT?: InputMaybe<Scalars['String']['input']>;
  phoneNumberLTE?: InputMaybe<Scalars['String']['input']>;
  phoneNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  phoneNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user_id field predicates */
  userID?: InputMaybe<Scalars['ID']['input']>;
  userIDContains?: InputMaybe<Scalars['ID']['input']>;
  userIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  userIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  userIDGT?: InputMaybe<Scalars['ID']['input']>;
  userIDGTE?: InputMaybe<Scalars['ID']['input']>;
  userIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  userIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  userIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIDLT?: InputMaybe<Scalars['ID']['input']>;
  userIDLTE?: InputMaybe<Scalars['ID']['input']>;
  userIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserRole = Node & {
  __typename?: 'UserRole';
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  creatorID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: Role;
  roleID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
  updaterID: Scalars['ID']['output'];
  user: User;
  userID: Scalars['ID']['output'];
};

/** Ordering options for UserRole connections */
export type UserRoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserRoles. */
  field: UserRoleOrderField;
};

/** Properties by which UserRole connections can be ordered. */
export enum UserRoleOrderField {
  Id = 'ID'
}

/**
 * UserRoleWhereInput is used for filtering UserRole objects.
 * Input was generated by ent.
 */
export type UserRoleWhereInput = {
  and?: InputMaybe<Array<UserRoleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<UserRoleWhereInput>;
  or?: InputMaybe<Array<UserRoleWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * UserWhereInput is used for filtering User objects.
 * Input was generated by ent.
 */
export type UserWhereInput = {
  and?: InputMaybe<Array<UserWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** fb_auth_uuid field predicates */
  fbAuthUUID?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDContains?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDGT?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDGTE?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fbAuthUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  fbAuthUUIDLT?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDLTE?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDNEQ?: InputMaybe<Scalars['String']['input']>;
  fbAuthUUIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fbAuthUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** profile edge predicates */
  hasProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasProfileWith?: InputMaybe<Array<UserProfileWhereInput>>;
  /** roles edge predicates */
  hasRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolesWith?: InputMaybe<Array<RoleWhereInput>>;
  /** user_roles edge predicates */
  hasUserRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserRolesWith?: InputMaybe<Array<UserRoleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<UserWhereInput>;
  or?: InputMaybe<Array<UserWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};
