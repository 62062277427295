import { Button, ButtonProps } from '@chakra-ui/react';
import { DownOne, UpOne } from '@icon-park/react';

type Props = { type: 'up' | 'down' } & Omit<ButtonProps, 'type'>;

export const ChangeOrderButton = ({ type, ...props }: Props) => (
  <Button
    display="block"
    p={0}
    width="16px"
    height="16px"
    borderRadius="2px"
    bg="gray.100"
    color="gray.700"
    minW="auto"
    {...props}
  >
    {type === 'up' && <UpOne />}
    {type === 'down' && <DownOne />}
  </Button>
);
