import { Box } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';
import { InvoiceSearchQueryProvider } from 'features/invoice/components/provider/SearchQueryProvider';
import { Outlet } from 'react-router-dom';

export const InvoicePageLayout = () => (
  <InvoiceSearchQueryProvider>
    <>
      <Box mb={8}>
        <PageHeading label="請求書" />
      </Box>
      <Outlet />
    </>
  </InvoiceSearchQueryProvider>
);
