import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Td, Tr } from '@chakra-ui/react';
import { More } from '@icon-park/react';
import { Link } from 'components/Link';
import { EstimationRequestTableRowFragment$key } from 'gql/__generated__/EstimationRequestTableRowFragment.graphql';
import { Fragment } from 'react';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const EstimationRequestTableRowFragment = graphql`
  fragment EstimationRequestTableRowFragment on EstimationRequest {
    id
    requestTitle
    createdAt
    company {
      id
      name
    }
    requestAssignees {
      supplier {
        id
        name
      }
    }
    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

export const EstimationRequestTableRow = ({
  queryRef,
}: { queryRef: EstimationRequestTableRowFragment$key }) => {
  const data = useFragment(EstimationRequestTableRowFragment, queryRef);

  const suppliers = new Set(
    data.requestAssignees?.map((assignee) => ({
      id: assignee.supplier.id,
      name: assignee.supplier.name,
    })),
  );

  return (
    <Tr>
      <Td>{data.requestTitle || '-'} </Td>
      <Td>
        <Link to={paths.company._id(data.company.id).url}>{data.company.name}</Link>
      </Td>
      <Td>
        {Array.from(suppliers).map((supplier, i) => (
          <Fragment key={supplier.id}>
            <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
            {i !== suppliers.size - 1 && ' / '}
          </Fragment>
        ))}
      </Td>
      <Td whiteSpace="nowrap">{dateFormat(data.createdAt)}</Td>
      <Td>
        {data.internalAssignees?.map((assignee) =>
          assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
            <Box key={assignee.user.id}>
              {concatFullName({
                lastName: assignee.user.profile[0].lastName,
                firstName: assignee.user.profile[0].firstName,
              })}
            </Box>
          ) : (
            <Box key={assignee.user.id}>-</Box>
          ),
        ) || '-'}
      </Td>
      <Td>
        <Menu>
          <MenuButton>
            <More />
          </MenuButton>
          <MenuList>
            <MenuItem as={RouterLink} to={paths.estimationRequest.duplication._id(data.id)}>
              複製
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
      <Td w="40px">
        <Link to={paths.estimationRequest._id(data.id)}>
          <Button colorScheme="blue" size="sm">
            詳細
          </Button>
        </Link>
      </Td>
    </Tr>
  );
};
