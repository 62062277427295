import { Button, Td, Text, Tr } from '@chakra-ui/react';
import { SupplierTableRowFragment$key } from 'gql/__generated__/SupplierTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const SupplierTableRowFragment = graphql`
  fragment SupplierTableRowFragment on Supplier {
    id
    name
    contacts {
      id
      lastName
      firstName
    }
  }
`;

export const SupplierTableRow = ({ queryRef }: { queryRef: SupplierTableRowFragment$key }) => {
  const { id, name, contacts } = useFragment(SupplierTableRowFragment, queryRef);

  return (
    <Tr>
      <Td>{name || '-'} </Td>
      <Td whiteSpace="nowrap">
        {contacts?.map((contact) => (
          <Text key={contact.id}>
            {concatFullName({ lastName: contact.lastName, firstName: contact.firstName })}
          </Text>
        ))}
      </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="sm" to={paths.supplier._id(id).url()}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
