import { Button, Flex, HStack, Text } from '@chakra-ui/react';

type Props = {
  hasNext: boolean;
  onClickMore: () => void;
  totalCount: number;
  currentLength?: number;
  loading: boolean;
};

export const LoadMoreButton = ({
  hasNext,
  onClickMore,
  totalCount,
  currentLength,
  loading,
}: Props) => (
  <Flex>
    {hasNext && (
      <Flex justify="center" mr={5}>
        <Button
          width="100px"
          colorScheme="gray"
          onClick={onClickMore}
          size="sm"
          isDisabled={loading}
        >
          もっと見る
        </Button>
      </Flex>
    )}
    <HStack>
      {currentLength && (
        <HStack>
          <Text>1 - {currentLength}</Text>
          <Text color="gray.400"> / {totalCount}</Text>
        </HStack>
      )}
    </HStack>
  </Flex>
);
