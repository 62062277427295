/**
 * @generated SignedSource<<f39902420cbbcf364cd89acb6799e458>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type detail_estimationRequestDetailPageFragment$data = {
  readonly estimationRequests: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"EstimationRequestAttachmentFilesFragment" | "EstimationRequestInfoFragment" | "EstimationRequestItemMessageFragment" | "EstimationRequestPreviewItemsFragment" | "EstimationResponsesFragment">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "detail_estimationRequestDetailPageFragment";
};
export type detail_estimationRequestDetailPageFragment$key = {
  readonly " $data"?: detail_estimationRequestDetailPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"detail_estimationRequestDetailPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "detail_estimationRequestDetailPageFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EstimationRequestConnection",
      "kind": "LinkedField",
      "name": "estimationRequests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationRequestEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationRequest",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EstimationRequestInfoFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EstimationRequestPreviewItemsFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EstimationRequestItemMessageFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EstimationRequestAttachmentFilesFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EstimationResponsesFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "af4e81cd7ebec3ed01d7a3e5526df281";

export default node;
