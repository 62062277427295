import { ComboBox, ComboBoxItem } from 'components/ComboBox';
import { CompanyComboBoxQuery as CompanyComboBoxQueryType } from 'gql/__generated__/CompanyComboBoxQuery.graphql';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { toast } from 'lib/toast';
import { DemandResult } from 'proto/model/search/v1/result_pb';
import { SearchType } from 'proto/model/search/v1/type_pb';
import { SearchService } from 'proto/service/search/v1/search_connect';
import { useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { captureException } from 'utils/error';

const CompanyComboBoxQuery = graphql`
  query CompanyComboBoxQuery  {
    companies(first: 30) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type Props = {
  defaultSelectedItem?: ComboBoxItem;
  onChangeSelected: (value?: ComboBoxItem | null) => void;
  isDisabled?: boolean;
  onClearInput: () => void;
  excludeIds?: string[];
};

export const CompanyComboBox = ({
  defaultSelectedItem,
  onChangeSelected,
  isDisabled = false,
  onClearInput,
  excludeIds = [],
}: Props) => {
  const { companies: companiesQuery } = useLazyLoadQuery<CompanyComboBoxQueryType>(
    CompanyComboBoxQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const companies = (companiesQuery.edges || [])
    .map((edge) => edge?.node)
    .filter((item): item is NonNullable<typeof item> => item != null);
  const { grpcClient, authorized } = useGrpcClient(SearchService);
  const [searchWord, setSearchWord] = useState(defaultSelectedItem?.name || '');
  const [items, setItems] = useState<ComboBoxItem[]>(
    defaultSelectedItem ? [defaultSelectedItem] : [],
  );
  const onFilterItems = async (
    _: ComboBoxItem[],
    input: string,
    // opts?: { signal?: AbortSignal },
  ): Promise<ComboBoxItem[]> => {
    if (!authorized) return companies;
    if (!input) return companies;
    if (searchWord === input) return items;

    setSearchWord(input);
    try {
      const res = await grpcClient?.search(
        { types: [SearchType.DEMAND], word: input },
        // NOTE: signalを渡すと、検索ワードが変わるたびにキャンセルされてしまう。
        //      そのため、signalを渡さないようにする。
        //      @see https://github.com/shizai-inc/lube-frontend/pull/444
        // { signal: opts?.signal },
      );
      const items = res.results
        .map((r) => r.result.value)
        .filter((r): r is DemandResult => r instanceof DemandResult && !excludeIds.includes(r.id));
      setItems(items);
      return items;
    } catch (e) {
      // ここでエラーが発生するのはサーバーが落ちている場合などユーザー影響の大きい異常事態なのでアラートが飛びまくる可能性がある。
      // そのためアラートが飛びすぎないようにseverityをwarningにして何が起きているのか追えるようにする。
      captureException(e, {
        level: 'warning',
      });

      let message = '';
      if (e instanceof Error) {
        message = e.message;
      }

      toast({
        title: `デマンドの検索処理中にエラーが発生しました`,
        description: message,
        status: 'error',
      });
    }
    return companies;
  };

  const handleReset = () => onClearInput();

  return (
    <ComboBox
      items={companies}
      isDisabled={isDisabled}
      onReset={handleReset}
      onChangeSelected={onChangeSelected}
      defaultSelectedItem={defaultSelectedItem}
      onFilterItems={onFilterItems}
    />
  );
};
