import { useExportEstimationRequestMutation } from 'gql/__generated__/useExportEstimationRequestMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { useContext } from 'react';
import { graphql } from 'relay-runtime';

export const useExportEstimationRequest = () => {
  const versionWatcher = useContext(VersionWatcherContext);

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<useExportEstimationRequestMutation>(
      graphql`
      mutation useExportEstimationRequestMutation(
        $id: ID!
      ) {
        exportEstimationRequest(id: $id) {
            url
        }
      }
    `,
    );

  const onSubmit = (id: string) => {
    const enableSoftDelete = versionWatcher.disableSoftDeleteEffect();

    commitMutation({
      variables: {
        id,
      },
      onCompleted: ({ exportEstimationRequest: { url } }) => {
        enableSoftDelete();
        window.open(url, '_blank', 'noopener, noreferrer');
      },
    });
  };

  return {
    onSubmit,
    isMutationInFlight,
  };
};
