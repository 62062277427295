/**
 * @generated SignedSource<<f6b95f3093fa2411a3fd8b31a4831d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateDemandInput = {
  memo?: string | null;
  name: string;
};
export type useUpdateDemandMutation$variables = {
  id: string;
  input: UpdateDemandInput;
};
export type useUpdateDemandMutation$data = {
  readonly updateDemand: {
    readonly id: string;
  };
};
export type useUpdateDemandMutation = {
  response: useUpdateDemandMutation$data;
  variables: useUpdateDemandMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Company",
    "kind": "LinkedField",
    "name": "updateDemand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateDemandMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateDemandMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "034b92d510c93fb651ef4c107c19b2cb",
    "id": null,
    "metadata": {},
    "name": "useUpdateDemandMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateDemandMutation(\n  $id: ID!\n  $input: UpdateDemandInput!\n) {\n  updateDemand(id: $id, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d7c35b256f997e5dd197a2c71627017";

export default node;
