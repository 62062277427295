/**
 * @generated SignedSource<<b64442952a7a14ebf5291094f7a7973c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationPreviewHeaderFragment$data = {
  readonly id: string;
  readonly orders: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly " $fragmentType": "EstimationPreviewHeaderFragment";
};
export type EstimationPreviewHeaderFragment$key = {
  readonly " $data"?: EstimationPreviewHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationPreviewHeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationPreviewHeaderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "orders",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Estimation",
  "abstractKey": null
};
})();

(node as any).hash = "9137d716414eadb8f3e0269328bfd276";

export default node;
