/**
 * @generated SignedSource<<5bbcf30c90478c3c9f2e331073a3bbd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type new_InvoiceNewPageQuery$variables = {};
export type new_InvoiceNewPageQuery$data = {
  readonly userInfo: {
    readonly connectedServices: {
      readonly freee: boolean;
    };
  };
};
export type new_InvoiceNewPageQuery = {
  response: new_InvoiceNewPageQuery$data;
  variables: new_InvoiceNewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserInfo",
    "kind": "LinkedField",
    "name": "userInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConnectedServices",
        "kind": "LinkedField",
        "name": "connectedServices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "freee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "new_InvoiceNewPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "new_InvoiceNewPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e367a81843d88db4a31c20414296ff39",
    "id": null,
    "metadata": {},
    "name": "new_InvoiceNewPageQuery",
    "operationKind": "query",
    "text": "query new_InvoiceNewPageQuery {\n  userInfo {\n    connectedServices {\n      freee\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ba9fd0061bdc42ef4b49e8004a34966";

export default node;
