/**
 * @generated SignedSource<<25552978b3d118523a10b8142c26fbea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useOwnCompanyInfo_Query$variables = {};
export type useOwnCompanyInfo_Query$data = {
  readonly ownCompanyInfo: {
    readonly address: string;
    readonly addressBuilding: string | null;
    readonly corporateNumber: string;
    readonly id: string;
    readonly name: string;
  };
};
export type useOwnCompanyInfo_Query = {
  response: useOwnCompanyInfo_Query$data;
  variables: useOwnCompanyInfo_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OwnCompanyInfo",
    "kind": "LinkedField",
    "name": "ownCompanyInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addressBuilding",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "corporateNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useOwnCompanyInfo_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useOwnCompanyInfo_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5a9ef09287b574d31bc9a89bc9fd515d",
    "id": null,
    "metadata": {},
    "name": "useOwnCompanyInfo_Query",
    "operationKind": "query",
    "text": "query useOwnCompanyInfo_Query {\n  ownCompanyInfo {\n    id\n    name\n    address\n    addressBuilding\n    corporateNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "1193ca07420d33986662c32c65bf8bc8";

export default node;
