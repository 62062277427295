import { EnvironmentType, Environments } from 'constants/environment';

export function getEnvironment(): EnvironmentType {
  const environment = import.meta.env.VITE_ENV;

  switch (environment) {
    case Environments.Production:
      return Environments.Production;
    case Environments.Preview:
      return Environments.Preview;
    case Environments.Development:
      return Environments.Development;
    default:
      throw new Error('invalid env name');
  }
}
