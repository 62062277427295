import { useParams } from 'react-router-dom';
import { OauthFreeeCallbackPage } from './freee';

const renderPage = (app: string | undefined) => {
  switch (app) {
    case 'freee':
      return <OauthFreeeCallbackPage />;
    default:
      return null;
  }
};

export const OauthCallbackPage = () => {
  const { app } = useParams();

  return <>{renderPage(app)}</>;
};
