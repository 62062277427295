import { Button, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { Caution, Delete, Edit } from '@icon-park/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { useDeleteDemandItem } from 'features/demandItem/api/useDeleteDemandItem';
import { buildDemandItemWhere, parseDemandItemCodeForUrl } from 'features/demandItem/models';
import { demandItems_DemandItemDetailPageFragment$key } from 'gql/__generated__/demandItems_DemandItemDetailPageFragment.graphql';
import { demandItems_DemandItemDetailPageQuery } from 'gql/__generated__/demandItems_DemandItemDetailPageQuery.graphql';
import { useEffect, useRef } from 'react';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MissingCategoryError, MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandItemDetailInfo } from './components/DemandItemDetailInfo';
import { DemandItemHistoryMenu } from './components/DemandItemHistoryMenu';

const DemandItemDetailPageQuery = graphql`
  query demandItems_DemandItemDetailPageQuery($where: DemandItemWhereInput!) {
    ...demandItems_DemandItemDetailPageFragment @arguments(where: $where)
  }
`;

const DemandItemDetailPageFragment = graphql`
  fragment demandItems_DemandItemDetailPageFragment on Query
  @refetchable(queryName: "demandItems_DemandItemDetailPageFragmentRefetchQuery")
  @argumentDefinitions(where: {type: "DemandItemWhereInput!"})
  {
    demandItems(where: $where) {
      edges {
        node {
          id
          name
          isCurrent
          orderItems(orderBy: { direction: DESC, field: CREATED_AT }, first: 1) {
            edges {
              node {
                id
              }
            }
          }
          ...DemandItemDetailInfoFragment
          ...DemandItemHistoryMenuFragment
        }
      }
    }
  }
`;

export const DemandItemDetailPage = () => {
  const navigate = useNavigate();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const { demandCode, itemCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }
  if (!itemCode) {
    throw new MissingRequiredParamError('itemCode');
  }

  const { category, itemNumber, version } = parseDemandItemCodeForUrl(itemCode);
  if (!category) {
    throw new MissingCategoryError();
  }

  const { deleteDemandItem, isMutationInFlight } = useDeleteDemandItem();
  const where = buildDemandItemWhere({ category, itemNumber, version });
  const query = useLazyLoadQuery<demandItems_DemandItemDetailPageQuery>(
    DemandItemDetailPageQuery,
    { where },
    {
      fetchPolicy: 'network-only',
    },
  );

  const [{ demandItems }] = useRefetchableFragment(
    DemandItemDetailPageFragment,
    query as demandItems_DemandItemDetailPageFragment$key,
  );

  if (
    !demandItems ||
    !demandItems.edges ||
    demandItems.edges.length === 0 ||
    !demandItems.edges[0]?.node ||
    demandItems.edges[0]?.node === null
  )
    return null;

  const item = demandItems.edges[0].node;
  const hasOrder = item.orderItems.edges?.[0]?.node?.id;

  const handleChangeHistory = (version: string) => {
    // 履歴を選択後、画面の切り替わりが早いとMenuが閉じず、
    // 挙動がおかしくなるため、Menuが閉じるまで遅らせて画面遷移をさせている
    timerRef.current = setTimeout(() => {
      navigate(
        paths.demands
          ._demandCode(demandCode)
          .items._itemCode({ itemNumber, category, version: parseInt(version) })
          .url(),
      );
    }, 100);
  };

  const handleEdit = () =>
    navigate(
      paths.demands._demandCode(demandCode).items._itemCode({ itemNumber, category }).edit.url(),
    );

  const handleDeleteCallback = () => {
    navigate(paths.demands._demandCode(demandCode).url());
  };

  // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <>
      <HStack justify="space-between" width="1024px">
        <RouterLink to={paths.demands._demandCode(demandCode).url()}>
          <PageBack />
        </RouterLink>
        <HStack spacing={4}>
          <ConfirmModalButton
            header="削除しますか？"
            button={({ onOpen }) => (
              <Tooltip
                hasArrow
                label="発注済みのため削除できません"
                isDisabled={!hasOrder}
                placement="top"
              >
                <Button
                  leftIcon={<Delete />}
                  isDisabled={!item.isCurrent || !!hasOrder}
                  onClick={onOpen}
                >
                  削除
                </Button>
              </Tooltip>
            )}
            footer={({ onClose }) => (
              <HStack spacing={3}>
                <Button onClick={onClose} isDisabled={isMutationInFlight}>
                  キャンセル
                </Button>
                <Button
                  colorScheme="red"
                  ml={3}
                  onClick={() => deleteDemandItem(item.id, handleDeleteCallback)}
                  isDisabled={isMutationInFlight}
                >
                  削除
                </Button>
              </HStack>
            )}
          />
          <DemandItemHistoryMenu queryRef={item} onChange={handleChangeHistory} />
          <Button
            colorScheme="blue"
            leftIcon={<Edit />}
            onClick={handleEdit}
            isDisabled={!item.isCurrent}
          >
            編集
          </Button>
        </HStack>
      </HStack>

      {!item.isCurrent && (
        <VStack
          px={4}
          py={3}
          alignItems="flex-start"
          borderWidth={2}
          borderColor="yellow.500"
          borderRadius="6px"
          spacing={1}
        >
          <HStack spacing={2}>
            <Caution theme="outline" size="24" fill="#D69E2E" />
            <Text fontWeight="bold" fontSize="sm">
              過去のバージョンを表示中です
            </Text>
          </HStack>
        </VStack>
      )}
      <SubHeading label={`商品の詳細：${item.name}`} />
      <DemandItemDetailInfo queryRef={item} />
    </>
  );
};
