/**
 * @generated SignedSource<<df2379dc7bdbe39826cbfc2f4bbd8af9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type EstimationUpdateInput = {
  companyID: string;
  detailMessage: string;
  expirationPeriod: string;
  internalAssignees?: ReadonlyArray<EstimationInternalAssigneeInput> | null;
  items: ReadonlyArray<EstimationDetailInput>;
  showAmountSummary: boolean;
  supplierID: string;
  title: string;
};
export type EstimationInternalAssigneeInput = {
  userID: string;
};
export type EstimationDetailInput = {
  documentDisplayStatus?: EstimationDetailDocumentDisplayStatus | null;
  name: string;
  orderNumber: number;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type useEstimationEditMutation$variables = {
  id: string;
  input: EstimationUpdateInput;
};
export type useEstimationEditMutation$data = {
  readonly updateEstimation: {
    readonly id: string;
  };
};
export type useEstimationEditMutation = {
  response: useEstimationEditMutation$data;
  variables: useEstimationEditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Estimation",
    "kind": "LinkedField",
    "name": "updateEstimation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEstimationEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEstimationEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a656b2f32f7f0c35cb664cf8d26d5359",
    "id": null,
    "metadata": {},
    "name": "useEstimationEditMutation",
    "operationKind": "mutation",
    "text": "mutation useEstimationEditMutation(\n  $id: ID!\n  $input: EstimationUpdateInput!\n) {\n  updateEstimation(id: $id, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "745711f6541b345bff626a86cf1da7ed";

export default node;
