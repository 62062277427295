import { Button, HStack } from '@chakra-ui/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { useDownloadSalesOrderPdf } from 'features/salesOrders/hooks/useDownloadSalesOrderPdf';
import { useSalesOrderDelete } from 'features/salesOrders/hooks/useSalesOrderDelete';
import { SalesOrderPreviewHeaderFragment$key } from 'gql/__generated__/SalesOrderPreviewHeaderFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const SalesOrderPreviewHeaderFragment = graphql`
  fragment SalesOrderPreviewHeaderFragment on SalesOrder
  {
    id
    orders {
      id
    }

    invoices {
        id
    }
  }
`;

type Props = {
  queryRef: SalesOrderPreviewHeaderFragment$key;
};

export const SalesOrderPreviewHeader = ({ queryRef }: Props) => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { downloadSalesOrderPdf } = useDownloadSalesOrderPdf();
  const { onSubmit } = useSalesOrderDelete();
  const { orders, invoices } = useFragment(SalesOrderPreviewHeaderFragment, queryRef);

  const order = orders ? orders[0] : null;
  const invoice = invoices ? invoices[0] : null;

  const isDeletable = !order && !invoice;

  return (
    <HStack spacing={4}>
      {isDeletable && (
        <ConfirmModalButton
          header="削除しますか？"
          button={({ onOpen }) => <Button onClick={onOpen}>削除</Button>}
          footer={({ onClose }) => (
            <HStack spacing={3}>
              <Button onClick={onClose}>キャンセル</Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  onClose();
                  onSubmit(id);
                }}
              >
                削除
              </Button>
            </HStack>
          )}
        />
      )}
      <RouterLink to={paths.salesOrder.id(id).edit.url()}>
        <Button colorScheme="blue">編集</Button>
      </RouterLink>
      <Button colorScheme="blue" onClick={() => downloadSalesOrderPdf(id)}>
        PDFダウンロード
      </Button>
    </HStack>
  );
};
