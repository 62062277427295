import { new_OrderNewPageFragment$data } from 'gql/__generated__/new_OrderNewPageFragment.graphql';

export const OrderDetailType = {
  Item: 'item',
} as const;

type SalesOrderType = NonNullable<new_OrderNewPageFragment$data['salesOrders']>;
type SalesOrderTypeEdgesType = NonNullable<SalesOrderType['edges']>;
type SalesOrderEdgeType = NonNullable<SalesOrderTypeEdgesType[number]>;
export type SalesOrderNodeType = NonNullable<SalesOrderEdgeType['node']>;

export type OrderFormType = 'new' | 'edit';

export const RECIPIENT_TYPE = {
  to: 'to',
  cc: 'cc',
} as const;

export type RecipientType = (typeof RECIPIENT_TYPE)[keyof typeof RECIPIENT_TYPE];

export const ORDER_SUBMIT_TYPE = {
  sending: 'sending',
  download: 'download',
} as const;

export type OrderSubmitType = (typeof ORDER_SUBMIT_TYPE)[keyof typeof ORDER_SUBMIT_TYPE];
