// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file model/item/v1/spec.proto (package model.item.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Struct } from "@bufbuild/protobuf";

/**
 * @generated from enum model.item.v1.SortedSpecType
 */
export enum SortedSpecType {
  /**
   * @generated from enum value: SORTED_SPEC_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 誰にでも表示可能な仕様のみ
   *
   * @generated from enum value: SORTED_SPEC_TYPE_PUBLIC = 1;
   */
  PUBLIC = 1,

  /**
   * サプライヤーのみに表示可能な仕様を含める
   *
   * @generated from enum value: SORTED_SPEC_TYPE_SUPPLIER = 2;
   */
  SUPPLIER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SortedSpecType)
proto3.util.setEnumType(SortedSpecType, "model.item.v1.SortedSpecType", [
  { no: 0, name: "SORTED_SPEC_TYPE_UNSPECIFIED" },
  { no: 1, name: "SORTED_SPEC_TYPE_PUBLIC" },
  { no: 2, name: "SORTED_SPEC_TYPE_SUPPLIER" },
]);

/**
 * @generated from message model.item.v1.SpecOnlyItem
 */
export class SpecOnlyItem extends Message<SpecOnlyItem> {
  /**
   * レスポンスとの照合のために設定する
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 仕様のJSON
   *
   * @generated from field: google.protobuf.Struct spec_json = 2;
   */
  specJson?: Struct;

  constructor(data?: PartialMessage<SpecOnlyItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.SpecOnlyItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "spec_json", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpecOnlyItem {
    return new SpecOnlyItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpecOnlyItem {
    return new SpecOnlyItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpecOnlyItem {
    return new SpecOnlyItem().fromJsonString(jsonString, options);
  }

  static equals(a: SpecOnlyItem | PlainMessage<SpecOnlyItem> | undefined, b: SpecOnlyItem | PlainMessage<SpecOnlyItem> | undefined): boolean {
    return proto3.util.equals(SpecOnlyItem, a, b);
  }
}

/**
 * 各仕様を表現する
 *
 * @generated from message model.item.v1.SpecValue
 */
export class SpecValue extends Message<SpecValue> {
  /**
   * 英名
   *
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * ラベル
   *
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * 値
   *
   * @generated from oneof model.item.v1.SpecValue.value
   */
  value: {
    /**
     * @generated from field: int64 int_value = 3;
     */
    value: bigint;
    case: "intValue";
  } | {
    /**
     * @generated from field: double double_value = 4;
     */
    value: number;
    case: "doubleValue";
  } | {
    /**
     * @generated from field: string string_value = 5;
     */
    value: string;
    case: "stringValue";
  } | {
    /**
     * @generated from field: bool bool_value = 6;
     */
    value: boolean;
    case: "boolValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool is_null = 7;
   */
  isNull = false;

  constructor(data?: PartialMessage<SpecValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.SpecValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "int_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
    { no: 4, name: "double_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "value" },
    { no: 5, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 6, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 7, name: "is_null", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpecValue {
    return new SpecValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpecValue {
    return new SpecValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpecValue {
    return new SpecValue().fromJsonString(jsonString, options);
  }

  static equals(a: SpecValue | PlainMessage<SpecValue> | undefined, b: SpecValue | PlainMessage<SpecValue> | undefined): boolean {
    return proto3.util.equals(SpecValue, a, b);
  }
}

/**
 * 順序付きの仕様を表現する
 *
 * @generated from message model.item.v1.SortedSpec
 */
export class SortedSpec extends Message<SortedSpec> {
  /**
   * リクエスト時に設定したID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 整列済みの仕様
   *
   * @generated from field: repeated model.item.v1.SpecValue values = 2;
   */
  values: SpecValue[] = [];

  constructor(data?: PartialMessage<SortedSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "model.item.v1.SortedSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "message", T: SpecValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SortedSpec {
    return new SortedSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SortedSpec {
    return new SortedSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SortedSpec {
    return new SortedSpec().fromJsonString(jsonString, options);
  }

  static equals(a: SortedSpec | PlainMessage<SortedSpec> | undefined, b: SortedSpec | PlainMessage<SortedSpec> | undefined): boolean {
    return proto3.util.equals(SortedSpec, a, b);
  }
}

