// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file service/estimation_response/v1/estimation_response.proto (package service.estimation_response.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetInitialDataByTokenRequest, GetInitialDataByTokenResponse, RegisterRequest, RegisterResponse } from "./estimation_response_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service service.estimation_response.v1.EstimationResponseService
 */
export const EstimationResponseService = {
  typeName: "service.estimation_response.v1.EstimationResponseService",
  methods: {
    /**
     * 見積依頼トークンからアイテムのサマリーを取得する
     *
     * @generated from rpc service.estimation_response.v1.EstimationResponseService.GetInitialDataByToken
     */
    getInitialDataByToken: {
      name: "GetInitialDataByToken",
      I: GetInitialDataByTokenRequest,
      O: GetInitialDataByTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * 見積回答を登録する
     *
     * @generated from rpc service.estimation_response.v1.EstimationResponseService.Register
     */
    register: {
      name: "Register",
      I: RegisterRequest,
      O: RegisterResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

