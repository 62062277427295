// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file service/monthly_checking_report/v1/export.proto (package service.monthly_checking_report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Month } from "../../../model/common/v1/month_pb.js";

/**
 * @generated from message service.monthly_checking_report.v1.ExportRequest
 */
export class ExportRequest extends Message<ExportRequest> {
  /**
   * @generated from field: uint32 year = 1;
   */
  year = 0;

  /**
   * @generated from field: model.common.v1.Month month = 2;
   */
  month = Month.UNSPECIFIED;

  constructor(data?: PartialMessage<ExportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.monthly_checking_report.v1.ExportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "month", kind: "enum", T: proto3.getEnumType(Month) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportRequest {
    return new ExportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportRequest {
    return new ExportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportRequest {
    return new ExportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExportRequest | PlainMessage<ExportRequest> | undefined, b: ExportRequest | PlainMessage<ExportRequest> | undefined): boolean {
    return proto3.util.equals(ExportRequest, a, b);
  }
}

/**
 * @generated from message service.monthly_checking_report.v1.ExportResponse
 */
export class ExportResponse extends Message<ExportResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<ExportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "service.monthly_checking_report.v1.ExportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportResponse {
    return new ExportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportResponse {
    return new ExportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportResponse {
    return new ExportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ExportResponse | PlainMessage<ExportResponse> | undefined, b: ExportResponse | PlainMessage<ExportResponse> | undefined): boolean {
    return proto3.util.equals(ExportResponse, a, b);
  }
}

