import { createStandaloneToast } from '@chakra-ui/react';

const { ToastContainer, toast } = createStandaloneToast({
  defaultOptions: {
    position: 'bottom-right',
    isClosable: true,
  },
});

export { ToastContainer, toast };
