/**
 * @generated SignedSource<<f6cecaaac06ab729a339e8d7b67f0a4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type initializeQuery$variables = {};
export type initializeQuery$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber: string | null;
    readonly userID: string;
  };
};
export type initializeQuery = {
  response: initializeQuery$data;
  variables: initializeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CurrentUser",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "initializeQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "initializeQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "26b66863f34a63c57a121f26cfdacddc",
    "id": null,
    "metadata": {},
    "name": "initializeQuery",
    "operationKind": "query",
    "text": "query initializeQuery {\n  currentUser {\n    userID\n    firstName\n    lastName\n    phoneNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "824a7a1d65c5bdeca84ae772bc1c7805";

export default node;
