/**
 * @generated SignedSource<<819744e38e2c5d21ea3187078b242bad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type InvoiceUpdateInput = {
  bookedAt: any;
  detailMessage: string;
  details: ReadonlyArray<UpdateInvoiceDetailInput>;
  internalMemo?: string | null;
  paymentDueAt: any;
  title: string;
};
export type UpdateInvoiceDetailInput = {
  Item?: InvoiceItemInput | null;
  invoiceDetailID?: string | null;
  orderNumber: number;
  salesDate?: any | null;
  salesOrderDetailID?: string | null;
  type: InvoiceDetailType;
};
export type InvoiceItemInput = {
  name: string;
  quantity: number;
  taxCategoryID: string;
  unitSellingPrice: number;
};
export type useInvoiceEditFormMutation$variables = {
  id: string;
  input: InvoiceUpdateInput;
};
export type useInvoiceEditFormMutation$data = {
  readonly updateInvoice: {
    readonly id: string;
  };
};
export type useInvoiceEditFormMutation = {
  response: useInvoiceEditFormMutation$data;
  variables: useInvoiceEditFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "updateInvoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useInvoiceEditFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useInvoiceEditFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9a3479ddef40c0ae7b4f6c1bc359ad9c",
    "id": null,
    "metadata": {},
    "name": "useInvoiceEditFormMutation",
    "operationKind": "mutation",
    "text": "mutation useInvoiceEditFormMutation(\n  $id: ID!\n  $input: InvoiceUpdateInput!\n) {\n  updateInvoice(id: $id, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "40f84112159188e3d0cf1b449e948d7b";

export default node;
