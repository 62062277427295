import {
  Box,
  Button,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import {
  INVOICE_FORM_TYPE,
  InvoiceFormType,
  InvoiceInputFormValueType,
} from 'features/invoice/form';
import { FormType } from 'features/invoice/type';
import { InvoiceConfirmQuery } from 'gql/__generated__/InvoiceConfirmQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';
import { buildDetailName } from '../helper';

type Props = {
  type?: FormType;
  demand: string;
  values: InvoiceInputFormValueType;
  toInputForm: () => void;
  onSubmit: () => void;
  isMutationInFlight: boolean;
};

const invoiceConfirmQuery = graphql`
  query InvoiceConfirmQuery  ($salesOrderIds: [ID!]){
    salesOrderDetails(where: {idIn: $salesOrderIds}) {
      edges {
        node {
          id
          branchNumber
          salesOrder {
            transactionID
          }
        }
      }
    }
  }
`;

export const InvoiceConfirm = ({
  type = INVOICE_FORM_TYPE.new,
  demand,
  values,
  isMutationInFlight,
  toInputForm,
  onSubmit,
}: Props) => {
  const { salesOrderDetails } = useLazyLoadQuery<InvoiceConfirmQuery>(
    invoiceConfirmQuery,
    {
      salesOrderIds: values.details
        .map((detail) => detail.salesOrderDetailId || null)
        .filter((v): v is NonNullable<typeof v> => v != null),
    },
    { fetchPolicy: 'network-only' },
  );

  const salesOrderInfo = new Map<string, { branchNumber: string; transactionId: string }>();
  (salesOrderDetails.edges || []).map((detail) => {
    if (detail && detail.node) {
      salesOrderInfo.set(detail.node.id, {
        branchNumber: detail.node.branchNumber,
        transactionId: detail.node.salesOrder.transactionID,
      });
    }
  });

  return (
    <VStack spacing={6} alignItems="flex-start">
      <PageBack onClick={toInputForm} />
      <VStack spacing={4} alignItems="flex-start">
        <SubHeading label="内容の確認" />
        <Text color="gray.500">{getDescription(type)}</Text>
      </VStack>
      <VStack spacing={6} alignItems="flex-start">
        <VStack spacing={1} alignItems="flex-start">
          <Box color="gray.500">デマンド</Box>
          <Box>{demand}</Box>
        </VStack>
        <VStack spacing={1} alignItems="flex-start">
          <Box color="gray.500">件名</Box>
          <Box>{values.title}</Box>
        </VStack>
        <HStack spacing={6}>
          <VStack spacing={1} alignItems="flex-start">
            <Box color="gray.500">請求日</Box>
            <Box>{dateFormat(values.dates.bookedAt)}</Box>
          </VStack>
          <VStack spacing={1} alignItems="flex-start">
            <Box color="gray.500">振込期日</Box>
            <Box>{dateFormat(values.dates.paymentDueAt)}</Box>
          </VStack>
        </HStack>
      </VStack>

      <Box w="768px">
        <Table>
          <Thead>
            <Tr>
              <Th color="gray.700" w="100%" pl="0">
                品名
              </Th>
              <Th color="gray.700" w="40rem" whiteSpace="nowrap">
                取引日
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                単価
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {values.details.length > 0 &&
              values.details.map((detail) => {
                const info = salesOrderInfo.get(detail.salesOrderDetailId || '');
                return (
                  <Tr key={detail.salesOrderDetailId} verticalAlign="top">
                    <Td pl="0" whiteSpace="pre-wrap">
                      {buildDetailName({ name: detail.name, info })}
                    </Td>
                    <Td color="gray.700" whiteSpace="nowrap">
                      {detail.salesDate ? dateFormat(detail.salesDate) : '-'}
                    </Td>
                    <Td isNumeric color="gray.700">
                      {detail?.quantity?.toLocaleString() || '-'}
                    </Td>
                    <Td isNumeric color="gray.700">
                      {detail?.unitSellingPrice?.toLocaleString() || '-'}
                    </Td>
                    <Td isNumeric color="gray.700" pr="0">
                      {calculateDetailAmount({
                        quantity: detail?.quantity || 0,
                        price: detail?.unitSellingPrice || 0,
                      }).toLocaleString()}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
      <HStack pb="1rem" justify="space-between" align="flex-start" w="768px">
        <VStack spacing={2} w="400px" align="start">
          <VStack spacing={1} align="start">
            <Text fontWeight="bold">備考</Text>
            <Text whiteSpace="pre-wrap">{values.detailMessage || '-'}</Text>
          </VStack>
          <VStack spacing={1} align="start">
            <Text fontWeight="bold">社内メモ</Text>
            <Text whiteSpace="pre-wrap">{values.memo || '-'}</Text>
          </VStack>
        </VStack>
        <VStack align="left" spacing={2} w="240px">
          <Flex mt={2} justify="space-between">
            <Box w="140px" fontSize="sm" whiteSpace="nowrap">
              小計
            </Box>
            <Box fontSize="sm">
              {calculateSubtotalAmount([
                ...(values.details || []).map((detail) => ({
                  unitSellingPrice: detail.unitSellingPrice || 0,
                  quantity: detail?.quantity || 0,
                })),
              ]).toLocaleString()}
            </Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box whiteSpace="nowrap" w="140px" fontSize="sm">
              消費税
            </Box>
            <Box fontSize="sm">
              {calculateTotalTaxAmount([
                ...(values.details || []).map((detail) => ({
                  unitSellingPrice: detail.unitSellingPrice || 0,
                  quantity: detail?.quantity || 0,
                  tax: {
                    rate: detail?.tax.rate,
                  },
                })),
              ]).toLocaleString()}
            </Box>
          </Flex>
          <Flex mt={4} justify="space-between">
            <Box w="140px" fontWeight="bold" whiteSpace="nowrap">
              合計金額
            </Box>
            <Box fontWeight="bold">
              {calculateTotalAmount([
                ...(values.details || []).map((detail) => ({
                  unitSellingPrice: detail.unitSellingPrice || 0,
                  quantity: detail?.quantity || 0,
                  tax: {
                    rate: detail?.tax.rate,
                  },
                })),
              ]).toLocaleString()}
            </Box>
          </Flex>
        </VStack>
      </HStack>

      <Box w="768px">
        <Button
          colorScheme="blue"
          w="full"
          type="submit"
          onClick={onSubmit}
          isDisabled={isMutationInFlight}
          isLoading={isMutationInFlight}
          loadingText={`${getSubmitButtonLabel(type)}中`}
        >
          {getSubmitButtonLabel(type)}
        </Button>
      </Box>
    </VStack>
  );
};

const getSubmitButtonLabel = (type: InvoiceFormType) => {
  switch (type) {
    case INVOICE_FORM_TYPE.new:
      return '作成';
    case INVOICE_FORM_TYPE.edit:
      return '更新';
  }
};

const getDescription = (type: InvoiceFormType) => {
  switch (type) {
    case INVOICE_FORM_TYPE.new:
      return '下記の内容でfreeeに請求書を作成します';
    case INVOICE_FORM_TYPE.edit:
      return '下記の内容でfreeeに請求書を更新します';
  }
};
