import { PartialMessage, Struct } from '@bufbuild/protobuf';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Delete, Paperclip } from '@icon-park/react';
import { AttachmentFileButton } from 'components/AttachmentFileButton';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { ErrorMessage } from 'components/ErrorMessage';
import { FileUploadButton } from 'components/FileUploadButton';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { useLiveQuery } from 'dexie-react-hooks';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import { EstimationRequestFormState } from 'features/estimationRequest/hooks/useEstimationRequestForm';
import { useQueryParams } from 'features/estimationRequest/hooks/useEstimationRequestQueryParams';
import {
  ZodEstimationRequestItem,
  ZodEstimationRequestNewFormData,
  createEstimationRequestSchema,
} from 'features/estimationRequest/zod';
import { ItemTable } from 'features/item/components/ItemTable';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { usePreventNavigation } from 'hooks/usePreventNavigation';
import { useSession } from 'hooks/useSession';
import { toast } from 'lib/toast';
import { RawItem } from 'proto/model/item/v1/summarize_item_pb';
import { ItemService } from 'proto/service/item/v1/item_connect';
import { SummarizeItemsResponse } from 'proto/service/item/v1/item_pb';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
  addEstimationRequestDraft,
  getEstimationRequestDraftCount,
  updateEstimationRequestDraft,
} from 'repositories/estimationRequestDraftRepository';
import { captureException } from 'utils/error';
import { mergeFileList } from 'utils/file';
import { formatBytes } from 'utils/number';
import { paths } from 'utils/paths';
import { EstimationRequestSupplierForm } from '../EstimationRequestSupplierForm';
import { ExcelFileUploadButton } from '../ExcelFileUploadButton';

const MIN_TEXTAREA_ROWS = 3;
const DEFAULT_WIDTH = 480;

export function EstimationRequestForm({
  data,
  onClickToConfirm,
  previousUrl,
}: {
  data: EstimationRequestFormState;
  onClickToConfirm: (data: EstimationRequestFormState) => void;
  previousUrl?: string;
}) {
  const { queryParams } = useQueryParams();
  const navigate = useNavigate();
  const { currentUser } = useSession();
  const {
    register,
    control,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm<ZodEstimationRequestNewFormData>({
    resolver: zodResolver(createEstimationRequestSchema),
    defaultValues: data,
  });
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { grpcClient, authorized } = useGrpcClient(ItemService);
  const [summarizedItems, setSummarizedItems] = useState<PartialMessage<SummarizeItemsResponse>>({
    items: [],
  });

  const demandId = watch('company').id;

  usePreventNavigation(isFormChanged);

  const onSubmit = (data: EstimationRequestFormState) => {
    onClickToConfirm(data);
  };

  const onChangeAttachment = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = mergeFileList(getValues('attachments'), event.target.files);
    setValue('attachments', newFiles, { shouldDirty: true });
  };

  const onDeleteAttachment = (index: number) => {
    const dataTransfer = new DataTransfer();
    const attachments = getValues('attachments');
    for (let i = 0; i < Object.keys(attachments).length; i++) {
      if (i !== index) dataTransfer.items.add(attachments?.[i]);
    }
    setValue('attachments', dataTransfer.files, { shouldDirty: true });
  };

  const onClearCompanyInput = () => setValue('company', { id: '', name: '' });

  const attachments = useWatch({ name: 'attachments', control });

  const handleUploaded = (items: ZodEstimationRequestItem[]) => {
    setValue('items', items, { shouldDirty: true });

    clearErrors('items');
  };

  const handleUploadedError = (message: string) => {
    setError('items', { type: 'custom', message });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    (async () => {
      if (!authorized) return;

      let unmounted = false;
      const items = getValues('items');
      if (items.length === 0) return;

      const rawItems = items.map((item): PartialMessage<RawItem> => {
        const specJson = Object.fromEntries(item.specJson || []);
        return {
          name: '',
          quantity: Number(item.quantity),
          specJson: Struct.fromJson(specJson),
        };
      });

      const summary = await grpcClient.summarizeItems({ items: rawItems });
      summary.items = summary.items.map((item) => {
        item.columns = item.columns.filter((col) => col.key !== 'unitPrice');
        return item;
      });

      if (unmounted) return;
      setSummarizedItems(summary);

      return () => {
        unmounted = true;
      };
    })();
  }, [getValues('items'), authorized]);

  const handleAddDraft = async () => {
    setIsFormChanged(false);
    try {
      await addEstimationRequestDraft(getValues());
      toast({
        title: '下書き保存が完了しました',
        status: 'success',
      });
      navigate(paths.estimationRequest.url());
    } catch (error) {
      toast({
        title: '下書きの保存に失敗しました',
        status: 'error',
      });
      captureException(error as Error);
    }
  };

  const handleUpsertDraft = async (onClose: () => void) => {
    setIsFormChanged(false);
    try {
      await updateEstimationRequestDraft(getValues());
      toast({
        title: '下書き保存が完了しました',
        status: 'success',
      });
      navigate(paths.estimationRequest.url());
    } catch (error) {
      toast({
        title: '下書きの保存に失敗しました',
        status: 'error',
      });
      onClose();
      captureException(error as Error);
    }
  };

  const itemSpecTemplateCopyUrl = new URL('https://docs.google.com');
  itemSpecTemplateCopyUrl.pathname = `/spreadsheets/d/${
    import.meta.env.VITE_ITEM_SPEC_TEMPLATE_SHEET_ID
  }/copy`;
  itemSpecTemplateCopyUrl.searchParams.set('id', import.meta.env.VITE_ITEM_SPEC_TEMPLATE_SHEET_ID);
  itemSpecTemplateCopyUrl.searchParams.set('copyCollaborators', 'true');
  itemSpecTemplateCopyUrl.searchParams.set('copyComments', 'false');
  itemSpecTemplateCopyUrl.searchParams.set('includeResolvedCommentsOnCopy', 'false');
  itemSpecTemplateCopyUrl.searchParams.set(
    'copyDestination',
    import.meta.env.VITE_ITEM_SPEC_DST_FOLDER_ID,
  );
  if (currentUser?.email) {
    // shizaiユーザーのアカウントで開くようにパラメータを設定
    itemSpecTemplateCopyUrl.searchParams.set('authuser', currentUser?.email);
  }

  useEffect(() => {
    if (isDirty) {
      setIsFormChanged(true);
    }
  }, [isDirty]);

  return (
    <>
      <Box mb={6}>
        <Link
          to={
            previousUrl ??
            paths.estimationRequest.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              supplier: queryParams.supplier,
              searchWord: queryParams.searchWord,
            })
          }
        >
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label="見積依頼の作成" />
      </Box>
      <Box w="100%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={6} alignItems="flex-start">
            <FormControl isInvalid={!!errors.company} isRequired w={DEFAULT_WIDTH}>
              <VStack alignItems="stretch" w="full" spacing={0}>
                <FormLabel>デマンド</FormLabel>
                <Controller
                  name="company"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <CompanyComboBox
                      onChangeSelected={onChange}
                      defaultSelectedItem={getValues('company')}
                      onClearInput={onClearCompanyInput}
                    />
                  )}
                />
                <ErrorMessage name="company.id" errors={errors} />

                <DemandMemo demandId={demandId} />
              </VStack>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.requestTitle} w={DEFAULT_WIDTH}>
              <FormLabel>案件名</FormLabel>
              <Input type="string" {...register('requestTitle')} />
              <ErrorMessage name="requestTitle" errors={errors} />
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.mailSubject} w={DEFAULT_WIDTH}>
              <FormLabel>メール件名</FormLabel>
              <Input type="string" {...register('mailSubject')} />
              <ErrorMessage name="mailSubject" errors={errors} />
            </FormControl>

            <EstimationRequestSupplierForm
              suppliers={getValues('suppliers')}
              onChange={(suppliers) => {
                setValue('suppliers', suppliers, { shouldDirty: true });
              }}
              errors={errors.suppliers}
            />

            <VStack alignItems="stretch" spacing={4}>
              <HStack alignItems="center" spacing={2} w={DEFAULT_WIDTH}>
                <CustomFormLabel isRequired>見積条件</CustomFormLabel>
                <Button
                  as="a"
                  href={itemSpecTemplateCopyUrl.toString()}
                  target="_blank"
                  size="sm"
                  rel="noopener noreferrer"
                >
                  テンプレートを開く
                </Button>
              </HStack>

              <FormControl isInvalid={!!errors.items} w={DEFAULT_WIDTH}>
                <ExcelFileUploadButton
                  register={register}
                  name="items"
                  onUploaded={handleUploaded}
                  onError={handleUploadedError}
                />
                <ErrorMessage name="items" errors={errors} />
              </FormControl>

              <VStack alignItems="start" w="100%">
                {(summarizedItems.items || []).map((item, index) => (
                  <Box key={item.category}>
                    <ItemTable
                      key={`item-table-${index}`}
                      readonly
                      items={summarizedItems.items || []}
                      index={index}
                    />
                  </Box>
                ))}
              </VStack>
            </VStack>

            <FormControl isRequired isInvalid={!!errors.specText} w={DEFAULT_WIDTH}>
              <FormLabel>その他の条件</FormLabel>
              <Textarea
                {...register('specText')}
                rows={
                  getValues('specText').split(/\n/).length > MIN_TEXTAREA_ROWS
                    ? getValues('specText').split(/\n/).length
                    : MIN_TEXTAREA_ROWS
                }
              />
              <Text color="gray.500" mt={2}>
                必要に応じて項目を調整し、記入を行いましょう
              </Text>
              <ErrorMessage name="specText" errors={errors} />
            </FormControl>

            {attachments?.length > 0 && (
              <Box w={DEFAULT_WIDTH}>
                {Array.from(attachments).map((attachment, index) => (
                  <HStack justify="space-between" key={`attachment-${index}`} my={2}>
                    <AttachmentFileButton
                      name={attachment.name}
                      size={formatBytes(attachment.size)}
                    />
                    <Button
                      variant="ghost"
                      borderRadius={50}
                      w={10}
                      h={10}
                      mt={index === 0 ? 8 : 0}
                      onClick={() => onDeleteAttachment(index)}
                    >
                      <Delete color="gray.700" />
                    </Button>
                  </HStack>
                ))}
              </Box>
            )}
            <FileUploadButton
              onChange={onChangeAttachment}
              name="attachments"
              register={register}
              errors={errors.attachments}
              label="データ添付"
              leftIcon={<Paperclip />}
            />

            <FormControl w={DEFAULT_WIDTH} isInvalid={!!errors.internalAssignees} isRequired>
              <FormLabel>担当者</FormLabel>
              <Controller
                name="internalAssignees"
                control={control}
                render={({ field: { onChange } }) => (
                  <UserMultipleSelectBox
                    onChange={onChange}
                    defaultValue={getValues('internalAssignees')}
                    menuPlacement="top"
                  />
                )}
              />
              <ErrorMessage name="internalAssignees" errors={errors} />
            </FormControl>

            <HStack w={DEFAULT_WIDTH} spacing={6}>
              <AddDraftButton onUpsertDraft={handleUpsertDraft} onAddDraft={handleAddDraft} />
              <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
                確認画面へ進む
              </Button>
            </HStack>
          </VStack>
        </form>
      </Box>
    </>
  );
}

const AddDraftButton = ({
  onUpsertDraft,
  onAddDraft,
}: { onUpsertDraft: (onClose: () => void) => void; onAddDraft: () => void }) => {
  const count = useLiveQuery(() => getEstimationRequestDraftCount());

  return (
    <>
      {(count || 0) > 0 ? (
        <ConfirmModalButton
          header="すでに下書きが存在します"
          body={
            <Text>
              下書き保存できるのは1件のみです。
              <br />
              保存されている下書きを削除して上書きしますか？
            </Text>
          }
          button={({ onOpen }) => (
            <Button minW="112px" onClick={onOpen}>
              下書き保存
            </Button>
          )}
          footer={({ onClose }) => (
            <HStack spacing={3}>
              <Button onClick={onClose}>キャンセル</Button>
              <Button colorScheme="blue" ml={3} onClick={() => onUpsertDraft(onClose)}>
                上書き保存
              </Button>
            </HStack>
          )}
        />
      ) : (
        <Button minW="112px" onClick={onAddDraft}>
          下書き保存
        </Button>
      )}
    </>
  );
};
