import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { InformationShizai } from 'components/InformationShizai';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { FormType } from 'features/estimation/type';
import { filterByDocumentDisplayStatusShown } from 'features/estimation/util';
import { ZodEstimationFormData } from 'features/estimation/zod';
import { EstimationConfirmFragment$key } from 'gql/__generated__/EstimationConfirmFragment.graphql';
import { EstimationConfirmQuery } from 'gql/__generated__/EstimationConfirmQuery.graphql';
import { Fragment } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { getToday } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';
import { ItemPriceList } from '../../../../components/ItemPriceList';
import { deduplicateAdjacentNames } from '../helper';

const getSubmitButtonLabel = (type: FormType) => {
  switch (type) {
    case 'new':
      return '作成してダウンロード';
    case 'edit':
      return '編集してダウンロード';
  }
};

const estimationConfirmQuery = graphql`
  query EstimationConfirmQuery ($companyId: ID!) {
    ...EstimationConfirmFragment
  }
`;

const estimationConfirmFragment = graphql`
  fragment EstimationConfirmFragment on Query {
    companies(where: {id: $companyId}) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const EstimationConfirm = ({
  type = 'new',
  data,
  onClickSubmit,
  isMutationInFlight,
  onClickToNewForm,
}: {
  type?: FormType;
  data: ZodEstimationFormData;
  onClickSubmit: () => void;
  isMutationInFlight: boolean;
  onClickToNewForm: () => void;
}) => {
  const query = useLazyLoadQuery<EstimationConfirmQuery>(
    estimationConfirmQuery,
    {
      companyId: data.company.id,
    },
    { fetchPolicy: 'network-only' },
  );
  const { companies } = useFragment(
    estimationConfirmFragment,
    query as EstimationConfirmFragment$key,
  );

  const handleSubmit = () => onClickSubmit();

  const shownDetails = filterByDocumentDisplayStatusShown(data.details);
  const items = deduplicateAdjacentNames(shownDetails);

  const detailsForCalculation = shownDetails.map((detail) => ({
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    quantity: detail.quantity || 0,
    tax: detail.tax || 0,
  }));

  return (
    <>
      <Box mb={6}>
        <PageBack onClick={onClickToNewForm} />
      </Box>
      <Box mb={6}>
        <SubHeading label="内容の確認" />
      </Box>
      <Box w="768px" mb="1.5rem">
        <Text mb="5px">件名</Text>
        <Text>{data.title}</Text>
      </Box>
      <Box w="768px">
        <VStack spacing={6}>
          <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full">
            <VStack align="stretch" spacing={0}>
              <HStack align="end" pb="1rem" justify="space-between">
                <SubHeading label="見積書" />
                <HStack spacing={4}>
                  <Text fontSize="sm">{getToday()}</Text>
                </HStack>
              </HStack>
              <Divider />
            </VStack>
            <HStack pb="1rem" justify="space-between" align="flex-start">
              <VStack spacing={2} w="320px" align="start">
                {companies.edges?.map((edge, i) => (
                  <Text fontSize="xl" fontWeight="bold" key={`company-${edge?.node?.id}-${i}`}>
                    {edge?.node?.name} 御中
                  </Text>
                ))}
                <Text fontSize="sm">有効期限：{data.expirationPeriod}</Text>
                <Text color="gray.500" fontSize="sm">
                  下記のとおりお見積申し上げます
                </Text>
                <VStack spacing={1} w="full" h={41}>
                  {data.showAmountSummary && (
                    <>
                      <HStack align="end" justify="space-between" w="full">
                        <Text>お見積金額</Text>
                        <HStack spacing={1} align="end">
                          <Text fontSize="2xl" fontWeight="bold">
                            {calculateTotalAmount(detailsForCalculation).toLocaleString()}
                          </Text>
                          <Text>円</Text>
                        </HStack>
                      </HStack>
                      <Divider borderColor="gray.700" />
                    </>
                  )}
                </VStack>
              </VStack>
              <Box w="320px">
                <InformationShizai />
              </Box>
            </HStack>
            <VStack align="start" justify="space-between">
              <Table>
                <Thead>
                  <Tr>
                    <Th color="gray.700" w="100%" pl="0">
                      品名
                    </Th>
                    <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                      数量
                    </Th>
                    <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                      単価
                    </Th>
                    <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
                      金額 (税抜)
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((item, i) => (
                    <Tr key={`detail-${item.name}-${i}`} verticalAlign="top">
                      <Td pl="0" whiteSpace="pre-wrap">
                        {item.name}
                      </Td>
                      <Td isNumeric color="gray.700">
                        {detailsForCalculation[i].quantity.toLocaleString()}
                      </Td>
                      <Td isNumeric color="gray.700">
                        {detailsForCalculation[i].unitSellingPrice.toLocaleString()}
                      </Td>
                      <Td isNumeric color="gray.700" pr="0">
                        {calculateDetailAmount({
                          quantity: detailsForCalculation[i].quantity,
                          price: detailsForCalculation[i].unitSellingPrice,
                        }).toLocaleString()}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </VStack>
            <HStack pb="1rem" justify="space-between" align="flex-start">
              <VStack spacing={2} w="400px" align="start">
                <Text>備考</Text>
                <Text whiteSpace="pre-wrap">{data.detailMessage}</Text>
              </VStack>
              {data.showAmountSummary && (
                <VStack align="left" spacing={2} w="240px">
                  <Flex mt={2} justify="space-between">
                    <Box w="140px" fontSize="sm">
                      小計
                    </Box>
                    <Box fontSize="sm">
                      {calculateSubtotalAmount(detailsForCalculation).toLocaleString()}
                    </Box>
                  </Flex>
                  <Flex mt={4} justify="space-between">
                    <Box whiteSpace="nowrap" w="140px" fontSize="sm">
                      消費税
                    </Box>
                    <Box fontSize="sm">
                      {calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}
                    </Box>
                  </Flex>
                  <Flex mt={4} justify="space-between">
                    <Box w="140px" fontWeight="bold">
                      合計金額
                    </Box>
                    <Box fontWeight="bold">
                      {calculateTotalAmount(detailsForCalculation).toLocaleString()}
                    </Box>
                  </Flex>
                </VStack>
              )}
            </HStack>
          </VStack>
          <Box w="768px" mb={6}>
            <Box color="gray.500" mb={1}>
              担当者
            </Box>
            <HStack wrap="wrap" spacing={0}>
              {(data.internalAssignees || []).map((assignee, index) => (
                <Fragment key={assignee.value}>
                  {index !== 0 && (
                    <Box color="gray.200" px={2}>
                      /
                    </Box>
                  )}
                  <HStack>
                    <Box whiteSpace="nowrap">{assignee.label}</Box>
                  </HStack>
                </Fragment>
              ))}
            </HStack>
          </Box>
          <Button
            colorScheme="blue"
            w="full"
            type="button"
            isDisabled={isMutationInFlight}
            onClick={handleSubmit}
          >
            {getSubmitButtonLabel(type)}
          </Button>
          <ItemPriceList
            details={data.details.map((detail) => ({
              name: detail.name,
              unitPrice: Number(detail.unitPrice) || 0,
              unitSellingPrice: Number(detail.unitSellingPrice) || 0,
              quantity: Number(detail.quantity) || 0,
            }))}
          />
        </VStack>
      </Box>
    </>
  );
};
