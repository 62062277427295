import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';

export const EstimationResponseError = () => (
  <>
    <Box mb={6}>
      <PageHeading label="データの取得に失敗しました" />
    </Box>
    <Text color="gray.500">エラーが発生しました。以下の原因が考えられます</Text>
    <UnorderedList color="gray.500" my={4}>
      <ListItem>URLが間違っている</ListItem>
      <ListItem>見積依頼が存在しない、依頼者によって取り消された</ListItem>
    </UnorderedList>
    <Text color="gray.500">解決しない場合は依頼者に問い合わせてください</Text>
  </>
);
