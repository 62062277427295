/**
 * @generated SignedSource<<4ea479468fcced12f3c500f72ec9ef56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type demandItems_DemandItemDetailPageFragment$data = {
  readonly demandItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isCurrent: boolean;
        readonly name: string;
        readonly orderItems: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null> | null;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailInfoFragment" | "DemandItemHistoryMenuFragment">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "demandItems_DemandItemDetailPageFragment";
};
export type demandItems_DemandItemDetailPageFragment$key = {
  readonly " $data"?: demandItems_DemandItemDetailPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"demandItems_DemandItemDetailPageFragment">;
};

import demandItems_DemandItemDetailPageFragmentRefetchQuery_graphql from './demandItems_DemandItemDetailPageFragmentRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": demandItems_DemandItemDetailPageFragmentRefetchQuery_graphql
    }
  },
  "name": "demandItems_DemandItemDetailPageFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "DemandItemConnection",
      "kind": "LinkedField",
      "name": "demandItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DemandItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DemandItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCurrent",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    },
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": {
                        "direction": "DESC",
                        "field": "CREATED_AT"
                      }
                    }
                  ],
                  "concreteType": "OrderItemConnection",
                  "kind": "LinkedField",
                  "name": "orderItems",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderItemEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OrderItem",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "orderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DemandItemDetailInfoFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DemandItemHistoryMenuFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e8437d32aad8812e0f2823944a26b78a";

export default node;
