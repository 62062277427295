import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { useEffect, useState } from 'react';
import { useSession } from './useSession';

/**
 * ユーザー行動のトラッキングを行う(LogRocket)
 * @returns
 */
export const useUserTracking = () => {
  const { currentUser: user } = useSession();
  const [initialized, setInitialized] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!import.meta.env.VITE_LOGROCKET_APP_ID) {
      return;
    }

    if (!initialized) {
      LogRocket.init(import.meta.env.VITE_LOGROCKET_APP_ID);
      setInitialized(true);
    }

    if (user) {
      LogRocket.identify(user.uid, {
        name: user.displayName || 'Annonymous',
        email: user.email || 'No email',
      });
    }

    LogRocket.getSessionURL((sessionURL) => {
      Sentry.getCurrentScope().setTag('logRocketSessionURL', sessionURL);
    });
  }, [user]);

  return null;
};
