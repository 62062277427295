import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { CustomFormHelperText } from 'components/CustomFormHelperText';
import { ErrorMessage } from 'components/ErrorMessage';
import { DemandRecurringCostFormType } from 'features/demandRecurringCost/form';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { DemandRecurringCostFormFragment$key } from 'gql/__generated__/DemandRecurringCostFormFragment.graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { DemandRecurringCostPriceForm } from './DemandRecurringCostPriceForm';

type Props = {
  queryRef: DemandRecurringCostFormFragment$key;
  navigateToConfirm: () => void;
};

const demandRecurringCostFormFragment = graphql`
  fragment DemandRecurringCostFormFragment on Query {
    taxCategories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const DemandRecurringCostForm = ({ queryRef, navigateToConfirm }: Props) => {
  const { taxCategories } = useFragment(demandRecurringCostFormFragment, queryRef);

  const form = useFormContext<DemandRecurringCostFormType>();

  const {
    control,
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = form;

  const onClearSupplierInput = () => setValue('supplier', { id: '', name: '' });

  if (
    !taxCategories ||
    !taxCategories.edges ||
    taxCategories.edges.length === 0 ||
    !taxCategories.edges[0]?.node ||
    taxCategories.edges[0]?.node === null
  )
    return null;

  const taxes = taxCategories.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  return (
    <form onSubmit={handleSubmit(navigateToConfirm)}>
      <VStack spacing={10} alignItems="flex-start" width="100%">
        <VStack spacing={4} alignItems="flex-start" width="512px">
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>費用名</FormLabel>
            <Input type="string" {...register('name')} />
            <ErrorMessage name="name" errors={errors} />
          </FormControl>
          <FormControl isInvalid={!!errors.supplier?.id}>
            <FormLabel>サプライヤー</FormLabel>
            <Controller
              name="supplier"
              control={control}
              render={({ field: { onChange } }) => (
                <SupplierComboBox
                  onChangeSelected={onChange}
                  defaultSelectedItem={
                    getValues('supplier') && getValues('supplier').id && getValues('supplier').name
                      ? {
                          id: getValues('supplier').id as string,
                          name: getValues('supplier').name as string,
                        }
                      : { id: '', name: '' }
                  }
                  onClearInput={onClearSupplierInput}
                />
              )}
            />
            <ErrorMessage name="supplier.id" errors={errors} />
          </FormControl>
          <FormControl isInvalid={!!errors.memo}>
            <FormLabel>社内メモ</FormLabel>
            <Textarea {...register('memo')} />
            <ErrorMessage name="memo" errors={errors} />
            <CustomFormHelperText>リピート時の注意点などを記載しましょう</CustomFormHelperText>
          </FormControl>
          <FormControl isInvalid={!!errors.note}>
            <FormLabel>補足</FormLabel>
            <Input type="string" {...register('note')} />
            <ErrorMessage name="note" errors={errors} />
            <CustomFormHelperText>
              書類に記載されるため、デマンドに伝えたい内容に限定しましょう
            </CustomFormHelperText>
          </FormControl>
        </VStack>

        <VStack spacing={4} alignItems="flex-start" width="512px">
          <Heading as="h3" fontSize="xl">
            単価表
          </Heading>
          <Box w="168px">
            <FormControl isRequired isInvalid={!!errors.taxId}>
              <FormLabel>税区分</FormLabel>
              <Select {...register('taxId')}>
                {taxes.map((tax) => (
                  <option key={tax.id} value={tax.id}>
                    {tax.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <DemandRecurringCostPriceForm />
        </VStack>

        <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
          確認画面へ
        </Button>
      </VStack>
    </form>
  );
};
