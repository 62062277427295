import { Box, Container, Img } from '@chakra-ui/react';
import ShizaiLogoBlack from 'public/images/shizai_logo_black.svg';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement;
};

export const EstimationResponseLayout = ({ children }: Props) => (
  <Box background="gray.50" minH="100vh">
    <Container maxW="720px" bg="White" minH="100vh" px={10} pb={10}>
      <Box as="header" py={5} mb={10}>
        <Img src={ShizaiLogoBlack} alt="shizai logo" />
      </Box>
      {children}
    </Container>
  </Box>
);
