import { Box, HStack } from '@chakra-ui/react';
import { EfferentFour } from '@icon-park/react';
import { Link } from 'components/Link';
import { Loading } from 'components/Loading';
import { useDeleteDemandItem } from 'features/demandItem/api/useDeleteDemandItem';
import {
  DemandItemDetailBlock,
  DemandItemDetailBlockBody,
  DemandItemDetailBlockFooter,
  DemandItemDetailBlockInfo,
} from 'features/demandItem/components/DemandItemDetailBlock';
import { DemandItemPriceTable } from 'features/demandItem/components/DemandItemPriceTable';
import {
  DEMAND_ITEM_CATEGORY,
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
} from 'features/demandItem/models';
import { DemandItemDetailBlocksQuery } from 'gql/__generated__/DemandItemDetailBlocksQuery.graphql';
import {
  ItemCategory as DemandItemDetailBlocksItemCategory,
  DemandItemDetailBlocksRefetchFragment$key,
} from 'gql/__generated__/DemandItemDetailBlocksRefetchFragment.graphql';
import { useTransition } from 'react';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { paths } from 'utils/paths';
import { DemandItemDetailBlocksHeader } from './DemandItemDetailBlocksHeader';

// 本来、detail_DemandDetailPageQueryにFragmentを渡して1回のリクエストでデータを取得したいが、
// そのやり方で、useRefetchableFragmentを使用するとエラーになる。
// そのため、Fragmentを親コンポーネントに渡さず、DemandItemDetailBlocks内でQueryを発行しデータを取得するようにしている。
const demandItemDetailBlocksQuery = graphql`
  query DemandItemDetailBlocksQuery  ($demandId: ID!) {
    ...DemandItemDetailBlocksRefetchFragment @arguments(demandId: $demandId)
  }
`;

const demandItemDetailBlocksRefetchFragment = graphql`
  fragment DemandItemDetailBlocksRefetchFragment on Query
  @refetchable(queryName: "DemandItemDetailBlocksRefetchQuery")
  @argumentDefinitions(demandId: {type: "ID"})
  {
    demandItems(where: {isCurrent: true, demandID: $demandId}, orderBy: {field: UPDATED_AT, direction: DESC}) {
      edges {
        node {
          id
          name
          category
          supplier {
            name
          }
          demand {
            id
            code
          }
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
          itemNumber
          version
          orderItems(orderBy: { direction: DESC, field: CREATED_AT }, first: 1) {
            edges {
              node {
                orderDetail {
                  order {
                    id
                    title
                  }
                }
              }
            }
          }
          ...DemandItemDetailBlocksHeaderFragment
        }
      }
    }
  }
`;

type Props = {
  demandId: string;
};

// TODO: keyにDemandItemDetailBlocksItemCategory型を使用したオブジェクトにリファクタ予定
const getDemandItemCategory = (category: DemandItemDetailBlocksItemCategory) => {
  if (category === DEMAND_ITEM_CATEGORY.Cardboard) return DEMAND_ITEM_CATEGORY.Cardboard;
  if (category === DEMAND_ITEM_CATEGORY.FlexiblePackage)
    return DEMAND_ITEM_CATEGORY.FlexiblePackage;
  if (category === DEMAND_ITEM_CATEGORY.GiftBox) return DEMAND_ITEM_CATEGORY.GiftBox;
  if (category === DEMAND_ITEM_CATEGORY.PaperBag) return DEMAND_ITEM_CATEGORY.PaperBag;
  return DEMAND_ITEM_CATEGORY.Other;
};

export const DemandItemDetailBlocks = ({ demandId }: Props) => {
  const query = useLazyLoadQuery<DemandItemDetailBlocksQuery>(
    demandItemDetailBlocksQuery,
    { demandId },
    { fetchPolicy: 'network-only' },
  );

  const [data, refetch] = useRefetchableFragment(
    demandItemDetailBlocksRefetchFragment,
    query as DemandItemDetailBlocksRefetchFragment$key,
  );
  const { demandItems } = data;

  const [isPending, startTransition] = useTransition();

  const { deleteDemandItem, isMutationInFlight } = useDeleteDemandItem();

  if (
    !demandItems ||
    !demandItems.edges ||
    demandItems.edges.length === 0 ||
    !demandItems.edges[0]?.node ||
    demandItems.edges[0]?.node === null
  )
    return null;

  const items = demandItems.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  const handleDeleteCallback = () => {
    startTransition(() => {
      refetch({ demandId }, { fetchPolicy: 'network-only' });
    });
  };

  return (
    <>
      {isPending ? (
        <Box width="100%" textAlign="center" mt={40}>
          <Loading />
        </Box>
      ) : (
        items.map((item) => {
          const order = item.orderItems.edges?.[0]?.node?.orderDetail?.order;
          return (
            <DemandItemDetailBlock key={item.id}>
              <DemandItemDetailBlocksHeader queryRef={item} />
              <DemandItemDetailBlockBody>
                <DemandItemDetailBlockInfo
                  values={[
                    {
                      label: 'コード',
                      node: item.demand?.code || '-',
                    },
                    {
                      label: 'サプライヤー',
                      node: item.supplier?.name || '-',
                    },
                    {
                      label: 'カテゴリー',
                      node:
                        DEMAND_ITEM_CATEGORY_LABEL[getDemandItemCategory(item.category)]
                          .categoryName || '-',
                    },
                    {
                      label: '最終発注',
                      node: order ? (
                        <Link to={paths.order._id(order.id)} target="_blank">
                          <HStack gap={1}>
                            <Box>{order.title}</Box>
                            <EfferentFour theme="outline" />
                          </HStack>
                        </Link>
                      ) : (
                        '-'
                      ),
                    },
                  ]}
                />
                <Box w="382px">
                  <DemandItemPriceTable
                    values={(item.prices || []).map((price) => ({
                      id: price.id,
                      quantity: price.quantity,
                      unitPrice: price.unitPrice,
                      unitSellingPrice: price.unitSellingPrice,
                    }))}
                  />
                </Box>
              </DemandItemDetailBlockBody>
              <DemandItemDetailBlockFooter
                editPath={paths.demands
                  ._demandCode(item.demand.code)
                  .items._itemCode({
                    itemNumber: item.itemNumber,
                    category: item.category as DemandItemCategory,
                  })
                  .edit.url()}
                detailPath={paths.demands
                  ._demandCode(item.demand.code)
                  .items._itemCode({
                    category: item.category as DemandItemCategory,
                    itemNumber: item.itemNumber,
                  })
                  .url()}
                onDelete={(callback) => {
                  deleteDemandItem(item.id, () => {
                    callback && callback();
                    handleDeleteCallback();
                  });
                }}
                hasOrder={!!order}
                isDeleting={isMutationInFlight}
              />
            </DemandItemDetailBlock>
          );
        })
      )}
    </>
  );
};
