import { Box, VStack } from '@chakra-ui/react';
import { CompanyDetailedInfoFragment$key } from 'gql/__generated__/CompanyDetailedInfoFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormatWithTime } from 'utils/date';

const CompanyDetailedInfoFragment = graphql`
  fragment CompanyDetailedInfoFragment on Company {
    id
    name
    internalMemos {
      body
    }
    createdAt
  }
`;

type Props = {
  queryRef: CompanyDetailedInfoFragment$key;
};

export const CompanyDetailedInfo = ({ queryRef }: Props) => {
  const { id, name, internalMemos, createdAt } = useFragment(CompanyDetailedInfoFragment, queryRef);

  let memo = '';
  if ((internalMemos?.length || 0) > 0) {
    memo = internalMemos?.[0].body || '';
  }

  return (
    <VStack spacing={6} alignItems="flex-start">
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">ID</Box>
        <Box>{id}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">企業名</Box>
        <Box>{name}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">メモ</Box>
        <Box whiteSpace="pre-wrap">{memo}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">作成日時</Box>
        <Box>{dateFormatWithTime(createdAt)}</Box>
      </VStack>
    </VStack>
  );
};
