import { Box, HStack } from '@chakra-ui/react';
import {
  DEMAND_ITEM_CATEGORY,
  DemandItemCardboard,
  DemandItemFlexiblePackage,
  DemandItemGiftBox,
  DemandItemOther,
  DemandItemPaperBag,
} from 'features/demandItem/models';
import { DemandItemSpecInfoFragment$key } from 'gql/__generated__/DemandItemSpecInfoFragment.graphql';
import { graphql, useFragment } from 'react-relay';

const DemandItemSpecInfoFragment = graphql`
  fragment DemandItemSpecInfoFragment on DemandItem {
    category
    cardboard {
      size
      type
      material
      thickness
      printingColor
      processing
      other
    }
    flexiblePackage {
      size
      material
      printingColor
      processing
      other
    }
    giftBox {
      size
      type
      paperType
      printingColor
      processing
      other
    }
    paperBag {
      size
      paperType
      printingColor
      processing
      handle
      other
    }
    other {
      specText
    }
  }
`;

type Props = {
  queryRef: DemandItemSpecInfoFragment$key;
};

export const DemandItemSpecInfo = ({ queryRef }: Props) => {
  const item = useFragment(DemandItemSpecInfoFragment, queryRef);

  const getCategorySpec = () => {
    if (item.category === DEMAND_ITEM_CATEGORY.Cardboard) {
      return item.cardboard as DemandItemCardboard;
    }
    if (item.category === DEMAND_ITEM_CATEGORY.FlexiblePackage) {
      return item.flexiblePackage as DemandItemFlexiblePackage;
    }
    if (item.category === DEMAND_ITEM_CATEGORY.GiftBox) {
      return item.giftBox as DemandItemGiftBox;
    }
    if (item.category === DEMAND_ITEM_CATEGORY.PaperBag) {
      return item.paperBag as DemandItemPaperBag;
    }
    return item.other as DemandItemOther;
  };

  switch (item.category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return <CardboardSpecInfo cardboard={getCategorySpec() as DemandItemCardboard} />;
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return (
        <FlexiblePackageSpecInfo flexiblePackage={getCategorySpec() as DemandItemFlexiblePackage} />
      );
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return <GiftBoxSpecInfo giftBox={getCategorySpec() as DemandItemGiftBox} />;
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return <PaperBagSpecInfo paperBag={getCategorySpec() as DemandItemPaperBag} />;
    case DEMAND_ITEM_CATEGORY.Other:
      return <OtherSpecInfo other={getCategorySpec() as DemandItemOther} />;
    default:
      return <OtherSpecInfo other={getCategorySpec() as DemandItemOther} />;
  }
};

const CardboardSpecInfo = ({ cardboard }: { cardboard: DemandItemCardboard }) => (
  <>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        サイズ
      </Box>
      <Box minHeight="24px">{cardboard.size || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        形状
      </Box>
      <Box minHeight="24px">{cardboard.type || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        構成
      </Box>
      <Box minHeight="24px">{cardboard.material || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        段厚
      </Box>
      <Box minHeight="24px">{cardboard.thickness || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        印刷
      </Box>
      <Box minHeight="24px">{cardboard.printingColor || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        加工
      </Box>
      <Box minHeight="24px">{cardboard.processing || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        その他
      </Box>
      <Box minHeight="24px">{cardboard.other || '-'}</Box>
    </HStack>
  </>
);

const FlexiblePackageSpecInfo = ({
  flexiblePackage,
}: { flexiblePackage: DemandItemFlexiblePackage }) => (
  <>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        サイズ
      </Box>
      <Box minHeight="24px">{flexiblePackage.size || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        形状
      </Box>
      <Box minHeight="24px">{flexiblePackage.type || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        構成
      </Box>
      <Box minHeight="24px">{flexiblePackage.material || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        印刷
      </Box>
      <Box minHeight="24px">{flexiblePackage.printingColor || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        加工
      </Box>
      <Box minHeight="24px">{flexiblePackage.processing || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        その他
      </Box>
      <Box minHeight="24px">{flexiblePackage.other || '-'}</Box>
    </HStack>
  </>
);

const GiftBoxSpecInfo = ({ giftBox }: { giftBox: DemandItemGiftBox }) => (
  <>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        サイズ
      </Box>
      <Box minHeight="24px">{giftBox.size || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        形状
      </Box>
      <Box minHeight="24px">{giftBox.type || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        用紙
      </Box>
      <Box minHeight="24px">{giftBox.paperType || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        印刷
      </Box>
      <Box minHeight="24px">{giftBox.printingColor || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        加工
      </Box>
      <Box minHeight="24px">{giftBox.processing || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        その他
      </Box>
      <Box minHeight="24px">{giftBox.other || '-'}</Box>
    </HStack>
  </>
);

const PaperBagSpecInfo = ({ paperBag }: { paperBag: DemandItemPaperBag }) => (
  <>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        サイズ
      </Box>
      <Box minHeight="24px">{paperBag.size || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        用紙
      </Box>
      <Box minHeight="24px">{paperBag.paperType || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        印刷
      </Box>
      <Box minHeight="24px">{paperBag.printingColor || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        加工
      </Box>
      <Box minHeight="24px">{paperBag.processing || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        持ち手
      </Box>
      <Box minHeight="24px">{paperBag.handle || '-'}</Box>
    </HStack>
    <HStack spacing={2}>
      <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
        その他
      </Box>
      <Box minHeight="24px">{paperBag.other || '-'}</Box>
    </HStack>
  </>
);

const OtherSpecInfo = ({ other }: { other: DemandItemOther }) => (
  <HStack spacing={2} alignItems="flex-start">
    <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
      その他
    </Box>
    <Box whiteSpace="pre-wrap">{other.specText || '-'}</Box>
  </HStack>
);
