import { Box, HStack, IconButton, VStack, useClipboard } from '@chakra-ui/react';
import { Clipboard } from '@icon-park/react';
import { toast } from 'lib/toast';

type Props = {
  transactionID: string;
};

export const SalesOrderDetailsTransactionId = ({ transactionID }: Props) => {
  const { onCopy } = useClipboard(transactionID);

  const handleCopy = () => {
    onCopy();
    toast({
      title: 'IDをコピーしました',
      status: 'success',
    });
  };

  return (
    <VStack spacing={1} alignItems="flex-start">
      <Box w="120px" color="gray.500">
        ID
      </Box>
      <HStack>
        <Box>{transactionID}</Box>
        <IconButton
          onClick={handleCopy}
          variant="fill"
          isRound
          aria-label="copy"
          bgColor="gray.100"
          fontSize={12}
          size="xs"
          icon={<Clipboard />}
        />
      </HStack>
    </VStack>
  );
};
