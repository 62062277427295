import { Button, Flex, HStack, Text } from '@chakra-ui/react';

type Props = {
  name: string;
  size: string;
  onClick?: () => void;
};

export const AttachmentFileButton = ({ name, size, onClick }: Props) => (
  <Button
    onClick={onClick}
    colorScheme="gray"
    borderRadius="6px"
    size="sm"
    justifyContent="space-between"
  >
    <HStack spacing={1}>
      <Flex>
        <Text
          fontSize="sm"
          lineHeight="20px"
          fontWeight="normal"
          noOfLines={1}
          whiteSpace="normal"
          wordBreak="break-all"
        >
          {name}
        </Text>
      </Flex>
      <Text color="gray.500" fontSize="xs">
        ({size})
      </Text>
    </HStack>
  </Button>
);
