/**
 * @generated SignedSource<<498325a2b3c7b69fe93123dc0edbbe7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationResponsesFragment$data = {
  readonly requestAssignees: ReadonlyArray<{
    readonly id: string;
    readonly responses: ReadonlyArray<{
      readonly createdAt: any;
      readonly expirationPeriod: string;
      readonly id: string;
      readonly importantNotes: string;
      readonly responseDetailMessage: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"EstimationResponseItemTableFragment">;
    }> | null;
    readonly supplier: {
      readonly id: string;
      readonly name: string;
    };
    readonly token: string;
  }> | null;
  readonly " $fragmentType": "EstimationResponsesFragment";
};
export type EstimationResponsesFragment$key = {
  readonly " $data"?: EstimationResponsesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationResponsesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationResponsesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EstimationRequestAssignee",
      "kind": "LinkedField",
      "name": "requestAssignees",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Supplier",
          "kind": "LinkedField",
          "name": "supplier",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "token",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationResponse",
          "kind": "LinkedField",
          "name": "responses",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "responseDetailMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expirationPeriod",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "importantNotes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EstimationResponseItemTableFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EstimationRequest",
  "abstractKey": null
};
})();

(node as any).hash = "1db689b10956303b1058598d511d0a76";

export default node;
