import { PartialMessage, Struct } from '@bufbuild/protobuf';
import { Box, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { AttachmentFileButton } from 'components/AttachmentFileButton';
import { CellValue } from 'exceljs';
import { ItemTable } from 'features/item/components/ItemTable';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { useOwnCompanyInfo } from 'hooks/useOwnCompanyInfo';
import { RawItem } from 'proto/model/item/v1/summarize_item_pb';
import { ItemService } from 'proto/service/item/v1/item_connect';
import { SummarizeItemsResponse } from 'proto/service/item/v1/item_pb';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { concatFullName } from 'utils/label';
import { formatBytes } from 'utils/number';

export type PreviewProps = {
  mailSubject: string;
  specText: string;

  suppliers: {
    id: string;
    name: string;
    headerMessage: string;
    footerMessage: string;
    contacts: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      memo?: string;
    }[];
  }[];

  attachments?: {
    name: string;
    size: number;
  }[];

  items: {
    name: string;
    specJson?: Map<string, CellValue | undefined> | undefined;
    quantity: number;
  }[];

  internalAssignees?: { value: string; label: string }[];

  children?: ReactElement;
};

const DEFAULT_WIDTH = 768;

export const EstimationRequestPreview = (data: PreviewProps) => {
  const { name: ownCompanyName, fullAddress: ownCompanyAddress } = useOwnCompanyInfo();
  const { grpcClient, authorized } = useGrpcClient(ItemService);
  const [summarizedItems, setSummarizedItems] = useState<PartialMessage<SummarizeItemsResponse>>({
    items: [],
  });
  useEffect(() => {
    (async () => {
      if (!authorized) return;

      let unmounted = false;
      const items = data.items;
      if (items.length === 0) return;

      const rawItems = items.map((item): PartialMessage<RawItem> => {
        const specJsonWithCellValue = Object.fromEntries(item.specJson || []);
        const specJson: { [key: string]: string | number | boolean | null } = {};

        for (const [key, value] of Object.entries(specJsonWithCellValue)) {
          if (value === undefined || value === null) {
            specJson[key] = null;
            continue;
          }

          switch (typeof value) {
            case 'string':
            case 'number':
            case 'boolean':
              specJson[key] = value;
              continue;
            default:
              specJson[key] = value.toString();
          }
        }

        return {
          name: item.name || '',
          quantity: item.quantity,
          specJson: Struct.fromJson(specJson),
        };
      });

      const summary = await grpcClient.summarizeItems({ items: rawItems });
      summary.items = summary.items.map((item) => {
        item.columns = item.columns.filter((col) => col.key !== 'unitPrice');
        return item;
      });

      if (unmounted) return;
      setSummarizedItems(summary);

      return () => {
        unmounted = true;
      };
    })();
  }, [data.items, authorized, grpcClient]);
  return (
    <VStack w="100%" alignItems="stretch" gap={6}>
      <VStack w={DEFAULT_WIDTH} gap={6} alignItems="stretch">
        {data.suppliers.map((supplier) => {
          return (
            <VStack key={`supplier-preview-${supplier.id}`} alignItems="stretch">
              <Heading as="h3" fontSize="xl">
                {supplier.name}
              </Heading>

              <Text color="gray.700">
                送信先：
                <VStack mt={2} alignItems="flex-start" wrap="wrap" spacing={2}>
                  {supplier.contacts.map((contact, _) => (
                    <VStack spacing={0} alignItems="flex-start" key={contact.id}>
                      <HStack>
                        <Box fontWeight="bold" whiteSpace="nowrap" fontSize="sm">
                          {concatFullName({
                            lastName: contact.lastName,
                            firstName: contact.firstName,
                          })}
                        </Box>
                        <Box color="gray.600" fontSize="sm">
                          {contact.email}
                        </Box>
                      </HStack>
                      <HStack alignItems="flex-start" fontSize="sm" color="gray.500">
                        <Box minWidth="fit-content">メモ:</Box>
                        <Box whiteSpace="pre-wrap">{contact.memo ?? '-'}</Box>
                      </HStack>
                    </VStack>
                  ))}
                </VStack>
              </Text>

              <VStack
                alignItems="stretch"
                whiteSpace="pre-wrap"
                fontSize="sm"
                borderRadius="6px"
                borderWidth="1px"
                borderColor="gray.200"
                p={4}
              >
                <Heading as="h4" fontSize="md" mb={4}>
                  {data.mailSubject}
                </Heading>

                <Box>
                  <Text>{supplier.headerMessage}</Text>
                  <Box my={4}>
                    <Text>お見積内容は下記URLよりご入力をお願いいたします。</Text>
                    <Text color="blue.500" display="inline" textDecoration="underline">
                      https://lube.shiza1.com/〜
                    </Text>
                  </Box>
                  <Text>{supplier.footerMessage}</Text>
                  <Text>--------------------------------------------------------</Text>
                  <Text>{ownCompanyName}</Text>
                  <Text>(あなたの名前)</Text>
                  <Text>(あなたのメールアドレス)</Text>
                  <Text>{ownCompanyAddress}</Text>
                </Box>

                <VStack align="stretch" spacing={0}>
                  {data.attachments != null && (
                    <Box>
                      {Array.from(data.attachments).map((attachment, index) => (
                        <HStack justify="space-between" key={`attachment-${index}`} my={2}>
                          <AttachmentFileButton
                            name={attachment.name}
                            size={formatBytes(attachment.size)}
                          />
                        </HStack>
                      ))}
                    </Box>
                  )}
                </VStack>
              </VStack>
            </VStack>
          );
        })}
      </VStack>

      <Text color="gray.500" mb={6} w={DEFAULT_WIDTH}>
        下記の情報はメールに表示されず、リンク先で表示されます
      </Text>

      <VStack alignItems="stretch" spacing={4}>
        <Heading as="h2" fontSize={24} w={DEFAULT_WIDTH}>
          見積条件
        </Heading>
        <Box whiteSpace="pre-wrap" w={DEFAULT_WIDTH}>
          {data.specText}
        </Box>
        <VStack w="100%" alignItems="start">
          {(summarizedItems.items || []).map((item, index) => (
            <Box key={item.category}>
              <ItemTable
                key={`item-table-${index}`}
                readonly
                items={summarizedItems.items || []}
                index={index}
              />
            </Box>
          ))}
        </VStack>
      </VStack>

      <VStack alignItems="stretch" spacing={4}>
        <Heading as="h2" fontSize={24} w={DEFAULT_WIDTH}>
          担当者
        </Heading>
        <HStack wrap="wrap" spacing={0}>
          {(data.internalAssignees || []).map((assignee, index) => (
            <Fragment key={assignee.value}>
              {index !== 0 && (
                <Box color="gray.200" px={2}>
                  /
                </Box>
              )}
              <HStack>
                <Box whiteSpace="nowrap">{assignee.label}</Box>
              </HStack>
            </Fragment>
          ))}
        </HStack>
      </VStack>

      <Box w={DEFAULT_WIDTH}>{data.children}</Box>
    </VStack>
  );
};
