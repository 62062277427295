// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file service/estimation_response_external/v1/estimation_response_external.proto (package service.estimation_response_external.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetInitialDataByTokenRequest, GetInitialDataByTokenResponse, RegisterRequest, RegisterResponse } from "./estimation_response_external_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service service.estimation_response_external.v1.EstimationResponseExternalService
 */
export const EstimationResponseExternalService = {
  typeName: "service.estimation_response_external.v1.EstimationResponseExternalService",
  methods: {
    /**
     * 見積依頼トークンからアイテムのサマリーを取得する
     *
     * @generated from rpc service.estimation_response_external.v1.EstimationResponseExternalService.GetInitialDataByToken
     */
    getInitialDataByToken: {
      name: "GetInitialDataByToken",
      I: GetInitialDataByTokenRequest,
      O: GetInitialDataByTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * 見積回答を登録する
     *
     * @generated from rpc service.estimation_response_external.v1.EstimationResponseExternalService.Register
     */
    register: {
      name: "Register",
      I: RegisterRequest,
      O: RegisterResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

