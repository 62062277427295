import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from '@chakra-ui/react';
import { More, Seal, SendEmail, TriangleRuler } from '@icon-park/react';
import { Link } from 'components/Link';
import { OrderTableRowFragment$key } from 'gql/__generated__/OrderTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const OrderTableRowFragment = graphql`
  fragment OrderTableRowFragment on Order {
    id
    title
    company {
      id
      name
    }
    supplier {
      id
      name
    }
    createdAt
    createdBy {
      profile {
        lastName
        firstName
      }
    }

    salesOrders {
      id
      estimations {
        id
        estimationResponse {
          id
          assignee {
            id
            estimationRequestID
          }
        }
      }
    }

    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

export const OrderTableRow = ({ queryRef }: { queryRef: OrderTableRowFragment$key }) => {
  const data = useFragment(OrderTableRowFragment, queryRef);

  const salesOrder = data.salesOrders?.[0];
  const estimation = salesOrder?.estimations?.[0];
  const estimationRequestId = estimation?.estimationResponse?.assignee.estimationRequestID;

  return (
    <Tr>
      <Td>{data.title || '-'} </Td>
      <Td>
        <Link to={paths.company._id(data.company.id).url}>{data.company.name}</Link>
      </Td>
      <Td>
        <Link to={paths.supplier._id(data.supplier.id).url()}>{data.supplier.name}</Link>
      </Td>
      <Td whiteSpace="nowrap">{dateFormat(data.createdAt)}</Td>
      <Td>
        {data.internalAssignees?.map((assignee) =>
          assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
            <Box key={assignee.user.id}>
              {concatFullName({
                lastName: assignee.user.profile[0].lastName,
                firstName: assignee.user.profile[0].firstName,
              })}
            </Box>
          ) : (
            <Box key={assignee.user.id}>-</Box>
          ),
        ) || '-'}
      </Td>
      <Td align="center" textAlign="center">
        {estimationRequestId ? (
          <Button
            as={RouterLink}
            w={10}
            h={10}
            borderRadius={50}
            to={paths.estimationRequest._id(estimationRequestId)}
            mx="auto"
          >
            <SendEmail />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td align="center" textAlign="center">
        {estimation ? (
          <Button
            w={10}
            h={10}
            borderRadius={50}
            to={paths.estimation._id(estimation.id).url}
            as={RouterLink}
          >
            <TriangleRuler />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td align="center" textAlign="center">
        {salesOrder ? (
          <Button
            w={10}
            h={10}
            borderRadius={50}
            to={paths.salesOrder.id(salesOrder.id).url()}
            as={RouterLink}
          >
            <Seal />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td>
        <Menu>
          <MenuButton>
            <More />
          </MenuButton>
          <MenuList>
            <MenuItem as={RouterLink} to={paths.order.edit._id(data.id)}>
              編集
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="sm" to={paths.order._id(data.id)}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
