import { getFirebaseAnalytics, logCustomClickEvent } from 'service/firebase';
import { useSession } from './useSession';

export const useAnalytics = () => {
  const { currentUser } = useSession();
  const analytics = getFirebaseAnalytics();

  const trackCustomClickEvent = (trackingName: string) => {
    logCustomClickEvent(analytics, {
      trackingName: trackingName,
      path: location.pathname,
      userName: currentUser?.displayName || '',
    });
  };

  return { trackCustomClickEvent };
};
