import { PartialMessage } from '@bufbuild/protobuf';
import { CloseIcon } from '@chakra-ui/icons';
import { Button, HStack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import {
  CategoryItemsSummary,
  ItemCategoryDataType,
  ItemSpecColumn,
} from 'proto/model/item/v1/summarize_item_pb';
import { FocusEvent, Fragment, useCallback } from 'react';
import { addItem, createTableRowData, getItemById, removeItem, updateItemValue } from './helper';

type Props =
  | {
      items: PartialMessage<CategoryItemsSummary>[];
      updateState: React.Dispatch<React.SetStateAction<PartialMessage<CategoryItemsSummary>[]>>;
      index: number;
      readonly?: false;
    }
  | {
      items: PartialMessage<CategoryItemsSummary>[];
      index: number;
      readonly: true;
    };

export const ItemTable = ({ items, index, ...props }: Props) => {
  if (items.length === 0) return null;

  const readonly = props.readonly === true;
  let updateState:
    | React.Dispatch<React.SetStateAction<PartialMessage<CategoryItemsSummary>[]>>
    | undefined;
  if (props.readonly !== true) {
    updateState = props.updateState;
  }

  const data = items[index];
  const rows = createTableRowData(data.items || [], (parentId) =>
    getItemById(data.items || [], parentId),
  );

  const handleChangeValue = (e: FocusEvent<HTMLInputElement>, id: string) => {
    updateState?.((prevState) =>
      updateItemValue(prevState, id, {
        kind: { case: 'numberValue', value: Number(e.target.value) },
      }),
    );
  };

  return (
    <Table>
      <Thead>
        <Tr>
          {(data.columns || []).map((column) => (
            <TableTh key={column.key} column={column} readonly={readonly} />
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((row, rowIndex) => (
          <Tr key={rowIndex}>
            {row.cells.map((cell) => {
              const column = (data.columns || []).find((column) => column.key === cell.type);
              if (column === undefined) return null;

              const editable = column.editable && !readonly;

              let actions: JSX.Element | null = null;
              if (cell.isLast && editable) {
                actions = (
                  <HStack>
                    <Button
                      onClick={() => {
                        console.log('add', cell);
                        updateState?.((prevState) =>
                          addItem(
                            prevState,
                            cell.ancestorIds[1],
                            cell.ancestorIds[0],
                            cell.sourceItemId,
                            cell.spec,
                          ),
                        );
                      }}
                      size="xs"
                      w="82px"
                      minW="82px"
                    >
                      下に1行追加
                    </Button>
                    <Button
                      onClick={() => {
                        updateState?.((prevState) => removeItem(prevState, cell.ancestorIds[0]));
                      }}
                      variant="ghost"
                      borderRadius={50}
                      w={10}
                      h={10}
                      isDisabled={!cell.hasSameSpecItems}
                    >
                      <CloseIcon color="gray.700" w="10px" h="10px" />
                    </Button>
                  </HStack>
                );
              }

              if (editable) {
                return (
                  <Fragment key={cell.id}>
                    <Td rowSpan={cell.rowSpan} border="none">
                      <HStack spacing={4}>
                        <ConfigurableNumberInput
                          onBlur={(event) => handleChangeValue(event, cell.id || '')}
                          defaultValue={cell.value}
                          w="112px"
                          minW="112px"
                        />
                        {actions}
                      </HStack>
                    </Td>
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={cell.id}>
                    <Td rowSpan={cell.rowSpan}>
                      <HStack
                        spacing={4}
                        justifyContent={
                          column.dataType === ItemCategoryDataType.STRING ? 'start' : 'end'
                        }
                        whiteSpace="pre-wrap"
                      >
                        <span>{cell.value}</span>
                        {actions}
                      </HStack>
                    </Td>
                  </Fragment>
                );
              }
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const TableTh = ({
  column,
  readonly,
}: { column?: PartialMessage<ItemSpecColumn>; readonly: boolean }) => {
  const getColor = useCallback(
    () => ({
      bg: column?.editable && !readonly ? 'blue.500' : 'gray.50',
      color: column?.editable && !readonly ? 'white' : 'gray.700',
    }),
    [column?.editable, readonly],
  );

  if (column === undefined) return null;

  return (
    <>
      {column.key !== 'unitPrice' && column.key !== 'quantity' && (
        <Th
          whiteSpace="nowrap"
          textAlign={column.dataType === ItemCategoryDataType.NUMBER ? 'right' : 'left'}
          border="none"
          py={3}
          {...getColor()}
        >
          {column.label}
        </Th>
      )}

      {column.key === 'quantity' && (
        <Th
          whiteSpace="nowrap"
          border="none"
          textAlign={column.dataType === ItemCategoryDataType.NUMBER ? 'right' : 'left'}
          width="112px"
          py={3}
          {...getColor()}
        >
          {column.label}
        </Th>
      )}

      {column.key === 'unitPrice' && (
        <Th whiteSpace="nowrap" p={0} border="none">
          <Text w="136px" textAlign="right" py={3} pr={4} pl={8} {...getColor()}>
            {column.label}
          </Text>
        </Th>
      )}
    </>
  );
};
