import { Box, VStack } from '@chakra-ui/react';
import { Link } from 'components/Link';
import { InvoiceDetailInfoFragment$key } from 'gql/__generated__/InvoiceDetailInfoFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat, dateFormatWithTime } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const InvoiceDetailInfoFragment = graphql`
  fragment InvoiceDetailInfoFragment on Invoice
  {
    id
    title
    createdAt
    bookedAt
    paymentDueAt
    company {
      name
    }
    createdBy {
      profile {
        lastName
        firstName
      }
    }
    freeeInvoice {
      freeeInvoiceID
    }
    salesOrders {
      id
      title
      estimations {
        id
        title
        estimationResponse {
          assignee {
            estimationRequestID
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: InvoiceDetailInfoFragment$key;
};

const FREEE_INVOICE_BASE_URL = 'https://secure.freee.co.jp/invoice/reports/invoices';

export const InvoiceDetailInfo = ({ queryRef }: Props) => {
  const { id, createdAt, createdBy, company, bookedAt, paymentDueAt, salesOrders, freeeInvoice } =
    useFragment(InvoiceDetailInfoFragment, queryRef);
  const freeeUrl = `${FREEE_INVOICE_BASE_URL}/${freeeInvoice?.freeeInvoiceID}`;
  const estimations = salesOrders
    ?.flatMap((salesOrder) => salesOrder.estimations)
    .filter((v): v is NonNullable<typeof v> => v != null);

  return (
    <VStack spacing={6} alignItems="flex-start">
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">ID</Box>
        <Box>{id}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">作成者</Box>
        <Box>
          {createdBy.profile?.[0]?.lastName && createdBy.profile?.[0]?.firstName
            ? concatFullName({
                lastName: createdBy.profile[0].lastName,
                firstName: createdBy.profile[0].firstName,
              })
            : '-'}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">作成日時</Box>
        <Box>{dateFormatWithTime(createdAt)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">デマンド</Box>
        <Box>{company.name}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">請求日</Box>
        <Box>{dateFormat(bookedAt)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">振込期日</Box>
        <Box>{dateFormat(paymentDueAt)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">見積依頼</Box>
        <Box>
          {estimations?.map((estimation) => (
            <Box key={estimation.id}>
              <Link
                to={paths.estimationRequest._id(
                  estimation.estimationResponse?.assignee.estimationRequestID || '',
                )}
              >
                {estimation.estimationResponse?.assignee.estimationRequestID}
              </Link>
            </Box>
          ))}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">見積書</Box>
        <Box>
          {estimations?.map((estimation) => (
            <Box key={estimation.id} wordBreak="break-all">
              <Link key={estimation.id} to={paths.estimation._id(estimation.id).url}>
                {estimation.title}
              </Link>
            </Box>
          ))}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">発注請書</Box>
        <Box>
          {salesOrders?.map((salesOrder) => (
            <Box key={salesOrder.id} wordBreak="break-all">
              <Link key={salesOrder.id} to={paths.salesOrder.id(salesOrder.id).url()}>
                {salesOrder.title}
              </Link>
            </Box>
          ))}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">freee請求書</Box>
        <Box wordBreak="break-all">
          <Link to={freeeUrl} target="_blank" rel="noopener noreferrer">
            {freeeUrl}
          </Link>
        </Box>
      </VStack>
    </VStack>
  );
};
