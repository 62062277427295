/**
 * @generated SignedSource<<3510ec9c87f1e7eca99e681d312ceb23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DemandItemSpecInfoFragment$data = {
  readonly cardboard: {
    readonly material: string;
    readonly other: string;
    readonly printingColor: string;
    readonly processing: string;
    readonly size: string;
    readonly thickness: string;
    readonly type: string;
  } | null;
  readonly category: ItemCategory;
  readonly flexiblePackage: {
    readonly material: string;
    readonly other: string;
    readonly printingColor: string;
    readonly processing: string;
    readonly size: string;
  } | null;
  readonly giftBox: {
    readonly other: string;
    readonly paperType: string;
    readonly printingColor: string;
    readonly processing: string;
    readonly size: string;
    readonly type: string;
  } | null;
  readonly other: {
    readonly specText: string;
  } | null;
  readonly paperBag: {
    readonly handle: string;
    readonly other: string;
    readonly paperType: string;
    readonly printingColor: string;
    readonly processing: string;
    readonly size: string;
  } | null;
  readonly " $fragmentType": "DemandItemSpecInfoFragment";
};
export type DemandItemSpecInfoFragment$key = {
  readonly " $data"?: DemandItemSpecInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemSpecInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "material",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printingColor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processing",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paperType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandItemSpecInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemCardboard",
      "kind": "LinkedField",
      "name": "cardboard",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thickness",
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemFlexiblePackage",
      "kind": "LinkedField",
      "name": "flexiblePackage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemGiftBox",
      "kind": "LinkedField",
      "name": "giftBox",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v6/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemPaperBag",
      "kind": "LinkedField",
      "name": "paperBag",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemOther",
      "kind": "LinkedField",
      "name": "other",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specText",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DemandItem",
  "abstractKey": null
};
})();

(node as any).hash = "be9c4203b41673a3327a815ff32d1458";

export default node;
