import { Input } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { toast } from 'lib/toast';
import { EstimationRequestResult } from 'proto/model/search/v1/result_pb';
import { SearchType } from 'proto/model/search/v1/type_pb';
import { SearchService } from 'proto/service/search/v1/search_connect';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'rooks';

type Props = {
  defaultSearchWord: string;
  onChange: (word: string, ids: string[], isSkip: boolean) => void;
};

export const EstimationRequestSearchForm = ({ defaultSearchWord, onChange }: Props) => {
  const [inputValue, setInputValue] = useState(defaultSearchWord);
  const setValueDebounced = useDebounce(setInputValue, 800);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueDebounced(event.target.value);
  };

  const { grpcClient, authorized } = useGrpcClient(SearchService);

  const onSearchEstimationRequests = async (): Promise<string[]> => {
    try {
      const res = await grpcClient.search({
        types: [SearchType.ESTIMATION_REQUEST],
        word: inputValue,
      });

      const ids = new Set<string>();
      res.results.forEach((r) => {
        if (r.result.value instanceof EstimationRequestResult) {
          ids.add(r.result.value.estimationRequestId);
        }
      });

      return Array.from(ids);
    } catch (error) {
      captureException(error, {
        level: 'warning',
      });

      let message = '';
      if (error instanceof Error) {
        message = error.message;
      }

      toast({
        title: '見積依頼の検索処理中にエラーが発生しました',
        description: message,
        status: 'error',
      });
    }

    return [];
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    (async () => {
      // grpc クライアントの認証が終わっていないと無駄な API 呼び出しになるため
      if (!authorized) {
        return;
      }

      if (inputValue === '') {
        onChange('', [], false);
        return;
      }

      const results = await onSearchEstimationRequests();

      // あいまい検索した結果マッチする見積依頼がなかった場合、一覧を取得しない
      if (results.length === 0) {
        onChange(inputValue, [], true);
        return;
      }

      onChange(inputValue, results, false);
    })();
  }, [inputValue, authorized]);

  return (
    <Input
      type="text"
      defaultValue={defaultSearchWord}
      onChange={handleChange}
      placeholder="例）配送箱、封筒、名古屋..."
      autoComplete="off"
      data-testid="estimation-request-search-form-input"
    />
  );
};
